import Locale from "translations";

export default function PassengerDetailsTransfer({ Transfer }) {
	const { backOffice } = Locale;

	return (
		<div>
			<div className=" table-responsive light-border">
				<table className="custom-table back-office-table table bg-white mb-0">
					<thead>
						<tr className="text-nowrap">
							<th>Type</th>
							<th>Name</th>
							<th>Surname</th>
							<th>Mobile/Cell</th>
							<th>Email</th>
						</tr>
					</thead>
					<tbody>
						{Transfer?.passengers?.length > 0 ? (
							Transfer?.passengers?.map((item) => (
								<tr>
									<td></td>
									<td>{item?.name}</td>
									<td>{item?.surname}</td>
									<td>-</td>
									<td>-</td>
								</tr>
							))
						) : (
							<tr>
								<td></td>
								<td>{Transfer?.holder_info?.name}</td>
								<td>{Transfer?.holder_info?.surname}</td>
								<td>{Transfer?.holder_info?.phone}</td>
								<td>{Transfer?.holder_info?.email}</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}
