export default function EditIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M14.0929 3.59997L5.88286 12.29C5.57286 12.62 5.27286 13.27 5.21286 13.72L4.84286 16.96C4.71286 18.13 5.55286 18.93 6.71286 18.73L9.93286 18.18C10.3829 18.1 11.0129 17.77 11.3229 17.43L19.5329 8.73997C20.9529 7.23997 21.5929 5.52997 19.3829 3.43997C17.1829 1.36997 15.5129 2.09997 14.0929 3.59997Z"
				stroke="#1E85FF"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.7231 5.05005C13.1531 7.81005 15.3931 9.92005 18.1731 10.2"
				stroke="#1E85FF"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.83301 22H21.833"
				stroke="#1E85FF"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
