import Model from "components/model";
import { useState } from "react";
import { store } from "react-notifications-component";
import { sendChangePriority } from "services/b2c";

function PriorityModel({
	isOpen,
	toggle,
	select,
	setselect,
	initialPriority,
	setReload,
}) {
	const [Priority, setPriority] = useState(initialPriority || "");
	const senddata = async () => {
		const req = await sendChangePriority({
			priority: Priority,
			reservationNums: select,
		});
		if (req.status === 200) {
			store.addNotification({
				title: "Done!",
				message: req.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setselect([]);
			setReload((prev) => !prev);
			toggle();
			setPriority("");
		}
	};

	return (
		<Model
			isOpen={isOpen}
			toggle={toggle}
			title={"Change Priority"}
			hasFooter={false}
			hasHeader={true}
		>
			<div className="w-100 d-flex flex-row align-items-center justify-content-evenly mb-4 Priority-model">
				<div className="d-flex">
					<input
						type="radio"
						id="high"
						checked={Priority === "high"}
						onChange={() => {
							setPriority("high");
						}}
					/>
					<label className="High mx-1" htmlFor="high">
						High
					</label>
				</div>
				<div className="d-flex">
					<input
						type="radio"
						id="medium"
						checked={Priority === "medium"}
						onChange={() => {
							setPriority("medium");
						}}
					/>
					<label className="Medium mx-1" htmlFor="medium">
						Medium
					</label>
				</div>
				<div className="d-flex">
					<input
						type="radio"
						id="low"
						checked={Priority === "low"}
						onChange={() => {
							setPriority("low");
						}}
					/>
					<label className="Low mx-1" htmlFor="low">
						Low
					</label>
				</div>
			</div>
			<button onClick={senddata} className="btn mb-4 btn-save-model">
				Save Changes
			</button>
		</Model>
	);
}

export default PriorityModel;
