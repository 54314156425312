import axios from "axios";
import { cleanEmpty } from "./general";

const attractionURL = process.env.REACT_APP_API_URL + "/api";

export const CreateAttraction = async (data) => {
	return await axios
		.post(`${attractionURL}/attraction`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewAttraction = async (id) => {
	return await axios
		.get(`${attractionURL}/attraction/${id}`)
		.then((res) => res)
		.catch((res) => res);
};
export const UpdateAttraction = async (id, data) => {
	return await axios
		.put(`${attractionURL}/attraction/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchAttraction = async (params) => {
	return await axios
		.get(`${attractionURL}/attraction`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((res) => res);
};
export const ShareAttraction = async (id, data) => {
	return await axios
		.put(`${attractionURL}/attraction/${id}/share`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const UpdateCalenderBulk = async (id, data) => {
	return await axios
		.put(`${attractionURL}/attraction/${id}/calendar/bulk`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateCalender = async (id, data) => {
	return await axios
		.put(`${attractionURL}/attraction/${id}/calendar`, data)
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const deleteAttraction = async (id) => {
	return await axios
		.delete(`${attractionURL}/attraction/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
