import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function RejectRequestModal({ isOpen, toggle, submitReject }) {
	const { backOffice, translate_companies } = Locale;
	const [rejectReason, setRejectReason] = useState("");

	return (
		<Modal isOpen={isOpen} centered={true}>
			<ModalHeader toggle={toggle}>
				<span>{backOffice.rejectRequest}</span>
			</ModalHeader>

			<ModalBody>
				<p className="m-0">{backOffice.provideRejectReason}</p>

				<textarea
					placeholder={`${backOffice.reasonForRejection}...`}
					name="reject-reason"
					id="reject-reason"
					rows="5"
					className="w-100 control-field__input h-auto"
					value={rejectReason}
					onChange={(e) => setRejectReason(e.target.value)}
				/>

				<button
					className="reject-btn w-100"
					onClick={() => submitReject({ reject_reason: rejectReason })}
					disabled={rejectReason.length === 0}
				>
					{translate_companies.reject}
				</button>
			</ModalBody>
		</Modal>
	);
}
