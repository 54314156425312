import SelectField from "components/Form/SelectField/SelectField";
// import SelectField from "components/shared/SelectField";

import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	fetchHistoryUsers,
	listGroupReservationsHistory,
} from "services/sbsReservations";
import { FilterIcon } from "../Main/DetailsIcons";

const statusLookups = [
	{ value: 1, label: "NEW_REQUEST" },
	{ value: 2, label: "PENDING" },
	{ value: 3, label: "TENTATIVE" },
	{ value: 4, label: "DEFINITE" },
	{ value: 5, label: "CANCELED" },
];

function History() {
	const [historyList, setHistoryList] = useState([]);

	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const { id } = useParams();
	const [metaData, setMetaData] = useState(null);
	const [historyUsers, setHistoryUsers] = useState([]);

	const [filters, setFilters] = useState({
		date: new Date(),
		responsibleName: "",
	});

	const fetchHistoryLookups = async () => {
		const userHistory = await fetchHistoryUsers(id);
		const formatData = userHistory.data.data.map((res) => ({
			label: res.user_name,
			value: res.user_id,
		}));
		setHistoryUsers(formatData);
	};

	useEffect(() => {
		fetchHistoryLookups();

		return () => {};
	}, []);

	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				date: moment(filters.date).format("DD-MM-YYYY"),
				responsibleName: filters.responsibleName?.value,
			};
			const response = await listGroupReservationsHistory(id, data, pages);
			if (response?.status === 200) {
				setHistoryList(response?.data.data);
				setMetaData(response.data.meta);
			}
		}
		fetchReservationsFilterdData();
	}, [filters.date, filters.responsibleName, pages]);

	const fetchReservationsData = async (filters = {}, page = pages) => {
		const response = await listGroupReservationsHistory(filters, page);
		if (response?.status === 200) {
			setHistoryList(response?.data?.data);
			setMetaData(response?.data?.meta);
		}
	};

	const goTo = (page) => {
		fetchReservationsData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<div id="reserv-history" className="border">
			<div className="history_filter py-2 px-3 border-bottom row gap-2">
				<div className="col-md-3 history_filter_select">
					<SelectField
						label={"Responsible Name"}
						// placeholder={"Responsible Name"}
						placeholder={filters?.responsibleName?.label}
						value={filters?.responsibleName?.label}
						name="responsibleName"
						// id="responsibleName"
						options={historyUsers}
						onChange={(e) => {
							setFilters((current) => ({
								...current,
								responsibleName: e,
							}));
						}}
					/>
				</div>
				<div className="col-md-3 history_filter_date">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"Date"}
						date={moment(filters.date)}
						onChangeDate={(date) =>
							setFilters((current) => ({ ...current, date: date._d }))
						}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>
				<div className="pt-4 d-flex">
					<button
						className="btn p-0"
						onClick={() =>
							setFilters({ date: new Date(), responsibleName: "" })
						}
					>
						<FilterIcon />

						<span className="mx-2">Reset Filter</span>
					</button>
				</div>
			</div>
			<div className="main_history px-3">
				{historyList.map((i) => (
					<div class="history_block pt-2">
						<div class="d-flex">
							<div class="px-2">
								<span class="tes">
									<i class="fa fa-circle"></i>
								</span>
							</div>
							<div className="history_content">
								<h6 class="font-weight-bold">{status(i)}</h6>
								<p>{`${i.user_name ? i.user_name + " |" : ""}  ${
									i.created_at
								}`}</p>
							</div>
						</div>
					</div>
				))}
			</div>
			<Pagination info={metaData} goTo={goTo} />
		</div>
	);
}

export default History;

const status = (element) => {
	if (element.attribute === "status") {
		return `Status changed from ${
			statusLookups.find((i) => i.value == +element.old_value)?.label
		} to ${statusLookups.find((i) => i.value == +element.value)?.label}`;
	}
	if (
		element.attribute === "date_from" ||
		element.attribute === "date_to" ||
		element.attribute === "rooms_count" ||
		element.attribute === "hotel_name"
	) {
		return `${element.attribute} changed from ${element.old_value} to ${element.value}`;
	}
	if (element.attribute === "total_sell_price") {
		return `Total Selling Price changed from ${element.old_value} to ${element.value}`;
	}
};
