import TimePickerField from "components/shared/TimePickerField";
import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { DeleteIcon } from "../Icons/deleteIcon";

export default function ConfigureTimeCalender(props) {
	const { isOpen, toggle, state, setState, ConfigureTime } = props;

	const handleTime = (value, index) => {
		const clone = state.times;
		clone[index] = value;
		setState((current) => ({ ...current, times: clone }));
	};
	useEffect(() => {
		if (state?.times?.length == 0) {
			setState({ ...state, times: [""] });
		}
	}, [isOpen]);
	console.log("state", state);
	const removeTime = (index) => {
		const clone = state?.times;
		clone.splice(index, 1);
		setState((current) => ({ ...current, times: clone }));
	};
	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className="configure-calender-attraction-modal"
		>
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<div className="w-90 title">
					<p className=" text-secondary text-center">{state?.day}</p>
				</div>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3 ">
				<div className=" m-0 px-4 w-100">
					<div>
						{state?.times?.map((time, index) => (
							<div className="time-confige  d-flex ">
								<TimePickerField
									label={`Time ${index > 0 ? index + 1 : ""}`}
									fromValue={time}
									onFromChange={(e) => {
										handleTime(e, index);
									}}
									name="time"
									hourPlaceholder="00"
									minutePlaceholder="00"
									format="hh:mm a"
								/>
								<button
									className="btn btn-link mt-4"
									onClick={() => removeTime(index)}
								>
									<DeleteIcon />
								</button>
							</div>
						))}
						<button
							className="btn btn-add"
							onClick={() =>
								setState({ ...state, times: [...state?.times, ""] })
							}
						>
							{/* <PlusIcon color={"#6A4D1B"} />  */}
							<i className="fas fa-plus" /> Add New
						</button>
					</div>
				</div>
			</ModalBody>
			<div className="w-100 pb-5 border-top-0  text-center px-4 mt-2">
				<button
					className="btn rounded companies-btn text-white   w-100"
					onClick={() => ConfigureTime(state)}
					// disabled={disabledBtn() ? true : false}
				>
					Apply
				</button>
			</div>
		</Modal>
	);
}
