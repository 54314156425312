import React, { useState } from "react";
import walletAdd from "assets/images/wallet/wallet-add.svg";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";
import { useHistory, useParams } from "react-router-dom";
import { addCompanyWallet, addYarvelWallet } from "services/wallet";
import useWallet from "./useWallet";
import { store } from "react-notifications-component";

export default function CreateCompanyRequest() {
  const { wallet } = Locale;
  const { type, company_id } = useParams();
  const [amount, setAmount] = useState();
  let history = useHistory();

  const { paymentCard } = useWallet(company_id);

  const addBalance = async () => {
    let res;
    if (type === "company") {
      res = await addCompanyWallet({
        company_id,
        amount,
        transaction_type: "add",
        payment_card_id:
          type === "company" ? paymentCard.debit : paymentCard.credit,
      });
    } else {
      res = await addYarvelWallet({
        company_id,
        amount,
        transaction_type: "add",
        payment_card_id:
          type === "company" ? paymentCard.debit : paymentCard.credit,
      });
    }

    if (res.status === 200) {
      store.addNotification({
        title: "Info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push(`/tourism-company/${company_id}/wallet`);

    }
  };

  return (
    <div className="wallet-wrraper" style={{ height: "70vh" }}>
      <div className="container p-0 display-card">
        <div className="create-card">
          <img src={walletAdd} alt="" />
          {wallet.addBalanceRequest}
          <div className="w-75 my-4">
            <TextField
              type="text"
              placeholder={wallet.amount}
              hasLabel={false}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            disabled={amount === undefined}
            class="btn btn-success w-75"
            onClick={() => {
              addBalance();
            }}
          >
            {wallet.addBalanceRequest}
          </button>
        </div>
      </div>
    </div>
  );
}
