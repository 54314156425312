import TextAreaField from "components/shared/TextAreaField";
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { updateTerms } from "services/sbsReservations";

function TermsConditions(props) {
	const { details, handleReservationState } = props;
	const { terms } = details;
	const [termsState, setTermsState] = useState(terms);
	const { id } = useParams();
	const handleTermsState = () => {
		handleReservationState({ name: "terms", value: termsState });
	};
	const sudanManualReservationsManagePermission = useShowPermission({
		permission: "Sudan-Manual-Reservations-Manage",
	});
	const updateTermsAndConditions = async () => {
		const res = await updateTerms(id, {
			terms: termsState,
		});

		if (res.status == 200) {
			handleTermsState();
		} else {
			setTermsState(terms);
		}
	};

	return (
		<div id="reserv-terms-conditions">
			<div className="terms_conditions_header w-100 d-flex">
				<p>Terms & Conditions</p>
				<ShowForPermission permission="Sudan-Manual-Reservations-Manage">
					<button
						className="btn btn-info p-2 rounded-0"
						onClick={updateTermsAndConditions}
						disabled={details?.status?.id >= 4}
					>
						Save Changes
					</button>
				</ShowForPermission>
			</div>

			<div className="my-2 terms_conditions_filed">
				<TextAreaField
					hasLabel={false}
					placeholder={"Add Remarks"}
					value={termsState}
					rows={5}
					disabled={!sudanManualReservationsManagePermission}
					name="reasons"
					onChange={(e) => {
						setTermsState(e.target.value);
					}}
				/>
			</div>
		</div>
	);
}

export default TermsConditions;
