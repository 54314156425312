import HotelbedsLogo from "assets/images/hotelbeds.png";
import phone from "assets/images/phone.png";
import SafaLogo from "assets/images/safa_logo.png";
import { toursVoucher } from "assets/prints/toursVoucher";
import { ToursInvoicePrint } from "components/Printing/ToursInvoicePrint";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FetchToursVendor, getAllToursVoucher, getToursInvoice } from 'services/vendor';
import ToursBookingDetails from "./BookingDetails";
import ToursBookingSummary from "./BookingSummary";

export default function VendorToursView() {
	const [bookingDetails, setBookingDetails] = useState(null);
	const { id ,brn} = useParams();
	useEffect(() => {
		const fetchTour = async () => {
			const res = await FetchToursVendor(id,brn);
			if (res?.status === 200) {
				setBookingDetails(res.data?.tour);
			}
		};
		fetchTour();
		return () => {};
	}, []);
	//print invoice
	const downloadInvoice = async () => {
		const invoiceRes = await getToursInvoice(id,brn);
		const invoiceData = invoiceRes?.data;
		if (invoiceData) {
			const keyValuePair = {
				"[ref]": invoiceData?.brn,
				"[name]": invoiceData?.holder,
				"[agency_ref]": invoiceData?.agency_reference,
				"[booking_time]": moment(invoiceData?.created_at).format("DD/MM/YYYY"),
				"[payment_method]": invoiceData?.payment_method,
				"[total_amount]": `${invoiceData?.total_price} ${invoiceData?.currency}`,
				"[services]": invoiceData?.reservations
					?.map((reservation) => {
						return `
					<div class="border-dots" style="padding: 10px 0">
						<h6 class="fw-700">${reservation?.name}</h6>
						<p>From: ${moment(reservation?.start_date).format("DD/MM/YYYY")} (${moment(
							reservation?.start_date
						).format("dddd")})</p>
						<p>
							${reservation?.adults} Adults ${
							reservation?.children?.length > 0
								? reservation?.children?.length + "Children"
								: ""
						}
						</p>
						<hr class="border-0"/>
					</div>
					`;
					})
					.join(""),
				"[cancle_policy]": invoiceData?.reservations
					?.map((reservation) => {
						return reservation?.details?.modalities?.[0]?.rates?.[0]?.rateDetails?.[0]?.operationDates
							?.filter(
								(operationDate) =>
									moment(reservation?.start_date).format("YYYY-MM-DD") ===
									operationDate?.from
							)
							?.map((charge) => {
								return `<div class="d-flex border-dots " style="padding: 10px 0">
								<p class="col-6">${reservation?.name}</p>
								<p class="col-3" style="text-align: center">${moment(
									charge?.cancellationPolicies?.[0]?.dateFrom
								).format("DD/MM/YYYY hh:mm")}</p>
								<p class="col-3" style="text-align: center">${
									charge?.cancellationPolicies?.[0]?.amount
								} ${invoiceData?.currency}</p>
								<hr class="border-0"/>
							</div>
						`;
							})
							.join("");
					})
					.join(""),
			};

			let printVar = ToursInvoicePrint;
			for (const key in keyValuePair) {
				printVar = printVar.replace(key, keyValuePair[key]);
			}
			printVar = printVar.replace("[SafaLogo]", `${HotelbedsLogo}`);
			var popupWin = window.open(
				"",
				" ",
				"top=0,left=0,height=100%,width=auto"
			);
			popupWin.document.open();
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>

					</style>
					</head>
					<body onLoad="{()=>window.print()}">${printVar}</body>
					</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	const printVoucher = async () => {
		const vourcherRes = await getAllToursVoucher(id,brn);
		if (vourcherRes.status !== 200) return;
		const vourcherData = vourcherRes?.data?.vouchers;
		let printVar = "";

		vourcherData?.forEach((item) => {
			const details = item?.details;
			let childrenString = "";
			item?.children?.forEach((age, index) => {
				childrenString = childrenString.concat(
					`<span>${age}${
						index !== item?.children?.length - 1 ? ", " : ""
					}</span>`
				);
			});
			let remarkString = "";

			details?.comments?.forEach((remark) => {
				remarkString = remarkString.concat(`<p>${remark?.text}</p>`);
			});

			const keyValuePair = {
				"[reference_number_key]": "Reference Number",
				"[reference_number_value]": item?.reference,
				"[safa_reference_number_key]": "Safa Reference Number",
				"[safa_reference_number_value]": item?.safa_reference,
				"[name]": item?.name,
				"[passenger_name_key]": "Passenger Name",
				"[passenger_name_value]": item?.passenger_name,
				"[booking_date_key]": "Booking Date",
				"[booking_date_value]": moment(item?.booking_date).format("YYYY-MM-DD"),
				"[agency_Reference_key]": "Agency Reference",
				"[agency_Reference_value]": item?.agency_reference,
				"[from_key]": "From",
				"[from_value]": moment(item?.from_date).format("YYYY-MM-DD"),
				"[to_key]": "To",
				"[to_value]": moment(item?.from_date).format("YYYY-MM-DD"),
				"[ticket_type_key]": "Ticket Type",
				"[ticket_type_vaue]": item?.ticket_type,
				// "[service_time_key]": "Service Time",
				// "[service_time_value]": "--",
				"[adult_key]": "Adult",
				"[adult_value]": item?.pax?.adults,
				"[children_key]": "Children",
				"[children_value]": item?.pax?.children,
				"[children_ages_key]": "Children Ages",
				"[children_ages_value]":
					item?.pax?.children_ages?.length > 0
						? item?.pax?.children_ages
						: "--",
				"[remarks_key]": "Remarks",
				"[remarks_value]": remarkString,
				"[provider_name_value]": item?.provider?.name,
				"[provider_ref_value]": item?.provider?.reference
					? item?.provider?.reference
					: "--",
				"[supplier_name_value]": item?.supplier?.name,
				"[supplier_vat_value]": item?.supplier?.vatNumber,
			};

			printVar += toursVoucher;

			for (const key in keyValuePair) {
				printVar = printVar?.replace(key, keyValuePair[key]);
			}

			printVar = printVar.replace(
				"[logo]",
				`<img width={200} src="${SafaLogo}" alt="safa logo" />`
			);

			printVar = printVar.replace(
				"[phone_img]",
				`<img src="${phone}" alt="phone" />`
			);
		});

		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
					<head>
						<title> print Voucher</title>
					</head>
					<body>${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};
	return (
		<>
					<div className='me-5  transfer d-flex align-items-center justify-content-between'>
			<div className='Booking-Details-tab'>Booking Details</div>
			<div className="box-invoice-btn mb-2 pt-2">
				
				<button
					className="btn btn-invoice-vendor"
					onClick={downloadInvoice}
				>
					<i class="fas fa-download"></i> Invoice
				</button>
				<button
					className="btn btn-invoice-vendor"
					onClick={printVoucher}
				>
					<i class="fas fa-download"></i> Voucher
				</button>
				</div>
	</div>
			<div className="row bg-white tour-details transfer ">
				<div className="col-8">
					<ToursBookingDetails
						bookingDetails={bookingDetails}
						key={bookingDetails?.brn}
						setBookingDetails={setBookingDetails}
					/>
				</div>
				<div className="col-4">
					<ToursBookingSummary
						bookingDetails={bookingDetails}
						setBookingDetails={setBookingDetails}
					/>
				</div>
			</div>
		</>
	);
}
