import AddIcon from "assets/images/add.png";
import EditIcon from "assets/images/edit-2.png";

import ConfirmModal from "components/Modals/ConfirmModal";
import Pagination from "components/shared/Pagination";
import { getNotification } from "helpers/makeNotifications";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Switch from "react-switch";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
	changeStatusPackagePlan,
	listSubscriptionPackagePlan,
} from "services/companies";
import { ViewIcon } from "../../Attractions/Icons/viewIcon";

export default function SubscriptionPackagePlan() {
	// ** hooks

	const [isOpen, setIsOpen] = useState(false);
	const [selectPlanCountries, setSelectPlanCountries] = useState([]);
	const toggle = (countries = null) => {
		setIsOpen((prev) => !prev);
		setSelectPlanCountries(countries);
	};
	const history = useHistory();
	const [planList, setPlanList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		page: 1,
	});
	const statusStyle = (status) => {
		switch (status) {
			case "active":
				return "active-status";
			case "inactive":
				return "inactive-status";

			default:
				return null;
		}
	};

	useEffect(() => {
		async function FetchPackagePlanList() {
			const res = await listSubscriptionPackagePlan({ ...filter });
			debugger;
			setPlanList(res?.data?.data);

			setMeta(res?.data?.meta);
		}
		FetchPackagePlanList();
	}, [filter]);
	const today = moment();
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);

	const [statusPlan, setStatusPlan] = useState({
		id: null,
		status: null,
	});
	const toggleConfirm = (id = null, status = null) => {
		setStatusPlan({
			id,
			status,
		});
		setConfirmIsOpen(!confirmIsOpen);
	};

	const allPlan =
		planList.length > 0 &&
		planList.map((plan) => {
			return (
				<tr>
					<td>{plan?.title}</td>
					<td>
						{plan?.start_date
							? moment(plan?.start_date).format("DD/MM/YYYY")
							: ""}
					</td>
					<td>
						{plan?.end_date ? moment(plan?.end_date).format("DD/MM/YYYY") : ""}
					</td>
					<td>{plan?.duration ? `${plan?.duration} Days` : "-"}</td>

					<td>
						<div className="d-flex flex-column countries">
							<p>{plan?.countries[0]?.name ?? "All"}</p>
							{plan?.countries.length > 1 && (
								<span onClick={() => toggle(plan?.countries)}>
									<ViewIcon />
									View More
								</span>
							)}
						</div>
					</td>
					<td className="amount-td">
						{plan?.total_price ? Number(plan?.total_price)?.toFixed(2) : "-"}
					</td>
					<td>{plan?.currency}</td>

					<td>
						<span
							className={`${
								plan?.status === "active" ? "active-status" : "inactive-status"
							}`}
						>
							{plan?.status}
						</span>
					</td>
					<td>
						<div className="action mt-0 flex-row justify-content-end">
							{plan.status !== "canceled" && plan.status !== "expired" && plan.status === "inactive" &&  (
								<span
									role="button"
									onClick={() =>
										history.push(`/subscription-package-plan/edit/${plan.id}`)
									}
								>
									<img src={EditIcon} alt="edit icon" width={20} height={20} />
								</span>
							)}
							<div>
								{plan.status === "inactive" ? (
									<Switch
										height={24}
										width={45}
										handleDiameter={20}
										className="mx-2 align-middle"
										onChange={() => toggleConfirm(plan.id, "active")}
										checked={false}
									/>
								) : (
									<Switch
										height={24}
										width={45}
										handleDiameter={20}
										className="mx-2 align-middle"
										onChange={() => toggleConfirm(plan.id, "inactive")}
										checked={true}
										onColor="#1777fb"
									/>
								)}{" "}
							</div>
						</div>
					</td>
				</tr>
			);
		});
	const goTo = (page) => {
		setFilter({ page: page });
	};
	const changeStatus = async () => {
		const data = {
			status: statusPlan.status,
		};
		const res = await changeStatusPackagePlan(statusPlan.id, data);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilter({ ...filter });
			toggleConfirm();
		}
	};
	return (
		<div className="companies-subscription subscription-package-list">
			<div className="title">
				<h4>Subscription Plan List</h4>
				<ShowForPermission permission="Tourism-Company-Manage">
					<button
						className="btn btn-primary"
						onClick={() => history.push(`/subscription-package-plan/add`)}
					>
						<img src={AddIcon} alt="add" />
						Add Plan
					</button>
				</ShowForPermission>
			</div>

			{/* <ShowForPermission permission="Tourism-Company-View"> */}
			<div className="subscription-table">
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>valid form</th>
							<th>valid to</th>
							<th>Duration</th>
							<th>country</th>
							<th>amount</th>
							<th>currency</th>

							<th>plan status</th>
							<th width="10%">action</th>
						</tr>
					</thead>
					<tbody>{allPlan}</tbody>
				</table>
				<div className="px-2">
					{planList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}{" "}
				</div>
			</div>
			<Modal isOpen={isOpen} centered={true}>
				<ModalHeader toggle={toggle}>
					<span>Show Countries</span>
				</ModalHeader>

				<ModalBody>
					{selectPlanCountries?.length > 0 &&
						selectPlanCountries?.map((country, index) => (
							<h6>{country.name}</h6>
						))}
				</ModalBody>
			</Modal>
			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={
					statusPlan.status === "active"
						? "Active Packege Plan"
						: "Inactive Package Plan"
				}
				onConfirm={() => changeStatus()}
				type="comfirm"
				centered={true}
			/>
		</div>
	);
}
