import ShowForPermission from 'helpers/showForPermission';
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { deleteProvider } from "services/vendor";

function ProviderList({ fetchService, ListProviders, setProModle, ProModle }) {
	const { id } = useParams();
	const [prevdata, setprevdata] = useState({});
	const [Module, setModule] = useState({
		isOpen: false,
		data: {},
	});
	const toggle = () => {
		setModule({ isOpen: !Module.isOpen });
	};
	const deletepro = async (pro_id) => {
		const req = await deleteProvider({ id, pro_id });
		if (req.status === 200) {
			fetchService();
			store.addNotification({
				title: "Delete",
				message: req.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	useEffect(() => {
		fetchService();
	}, []);
	return (
		<>
			<div className=" table-responsive px-4 our-border w-100">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Provider</th>
							<th>Commission</th>
							<th>Commission Type</th>
							<th>Safa</th>
							<th>Provider</th>
							<th>Control</th>
						</tr>
					</thead>
					<tbody>
						{ListProviders?.length > 0 ? (
							<>
								{ListProviders?.map((res) => (
									<tr key={res.id}>
										<td>{res.provider}</td>
										<td>{res.commission}</td>
										<td>{res.commission_type}</td>
										<td>{res.safa_commission}</td>
										<td>{res.vendor_commission}</td>
										<td>
											<ShowForPermission permission="Vendors-Management-Manage">
												<button
													className="btn text-info py-0"
													onClick={() => {
														setProModle({
															...ProModle,
															type: "Edit",
															isOpen: !ProModle.isOpen,
															data: res,
														});
													}}
												>
													<i className="fas fa-edit"></i>
												</button>
												<button
													className="btn text-danger py-0"
													onClick={() => {
														deletepro(res.id);
													}}
												>
													<i className="fas fa-trash pe-1"></i>
												</button>
											</ShowForPermission>
										</td>
									</tr>
								))}
							</>
						) : (
							<tr>
								<td colSpan="6">
									<div className="product-build__product-no-data ">
										<i className="icx icx-eye-slash" aria-hidden="true"></i>
										<h4>{"No Providers Found For this Service"}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{/* 			<Model
				isOpen={Module.isOpen}
				type={"Edit"}
				title={"Edit Provider"}
				toggle={toggle}
				size="lg"
				hasFooter={false}
				hasHeader={true}
			>
				<EditProvider
					prevdata={prevdata}
					toggle={toggle}
					fetchService={fetchService}
				/>
			</Model> */}
		</>
	);
}

export default ProviderList;
