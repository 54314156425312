import Pagination from "components/shared/Pagination";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { commercialDistHotelList } from 'services/commercialDistribution';
import Locale from 'translations';
import CommercialDistHotelFilters from './Filters';


export default function CommercialDistHotelReservation() {
  const { backOffice, vendor } = Locale;
  const { id } = useParams();
  const [hotelsList, setHotelsList] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState({
    search: "",
    check_in: null,
    destination: null,
    status: null,
    page: 1,
  });

  function goTo(page) {
    setFilters({ ...filters, page: +page });
  };

  async function fetchHotelReservations() {
    const filtersData = {
      search: filters?.search,
      city_id: filters?.destination?.id,
      check_in: filters?.check_in ? moment(filters?.check_in).format("YYYY-MM-DD") : null,
      status: filters?.status?.label,
      page: filters?.page
    }
    const hotelsRes = await commercialDistHotelList(id, filtersData);
    if (hotelsRes?.status === 200) {
      setHotelsList(hotelsRes?.data?.data);
      setMeta(hotelsRes?.data?.meta);
    }
  }

  useEffect(() => {
    fetchHotelReservations();
  }, [filters]);

  const statusClasses = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return "text-light-yellow";
      case "confirmed":
      case "paid":
      case "approved":
        return "text-success";
      default:
        return "text-danger";
    }
  };




  return (
    <div>

      <CommercialDistHotelFilters filters={filters} setFilters={setFilters} />

      <div className=" table-responsive px-4 our-border">
        <table className="custom-table back-office-table table table-striped">
          <thead>
            <tr>
              <th>Ref.</th>
              <th>BRN</th>
              {/* <th>{vendor.agency}</th> */}
              <th>{vendor.hotelName}</th>
              <th>{vendor.destination}</th>
              <th>{vendor.roomCount}</th>
              <th>{vendor.bookingDate}</th>
              <th>{vendor.checkin}</th>
              <th>{vendor.status}</th>
            </tr>
          </thead>
          <tbody>
            {hotelsList?.length > 0 ?
              hotelsList?.map(hotel => {
                return (
                  <tr key={hotel?.id}>
                    <td>{hotel?.reference_number}</td>
                    <td>{hotel?.brn}</td>
                    <td>{hotel?.hotel?.name}</td>
                    <td>{hotel?.hotel?.city?.name}</td>
                    <td>{hotel?.rooms_count}</td>
                    <td>{hotel?.created_at ? moment(hotel?.created_at).format("DD-MM-YYYY") : "-"}</td>
                    <td>{hotel?.check_in ? moment(hotel?.check_in).format("DD-MM-YYYY") : "-"}</td>
                    <td>
                      <span className={`${statusClasses(hotel.status)} text-lowercase`}>
                        {hotel?.status}
                      </span>
                    </td>
                    <td>
                      <Link className="d-inline-block action--btn" to={`/manage-commercial-distribution/${id}/view/hotel/${hotel.id}/booking`}>
                        <i class="fas fa-chevron-right mx-1"></i>
                      </Link>
                    </td>
                  </tr>
                )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>
            }
          </tbody>
          {/* <tbody>{AllReservations}</tbody> */}
        </table>
        <div className="px-2">
          <Pagination info={meta} goTo={goTo} />
        </div>
      </div>
    </div>
  )
}
