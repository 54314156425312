import TextField from "components/Form/TextField/TextField";
import Model from "components/model";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";

function FilterCrmModel({
	isOpen,
	toggle,
	filter,
	setFilter,
	setIsFilter,
	travelAgentOptions,
}) {
	const statusOptions = [
		{ value: "pending", label: "Pending" },
		{ value: "rejected", label: "Rejected" },
		{ value: "approved", label: "Approved" },
		{ value: "canceled", label: "Canceled" },
		{ value: "paid", label: "Paid" },
		{ value: "confirmed", label: "Confirmed" },
		{ value: "expired", label: "Expired" },
	];
	const priorityOptions = [
		{ value: "high", label: "High" },
		{ value: "medium", label: "Medium" },
		{ value: "low", label: "Low" },
	];
	const dealStatusOptions = [
		{ value: "new", label: "New" },
		{ value: "contact", label: "Contact" },
		{ value: "package_change", label: "Package Change" },
		{ value: "confirmed_by_ta", label: "confirmed_by_ta" },
		{ value: "payment_done", label: "Payment Done" },
		{ value: "passport_submitted", label: "Passport Submitted" },
		{ value: "e_number", label: "E Number" },
		{ value: "visa_number", label: "Visa Number" },
	];

	return (
		<Model
			isOpen={isOpen}
			toggle={toggle}
			title={"Filter"}
			hasFooter={false}
			hasHeader={true}
		>
			<div className="row w-100">
				<div className="col-md-6">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"Request Date"}
						date={filter.request_date}
						onChangeDate={(e) => {
							setFilter({ ...filter, request_date: e });
						}}
					/>
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"Departure Date"}
						date={filter.departure_date}
						onChangeDate={(e) => {
							setFilter({ ...filter, departure_date: e });
						}}
					/>
					<div className="mt-1">
						<label className="wrap-label">Pax</label>
						<div className="warp-field">
							<TextField
								type={"number"}
								value={filter.pax.from}
								onChange={(e) => {
									setFilter({
										...filter,
										pax: { ...filter.pax, from: e.target.value },
									});
								}}
							/>
							<p>To</p>
							<TextField
								type={"number"}
								value={filter.pax.to}
								onChange={(e) => {
									setFilter({
										...filter,
										pax: { ...filter.pax, to: e.target.value },
									});
								}}
							/>
						</div>
					</div>
					<div className="mt-2">
						<label className="wrap-label">Paid</label>
						<div className="warp-field">
							<TextField
								type={"number"}
								extraTextPosition="append"
								// extraText={"EGP"}
								value={filter.paid.from}
								onChange={(e) => {
									setFilter({
										...filter,
										paid: { ...filter.paid, from: e.target.value },
									});
								}}
							/>
							<p>To</p>
							<TextField
								type={"number"}
								extraTextPosition="append"
								// extraText={"EGP"}
								value={filter.paid.to}
								onChange={(e) => {
									setFilter({
										...filter,
										paid: { ...filter.paid, to: e.target.value },
									});
								}}
							/>
						</div>
					</div>
					<div className="Selected-field">
						<SelectField
							label={"Deal Status"}
							placeholder={"Deal Status"}
							options={dealStatusOptions}
							value={filter.deal_status}
							onChange={(e) => {
								setFilter({
									...filter,
									deal_status: e,
								});
							}}
						/>
					</div>
					<div className="mt-2">
						<label className="wrap-label">Before Departure</label>
						<div className="warp-field">
							<DatePickerField
								disabled={filter.after_departure.date !== undefined}
								type="text"
								placeholder={"DD/MM/YYYY"}
								label={"bookingDate"}
								date={filter.before_departure.date}
								onChangeDate={(e) => {
									setFilter({
										...filter,
										before_departure: { ...filter.before_departure, date: e },
									});
								}}
							/>
							<div className="w-50">
								<SelectField
									label={"view"}
									placeholder={"Status"}
									disabled={filter.after_departure.date !== undefined}
									options={statusOptions}
									value={filter.before_departure.status}
									onChange={(e) => {
										setFilter({
											...filter,
											before_departure: {
												...filter.before_departure,
												status: e,
											},
										});
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="Selected-field">
						<SelectField
							label={"Travel Agent"}
							placeholder={"Travel Agent"}
							options={travelAgentOptions}
							value={filter.travel_agent}
							onChange={(e) => {
								setFilter({
									...filter,
									travel_agent: e,
								});
							}}
						/>
					</div>
					<div className="mt-1">
						<label className="wrap-label">Nights</label>
						<div className="warp-field">
							<TextField
								type={"number"}
								value={filter.night.from}
								onChange={(e) => {
									setFilter({
										...filter,
										night: { ...filter.night, from: e.target.value },
									});
								}}
							/>
							<p>To</p>
							<TextField
								type={"number"}
								value={filter.night.to}
								onChange={(e) => {
									setFilter({
										...filter,
										night: { ...filter.night, to: e.target.value },
									});
								}}
							/>
						</div>
					</div>
					<div className="mt-2">
						<label className="wrap-label">Total</label>
						<div className="warp-field">
							<TextField
								type={"number"}
								extraTextPosition="append"
								// extraText={"EGP"}
								value={filter.total.from}
								onChange={(e) => {
									setFilter({
										...filter,
										total: { ...filter.total, from: e.target.value },
									});
								}}
							/>
							<p>To</p>
							<TextField
								type={"number"}
								extraTextPosition="append"
								// extraText={"EGP"}
								value={filter.total.to}
								onChange={(e) => {
									setFilter({
										...filter,
										total: { ...filter.total, to: e.target.value },
									});
								}}
							/>
						</div>
						<div className="mt-2">
							<label className="wrap-label">Outstanding</label>
							<div className="warp-field">
								<TextField
									type={"number"}
									extraTextPosition="append"
									// extraText={"EGP"}
									value={filter.outstanding.from}
									onChange={(e) => {
										setFilter({
											...filter,
											outstanding: {
												...filter.outstanding,
												from: e.target.value,
											},
										});
									}}
								/>
								<p>To</p>
								<TextField
									type={"number"}
									extraTextPosition="append"
									// extraText={"EGP"}
									value={filter.outstanding.to}
									onChange={(e) => {
										setFilter({
											...filter,
											outstanding: {
												...filter.outstanding,
												to: e.target.value,
											},
										});
									}}
								/>
							</div>
						</div>
						<div className="Selected-field">
							<SelectField
								label={"Priority"}
								placeholder={"Priority"}
								options={priorityOptions}
								value={filter.priority}
								onChange={(e) => {
									setFilter({
										...filter,
										priority: e,
									});
								}}
							/>
						</div>
						<div className="mt-2">
							<label className="wrap-label">After Departure</label>
							<div className="warp-field">
								<DatePickerField
									disabled={filter.before_departure.date !== undefined}
									type="text"
									placeholder={"DD/MM/YYYY"}
									date={filter.after_departure.date}
									onChangeDate={(e) => {
										setFilter({
											...filter,
											after_departure: { ...filter.after_departure, date: e },
										});
									}}
								/>
								<div className="w-50">
									<SelectField
										placeholder={"Status"}
										disabled={filter.before_departure.date !== undefined}
										options={statusOptions}
										value={filter.after_departure.status}
										onChange={(e) => {
											setFilter({
												...filter,
												after_departure: {
													...filter.after_departure,
													status: e,
												},
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button
				onClick={() => {
					toggle();
					setIsFilter((prev) => !prev);
				}}
				className="btn my-4 btn-save-model"
			>
				Filter
			</button>
		</Model>
	);
}

export default FilterCrmModel;
