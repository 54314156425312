export default function CheckBox(props) {
	return (
		<>
			<input
				{...props}
				type="Checkbox"
				name={props.name}
				value={props.value}
				checked={props.checked}
				onChange={props.onChange}
			/>

			<label
				/* 	className="form-check-label pointer text-caption mx-2" */
				htmlFor="exampleCheck1"
			>
				{props.text}
			</label>
		</>
	);
}
