import { useHotelDispatch, useHotelState } from "context/hotelRequest";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { viewHotel } from "services/hotels";
import Locale from "translations";
import BasicInfo from "./BasicInfo/index";
import Reservations from "./ListReservations";

export default function HotelDetails() {
  let locale = localStorage.getItem("currentLocale") || "en";
  const { backOffice } = Locale;
  const { step, current } = useHotelState();
  const dispatchHotel = useHotelDispatch();
  const [activeTab, setActiveTab] = useState(step);
  const [state, setState] = useState({});
  const { id } = useParams();

  const history = useHistory();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      dispatchHotel({ type: "progress", current: tab, step: step });
    }
  };

  const fetchData = async (id) => {
    const res = await viewHotel(id);
    if (res.status === 200) {
      // dispatch({ type: "ReservationsDetails", payload: res.data });
      setState(res.data);
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id);
      dispatchHotel({
        type: "progress",
        progress: 0,
        step: "1",
        current: "1",
      });
    } else {
      dispatchHotel({
        type: "progress",
        progress: 0,
        step: "1",
        current: "1",
      });
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (current) {
      setActiveTab(current);
    } else {
      setActiveTab(step);
    }
  }, [current, step]);

  return (
    <div className="property platform-details">
      <div className=" text-black mb-3">
        <div className="">
          <div className="row m-0">
            <h1>{state?.hotel?.["name_" + locale]}</h1>
          </div>
        </div>
      </div>
      <div className="">
        <div className="text-black">
          <div
            className="d-flex route-block align-items-center justify-content-between"
            style={{ borderBottom: "1px solid #d9dde0" }}
          >
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === "1" ? "is-active" : ""} ${
                    +step > 1 ? "done" : ""
                  }`}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {backOffice.basicInfo}
                </NavLink>
              </NavItem>

              {/* <NavItem>
                <NavLink
                  className={`${activeTab === "2" ? "is-active" : ""}  ${
                    +step > 2 ? "done " : ""
                  } `}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {backOffice.rounds}
                </NavLink>
              </NavItem> */}

              <NavItem>
                <NavLink
                  className={`${activeTab === "3" ? "is-active" : ""} ${
                    +step > 3 ? "done " : ""
                  }`}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  {backOffice.reservations}
                </NavLink>
              </NavItem>
            </Nav>
            <div className="current-status text-muted">
              {backOffice.currentStatus}
              <span
                className={
                  state?.hotel?.is_active ? "text-success" : "text-danger"
                }
              >
                {" "}
                :{" "}
                {state?.hotel?.is_active == 1
                  ? backOffice.active
                  : backOffice.inActive}{" "}
              </span>
              {/* <button className="btn btn-link" title={backOffice.edit}>
                <i className="fas fa-edit"></i>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <BasicInfo state={state} />
          </TabPane>
          {/* <TabPane tabId="2">
            <RoundsDetails state={state} />
          </TabPane> */}
          <TabPane tabId="3">
            {/* hotelID={id} */}
            <Reservations />
          </TabPane>
        </TabContent>
      </div>

      <div className="row flex-row-reverse m-0 mt-3">
        <div className="col-md-1">
          <div
            className={`btn btn-sbs-secondary w-100`}
            onClick={history.goBack}
          >
            {backOffice.back}
          </div>
        </div>
      </div>
    </div>
  );
}
