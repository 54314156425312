import { useGlobalState } from "context/global";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Locale from "translations";

export default function CompaniesSubNav() {
	const { company_id } = useParams();
	const { pathname } = useLocation();
	const { companyName } = useGlobalState();
	const { translate_companies, Companies } = Locale;
	const { locale } = useGlobalState();

	return (
		<>
			<div className="my-2 all-tabs  ">
				<h2 className="font-weight-bold px-2">
					{companyName && companyName.hasOwnProperty("en")
						? companyName?.en
						: companyName?.ar}
				</h2>
			</div>
			<div className="d-flex my-2 border-light border-bottom tabs all-tabs">
				<NavLink
					exact={true}
					to={`/tourism-company/profile/${company_id}`}
					activeClassName="is-active"
					className=" bg-subNav  text-bold our-taps "
				>
					{Companies.profile}
				</NavLink>
				<NavLink
					exact={true}
					to={`/tourism-company/employees/${company_id}`}
					isActive={() => {
						return pathname.includes("/employee");
					}}
					activeClassName="is-active"
					className="text-bold bg-subNav  our-taps"
				>
					{Companies.employees}
				</NavLink>
				<NavLink
					exact={true}
					to={`/tourism-company/groups/${company_id}`}
					activeClassName="is-active"
					isActive={() => {
						return pathname.includes("/group");
					}}
					className="text-bold bg-subNav  our-taps"
				>
					{Companies.groups}
				</NavLink>
				<NavLink
					exact={true}
					to={`/tourism-company/branches/${company_id}`}
					activeClassName="is-active"
					className="text-bold bg-subNav our-taps"
				>
					{Companies.branches}
				</NavLink>
				<NavLink
					exact={true}
					to={`/tourism-company/${company_id}/wallet`}
					activeClassName="is-active"
					isActive={() => {
						return pathname.includes("/wallet");
					}}
					className="text-bold bg-subNav our-taps"
				>
					{translate_companies.wallet}
				</NavLink>
				{/* <NavLink
          exact={true}
          to={`/tourism-company/orders/${company_id}/in-progress`}
          activeClassName="active-companies-tourism"
          className="text-bold bg-subNav "
        >
          {translate_companies.orders}
        </NavLink> */}
				<NavLink
					exact={true}
					to={`/tourism-company/reservations/${company_id}`}
					activeClassName="is-active"
					isActive={() => {
						return pathname.includes("/reservation");
					}}
					className="text-bold bg-subNav our-taps"
				>
					{translate_companies.Reservations}
				</NavLink>
				<NavLink
					exact={true}
					to={`/tourism-company/${company_id}/subscription-plan`}
					activeClassName="is-active"
					isActive={() => {
						return pathname.includes("/subscription");
					}}
					className="text-bold bg-subNav our-taps"
				>
					Subscription Plan
				</NavLink>
			</div>
		</>
	);
}
