import React, { useEffect, useState } from "react";
import { paymentCards } from "services/wallet";

const useWallet = (id) => {
  const [debit, setDebit] = useState();
  const [credit, setCredit] = useState();
  const [paymentCard, setPaymentCard] = useState();

  useEffect(async () => {
    const res = await paymentCards(id);
    if (res?.status === 200) {
      setDebit(res.data?.data.debit?.amount);
      setCredit({
        amount: res.data?.data.credit?.amount,
        limit: res.data?.data.credit?.limit,
      });
      setPaymentCard({
        debit: res.data?.data.debit?.id,
        credit: res.data?.data.credit?.id,
      });
    }
  }, []);

  return {
    debit,
    credit,
    paymentCard,
  };
};

export default useWallet;
