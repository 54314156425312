import generateUniqueID from 'helpers/generateUniqueID';
import validate, { isFormValid } from 'helpers/validate';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { useHistory, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import { addPromoCity, editPromoCity, viewPromoCity } from 'services/promo';
import { formatPromoDataForAPI, formatPromoFromAPI } from '../helper/formatPromoDetails';
import PromoCityDetails from './City/Details';
import PromoCityDiscounts from './City/Discounts';
import PromoHotels from './Hotels';


export default function AddEditPromoCity() {
  const history = useHistory();
  const { id, status } = useParams();
  const isEditMode = id && status === "edit";
  const [activeTab, setActivetab] = useState(1);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  // hotel schema
  const initalHotelsObj = {
    id: generateUniqueID(),
    hotelName: null,
    priceFrom: null,
    hotelDiscount: null,
    bookingDate: null,
    travelDate: { from: moment().add(1, 'd'), to: moment().add(1, 'd'), },
  }
  // main promo state
  const [promoDataState, setPromoDataState] = useState({
    cityName: null,
    currency: null,
    cityImage: null,
    cityBanner: null,
    discounts: [{
      id: generateUniqueID(),
      countries: null,
      discount: null,
    }],
    hotels: [initalHotelsObj],
    publish: 0
  });

  // add new promo city
  async function addNewPromoCity(cityData) {
    const newCityRes = await addPromoCity(cityData);
    if (newCityRes?.status === 200) {
      history.push('/promo');
      store.addNotification({
        title: "Something Went Wrong!",
        message: newCityRes.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }
  // edit exist promo city 
  async function editCity(cityData) {
    const editCityRes = await editPromoCity(id, cityData);
    if (editCityRes?.status === 200) {
      history.push('/promo');
      store.addNotification({
        title: "Something Went Wrong!",
        message: editCityRes.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  function cityCheckErrors() {
    let submitCityErrors = {}
    let hasError = false;
    const validations = ["cityName", "currency", "cityImage", "cityBanner"];
    validations?.forEach(key => {
      const keyValidation = {
        ...validate(
          { name: key, value: promoDataState[key] },
          { required: true }
        )
      }
      submitCityErrors = {
        ...submitCityErrors,
        ...keyValidation
      }
      hasError = hasError ? true : keyValidation[key]?.required;
    });
    promoDataState?.discounts?.forEach((discount, index) => {
      Object.keys(discount).forEach(discountKey => {
        const keyValidation = {
          ...validate(
            { name: `${discountKey}-${index}`, value: promoDataState?.discounts[index][discountKey] },
            { required: true }
          )
        }
        if (discountKey !== "id") {
          submitCityErrors = {
            ...submitCityErrors,
            ...keyValidation
          }
          hasError = hasError ? true : keyValidation[`${discountKey}-${index}`]?.required;
        }
      })
    });
    return { submitCityErrors, hasError };
  }


  function hotelsCheckErrors() {
    let submitHotelsErrors = {}
    promoDataState?.hotels?.forEach((hotel, index) => {
      Object.keys(hotel).forEach(hotelKey => {
        if (hotelKey !== "id") {
          submitHotelsErrors = {
            ...submitHotelsErrors,
            ...validate(
              { name: `${hotelKey}-${index}`, value: promoDataState?.hotels[index][hotelKey] },
              { required: true }
            )
          }
        }
      })
    });
    return submitHotelsErrors;
  }

  function checkFormErrors() {
    const hotelsErrors = hotelsCheckErrors();
    const cityErrors = cityCheckErrors();
    setErrors({ ...cityErrors?.submitCityErrors, ...hotelsErrors });
    if (!cityErrors?.hasError && !isEditMode) {
      setActivetab(2)
    }
  }

  // on submit check errors
  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  // handle add and edit city
  useEffect(() => {
    // use helper function to format data will sent to api
    const cityData = formatPromoDataForAPI(promoDataState);
    // check if user will add new promo or edit exist promo
    if (isFormValid(errors) && !isEditMode) {
      addNewPromoCity(cityData);
    } else if (isFormValid(errors) && isEditMode) {
      editCity(cityData)
    }
  }, [isErrorLoaded]);



  // get promo details if in edit mode
  useEffect(() => {
    if (isEditMode) {
      async function getPromoDetails() {
        const promoDetailsRes = await viewPromoCity(id);
        if (promoDetailsRes?.status === 200) {
          const promoDetails = promoDetailsRes?.data?.data;
          // use helper function to format data coming from api
          setPromoDataState(() => formatPromoFromAPI(promoDetails))
        }
      }
      getPromoDetails();
    }
  }, [isEditMode]);



  return (
    <div className='promo-container'>
      <h1>{isEditMode ? "Edit City" : "Add New City"}</h1>
      <div className="nav-tab-container justify-content-between align-items-center">
        <div className='d-flex'>
          <button
            className={`nav-tab-item ${activeTab === 1 ? "active" : ""}`}
            onClick={() => setActivetab(1)}
          >
            <h2 className="px-2 m-0">City Details</h2>
          </button>
          <button
            className={`nav-tab-item ${activeTab === 2 ? "active" : ""} `}
            onClick={() => setActivetab(2)}
            disabled={cityCheckErrors()?.hasError}
          >
            <h2 className="px-2 m-0">Hotels</h2>
          </button>
        </div>
        {/* publish */}
        <div>
          {isEditMode ?
            <Switch
              height={24}
              width={45}
              handleDiameter={10}
              uncheckedIcon={false}
              checkedIcon={false}
              className="mx-2 align-middle"
              onColor="#00AAEB"
              onChange={() => {
                setPromoDataState({ ...promoDataState, publish: !promoDataState?.publish })
              }}
              checked={promoDataState?.publish}
            />
            :
            null
          }
        </div>
      </div>

      {/* page content */}
      <div>
        {activeTab === 1 ?
          <div className=''>
            <PromoCityDetails
              promoDataState={promoDataState}
              setPromoDataState={setPromoDataState}
              errors={errors}
              setErrors={setErrors}
              initalHotelsObj={initalHotelsObj}
              isEditMode={isEditMode}
            />
            <PromoCityDiscounts
              promoDataState={promoDataState}
              setPromoDataState={setPromoDataState}
              errors={errors}
              setErrors={setErrors}
              isEditMode={isEditMode}
            />
          </div>
          :
          null}

        {activeTab === 2 ?
          <PromoHotels
            promoDataState={promoDataState}
            setPromoDataState={setPromoDataState}
            errors={errors}
            setErrors={setErrors}
            initalHotelsObj={initalHotelsObj}
            isEditMode={isEditMode}
          /> :
          null}
      </div>

      {/* actions buttons */}
      <div className='d-flex justify-content-end my-3'>
        <button
          className='btn cancel mx-2'
          onClick={() => history.push('/promo')}
        >
          Back
        </button>
        {/* submit button */}
        <button
          className='accept-btn'
          onClick={submit}
          disabled={promoDataState?.hotels?.length === 0 || promoDataState?.discounts?.length === 0}
        >
          {isEditMode ? "Edit City" : "Add City"}
        </button>
      </div>

    </div>
  )
}
