import { useState } from "react";
import { Modal, Table } from "reactstrap";

function FareRulesModel({ isOpen, toggle, title, flightData }) {
	const flightsSearch = {};
	const [isCopy, setIsCopy] = useState();
	const [show, setshow] = useState(false);
	const toggelShow = () => {
		setshow(!show);
	};
	const copyText = (text) => {
		navigator.clipboard.writeText(text);
		setIsCopy(text);
	};

	const BeforeDepartureADT =
		flightData?.journey_0?.length > 0
			? flightData?.journey_0?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.ADT?.beforeDeparture?.length > 0 &&
								item?.ruleMap?.ADT?.beforeDeparture?.map((i) => (
									<tr>
										<td>{i.penaltyType ? i.penaltyType : "-"}</td>
										<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
									</tr>
								))
							);
						})
					);
			  })
			: null;

	const BeforeDepartureCHD =
		flightData?.journey_0?.length > 0
			? flightData?.journey_0?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.CHD?.beforeDeparture?.length > 0 &&
								item?.ruleMap?.CHD?.beforeDeparture?.map((i) => {
									return (
										<tr>
											<td>{i.penaltyType ? i.penaltyType : "-"}</td>
											<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
										</tr>
									);
								})
							);
						})
					);
			  })
			: null;
	const BeforeDepartureADT1 =
		flightData?.journey_1?.length > 0
			? flightData?.journey_1?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.ADT?.beforeDeparture?.length > 0 &&
								item?.ruleMap?.ADT?.beforeDeparture?.map((i) => (
									<tr>
										<td>{i.penaltyType ? i.penaltyType : "-"}</td>
										<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
									</tr>
								))
							);
						})
					);
			  })
			: null;

	const BeforeDepartureCHD1 =
		flightData?.journey_1?.length > 0
			? flightData?.journey_1?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.CHD?.beforeDeparture?.length > 0 &&
								item?.ruleMap?.CHD?.beforeDeparture?.map((i) => {
									return (
										<tr>
											<td>{i.penaltyType ? i.penaltyType : "-"}</td>
											<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
										</tr>
									);
								})
							);
						})
					);
			  })
			: null;

	const AfterDepartureADT =
		flightData?.journey_0?.length > 0
			? flightData?.journey_0?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.ADT?.afterDeparture?.length > 0 &&
								item?.ruleMap?.ADT?.afterDeparture?.map((i) => (
									<tr>
										<td>{i.penaltyType ? i.penaltyType : "-"}</td>
										<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
									</tr>
								))
							);
						})
					);
			  })
			: null;

	const AfterDepartureCHD =
		flightData?.journey_0?.length > 0
			? flightData?.journey_0?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.CHD?.afterDeparture?.length > 0 &&
								item?.ruleMap?.CHD?.afterDeparture?.map((i) => {
									return (
										<tr>
											<td>{i.penaltyType ? i.penaltyType : "-"}</td>
											<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
										</tr>
									);
								})
							);
						})
					);
			  })
			: null;
	const AfterDepartureADT1 =
		flightData?.journey_1?.length > 0
			? flightData?.journey_1?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.ADT?.afterDeparture?.length > 0 &&
								item?.ruleMap?.ADT?.afterDeparture?.map((i) => (
									<tr>
										<td>{i.penaltyType ? i.penaltyType : "-"}</td>
										<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
									</tr>
								))
							);
						})
					);
			  })
			: null;

	const AfterDepartureCHD1 =
		flightData?.journey_1?.length > 0
			? flightData?.journey_1?.map((journeyItem, index) => {
					return (
						journeyItem?.segments?.length > 0 &&
						journeyItem?.segments?.map((item) => {
							return (
								item?.ruleMap?.CHD?.afterDeparture?.length > 0 &&
								item?.ruleMap?.CHD?.afterDeparture?.map((i) => {
									return (
										<tr>
											<td>{i.penaltyType ? i.penaltyType : "-"}</td>
											<td>{i.amount ? i.amount + i.currencyCode : "-"}</td>
										</tr>
									);
								})
							);
						})
					);
			  })
			: null;
	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
				<div className="d-flex align-items-center border-bottom justify-content-between px-4 pt-3 w-100 ">
					<h3>{title}</h3>
					<a href="##" onClick={toggle} className="btn-x">
						<i class="fas fa-times"></i>
					</a>
				</div>
				<div className="p-4 fare-rules-model">
					<div className="row border border-top-0">
						<div className="col-4 px-0">
							<div className="bg-tap">
								<p style={{ fontSize: "16px" }}>
									{flightsSearch?.from?.code} - {flightsSearch?.to?.code}
								</p>
								<p
									onClick={toggelShow}
									className="pointer fw-bold mb-0"
									style={{ color: "#808080", fontSize: "14px" }}
								>
									PENALTIES
								</p>
								<i
									onClick={toggelShow}
									style={{ color: "#808080", fontSize: "12px" }}
									class="fas fa-angle-down pointer"
								></i>
							</div>
							<div className="rules-side-bar">
								{show && (
									<ul>
										{/* <li>02 DAY/TIME</li>
										<li>04 FLT APPLICATION</li>
										<li>05 ADVANCE RES/TKT</li>
										<li>06 MIN STAY 07 MAX STAY</li>
										<li>08 STOPOVERS</li>
										<li>09 TRANSFERS/RTGS</li>
										<li>10 COMBINABILITY</li>
										<li>11 BLACKOUTS</li>
										<li>12 SURCHARGES</li>
										<li>14 TVL RESTRICTION</li>
										<li>15 SALES RESTRICT</li> */}
										<li className="active"> PENALTIES</li>
										{/* <li>50 RULE APPLICATION</li> */}
									</ul>
								)}
							</div>
						</div>
						<div className="col-8 px-0">
							<div className="rules-tap">
								<p style={{ fontSize: "16px", color: "#0050A6" }}>
									For Adult & Child
								</p>
							</div>
							<div className="rules-side-bar">
								{show ? (
									<>
										<div className="rules-head">
											<div className="title-rules">
												<p>PENALTIES</p>
												<span>
													Click to view{" "}
													<strong style={{ color: "#0050A6" }}>
														Overview .
													</strong>{" "}
												</span>
											</div>
											{flightData?.penalties ? (
												<button
													className="btn btn-link"
													onClick={() => copyText(flightData?.penalties)}
												>
													COPY
												</button>
											) : null}
										</div>
										<p
											className="px-2"
											style={{ color: "#5C5C5C", fontSize: "12px" }}
										>
											{flightData?.penalties ? (
												flightData?.penalties
											) : (
												<p className="text-center py-4">Not Penalties Found</p>
											)}
										</p>
									</>
								) : (
									<>
										<div className="rules-head">
											<div className="title-rules">
												<p>PENALTIES</p>
												<span>
													The following content is for reference only, click to
													view{" "}
													<strong style={{ color: "#0050A6" }}>
														original .
													</strong>{" "}
												</span>
											</div>
										</div>
										<div className="table-container mx-2">
											<Table
												striped
												className="table-update p-2 bg-white table table-striped"
												style={{ verticalAlign: "center" }}
											>
												<thead style={{ backgroundColor: "#E6E6E6" }}>
													<tr>
														<th>{"Before Departure"}</th>
														<th>{"Charge"}</th>
													</tr>
												</thead>
												<tbody>
													{BeforeDepartureADT}
													{BeforeDepartureADT1}
												</tbody>
											</Table>
										</div>
										<div className="table-container mx-2">
											<Table
												striped
												className="table-update p-2 bg-white table table-striped"
												style={{ verticalAlign: "center" }}
											>
												<thead style={{ backgroundColor: "#E6E6E6" }}>
													<tr>
														<th>{"After Departure"}</th>
														<th>{"Charge"}</th>
													</tr>
												</thead>
												<tbody>
													{AfterDepartureADT}
													{AfterDepartureADT1}
												</tbody>
											</Table>
										</div>

										<p
											style={{
												fontSize: "16px",
												color: "#0050A6",
												marginInline: "9px",
											}}
										>
											For Child
										</p>
										<div className="table-container mx-2">
											<Table
												striped
												className="table-update p-2 bg-white table table-striped"
												style={{ verticalAlign: "center" }}
											>
												<thead style={{ backgroundColor: "#E6E6E6" }}>
													<tr>
														<th>{"Before Departure"}</th>
														<th>{"Charge"}</th>
													</tr>
												</thead>
												<tbody>
													{BeforeDepartureCHD}
													{BeforeDepartureCHD1}
												</tbody>
											</Table>
										</div>
										<div className="table-container mx-2">
											<Table
												striped
												className="table-update p-2 bg-white table table-striped"
												style={{ verticalAlign: "center" }}
											>
												<thead style={{ backgroundColor: "#E6E6E6" }}>
													<tr>
														<th>{"After Departure"}</th>
														<th>{"Charge"}</th>
													</tr>
												</thead>
												<tbody>
													{AfterDepartureCHD}
													{AfterDepartureCHD1}
												</tbody>
											</Table>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default FareRulesModel;
