import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import Locale from "translations";
import CustomModal from "../Modal";

export default function AddDirectVisaModal({
	addEditDirectVisa,
	addVisaModal,
	toggleAddVisaModal,
	countries,
	handleDirectVisaDocument,
	directVisaData,
	handleDirectVisaInputs,
	offlineSuppliers,
	selectedOfflineSupplier,
	setSelectedOfflineSupplier,
}) {
	const { backOffice, requests } = Locale;
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	console.log("errorserrors", errors);
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "passport_number", value: directVisaData.passport_number },
				{ required: true }
			),
			...validate(
				{ name: "national_id", value: directVisaData.national_id },
				{ required: true }
			),
			...validate(
				{ name: "name", value: directVisaData.name },
				{ required: true }
			),
			...validate(
				{ name: "agent_name", value: directVisaData.agent_name },
				{ required: true }
			),
			...validate(
				{ name: "visa", value: directVisaData.visa },
				{ required: true }
			),
			...validate(
				{ name: "price", value: directVisaData.price },
				{ required: true }
			),
			...validate(
				{ name: "travel_date", value: directVisaData.travel_date },
				{ required: true }
			),
			// ...validate(
			// 	{ name: "payment_status", value: directVisaData.payment_status },
			// 	{ required: true }
			// ),
			...validate(
				{
					name: "visa_document",
					value: directVisaData.visa_document,
				},
				{ required: true }
			),
		});
	};
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			addEditDirectVisa();
		}
	}, [isErrorLoaded]);

	return (
		<CustomModal
			header={
				directVisaData?.mode === "edit" ? "Edit EUP Barcode" : "Add EUP Barcode"
			}
			modalIsOpen={addVisaModal}
			toggle={() => toggleAddVisaModal()}
			size={"md"}
			centered={false}
		>
			<div className="row direct-visa-form">
				{/* passport number */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={backOffice.passport}
						label={backOffice.passport}
						id="passport_number"
						name="passport_number"
						value={directVisaData.passport_number}
						onChange={(e) => {
							handleDirectVisaInputs(e.target.value, "passport_number");

							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
						errors={errors?.passport_number}
						color={errors?.passport_number?.required ? "danger" : ""}
					/>
				</div>
				{/* national ID*/}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={"National ID"}
						label={"National ID"}
						id="national_id"
						name="national_id"
						value={directVisaData.national_id}
						onChange={(e) => {
							handleDirectVisaInputs(e.target.value, "national_id");

							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
						errors={errors?.national_id}
						color={errors?.national_id?.required ? "danger" : ""}
					/>
				</div>
				{/* name */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={"Name"}
						label={"Name"}
						id="name"
						name="name"
						value={directVisaData.name}
						onChange={(e) => {
							handleDirectVisaInputs(e.target.value, "name");
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
						errors={errors?.name}
						color={errors?.name?.required ? "danger" : ""}
					/>
				</div>
				{/* agent name */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={"Travel Agent "}
						label={"Travel Agent "}
						id="agent_name"
						name="agent_name"
						value={directVisaData.agent_name}
						onChange={(e) => {
							handleDirectVisaInputs(e.target.value, "agent_name");

							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
						errors={errors?.agent_name}
						color={errors?.agent_name?.required ? "danger" : ""}
					/>
				</div>

				{/* visa number */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={backOffice.visaNumber}
						label={backOffice.visaNumber}
						id="visa"
						name="visa"
						value={directVisaData.visa}
						onChange={(e) => {
							handleDirectVisaInputs(e.target.value, "visa");
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
						errors={errors?.visa}
						color={errors?.visa?.required ? "danger" : ""}
					/>
				</div>
				{/* price */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={requests.Price}
						label={requests.Price}
						id="price"
						name="price"
						value={directVisaData.price}
						onChange={(e) => {
							handleDirectVisaInputs(e.target.value, "price");

							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
						errors={errors?.price}
						color={errors?.price?.required ? "danger" : ""}
					/>
				</div>
				{/* travel date */}
				<div className="col-12">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"Travel Date"}
						onOutsideClick={false}
						date={directVisaData?.travel_date}
						onChangeDate={(e) => {
							handleDirectVisaInputs(e, "travel_date");

							setErrors({
								...errors,
								...validate(
									{ name: "travel_date", value: e },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.travel_date}
						color={errors?.travel_date?.required ? "danger" : ""}
					/>
				</div>

				<div className="col-12">
					<SelectField
						label={requests.SelectSupplier}
						placeholder={requests.SelectSupplier}
						name="supplier_id"
						options={offlineSuppliers}
						value={selectedOfflineSupplier}
						onChange={(selectedSupplier) => {
							setSelectedOfflineSupplier(selectedSupplier);
						}}
					/>
				</div>
				{/* payment status  */}
				{/* <div className="col-12">
					<SelectField
						placeholder={"payment status"}
						label={"payment status"}
						id="payment_status"
						name="payment_status"
						options={[
							{ label: "paid", value: "paid" },
							{ label: "unpaid", value: "unpaid" },
						]}
						value={directVisaData.payment_status?.label}
						onChange={(e) => {
							handleDirectVisaInputs(e, "payment_status");
							setErrors({
								...errors,
								...validate(
									{ name: "payment_status", value: e },
									{
										required: true,
									}
								),
							});
						}}
						errors={errors?.payment_status}
						color={errors?.payment_status?.required ? "danger" : ""}
					/>
				</div> */}
				{/* upload visa document */}
				<div className="col-12">
					<div
						className={`our-border-dashed my-2 ${
							errors?.visa_document?.required ? "border border-danger" : ""
						}`}
					>
						<h6 className="p-2 span">Upload Document</h6>

						<div
							className={`py-3  text-center d-flex flex-column justify-content-center `}
						>
							{/* display image */}
							{directVisaData.visa_document?.fileDisplay ? (
								<figure className="d-flex  justify-content-center ">
									{directVisaData.visa_document?.fileObject?.type !==
									"application/pdf" ? (
										<img
											src={`${directVisaData.visa_document?.fileDisplay}`}
											width={150}
											// height={150}
											alt="attachment"
										/>
									) : (
										<>
											<i className="fas fa-paperclip file-attach" />
											{directVisaData?.visa_document?.fileObject?.name}
										</>
									)}
								</figure>
							) : null}

							<button className="file-btn shape-border w-50 m-auto pointer">
								{"Add Attachment"}
								<input
									type="file"
									className="custom-file"
									name="visa_document"
									id="visa_document"
									accept="image/png, image/gif,image/jpeg,application/pdf"
									onChange={(e) => {
										handleDirectVisaDocument(e);
										setErrors({
											...errors,
											...validate(
												{ name: "visa_document", value: e },
												{
													required: true,
												}
											),
										});
									}}
								/>
							</button>
						</div>
					</div>
				</div>

				<div className="col-12">
					<button
						class="btn companies-btn w-100 my-3"
						// disabled={
						// 	!directVisaData.passport_number || !directVisaData.nationality
						// }
						onClick={submit}
					>
						{directVisaData?.mode === "edit" ? "Edit" : "Add"}
					</button>
				</div>
			</div>
		</CustomModal>
	);
}
