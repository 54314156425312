import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useEffect, useRef, useState } from "react";
import { fetchCompany, fetchSeason } from "services/lookups";
import {
	Add_Commission,
	editCommission,
	viewPlatform,
} from "services/platform";
import Locale from "translations";

import { useGlobalState } from "context/global";
import { store } from "react-notifications-component";
const PlatformCommissionInfo = ({
	index,
	CommissionsSelect,
	addCommission,
	SetCommissionsList,
	CommissionsList,
	ModelCommissions,
	CommissionsSelectIndex,
	PlatformID,
	countries,
	AllSeasons,
	title,
}) => {
	const { backOffice } = Locale;
	const { locale } = useGlobalState();

	const [commissionState, setCommissionState] = useState({
		platformName: PlatformID
			? CommissionsSelect?.name
			: CommissionsSelect?.name?.en,
		commissionType: CommissionsSelect?.type,
		reservationType: CommissionsSelect?.reservation_type,
		selectors: "",
		baseCommission: CommissionsSelect?.base_commission,
		safaCommission: CommissionsSelect?.safa_commission,
		platformCommission: CommissionsSelect?.platform_commission,
		applyCommission: CommissionsSelect?.apply_type,
	});

	const firstRender = useRef(false);

	const [season, setSeason] = useState([]);
	const [Countries, setCountries] = useState([]);
	const [Seasons, setSeasons] = useState([]);
	const [HotelLevel, setHotelLevel] = useState([]);
	const [Companies, setCompanies] = useState([]);
	const [Company, setCompany] = useState([]);

	const [CommissionType, setCommissionType] = useState();
	const [ApplyCommission, setApplyCommission] = useState();

	const [PercentageError, setPercentageError] = useState(false);

	const [CountriesWithALL, setCountriesWithALL] = useState([]);

	const [ReservationType, setReservationType] = useState();

	// Fetch season
	useEffect(() => {
		let newCountries = [];
		newCountries.push({
			code: "all",
			value: 0,
			label: "All",
		});
		countries.map((country) => newCountries.push(country));

		setCountriesWithALL(newCountries);

		if (ModelCommissions === "edit") {
			getCommissionType(CommissionsSelect?.type);
			getApplyCommission(CommissionsSelect?.apply_type);
			getReservationType(CommissionsSelect?.reservation_type);
			getSelectors(CommissionsSelect?.selectors);
		}

		async function fetchLookups() {
			const res = await fetchSeason();
			let newFormat = [];
			let newFormatCompany = [];

			newFormat.push({
				value: 0,
				label: "All",
			});
			newFormatCompany.push({
				value: 0,
				label: "All",
			});
			const resCompany = await fetchCompany();
			newFormat.push({
				value: 0,
				label: "All",
			});

			const format = res.seasons.map((t) => {
				newFormat.push({
					value: t.id,
					label: t.name,
				});
			});

			const formatCompany = resCompany?.data.map((t) => {
				newFormatCompany.push({
					value: t.id,
					label: t.name[locale],
				});
			});

			setSeason(newFormat);
			setCompany(newFormatCompany);
		}
		fetchLookups();
	}, []);

	const getCommissionType = (type) => {
		let Returned = "";

		switch (type) {
			case "base":
				Returned = {
					value: "base",
					label: "Base",
				};
				break;
			case "fixed":
				Returned = {
					value: "fixed",
					label: "Fixed",
				};
				break;
			default:
				Returned = {
					value: "",
					label: "",
				};
				break;
		}

		setCommissionType(Returned);

		return Returned;
	};
	const getApplyCommission = (type) => {
		let Returned = "";

		switch (type) {
			case "add":
				Returned = {
					value: "add",
					label: "Add",
				};
				break;
			case "deduct":
				Returned = {
					value: "deduct",
					label: "Deduct",
				};
				break;
			default:
				Returned = {
					value: "",
					label: "",
				};
				break;
		}

		setApplyCommission(Returned);

		return Returned;
	};

	const getReservationType = (type) => {
		let Returned = "";

		switch (type) {
			case "hotel":
				Returned = {
					value: "hotel",
					label: "Hotel",
				};
				break;
			case "transfer":
				Returned = {
					value: "transfer",
					label: "Transfer",
				};
				break;
			case "flight":
				Returned = {
					value: "flight",
					label: "Flight",
				};
				break;
			case "tour":
				Returned = {
					value: "tour",
					label: "Tour",
				};
				break;
			case "package":
				Returned = {
					value: "package",
					label: "Package",
				};
				break;
			default:
				Returned = {
					value: "",
					label: "",
				};
				break;
		}

		setReservationType(Returned);

		return Returned;
	};

	const getSelectors = (selector) => {
		let arr = [];
		Object.entries(selector).map((item) => {
			arr.push(getSelectorItem(item[0], item[1]));
		});

		setSelectedOptions(arr);

		if (arr.length > 0) {
			setIsOpen(true);
		}

		setCommissionState({
			...commissionState,
			selectors: arr,
		});
	};

	const getSelectorItem = (selector, values) => {
		let Returned = "";
		switch (selector) {
			case "country":
				Returned =
					values !== ""
						? {
								value: 1,
								label: "Country",
						  }
						: "";
				setCountriesFormat(values);
				break;
			case "season":
				Returned =
					values !== ""
						? {
								value: 2,
								label: "Season",
						  }
						: "";
				setSeasonsFormat(values);
				break;
			case "hotel":
				Returned =
					values !== ""
						? {
								value: 3,
								label: "Hotel Level",
						  }
						: "";
				setHotelLevelFormat(values);
				break;
			case "company":
				Returned =
					values !== ""
						? {
								value: 3,
								label: "Company",
						  }
						: "";
				setCompanyFormat(values);
				break;
			default:
				Returned = {
					value: "",
					label: "",
				};
				break;
		}

		return Returned;
	};

	const setCountriesFormat = (countriesInSelector) => {
		if (countriesInSelector !== "") {
			if (countriesInSelector === "all") {
				let arr = [];
				arr.push({
					code: "all",
					value: 0,
					label: "All",
				});
				setCountries(arr);
			} else {
				let arr = [];
				if (PlatformID) {
					countriesInSelector.map((country) => {
						let obj = {
							code: country.country_code,
							value: country.country_id,
							label: country.name?.en,
						};
						arr.push(obj);
					});
				} else {
					countriesInSelector.map((country) => {
						arr.push(countries.find((item) => item.value === country));
					});
				}
				setCountries(arr);
			}
		}
	};

	const setSeasonsFormat = (seasonsInSelector) => {
		if (seasonsInSelector !== "") {
			if (seasonsInSelector === "all") {
				let arr = [];
				arr.push({
					value: 0,
					label: "All",
				});
				setSeasons(arr);
			} else {
				let arr = [];

				if (PlatformID) {
					seasonsInSelector.map((seasonItem) => {
						let obj = {
							label: seasonItem.name.en,
							value: seasonItem.id,
						};
						arr.push(obj);
					});
				} else {
					seasonsInSelector.map((seasonItem) => {
						arr.push(AllSeasons.find((item) => item.value === seasonItem));
					});
				}

				setSeasons(arr);
			}
		}
	};

	const setHotelLevelFormat = (hotelLevel) => {
		if (hotelLevel !== "") {
			if (hotelLevel === "all") {
				let arr = [];
				arr.push({
					value: 0,
					label: "All",
				});
				setHotelLevel(arr);
			} else {
				let arr = [];

				if (PlatformID) {
					hotelLevel.map((level) => {
						let obj = {
							label: level.id,
							value: level.id,
						};
						arr.push(obj);
					});
				} else {
					hotelLevel.map((level) => {
						arr.push(HotelLevelCommission.find((item) => item.value === level));
					});
				}

				setHotelLevel(arr);
			}
		}
	};
	const setCompanyFormat = (companyInSelector) => {
		if (companyInSelector !== "") {
			if (companyInSelector === "all") {
				let arr = [];
				arr.push({
					value: 0,
					label: "All",
				});
				setCompanies(arr);
			} else {
				let arr = [];

				if (PlatformID) {
					companyInSelector.map((companyItem) => {
						let obj = {
							label: companyItem.name.en,
							value: companyItem.id,
						};
						arr.push(obj);
					});
				}
				// else{
				//   companyInSelector.map(seasonItem => {
				//     arr.push(AllSeasons.find(item => item.value === seasonItem));
				//   });
				// }

				setCompanies(arr);
			}
		}
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setCommissionState({ ...commissionState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				if (e.value !== null) {
					let arr = [];
					if (value.find((item) => item.code === "all")) {
						arr.push(value.find((item) => item.code === "all"));
						setCountries(arr);
					} else {
						setCountries(value);
					}
				} else {
					setCountries(value);
				}

				setCommissionState({
					...commissionState,
					city: "",
					[name]: value,
				});
			} else setCommissionState({ ...commissionState, [name]: value });
		}
	};

	const [isOpen, setIsOpen] = useState(false);
	const Selectors = [
		{
			value: 1,
			label: "Country",
		},
		{
			value: 2,
			label: "Season",
		},
		{
			value: 3,
			label: "Hotel Level",
		},
		{
			value: 4,
			label: "Company",
		},
	];

	const SelectorsWithoutHotel = [
		{
			value: 1,
			label: "Country",
		},
		{
			value: 2,
			label: "Season",
		},
	];
	const SelectorsPackage = [
		{
			value: 1,
			label: "Country",
		},
		{
			value: 3,
			label: "Company",
		},
	];

	const HotelLevelCommission = [
		{
			value: 0,
			label: "All",
		},
		{
			value: 1,
			label: "1",
		},
		{
			value: 2,
			label: "2",
		},
		{
			value: 3,
			label: "3",
		},
		{
			value: 4,
			label: "4",
		},
		{
			value: 5,
			label: "5",
		},
	];

	const commissionTypes = [
		{
			value: "base",
			label: "Base",
		},
		// {
		//   value: 'fixed',
		//   label: "Fixed",
		// }
	];

	const applyCommission = [
		{
			value: "add",
			label: "Add",
		},
		{
			value: "deduct",
			label: "Deduct",
		},
	];

	const reservationType = [
		{
			value: "hotel",
			label: "Hotel",
		},
		{
			value: "tour",
			label: "Tour",
		},
		{
			value: "transfer",
			label: "Transfer",
		},
		{
			value: "flight",
			label: "Flight",
		},
		{
			value: "package",
			label: "Package",
		},
	];

	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectedCommissionType, setSelectedCommissionType] = useState("%");
	const [selectedReservationType, setSelectedReservationType] = useState(false);

	const addNewCommission = async () => {
		let CommissionsListArr = "";
		if (ModelCommissions === "edit") {
			if (PlatformID !== null) {
				let clonedCommissions = [...CommissionsList];
				clonedCommissions.splice(CommissionsSelectIndex, 1);

				CommissionsListArr = clonedCommissions;
				debugger;
				let payload = {
					name: {
						en: commissionState.platformName,
						ar: commissionState.platformName,
					},
					type: CommissionType.value,
					base_commission: commissionState.baseCommission,
					safa_commission: commissionState.safaCommission,
					platform_commission: commissionState.platformCommission,
					apply_type: ApplyCommission.value,

					reservation_type: ReservationType.value,
					// selectors: {
					// 	country: Countries.find((item) => item.value === 0)
					// 		? "all"
					// 		: Countries.length > 0
					// 		? Countries.map((item) => item.value)
					// 		: "",
					// 	hotel: HotelLevel.find((item) => item.value === 0)
					// 		? "all"
					// 		: HotelLevel.length > 0
					// 		? HotelLevel.map((item) => item.value)
					// 		: "",
					// 	season: Seasons.find((item) => item.value === 0)
					// 		? "all"
					// 		: Seasons.length > 0
					// 		? Seasons.map((item) => item.value)
					// 		: "",
					// 	company: Companies.find((item) => item.value === 0)
					// 		? "all"
					// 		: Companies.length > 0
					// 		? Companies.map((item) => item.value)
					// 		: "",
					// },
					selectors: {
						country:
							selectedOptions.filter((item) => item.label === "Country")
								?.length > 0
								? Countries.find((item) => item.value === 0)
									? "all"
									: Countries.length > 0
									? Countries.map((item) => item.value)
									: ""
								: null,
						hotel:
							selectedOptions.filter((item) => item.label === "Hotel Level")
								?.length > 0
								? HotelLevel.find((item) => item.value === 0)
									? "all"
									: HotelLevel.length > 0
									? HotelLevel.map((item) => item.value)
									: ""
								: null,
						season:
							selectedOptions.filter((item) => item.label === "Season")
								?.length > 0
								? Seasons.find((item) => item.value === 0)
									? "all"
									: Seasons.length > 0
									? Seasons.map((item) => item.value)
									: ""
								: null,
						company:
							selectedOptions.filter((item) => item.label === "Company")
								?.length > 0
								? Companies.find((item) => item.value === 0)
									? "all"
									: Companies.length > 0
									? Companies.map((item) => item.value)
									: ""
								: null,
					},
				};

				const res = await editCommission(
					PlatformID,
					CommissionsSelect.id,
					payload
				);
				if (res.status === 200) {
					store.addNotification({
						title: "Done!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					const resplatform = await viewPlatform(PlatformID);
					SetCommissionsList(resplatform?.data?.platform?.commissions);
					addCommission(index);
				}
			} else {
				let clonedCommissions = [...CommissionsList];
				clonedCommissions.splice(CommissionsSelectIndex, 1);

				CommissionsListArr = clonedCommissions;

				let payload = {
					name: {
						en: commissionState.platformName,
						ar: commissionState.platformName,
					},
					type: CommissionType.value,
					apply_type: ApplyCommission.value,
					base_commission: commissionState.baseCommission,
					safa_commission: commissionState.safaCommission,
					platform_commission: commissionState.platformCommission,
					reservation_type: ReservationType.value,
					selectors: {
						country: Countries.find((item) => item.value === 0)
							? "all"
							: Countries.length > 0
							? Countries.map((item) => item.value)
							: "",
						hotel: HotelLevel.find((item) => item.value === 0)
							? "all"
							: HotelLevel.length > 0
							? HotelLevel.map((item) => item.value)
							: "",
						season: Seasons.find((item) => item.value === 0)
							? "all"
							: Seasons.length > 0
							? Seasons.map((item) => item.value)
							: "",
						company: Companies.find((item) => item.value === 0)
							? "all"
							: Companies.length > 0
							? Companies.map((item) => item.value)
							: "",
					},
				};

				CommissionsListArr.push(payload);
				SetCommissionsList(CommissionsListArr);
				addCommission(index);
			}
		} else {
			if (PlatformID !== null) {
				CommissionsListArr = CommissionsList;

				let payload = {
					name: {
						en: commissionState.platformName,
						ar: commissionState.platformName,
					},
					type: CommissionType.value,
					apply_type: ApplyCommission.value,
					base_commission: commissionState.baseCommission,
					safa_commission: commissionState.safaCommission,
					platform_commission: commissionState.platformCommission,
					reservation_type: ReservationType.value,
					selectors: {
						country: Countries.find((item) => item.value === 0)
							? "all"
							: Countries.length > 0
							? Countries.map((item) => item.value)
							: "",
						hotel: HotelLevel.find((item) => item.value === 0)
							? "all"
							: HotelLevel.length > 0
							? HotelLevel.map((item) => item.value)
							: "",
						season: Seasons.find((item) => item.value === 0)
							? "all"
							: Seasons.length > 0
							? Seasons.map((item) => item.value)
							: "",
						company: Companies.find((item) => item.value === 0)
							? "all"
							: Companies.length > 0
							? Companies.map((item) => item.value)
							: "",
					},
				};

				const res = await Add_Commission(PlatformID, payload);
				if (res.status === 200) {
					store.addNotification({
						title: "Done!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					const resplatform = await viewPlatform(PlatformID);
					SetCommissionsList(resplatform?.data?.platform?.commissions);
					addCommission(index);
				}
			} else {
				CommissionsListArr = CommissionsList;

				let objectSubmitted = {
					name: {
						en: commissionState.platformName,
						ar: commissionState.platformName,
					},
					type: CommissionType.value,
					base_commission: commissionState.baseCommission,
					safa_commission: commissionState.safaCommission,
					platform_commission: commissionState.platformCommission,
					apply_type: commissionState.applyCommission.value,
					reservation_type: ReservationType.value,
					selectors: {
						country: Countries.find((item) => item.value === 0)
							? "all"
							: Countries.length > 0
							? Countries.map((item) => item.value)
							: "",
						hotel: HotelLevel.find((item) => item.value === 0)
							? "all"
							: HotelLevel.length > 0
							? HotelLevel.map((item) => item.value)
							: "",
						season: Seasons.find((item) => item.value === 0)
							? "all"
							: Seasons.length > 0
							? Seasons.map((item) => item.value)
							: "",
						company: Companies.find((item) => item.value === 0)
							? "all"
							: Companies.length > 0
							? Companies.map((item) => item.value)
							: "",
					},
				};

				CommissionsListArr.push(objectSubmitted);
				SetCommissionsList(CommissionsListArr);
				addCommission(index);
			}
		}
	};

	useEffect(() => {
		if (firstRender.current) {
			if (
				commissionState.platformCommission !== "" &&
				Number(commissionState.safaCommission) +
					Number(commissionState.platformCommission) ===
					100
			) {
				setPercentageError(false);
			} else {
				setPercentageError(true);
			}
		}
		firstRender.current = true;
	}, [commissionState.platformCommission, commissionState.safaCommission]);
	console.log("commissionStatecommissionState", commissionState);

	return (
		<>
			<div className="row custom--row">
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.commissionName}
						placeholder={backOffice.commissionName}
						name="commission_name"
						value={commissionState.platformName}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								platformName: e.target.value,
							});
						}}
					/>
				</div>
				<div className="col-md-4">
					<SelectField
						label={backOffice.commissionType}
						placeholder={backOffice.select + " " + backOffice.commissionType}
						options={commissionTypes}
						name="commission_type"
						value={CommissionType}
						onChange={(e) => {
							handleChange({ name: "commission_type", value: e });
							setCommissionState({
								...commissionState,
								commissionType: e,
							});
							setCommissionType(e);
							setSelectedCommissionType(
								e.value == "fixed" ? backOffice.SAR : "%"
							);
						}}
					/>
				</div>

				<div className="col-md-4">
					<SelectField
						label={backOffice.reservationType}
						placeholder={backOffice.select + " " + backOffice.reservationType}
						options={reservationType}
						name="reservation_type"
						value={ReservationType}
						onChange={(e) => {
							handleChange({ name: "reservation_type", value: e });
							setCommissionState({
								...commissionState,
								reservationType: e,
							});
							setReservationType(e);
							setSelectedReservationType(e.value === "trans" ? true : false);
						}}
					/>
				</div>
			</div>
			<div className="row custom--row">
				<div className="col-md-12">
					<SelectField
						multi
						label={backOffice.selectors}
						placeholder={backOffice.select + " " + backOffice.selectors}
						options={
							selectedReservationType
								? SelectorsWithoutHotel
								: ReservationType?.value == "package" ||
								  ReservationType?.value == "tour"
								? SelectorsPackage
								: Selectors
						}
						name="selectors"
						value={commissionState.selectors}
						onChange={(e) => {
							setIsOpen(true);
							setCommissionState({
								...commissionState,
								selectors: e,
							});
							setSelectedOptions(e);
							//selectedOptions.push(e)
						}}
					/>
				</div>
			</div>

			{isOpen == true && selectedOptions?.length > 0 ? (
				<div
					className="row custom--row p-2"
					id="selectorsConfig"
					style={{ backgroundColor: "#EDF2F7" }}
				>
					<div className="col-12 text-center gray h5 mb-0">
						{backOffice.selectorsConfiguration}
					</div>
					{selectedOptions.find((item) => item.label === "Country") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.country}
								placeholder={backOffice.select + " " + backOffice.country}
								options={CountriesWithALL}
								name="countries"
								value={Countries}
								onChange={(e) => {
									handleChange({ name: "country", value: e });
								}}
							/>
						</div>
					) : null}
					{selectedOptions.find((item) => item.label === "Season") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.season}
								placeholder={backOffice.select + " " + backOffice.season}
								options={season}
								value={Seasons}
								name="season"
								onChange={(e) => {
									handleChange({ name: "season", value: e });
									//setSeasons(e.value)
									if (e !== null) {
										let arr = [];
										if (e.find((item) => item.value === 0)) {
											arr.push(e.find((item) => item.value === 0));
											setSeasons(arr);
										} else {
											setSeasons(e);
										}
									} else {
										setSeasons(e);
									}
								}}
							/>
						</div>
					) : null}
					{selectedOptions.find((item) => item.label === "Hotel Level") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.hotelLevel}
								placeholder={backOffice.select + " " + backOffice.hotelLevel}
								options={HotelLevelCommission}
								name="hotelLevel"
								value={HotelLevel}
								onChange={(e) => {
									handleChange({ name: "hotelLevel", value: e });
									//setHotelLevel(e.value);
									if (e !== null) {
										let arr = [];
										if (e.find((item) => item.value === 0)) {
											arr.push(e.find((item) => item.value === 0));
											setHotelLevel(arr);
										} else {
											setHotelLevel(e);
										}
									} else {
										setHotelLevel(e);
									}
								}}
							/>
						</div>
					) : null}
					{selectedOptions.find((item) => item.label === "Company") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.company}
								placeholder={backOffice.select + " " + backOffice.company}
								options={Company}
								name="company"
								value={Companies}
								onChange={(e) => {
									handleChange({ name: "company", value: e });
									//setHotelLevel(e.value);
									if (e !== null) {
										let arr = [];
										if (e.find((item) => item.value === 0)) {
											arr.push(e.find((item) => item.value === 0));
											setCompanies(arr);
										} else {
											setCompanies(e);
										}
									} else {
										setCompanies(e);
									}
								}}
							/>
						</div>
					) : null}
				</div>
			) : null}
			<div className="row custom--row">
				<div className="col-md-4">
					<SelectField
						label={backOffice.applyCommission}
						placeholder={backOffice.select + " " + backOffice.applyCommission}
						options={applyCommission}
						name="apply_commission"
						value={ApplyCommission}
						onChange={(e) => {
							handleChange({ name: "apply_commission", value: e });
							setCommissionState({
								...commissionState,
								applyCommission: e,
							});
							setApplyCommission(e);
						}}
					/>
				</div>
			</div>

			<div className="row custom--row">
				<div className="col-md-4">
					<TextField
						type="number"
						label={
							selectedCommissionType === "%"
								? backOffice.baseCommission
								: backOffice.fixedCommission
						}
						placeholder={
							selectedCommissionType === "%"
								? backOffice.baseCommission
								: backOffice.fixedCommission
						}
						name="base_commission"
						value={commissionState.baseCommission}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								baseCommission: e.target.value,
							});
						}}
						extraTextPosition="append"
						extraText={selectedCommissionType}
						color={commissionState.baseCommission > 100 ? "danger" : ""}
					/>
					{commissionState.baseCommission > 100 ? (
						<p className="text-danger">*Must be equal or less than 100</p>
					) : null}
				</div>

				<div className="col-md-4">
					<TextField
						type="number"
						label={backOffice.safaCommission}
						placeholder={backOffice.safaCommission}
						name="safa_commission"
						value={commissionState.safaCommission}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								safaCommission: e.target.value,
							});
						}}
						color={PercentageError ? "danger" : ""}
						extraTextPosition="append"
						extraText="%"
					/>
				</div>

				<div className="col-md-4">
					<TextField
						type="number"
						label={backOffice.platformCommission}
						placeholder={backOffice.platformCommission}
						name="platform_commission"
						value={commissionState.platformCommission}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								platformCommission: e.target.value,
							});
						}}
						color={PercentageError ? "danger" : ""}
						extraTextPosition="append"
						extraText="%"
					/>
				</div>
			</div>
			{PercentageError ? (
				<p className="text-danger">
					SAFA COMMISSION + PLATFORM COMMISSION Must equal 100
				</p>
			) : null}
			<div className="row custom--row mt-2 flex-row-reverse">
				<div className="col-md-3">
					<button
						disabled={
							(commissionState.selectors !== null ||
								commissionState.selectors !== "") &&
							(Countries?.length > 0 ||
								Companies?.length > 0 ||
								HotelLevel?.length > 0) &&
							commissionState.baseCommission !== undefined &&
							commissionState.safaCommission !== undefined &&
							commissionState.platformCommission !== undefined &&
							commissionState.baseCommission <= 100 &&
							!PercentageError
								? false
								: true
						}
						className="btn btn-sbs-primary w-100"
						onClick={addNewCommission}
					>
						{title == "add" ? ` ${backOffice.add}` : `${backOffice.edit}`}
					</button>
				</div>
			</div>
		</>
	);
};

export default PlatformCommissionInfo;
