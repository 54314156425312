import SelectField from "components/shared/SelectField";
import { getNotification } from "helpers/makeNotifications";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { listCurrencies } from "services/multiWallets";
import { addVendorWallet, listVendorWallets } from "services/vendor";
import Locale from "translations";
import Wallet from "./Wallet";
import PaymentPopup from "./ui/PaymentPopup";
import ViewTransactionPopup from "./ui/ViewTransactionPopup";

export default function VendorWallet({ isOpenAdd, toggleAdd }) {
	const { id } = useParams();
	const [listWallets, setListWallets] = useState([
		// {
		// 	amount: 0,
		// 	company_id: 212,
		// 	created_at: "2022-11-02 10:21 AM",
		// 	currency: "EGP",
		// 	id: 757,
		// 	limit: 0,
		// 	ref_num: "11254955212",
		// 	type: "debit",
		// 	updated_at: "2022-11-02 10:21 AM",
		// },
	]);
	const [currencies, setCurrencies] = useState([]);
	const [popupInfo, setpopupInfo] = useState({});
	const [reload, setReload] = useState(true);
	const { wallet } = Locale;

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenView, setIsOpenView] = useState(false);

	const toggle = (id = null, currency = null, account = null, type) => {
		setpopupInfo({
			id,
			currency,
			account,
			type,
		});
		setIsOpen(!isOpen);
	};

	const toggleView = (transaction = null, currency = null) => {
		setpopupInfo({ ...transaction, currency });
		setIsOpenView(!isOpenView);
	};

	useEffect(() => {
		const getWallets = async () => {
			const res = await listVendorWallets(id);
			const currency = await listCurrencies();

			if (res?.status === 200) {
				setListWallets(res.data.data);
			}
			if (currency?.status === 200) {
				const formatted = currency?.data?.data?.data?.map((item) => {
					return { value: item.id, label: item.currency_code };
				});
				setCurrencies(formatted);
			}
		};
		getWallets();
	}, [reload]);

	const allWallets = useMemo(() => {
		return listWallets?.length > 0
			? listWallets.map((wallet) => (
					<Wallet
						key={wallet.id}
						Wallet={wallet}
						toggle={toggle}
						isOpenView={isOpenView}
						toggleView={toggleView}
						viewMore={true}
						reload={reload}
					/>
			  ))
			: null;
	}, [listWallets, reload]);

	const addWallet = async () => {
		const res = await addVendorWallet(id, {
			amount: 0,
			currency: popupInfo.Currency.label,
		});
		if (res?.status === 200 || res?.status === 201) {
			getNotification(res?.data?.message);
			toggleAdd();
			setReload((prev) => !prev);
		}
	};
	return (
		<div className="vendor-wallet">
			{allWallets}

			<PaymentPopup
				isOpen={isOpen}
				popupInfo={popupInfo}
				toggle={toggle}
				setpopupInfo={setpopupInfo}
				setReload={setReload}
			/>

			<ViewTransactionPopup
				isOpen={isOpenView}
				toggle={toggleView}
				popupInfo={popupInfo}
			/>

			<Modal isOpen={isOpenAdd} toggle={toggleAdd}>
				<div className="lan d-flex justify-content-between align-items-baseline p-3  border-bottom ">
					<p className=" text-secondary mb-0 "> {wallet.AddWallet}</p>
					<i
						className="far fa-times-circle fa-lg text-secondary "
						onClick={toggleAdd}
					></i>
				</div>

				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 ">
					<div className=" m-0 p-0 w-75">
						<div className="mx-1">
							<SelectField
								type="text"
								placeholder={wallet.Currency}
								label={wallet.Currency}
								options={currencies}
								onChange={(e) => {
									setpopupInfo({
										Currency: e,
									});
								}}
								hasLabel={true}
								name="Currency"
							/>
						</div>
					</div>
				</ModalBody>
				<div className="w-100 pb-5 border-top-0  text-center  p-0  ">
					<button className="btn  btn-success rounded w-70" onClick={addWallet}>
						{wallet.AddWallet}
					</button>
				</div>
			</Modal>
		</div>
	);
}
