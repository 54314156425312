import moment from "moment/moment";
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useLocation } from "react-router-dom";
import {
	exportToExcel,
	listGroupReservationsSaudi,
} from "services/sbsReservations";
import Locale from "translations";
import Colums from "../../Bookings/Colums";
import Filter from "./Filter";
import ManulaBookItem from "./ManulaBookItem";
import ReservationsTable from "./Table";

export default function SaudiReservationsList() {
	const { backOffice, booking, requests, payment } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [reservations, setReservations] = useState([]);
	const [metaData, setMetaData] = useState(null);
	const [show, setShow] = useState({
		filter: false,
		colums: false,
	});

	const [filters, setFilters] = useState({
		search: "",
		date_from_start: undefined,
		date_from_end: undefined,
		destination_id: null,
		status: "",
	});

	const colums = [
		// { id: "brn", label: booking.SRN },
		{ id: "sbs_ref", label: "SBS Ref." },
		{ id: "admin_name", label: backOffice.confirmation },
		{ id: "supplier", label: requests.supplier },
		{ id: "company", label: booking.Agency },
		{ id: "hotel_name", label: booking.HotelName },
		{ id: "destination", label: booking.Destination },
		{ id: "rooms_count", label: booking.RoomCount },
		{ id: "created_at", label: booking.BookingDate },
		{ id: "status", label: backOffice.status },
		{ id: "date_from", label: booking.CheckinDate },
		{ id: "nights", label: booking.Nights },
		{ id: "pax", label: booking.pax },
		{ id: "selling_rate", label: backOffice.SellingRate },
		{ id: "total_refund", label: "Total Refund" },
		{ id: "customer_charges", label: booking.customerCharges },
		{ id: "net_rate", label: backOffice.NetRate },
		{ id: "earnings", label: backOffice.earnings },
		{ id: "total_price", label: "Price" },
		{ id: "currency", label: "Currency" },

		// { id: "payment_method", label: payment.messages.paymentMethod },
		// { id: "payment_document_number", label: payment.messages.paymentDocumentNumber },
	];

	const columsList = colums.map((item) => {
		return {
			id: item.id,
			name: item.label,
		};
	});
	const [checkedColums, setCheckedColums] = useState(
		columsList.map((colum) => colum.id).slice(0, 9)
	);
	const toggleColsShow = (active) => {
		setShow(active);
	};

	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				...filters,
				search: filters.search,
				destination_id: filters.destination_id?.value,
				date_from_start: filters?.date_from_start
					? moment(filters?.date_from_start).format("YYYY-MM-DD")
					: "",
				date_from_end: filters?.date_from_end
					? moment(filters?.date_from_end).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await listGroupReservationsSaudi(pages, data);
			setReservations(response?.data.data);
			setMetaData(response.data.meta);
		}
		fetchReservationsFilterdData();
	}, [filters]);

	const goTo = (page) => {
		setFilters({ ...filters, page: page });
	};

	const exportToFile = async () => {
		const res = await exportToExcel("saudi", {
			...filters,
			destination_id: filters.destination_id?.value,
			date_from_start: filters?.date_from_start
				? moment(filters?.date_from_start).format("YYYY-MM-DD")
				: "",
			date_from_end: filters?.date_from_end
				? moment(filters?.date_from_end).format("YYYY-MM-DD")
				: "",
			status: filters.status?.value,
			export_columns: checkedColums,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`manual-saudi-reservations-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const handelCheckCols = (colum, checked) => {
		if (checked) {
			setCheckedColums([...checkedColums, colum.id]);
		} else {
			let newListChecked = checkedColums.filter((id) => id !== colum.id);
			setCheckedColums(newListChecked);
		}
	};

	const allBooking = useMemo(() => {
		return reservations.map((data) => {
			return <ManulaBookItem data={data} checkedColums={checkedColums} />;
		});
	}, [checkedColums, reservations]);

	const tableHeaders = useMemo(() => {
		return columsList.map((colum) => {
			return checkedColums.includes(colum.id) ? (
				<th key={colum.id}>{colum.name}</th>
			) : null;
		});
	}, [checkedColums, columsList]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="main-title">Saudi Group Reservations</h6>
				<div className="row m-0 p-0 col-8   justify-content-end">
					<div className="col-xl-2 col-3">
						<Colums
							toggleShow={toggleColsShow}
							show={show}
							columsList={columsList}
							handelCheck={handelCheckCols}
							checkedColums={checkedColums}
						/>
					</div>

					<div className="col-xl-2 col-3">
						<div className="border rounded filter-col">
							<button
								className="btn btn-primary w-100"
								style={{ color: "#fff" }}
								onClick={exportToFile}
							>
								{booking.ExportExcel}
							</button>
						</div>
					</div>
				</div>
			</div>
			<Filter
				reservations={reservations}
				setReservations={setReservations}
				pages={pages}
				setMetaData={setMetaData}
				setFilters={setFilters}
				filters={filters}
				exportToFile={exportToFile}
			/>
			<ReservationsTable
				reservations={allBooking}
				setReservations={setReservations}
				goTo={goTo}
				metaData={metaData}
				tableHeaders={tableHeaders}
			/>
		</>
	);
}
