import hotelImg from "assets/images/Hotelcircel.svg";
import flightImg from "assets/images/flight.svg";
import toursImg from "assets/images/tours.svg";
import transfersImg from "assets/images/transfers.svg";

function ServiceItem({ Name, CheckedService, ischecked }) {



const imgSrc = (() => {
	switch (Name) {
		case 'hotels':
	 return 	hotelImg	
		break;
		case 'transfers':
	  return		transfersImg
		break;
		case 'flight':
			return	flightImg
			break;
		case 'tours':
			return	toursImg
			break;
		case 'transportation':
			return	transfersImg
			break;
		default:
		 return	""		
	}
})();

	return (
		<div className="col-6 item-service py-3" key={Name}>
			<div className="w-50 item-service justify-content-start">
				<input
					checked={ischecked}
					type="checkbox"
					style={{ accentColor: "#b98637" }}
					id={Name}
					name={Name}
					onChange={() => {
						CheckedService(Name);
					}}
				/>
				<label htmlFor={Name}>
				<img src={imgSrc} alt={Name} />
					{Name}</label>
			</div>
		</div>
	);
}

export default ServiceItem;
