import hotelImg from "assets/images/Hotels.svg";
import visaImg from "assets/images/visaOline.svg";
import Model from "components/model";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useState } from "react";
import { cleanEmpty } from "services/general";
import Locale from "translations";

export default function Details({ data }) {
	const { backOffice } = Locale;

	const [serviceState, setServiceState] = useState();
	const servicesOptions = [
		!data?.services?.some((item) => item.name === "Online visa")
			? {
					value: 2,
					label: "Online visa",
			  }
			: null,
		!data?.services?.some((item) => item.name === "Hotels")
			? {
					value: 1,
					label: "Hotels",
			  }
			: null,
	];
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const toggle = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "service",
			title: "Add Service",
		});
	};

	//   let arr = [...data?.service];
	const [arr, setArr] = useState([...data?.services]);
	let hotel = arr.some((item) => item.name === "Hotels");
	let visa = arr.some((item) => item.name === "Online visa");

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setServiceState({ ...serviceState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setServiceState({
				...serviceState,
				[name]: { id: value?.value, name: value?.label },
			});
		}
	};
	console.log("serviceState", arr);
	return (
		<>
			<div className="inputs">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-8">
						<div className="main-label">
							<TextField
								type="text"
								label={backOffice.supplierName}
								hasLabel={true}
								value={data?.name}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-8">
						<div className="main-label">
							<TextField
								type="text"
								label={backOffice.businessEmail}
								hasLabel={true}
								value={data?.email}
								disabled={true}
							/>
						</div>
					</div>
				</div>
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-4">
						<div className="main-label">
							<TextField
								type="text"
								label={backOffice.countryRegion}
								hasLabel={true}
								value={data?.country?.name}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-4">
						<div className="main-label">
							<TextField
								type="text"
								label={backOffice.phoneNo}
								hasLabel={true}
								value={data?.phone_number}
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="services">
				<div className="services-header d-flex justify-content-between align-content-center my-2">
					<h3>{backOffice.services}</h3>
					{arr?.length < 2 ? (
						<button className="btn btn-grey-suppliers" onClick={() => toggle()}>
							{backOffice.addServices}
						</button>
					) : null}
				</div>
				<div className="services-items">
					<div className="row  w-100 no-gutter m-0">
						{hotel ? (
							<div className="col-3">
								<div className="d-flex justify-content-evenly align-items-center services-item-content img-service">
									<div className="d-flex align-items-center ">
										<span className="service-img">
											<img src={hotelImg} alt="" width={42} />
										</span>
										<span className="mx-3 service-name">
											{backOffice.hotel}
										</span>
									</div>

									<div className="d-flex align-items-center">
										<span
											className={
												data?.status === "Active"
													? "text-success"
													: "text-danger "
											}
										>
											{data.status == "Active"
												? `${backOffice.active}`
												: `${backOffice.inActive}`}
										</span>
										{/* {data.status === "Active" ? (
											<button
												className="mx-3 btn btn-link  btn-lg"
												style={{ cursor: "pointer" }}
											>
												<i class="fas fa-stop-circle text-danger"></i>
											</button>
										) : (
											<button
												className="mx-3 btn btn-link  btn-lg"
												style={{ cursor: "pointer" }}
											>
												<i class="fas fa-play-circle text-success"></i>
											</button>
										)} */}
									</div>
								</div>
							</div>
						) : null}
						{visa ? (
							<div className="col-3">
								<div className="d-flex justify-content-evenly align-items-center services-item-content img-service">
									<div className="d-flex align-items-center ">
										<span className="service-img">
											<img src={visaImg} alt="" width={42} />
										</span>
										<span className="mx-3 service-name">{backOffice.visa}</span>
									</div>

									<div className="d-flex align-items-center">
										<span
											className={
												data?.status === "Active"
													? "text-success"
													: "text-danger "
											}
										>
											{data.status == "Active"
												? `${backOffice.active}`
												: `${backOffice.inActive}`}
										</span>
										{/* {data.status === "Active" ? (
											<button
												className="mx-3 btn btn-link  btn-lg"
												style={{ cursor: "pointer" }}
											>
												<i class="fas fa-stop-circle text-danger"></i>
											</button>
										) : (
											<button
												className="mx-3 btn btn-link  btn-lg"
												style={{ cursor: "pointer" }}
											>
												<i class="fas fa-play-circle text-success"></i>
											</button>
										)} */}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
			{/* Modal */}
			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={modal?.title}
				toggle={toggle}
				size="md"
				hasFooter={false}
			>
				<div className="w-100 p-4">
					<div className="row custom--row">
						<div className="col-md-12">
							<SelectField
								label={backOffice.services}
								placeholder={backOffice.selectService}
								options={cleanEmpty(servicesOptions)}
								name="service"
								//value={Status}
								onChange={(e) => {
									handleChange({ name: "service", value: e });
								}}
								// errors={errors.status}
								// color={errors.status?.required ? "danger" : ""}
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end">
						<button className="btn btn-secondary px-4 mx-3" onClick={toggle}>
							{backOffice.close}
						</button>
						<button
							className="btn btn-grey-suppliers px-4"
							onClick={() => {
								arr.push(serviceState?.service);
								setArr([...arr]);
								toggle();
							}}
						>
							{backOffice.add}
						</button>
					</div>
				</div>
			</Model>
		</>
	);
}
