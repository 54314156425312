import { useGlobalState } from "context/global";
import { downloadFileWithUUID } from "helpers/downloadFileWithUUID";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory, useParams } from "react-router-dom";
import {
	addRawdaDocument,
	addVisaDocument,
	viewOnlineVisaRequest,
	viewOnlineVisaRequestWaiting,
	visaRequestReapplyPassenger,
	visaRequestRejectPassenger,
} from "services/Request";
import { uploadFile } from "services/companies";
import Locale from "translations";
import InsuranceModal from "./Modals/InsuranceModal";
import IssueVisaModal from "./Modals/IssueVisaModal";
import RadwaModal from "./Modals/RadwaModal";
import ReapplyVisaModal from "./Modals/ReapplyVisaModal";
import RejectVisaModal from "./Modals/RejectVisaModal";
import ViewRequestHeader from "./ViewRequestHeader";

export default function ViewRequest() {
	const { requests } = Locale;
	// const [searchVal, setSearchVal] = useState(null);
	// const [ListOfChecked, setListOfChecked] = useState([]);
	const { status, id } = useParams();
	let history = useHistory();
	const permissionArr = ["Manage-Sold-Visas", "Visa-Requests-Manage"];

	const { locale } = useGlobalState();
	const [passangers, setPassangers] = useState([]);
	const [header, setHeader] = useState({});
	const [issueVisa, setIssueVisa] = useState({
		logo: "",
		visaNumber: "",
		insurance: "",
	});
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenRawda, setIsOpenRawda] = useState(false);
	const [isOpenInsurance, setIsOpenInsurance] = useState(false);
	const [errors, setErrors] = useState({});
	const [reload, setReload] = useState(false);
	const [insuranceState, setInsuranceState] = useState();
	const [RadwaState, setRadwaState] = useState();
	const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);
	const [reapllyModalIsOpen, setReapllyModalIsOpen] = useState(false);
	const [changeStatusReason, setChangeStatusReason] = useState({
		type: "",
		reason: "",
		passengerId: null,
		passengerPassportNumber: null,
	});

	const toggle = (id) => {
		setIssueVisa({
			id: id,
			logo: "",
			visaNumber: "",
			insurance: "",
		});
		setIsOpen(!isOpen);
	};
	const toggleInsurance = (id) => {
		setInsuranceState({ ...insuranceState, id });
		setIsOpenInsurance(!isOpenInsurance);
	};
	const toggleRawda = () => {
		setIsOpenRawda(!isOpenRawda);
	};

	function toggleChangeStatusModal(type, passengerId, passengerPassportNumber) {
		if (type === "reject") {
			setRejectModalIsOpen(!rejectModalIsOpen);
		} else if (type === "reapply") {
			setReapllyModalIsOpen(!reapllyModalIsOpen);
		}
		setChangeStatusReason({
			reason: "",
			type,
			passengerId,
			passengerPassportNumber,
		});
	}

	function handleReasonChange(value) {
		setChangeStatusReason({ ...changeStatusReason, reason: value });
	}
	// const handelCheck = (passenger, flag) => {
	// 	if (flag) {
	// 		setListOfChecked([...ListOfChecked, passenger.id]);
	// 	} else {
	// 		let newListChecked = ListOfChecked.filter((id) => id != passenger.id);
	// 		setListOfChecked(newListChecked);
	// 	}
	// };

	function passengerStatusClasses(status) {
		switch (status) {
			case "issued":
				return "text-success";
			case "rejected":
				return "text-danger";
			case "invalid":
				return "pending";
			case "waiting":
				return "waiting";
			default:
				break;
		}
	}

	const allPassengers =
		passangers?.length > 0
			? passangers?.map((passenger) => {
					console.log(passenger?.status);
					return (
						<tr key={passenger?.id}>
							<td>
								{/* <input
                type="checkbox"
                checked={ListOfChecked.includes(passenger.id)}
                className="mx-1 insert-group-checkbox "
                onChange={(e) => {
                  handelCheck(passenger, e.target.checked);
                }}
              /> */}
								{passenger.passport_number}
							</td>
							<td>
								{locale === "en"
									? passenger.full_name_en
									: passenger.full_name_ar}
							</td>
							<td>{passenger.gender}</td>
							<td>{passenger.date_of_birth}</td>
							<td>
								{passenger.age
									? passenger?.age
									: moment().diff(moment(passenger?.date_of_birth), "year")}
							</td>
							<td>
								{passenger.relationship ? passenger.relationship[locale] : ""}

								{passenger.visa_number}
							</td>
							<td>{passenger.national_id}</td>
							<td>{passenger.nationality}</td>

							<td>
								{passenger.visa_document ? (
									<i
										class="fas fa-download mx-2 waiting pointer"
										onClick={() =>
											downloadFileWithUUID(
												passenger.visa_document,
												`visa-document-${passenger?.passport_number}`
											)
										}
									></i>
								) : (
									<span className="mx-2">-</span>
								)}
							</td>
							<td>
								{passenger.insurance_document ? (
									<i
										class="fas fa-download mx-2 waiting pointer"
										onClick={() =>
											downloadFileWithUUID(
												passenger.insurance_document,
												`insurance-document-${passenger?.passport_number}`
											)
										}
									></i>
								) : (
									<span className="mx-2">-</span>
								)}
							</td>
							<td>
								{passenger.entry_stamp ? (
									<i
										class="fas fa-download mx-2 waiting pointer"
										onClick={() =>
											downloadFileWithUUID(
												passenger.entry_stamp,
												`entry-stamp-document-${passenger?.passport_number}`
											)
										}
									></i>
								) : (
									<span className="mx-2">-</span>
								)}
							</td>
							<td>
								<span
									className={`${passengerStatusClasses(
										passenger?.status
									)} text-capitalize`}
								>
									{passenger?.status ? passenger?.status : "-"}
								</span>
							</td>
							<td>
								{header.status !== "issued" &&
									passenger?.status !== "rejected" &&
									passenger?.status !== "invalid" &&
									passenger.visa_document == null &&
									passenger.visa_number == null && (
										<ShowForPermission permission={permissionArr}>
											<button
												className="btn btn-primary"
												onClick={() => toggle(passenger.id)}
											>
												Issue Visa
											</button>
										</ShowForPermission>
									)}
								{header.status !== "issued" &&
									passenger.status !== "issued" &&
									passenger?.status !== "invalid" &&
									passenger?.status !== "rejected" &&
									passenger?.visa_document === null &&
									passenger.visa_number == null && (
										<ShowForPermission permission={permissionArr}>
											<button
												className="btn btn-reject mx-2 my-2"
												onClick={() =>
													toggleChangeStatusModal(
														"reject",
														passenger.id,
														passenger?.passport_number
													)
												}
											>
												Reject
											</button>
										</ShowForPermission>
									)}
								{header.status !== "issued" &&
									passenger.status !== "issued" &&
									passenger?.status !== "invalid" &&
									passenger?.status !== "rejected" &&
									passenger?.visa_document === null &&
									passenger.visa_number == null && (
										<ShowForPermission permission={permissionArr}>
											<button
												className="btn btn-reapply"
												onClick={() =>
													toggleChangeStatusModal(
														"reapply",
														passenger.id,
														passenger?.passport_number
													)
												}
											>
												Reapply
											</button>
										</ShowForPermission>
									)}
								{(passenger?.status === "issued" ||
									passenger?.visa_document) && (
									<ShowForPermission permission={permissionArr}>
										<button
											className="btn btn-reapply"
											onClick={() => {
												const fetchFilesURL =
													process.env.REACT_APP_API_URL + "/api/files/fetch";
												const visaDocument = `${fetchFilesURL}/${passenger?.visa_document}`;
												toggle(passenger?.id);
												setIssueVisa({
													id: passenger.id,
													displayImg: visaDocument,
													logo: passenger?.visa_document,
													insurance: passenger?.insurance_document,
													visaNumber: passenger?.visa_number,
												});
											}}
										>
											Upload Visa & Insurance
										</button>
									</ShowForPermission>
								)}
							</td>
							<td>
								<ShowForPermission permission={permissionArr}>
									<Link
										to={`/online-visa/view/passenger/${status}/${id}/${passenger.id}`}
									>
										{locale === "en" ? (
											<i class="fas fa-angle-right"></i>
										) : (
											<i class="fas fa-angle-left"></i>
										)}
									</Link>
								</ShowForPermission>
							</td>
						</tr>
					);
			  })
			: [];

	useEffect(() => {
		const fetchRequestIssued = async () => {
			const response = await viewOnlineVisaRequest(id);
			if (response.status === 200 || response.status === 201) {
				setPassangers(response.data.data.passengers);
				setHeader(response.data.data);
			}
		};

		const fetchRequestWaiting = async () => {
			const response = await viewOnlineVisaRequestWaiting(id);
			if (response.status === 200 || response.status === 201) {
				setPassangers(response.data.data.passengers);
				setHeader(response.data.data);
			}
		};

		if (status === "waiting") {
			fetchRequestWaiting();
		} else {
			fetchRequestIssued();
		}
	}, [reload]);

	// const checkFormErrors = () => {
	// 	let SubmitError = {};
	// 	Object.keys(issueVisa).forEach((key) => {
	// 		SubmitError = {
	// 			...SubmitError,
	// 			...validate(
	// 				{
	// 					name: key,
	// 					value: issueVisa[key],
	// 				},
	// 				{ required: true, number: key === "visaNumber" ? true : false }
	// 			),
	// 		};
	// 	});

	// 	setErrors(() => SubmitError);
	// 	return isFormValid(SubmitError);
	// };

	const addIssueVisa = async () => {
		let formData = new FormData();
		formData.append("file", issueVisa.logo);
		let formData1 = new FormData();
		formData1.append("file", issueVisa.insurance);
		let isImgSelected = "";
		let isInsuranceSelected = "";

		if (
			issueVisa.logo &&
			issueVisa.logo !== "" &&
			typeof issueVisa.logo !== "string"
		) {
			isImgSelected = await uploadFile(formData);
		}

		if (
			issueVisa.insurance &&
			issueVisa.insurance !== "" &&
			typeof issueVisa.insurance !== "string"
		) {
			isInsuranceSelected = await uploadFile(formData1);
		}
		if (issueVisa.logo !== "" || issueVisa.insurance !== "") {
			const res = await addVisaDocument({
				visa_request_id: header.id,
				passenger_id: issueVisa.id,
				visa_number: +issueVisa.visaNumber,
				visa_document: isImgSelected?.data?.uuid || issueVisa?.logo,
				insurance_document:
					isInsuranceSelected?.data?.uuid || issueVisa.insurance,
			});
			if (res.status === 200 || res.status === 201) {
				toggle();
				store.addNotification({
					title: "Info!",
					message: res?.message
						? res?.message
						: "Visa number document added successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						setIssueVisa({
							logo: "",
							visaNumber: "",
							displayImg: "",
						});
						setReload((prev) => !prev);
					},
				});
			}
		}
	};

	const AddRawda = async () => {
		let formData = new FormData();
		formData.append("file", RadwaState);
		let isFileSelected = "";

		isFileSelected = await uploadFile(formData);

		if (isFileSelected.status === 200) {
			const res = await addRawdaDocument({
				visa_request_id: header.id,
				document: isFileSelected.data.uuid,
			});
			if (res.status === 200 || res.status === 201) {
				toggleRawda();
				store.addNotification({
					title: "Info!",
					message: res?.message
						? res?.message
						: "Upload Madinah Rawda Reservation successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						setRadwaState();
						setReload((prev) => !prev);
					},
				});
			}
		}
	};

	async function submitReason() {
		const statusType = changeStatusReason.type;
		const data = {
			visa_request_id: Number(id),
			passenger_id: changeStatusReason?.passengerId,
			passport_number: changeStatusReason?.passengerPassportNumber,
			reason: changeStatusReason?.reason,
		};
		let res;
		if (statusType === "reject") {
			res = await visaRequestRejectPassenger(data);
		} else if (statusType === "reapply") {
			res = await visaRequestReapplyPassenger(data);
		} else {
			return;
		}
		if (res?.status === 200) {
			store.addNotification({
				title: "Info!",
				message: res?.data?.message
					? res?.data?.message
					: "Passenger Status Updated successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setIssueVisa({
						logo: "",
						visaNumber: "",
						displayImg: "",
					});
					setReload((prev) => !prev);
				},
			});
			toggleChangeStatusModal(statusType, null);
		}
	}
	return (
		<>
			<div>
				<ViewRequestHeader
					header={header}
					downloadFileWithUUID={downloadFileWithUUID}
				/>
			</div>

			<div className="mt-3  border rounded ">
				<header className=" p-3 border-bottom d-flex justify-content-between">
					<p className="m-0 font-weight-bold text-black">
						{requests.Travelers} ({header.count_travellers}/{header.pax})
					</p>
					<div>
						<ShowForPermission permission={permissionArr}>
							{!header?.rawda_reservation_document &&
							header?.is_umrah === 1 &&
							!header?.rawda_reservation_attachment ? (
								<button
									className="btn btn-primary"
									onClick={() => toggleRawda()}
								>
									Upload Madinah Rawda{" "}
								</button>
							) : null}

							{header?.rawda_reservation_document &&
							header?.is_umrah === 1 &&
							header?.rawda_reservation_attachment ? (
								<>
									<button
										className="btn btn-primary mx-2"
										onClick={() =>
											downloadFileWithUUID(
												header?.rawda_reservation_attachment,
												"rawda-document"
											)
										}
									>
										Donwload Madinah Rawda{" "}
									</button>
									<button
										className="btn btn-primary"
										onClick={() => toggleRawda()}
									>
										Update Madinah Rawda{" "}
									</button>
								</>
							) : null}
						</ShowForPermission>
					</div>
				</header>

				<div className="d-flex   border-top px-3  align-items-center  justify-content-between  ">
					<div className="col-4 p-0">
						{/* <button
              className="btn btn-success m-1"
            >
              {requests.SendToOffline}
            </button> */}
					</div>

					<div className="col-6 m-0  justify-content-end d-flex align-items-center  ">
						{/* <button className="btn btn-primary  m-1">
              <i class="fas fa-file-excel mx-2"></i>
              {requests.ExportSelectedToExcel}
            </button> */}

						{/* <div className=" mx-2 w-50">
							<TextField
								hasLabel={false}
								placeholder={requests.search}
								value={searchVal}
								onChange={(e) => {
									setSearchVal(e.target.value);
								}}
							/>
						</div> */}
					</div>
				</div>
			</div>

			<div className="border table-responsive visa-requests-passengers">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{requests.PassportNumber}</th>
							<th width="22%">{requests.Name}</th>
							<th>{requests.Gender}</th>
							<th>{requests.Birthday}</th>
							<th>{requests.Age}</th>
							<th>Visa number</th>
							<th>{requests.NationalId}</th>
							<th>{requests.Nationality}</th>
							<th>Visa</th>
							<th>Insurance</th>
							<th>Entry Stamp</th>
							<th>Status</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>{allPassengers}</tbody>
				</table>
			</div>

			<div className="d-flex justify-content-end my-4">
				<button
					className="btn bg-gray text-white mx-2 px-5"
					onClick={() => {
						history.push(`/bookings-sold-visas`);
					}}
				>
					{requests.back}
				</button>
			</div>

			<IssueVisaModal
				isOpen={isOpen}
				toggle={toggle}
				issueVisa={issueVisa}
				setIssueVisa={setIssueVisa}
				setErrors={setErrors}
				errors={errors}
				addIssueVisa={addIssueVisa}
			/>
			<RadwaModal
				isOpen={isOpenRawda}
				toggle={toggleRawda}
				setRadwaState={setRadwaState}
				RadwaState={RadwaState}
				AddRawda={AddRawda}
			/>
			<InsuranceModal
				isOpen={isOpenInsurance}
				toggle={toggleInsurance}
				setInsuranceState={setInsuranceState}
				insuranceState={insuranceState}
				// AddRawda={AddRawda}
			/>

			<RejectVisaModal
				isOpen={rejectModalIsOpen}
				toggle={() => toggleChangeStatusModal("reject")}
				reason={changeStatusReason.reason}
				handleReasonChange={handleReasonChange}
				submitReason={submitReason}
			/>

			<ReapplyVisaModal
				isOpen={reapllyModalIsOpen}
				toggle={() => toggleChangeStatusModal("reapply")}
				reason={changeStatusReason.reason}
				handleReasonChange={handleReasonChange}
				submitReason={submitReason}
			/>
		</>
	);
}
