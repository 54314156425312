import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Locale from "translations";

export default function RequestItemHeader({
	isOpen,
	toggleCollapse,
	requestTypeText,
	details,
	status,
	isView,
}) {
	const { backOffice } = Locale;
	const statusClasses = (status) => {
		switch (status) {
			case "confirmed":
				return "text-success-status";
			case "rejected":
			case "expired":
				return "text-danger-status";
			default:
				return "text-warning-status";
		}
	};

	const statusKey = (status) => {
		switch (status) {
			case "pending_approval":
				return "Pending Approval";
			case "waiting_approval":
				return "Waiting For Approval";
			default:
				return status;
		}
	};
	return (
		<div className="flight-requests-header">
			<div className="flight-requests-type px-2">{requestTypeText}</div>
			{isView && (
				<p className="d-flex flex-column my-1 col-md-1 request-reseration-num">
					<span>Reservation No.</span>
					<Link to={`/airline-reservations/${details?.flight_booking_id}`}>
						<span className="font-weight-bold text-decoration-underline">
							{details.flight_info?.safa_reference}
						</span>
					</Link>
				</p>
			)}

			<p
				className={`d-flex flex-column my-1  ${
					isView ? "col-md-3" : "col-md-4"
				}`}
			>
				<span>{backOffice.requestNo}</span>
				<span className="font-weight-bold">{details?.request_num}</span>
			</p>
			{isView && (
				<p className="d-flex flex-column my-1 col-md-1 request-reseration-num">
					<span>Company Name</span>
					<span className="font-weight-bold">
						{details?.request_by?.company_name}
					</span>
				</p>
			)}
			<p
				className={`d-flex flex-column my-1 ${
					isView ? "col-md-1" : "col-md-2"
				}`}
			>
				<span>{backOffice.requestDate}</span>
				<span className="font-weight-bold">
					{moment(details?.created_at).format("YYYY-MM-DD hh:mm A")}
				</span>
			</p>

			<p
				className={`d-flex flex-column my-1 ${
					isView ? "col-md-1" : "col-md-2"
				}`}
			>
				<span>{backOffice.requestBy}</span>
				<span className="font-weight-bold">
					{details?.request_by?.user_name ?? details?.request_by?.company_name}
				</span>
			</p>

			<p className="d-flex flex-column my-1 col-md-1 request-status ">
				<span>{backOffice.status}</span>
				<span
					className={`font-weight-bold text-capitalize ${statusClasses(
						status
					)}`}
				>
					{statusKey(status)}
				</span>
			</p>

			<button className="btn-toggel btn" onClick={toggleCollapse}>
				{isOpen ? (
					<i className="fas fa-chevron-up"></i>
				) : (
					<i className="fas fa-chevron-down"></i>
				)}
			</button>
		</div>
	);
}
