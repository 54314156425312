import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextAreaField from "components/shared/TextAreaField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { uploadFile } from "services/auth";
import { createPayment, fetchPaymentMethods } from "services/package";
import { mapCurrencyToSelect } from ".";

const REQUIRED_FIELDS = ["amount", "currency", "payment_method"];

export default function AddPaymentModal({
	isOpen,
	toggle,
	paymentModal,
	setAddPaymentModal,
	fetchPackages,
}) {
	const { allCurrencies } = useGlobalState();
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [errors, setErrors] = useState({});

	const checkFormErrors = () => {
		let submitErrors = {};
		Object.keys(paymentModal.data).forEach((key) => {
			if (REQUIRED_FIELDS.includes(key)) {
				submitErrors = {
					...submitErrors,
					...validate(
						{
							name: key,
							value: paymentModal.data[key],
						},
						{
							required: true,
							maxNumber:
								key === "amount"
									? paymentModal.data.package_price -
									  paymentModal.data.amount_pay
									: null,
						}
					),
				};
			}
		});
		setErrors(submitErrors);
		return submitErrors;
	};

	const handleOnChange = (key, value) => {
		setAddPaymentModal({
			...paymentModal,
			data: {
				...paymentModal.data,
				[key]: value,
			},
		});
	};

	const handleDocument = (e) => {
		setAddPaymentModal({
			...paymentModal,
			data: {
				...paymentModal.data,
				payment_document: {
					fileDisplay: URL.createObjectURL(e.target.files[0]),
					fileObject: e.target.files[0],
				},
			},
		});
	};

	const submit = async () => {
		const formErrors = checkFormErrors();
		if (isFormValid(formErrors)) {
			const fileFormData = new FormData();
			fileFormData.append(
				"file",
				paymentModal.data.payment_document.fileObject
			);

			let file_id = paymentModal.data.payment_document.file_id;

			if (paymentModal.data.payment_document.fileObject) {
				const {
					data: { uuid },
				} = await uploadFile(fileFormData);
				file_id = uuid;
			}

			const formData = new FormData();
			formData.append(
				"safa_visa_package_id",
				paymentModal.data.safa_visa_package_id
			);
			formData.append(
				"payment_method_id",
				paymentModal.data.payment_method.value
			);
			formData.append("amount", paymentModal.data.amount);
			formData.append("currency", paymentModal.data.currency.value);
			paymentModal?.data?.note &&
				formData.append("note", paymentModal.data.note);

			file_id && formData.append("file_id", file_id);

			paymentModal?.data?.batch_date &&
				formData.append(
					"batch_date",
					moment(paymentModal.data.batch_date).format("YYYY-MM-DD")
				);

			await createPayment(formData);

			setAddPaymentModal({
				open: false,
				type: "add",
				data: {
					safa_visa_package_id: null,
					amount: "",
					currency: "",
					payment_method: "",
					notes: "",
					payment_document: {
						fileDisplay: null,
						fileObject: null,
						file_id: null,
					},
				},
			});

			await fetchPackages();
		}
	};

	useEffect(() => {
		fetchPaymentMethods().then((res) => {
			const formated = res.data.data.map((item) => ({
				label: item.name,
				value: item.id,
			}));
			setPaymentMethods(formated);
		});
	}, []);

	useEffect(() => {
		if (paymentModal.open) {
			setErrors({});
		}
	}, [paymentModal.open]);

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>
				{paymentModal.type === "edit" ? "Edit Payment" : "Add Payment"}
			</ModalHeader>

			<ModalBody>
				<div className="row align-items-start">
					<div className="col-12">
						<TextField
							label="Company Name"
							name="company"
							value={paymentModal.data.company_name}
							disabled
						/>
					</div>

					<div className="col-6">
						<TextField
							label="Amount"
							name="amount"
							placeholder="Amount"
							type="number"
							value={paymentModal.data.amount}
							max={
								paymentModal.data.package_price - paymentModal.data.amount_pay
							}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{
											name: "amount",
											value: e.target.value,
										},
										{
											required: true,
											maxNumber:
												paymentModal.data.package_price -
												paymentModal.data.amount_pay,
										}
									),
								});
								handleOnChange("amount", e.target.value);
							}}
							color={errors.amount?.required ? "danger" : ""}
							errors={errors.amount}
						/>
					</div>

					<div className="col-6">
						<SelectField
							label="Currency"
							placeholder="Currency"
							name="currency"
							id="currency"
							options={mapCurrencyToSelect(allCurrencies)}
							value={paymentModal.data.currency}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{
											name: "currency",
											value: e.value,
										},
										{ required: true }
									),
								});
								handleOnChange("currency", e);
							}}
							color={errors.currency?.required ? "danger" : ""}
							errors={errors.currency}
							disabled={paymentModal.type === "edit"}
						/>
					</div>

					<div className="col-6">
						<SelectField
							label="Payment Method"
							placeholder="Payment Method"
							name="payment_method"
							id="payment_method"
							options={paymentMethods}
							value={paymentModal.data.payment_method}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{
											name: "payment_method",
											value: e.value,
										},
										{ required: true }
									),
								});
								handleOnChange("payment_method", e);
							}}
							color={errors.payment_method?.required ? "danger" : ""}
							errors={errors.payment_method}
						/>
					</div>

					<div
						className="col-6"
						style={{ padding: "0.625rem 0.625rem 0.3125rem 0.625rem" }}
					>
						<DatePickerField
							label="Date"
							name="batch_date"
							date={paymentModal.data.batch_date}
							onChangeDate={(e) => {
								handleOnChange("batch_date", e);
							}}
						/>
					</div>

					<div className="col-12">
						<div className="col-12">
							<div className=" our-border-dashed my-2">
								<h6 className="p-2 span">Upload Payment Document</h6>

								<div
									className={`py-3  text-center d-flex flex-column justify-content-center`}
								>
									{/* display image */}
									{paymentModal.data.payment_document?.fileDisplay ? (
										<figure className="d-flex  justify-content-center ">
											{paymentModal.data.payment_document?.fileObject?.type !==
											"application/pdf" ? (
												<img
													src={`${paymentModal.data.payment_document?.fileDisplay}`}
													width={150}
													// height={150}
													alt="attachment"
												/>
											) : (
												<>
													<i className="fas fa-paperclip file-attach" />
													{
														paymentModal?.data.payment_document?.fileObject
															?.name
													}
												</>
											)}
										</figure>
									) : null}

									<button className="file-btn shape-border w-50 m-auto pointer">
										{"Add Attachment"}
										<input
											type="file"
											className="custom-file"
											name="payment_document"
											id="payment_document"
											accept="image/png, image/gif,image/jpeg,application/pdf"
											onChange={(e) => handleDocument(e)}
										/>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="col-12">
						<TextAreaField
							label="Notes"
							name="notes"
							placeholder="Notes"
							value={paymentModal.data.note}
							onChange={(e) => handleOnChange("note", e.target.value)}
						/>
					</div>
				</div>
			</ModalBody>

			<ModalFooter>
				<button
					className="btn btn-secondary"
					onClick={() => toggle(paymentModal.type)}
				>
					Cancel
				</button>
				<button className="btn btn-primary" onClick={submit}>
					Save
				</button>
			</ModalFooter>
		</Modal>
	);
}
