import axios from "axios";
import { cleanEmpty } from "./general";
const reservationsUrl =
	process.env.REACT_APP_API_URL + "/api/group-reservation";
const baseUrl = process.env.REACT_APP_API_URL + "/api";

export const fetchGroup = async (id) => {
	return await axios
		.get(`${reservationsUrl}/requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const acceptResrvationRequests = async (id) => {
	return await axios
		.put(`${reservationsUrl}/requests/${id}/accept`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addRemarks = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/requests/${id}/add-remark`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNotes = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/requests/${id}/add-note`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const cancelGroup = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/requests/${id}/cancel`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const definiteConfirmation = async (id) => {
	return await axios
		.put(`${reservationsUrl}/requests/${id}/definite`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateTerms = async (id, data) => {
	return await axios
		.put(`${reservationsUrl}/requests/${id}/terms`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const TentativeConfirmReservation = async (id, data) => {
	return await axios
		.put(`${reservationsUrl}/requests/${id}/tentative`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listGroupReservations = async (params) => {
	return await axios
		.get(`${reservationsUrl}/requests`, {
			params: { ...cleanEmpty(params) },
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const listGroupReservationsSudan = async (page, params) => {
	return await axios
		.get(`${reservationsUrl}/requests?page=${page}&platform=sudan`, {
			params: { ...cleanEmpty(params) },
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const listGroupReservationsSaudi = async (page, params) => {
	return await axios
		.get(`${reservationsUrl}/requests?page=${page}&platform=saudi`, {
			params: { ...cleanEmpty(params) },
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const listGroupReservationsHistory = async (id, params, page) => {
	return await axios
		.get(`${reservationsUrl}/requests/${id}/history?page=${page}`, { params })
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchOfflineSupplier = async () => {
	return await axios
		.get(`${reservationsUrl}/list-offline-suppliers`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchSuppliersHotels = async (id) => {
	return await axios.get(`${reservationsUrl}/offline-suppliers/${id}/hotels`);
};

export const OfflineReservationsList = async (page, params = {}) => {
	return await axios
		.get(`${reservationsUrl}/offline-requests?page=${page}`, {
			params: params != null ? cleanEmpty(params) : "",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchSuppliersLookups = async () => {
	return await axios
		.get(`${reservationsUrl}/list-offline-suppliers`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchHotelNamesLookups = async (id) => {
	return await axios
		.get(`${reservationsUrl}/offline-suppliers/${id}/hotels`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateReservation = async (id, data) => {
	return await axios
		.put(`${reservationsUrl}/requests/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchMealPlansLookups = async (id) => {
	return await axios
		.get(`${baseUrl}/lookups/meal-plans`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchRoomViewsLookups = async (id) => {
	return await axios
		.get(`${baseUrl}/lookups/room-views`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchRoomTypesLookups = async (id) => {
	return await axios
		.get(`${baseUrl}/lookups/room-types`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createOfflineRequest = async (data) => {
	return await axios
		.post(`${reservationsUrl}/offline-requests`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
//
export const fetchCompaniesLookups = async () => {
	return await axios
		.get(`${baseUrl}/lookups/companies`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const convertReservationToOnline = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/offline-requests/${id}/convert-to-online`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const AddReservationConfirmationNumber = async (id, data) => {
	return await axios
		.post(`${reservationsUrl}/requests/${id}/add-confirmation-number`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchHistoryUsers = async (id) => {
	return await axios
		.get(`${reservationsUrl}/requests/${id}/history/users`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const uploadFile = async (data) => {
	return await axios
		.post(`${baseUrl}/files/upload`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcel = async (supplier_name, params) => {
	return await axios
		.get(
			`${baseUrl}/group-reservation/export/requests?platform=${supplier_name}`,
			{
				params: cleanEmpty(params),
				responseType: "blob",
			}
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelSBS = async (params) => {
	return await axios
		.get(`${baseUrl}/group-reservation/export/requests`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelOfflineReservation = async (params) => {
	return await axios
		.get(`${baseUrl}/group-reservation/export/offline-requests`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNewRoomView = async (params) => {
	return await axios
		.post(`${baseUrl}/lookups/room-views`, {
			...params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchGroupReservationPDF = async (id) => {
	return await axios
		.get(`${reservationsUrl}/requests/${id}/pdf`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchGroupReservationVoucher = async (id) => {
	return await axios
		.get(`${reservationsUrl}/requests/${id}/voucher`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteRemark = async (id, remark_id) => {
	return await axios
		.delete(`${reservationsUrl}/requests/${id}/${remark_id}/delete-remark`)
		.then((res) => res)
		.catch((err) => err.response);
};
