import ExternalItem from './ExternalItem';
import HotelItem from './HotelItem';
import InternalItem from './InternalItem';
import SightseeingItem from './SightseeingItem';




export default function PackageItinerary({ productItinerary, classNames = "", showTitle = true }) {
  function itineraryElements() {
    return productItinerary?.map((item, index) => {
      switch (item ? item?.item?.name : "") {
        case "internal transportation":
          return (
            <InternalItem
              key={`internalTransportation-${item?.id}-${index}`}
              element={item}
              index={index}
              id={item?.id}
            />
          );
        case "external transportation":
          return (
            <ExternalItem
              key={`externalTransportation-${item?.id}-${index}`}
              element={item}
              index={index}
              id={item?.id}
            />
          );
        case "hotel":
          return (
            <HotelItem
              key={`hotel-${item?.id}-${index}`}
              element={item}
              index={index}
              id={item?.id}
            />
          );
        case "sightseeing":
          return (
            <SightseeingItem
              key={`sightseeing-${item?.id}-${index}`}
              element={item}
              index={index}
              id={item?.id}
            />
          );
        default:
          return null
      }
    })
  }
  return (
    <div className={`Package-Details-items ${classNames ? classNames : ""}`}>
      {showTitle ? <h4>Package Details</h4> : null}
      {itineraryElements()}
    </div>
  )
}

