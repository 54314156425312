import ChangeRequest from "../../shared/ChangeRequest";
import RefundRequest from "../../shared/RefundRequest";
import VoidRequest from "../../shared/VoidRequest ";

export default function FlightBookingRequests({ requests, fetchInventory }) {
	const closedRequestStatus = ["rejected", "expired", "confirmed"];
	const openedRequests = requests?.filter(
		(request) => !closedRequestStatus.includes(request?.status)
	);
	const closedRequests = requests?.filter((request) =>
		closedRequestStatus.includes(request?.status)
	);

	function renderRequestsByType(request) {
		switch (request?.type) {
			case "refund":
				return (
					<RefundRequest
						fetchData={fetchInventory}
						request={request}
						key={`${request?.id}-${request?.request_num}`}
					/>
				);
			case "change":
				return (
					<ChangeRequest
						fetchData={fetchInventory}
						request={request}
						key={`${request?.id}-${request?.request_num}`}
					/>
				);
			case "void":
				return (
					<VoidRequest
						fetchData={fetchInventory}
						request={request}
						key={`${request?.id}-${request?.request_num}`}
					/>
				);
			default:
				return null;
		}
	}

	return (
		<div className="p-md-3 py-2 bg-white">
			{/* open request  */}
			<div>
				<h5>{"Open Requests"}</h5>
				<hr className="my-1 bg-black" />
				{openedRequests?.length > 0 ? (
					openedRequests?.map((request) => renderRequestsByType(request))
				) : (
					<div className="w-100 text-center">
						<div className="product-build__product-no-data-25h ">
							<h4>
								<i className="fas fa-exclamation-triangle" /> Not Request Found
							</h4>
						</div>
					</div>
				)}
			</div>

			{/* closed request */}
			<div>
				<h5>{"Closed Requests"}</h5>
				<hr className="my-1 bg-black" />
				{closedRequests?.length > 0 ? (
					closedRequests?.map((request) => renderRequestsByType(request))
				) : (
					<div className="w-100 text-center">
						<div className="product-build__product-no-data-25h ">
							<h4>
								<i className="fas fa-exclamation-triangle" /> Not Request Found
							</h4>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
