export const voucherString = `
<style>
* {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

p {
  margin: 0;
}


.container-wrapper {
border: 1px solid #0c3b5c;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
margin-bottom: 2px;
}


.container-wrapper {
border: 4px solid #0c3b5c;
}

.voucher-header .header-img {
border: 1px solid #ebebeb;
width: 310px;
display: flex;
align-items: center;
justify-content: center;
}

.voucher-header .header-name h4 {
color: #0c3b5c;
font-size: 18px;
font-weight: 700;
margin: 7px 0;
}
.voucher-header .header-data {
width: 60%;
}

.voucher-header .header-data div {
margin: 3px 0;
}
.voucher-header .header-data h5 {
color: #2d2d2d;
font-size: 18px;
}
.voucher-header .header-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 14px;
}

.voucher-header {
gap: 85px;
}

hr {
border-top: 1px solid #0c3b5c !important;
}

.services h3 {
color: #0c3b5c;
font-size: 18px;
font-weight: 700;
}

.services .service-data div {
margin: 3px 0;
}
.services .service-data h5 {
color: #2d2d2d;
font-size: 20px;
}
.services .service-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 17px;
}

.passenger h3 {
color: #0c3b5c;
font-size: 18px;
font-weight: 700;
}
.passenger .passenger-data div {
margin: 3px 0;
}
.passenger .passenger-data h5 {
color: #2d2d2d;
font-size: 20px;
}
.passenger .passenger-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 18px;
}
.passenger .passenger-data .passenger-info h6 {
color: #2d2d2d;
}
.passenger .passenger-data .passenger-info p {
color: #0c3b5c;
}
.passenger .passenger-data .passenger-info {
background: #f7f7f7;
border: 4px solid #f4f4f4;
padding: 25px;
}

html,
.bg-gray-100 {
background-color: #fff !important;
}

.voucher-header .header-img {
border: 1px solid #ebebeb;
width: 210px !important;
display: flex;
align-items: center;
justify-content: center;
}

.voucher-header .header-name h4 {
color: #0c3b5c;
font-size: 16px !important;
font-weight: 700;
margin: 7px 0;
}

.voucher-header .header-data div {
margin: 3px 0;
}
.voucher-header .header-data h5 {
color: #2d2d2d;
font-size: 16px !important;
}
.voucher-header .header-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 14px !important;
}
.voucher-header .header-data {
width: 60%;
}

.voucher-header {
gap: 20px !important;
}

.services h3 {
color: #0c3b5c;
font-size: 14px !important;
font-weight: 500 !important;
}
.services .service-data div {
margin: 3px 0;
}
.services .service-data h5 {
color: #2d2d2d;
font-size: 15px !important;
}
.services .service-data p {
color: #2d2d2d;
font-weight: 500 !important;
font-size: 15px !important;
}

.passenger h3 {
color: #0c3b5c;
font-size: 14px !important;
font-weight: 500 !important;
}
.passenger .passenger-data div {
margin: 3px 0;
}
.passenger .passenger-data h5 {
color: #2d2d2d;
font-size: 16px !important;
}
.passenger .passenger-data p {
color: #2d2d2d;
font-weight: 500 !important;
font-size: 15px !important;
}
.passenger .passenger-data .passenger-info h6 {
color: #2d2d2d;
}
.passenger .passenger-data .passenger-info p {
color: #0c3b5c;
}
.passenger .passenger-data .passenger-info {
background: #f7f7f7;
border: 4px solid #f4f4f4;
padding: 10px !important;
}

.voucher h3 {
background-color: #fff;
}
.sidebar.office-navbar {
display: none !important;
}

.bg-white {
background-color: #fff !important;
}
.p-4 {
padding: 1.5rem !important;
}
.d-flex {
display: flex !important;
}

.flex-column {
flex-direction: column;
}
.text-center {
text-align: center !important;
}
.text-success {
color: #00b545 !important;
}
.font-weight-bold {
font-weight: 700 !important;
}

.py-2 {
padding-top: 0.5rem !important;
padding-bottom: 0.5rem !important;
}

.py-1 {
padding-top: 0.25rem !important;
padding-bottom: 0.25rem !important;
}
.px-4 {
padding-right: 1.5rem !important;
padding-left: 1.5rem !important;
}
.mb-5 {
margin-bottom: 3rem !important;
}
.mb-3 {
margin-bottom: 1rem !important;
}
.mb-2 {
margin-bottom: 0.5rem !important;
}
.my-2 {
margin-top: 0.5rem !important;
margin-bottom: 0.5rem !important;
}
.my-1 {
margin-top: 0.25rem !important;
margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
.col-sm-8,
.col-sm-9 {
flex: 0 0 auto;
}
.col-sm-8 {
width: 66.66666667%;
}
.col-sm-4,
.col-sm-5 {
flex: 0 0 auto;
}

.col-sm-4 {
width: 33.33333333%;
}
}
.col-9 {
width: 75%;
}
.col-8,
.col-9 {
flex: 0 0 auto;
}
.col-8 {
width: 66.66666667%;
}
.col-7 {
width: 58.33333333%;
}
.col-6,
.col-7 {
flex: 0 0 auto;
}
.col-6 {
width: 50%;
}
.col-5 {
width: 41.66666667%;
}
.col-4,
.col-5 {
flex: 0 0 auto;
}
.col-4 {
width: 33.33333333%;
}
.col-3 {
width: 25%;
}
.col-2,
.col-3 {
flex: 0 0 auto;
}
.col-2 {
width: 16.66666667%;
}
.row {
--bs-gutter-x: 20px;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
margin-right: -0.5;
margin-left: -0.5;
}
.row {
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col-4 {
flex: 0 0 33.3333333333%;
max-width: 33.3333333333%;
}
.col-6 {
flex: 0 0 50%;
max-width: 50%;
}
.col-3 {
flex: 0 0 25%;
max-width: 25%;
}
.col-8 {
flex: 0 0 66.6666666667%;
max-width: 66.6666666667%;
}
.col-2 {
flex: 0 0 16.6666666667%;
max-width: 16.6666666667%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.mb-1,
.my-1 {
margin-bottom: 0.25rem !important;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}

</style>

<div class="voucher bg-white p-4">
<div class="container-wrapper p-4">
  <div class="voucher-header d-flex  my-2">
    <div class="d-flex flex-column header-name">
      <div class="header-img  p-4 text-center">
        [voucher_avatar]
      </div>
      <h4>[voucher_name]</h4>
    </div>

    <div class="header-data">
      <div class="row">
        <div class="col-6">
          <h5>[inventory_bookingReference] :</h5>
        </div>
        <div class="col-6">
          <p>[voucher_bookingCode]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h5>[inventory_printedOn] :</h5>
        </div>
        <div class="col-6">
          <p>[voucher_printedOn]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h5>[inventory_confirmationNO] :</h5>
        </div>
        <div class="col-6">
          <p>[voucher_confirmationNumber]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h5>[inventory_bookingStatus] :</h5>
        </div>
        <div class="col-6 ">
          <p class="text-success">[voucher_bookingStatus]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h5>[inventory_bookedBy] :</h5>
        </div>
        <div class="col-6">
          <p>[voucher_bookedBy]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h5>[inventory_referenceNumber] :</h5>
        </div>
        <div class="col-6">
          <p>[voucher_referenceNumber]</p>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <div class="services my-2">
    <h3>Company Profile</h3>
    <div class="service-data px-4 py-1">
      <div class="row">
        <div class="col-2">
          <h5>Name :</h5>
        </div>
        <div class="col-5">
          <p>[voucher_company_name]</p>
        </div>

        <div class="col-2">
          <h5>[inventory_address] :</h5>
        </div>
        <div class="col-3">
          <p>[voucher_address]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <h5>Email :</h5>
        </div>
        <div class="col-5">
          <p>[voucher_business_email]</p>
        </div>

        <div class="col-2">
          <h5>[inventory_telephone] :</h5>
        </div>
        <div class="col-3">
          <p>[voucher_phone]</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <h5>Country :</h5>
        </div>
        <div class="col-5">
          <p>[voucher_country]</p>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <div class="services my-2">
    <h3>[inventory_serviceProviderDetails]</h3>
    <div class="service-data px-4 py-2">
      <div class="row">
        <div class="col-3">
          <h5>[inventory_hotelName] :</h5>
        </div>
        <div class="col-9">
          <p>[voucher_hotel_name]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <h5>[inventory_address] :</h5>
        </div>
        <div class="col-9">
          <p>[voucher_hotel_address]</p>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <h5>[inventory_telephone] :</h5>
        </div>
        <div class="col-9">
          <p>[voucher_hotel_phone]</p>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <div class="passenger my-2">
    <h3>[inventory_passengerDetails]</h3>
    <div class="passenger-data px-4 py-1">
      <div class="row">
        <div class="col-4">
          <h5>[inventory_passengerName] :</h5>
        </div>
        <div class="col-8">
          <p>[voucher_passenger_name]</p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h5>[inventory_passengerNationality] :</h5>
        </div>
        <div class="col-6">
          <p>[voucher_nationality]</p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h5>[inventory_CountryResidence] :</h5>
        </div>
        <div class="col-2">
          <p>[voucher_passenger_country]</p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h5>[inventory_serviceType] :</h5>
        </div>
        <div class="col-2">
          <p>[voucher_service_type]</p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h5>[inventory_city] :</h5>
        </div>
        <div class="col-2">
          <p>[voucher_city]</p>
        </div>
      </div>
      <div class="passenger-info my-1">
        <div class="row">
          <div class="mb-3 col-sm-4">
            <h6 class="">[inventory_checkin]</h6>
            <p class="font-weight-bold">[voucher_checkin]</p>
          </div>
          <div class="mb-3 col-sm-4">
            <h6>[inventory_checkOut]</h6>
            <p class="font-weight-bold">[voucher_checkout]</p>
          </div>
          <div class="mb-3 col-sm-4">
            <h6>[inventory_roomType]</h6>
            <p class="font-weight-bold">[voucher_roomType]</p>
          </div>

          <div class="mb-2 col-sm-8">
            <h6>[inventory_roomCategory]</h6>
            <p class="font-weight-bold">[voucher_roomCategory]</p>
          </div>
          <div class="mb-2 col-sm-4">
            <h6>[inventory_MealPlan]</h6>
            <p class="font-weight-bold">[voucher_rateBasis]</p>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-5">
          <h5>[inventory_additionalRequests] :</h5>
        </div>
        <div class="col-7">
          <p>[voucher_additionalRequests]</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div> `;
