import moment from 'moment';
import Locale from "translations";

export default function Booking({ bookingInfo }) {
	const { booking } = Locale;

	return (
		<div className="mt-2">
			<div className="border bg-white">
				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.HotelName}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.hotel?.name}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.residence}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.residence || "-"}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {"Total Price"}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.total_price}
						{bookingInfo?.currency}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView"> {"Craeted At"}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.created_at}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.Passengers}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{(+bookingInfo?.total_adults) + (+bookingInfo?.total_children)}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.TripStarts}</p>
					<p className="m-0 p-2 col-4 border-booking">
						<span className="text-success">
							{bookingInfo?.check_in ? moment(bookingInfo?.check_in).format("DD-MM-YYYY") : ""}
						</span>
						{" "} - {" "}
						<span className="text-danger">
							{bookingInfo?.check_out ? moment(bookingInfo?.check_out).format("DD-MM-YYYY") : ""}
						</span>
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView ">
						{"Reservation Status"}
					</p>
					<p
						className={`m-0 p-2 col-4 ${bookingInfo?.reservation_status !== "canceled"
							? "text-success"
							: "text-danger"
							}`}
					>
						{bookingInfo?.status}
					</p>
				</div>
			</div>
		</div>
	);
}
