import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { fetchCities } from "services/lookups";
import Locale from "translations";

export default function OfflineFilter({
	onReset,
	filterState,
	setFilterState,
	
}) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilterState({ ...filterState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setFilterState({
					...filterState,
					city: "",
					[name]: value,
				});
			} else setFilterState({ ...filterState, [name]: value });
		}
	};
	const { allCountries } = useGlobalState();
	//Fetch Countries
	useEffect(() => {
		async function countriesList() {
			const format = await allCountries?.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesList();
	}, [allCountries]);

	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (filterState.country?.value) {
				const res = await fetchCities(filterState.country?.value);
				const formatted = res?.data.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [filterState.country]);

	

	const resetFilters = () => {
		setFilterState({
			search: "",
			country: "",
			city: "",
		});
		setCities([]);
	};

	return (
		<>
			<div className="main-filter bg-white-blue py-2 mt-2">
				<div className="row align-items-center  w-100 no-gutter m-0">
					<div className="col-md-2">
						<div className="main-label">
							<TextField
								type="text"
								placeholder={backOffice.search}
								label={backOffice.search}
								/* 								hasLabel={false}
								 */ value={filterState.search}
								onChange={(e) => {
									setFilterState({
										...filterState,
										search: e.target.value,
									});
								}}
							/>
							<i className="fas fa-search fa-fw"></i>
						</div>
					</div>

					<div className="col-md-2">
						<SelectField
							label={backOffice.country}
							/* 							hasLabel={false}
							 */ placeholder={backOffice.country}
							name="country"
							options={countries}
							id="basic-countries"
							onChange={(e) => {
								handleChange({ name: "country", value: e });
							}}
							value={filterState.country}
						/>
					</div>

					<div className="col-md-2">
						<SelectField
							/* 							hasLabel={false}
							 */ label={backOffice.city}
							value={filterState.city}
							placeholder={backOffice.city}
							name="city"
							options={cities}
							onChange={(e) => {
								handleChange({ name: "city", value: e });
							}}
						/>
					</div>

					<div className="col-md" onClick={resetFilters}>
						<div
							onClick={onReset}
							className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
						>
							{/*               <i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
							 */}{" "}
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
