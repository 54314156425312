import axios from "axios";
const visaURL = process.env.REACT_APP_API_URL + "/api/online-visa";
const URL = process.env.REACT_APP_API_URL + "/api";
export const fetchType = async (id) => {
	return await axios
		.get(`${visaURL}/list/types/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCurrencies = async () => {
	return await axios
		.get(`${visaURL}/list/currencies`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addDestination = async (data) => {
	return await axios
		.post(`${visaURL}/add/destination`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const addNewDestination = async (data) => {
	return await axios
		.post(`${visaURL}/add-destination-residence-config`, data)
		.then((res) => res)
		.catch((err) => err);
};

///visa/configurations
export const viewConfigurations = async () => {
	return await axios
		.get(`${visaURL}/visa/configurations`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewNewConfigurations = async () => {
	return await axios
		.get(`${visaURL}/get-destination-residence-configs`)
		.then((res) => res)
		.catch((err) => err.response);
};
// export const emailAvailability = async (data) => {
// 	return await axios
// 		.post(`${URL}/check_avaliable_email`, data)
// 		.then((res) => res)
// 		.catch((err) => err);
// };

// export const nameAvailability = async (data) => {
// 	return await axios
// 		.post(`${URL}/check_avaliable_company_name`, data)
// 		.then((res) => res)
// 		.catch((err) => err);
// };

// export const activeAccount = async (data) => {
// 	return await axios
// 		.post(`${authURL}/active-account`, data)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

// export const verifyEmail = async (data) => {
// 	return await axios
// 		.post(`${URL}/check_company_and_user_status`, data)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

// export const userLogin = async (data) => {
// 	return await axios
// 		.post(`${URLAUTH}/api/auth/login`, data)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

// export const forgetPass = async (data) => {
// 	return await axios
// 		.post(`${URL}/forget-password`, data)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

// export const passReset = async (data) => {
// 	return await axios
// 		.post(`${URL}/forget-password/reset`, data)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

// export const accountRegister = async (data) => {
// 	return await axios
// 		.post(`${URL}/register`, data)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

export const fetchAllCountries = async () => {
	return await axios
		.get(`${URL}/lookups/list-countries`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchAllConsumers = async () => {
	return await axios
		.get(`${URL}/lookups/consumers`)
		.then((res) => res)
		.catch((err) => err.response);
};
