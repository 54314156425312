
function ListService({children}) {

  return (
    <>
    			<div className=" table-responsive px-4 our-border w-100">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>services</th>
							<th>Providers</th>
							<th>Control</th>
						</tr>
					</thead>
          <tbody>
          {children}
          </tbody>
				</table>
			</div>
    </>
  )
}

export default ListService