import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Bank({ bank }) {
	const [ViewMore, setViewMore] = useState(true);

	const toggleViewMore = () => {
		setViewMore(!ViewMore);
	};

	return (
		<tr key={bank.id}>
			<td className="w-25">{bank.currency}</td>

			{bank.countries.length <= 3 ? (
				<td className="w-25">
					{bank.countries.map((country) => {
						return <span className="mx-1">{country.name} ,</span>;
					})}
				</td>
			) : ViewMore ? (
				<td className="w-25">
					{bank.countries.slice(0, 3).map((country) => {
						return <span className="mx-1">{country.name} ,</span>;
					})}
					<p className="text-primary m-0 mx-1 pointer" onClick={toggleViewMore}>
						View More ({bank.countries.length - 3})
					</p>
				</td>
			) : (
				<td className="w-25">
					<div>
						{bank.countries.map((country) => {
							return <span className="mx-1">{country.name} ,</span>;
						})}
					</div>
					<p className="text-primary m-0 mx-1 pointer" onClick={toggleViewMore}>
						View Less
					</p>
				</td>
			)}

			<td className="w-25">{bank.bank_name}</td>
			<ShowForPermission permission="Bank-Transfer-Details-Manage">
				<td>
					<Link to={`/bank-configuration/edit/${bank.id}`}>
						<i class="fas fa-edit mx-3"></i>
					</Link>
				</td>
			</ShowForPermission>
		</tr>
	);
}
