import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useState } from "react";
import { isInclusivelyAfterDay } from "react-dates";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { updateSoldVisaExpireDate } from "services/Request";
import CustomModal from "../../Modal";

export default function ListSoldVisas({
	ListVisa,
	meta,
	goTo,
	setReload,
	reload,
}) {
	const { locale } = useGlobalState();
	const [editExpirtDateModal, setEditExpirtDateModal] = useState(false);
	const [newExpireData, setNewExpireData] = useState({
		date: null,
		visaId: null,
		currentExpireDate: null,
	});
	function toggleEditExpirtDateModal(visaId, currentExpireDate) {
		setEditExpirtDateModal(!editExpirtDateModal);
		setNewExpireData({ date: null, visaId, currentExpireDate });
	}
	async function updateExpireDate() {
		const data = {
			visa_request_id: newExpireData.visaId,
			expiration_date: moment(newExpireData.date).format("YYYY-MM-DD"),
		};
		const res = await updateSoldVisaExpireDate(data);
		if (res?.status === 200) {
			store.addNotification({
				title: "Done!",
				message: res?.data?.message || "Expire Date Update Successfuly",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setReload(!reload);
			toggleEditExpirtDateModal(null, null);
		}
	}

	return (
		<>
			<div className=" table-responsive px-4  our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>ID.</th>
							<th>Main Id Request.</th>
							<th>Travel Agent</th>
							<th>Source</th>
							<th>Destinations</th>
							<th>Vsia Type</th>
							<th>Visa count</th>
							<th>Departure Date</th>
							<th>Booking Date</th>
							<th>Request Expire Date</th>
							<th>Price</th>
							<th>Currency</th>
							<th>status</th>
						</tr>
					</thead>
					<tbody>
						{ListVisa?.map((res) => (
							<tr key={res?.id}>
								<td>{res?.id}</td>
								<td>{res.parent_id ? res.parent_id : "-"}</td>
								<td>{res?.company_name}</td>
								<td>{res?.source}</td>
								<td>{res?.destination}</td>
								<td>{res?.visa_type}</td>
								<td>
									<span>{res?.pax} </span>(
									<span className="text-danger">
										{`${res?.passengers_count}`}
									</span>
									/
									<span className="text-success">{`${res?.availability}`}</span>
									)
								</td>
								<td>{res?.departure_date}</td>
								<td>{res?.request_date}</td>
								<td>
									{moment(res?.expiration_date).format("YYYY-MM-DD")}
									<ShowForPermission permission="Manage-Sold-Visas">
										{res?.status === "pending" || res?.status === "expired" ? (
											<button
												className="btn p-0"
												onClick={() =>
													toggleEditExpirtDateModal(
														res?.id,
														res?.expiration_date
													)
												}
											>
												<i className="fas fa-edit mx-2"></i>
											</button>
										) : null}
									</ShowForPermission>
								</td>
								<td>{res?.total}</td>
								<td>{res?.currency}</td>
								<td className={`${res?.status}`}>{res?.status}</td>

								<td className={`${res?.status}`}>
									{/* <ShowForPermission permission="View-Sold-Visas"> */}
									<Link to={`/online-visa/view/request/waiting/${res?.id}`}>
										{locale === "en" ? (
											<i class="fas fa-angle-right"></i>
										) : (
											<i class="fas fa-angle-left"></i>
										)}
									</Link>
									{/* </ShowForPermission> */}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Pagination info={meta} goTo={goTo} />
			</div>
			<CustomModal
				modalIsOpen={editExpirtDateModal}
				toggle={toggleEditExpirtDateModal}
				size="md"
				centered
				header={"Edit Expire Date"}
			>
				<div className="">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"New Expire Date"}
						onOutsideClick={false}
						date={newExpireData.date}
						onChangeDate={(e) => {
							setNewExpireData({ ...newExpireData, date: e });
						}}
						isOutsideRange={(day) =>
							!isInclusivelyAfterDay(
								day,
								moment(newExpireData.currentExpireDate).add(1, "day")
							)
						}
					/>
					<button
						className="btn btn-primary w-100 mt-3"
						onClick={updateExpireDate}
						disabled={!newExpireData.date}
					>
						Save
					</button>
				</div>
			</CustomModal>
		</>
	);
}
