import DateFnsUtils from "@date-io/date-fns";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { AddOffer, EditOffer } from "services/platform";
import Locale from "translations";

export default function PlatformOffers({
	item,
	index,
	changeValueOffer,
	toggle,
	title,
	setReload,
}) {
	const { backOffice } = Locale;
	const [SpecialOffer, setSpecialOffer] = useState({ ...item });
	const { id } = useParams();

	const addOfferItem = async (e, index) => {
		if (id && e.id == null) {
			const res = await AddOffer(id, {
				from_date: moment(e.form).format("YYYY-MM-DD"),
				to_date: moment(e.to).format("YYYY-MM-DD"),
				value: e.offer,
			});
			if (res?.status === 200) {
				toggle();
				//changeValueOffer(index, "add", false, { ...e });
				store.addNotification({
					title: "Done!",
					message: res?.data?.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				//window.location.reload();
				//specialOfferList();
				setReload((prev) => !prev);
			}
		} else {
			const res = await EditOffer(id, e.id, {
				from_date: moment(e.form).format("YYYY-MM-DD"),
				to_date: moment(e.to).format("YYYY-MM-DD"),
				value: e.offer,
			});
			if (res.status === 200) {
				toggle();
				//changeValueOffer(index, "edit", false, { ...SpecialOffer });
				store.addNotification({
					title: "Done!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	return (
		<>
			<div className="row">
				<div className="col-6">
					<span className="text-black d-block mt-2">{backOffice.From}</span>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="my-2 DatePicker-Form maxW320"
							variant="outlined"
							inputVariant="outlined"
							id="date-picker-dialog"
							format="dd/MM/yyyy"
							value={SpecialOffer.form}
							onChange={(e) => {
								if (moment(SpecialOffer.to).isSameOrBefore(e)) {
									setSpecialOffer({
										...SpecialOffer,
										form: new Date(e),
										to: moment(e).add(1, "day"),
									});
								} else {
									setSpecialOffer({
										...SpecialOffer,
										form: new Date(e),
									});
								}
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>

				<div className="col-6">
					<span className="text-black d-block mt-2">{backOffice.To}</span>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="my-2 DatePicker-Form maxW320"
							variant="outlined"
							inputVariant="outlined"
							id="date-picker-dialog"
							format="dd/MM/yyyy"
							value={SpecialOffer.to}
							onChange={(e) => {
								if (moment(SpecialOffer.form).isSameOrAfter(e)) {
									setSpecialOffer({
										...SpecialOffer,
										to: new Date(e),
										form: moment(e).add(-1, "day"),
									});
								} else {
									setSpecialOffer({
										...SpecialOffer,
										to: new Date(e),
									});
								}
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
				<div className="col-6">
					<TextField
						type="text"
						label={backOffice.Offer}
						placeholder={backOffice.Offer}
						name="hotelCountry"
						value={SpecialOffer.offer}
						onChange={(e) => {
							setSpecialOffer({
								...SpecialOffer,
								offer: e.target.value,
							});
						}}
					/>
				</div>
			</div>
			<div className="row w-100">
				<div className="col-12 d-flex align-items-end justify-content-end pb-3">
					<button
						className="btn btn-sbs-primary w-25"
						onClick={() => {
							addOfferItem({ ...SpecialOffer }, index);
						}}
					>
						{title == "add" ? ` ${backOffice.add}` : `${backOffice.edit}`}
					</button>
				</div>
			</div>
		</>
	);
}
