
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ToggleServices, providerservices } from 'services/vendor';
import ServiceItem from './ServiceItem';

function AddService({toggle,allService,fetchData}) {
	const { id } = useParams();
  const [Service, setService] = useState([])
	const [SelectedService, setSelectedService] = useState([]);

  const CheckedService = (Name) => {

    if(SelectedService.includes(Name)){

      setSelectedService(prev => prev.filter(item => item !== Name))
    }else{
      
      setSelectedService([...SelectedService, Name]);
    }
 
	};
  const AddServices = async ()=>{
    const res = await ToggleServices(id,SelectedService)
    if(res.status === 200){
      fetchData()
      toggle()
    }
  }

  useEffect(() => {

    const CheckedService = allService.map((res)=>res.name)
    setSelectedService([...CheckedService]);
    const fetchServices = async ()=>{
      const res = await providerservices()
      if(res.status === 200){
        setService(res?.data?.data?.services)
      }
    }
    fetchServices()
  }, [])
  
  return (
    <>
      {Service?.map((res)=>{
     return  <ServiceItem key={res} Name={res} CheckedService={CheckedService} ischecked={SelectedService?.includes(res)} />
})}
    <button className="btn btn-success w-75 mx-5 mb-3" onClick={AddServices}>Add Service</button>
    </>
  )
}

export default AddService