import Model from "components/model";
import { useEffect, useState } from 'react';
import ServicesImages from '../Configuration/ServiceImg';

export default function AddServicesModal({ isOpen, toggleModal, allServices, addServices, selectedServices }) {
  const [checkedServices, setCheckedServices] = useState([])

  const checkedService = (serviceName) => {
    if (checkedServices.filter(checkedService => serviceName === checkedService?.name).length > 0) {
      setCheckedServices(prev => prev.filter(item => item?.name !== serviceName))
    } else {
      setCheckedServices([...checkedServices, { name: serviceName, providers: [] }]);
    }
  };

  useEffect(() => {
    setCheckedServices(selectedServices)
  }, [selectedServices]);

  return (
    <Model
      isOpen={isOpen}
      type={"add"}
      title={`Add Service`}
      toggle={toggleModal}
      size="md"
      hasFooter={false}
      hasHeader={true}
    >
      {allServices?.map(service => {
        return (
          <div className="col-6 item-service py-3" key={service?.name}>
            <div className="w-50 item-service justify-content-start">
              <input
                checked={checkedServices.filter(checkedService => service?.name === checkedService?.name).length > 0}
                type="checkbox"
                style={{ accentColor: "#b98637" }}
                id={service?.name}
                name={service?.name}
                onChange={() => {
                  checkedService(service?.name);
                }}
              />
              <label htmlFor={service?.name} className='pointer'>
                <ServicesImages name={service?.name} />
                {service?.name}
              </label>
            </div>
          </div>
        )
      })}
      <button className="accept-btn w-75 mx-5 mb-3"
        disabled={checkedServices?.length === 0}
        onClick={() => addServices(checkedServices)}
      >
        {selectedServices.length > 0 ? "Edit Services" : "Add Service"}
      </button>
    </Model>
  )
}
