import { useEffect, useState } from "react";

function PricesBox({ Transfer, currency }) {
	const [Total, setTotal] = useState(0);
	useEffect(() => {
		let totalPrice = 0;
		for (let i = 0; i < Transfer?.length; i++) {
			if (
				Transfer[i].status !== "CANCELED" &&
				Transfer[i].status !== "expired"
			) {
				totalPrice += Transfer[i].total_price;
				setTotal(totalPrice);
			}
		}
	}, []);
	return (
		<>
			<div className="col-price">
				{Transfer?.map((res) => (
					<>
						{res.status !== "CANCELED" && res.status !== "expired" ? (
							<div key={res.id} className="item-box">
								<div className="item-price">
									<p>
										{res?.type} {res?.category} {res?.vehicle}
									</p>
									<p>{`${res?.total_price} ${res?.currency}`}</p>
								</div>
								<span>
									From: {res?.from_name}
									<br />
									To: {res?.to_name}
								</span>
							</div>
						) : null}
					</>
				))}
				<div className="total-price">
					<p className="trans-dark">Total Price</p>
					<p className="trans-dark">
						{Total} {currency}
					</p>
				</div>
			</div>
		</>
	);
}

export default PricesBox;
