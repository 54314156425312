import DatePickerField from "components/shared/DatePickerField";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";

export default function ApplyFree({
	freeTrialDates,
	setFreeTrialDates,
	disabled,
	addPlanFn = () => {},
}) {
	return (
		<div className="apply-free">
			<div className="dates">
				<p>From</p>
				<div className="daye-item">
					<DatePickerField
						placeholder={"DD/MM/YYYY"}
						hasLabel={false}
						date={
							freeTrialDates?.start_date_free
								? moment(freeTrialDates?.start_date_free)
								: undefined
						}
						onChangeDate={(date) => {
							setFreeTrialDates({
								...freeTrialDates,
								start_date_free: date,
								end_date_free: null,
							});
						}}
						isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
					/>
				</div>

				<p>To</p>
				<div className="daye-item">
					<DatePickerField
						placeholder={"DD/MM/YYYY"}
						hasLabel={false}
						date={
							freeTrialDates?.end_date_free
								? moment(freeTrialDates?.end_date_free)
								: undefined
						}
						onChangeDate={(date) => {
							setFreeTrialDates({
								...freeTrialDates,
								end_date_free: date,
							});
						}}
						isOutsideRange={(day) =>
							!isInclusivelyAfterDay(
								day,
								moment(freeTrialDates?.start_date_free || moment())
							)
						}
					/>
				</div>

				<button
					className="btn btn-apply mx-3 ms-sm-auto"
					onClick={() => {
						addPlanFn();
					}}
					disabled={disabled}
				>
					Apply Free Trial
				</button>
			</div>
		</div>
	);
}
