import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { stopShareProperty } from "services/property";

export default function ConfirmModel({
	modal,
	toggleModal,
	selected,
}) {
	// const { price } = useMarketplaceState();

	const stopSharePropert = async () => {

		const res = await stopShareProperty(selected.id);

		if (res.status == 200 || res.status == 201) {
			store.addNotification({
				title: "info!",
				message: "Stop Share successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				
			});
			toggleModal()
		}
		console.log("res***", res);
	};
	return (
		<>
			<Modal
				size="md"
				className="price-model"
				isOpen={modal}
				toggle={toggleModal}
			>
				<ModalHeader className="align-items-center" toggle={toggleModal}>
					<p>Confirm Model</p>
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						<div className="text-center my-3">
							{/* <img className="mt-2" src={successImg} alt="" /> */}
							<p>Are you sure you want to stop share this property?

</p>
							<p>
								<button
									className="btn btn-success btn-lg fx-15 mx-2 mt-3"
									onClick={() => {
										stopSharePropert();
									}}
								>
									Yes
								</button>
								<button
									className="btn btn-danger btn-lg fx-15 mt-3"
									onClick={() => {
										toggleModal();
									}}
								>
									No
								</button>	
							</p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
