import axios from "axios";
import { cleanEmpty } from "./general";
const promoApi = process.env.REACT_APP_API_URL + "/api/hotel-promo";

export const fetchAllPromos = async (params = {}) => {
  return await axios
    .get(`${promoApi}/list`, { params: cleanEmpty(params), })
    .then((res) => res)
    .catch((res) => res);
};

export const togglePromoPublish = async (id) => {
  return await axios
    .get(`${promoApi}/toggle-publish/${id}`)
    .then((res) => res)
    .catch((res) => res);
};

export const addPromoCity = async (data) => {
  return await axios
    .post(`${promoApi}/add-city`, data)
    .then((res) => res)
    .catch((res) => res);
};
export const deletePromo = async (id) => {
  return await axios
    .delete(`${promoApi}/delete-city/${id}`,)
    .then((res) => res)
    .catch((res) => res);
};

export const viewPromoCity = async (id) => {
  return await axios
    .get(`${promoApi}/view-city/${id}`)
    .then((res) => res)
    .catch((res) => res);
};

export const editPromoCity = async (id, data) => {
  return await axios
    .post(`${promoApi}/edit-city/${id}`, data)
    .then((res) => res)
    .catch((res) => res);
};


