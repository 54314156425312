import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";

import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import { Link, useHistory, useParams } from "react-router-dom";
import Locale from "translations";

export default function Filter({ filter, setFilter, Groups, Branches }) {
	const { Companies, backOffice } = Locale;
	const { company_id } = useParams();

	const statusOptions = [
		{ value: 1, label: "Active" },
		{ value: 0, label: "Inactive" },
	];
	let history = useHistory();

	return (
		<>
			<div className="main-filter bg-white-blue py-2 my-2">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-2">
						<TextField
							type="text"
							placeholder={Companies.search}
							label={Companies.search}
							hasLabel={true}
							value={filter.name}
							onChange={(e) => {
								setFilter({
									...filter,
									name: e.target.value,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							hasLabel={true}
							placeholder={Companies.groups}
							label={Companies.groups}
							name="status"
							value={filter.group_id}
							options={Groups}
							onChange={(e) => {
								setFilter({
									...filter,
									group_id: e,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							hasLabel={true}
							placeholder={Companies.branch}
							label={Companies.branch}
							name="status"
							value={filter.branch_id}
							options={Branches}
							onChange={(e) => {
								setFilter({
									...filter,
									branch_id: e,
								});
							}}
						/>
					</div>

					<div className="col-2">
						<SelectField
							hasLabel={true}
							placeholder={backOffice.status}
							label={backOffice.status}
							name="status"
							value={filter.status}
							options={statusOptions}
							onChange={(e) => {
								setFilter({
									...filter,
									status: e,
								});
							}}
						/>
					</div>

					<div
						className="col-2"
						onClick={() => {
							setFilter({
								name: "",
								status: null,
								branch_id: null,
								group_id: null,
							});
						}}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>

					<div className=" col-2  d-flex justify-content-end">
						<ShowForPermission permission="Tourism-Company-Manage">
							<Link
								className="btn rounded companies-btn w-75"
								to={{
									pathname: `/tourism-company/add/employee/${company_id}`,
									state: { employee: {} },
								}}
							>
								{Companies.addEmployee}
							</Link>
						</ShowForPermission>
					</div>
				</div>
			</div>
		</>
	);
}
