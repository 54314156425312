import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import { listVendorToursReservation } from "services/vendor";
import Locale from "translations";
import Filter from "./filter";

const ToursReservation = () => {
	const { backOffice, vendor } = Locale;
	const { id } = useParams();
	const { locale } = useGlobalState();
	const [countries, setCountries] = useState([]);
	const [resvationList, setResvationList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		search: undefined,

		//created_at
		booking_date: undefined,
		//start_date
		tour_date: undefined,
		status: undefined,
		country: null,
		page: 1,
	});
	const statusClasses = (status) => {
		switch (status) {
			case "pending":
				return "text-light-yellow";
			case "confirmed":
			case "issued":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};
	const AllReservations =
		resvationList?.length > 0 ? (
			resvationList?.map((res, index) => (
				<tr>
					<td>{res.safa_reference}</td>
					<td>{res?.company?.name[locale]}</td>
					<td>{res.leader_name}</td>

					<td>{moment(res.confirmation_date).format("YYYY-MM-DD")} </td>
					<td>{moment(res.free_cancel_date).format("YYYY-MM-DD")} </td>
					<td>
						{res?.auto_cancel_date
							? moment(res.auto_cancel_date).format("YYYY-MM-DD")
							: "-"}{" "}
					</td>

					<td>
						{res.total_price} {res?.currency}
					</td>
					<td className={`${statusClasses(res.status)} text-capitalize`}>
						{res.status}
					</td>
					<td>
						<Link
							to={{
								pathname: `/manage-vendor/${id}/view/tours/${res.id}/booking`,
							}}
							className="d-inline-block action--btn"
						>
							<i class="fas fa-chevron-right mx-1"></i>
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};

	useEffect(() => {
		async function fetchReservation() {
			const res = await listVendorToursReservation(id, {
				search: filter.search,
				country_id: filter.country?.value,
				created_at: filter.booking_date
					? moment(filter.booking_date).format("YYYY-MM-DD")
					: null,
				start_date: filter.tour_date
					? moment(filter.tour_date).format("YYYY-MM-DD")
					: null,
				status: filter.status?.label,
				page: filter.page,
			});
			setResvationList(res?.data?.data);
			setMeta(res?.data?.meta);
		}

		async function fetchLookups() {
			const res = await fetchCountries();
			if (res.status === 200 || res?.data?.length > 0) {
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
				}));
				setCountries(format);
			}
		}
		fetchReservation();
		fetchLookups();
	}, [filter]);
	return (
		<>
			<Filter filter={filter} setFilter={setFilter} countries={countries} />
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Ref.</th>
							<th>Agancy</th>
							<th>Name</th>
							<th>Booking Date</th>
							<th>Free cancelation Date</th>
							<th>Auto cancelation</th>
							<th>Total Price</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>

					<tbody>{AllReservations}</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
};

export default ToursReservation;
