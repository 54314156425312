import { useParams } from "react-router-dom";
import Locale from "translations";
import walletBlue from "../../../../../assets/images/onlineVisa/walletBlue.svg";

export default function ViewRequestHeader({ header }) {
	const { requests, backOffice } = Locale;

	const { status, id } = useParams();

	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-12  justify-content-between    p-0">
					<div className="col-xl-2 col-12  py-5 bg-gray-100 text-center">
						<p className="m-0 font-weight-bold">{requests.TRANSPORTATION}</p>
						<p className="m-0 font-weight-bold"> {requests.REQUESTS}</p>
					</div>

					<div className="row col-6 col-xl-4 py-2 px-4">
						<div className="col-6 col-xl-5 text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.TravelAgent}</p>
							<p className="m-0 ">{requests.Destination}</p>
							<p className="m-0 ">{requests.CycleType}</p>
							{/* <p className="m-0 ">{requests.TransportType}</p> */}
							<p className="m-0 ">{requests.Pax}</p>
							<p className="m-0 ">{requests.VisaPriceOne}</p>
							<p className="m-0 ">{requests.RequestDate}</p>
						</div>

						<div className="col-6 col-xl-7 ">
							<p className="m-0">
								{header?.supplier_name ? header?.supplier_name : "-"}
							</p>

							<p className="m-0">{header?.destination?.name}</p>
							<p className="m-0">{header?.cycle_type?.name} Cycle</p>
							{/* <p className="m-0">{header?.transportation_type}</p> */}
							<p className="m-0">{header?.pax}</p>
							<p className="m-0">
								{header?.price_per_one} {header?.currency}
							</p>
							<p className="m-0">
								{header?.request_date ? header?.request_date : "-"}
							</p>
						</div>
					</div>

					<div className="row col-6 col-xl-4 py-2 ">
						<div className="col-6 col-xl-5  text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.Departure}</p>
							<p className="m-0 ">{requests.Return}</p>

							<p className="m-0 ">{requests.status}</p>
						</div>

						<div className="col-6 col-xl-7">
							<p className="m-0">{header?.departure_date}</p>
							<p className="m-0">{header?.return_date}</p>

							<p
								className={`m-0 ${
									header?.status === "approved" ? "text-success" : "text-danger"
								}`}
							>
								{header?.status}{" "}
							</p>
						</div>
					</div>

					<div className="d-flex align-items-end  justify-content-end col col-xl-2 p-0 ">
						<div className="d-flex  bg-gray-100 px-4 text-center ">
							<img src={walletBlue} alt="" className="online-vise-img" />
							<div className="mx-3">
								<p className="m-0 txt-blue">{requests.TotalPrice}</p>
								<p className="m-0 text-success font-weight-bold">
									{header?.total_price} {header?.currency?.toLocaleUpperCase()}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
