import { useEffect, useState } from "react";
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from "react-router-dom";
import { commercialDistHotelView } from 'services/commercialDistribution';
import Locale from "translations";
import Booking from "./Booking";
import Rooms from './Rooms';

export default function CommercialDistHotelReservationView() {
	const { id, brn } = useParams();
	const { booking, backOffice } = Locale;
	const { path, url } = useRouteMatch();
	const { push, location } = useHistory();
	const { pathname } = location;
	const [bookingData, setBookingData] = useState();

	useEffect(() => {
		const viewReservation = async () => {
			const res = await commercialDistHotelView(id, brn);
			setBookingData(res?.data?.data);
		};
		viewReservation();
	}, []);

	const tabsContent = [
		{
			id: 1,
			route: "booking",
			component: <Booking bookingInfo={bookingData} />,
		},
		{
			id: 2,
			route: "rooms",
			component: <Rooms bookingData={bookingData} />,
		},
	];

	return (
		<div className="vendor">
			<h3 className="txt-blue font-weight-bold col">Commercial Distribution</h3>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between  ">
				<div className="d-flex ">
					<button className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold ${pathname.endsWith("booking") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/booking`);
						}}
					>
						{booking.booking}
					</button>

					<button className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold ${pathname.endsWith("rooms") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/rooms`);
						}}
					>
						{backOffice.Rooms}
					</button>
				</div>
			</div>

			<Switch>
				{tabsContent.map((pane) => (
					<Route path={`${path}/${pane.route}`} key={pane?.route}>{pane.component}</Route>
				))}
			</Switch>
		</div>
	);
}
