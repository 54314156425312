import SelectField from "components/shared/SelectField";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { uploadFile } from "services/companies";
import {
	addNewDestination,
	fetchAllConsumers,
	fetchAllCountries,
	viewNewConfigurations,
} from "services/visa";
import Locale from "translations";
import AddVisa from "./addVisa/addVisa";

export default function VisaConfiguration() {
	const { backOffice } = Locale;
	const [arrayCountry, setArrayCountry] = useState([]);
	// let locale = localStorage.getItem("currentLocale") || "en";
	// const { allCountries } = useGlobalState();

	const [destination, setDestination] = useState([]);
	const [indexConfiguration, setIndexConfiguration] = useState(-1);
	const [ShowAdd, setShowAdd] = useState(false);

	const [inputsState, setInputsState] = useState([]);

	const add = () => {
		add2();
	};
	const [viewConfiguration, setviewConfiguration] = useState([]);
	const [country, setCountry] = useState();
	const [countryPhoto, setCountryPhoto] = useState({
		displayImg: undefined,
		img: undefined,
	});

	const add2 = async () => {
		debugger;
		if (viewConfiguration?.length > 0) {
			let x = viewConfiguration.map((res, index) => {
				return {
					destination_id: res.country_code,
					country_photo: res.country_photo_uuid ?? res.country_photo,
					terms: { en: res.terms.en, ar: res.terms.ar },
					visas: res.visa_types.map((rese) => {
						return {
							visa_type_id: rese.id,
							residences: rese.residences.map((residence, index) => {
								return {
									currency:
										typeof residence.currency === "string"
											? residence.currency
											: residence.currency.label,
									id: residence.id,
									price: +residence.price,
									transportation_type: residence?.transportation_type
										? residence?.transportation_type?.value
											? residence?.transportation_type?.value
											: residence?.transportation_type
										: "",

									residencesInfo:
										residence?.residencesNames?.findIndex(
											(obj) => obj.value === 0
										) !== -1
											? []
											: residence?.residencesNames?.length > 0 &&
											  residence?.residencesNames[0]?.country_id
											? residence?.residencesNames?.map(
													(item) => item.country_id
											  )
											: residence?.residencesNames?.map((item) => item.value),

									// add new fields
									nationalities:
										residence?.nationalities?.findIndex(
											(obj) => obj.value === 0
										) !== -1
											? []
											: residence?.nationalities?.length > 0 &&
											  residence?.nationalities[0]?.country_id
											? residence?.nationalities?.map((item) => item.country_id)
											: residence?.nationalities?.map((item) => item.value),

									start_date: residence?.start_date
										? moment(residence?.start_date).format("YYYY-MM-DD")
										: residence?.start_date,
									end_date: residence?.end_date
										? moment(residence?.end_date).format("YYYY-MM-DD")
										: residence?.end_date,
									expiration_days: +residence?.expiration_days,
									processing_time: residence?.processing_time,
									consumers:
										residence?.consumers && residence?.consumers.length > 0
											? residence?.consumers.map((res) => res.value || res.id)
											: [],
									uuid: residence?.uuid ? residence?.uuid : null,
									package_plans:
										residence?.package_plans?.length > 0
											? residence?.package_plans?.map((item) => ({
													plan_name: item.plan_name,
													range_from: item.range_from,
													range_to: item.range_to,
													package_price: item.package_price,
													visa_include: item?.visa_include?.map(
														(item) => item.name ?? item
													),
													residence_data: item?.residence_data ? 1 : 0,
													entry_stamp: item?.entry_stamp ? 1 : 0,
													notes: item?.notes,
											  }))
											: [],
								};
							}),
						};
					}),
				};
			});
			//cleanEmpty(x)
			const res = await addNewDestination({ destinations: x });
			if (res.status === 200) {
				view();
				store.addNotification({
					title: "Info!",
					message: res?.msg ? res?.msg : "done successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			const res = await addNewDestination({
				destinations: [],
				reset_config: 1,
			});
			if (res.status === 200) {
				view();
				store.addNotification({
					title: "Info!",
					message: res?.msg ? res?.msg : "done successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	/********* View Configuration  ********* */

	useEffect(() => {
		view();
		//window.location.reload();
	}, []);
	const view = async () => {
		const res = await viewNewConfigurations();

		if (res?.status === 200) {
			setviewConfiguration(res?.data?.data);
			setInputsState(res?.data?.data);
		}
	};
	const [countries, setCountries] = useState([]);
	const [consumers, setConsumers] = useState([]);
	const [countriesVisa, setCountriesVisa] = useState([]);

	useEffect(() => {
		async function countriesLookups() {
			const res = await fetchAllCountries();
			if (res.status === 200) {
				let format = res.data.data.map((t) => ({
					value: t.country_id,
					label: t.name,
					code: t.country_id,
				}));
				setCountries([{ label: "All", value: 0 }, ...format]);
				setCountriesVisa(format);
			}
		}
		countriesLookups();

		async function consumersLookups() {
			const res = await fetchAllConsumers();

			if (res.status === 200) {
				let format = res.data.data.map((t) => ({
					value: t.id,
					label: t.name,
					code: t.id,
				}));
				setConsumers(format);
			}
		}
		consumersLookups();
	}, []);
	const [isSelectCounter, setIsSelectCounter] = useState(false);
	const AddCountry = async () => {
		let formData = new FormData();
		formData.append("file", countryPhoto.img);
		let isImgSelected = "";
		if (countryPhoto.img) {
			isImgSelected = await uploadFile(formData);
		}

		if (isImgSelected.status === 200) {
			let x = viewConfiguration;
			x.push({
				country: country.label,
				country_code: country.value,
				country_photo: countryPhoto.img,
				country_photo_uuid: isImgSelected.data.uuid,
				country_photo_preview: countryPhoto.displayImg,
				terms: {
					en: "",
					ar: "",
				},
				visa_types: [],
			});
			setShowAdd(false);
			setviewConfiguration([...x]);

			setCountryPhoto({
				displayImg: undefined,
				img: undefined,
			});
			setIsSelectCounter((prev) => !prev);
		}
	};

	const isVisaTypesEmpty =
		viewConfiguration.filter((item) => item?.visa_types?.length === 0)?.length >
		0;
	// console.log("viewConfiguration00", isVisaTypesEmpty);

	return (
		<div className="visa-configuration">
			<div className="row d-flex justify-content-end w-100 pb-1">
				<ShowForPermission permission="Visa-Configuration-Manage">
					{ShowAdd ? (
						<>
							<div className="col-4 add-country-field-config ">
								<SelectField
									label={backOffice.country}
									placeholder={backOffice.country}
									options={countriesVisa}
									id="basic-countries"
									name="country"
									onChange={(e) => {
										setCountry(e);
									}}
									//value={country}
								/>
								{countryPhoto.displayImg && (
									<figure className="d-flex  justify-content-center ">
										<img
											src={`${countryPhoto.displayImg}`}
											width={150}
											height={150}
											alt="attachment"
										/>
									</figure>
								)}
								<button className="file-btn shape-border w-50 m-auto pointer">
									Add country photo
									<input
										type="file"
										className="custom-file"
										accept="image/png, image/gif,image/jpeg"
										onChange={(e) => {
											setCountryPhoto({
												displayImg: URL.createObjectURL(e.target.files[0]),
												img: e.target.files[0],
											});
										}}
									/>
								</button>
							</div>
							<div className="col-2 mt-4">
								<button
									className="btn btn-success px-5"
									onClick={() => AddCountry()}
									disabled={!(countryPhoto?.displayImg && country)}
								>
									Add
								</button>
							</div>
						</>
					) : (
						<button
							className="btn btn-success p-2 my-2"
							onClick={() => {
								setShowAdd(true);
							}}
						>
							<i class="fas fa-plus-circle text-white mx-1"></i>
							Add Visa Destination
						</button>
					)}
				</ShowForPermission>
			</div>
			{viewConfiguration &&
			viewConfiguration?.length > 0 &&
			countries &&
			countries.length > 0 ? (
				<>
					<AddVisa
						// country={country}
						destination={destination}
						setDestination={setDestination}
						indexConfiguration={indexConfiguration}
						setIndexConfiguration={setIndexConfiguration}
						inputsState={inputsState}
						setInputsState={setInputsState}
						arrayCountry={arrayCountry}
						setArrayCountry={setArrayCountry}
						viewConfiguration={viewConfiguration}
						setviewConfiguration={setviewConfiguration}
						country={country}
						countries={countries}
						consumers={consumers}
						isSelectCounter={isSelectCounter}
					/>
				</>
			) : null}

			<div className="row custom--row mt-2 flex-row-reverse pb-5 mb-5">
				<ShowForPermission permission="Visa-Configuration-Manage">
					<div className="mx-1">
						<button
							className="btn btn-primary w-100 px-5 visa-save-changes"
							onClick={add}
							disabled={ShowAdd || isVisaTypesEmpty}
						>
							{backOffice.save}
						</button>
					</div>
				</ShowForPermission>

				<div className="mx-1">
					<Link className={`btn btn-sbs-secondary w-100`} to={`/`}>
						{backOffice.back}
					</Link>
				</div>
			</div>
		</div>
	);
}
