import React, { useState } from "react";

import walletAdd from "assets/images/wallet/wallet-add.svg";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";
import { useHistory, useParams } from "react-router-dom";
import useWallet from "./useWallet";
import { payCredit } from "services/wallet";
import { store } from "react-notifications-component";

export default function SaddRequest() {
  const { wallet, translate_companies } = Locale;
  const { company_id } = useParams();
  const [amount, setAmount] = useState();
  const { debit, credit, paymentCard } = useWallet(company_id);
  let history = useHistory();

  const sadd = async () => {
    const res = await payCredit(
      {
        amount,
        transaction_type: "add",
      },
      company_id
    );
    if (res.status === 200) {
      store.addNotification({
        title: "Info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push(`/tourism-company/${company_id}/wallet`);
    }
  };

  return (
    <div className="wallet-wrraper" style={{ height: "70vh" }}>
      <div className="container p-0 display-card">
        <div className="create-card">
          <img src={walletAdd} alt="" />
          <p className="font-weight-bold my-2">
            {translate_companies.wallet_pay_header}
          </p>{" "}
          <div className="d-flex justify-content-between   w-100">
            <div className="balance_box p-4  sadd">
              <p>{translate_companies.company_balance}</p>
              <p className="font-weight-bold">{debit}</p>
              <p>{translate_companies.egyptian_pound}</p>
            </div>
            <div className="balance_box  p-4  sadd ">
              <p>{translate_companies.wallet_safa_account}</p>
              <p className="font-weight-bold">
                {credit?.amount} / {credit?.limit}
              </p>
              <p>{translate_companies.egyptian_pound}</p>
            </div>
          </div>
          <div className="w-100 my-3">
            <TextField
              type="text"
              placeholder={wallet.amount}
              hasLabel={false}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            disabled={amount === undefined}
            class="btn btn-success w-100"
            onClick={() => {
              sadd();
            }}
          >
            {translate_companies.wallet_pay_button}
          </button>
        </div>
      </div>
    </div>
  );
}
