import domesticIcon from 'assets/images/icons/domesticFlight.svg';
import { useGlobalState } from 'context/global';

function InternalItem({ element }) {
  const { locale } = useGlobalState();
  return (
    <div className='package-item-container'>
      <div className='package-item'>
        <div className='icon-item'>
          <img src={domesticIcon} alt="" />
        </div>
        <div className='d-flex align-items-center flex-column m-2'>
          {element?.itemable?.transporter_name?.[locale]}
        </div>
        {/* external from */}
        {/* <div className='d-flex flex-column m-2' >
          <p className='airprot-name'>
            El Nouzha Airport
          </p>
          <span className='country-name'>
            Egypt
          </span>
          <span className='date-name'>
            17/06/2022
          </span>
        </div> */}

        {/* path  */}
        {/* <div className='path-details'>
          <div className='path-start-point'></div>
          <span className='path-stop'>1 Stop</span>
          <img src={dirctimg} alt="" />
        </div> */}

        {/* extrenal to */}
        {/* <div className='d-flex flex-column m-2' >
          <p className='airprot-name'>
            El Nouzha Airport
          </p>
          <span className='country-name'>
            Egypt
          </span>
          <span className='date-name'>
            17/06/2022
          </span>
        </div> */}

      </div>
    </div>
  )
}

export default InternalItem