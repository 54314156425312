import axios from "axios";
import { useState } from "react";
import { store } from "react-notifications-component";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
	airlineHandleRefundPending,
	airlineHandleWaiting,
} from "services/airlines";
import Locale from "translations";
//import ConfirmPendingRequestModal from "../Reservations/view/Requests/Modals/ConfirmPendingRequest";
import ConfirmPendingRequestModal from "./Modals/ConfirmPendingRequest";
import RejectRequestModal from "./Modals/RejectRequest";
import RequestItemHeader from "./RequestItemHeader";

export default function RefundRequest({ request, fetchData, isView = false }) {
	const { backOffice, translate_companies } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [confirmPendingModal, setConfirmPendingModal] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [rejectReason, setRejectReason] = useState("");

	function toggleCollapse() {
		setIsOpen(!isOpen);
	}
	function toggleConfirmPendingModal() {
		setConfirmPendingModal(!confirmPendingModal);
	}

	function toggleConfirmModal(xType = "accept") {
		setConfirmModalOpen({
			...confirmModalOpen,
			status: !confirmModalOpen.status,
			type: xType,
		});
	}
	function toggleRejectModal() {
		setRejectModal(!rejectModal);
	}

	const handleAcceptWaiting = async (status) => {
		let data;
		if (status === "reject") {
			data = {
				request_id: request.id,
				action: status,
				reject_reason: rejectReason,
			};
		} else {
			data = {
				request_id: request.id,
				action: status,
			};
		}
		const res = await airlineHandleWaiting("refund", data);
		if (res.status === 200 || res.status === 201) {
			toggleConfirmModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	async function handleRejectRequest(e) {
		let data = {
			request_id: request.id,
			action: "reject",
			reject_reason: e.reject_reason,
		};
		const res = await airlineHandleRefundPending(data);
		if (res.status === 200 || res.status === 201) {
			toggleRejectModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	const handleAcceptRequest = async (e) => {
		let data = {
			request_id: request.id,
			action: "accept",
			amount: e.amount,
		};
		const res = await airlineHandleRefundPending(data);
		if (res.status === 200 || res.status === 201) {
			toggleConfirmPendingModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	function downloadCertFiles() {
		const certFiles = request?.certification_files;
		const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
		const config = {
			fetchFilesURL,
			method: "GET",
			responseType: "blob",
		};
		for (let i = 0; i < certFiles.length; i++) {
			axios.get(`${fetchFilesURL}/${certFiles[i]}`, config).then((res) => {
				const blob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const objectUrl = window.URL.createObjectURL(blob);
				const anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`${certFiles[i]}.${res.data.type.split("/")[1]}`
				);
				anchor.click();
			});
		}
	}

	// "pending" | "rejected" | "pending approval" | "under review" | "confirmed";
	const status = request?.status;
	console.log("status", status);
	const passengersType = {
		ADT: "Adult",
		CHD: "Child",
	};
	return (
		<div className="my-3">
			<RequestItemHeader
				isOpen={isOpen}
				toggleCollapse={toggleCollapse}
				details={request}
				requestTypeText={backOffice.refundRequest}
				status={status}
				isView={isView}
			/>

			<Collapse isOpen={isOpen}>
				<div className="flight-requests-details">
					<div className="row m-0">
						<div className="col-md-6 col-12">
							{/* passengers data */}
							<div className="d-flex flex-column">
								<h2 className="text-capitalize blue h6">
									{backOffice.passengers}
								</h2>
								{request?.passengers?.map((passenger) => {
									return (
										<div
											className="flight-requests-refund-passenger"
											key={`${passenger?.id}-${passenger?.flight_request_id}`}
										>
											<div className="col-4">
												<i className="far fa-user-circle"></i>
												<span className="mx-1">
													{passenger?.passenger_info?.full_name}
												</span>
											</div>
											<span className="col-3">
												{passengersType?.[passenger?.passenger_info?.type]}
											</span>
											<span className="col-5">
												Ticket No. {passenger?.passenger_info?.ticketNumber}
											</span>
										</div>
									);
								})}
							</div>

							{/* refund reason */}
							<div className="mt-3">
								<h3 className="my-2 text-capitalize blue h6">
									{backOffice.refundReason}
								</h3>
								<div className="d-flex align-items-center justify-content-between">
									<span className="flight-requests-box p-1">
										{request?.reason}
									</span>

									{request?.certification_files &&
										request?.certification_files?.length > 0 && (
											<button
												className="download-cert"
												onClick={downloadCertFiles}
											>
												<i class="fas fa-download"></i>
												<span className="mx-1">
													{backOffice.downloadCertificationFile}
												</span>
											</button>
										)}
								</div>
								{request?.remarks && (
									<p className="flight-requests-box p-1 mt-2 lh-1">
										{request?.remarks}
									</p>
								)}
							</div>
						</div>

						<div className="col-md-6 col-12">
							{/* contact info */}
							<RequestContactInfo requestDetails={request} />

							{/* actions and status */}
							{status === "rejected" && request?.reject_reason && (
								<div className="flight-requests-danger-box py-1 px-2 mt-3">
									<h3 className="text-capitalize my-2">
										{backOffice.refundRejectReason}
									</h3>
									<p>{request?.reject_reason}</p>
								</div>
							)}

							{status === "pending" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									{/* <h3 className='text-capitalize mb-2 h6'>{backOffice.refundAmount}</h3> */}
									{/* <p className='d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white'>
                    <span>{backOffice.amount}</span>
                    <span className='text-danger'>180.00 SAR</span>
                  </p> */}
									<div className="flight-requests-refund-actions mt-2">
										<button
											className="reject-btn mx-3"
											onClick={toggleRejectModal}
										>
											{translate_companies.reject}
										</button>

										<button
											className="accept-btn"
											onClick={toggleConfirmPendingModal}
										>
											{backOffice.confirm}
										</button>
									</div>
								</div>
							)}

							{status === "waiting_approval" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<div className="flight-requests-refund-actions mt-2">
										<button
											className="reject-btn mx-3"
											onClick={() => toggleConfirmModal("reject")}
										>
											{translate_companies.reject}
										</button>

										<button
											className="accept-btn"
											onClick={() => toggleConfirmModal("accept")}
										>
											{backOffice.confirm}
										</button>
									</div>
								</div>
							)}

							{(status === "under review" || status === "confirmed") && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2 blue h6">
										{backOffice.refundAmount}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{backOffice.amount}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</Collapse>

			{/* confirm request modal */}
			{confirmModalOpen.status && (
				<Modal isOpen={confirmModalOpen.status} centered={true}>
					<ModalHeader toggle={toggleConfirmModal}>
						<span>
							{" "}
							{confirmModalOpen.type === "accept"
								? backOffice.confirmRequest
								: "Reject Request"}
						</span>
					</ModalHeader>
					<ModalBody>
						<div className="p-3 text-center">
							{confirmModalOpen.type === "accept" ? (
								<i
									className="fas fa-question-circle"
									style={{ fontSize: "3.6rem", color: "#F0E5D3" }}
								/>
							) : (
								<textarea
									placeholder={`${backOffice.reasonForRejection}...`}
									name="reject-reason"
									id="reject-reason"
									rows="5"
									className="w-100 control-field__input h-auto"
									value={rejectReason}
									onChange={(e) => setRejectReason(e.target.value)}
								/>
							)}
							{confirmModalOpen.type === "accept" && (
								<p className="py-4 h5 font-weight-bold">
									{backOffice.youSureConfirmRequest}
								</p>
							)}
							<button
								className={`flight-checkout-btn w-100 font-weight-bold border-0 rounded py-2 ${
									confirmModalOpen.type === "accept"
										? "accept-btn"
										: "reject-btn"
								}`}
								disabled={
									confirmModalOpen.type !== "accept" &&
									rejectReason.length === 0
								}
								onClick={() => handleAcceptWaiting(confirmModalOpen.type)}
							>
								{confirmModalOpen.type === "accept"
									? backOffice.confirm
									: backOffice.reject}
							</button>
						</div>
					</ModalBody>
				</Modal>
			)}

			{rejectModal && (
				<RejectRequestModal
					isOpen={rejectModal}
					toggle={toggleRejectModal}
					submitReject={handleRejectRequest}
				/>
			)}
			{confirmPendingModal && (
				<ConfirmPendingRequestModal
					isOpen={confirmPendingModal}
					toggle={toggleConfirmPendingModal}
					submitAmount={handleAcceptRequest}
				/>
			)}
		</div>
	);
}

export function RequestContactInfo({ requestDetails }) {
	const { backOffice } = Locale;
	return (
		<>
			<h2 className="text-capitalize blue h6">{backOffice.contact}</h2>
			<div className="flight-requests-contact">
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{backOffice.contactName}</span>
					<span>{requestDetails?.contact_info?.full_name}</span>
				</p>
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{backOffice.email}</span>
					<span>{requestDetails?.contact_info?.email}</span>
				</p>
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{backOffice.cellphone}</span>
					<span>
						{requestDetails?.contact_info?.phone_code}
						{requestDetails?.contact_info?.phone}{" "}
					</span>
				</p>
			</div>
		</>
	);
}
