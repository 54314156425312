import { Table } from "reactstrap";

const GENDER = { M: "Male", F: "Female" };

function PnrList({ list }) {
	return (
		<div className="table-container mt-3">
			<p className="mb-1">PNR List</p>
			<Table
				striped
				className="table-update p-2 bg-white flight-inventory-table border-light"
				style={{ verticalAlign: "center", borderRadius: "10px" }}
			>
				<thead>
					<tr>
						<th>Segment</th>
						<th>Flight</th>
						<th>PAX Name(PTC)</th>
						<th>Gender</th>
						<th>PNR</th>
						<th>Airline PNR</th>
						<th>TKT Number</th>
					</tr>
				</thead>
				<tbody>
					{list?.length > 0
						? list?.map((item) => {
							return (
								<tr>
									<td>{`${item?.departure?.code} - ${item?.arrival?.code}`}</td>
									<td>{item?.flight_num}</td>
									<td>{item?.passenger_name}</td>
									<td>{GENDER[item?.passenger_sex]}</td>
									<td>{item?.pnr || "-"}</td>
									<td>{item?.air_pnr || "-"}</td>
									<td>{item?.ticket_number || "-"}</td>
								</tr>
							);
						})
						: null}
				</tbody>
			</Table>
		</div>
	);
}

export default PnrList;
