import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";

import { add_Season, editSeason, listSeasons } from "services/seasons";
import Locale from "translations";

import validate, { isFormValid } from "helpers/validate";

const AddEditSeason = ({
	index,
	SeasonsSelect,
	addSeason,
	SeasonID,
	ModelSeasons,
	SeasonsSelectIndex,
	setSeasonList,
}) => {
	const { backOffice } = Locale;
	const [seasonState, setSeasonState] = useState({
		seasonNameEN: SeasonID ? SeasonsSelect?.name_en : "",
		seasonNameAR: SeasonID ? SeasonsSelect?.name_ar : "",
		seasonStartDate: SeasonID ? SeasonsSelect?.start_date : moment(new Date()).add(1,'d').format("YYYY-MM-DD"),
		seasonEndDate: SeasonID ? SeasonsSelect?.end_date : moment(new Date()).add(2,'d').format("YYYY-MM-DD"),
		status: SeasonID ? SeasonsSelect?.is_active : "",
	});

	console.log("SeasonsSelect", SeasonsSelect);
	console.log("SeasonID", SeasonID);

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [Status, setStatus] = useState();

	useEffect(() => {
		if (ModelSeasons === "edit") {
			getStatus(SeasonsSelect?.is_active);
		}
	}, []);

	const getStatus = (status) => {
		let Returned = "";

		switch (status) {
			case 1:
				Returned = {
					value: true,
					label: "Active",
				};
				break;
			case 0:
				Returned = {
					value: false,
					label: "Inactive",
				};
				break;
			default:
				Returned = {
					value: false,
					label: "Inactive",
				};
				break;
		}
		setStatus(Returned);

		return Returned;
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "season_nameEN", value: seasonState.seasonNameEN },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "season_nameAR", value: seasonState.seasonNameAR },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "season_start_date", value: seasonState.seasonStartDate },
				{ required: true }
			),
			...validate(
				{ name: "season_end_date", value: seasonState.seasonEndDate },
				{ required: true }
			),
			...validate(
				{ name: "status", value: seasonState.status },
				{ required: true }
			),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setSeasonState({ ...seasonState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setSeasonState({ ...seasonState, [name]: value });
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			addNewSeason();
		}
	}, [isErrorLoaded]);

	const addNewSeason = async () => {
		if (ModelSeasons === "edit") {
			let payload = {
				name: {
					en: seasonState.seasonNameEN,
					ar: seasonState.seasonNameAR,
				},
				start_date: seasonState.seasonStartDate,
				end_date: seasonState.seasonEndDate,
				is_active: seasonState.status?.value,
			};

			const res = await editSeason(SeasonID, payload);
			if (res.status === 200) {
				const resplatform = await listSeasons();
				setSeasonList(resplatform?.data?.seasons);
				store.addNotification({
					title: "Done!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				addSeason(index);
			}
		} else {
			let payload = {
				name: {
					en: seasonState.seasonNameEN,
					ar: seasonState.seasonNameAR,
				},
				start_date: seasonState.seasonStartDate,
				end_date: seasonState.seasonEndDate,
				is_active: seasonState.status?.value,
			};

			const res = await add_Season(payload);
			if (res.status === 200) {
				const resplatform = await listSeasons();
				setSeasonList(resplatform?.data?.seasons);
				store.addNotification({
					title: "Done!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				addSeason(index);
			}
		}
	};

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const statusOptions = [
		{
			value: true,
			label: "Active",
		},
		{
			value: false,
			label: "Inactive",
		},
	];
	return (
		<>
			<div className="row custom--row">
				<div className="col-md-6">
					<TextField
						type="text"
						label={backOffice.seasonNameEN}
						placeholder={backOffice.seasonNameEN}
						name="season_nameEN"
						value={seasonState.seasonNameEN}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
							setSeasonState({
								...seasonState,
								seasonNameEN: e.target.value,
							});
						}}
						min={3}
						color={
							errors?.season_nameEN?.required || errors?.season_nameEN?.min
								? "danger"
								: ""
						}
						errors={errors?.season_nameEN}
					/>
				</div>

				<div className="col-md-6">
					<TextField
						type="text"
						label={backOffice.seasonNameAR}
						placeholder={backOffice.seasonNameAR}
						name="season_nameAR"
						value={seasonState.seasonNameAR}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
								}),
							});
							setSeasonState({
								...seasonState,
								seasonNameAR: e.target.value,
							});
						}}
						min={3}
						color={
							errors?.season_nameAR?.required || errors?.season_nameAR?.min
								? "danger"
								: ""
						}
						errors={errors?.season_nameAR}
					/>
				</div>
			</div>

			<div className="row custom--row px-2">
				<div className="col-md-6 px-1 ">
					<DatePickerField
						label={backOffice.seasonStartDate}
						date={moment(seasonState?.seasonStartDate)}
						name="season_start_date"
						onChangeDate={(date) => {
							setSeasonState({
								...seasonState,
								seasonStartDate: date?._d,
								
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
						color={errors?.season_start_date?.required ? "danger" : ""}
						errors={errors?.season_start_date}
					/>
				</div>
				<div className="col-md-6">
					<DatePickerField
						label={backOffice.seasonEndDate}
						date={moment(seasonState?.seasonEndDate)}
						name="season_end_date"
						onChangeDate={(date) => {
							setSeasonState({
								...seasonState,
								seasonEndDate: date?._d,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
						color={errors?.season_end_date?.required ? "danger" : ""}
						errors={errors?.season_end_date}
					/>
				</div>
			</div>

			<div className="row custom--row">
				<div className="col-md-4">
					<SelectField
						label={backOffice.status}
						placeholder={backOffice.select + " " + backOffice.status}
						options={statusOptions}
						name="status"
						value={Status}
						onChange={(e) => {
							handleChange({ name: "status", value: e });
							setSeasonState({
								...seasonState,
								status: e,
							});
							setStatus(e);
						}}
						errors={errors.status}
						color={errors.status?.required ? "danger" : ""}
					/>
				</div>
			</div>

			<div className="row custom--row mt-2 flex-row-reverse">
				<div className="col-md-3">
					<button className="btn btn-sbs-primary w-100" onClick={submit}>
						{ModelSeasons === "edit" ? backOffice.edit : backOffice.add}
					</button>
				</div>
			</div>
		</>
	);
};

export default AddEditSeason;
