import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import Locale from "translations";

export default function Model(props) {
	const { teamManagement } = Locale;
	const type = props.type;

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				className={props.className}
				size={props.size}
				toggle={props.toggle}
			>
				{props.hasHeader ? (
					<ModalHeader className="font-weight-bold" toggle={props.toggle}>
						{props.title}
					</ModalHeader>
				) : null}
				<ModalBody className="py-0">
					<Row className="mx-0">{props.children}</Row>
				</ModalBody>
				{props.hasFooter ? (
					<ModalFooter>
						<Button
							color="primary"
							onClick={props.submit}
							disabled={props.disabled}
						>
							{type === "create" && teamManagement.add}
							{type === "update" && teamManagement.edit}
							{type === "delete" && teamManagement.delete}
							{type === "ok" && teamManagement.ok}
						</Button>
						<Button color="secondary" onClick={props.toggle}>
							{teamManagement.cancel}
						</Button>
					</ModalFooter>
				) : null}
			</Modal>
		</>
	);
}

Model.defaultProps = {
	hasFooter: true,
};
