import { Modal, ModalBody } from "reactstrap";

const CustomModal = ({ modalIsOpen, size, toggle, header, children, centered }) => {




  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        toggle={toggle}
        size={size}
        centered={centered}
      >
        <div className=" p-3  border-bottom d-flex justify-content-between">
          <h4 className="mb-0  font-weight-bold">{header}</h4>
          <span className="close-modal fas fa-times text-gray pointer" onClick={toggle}></span>
        </div>


        <ModalBody >
          <div>
            {children}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CustomModal;