import { useEffect, useState } from "react";

function PricesBox({ Transfer, currency }) {
	const [Total, setTotal] = useState(0);
	useEffect(() => {
		let totalPrice = 0;
		for (let i = 0; i < Transfer?.length; i++) {
			totalPrice += Transfer[i].total_price;
			setTotal(totalPrice);
		}
	}, []);
	return (
		<>
			<div className="col-price">
				{Transfer?.map((res) => (
					<>
						<div key={res.id} className="item-box">
							<div className="item-price">
								<p>
									{res?.type} {res?.category} {res?.vehicle}
								</p>
								<p>{`${res?.total_price} ${res?.currency}`}</p>
							</div>
							<span>
								From: {res?.from_name}
								<br />
								To: {res?.to_name}
							</span>
						</div>
					</>
				))}
				<div className="">
					<p className="trans-dark-light mb-0">Reservation Total Cost</p>
					<p className="text-success fw-bold fs-5">
						{Total} {currency}
					</p>
				</div>
			</div>
		</>
	);
}

export default PricesBox;
