import { fetchCalendarFormat } from "data/calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchCalendar } from "services/calendar";
import { getListRooms } from "services/rateplan";
import CalendarRoom from "./CalendarRoom";
import Filter from "./Filter";

export default function Calendar({ rooms }) {
	const { id } = useParams();
	const [roomList, setRoomList] = useState([]);
	const [roomCalender, setRoomCalender] = useState(rooms || []);
	const [days, setDays] = useState([]);
	const [filters, setFilters] = useState({
		rooms: [],
		dateStart: moment(new Date()),
		dateEnd: moment(new Date()),
	});

	const getViewCalendar = async (filters) => {
		const { id, date_from, date_to, rooms } = fetchCalendarFormat({
			...filters,
		});

		if (moment(date_to).isAfter(date_from)) {
			const req = await fetchCalendar(id, date_from, date_to, rooms);
			setRoomCalender([]);

			setTimeout(() => {
				if (req.hasOwnProperty("data")) {
					setRoomCalender(req.data);
					getDays(date_from, date_to);
					return req;
				}
			}, 20);
		}
	};

	console.log("id-------", id);

	async function getDays(startDate, endDate) {
		let dates = [];
		let startloob;
		startloob = startDate;
		if (startDate !== "Invalid date" && endDate !== "Invalid date") {
			while (moment(startloob._d).format("YYYY-MM-DD") !== endDate) {
				if (
					moment(startloob._d).format("YYYY-MM-DD") !==
					moment().format("YYYY-MM-DD")
				) {
					dates.push(moment(startloob._d).format("YYYY-MM-DD"));
				}
				startloob = moment(startloob).add(1, "days");
			}
		}
		setDays(dates);
	}

	useEffect(() => {
		if (roomList.length === 0) {
			const fetchRooms = async () => {
				const req = await getListRooms(id);
				const formatted = req.data.data.map((room) => ({
					value: room.id,
					label: room.custom_name !== null ? room.custom_name : room.name.name,
				}));
				setRoomList(formatted);
			};
			fetchRooms();
		}
	}, [roomList]);

	return (
		<>
			<div className="col-md-12">
				<Filter
					getViewCalendar={getViewCalendar}
					id={id}
					setFilters={setFilters}
					filters={filters}
					rooms={roomList}
				/>
				{roomCalender.length > 0
					? roomCalender.map((room) => (
							<CalendarRoom
								days={days}
								id={id}
								filters={filters}
								getViewCalendar={getViewCalendar}
								room={room}
								key={room}
							/>
					  ))
					: ""}
			</div>
		</>
	);
}
