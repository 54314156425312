import Model from "components/model";
import ShowForPermission from 'helpers/showForPermission';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ConfigurationsModules } from "services/vendor";
import AddService from "./Services/AddService";
import ListService from "./Services/ListServices";
import ListBody from "./Services/ListServices/ListBody";
import VendorModule from "./VendorModule";

function VendorConfigurations({ allService, fetchData }) {
	const { id } = useParams();
	const [isOpen, setisOpen] = useState(false);
	const [Modules, setModules] = useState([]);
	const [isMarketplaceActive, setisMarketplaceActive] = useState({});
	const [reload, setReload] = useState(false);

	const fetchModules = async () => {
		const res = await ConfigurationsModules(id);
		if (res.status === 200) {
			setModules(res.data);
			const marketplace = res.data?.filter(
				(req) => req.name == "Marketplace"
			)[0];
			setisMarketplaceActive({ ...marketplace });
		}
	};

	const toggle = () => {
		setisOpen(!isOpen);
	};
	useEffect(() => {
		fetchModules();
	}, [reload]);

	return (
		<>
			<div className="pt-4 all-modules">
				{Modules?.map((res) => {
					return (
						<VendorModule
							Name={res.name}
							status={res.is_active}
							setReload={setReload}
						/>
					);
				})}
			</div>

			{allService?.length > 0 ? (
				<>
					<div className="bg-white border md-border-radius d-flex align-items-center justify-content-center flex-wrap">
						<div className="w-100 text-end mt-2">
							<ShowForPermission permission="Vendors-Management-Manage">
								<button className="btn btn-success m-4 " onClick={toggle}>
									{" "}
									Add Service
								</button>
							</ShowForPermission>
						</div>
						<ListService>
							{allService?.map((res) => (
								<ListBody Service={res} />
							))}
						</ListService>
					</div>
				</>
			) : (
				<>
					<div className="bg-white py-4 border md-border-radius d-flex align-items-center justify-content-center flex-wrap">
						<button className="btn btn-success" onClick={toggle}>
							{" "}
							Add Service
						</button>
					</div>
				</>
			)}

			<Model
				isOpen={isOpen}
				type={"add"}
				title={`Add Service`}
				toggle={toggle}
				size="md"
				hasFooter={false}
				hasHeader={true}
			>
				<AddService
					toggle={toggle}
					fetchData={fetchData}
					allService={allService}
				/>
			</Model>
		</>
	);
}

export default VendorConfigurations;
