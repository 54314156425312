import FilterInvoicesReport from './FilterInvoicesReport'
import ListReportInvoices from './ListReportInvoices'

function InvoicesReport() {
  return (
    <>
    <h4>Invoices Report</h4>
    <FilterInvoicesReport />
    <ListReportInvoices />
    </>
  )
}

export default InvoicesReport