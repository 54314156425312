function StatisticItem({ title, number, img, className }) {
	return (
		<div className={`${className}`}>
			<div className="stat-item ">
				<p className="stat-title">{title}</p>
				<div className="d-flex justify-content-between align-items-center">
					<h2 className="stat-num">{number}</h2>
					<img src={img} alt="" srcset="" />
				</div>
			</div>
		</div>
	);
}

export default StatisticItem;
