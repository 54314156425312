import InfoIcon from "assets/images/Featured icon.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function ChangeStatus({
	isOpen,
	toggle,
	Status,
	setStatus,
	ChangeStatus,
	isStatusFree,
}) {
	return (
		<Modal
			isOpen={isOpen}
			className="change-status-plan-model"
			size={"md"}
			toggle={toggle}
		>
			<ModalHeader
				className="font-weight-bold"
				toggle={() => {
					toggle();
					setStatus({
						status: "canceled",
						refundAmount: null,
					});
				}}
			>
				<img src={InfoIcon} alt="" width={48} />
			</ModalHeader>
			<ModalBody className="py-0">
				<div className="title">
					<h6>
						{Status.status === "canceled" ? "Cancel Plan" : "Confirm Plan"}
					</h6>
					<p>
						{Status.status === "canceled"
							? "Are you sure you need to cancel plan"
							: "Are you sure you need to Confirm Plan"}
					</p>
				</div>
				<div className="input">
					<>
						{!isStatusFree && (
							<>
								{" "}
								<label>Refund Amount</label>
								<input
									type="number"
									value={Status.refundAmount}
									placeholder="Enter Amount"
									onChange={(e) => {
										setStatus({ ...Status, refundAmount: e.target.value });
									}}
								/>
							</>
						)}

						<button
							className="btn btn-apply"
							disabled={isStatusFree ? false : !Status.refundAmount}
							onClick={() => ChangeStatus()}
						>
							Save & Apply
						</button>
					</>

					{Status.status !== "canceled" && (
						<button className="btn btn-apply" onClick={() => ChangeStatus()}>
							Confirm
						</button>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
}
