import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import {
	createOfflineRequest,
	fetchHotelNamesLookups,
	fetchMealPlansLookups,
	fetchRoomTypesLookups,
	fetchRoomViewsLookups,
	fetchSuppliersLookups,
} from "services/sbsReservations";
import OfflineReservationCompany from "./Details/Company";
import OfflineReservationRequest from "./Details/Request";
import OfflineReservationRooms from "./Rooms";

export default function AddOfflineReservation() {
	const { locale, allCountries, allCurrencies } = useGlobalState();
	const history = useHistory();
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [countries, setCountries] = useState([]);
	const [suppliersLookups, setSuppliersLookups] = useState([]);
	const [hotelsLookups, setHotelsLookups] = useState([]);
	const [roomLookups, setRoomLookups] = useState([]);
	const [mealsLookup, setMealsLookup] = useState([]);
	const [requestDetails, setRequestDetails] = useState({
		destination: null,
		supplier: null,
		hotel_name: null,
		check_in: null,
		check_out: null,
		meal_plan: null,
		residence: null,
		nationality: null,
		currency: null,
		remarks: "",
	});
	const [companyDetails, setCompanyDetails] = useState({
		company_name: "",
		contact_person: "",
		email: "",
		country: null,
		phone: "",
	});
	const [roomsList, setRoomsList] = useState([]);

	// get currenices and countries from context
	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		currenciesLookup();
		countriesLookups();
	}, [allCurrencies, allCountries]);

	// format lookups
	function formatLookups(lookupArray) {
		return lookupArray.map((lookup) => ({
			...lookup,
			value: lookup.id,
			label: lookup.name,
		}));
	}
	// get Hotels lookups based on selected supplier
	async function getHotelsLookups(supplierId) {
		const formatedHotels = await fetchHotelNamesLookups(supplierId).then(
			(hotels) =>
				hotels?.data?.data?.map((hotel) => {
					return { value: hotel.id, label: hotel.hotel_name };
				})
		);
		setHotelsLookups(formatedHotels);
	}

	// get suppliers Lookup
	useEffect(() => {
		async function fetchLookups() {
			const formtSuppliers = await fetchSuppliersLookups().then((response) =>
				formatLookups(response?.data?.data)
			);
			const formatMealPlans = await fetchMealPlansLookups().then((response) =>
				formatLookups(response?.data?.data)
			);
			const formatRoomViews = await fetchRoomViewsLookups().then((response) =>
				formatLookups(response?.data?.data)
			);
			const formatRoomTypes = await fetchRoomTypesLookups().then((response) =>
				formatLookups(response?.data?.data)
			);
			setSuppliersLookups(formtSuppliers);
			setRoomLookups({
				...roomLookups,
				views: formatRoomViews,
				types: formatRoomTypes,
			});
			setMealsLookup(formatMealPlans);
		}
		fetchLookups();
	}, []);

	// check if form has no errors then it will add new offline reservation
	useEffect(() => {
		if (isFormValid(errors) && roomsList.length > 0) {
			debugger;
			const data = {
				destination_id: requestDetails?.destination?.value,
				hotel_name: requestDetails?.hotel_name?.label,
				date_from: moment(requestDetails?.check_in).format("YYYY-MM-DD"),
				date_to: moment(requestDetails?.check_out).format("YYYY-MM-DD"),
				nationality: requestDetails?.nationality?.code,
				residency: requestDetails?.residence?.code,
				remarks: requestDetails?.remarks || "",
				meal_plan_id: requestDetails?.meal_plan?.value,
				currency: requestDetails?.currency?.label,
				rooms_data: roomsList.map((room) => {
					return {
						count: +room.count,
						number_of_adult: +room.number_of_adult,
						room_view_id: +room.room_view?.id,
						room_type_id: +room.room_type?.id,
						children: +room.number_of_childs,
					};
				}),

				company_details: {
					name: companyDetails?.company_name,
					contact_person: companyDetails?.contact_person,
					email: companyDetails?.email,
					country_id: companyDetails?.country?.value,
					phone: companyDetails?.phone,
				},
			};
			async function createNewOfflineReservation() {
				const response = await createOfflineRequest(data);
				if (response.status === 200) {
					store.addNotification({
						title: "Info!",
						message: response?.data?.message
							? response?.data?.message
							: "done successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					setTimeout(() => {
						history.push("/manual-reservations/offline-reservations");
					}, 200);
				}
			}
			createNewOfflineReservation();
		}
	}, [isErrorLoaded]);
	// check if there is error in any field
	function checkFormErrors() {
		let SubmitError = {};
		debugger;

		Object.keys(requestDetails).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: requestDetails[key] },
					{ required: key !== "remarks" && key !== "meal_plan" ? true : false }
				),
			};
		});
		Object.keys(companyDetails).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: companyDetails[key] },
					{ required: true, email: key === "email" ? true : false }
				),
			};
		});
		setErrors(() => SubmitError);
	}
	// submit
	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	return (
		<>
			<OfflineReservationRequest
				currencies={currencies}
				countries={countries}
				requestDetails={requestDetails}
				setRequestDetails={setRequestDetails}
				setErrors={setErrors}
				errors={errors}
				getHotelsLookups={getHotelsLookups}
				suppliersLookups={suppliersLookups}
				hotelsLookups={hotelsLookups}
				mealsLookup={mealsLookup}
			/>
			<OfflineReservationCompany
				companyDetails={companyDetails}
				setCompanyDetails={setCompanyDetails}
				countries={countries}
				setErrors={setErrors}
				errors={errors}
			/>
			<OfflineReservationRooms
				roomLookups={roomLookups}
				roomsList={roomsList}
				setRoomsList={setRoomsList}
			/>
			<div className="d-flex  justify-content-end mt-3 ">
				<button
					type="button"
					className=" cancel-bg p-2 px-4 mx-3"
					onClick={() =>
						history.push(`/manual-reservations/offline-reservations`)
					}
				>
					Back
				</button>
				<button
					type="button"
					className="btn px-4"
					onClick={submit}
					style={{
						backgroundColor: "#00B680",
						boxShadow: "0px 3px 6px #00B68053",
						color: "#fff",
					}}
				>
					Add Request
				</button>
			</div>
		</>
	);
}
