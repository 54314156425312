import moment from "moment";
import { useState } from "react";
import Locale from "translations";

export default function ListPlatformOffers({
	item,
	index,
	changeValueOffer,
	deleteOfferfn,
	setOfferItem,
}) {
	const { backOffice } = Locale;
	const [SpecialOffer, setSpecialOffer] = useState({ ...item });

	return (
		<tr key={index}>
			<td style={{ maxWidth: "320px", width: "320px" }}>
				{moment(SpecialOffer.form).format("YYYY-MM-DD")}
			</td>

			<td>{moment(SpecialOffer.to).format("YYYY-MM-DD")}</td>

			<td>{SpecialOffer.offer}</td>
			<td>
				<button
					className="btn btn-link mr-2"
					onClick={() => {
						changeValueOffer(index, "edit", !item.edit, {
							...SpecialOffer,
						});
						setOfferItem({ ...SpecialOffer });
					}}
					title={backOffice.edit}
				>
					<i className="fas fa-edit"></i>{" "}
				</button>
			</td>
			<td>
				<a
					href={() => false}
					className="d-inline-block text-danger"
					onClick={() => {
						deleteOfferfn(item, index);
					}}
				>
					<i className="fas fa-trash"></i>
				</a>
			</td>
		</tr>
	);
}
