import ShowForPermission from "helpers/showForPermission";
import { Link, useHistory, useParams } from "react-router-dom";
import Locale from "translations";
import ListGroups from "./ListGroups";

export default function GroupsTourism() {
	const { company_id } = useParams();

	const { Companies } = Locale;
	let history = useHistory();

	return (
		<div>
			<div className=" w-100  d-flex justify-content-end">
				<ShowForPermission permission="Tourism-Company-Manage">
					<Link
						className="btn rounded companies-btn "
						to={{
							pathname: `/tourism-company/add/group/${company_id}`,
							state: { group: {} },
						}}
					>
						{Companies.addGroup}
					</Link>
				</ShowForPermission>
			</div>
			<ListGroups />
		</div>
	);
}
