import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	addAvailabilityRoom,
	addPriceCountry,
	addPriceRoom,
} from "services/calendar";

function Cell({
	hotelId,
	id,
	selected,
	setSelected,
	started,
	setStarted,
	handle,
	setHandle,
	day,
	room,
	room_availability_days,
	ratePlans,
	setHandleFirstDay,
	handleFirstDay,
	heightRate,
	RangeDays,
	setRangeDays,
}) {
	const [run, setRun] = useState(false);
	const [dayCalender, setDayCalender] = useState({
		count: null,
		Price: [],
		pr: [],
	});
	const [counter, setCounter] = useState(0);
	const [counterPrice, setCounterPrice] = useState(0);
	const [countryPrice, setCountryPrice] = useState({});
	const [price, setPrice] = useState({});
	const [roomAvailabilityDays, setRoomAvailabilityDays] = useState([]);

	const onClick = () => {
		if (dayCalender !== undefined) {
			setHandleFirstDay(dayCalender);
			setRangeDays({ ...RangeDays, count: dayCalender.count });
		}
		setSelected([id]);
	};

	useEffect(() => {
		if (room_availability_days.length > 0 && !run) {
			setRoomAvailabilityDays(room_availability_days);
			const findDay = room_availability_days.find(
				(element) => element.day === day
			);
			setDayCalender(findDay);
			setRun(true);
		}
	}, [room_availability_days]);

	const moveLeftHandle = (id) => {
		let filtered;
		if (!selected.includes(id) && id <= selected[selected.length - 1]) {
			if (selected[0] === id + 1) {
				setSelected([...selected, id].sort((a, b) => a - b));
				setRangeDays({ ...RangeDays, days: [...RangeDays.days, day] });
			}
		} else if (selected.includes(id) && id < selected[selected.length - 1]) {
			filtered = [...selected].filter((item) => item > id);
			setSelected(filtered.sort((a, b) => a - b));
		} else return;
	};

	const moveRightHandle = (id) => {
		let filtered;
		if (!selected.includes(id) && id >= selected[0]) {
			if (selected[selected.length - 1] === id - 1) {
				setRangeDays({ ...RangeDays, days: [...RangeDays.days, day] });
				setSelected([...selected, id].sort((a, b) => a - b));
			}
		} else if (selected.includes(id) && id > selected[0]) {
			filtered = [...selected].filter((item) => item < id);
			setSelected(filtered.sort((a, b) => a - b));
		} else return;
	};

	const onMouseEnter = (id, started, e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!selected?.length) return;
		if (started === "left" && handle === "left") {
			moveLeftHandle(id);
		}
		if (started === "right" && handle === "right") {
			moveRightHandle(id);
		}
	};

	const inputPrice = async (e, ratePlan) => {
		if (e !== undefined && e.target.value !== undefined) {
			const response = await addPriceRoom(hotelId, {
				room_availability_day_id: dayCalender.id,
				property_rate_id: ratePlan.value,
				price: e.target.value,
			});

			if (response.hasOwnProperty("price")) {
				let dayPrice = { ...dayCalender };
				dayPrice.property_rate_prices = [
					...dayPrice.property_rate_prices,
					response,
				];
				setDayCalender(dayPrice);
				store.addNotification({
					title: "Info!",
					message: "done",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	const inputAvailability = async (e) => {
		if (e !== undefined && e.target.value !== undefined) {
			const response = await addAvailabilityRoom(hotelId, {
				room_id: room.id,
				day: moment(day).format("YYYY-MM-DD"),
				count: e.target.value,
			});

			if (response.hasOwnProperty("count")) {
				let room = roomAvailabilityDays;
				const findDay = roomAvailabilityDays.find(
					(element) => element.day === day
				);
				const index = roomAvailabilityDays.indexOf(findDay);
				if (findDay != undefined && index) {
					room[index] = response;
					setDayCalender(response);
					setHandleFirstDay(response);
					setRangeDays({ ...RangeDays, count: response.count });
				} else {
					room.push(response);
					setDayCalender(response);
					setHandleFirstDay(response);
					setRangeDays({ ...RangeDays, count: response.count });
				}
				setRoomAvailabilityDays(room);
				store.addNotification({
					title: "Info!",
					message: "done",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	const getPrice = (e, index) => {
		let prices = "";
		if (
			dayCalender &&
			dayCalender?.property_rate_prices?.length > 0 &&
			!dayCalender?.hasOwnProperty("Price")
		) {
			dayCalender?.property_rate_prices.map((res) => {
				if (e.value == res.property_rate_id) {
					prices = res.price;
				}
			});
		} else {
			prices =
				dayCalender != undefined && dayCalender?.hasOwnProperty("Price")
					? dayCalender.Price[index]
					: "";
		}

		return prices;
	};

	const inputPriceDayCalender = (value, index) => {
		let list = dayCalender?.Price;
		if (list === undefined) {
			list = [];
		}
		list[index] = value;
		setDayCalender({ ...dayCalender, Price: list });
	};

	const handleOnChangeAvailability = (e) => {
		setDayCalender({ ...dayCalender, count: e.target.value });
		setCounter(counter + 1);
	};

	const handleOnChangePrice = (e, ratePlan, index) => {
		inputPriceDayCalender(e.target.value, index);
		setPrice({ event: e, ratePlan: ratePlan });
		setCounterPrice(counterPrice + 1);
	};

	useEffect(() => {
		if (
			dayCalender !== undefined &&
			dayCalender?.count > 0 &&
			counterPrice > 0
		) {
			const timeoutId = setTimeout(
				() => inputPrice(price.event, price.ratePlan),
				800
			);
			return () => clearTimeout(timeoutId);
		}
	}, [dayCalender?.Price?.length]);

	useEffect(() => {
		if (dayCalender !== undefined && dayCalender?.count > 0 && counter > 0) {
			const timeoutId = setTimeout(
				() => inputAvailability({ target: { value: dayCalender.count } }),
				800
			);
			return () => clearTimeout(timeoutId);
		}
	}, [dayCalender?.count]);
	console.log(dayCalender);
	const ChangePriceCountry = async (value, ratePlan, country) => {
		let hotel_rate_price_id = 0;
		if (dayCalender && dayCalender.property_rate_prices.length > 0) {
			dayCalender.property_rate_prices.forEach((element) => {
				if (element.property_rate_id === ratePlan?.value) {
					hotel_rate_price_id = element.id;
				}
			});
		}

		if (value !== undefined && value !== undefined) {
			const response = await addPriceCountry(hotelId, {
				country_id: country.value,
				property_rate_price_id: hotel_rate_price_id,
				price: value,
			});

			if (response.hasOwnProperty("price")) {
				store.addNotification({
					title: "Info!",
					message: "done",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	const handleOnChangePriceCountry = (event, ratePlan, country) => {
		setCountryPrice({
			value: event.target.value,
			ratePlan: ratePlan,
			country: country,
		});
	};
	useEffect(() => {
		if (dayCalender !== undefined && dayCalender?.count > 0 && countryPrice) {
			const timeoutId = setTimeout(
				() =>
					ChangePriceCountry(
						countryPrice.value,
						countryPrice.ratePlan,
						countryPrice.country
					),
				800
			);
			return () => clearTimeout(timeoutId);
		}
	}, [countryPrice]);
	const getPriceCountry = (ratePlan, country) => {
		let PriceCountry = null;

		if (dayCalender && dayCalender.property_rate_prices?.length > 0) {
			dayCalender.property_rate_prices.forEach((element) => {
				if (element.property_rate_id === ratePlan.value) {
					if (
						element.property_rate_price_countries &&
						element.property_rate_price_countries.length > 0
					) {
						element.property_rate_price_countries.forEach((elem) => {
							if (elem.country_id === country.value) {
								PriceCountry = elem.price;
							}
						});
					}
				}
			});
		}
		return PriceCountry;
	};

	return (
		<ul
			className={`calendar-day  pb-4  
			
			${
				(dayCalender?.count > 0 && dayCalender.hasOwnProperty("Price")) ||
				(dayCalender?.count > 0 &&
					dayCalender?.property_rate_prices?.length > 0)
					? "bookable"
					: "non-bookable"
			}
			${selected && selected.includes(id) ? "selected" : ""} 
	
	
			`}
			onMouseEnter={(e) => (!!started ? onMouseEnter(id, started, e) : false)}
		>
			<li>
				<p>{moment(day).format("dddd")}</p>
				<p>{day}</p>
			</li>
			<li className="line">
				<span className={`${dayCalender?.count === 0 ? "d-none" : ""}`}>
					{(dayCalender?.count > 0 && dayCalender.hasOwnProperty("Price")) ||
					(dayCalender?.count > 0 &&
						dayCalender?.property_rate_prices?.length > 0) > 0
						? "bookable"
						: ""}
				</span>
				<span
					className={`${
						(dayCalender?.count > 0 && dayCalender.hasOwnProperty("Price")) ||
						(dayCalender?.count > 0 &&
							dayCalender?.property_rate_prices?.length > 0) > 0
							? "d-none"
							: ""
					}`}
				>
					{" "}
					{(dayCalender?.count > 0 && dayCalender.hasOwnProperty("Price")) ||
					(dayCalender?.count > 0 &&
						dayCalender?.property_rate_prices?.length > 0) > 0
						? ""
						: "non-bookable"}
				</span>
			</li>
			<li
				className={`${selected && selected.includes(id) ? "drag-line" : ""}`}
				// className="drag-line"
			>
				{selected && selected[0] == id && (
					<i
						onMouseDown={() => setStarted("left")}
						onMouseUp={() => setStarted("")}
						onMouseOver={() => setHandle("left")}
						className="fas fa-bars drag-left"
					></i>
				)}
				<input
					onClick={onClick}
					onChange={handleOnChangeAvailability}
					placeholder={0}
					value={dayCalender?.count}
					disabled
					id={id}
					type="text"
				/>
				{selected && selected[selected.length - 1] == id && (
					<i
						onMouseDown={() => setStarted("right")}
						onMouseUp={() => setStarted("")}
						onMouseOver={() => setHandle("right")}
						className="fas fa-bars drag-right"
					></i>
				)}
			</li>

			<li>
				<input type="text" disabled value="" />
			</li>
			<li className="empty-block" style={{ height: heightRate }}></li>
			{ratePlans?.length > 0
				? ratePlans.map((ratePlan, index) => {
						return (
							<>
								<li>
									<input
										disabled={true}
										onChange={(e) => {
											handleOnChangePrice(e, ratePlan, index);
										}}
										type="number"
										value={getPrice(ratePlan, index)}
										max={room.count}
									/>
								</li>
								{ratePlan.showCountries &&
								ratePlan.countries &&
								ratePlan.countries.length > 0
									? ratePlan.countries.map((res) => (
											<li>
												<input
													type="number"
													disabled
													onChange={(e) => {
														handleOnChangePriceCountry(e, ratePlan, res);
													}}
													value={getPriceCountry(ratePlan, res)}
												/>
											</li>
									  ))
									: ""}
							</>
						);
				  })
				: ""}
		</ul>
	);
}

export default Cell;
