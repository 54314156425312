import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import { Link, useParams } from "react-router-dom";
import Switch from "react-switch";
import Locale from "translations";
function AdsTable({
	Ads,
	setAds,
	goTo,
	meta,
	toggleConfirm,
	setSelectAd,
	toggleDelete,
}) {
	const { ads, backOffice } = Locale;
	const { id } = useParams();
	const AllAds = Ads?.map((res) => {
		return (
			<>
				<tr key={res?.id}>
					<td>{res?.name}</td>
					<td>{res?.type}</td>
					<td>{res?.no_of_items}</td>
					<td>{res?.expiry_date}</td>

					<ShowForPermission permission="Popup-Ads-Manage">
						<td>
							{res?.publish == 0 ? (
								<Switch
									height={24}
									width={45}
									handleDiameter={10}
									uncheckedIcon={false}
									className="mx-2 align-middle"
									onColor="#C3C3C3"
									onChange={() => toggleConfirm(res?.id, res?.publish)}
									checked={false}
								/>
							) : (
								<Switch
									height={24}
									width={45}
									handleDiameter={10}
									checkedIcon={false}
									className="mx-2 align-middle"
									onChange={() => toggleConfirm(res?.id, res?.publish)}
									checked={true}
									onColor="#00AAEB"
								/>
							)}
						</td>
					</ShowForPermission>
					<ShowForPermission permission="Popup-Ads-Manage">
						<td>
							<Link to={`/popup-ads/${id}/edit/${res?.id}`}>
								<i class="fas fa-edit text-primary pointer me-2 "></i>
							</Link>
							<i
								class="fas fa-trash text-danger pointer ms-2"
								onClick={() => {
									setSelectAd(res);
									toggleDelete();
								}}
							></i>
						</td>
					</ShowForPermission>
				</tr>
			</>
		);
	});

	return (
		<>
			<div className="our-border  px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{ads.Name}</th>
							<th>{ads.Type}</th>
							<th>{ads.Noofitem}</th>
							<th>{ads.ExpiryDate}</th>
							<ShowForPermission permission="Popup-Ads-Manage">
								<th>{ads.Publish}</th>
							</ShowForPermission>
							<ShowForPermission permission="Popup-Ads-Manage">
								<th>{ads.Actions}</th>
							</ShowForPermission>
						</tr>
					</thead>
					<tbody>
						{Ads?.length > 0 ? (
							AllAds
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{Ads?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
			</div>
		</>
	);
}
export default AdsTable;
