import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { commercialDistTransferView } from "services/commercialDistribution";
import PricesBox from "./PricesBox";
import TransferItem from "./transferItem";

export default function CommercialDisTransferReservationView() {
	const { id, reservation_id } = useParams();
	const [Transfer, setTransfer] = useState({});
	console.log(reservation_id);
	useEffect(() => {
		const fetchView = async () => {
			const res = await commercialDistTransferView(id, reservation_id);
			if (res.status === 200) {
				setTransfer(res?.data?.data);
			}
		};
		fetchView();
	}, [reservation_id, id]);

	return (
		<>
			<div className="me-5 transfer d-flex align-items-center justify-content-between">
				<div className="Booking-Details-tab">Booking Details</div>
			</div>
			<div className="row me-5 bg-white transfer">
				<div className="col-8">
					<div className="d-flex justify-content-between align-items-center">
						<p className="trans-dark-top">Reservation</p>
						<p className="ref-btn">Ref No. {Transfer?.reference_number}</p>
					</div>
					{Transfer.items?.map((res) => (
						<TransferItem item={res} key={res?.id} />
					))}
				</div>
				{/*                       right col                  */}
				<div className="col-4">
					<p className="trans-dark-top">Booking Summary</p>
					{Transfer?.items?.length > 0 && (
						<PricesBox
							Transfer={Transfer?.items}
							currency={Transfer?.currency}
						/>
					)}
				</div>
			</div>{" "}
		</>
	);
}
