import axios from "axios";
import { cleanEmpty } from "./general";

const URL = process.env.REACT_APP_API_URL + "/api";
const airLinesURL = URL + "/flights";

export const airlineReservationsRequests = async (id) => {
	return await axios
		.get(`${airLinesURL}/request/list?flight_booking_id=${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const airlineHandleRefundPending = async (data) => {
	return await axios
		.post(`${airLinesURL}/request/refund/handle-pending`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const airlineHandleChangePending = async (data) => {
	return await axios
		.post(`${airLinesURL}/request/change/handle-pending`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const airlineHandleVoidPending = async (data) => {
	return await axios
		.post(`${airLinesURL}/request/void/handle-pending`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const airlineHandleWaiting = async (name, data) => {
	return await axios
		.post(`${airLinesURL}/request/${name}/handle-waiting-approval`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getAllFilghtRequests = async (params) => {
	return await axios
		.get(`${airLinesURL}/request/list`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
//list flight

export const getFilghtList = async (params) => {
	return await axios
		.get(`${airLinesURL}/booking`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewflight = async (id) => {
	return await axios
		.get(`${airLinesURL}/booking/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const portsLookup = async (name) => {
	return await axios
		.get(`${airLinesURL}/lookups/air-ports?search=${name}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getFilghtHistory = async (id, params = {}) => {
	return await axios
		.get(`${airLinesURL}/request/${id}/list-history`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const addFlightTicket = async (requestId, params = {}) => {
	return await axios
		.post(`${airLinesURL}/request/${requestId}/tickets`, {
			...cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateFlightTicket = async (requestId, ticketId, params = {}) => {
	return await axios
		.put(`${airLinesURL}/request/${requestId}/tickets/${ticketId}`, {
			...cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteFlightTicket = async (requestId, ticketId) => {
	return await axios
		.delete(`${airLinesURL}/request/${requestId}/tickets/${ticketId}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const sendChangeRequestEmail = async (requestId) => {
	return await axios
		.get(`${airLinesURL}/request/change/confirm-request/${requestId}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportToExcelAirlineRes = async (params) => {
	return await axios
		.get(`${airLinesURL}/booking/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
