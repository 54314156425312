import Rating from "@material-ui/lab/Rating";
import Pagination from "components/shared/Pagination";
import { useGlobalDispatch } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";

import { listProperties, verifyProperty } from "services/property";
import Locale from "translations";
import ConfirmModel from "./ConfirmModel";
const locale = localStorage.getItem("currentLocale") || "en";
export default function PropertyTable({
	setProperties,
	properties,
	goTo,
	meta,
}) {
	const dispatch = useGlobalDispatch();
	const history = useHistory();
	const { backOffice } = Locale;
	const [selected, setselected] = useState({});
	const [modalConfirm, setModalConfirm] = useState(false);
	const { search } = useLocation();

	const [_, pages] = search.split("=");

	const toggleModalConfirm = () => {
		setModalConfirm(!modalConfirm);

		if (modalConfirm) {
			fetchData();
		}
	};

	const calculateStep = (progress) => {
		// switch (Math.floor(progress)) {
		//   case 16:
		//     return "2";
		//   case 33:
		//     return "3";
		//   case 50:
		//     return "4";
		//   case 66:
		//     return "5";
		//   case 83:
		//     return "6";
		//   default:
		return "1";
		// }
	};

	const fetchData = async (filters = null, page = pages) => {
		const res = await listProperties(filters, page);
		if (res?.status === 200) {
			setProperties(res.data.data);
		}
	};
	const goToPropertyStep = (property) => {
		dispatch({
			type: "progress",
			progress: Math.floor(property.registeration_progress),
			step: calculateStep(property.registeration_progress),
		});
		// dispatch({ type: "mode", mode: "edit" });
		history.push({
			pathname: `/property/${property.id}`,
			state: { id: property.id },
		});
	};

	const verify = async (id, value) => {
		const res = await verifyProperty(id, { value: value });
		if (res.status == 200) {
			store.addNotification({
				title: "Done!",
				message: "verify successfull",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					window.location.reload();
				},
			});
		}
	};

	const AllProducts = properties?.map((property) => (
		<tr key={property.id}>
			<td style={{ width: "220px" }}>{property.property_name}</td>
			<td>{property.supplier_name}</td>
			<td>
				<Rating name="read-only" value={property.rate} readOnly />
			</td>
			<td>{property.country[locale]}</td>
			<td>{property.city[locale]}</td>
			<td>{property.created_date}</td>
			<td>{property.share_request?.publish_from}</td>
			<td>{property.share_request?.publish_to}</td>
			<td
				className={
					property.status === "Active" || property.status === "Shared"
						? "text-success"
						: property.status === "In Verify"
						? "text-warning"
						: property.status === "In Progress"
						? "text-info"
						: "text-danger"
				}
			>
				{property.status === "Shared" ? (
					property.share_status ? (
						"Shared"
					) : (
						<span className="text-danger">Not Shared</span>
					)
				) : (
					property.status
				)}
			</td>
			{property.status == "Pending to verify" ? (
				<td>
					<ShowForPermission permission="Property-Management-Manage">
						<div className="d-inline-block">
							<div className={`link-company pointer icon-status bg-success`}>
								{/* <i className="fas fa-fw fa-play"></i> */}
								<button
									className="btn btn-success"
									onClick={() => {
										verify(property.id, 5);
									}}
								>
									Verify
								</button>
							</div>
						</div>
					</ShowForPermission>
					{/* <div className="d-inline-block mx-2">
              <div
                onClick={() => verify(property.id, 4)}
                className={`link-company pointer icon-status bg-danger`}
              >
                <i className="fas fa-fw fa-stop"></i>
              </div>
            </div> */}
				</td>
			) : null}
			<td>
				<Link
					to={{
						pathname: `/property/${property.id}/view`,
					}}
				>
					<i className="icx icx-eye fa-lg"></i>
				</Link>
				<ShowForPermission permission="Property-Management-Manage">
					{/* {property.status === "Shared" && property.share_status ? (
						<button
							className="btn"
							onClick={() => {
								setselected(property);
								setModalConfirm(true);
							}}
							title="stop share"
						>
							<i class="fas fa-ban text-danger"></i>
						</button>
					) : (
						""
					)} */}

					<Link
						to={{
							pathname: `/property/${property.id}`,
						}}
					>
						<i className="icx icx-edit fa-lg"></i>
					</Link>

					<Link
						to={{
							pathname: `/Calendar/${property.id}`,
						}}
					>
						<i class="fas fa-calendar-alt "></i>
					</Link>
				</ShowForPermission>
			</td>
		</tr>
	));

	return (
		<>
			<div className="our-border  px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th style={{ width: "200px" }}>{backOffice.hotelName}</th>
							<th>{backOffice.supplierName}</th>
							<th>{backOffice.rate}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.city}</th>
							<th>{backOffice.createdDate}</th>
							<th>publish From</th>
							<th>publish to</th>
							<th>{backOffice.status}</th>

							{/* Icons */}
							<ShowForPermission permission="Property-Management-Manage">
								<th colSpan="2">{backOffice.tools}</th>
							</ShowForPermission>
						</tr>
					</thead>
					<tbody>
						{properties?.length > 0 ? (
							AllProducts
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{properties?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
			</div>
			<ConfirmModel
				modal={modalConfirm}
				toggleModal={toggleModalConfirm}
				selected={selected}
			/>
		</>
	);
}
