import { useGlobalState } from "context/global";

export default function useShowPermission({ type, permission }) {
	const { permissions, role } = useGlobalState();

	let hasPermission = false;
	if (permissions && permissions?.length > 0 && permissions != "[]") {
		const permissionType = type ? type : "or";
		if (Array.isArray(permission) && permissionType === "or") {
			hasPermission = permission?.some((per) => permissions?.includes(per))
				? true
				: false;
		} else if (Array.isArray(permission) && permissionType === "and") {
			hasPermission = permission?.every((per) => permissions?.includes(per))
				? true
				: false;
		} else {
			hasPermission = permissions?.includes(permission) ? true : false;
		}
	} else if (role == "Super Admin") {
		hasPermission = true;
	} else {
		hasPermission = false;
	}

	return hasPermission;
}
