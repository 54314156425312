import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { fetchImages, uploadFile } from "services/companies";
import { editVendors, showVendor } from "services/vendor";
import UploadContract from "./UploadContract";

function EditVendor() {
	const [photo, setphoto] = useState();
	const { push } = useHistory();
	const { id } = useParams();

	const [vendor, setVendor] = useState({
		name: "",
		country: null,
		sbs_url: "",
		contract_start: null,
		contract_end: null,
		address: "",
		manager_name: "",
		email: "",
		phone: "",
		logo: null,
		contract: null,
		vendor_type: "",
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);

	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(vendor).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value: vendor[key],
					},
					{
						required: key !== "sbs_url" && key !== "sm_url",
						email: key === "email",
					}
				),
			};
		});

		setErrors(() => SubmitError);
		return isFormValid(SubmitError);
	};
	const handleForm = ({ name, value, errorsValidation = {} }) => {
		setVendor((prev) => ({
			...prev,
			[name]: value,
		}));

		setErrors((prev) => ({
			...prev,
			...validate(
				{ name, value },
				{
					required: true,
					...errorsValidation,
				}
			),
		}));
	};

	const handleLogo = (file) => {
		handleForm({ name: "logo", value: file });

		let reader = new FileReader();
		reader.onload = function (event) {
			setphoto(event.target.result);
		};
		reader.readAsDataURL(file);
	};
	const removeLogo = () => {
		setphoto(null);
		handleForm({ name: "logo", value: null });
	};

	const { locale, allCountries } = useGlobalState();
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		const getVendorData = async () => {
			const res = await showVendor(id);
			const data = res.data.data;
			const fetchLogo = await fetchImages(data?.logo);
			setphoto(fetchLogo);

			const country = {
				value: data?.country?.country_id,
				label: data?.country?.name,
			};

			setVendor({
				...data,
				vendor_type:
					{ label: res?.data?.data?.type, value: res?.data?.data?.type } ||
					null,
				country,
				// contract: ,
			});
		};
		getVendorData();
	}, []);
	console.log("handleFormhandleFormhandleForm", vendor, errors);
	const uploadVendorFiles = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file.name);
		const uuid = await uploadFile(formData).then((res) => res.data.uuid);
		return uuid;
	};
	useEffect(() => {
		const updateVendor = async () => {
			const logoUuid =
				typeof vendor.logo === "string"
					? vendor.logo
					: await uploadVendorFiles(vendor.logo);
			const contractUuid =
				typeof vendor.contract === "string"
					? vendor.contract
					: await uploadVendorFiles(vendor.contract);

			const data = {
				country_id: vendor.country.value,
				contract_start: moment(vendor.contract_start).format("YYYY-MM-DD"),
				contract_end: moment(vendor.contract_end).format("YYYY-MM-DD"),
				address: vendor.address,
				manager_name: vendor.manager_name,
				email: vendor.email,
				phone: vendor.phone,
				type: vendor.vendor_type?.value,
				logo: logoUuid,
				contract: contractUuid,
			};
			const response = await editVendors(id, data);

			if (response.status === 200) {
				push(`/manage-vendor/${id}/vendor-details`);
				store.addNotification({
					title: "Vendor Edit",
					message: "Vendor has been updated successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		};

		if (isFormValid(errors)) {
			updateVendor();
		}

		return () => {};
	}, [isErrorLoaded]);

	return (
		<>
			<div className="mine-box w-100 pb-4">
				<h5 className="box-title mb-3">Edit Vendor</h5>
				<div className="mx-5">
					<div className="row">
						<div className="col-2">
							<p className="mb-0">Vendor Logo</p>
							<div className="img-box-ven p-1">
								{photo ? (
									<>
										<i
											class="fas fa-times remove-photo"
											onClick={removeLogo}
										></i>
										<img src={photo} alt="logo" className="img-fluid" />
									</>
								) : (
									<>
										<button className="file-btn w-75 ">
											{"Upload Logo"}
											<input
												type="file"
												id="logo-button"
												className="custom-file h-100"
												accept="image/png, image/gif, image/jpeg"
												onChange={(e) => {
													const file = e.target.files.item(0);
													handleLogo(file);
												}}
											/>
										</button>
									</>
								)}
							</div>
							{errors?.logo?.required && (
								<small className="text-danger">Logo is required</small>
							)}
						</div>
						<div className="col-5">
							<TextField
								label={"Vendor Name"}
								placeholder="Vendor Name"
								type={"text"}
								value={vendor.name}
								onChange={(e) => {
									handleForm({ name: "name", value: e.target.value });
								}}
								errors={errors?.name}
								color={errors?.name?.required ? "danger" : ""}
							/>

							<TextField
								label={"URL"}
								placeholder="URL"
								type={"text"}
								value={vendor.sbs_url}
								disabled
							/>
							<TextField
								label={"Address"}
								placeholder="Address"
								type={"text"}
								value={vendor.address}
								onChange={(e) => {
									handleForm({ name: "address", value: e.target.value });
								}}
								errors={errors?.address}
								color={errors?.address?.required ? "danger" : ""}
							/>
							<TextField
								label={"Email Address"}
								placeholder="Email Address"
								type={"email"}
								value={vendor.email}
								onChange={(e) => {
									handleForm({
										name: "email",
										value: e.target.value,
										errorsValidation: { email: true },
									});
								}}
								errors={errors?.email}
								color={
									errors?.email?.required || errors?.email?.email
										? "danger"
										: ""
								}
							/>
							<UploadContract
								setVendor={setVendor}
								vendor={vendor}
								setErrors={setErrors}
								errors={errors}
							/>
						</div>
						<div className="col-5">
							<SelectField
								label={"Country"}
								options={countries}
								value={vendor.country}
								onChange={(e) => {
									handleForm({ name: "country", value: e });
								}}
								errors={errors?.country}
								color={errors?.country?.required ? "danger" : ""}
							/>
							<div className="px-2 date-range-input">
								{/* <DatePickerField
									label={"Contract validity"}
									date={moment(new Date())}
								/> */}
								<label className="mb-0 mt-3">Contract validity</label>
								<DateRangeField
									// hasLabel={true}
									// label="Contract validity"
									startDate={vendor.contract_start}
									endDate={vendor.contract_end}
									startDatePlaceholderText="DD/MM/YYY"
									endDatePlaceholderText="DD/MM/YYY"
									readOnly={true}
									onDatesChange={({ startDate, endDate }) => {
										setVendor((prev) => ({
											...prev,
											contract_start: startDate,
											contract_end: endDate,
										}));
									}}
									numberOfMonths={1}
								/>
							</div>
							<TextField
								label={"Manager Name"}
								placeholder="Manager Name"
								type={"text"}
								value={vendor.manager_name}
								onChange={(e) => {
									handleForm({ name: "manager_name", value: e.target.value });
								}}
								errors={errors?.manager_name}
								color={errors?.manager_name?.required ? "danger" : ""}
							/>
							<TextField
								label={"Mobile No."}
								placeholder="Mobile No."
								type={"number"}
								value={vendor.phone}
								onChange={(e) => {
									handleForm({ name: "phone", value: e.target.value });
								}}
								errors={errors?.phone}
								color={errors?.phone?.required ? "danger" : ""}
							/>

							<SelectField
								label={"Vendor Type"}
								value={vendor.vendor_type}
								options={[
									{ label: "b2b", value: "b2b" },
									{ label: "b2c", value: "b2c" },
								]}
								onChange={(e) => {
									handleForm({ name: "vendor_type", value: e });
								}}
								errors={errors?.vendor_type}
								color={errors?.vendor_type?.required ? "danger" : ""}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex  justify-content-end mt-5">
				<button
					className=" cancel-bg p-2 px-4  mx-3"
					onClick={() => {
						push(`/manage-vendor/${id}/vendor-details`);
					}}
				>
					Back{" "}
				</button>

				<button className="btn btn-success" onClick={submit}>
					{" "}
					Edit Vendor
				</button>
			</div>
		</>
	);
}

export default EditVendor;
