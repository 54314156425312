import resetIcon from "assets/images/wallet/resetIcon.png";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useState } from "react";
import { airlineLookup } from "services/b2c";
import Locale from "translations";

export default function ChangeFilter({
	filters,
	filterHandler,
	submitFilter,
	resetFilters,
	hasFirstArrival,
	firstArrivalOptions,
	packageDetails,
}) {
	const { backOffice } = Locale;
	// const { locale, allCountries } = useGlobalState();
	// const [countries, setCountries] = useState();
	const [listAuto, setListAuto] = useState([]);

	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const airs = await airlineLookup(inputValue);
			let result = airs?.data?.data?.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});

			setListAuto(result);
		}
	};

	const nightsOptions = Array(9)
		.fill(0)
		.map((_, i) => ({
			value: i + 1,
			label: (i + 1).toString(),
			code: (i + 1).toString(),
		}));

	const classOptions = Array(5)
		.fill(0)
		.map((_, i) => ({
			value: i + 1,
			label: (i + 1).toString(),
			code: (i + 1).toString(),
		}));

	// useEffect(() => {
	// 	async function countriesLookups() {
	// 		const format = await allCountries.map((t) => ({
	// 			value: t.id,
	// 			label: t.names[locale],
	// 			code: t.country_code,
	// 		}));
	// 		setCountries(format);
	// 	}
	// 	countriesLookups();
	// }, [allCountries, locale]);

	return (
		<>
			<div className="row w-100 border border-bottom-0 d-flex align-items-center pb-4">
				<div className="col-md-2">
					<TextField
						placeholder={"Select"}
						label={"Travel Agent"}
						name="travelAgent"
						id="travelAgent"
						value={filters?.travelAgent?.label}
						onChange={() => {}}
						disabled
						readOnly
					/>
				</div>
				<div className="col-md-2">
					<DatePickerField
						placeholder={"Select"}
						label={"Departure Date"}
						name="departureDate"
						id="departureDate"
						onOutsideClick={false}
						minDate={moment(packageDetails?.departure_date).subtract(
							5 + 1,
							"day"
						)}
						maxDate={moment(packageDetails?.departure_date).add(5 + 1, "days")}
						date={filters?.departureDate}
						onChangeDate={(e) => {
							filterHandler({ name: "departureDate", value: e });
						}}
					/>
				</div>
				<div className="col-md-1">
					<SelectField
						placeholder={"Select"}
						label={"Nights"}
						name="nights"
						id="nights"
						options={nightsOptions}
						value={filters?.nights}
						onChange={(e) => {
							filterHandler({ name: "nights", value: e });
						}}
					/>
				</div>
				<div className="col-md-1">
					<SelectField
						placeholder={"Select"}
						label={"Class"}
						name="class"
						id="class"
						options={classOptions}
						value={filters?.class}
						onChange={(e) => {
							filterHandler({ name: "class", value: e });
						}}
					/>
				</div>
				{hasFirstArrival && (
					<div className="col-md-2">
						<SelectField
							placeholder={"Select"}
							label={"First Arrival"}
							name="firstArrival"
							id="firstArrival"
							options={firstArrivalOptions}
							value={filters?.firstArrival}
							onChange={(e) => {
								filterHandler({ name: "firstArrival", value: e });
							}}
						/>
					</div>
				)}
				{/* <div className="col-md-2">
					<SelectField
						placeholder={"Select"}
						label={"Departure From"}
						name="departureFrom"
						options={countries}
						value={filters?.departureFrom}
						onChange={(e) => {
							filterHandler({ name: "departureFrom", value: e });
						}}
					/>
				</div> */}
				<div className="col-md-2">
					<AutoCompleteField
						label="Airline"
						name="airline"
						id="airline"
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						isSearchable={true}
						placeholder={"Select"}
						value={filters?.airline?.label}
						onChange={(e) => filterHandler({ name: "airline", value: e })}
						onSelectValue={(e) => {
							filterHandler({ name: "airline", value: e });
						}}
					/>
				</div>
				<div className="col-12 row">
					<div className="col-md-3">
						<label className="wrap-label">Price</label>
						<div className="filter-warp-field">
							<TextField
								type={"number"}
								name="minPrice"
								id="minPrice"
								extraTextPosition="append"
								extraText={packageDetails?.currency}
								value={filters?.minPrice}
								onChange={(e) =>
									filterHandler({ name: "minPrice", value: +e.target.value })
								}
							/>
							<p>To</p>
							<TextField
								type={"number"}
								name="maxPrice"
								id="maxPrice"
								extraTextPosition="append"
								extraText={packageDetails?.currency}
								value={filters?.maxPrice}
								onChange={(e) =>
									filterHandler({ name: "maxPrice", value: +e.target.value })
								}
								color={filters?.minPrice > filters?.maxPrice ? "danger" : ""}
							/>
						</div>
						<small className="text-danger">
							{filters?.minPrice > filters?.maxPrice &&
								"max price must be greater than min price"}
						</small>
					</div>
					<div className="col-md-3 d-flex gap-2">
						<div className=" mt-4 ">
							<button
								type="button"
								class="btn btn-success px-5"
								onClick={() => submitFilter()}
							>
								Filter
							</button>
						</div>
						<div
							onClick={resetFilters}
							className="d-flex align-items-center  pointer mt-4 "
						>
							<img src={resetIcon} alt="reset icon" />
							<p className="mx-2 my-0">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="border border-bottom-0 crm-filter">
				<div className="col-md-6">
					<TextField type="text" placeholder={` ${backOffice.search}`} />
				</div>
			</div>
		</>
	);
}
