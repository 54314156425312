import ConfirmModal from "components/Modals/ConfirmModal";
import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { deleteGroup } from "services/managementTeam";
import Locale from "translations";
import GroupUser from "./GroupUser";

export default function Group({ group, setdeleting }) {
	const [isOpen, setIsOpen] = useState(false);
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const toggleConfirm = () => {
		setConfirmIsOpen(!confirmIsOpen);
	};
	const { backOffice, teamManagement, confirmation } = Locale;
	const toggle = () => setIsOpen(!isOpen);

	const allUser = group?.users?.map((user) => {
		return (
			<GroupUser
				user={user}
				confirmIsOpen={confirmIsOpen}
				setConfirmIsOpen={setConfirmIsOpen}
				toggleConfirm={toggleConfirm}
			/>
		);
	});
	const deleteTeam = async (e) => {
		const res = await deleteGroup(e);

		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setConfirmIsOpen(false);
			setdeleting((prev) => !prev);
		}
	};
	return (
		<div className="pt-3">
			<div className="row  m-0  align-items-center collapse-bg">
				<button className="collapse-btn  px-3 py-2" onClick={() => toggle()}>
					{isOpen ? (
						<i class="fas fa-minus  text-white"></i>
					) : (
						<i class="fas fa-plus text-white"></i>
					)}
				</button>

				<p className="col-3 gName-f  font-weight-bold  m-0">
					{group.display_name}
				</p>
				<p className="col-3  m-0 d-flex justify-content-end our-fs-14">
					<span className="member-num mx-2 our-fs-16 text-white ">
						{group?.users?.length}
					</span>
					{teamManagement.members}
				</p>

				<ShowForPermission permission="Team-Management-Groups-Manage">
					<Link
						type="button"
						class="col d-flex justify-content-end align-items-center our-fs-14   px-4"
						to={`/management-team/edit/group/${group.id}`}
					>
						<i class="far fa-edit mx-2"></i>
						{teamManagement.editTeam}
					</Link>

					<a
						onClick={() => toggleConfirm()}
						class=" text-danger d-flex justify-content-end align-items-center  px-4"
					>
						<i class="fa fa-trash mx-2"></i>
						{teamManagement.delete}
					</a>
				</ShowForPermission>
			</div>

			<Collapse isOpen={isOpen}>
				<table className="custom-table back-office-table table table-striped">
					<thead>
						{allUser?.length > 0 ? (
							<tr>
								<th className="CompanyHead-title w-25 ">
									{teamManagement.userName}
								</th>
								<th className="CompanyHead-title w-25 ">{backOffice.email}</th>
								<th className="CompanyHead-title w-25 ">{backOffice.status}</th>
							</tr>
						) : (
							<th className="CompanyHead-title text-center">
								{backOffice.noUsers}{" "}
								<i class="fas fa-exclamation-circle text-warning"></i>
							</th>
						)}
					</thead>
					<tbody>{allUser?.length > 0 ? allUser : null}</tbody>
				</table>
			</Collapse>

			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={confirmation.confirmDeleteGroup}
				onConfirm={() => deleteTeam(group.id)}
			/>
		</div>
	);
}
