import moment from "moment";
import { useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import PackageItinerary from "../view-Booking-Package/PackageItinerary";
import ChangeTableRow from "./ChangeTableRow";

export default function ChangeList({
	filterPackageResult,
	packageTypeUmrahOrHajj,
	tabelDate,
	submitFilter,
	filterHandler,
	packageDetails,
}) {
	const { backOffice } = Locale;
	const [itineraryModal, setItineraryModal] = useState(false);
	const [productItinerary, setProductItinerary] = useState(null);

	function toggleItineraryModal() {
		setItineraryModal(!itineraryModal);
	}
	const allRoomAvailabilities = useMemo(() => {
		let roomAvailabilities = [];
		filterPackageResult?.forEach((packageItem) =>
			roomAvailabilities.push([...packageItem?.roomsAvailabilities])
		);
		roomAvailabilities = roomAvailabilities?.flat();
		let uniqeRoomAvailabilities = roomAvailabilities.filter(
			(room, index, originalArr) => {
				return (
					originalArr.findIndex(
						(item) => room?.room_type === item?.room_type
					) === index
				);
			}
		);
		return uniqeRoomAvailabilities;
	}, [filterPackageResult]);

	// get list of dayes before and after selected departure date
	const beforeAfterDepartureDates = useMemo(() => {
		const startDate = moment(packageDetails?.departure_date).subtract(5, "days");
		const endDate = moment(packageDetails?.departure_date).add(5, "days");
		let dates = [];
		for (var date = moment(startDate); date <= endDate; date.add(1, "day")) {
			dates.push(moment(date));
		}
		return dates;
	}, [packageDetails?.departure_date]);

	return (
		<>
			<div className="border border-bottom-0 d-flex gap-1 align-items-center justify-content-end end py-2 px-2">
				{beforeAfterDepartureDates.map((date) => {
					return moment(date).format("DD-MM-YYYY") === moment(tabelDate).format("DD-MM-YYYY") ? (
						<span className={`re-badge selected`}>
							{moment(date).format("DD.MMM.YY")}
						</span>
					) : (
						<button
							className="re-badge"
							onClick={() => {
								submitFilter(moment(date).format("YYYY-MM-DD"));
							}}
						>
							{moment(date).format("DD.MMM.YY")}
						</button>
					);
				})}
			</div>
			<div className="our-border ">
				<table className="financialTable align-middle table table-striped m-0 text-center">
					<thead>
						<tr>
							<th>{backOffice.TravelAgent}</th>
							<th>{backOffice.PackageName}</th>
							<th>{backOffice.DepartureNights}</th>
							<th>{backOffice.Airline}</th>
							{packageTypeUmrahOrHajj ? (
								<>
									<th>{backOffice.MakkahHotel}</th>
									<th>{backOffice.MadinahHotel}</th>
								</>
							) : (
								<th>{backOffice.hotel}</th>
							)}
							<th>{backOffice.Mazar}</th>
							{/* based on  */}
							{allRoomAvailabilities?.map((room) => {
								return (
									<th key={room?.id}>
										<i class="fas fa-user-alt"></i>
										<span className="mx-1">{room?.room_type}</span>
									</th>
								);
							})}
							<th>{backOffice.Actions}</th>
						</tr>
					</thead>
					<tbody>
						{filterPackageResult?.length > 0 ? (
							filterPackageResult?.map((packageItem) => (
								<ChangeTableRow
									key={packageItem?.product_uuid}
									packageItem={packageItem}
									toggleItineraryModal={toggleItineraryModal}
									setProductItinerary={setProductItinerary}
									packageTypeUmrahOrHajj={packageTypeUmrahOrHajj}
									allRoomAvailabilities={allRoomAvailabilities}
								/>
							))
						) : (
							<tr>
								<td colSpan="100%" className="text-center py-2">
									{" "}
									No Result
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{/* Package Itinerary  */}
			{productItinerary && (
				<Modal
					isOpen={itineraryModal}
					toggle={toggleItineraryModal}
					size="lg"
					centered={true}
				>
					<ModalHeader toggle={toggleItineraryModal}>
						<span className="mb-0  font-weight-bold">{"Change Package"}</span>
					</ModalHeader>
					<ModalBody>
						<PackageItinerary
							productItinerary={productItinerary}
							classNames="mt-0 p-0 border-0"
							showTitle={false}
						/>
					</ModalBody>
				</Modal>
			)}
		</>
	);
}
