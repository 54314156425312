import moment from "moment";
import { useEffect, useState } from "react";
import { getAllFilghtRequests } from "services/airlines";
import ClosedRequests from "./ClosedRequests";
import Filter from "./Filter";
import OpenRequests from "./OpenRequests";

export default function AirlineRequests() {
	const [tabStatus, setTabStatus] = useState(1);
	const [requestDetails, setRequestDetails] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		status_type: "open",
		search: "",
		request_date: null,
		request_type: null,
		status: null,
	});
	// const closedRequestStatus = ["rejected", "expired", "confirmed"];
	// const openedRequests = requestDetails?.filter(
	// 	(request) => !closedRequestStatus.includes(request?.status)
	// );
	// const closedRequests = requestDetails?.filter((request) =>
	// 	closedRequestStatus.includes(request?.status)
	// );
	const FetchFilghtRequests = async () => {
		const res = await getAllFilghtRequests({
			status_type: tabStatus === 1 ? "open" : "closed",
			search: filter?.search,
			date: filter.request_date
				? moment(filter.request_date).format("YYYY-MM-DD")
				: "",
			type: filter.request_type?.value,
			status: filter.status?.value,
			page: filter.page,
		});
		if (res?.status === 200) {
			setRequestDetails(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	};
	useEffect(() => {
		FetchFilghtRequests();
	}, [filter, tabStatus]);
	const goTo = (page) => {
		setFilter((prev) => ({ ...prev, page: page }));
	};
	const resetFilter = () => {
		setFilter({
			search: "",
			request_date: null,
			request_type: null,
			status: null,
			page: 1,
		});
	};

	useEffect(() => {
		let filterKey = {
			search: filter.search,
			request_date: filter.request_date,
			request_type: filter.request_type,
			status: filter.status,
		};
		const isNull = Object.values(filterKey).every((value) => {
			if (value == null) return true;
			return false;
		});
		if (!isNull || filter.page > 1) {
			resetFilter();
		}
	}, [tabStatus]);

	return (
		<div className="b2c_booking_details airline_reservation">
			<div>
				<h3>Airline Requests</h3>
			</div>
			<div className="booking_tab_container">
				<button
					className={`booking_tab p-3 ${tabStatus === 1 ? "active" : ""}`}
					onClick={() => setTabStatus(1)}
				>
					<h6 className="px-2 m-0">Open Request</h6>
				</button>
				<button
					className={`booking_tab p-3 ${tabStatus === 2 ? "active" : ""}`}
					onClick={() => setTabStatus(2)}
				>
					<h6 className="px-2 m-0">Closed Requests</h6>
				</button>
			</div>
			<Filter
				filter={filter}
				setFilter={setFilter}
				resetFilter={resetFilter}
				tabStatus={tabStatus}
			/>
			{tabStatus === 1 ? (
				<OpenRequests
					FetchFilghtRequests={FetchFilghtRequests}
					openedRequests={requestDetails}
					goTo={goTo}
					meta={meta}
				/>
			) : (
				<ClosedRequests
					closedRequests={requestDetails}
					goTo={goTo}
					meta={meta}
					FetchFilghtRequests={FetchFilghtRequests}
				/>
			)}
		</div>
	);
}
