import axios from "axios";
import { cleanEmpty } from "./general";
const subscriptionaURL =
	process.env.REACT_APP_API_URL + "/api/subscription-modules";
const URL = process.env.REACT_APP_API_URL + "/api";

// configurations Subscripion-plan
export const FetchSubscripionConfigurations = async () => {
	return await axios
		.get(`${subscriptionaURL}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const SaveSubscripionConfigurations = async (data) => {
	return await axios
		.put(`${subscriptionaURL}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const ViewSubscripionConfigurationsPlan = async (id) => {
	return await axios
		.get(`${URL}/subscription-plan/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const CreateSubscripionPlan = async (data) => {
	return await axios
		.post(`${URL}/subscription-plan`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const UpdateSubscripionPlan = async (data, id) => {
	return await axios
		.put(`${URL}/subscription-plan/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const UpdateSubscripionPlanStatus = async (data, id) => {
	return await axios
		.put(`${URL}/subscription-plan/${id}/status`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const subscriptionModules = async (planId) => {
	return await axios
		.get(`${URL}/subscription-plan/${planId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const CreateSubscripionPackgePlan = async (data) => {
	return await axios
		.post(`${URL}/subscription-package`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const ViewSubscripionConfigurationsPackage = async (id) => {
	return await axios
		.get(`${URL}/subscription-package/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const UpdateSubscripionPackagePlan = async (data, id) => {
	return await axios
		.put(`${URL}/subscription-package/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchAvailableSubscriptionPackages = async () => {
	return await axios
		.get(`${URL}/available-subscription-package`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const saveSubscriptionPlan = async (data) => {
	return await axios
		.post(`${URL}/subscription-plan`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
