import validate from "helpers/validate";

function UploadContract({ setVendor, vendor, setErrors, errors }) {
	const allowedFileExt = [
		"image/jpg",
		"image/jpeg",
		"image/png",
		"application/x-rar-compressed",
		"application/x-zip-compressed",
		".pdf",
	];

	function handleFileUpload(e) {
		let files = e.target.files.item(0);

		setVendor((prev) => ({ ...prev, contract: files }));
		setErrors((prev) => ({
			...prev,
			...validate({ name: "contract", value: !!files }, { required: true }),
		}));
	}

	function handleRemoveFile() {
		setVendor((prev) => ({ ...prev, contract: null }));
		setErrors((prev) => ({
			...prev,
			...validate({ name: "contract", value: false }, { required: true }),
		}));
	}

	return (
		<div className="contaract-box mx-2">
			<label htmlFor="contract" className="d-block align-self-baseline">
				Upload Contract
			</label>
			<button className="file-btn w-50 m-3 ">
				{"Upload Attachment"}
				<input
					type="file"
					accept={allowedFileExt.join(",")}
					onChange={(e) => {
						handleFileUpload(e);
						e.target.value = null;
					}}
					className="custom-file"
					id="contract"
				/>
			</button>

			{errors?.contract?.required ? (
				<small className="text-danger">contract is required</small>
			) : null}

			{/* files list */}
			<ul className="p-0">
				{vendor?.contract && (
					<li className="text-lowercase contract-files mb-1">
						<i className="fas fa-paperclip file-attach"></i>
						<p className="mx-2 contract-files-text">
							{vendor?.contract?.name ?? vendor?.contract}
						</p>
						<i
							className="fas fa-times-circle pointer text-danger"
							onClick={() => handleRemoveFile()}
						></i>
					</li>
				)}
			</ul>
		</div>
	);
}

export default UploadContract;
