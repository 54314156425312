import axios from "axios";
const Url = process.env.REACT_APP_API_URL + "/api";
const lookupsUrl = process.env.REACT_APP_API_URL + "/api/lookups";
const lookupsUrlOld = process.env.REACT_APP_API_URL_old + "/api/lookups";
const lookupSeasonUrl =
	process.env.REACT_APP_API_URL + "/api/commission-management";
const lookupCompanyUrl =
	process.env.REACT_APP_API_URL + "/api/company-management";

const lookupPropertyUrl = process.env.REACT_APP_API_URL + "/api/lookups";

export const fetchCountries = async () => {
	return await axios
		.get(`${lookupPropertyUrl}/countries`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const fetchNewCountries = async () => {
	return await axios
		.get(`${lookupPropertyUrl}/lt-countries`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const fetchCitiesSearch = async (search) => {
	return await axios
		.get(`${Url}/marketplace/bookings/cities?search=${search}`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};
export const fetchCycleTypes = async () => {
	return await axios
		.get(`${Url}/transportation/list/cycle_types`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchServices = async (id) => {
	return await axios
		.get(`${lookupsUrl}/services`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCategories = async (id) => {
	return await axios
		.get(`${lookupsUrl}/categories/${id}`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const fetchPackageCountries = async () => {
	return await axios
		.get(`${lookupsUrl}/lt-countries`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchCities = async (id) => {
	return await axios
		.get(`${lookupsUrlOld}/cities?country_id=${id}`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchAreas = async (id) => {
	return await axios
		.get(`${lookupsUrlOld}/areas?city_id=${id}`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchLanguages = async (lang = "en") => {
	return await axios
		.get(`${lookupPropertyUrl}/languages`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchRoomViews = async (lang = "en") => {
	return await axios
		.get(`${lookupPropertyUrl}/room-views`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchFacilities = async (lang = "en") => {
	return await axios
		.get(`${lookupPropertyUrl}/facilities`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchRoomTypes = async (lang = "en") => {
	return await axios
		.get(`${lookupPropertyUrl}/room-types`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchMealPlans = async (lang = "en") => {
	return await axios
		.get(`${lookupPropertyUrl}/meal-plans`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchRoomNames = async (lang = "en") => {
	return await axios
		.get(`${lookupsUrl}/room-names`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchAmenities = async (lang = "en") => {
	return await axios
		.get(`${lookupPropertyUrl}/amenities`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchSeason = async () => {
	return await axios
		.get(`${lookupSeasonUrl}/season-lookup`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const fetchCompany = async () => {
	return await axios
		.get(`${lookupCompanyUrl}/lookup`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchExternalHotels = async (params) => {
	return await axios
		.get(`${lookupsUrl}/hotels`, { params })
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const fetchSupplier = async () => {
	return await axios
		.get(`${lookupPropertyUrl}/suppliers`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchPayableTypes = async () => {
	return await axios
		.get(`${lookupsUrl}/payable`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchCitiesHotels = async (cityId, searchValue) => {
	return await axios
		.get(`${lookupsUrl}/cities-hotels?city_id=${cityId}&search=${searchValue}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchOnlineProviders = async (servieName) => {
	return await axios
		.get(`${Url}/marketplace/providers?service_name=${servieName}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchProviders = async () => {
	return await axios
		.get(`${Url}/marketplace/providers`)
		.then((res) => res)
		.catch((err) => err.response);
};
