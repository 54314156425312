import classNames from "classnames";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import NavBar from "./Header/Navbar";
import BreadCrumb from "./breadCrumb";

function BackOfficeLayout(props) {
	const [state, setState] = useState({ isOpen: false, isMobile: true });
	let previousWidth = -1;

	const updateWidth = () => {
		const width = window.innerWidth;
		const widthLimit = 991;
		const isMobile = width <= widthLimit;
		const wasMobile = previousWidth <= widthLimit;

		if (isMobile !== wasMobile) {
			setState({
				isOpen: !isMobile,
			});
		}
		previousWidth = width;
	};

	const toggle = () => {
		setState({ isOpen: !state.isOpen });
	};

	useEffect(() => {
		updateWidth();
		window.addEventListener("resize", updateWidth.bind(this));
		return () => {
			window.removeEventListener("resize", updateWidth.bind(this));
		};
	}, []);

	return (
		<div className={classNames("App backOffice-wrapper")}>
			{/* <Content toggle={toggle} isOpen={state.isOpen} /> */}
			<Container
				fluid
				className={`main-content page-content ${
					state.isOpen ? "" : "main-content-close"
				}`}
				style={{ overflowY: "auto" }}
			>
				<NavBar
					toggle={toggle}
					isOpen={state.isOpen}
					isMobile={state.isMobile}
					setIsOpen={setState}
				/>
				<BreadCrumb />
				{props.children}
			</Container>
		</div>
	);
}

export default BackOfficeLayout;
