import ConfirmModal from "components/Modals/ConfirmModal";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { listContracts, verifyContractList } from "services/property";
import Locale from "translations";

export default function ContractTableContent({
	fetchData,
	productItem,
	setMeta,
	setProductList,
}) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const [IsOpen, setisopen] = useState(false);
	const [userdet, setuserdet] = useState({});
	const toggleConfirm = (id = null, value = null) => {
		setisopen(!IsOpen);
		setuserdet({ id, value });
	};

	const { backOffice, confirmation } = Locale;

	const verify = async (id, value) => {
		const res = await verifyContractList(id, { status: value });
		if (res.status === 200) {
			const resList = await listContracts(null, 1);
			if (resList?.status === 200) {
				setProductList(resList.data.data);
				setMeta(resList.data.meta);
			}
			store.addNotification({
				title: "Done!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setisopen(!IsOpen);
			fetchData();
		}
	};

	return (
		<>
			<tr key={productItem.id}>
				<td scope="row">{productItem.supplier_name}</td>
				<td>{productItem.property_name}</td>
				<td>{productItem.country.name[locale]}</td>
				<td>{productItem.supplier_email} </td>
				<td>{productItem.supplier_phone} </td>
				<td>{moment(productItem.created_at).format("DD-MM-YYYY")} </td>
				<td>
					<p
						className={
							+productItem.is_active === 0
								? "text-danger"
								: +productItem.is_active === 1
								? "text-success"
								: "text-danger"
						}
					>
						{+productItem.is_active === 1
							? backOffice.active
							: backOffice.inActive}
					</p>
				</td>
				<ShowForPermission permission="Contract-List-Manage">
					<td>
						{+productItem.is_active === 0 ? (
							// <>
							// 	<div className="d-inline-block mx-2">
							// 		<div
							// 			onClick={() => verify(productItem.id, 1)}
							// 			className={`link-company pointer icon-status bg-success`}
							// 		>
							// 			<i className="fas fa-fw fa-play"></i>
							// 		</div>
							// 	</div>
							// </>
							<Switch
								height={24}
								width={45}
								handleDiameter={20}
								className="mx-2 align-middle"
								onChange={() => {
									toggleConfirm(productItem.id, 1);
									//  verify(productItem.id, 1)
								}}
								checked={false}
							/>
						) : (
							<Switch
								height={24}
								width={45}
								handleDiameter={20}
								className="mx-2 align-middle"
								onColor="#1777fb"
								onChange={() => {
									// verify(productItem.id, +productItem.is_active === 0 ? 1 : 0)
									toggleConfirm(
										productItem.id,
										+productItem.is_active === 0 ? 1 : 0
									);
								}}
								checked={true}
							/>
							// <>
							// 	<div className="d-inline-block mx-2">
							// 		<div
							// 			onClick={() =>
							// 				verify(productItem.id, +productItem.is_active === 0 ? 1 : 0)
							// 			}
							// 			className={`link-company pointer icon-status bg-danger`}
							// 		>
							// 			<i className="fas fa-fw fa-stop"></i>
							// 		</div>
							// 	</div>
							// </>
						)}
						<Link
							to={{
								pathname: `/contract-list/${productItem.id}`,
							}}
							className="d-inline-block"
						>
							<i className="fas fa-fw fa-eye fa-lg"></i>
						</Link>

						<Link
							to={{
								pathname: `/contract-list0/edit/${productItem.id}`,
							}}
							className="d-inline-block mx-2"
						>
							<i className="fas fa-fw fa-edit "></i>
						</Link>
					</td>
				</ShowForPermission>
			</tr>
			<ConfirmModal
				IsOpen={IsOpen}
				toggle={toggleConfirm}
				message={confirmation.changeStatus}
				onConfirm={() => verify(userdet?.id, userdet?.value)}
				type="comfirm"
			/>
			verify
		</>
	);
}
