import axios from "axios";
const TransfersURL =
	process.env.REACT_APP_API_URL + "/api/marketplace/market-packages";

export const listCountry = async (id, item) => {
	return await axios
		.get(`${TransfersURL}/list-countries`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateCountryIcon = async (id, data) => {
	return await axios
		.post(`${TransfersURL}/edit-country-icon/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
