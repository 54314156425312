import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listB2cUsers = async (params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/user-management/users`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listB2cReservations = async (params) => {
	return await axios
		.get(`${backOfficeApi}/umrah-planner/reservations`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const b2cReservationsView = async (id) => {
	return await axios
		.get(`${backOfficeApi}/umrah-planner/reservations/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const userReservations = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/umrah-planner/users/${id}/reservations`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const packageReservationsList = async (id, params) => {
	return await axios
		.get(
			`${backOfficeApi}/b2c/user-management/users/${id}/package-reservations`,
			{
				params: cleanEmpty(params),
			}
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeUserPassword = async (id, params) => {
	return await axios
		.put(`${backOfficeApi}/b2c/user-management/users/${id}/change-password`, {
			...cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateUser = async (id, params) => {
	return await axios
		.put(`${backOfficeApi}/b2c/user-management/users/${id}`, {
			...cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const b2cUserView = async (id) => {
	return await axios
		.get(`${backOfficeApi}/b2c/user-management/users/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteB2cUserView = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/b2c/user-management/users/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const allTransaction = async (params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/transactions`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const getUserActivityLogs = async (id, filter) => {
	return await axios
		.get(`${backOfficeApi}/b2c/user-management/users/${id}/activity-logs`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getpackageBookingList = async (filter) => {
	return await axios
		.get(`${backOfficeApi}/b2c/package-management/list-reservations`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const getPackageDetails = async (reservation_num) => {
	return await axios
		.get(
			`${backOfficeApi}/b2c/package-management/view-reservation/${reservation_num}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcel = async (params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/package-management/export-reservations`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const sendChangePriority = async (params) => {
	return await axios
		.post(`${backOfficeApi}/b2c/package-management/change-priority`, params)
		.then((res) => res)
		.catch((err) => err.response);
};

export const redirectFilter = async (params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/package-management/change-priority`, params)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addCallLog = async (params) => {
	return await axios
		.post(`${backOfficeApi}/b2c/package-management/add-call-log`, params)
		.then((res) => res)
		.catch((err) => err.response);
};
export const airlineLookup = async (name) => {
	return await axios
		.get(
			`${backOfficeApi}/b2c/package-management/lookups/transports?name=${name}&transportation_type_id=2`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const searchPackages = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/package-management/search-packages/${id}`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const changeReservation = async (data) => {
	return await axios
		.post(`${backOfficeApi}/b2c/package-management/change-reservation`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const redirectReservation = async (data) => {
	return await axios
		.post(`${backOfficeApi}/b2c/package-management/redirect-reservation`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getPackageHistory = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/b2c/package-management/history/${id}`, params)
		.then((res) => res)
		.catch((err) => err.response);
};
