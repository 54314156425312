import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import {
	ChangeStatusTour,
	exportToExcelTours,
	listBookingTours,
} from "services/Request";
import Locale from "translations";
import RequestModal from "../modals/requestModal";
import Filter from "./Filter";

export default function BookingTours() {
	const { locale, allCountries } = useGlobalState();
	const { backOffice } = Locale;
	const [tourList, setTourList] = useState([]);
	const [countries, setCountries] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		country: null,
		// booking_date: undefined,
		start_booking_date: undefined,
		end_booking_date: undefined,
		tour_date: null,
		status: null,
		source: null,
	});
	const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
	const [typeRequest, setTypeRequest] = useState({});

	function toggleRequestModal(type = null, id = null) {
		setRequestModalIsOpen((prev) => !prev);
		setTypeRequest({ type, id });
	}
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);
	useEffect(() => {
		const fetchTours = async () => {
			const res = await listBookingTours({
				//...filter,
				search: filter.search,
				country_id: filter.country?.value,
				created_at_from: filter.start_booking_date
					? moment(filter.start_booking_date).format("YYYY-MM-DD")
					: null,
				created_at_to: filter.end_booking_date
					? moment(filter.end_booking_date).format("YYYY-MM-DD")
					: null,
				start_date: filter.tour_date
					? moment(filter.tour_date).format("YYYY-MM-DD")
					: null,
				status: filter.status?.value,
				source: filter.source?.value,
				page: filter.page,
			});
			setTourList(res.data.data);
			setMeta(res?.data?.meta);
		};
		fetchTours();
	}, [filter]);

	const statusClasses = (status) => {
		switch (status) {
			case "pending":
				return "text-light-yellow";
			case "confirmed":
			case "paid":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};
	const allAirlineList =
		tourList.length > 0 ? (
			tourList.map((item) => {
				return (
					<tr className="text-nowrap" key={item.id}>
						<td>{item.safa_reference}</td>
						<td>{item?.company?.name[locale]}</td>
						<td>{item.leader_name}</td>

						<td>{moment(item.confirmation_date).format("YYYY-MM-DD")} </td>
						<td>{moment(item.free_cancel_date).format("YYYY-MM-DD")} </td>
						<td>
							{item?.auto_cancel_date
								? moment(item.auto_cancel_date).format("YYYY-MM-DD")
								: "-"}{" "}
						</td>
						{/* <td>{item?.provider_total_price}</td>
						<td>{item?.provider_currency}</td> */}
						<td>{item.total_price}</td>
						<td>{item?.currency}</td>
						{/* <td>{item?.gds == 10 ? "SBS" : "Hotelbeds"}</td> */}
						<td>{item?.source}</td>

						<td className={`${statusClasses(item.status)} text-capitalize`}>
							{item.status}
						</td>
						<td>
							<div className="tours-action d-flex align-items-center justify-content-end">
								<ShowForPermission permission="Tours-Bookings-Manage">
									{item?.status === "pending" && (
										<>
											<button
												className="btn btn-link  text-success"
												onClick={() => toggleRequestModal("accept", item.brn)}
											>
												<i class="fas fa-check"></i>
											</button>
											<button
												className="btn btn-link text-danger"
												onClick={() => toggleRequestModal("reject", item.brn)}
											>
												<i class="fas fa-times"></i>
											</button>
										</>
									)}
								</ShowForPermission>

								<Link to={`/bookings-tours/${item.id}`}>
									{locale === "en" ? (
										<i class="fas fa-angle-right mx-2"></i>
									) : (
										<i class="fas fa-angle-left mx-2"></i>
									)}
								</Link>
							</div>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter((prev) => ({ ...prev, page: page }));
	};

	async function handleRequest(e = null) {
		let data;
		if (typeRequest.type == "accept") {
			data = {
				status: "approved",
				expiration_date: moment(e?.expiration_date).format("YYYY-MM-DD"),
			};
		} else {
			data = {
				status: "rejected",
				reject_reason: e?.reject_reason,
			};
		}

		const res = await ChangeStatusTour(typeRequest.id, data);
		if (res.status === 200 || res.status === 201) {
			toggleRequestModal();
			setFilter((prev) => ({ ...prev }));
			store.addNotification({
				title: "Info",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					//toggleRequestModal();
					setTypeRequest({});
				},
			});
		}
	}
	const exportToFile = async () => {
		const res = await exportToExcelTours({
			search: filter.search,
			country_id: filter.country?.value,
			created_at_from: filter.start_booking_date
				? moment(filter.start_booking_date).format("YYYY-MM-DD")
				: null,
			created_at_to: filter.end_booking_date
				? moment(filter.end_booking_date).format("YYYY-MM-DD")
				: null,
			start_date: filter.tour_date
				? moment(filter.tour_date).format("YYYY-MM-DD")
				: null,
			status: filter.status?.value,
			source: filter.source?.value,
			page: filter.page,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Tours-Booking-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<div>
			<div className="d-flex justify-content-between">
				<h3 className="mb-2">Tour Reservations</h3>

				<button className="btn btn-primary" onClick={() => exportToFile()}>
					Export To Excel
				</button>
			</div>
			<Filter filter={filter} setFilter={setFilter} countries={countries} />

			<div className=" table-responsive px-4  our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>Ref.</th>
							<th>Agancy</th>
							<th>Name</th>
							<th>Booking Date</th>
							<th>Free cancelation Date</th>
							<th>Auto cancelation</th>
							<th>Total Price</th>
							<th>Currency</th>
							<th>Source</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>

					<tbody>{allAirlineList}</tbody>
				</table>
				<Pagination info={meta} goTo={goTo} />
			</div>

			{requestModalIsOpen && (
				<RequestModal
					isOpen={requestModalIsOpen}
					toggle={toggleRequestModal}
					submit={handleRequest}
					typeRequest={typeRequest.type}
				/>
			)}
		</div>
	);
}
