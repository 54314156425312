import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from 'translations';



export default function CommercialDistTourFilters({ filters, setFilters, countries }) {
  const { backOffice, vendor } = Locale;

  function resetFilters() {
    setFilters({
      search: "",
      country: null,
      booking_date: null,
      tour_date: null,
      status: null,
      page: 1,
    });
  };

  const STATUS = [
    { value: 6, label: "confirmed" },
    { value: 4, label: "canceled" },
    // { value: 1, label: "pending" },
    // { value: 2, label: "rejected" },
    // { value: 7, label: "expired" },
  ];

  return (
    <div className="main-filter bg-white-blue py-2 my-2">
      <div className="row align-items-center w-100 no-gutter m-0">
        {/* search */}
        <div className="col-2">
          <TextField
            type="text"
            placeholder={backOffice.search}
            label={backOffice.search}
            hasLabel={true}
            value={filters.search}
            onChange={(e) => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
          />
        </div>
        {/* country */}
        {/* <div className="col-2">
          <SelectField
            placeholder={"Country"}
            label={"Country"}
            options={countries}
            value={filters.country}
            onChange={(e) => {
              setFilters({
                ...filters,
                country: e,
              });
            }}
          />
        </div> */}
        {/* booking date */}
        <div className="col-2">
          <DatePickerField
            hasLabel={true}
            label={vendor.bookingDate}
            date={filters.booking_date}
            placeholder={vendor.bookingDate}
            onChangeDate={(filterDate) => {
              setFilters({
                ...filters,
                booking_date: filterDate,
              });
            }}
            isOutsideRange={() => false}
          />
        </div>
        {/* tour date */}
        <div className="col-2">
          <DatePickerField
            hasLabel={true}
            label={backOffice.tourDate}
            date={filters.tour_date}
            placeholder={backOffice.tourDate}
            onChangeDate={(filterDate) => {
              setFilters({
                ...filters,
                tour_date: filterDate,
              });
            }}
            isOutsideRange={() => false}
          />
        </div>
        {/* reservation status */}
        <div className="col-2">
          <SelectField
            hasLabel={true}
            label={vendor.status}
            value={filters.status}
            placeholder={backOffice.status}
            name="status"
            options={STATUS}
            onChange={(e) => {
              setFilters({
                ...filters,
                status: e,
              });
            }}
          />
        </div>


        <div className="col-md">
          <div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn" onClick={resetFilters}>
            <img src={resetIcon} alt="reset filters" />
            <p className="text-caption">{backOffice.resetFilter}</p>
          </div>
        </div>

      </div>


    </div>
  )
}
