import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import AutoCompleteMultiField from "components/Form/AutoCompleteMultiField/AutoCompleteMultiField";
import NumberField from "components/Form/NumberField/NumberField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { isInclusivelyAfterDay } from "react-dates";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { fetchCitiesSearch } from "services/lookups";
import { listMarketplaceCurrencies } from "services/multiWallets";
import {
	fetchProviderslist,
	fetchProvidorHotels,
	searchHotelsApi,
} from "services/wallet";
import Locale from "translations";

const MarketingOffers = () => {
	const { locale, allCountries } = useGlobalState();
	const { backOffice, booking, marketPlace } = Locale;
	let history = useHistory();
	const searchTypeOptions = [
		{ label: "Hotels", value: 0 },
		{ label: "Offers", value: 1 },
	];

	const [countries, setCountries] = useState([]);
	const [countriesAll, setCountriesAll] = useState([]);
	const [countriesAll2, setCountriesAll2] = useState([]);
	const [countriesAllClone, setCountriesAllClone] = useState([]);

	const [currencies, setCurrencies] = useState([]);

	const [ProviderOption, setProviderOption] = useState([]);
	const [searchState, setSearchState] = useState({
		title: "",
		destination: "",
		Hotels: [],
		HotelsSelected: [],
		Provider: "",
		checkIn: moment(new Date()).add(1, "d"),
		checkOut: moment(new Date()).add(2, "d"),

		StartDate: moment(new Date()).add(1, "d"),
		EndDate: moment(new Date()).add(2, "d"),
		residence: "",
		nationality: "",
		currency: "",
		country_id: "",
		rooms: [{ number_of_adult: null, BoxChild: false }],
		searchState: "",
		searchType: searchTypeOptions[0],
		expireDate: moment(new Date()).add(1, "d"),
		discount: "",
	});

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [errors, setErrors] = useState({
		rooms: [],
	});
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					//name: item.name + " - " + item.country.name[locale],
					name: item.name,
					value: item.id,
					label: item.name,
					country: item.country,
				};
			});

			setListAuto(result);
		}
	};

	const [listHotels, setListHotels] = useState([]);
	const getHotelsListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const Hotels = await fetchProvidorHotels(
				searchState.Provider.value,
				inputValue,
				searchState?.destination?.value
			);
			let result = Hotels?.data.map((item) => {
				return {
					...item,
					id: item.hotel_id,
					name: item.name,
					value: item.hotel_id,
					label: item.name,
				};
			});

			setListHotels(result);
		}
	};

	const handleChange = (e, flag = false, resetHotels) => {
		const name = e.target?.name;
		const value = e.value?.name;
		if (flag) {
			setSearchState({
				...searchState,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else
			setSearchState({
				...searchState,
				destination: {
					label: e?.value?.name,
					value: e?.value?.id,
					country: e?.value?.country,
				},
			});
		setSearchState({
			...searchState,
			destination: {
				label: e?.value?.name,
				value: e?.value?.id,
				country: e?.value?.country,
			},
			HotelsSelected: resetHotels && e?.name === "destination" ? [] : searchState?.HotelsSelected,
			Hotels: resetHotels && e?.name === "destination" ? [] : searchState?.Hotels,
			HotelsName: resetHotels && e?.name === "destination" ? [] : searchState?.HotelsName,
		});
	};
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountriesAll([{ label: "All", value: 0 }, ...format]);
			setCountriesAll2([{ label: "All", value: 0 }, ...format]);
			setCountriesAllClone([{ label: "All", value: 0 }, ...format]);
			setCountries([{ label: "All", value: 0 }, ...format]);
		}
		countriesLookups();
	}, [allCountries]);

	useEffect(() => {
		const fetchCurrencies = async () => {
			const currency = await listMarketplaceCurrencies();
			if (currency?.status === 200) {
				const formatted = currency.data?.map((item) => {
					return { value: item.id, label: item.currency_code };
				});
				setCurrencies(formatted);
			}
		};
		// fetch providorslist
		const fetchProviders = async () => {
			const providors = await fetchProviderslist();
			if (providors?.status == 200) {
				const providersList = providors.data?.map((p) => {
					return { value: p.id, label: p.name };
				});
				setProviderOption(providersList);
			}
		};
		fetchProviders();
		fetchCurrencies();
	}, []);

	// useEffect(()=>{
	// 	const providorHotels = async () =>{
	// 		const hotels = await fetchProvidorHotels(searchState.Provider?.value);
	// 		if(hotels?.status == 200){
	// 			const hotelsList = hotels.data?.map((h)=>{
	// 				return {value:h.hotel_id , label:h.name}
	// 			})
	// 			setHotelsOption(hotelsList)
	// 		}
	// 	}
	// 	providorHotels()
	// 	// {searchState.Provider.value == undefined ?
	// 	// null:providorHotels()}
	// },[searchState.Provider])

	const setRoomsCount = (name, index, value) => {
		let x = searchState?.rooms;
		x[index] = { ...x[index], [name]: value };
		if (name == "roomLength") {
			let rooms = [...Array(+value).keys()].map((res) => ({
				...res,
				children: [],
			}));
			x[index] = { ...x[index], roomsList: rooms };
		}
		setSearchState({ ...searchState, rooms: x });
	};
	const setChildrenCount = (name, index, value) => {
		let x = searchState?.rooms;
		let rooms = [...Array(+value)?.keys()].map((res) => ({
			...res,
			age: 0,
		}));
		let roomsList = x[index].roomsList;
		if (roomsList && roomsList?.length > 0) {
			roomsList.map((res) => {
				res.children = rooms;
			});
		}
		x[index] = { ...x[index], [name]: rooms };
		setSearchState({ ...searchState, rooms: x });
	};
	const setAge = (index, DD, XX, value) => {
		let x = searchState?.rooms;
		let c = x[index]?.roomsList[DD].children;
		const newArr = c.map((ress, indexs) => {
			if (indexs == XX) {
				return { ...ress, age: +value };
			}
			return { ...ress };
		});
		x[index].roomsList[DD].children = newArr;
		setSearchState({ ...searchState, rooms: x });
	};
	useEffect(() => {
		setRoomsCount("roomLength", 0, 1);
		setRoomsCount("number_of_adult", 0, 2);
	}, []);

	const offersOption = [
		{
			id: 3,
			label: "Makkah",
			name: "Makkah",
			value: 3,
		},
		{
			id: 4,
			label: "Madinah",
			name: "Madinah",
			value: 4,
		},
	];

	const checkFormErrors = () => {
		let roomError = [];
		searchState.rooms.map((res) => {
			let newError = {
				...validate(
					{
						name: "roomLength",
						value: res.roomLength,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "number_of_adult",
						value: res.number_of_adult,
					},
					{
						required: true,
					}
				),
			};

			roomError.push(newError);
		});

		let SubmitError = {};
		if (searchState.Hotels?.length == 0) {
			SubmitError = { required: true };
		} else {
			SubmitError = { required: false };
		}
		setErrors({
			...errors,
			// Hotels: SubmitError,
			rooms: roomError,
			...validate(
				{ name: "title", value: searchState.title },
				{ required: true }
			),
			...validate(
				{ name: "destination", value: searchState.destination },
				{ required: true }
			),
			...validate(
				{ name: "residence", value: searchState.residence },
				{ required: true }
			),
			...validate(
				{ name: "nationality", value: searchState.nationality },
				{ required: true }
			),
			// ...validate(
			// 	{ name: "goingTo", value: searchState.goingTo },
			// 	{ required: true }
			// ),
			...validate(
				{ name: "checkIn", value: searchState.checkIn },
				{ required: true }
			),
			...validate(
				{ name: "checkOut", value: searchState.checkOut },
				{ required: true }
			),
			...validate(
				{ name: "StartDate", value: searchState.StartDate },
				{ required: true }
			),
			...validate(
				{ name: "EndDate", value: searchState.EndDate },
				{ required: true }
			),

			// ...validate(
			// 	{ name: "Hotels", value: searchState.Hotels },
			// 	{ required: true }
			// ),
			...validate(
				{ name: "Provider", value: searchState.Provider },
				{ required: true }
			),
			...validate(
				{ name: "currency", value: searchState.currency },
				{ required: true }
			),
			...validate(
				{ name: "searchType", value: searchState.searchType },
				{ required: true }
			),
			...validate(
				{ name: "expireDate", value: searchState.expireDate },
				{ required: true }
			),
			...validate(
				{ name: "country_id", value: searchState.country_id },
				{ required: true }
			),
			...validate(
				{ name: "discount", value: searchState.discount },
				{ maxNumber: 100 }
			),
		});
	};

	const handleSearchCheck = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		const SearchHotels = async () => {
			if (Object.values(errors).length > 1) {
				const flag = errors.rooms.every((error) => {
					if (!isFormValid(error)) {
						return false;
					}
					return true;
				});
				if (isFormValid({ ...errors, rooms: { required: !flag } }) && flag) {
					const handleSearch = async () => {
						let children = [];
						let all_rooms_count = [];
						let rooms_data = searchState?.rooms.map((resa) => {
							resa?.roomsList?.map((res) => {
								children.push({
									number_of_adult: +resa.number_of_adult,
									children: [...res.children],
								});
							});
							return children;
						});
						let rooms_count_payload = searchState?.rooms.map((resa) => {
							let x = [];
							resa?.roomsList?.map((res) => {
								x.push(res.children.map((res1) => res1)[0]);
							});
							all_rooms_count.push({
								number_of_adult: +resa.number_of_adult,
								rooms_count: resa.roomLength,
								children: x,
							});
							return all_rooms_count;
						});
						const data = {
							is_umrah: 0,
							is_vacation: 1,
							special_code: "",
							//country: searchState.destination.value,
							title: searchState?.title,
							hotels: searchState.HotelsSelected,
							provider_id: searchState.Provider?.value,
							city_code: searchState?.destination?.value,
							date_from: moment(searchState.checkIn).format("YYYY-MM-DD"),
							date_to: moment(searchState.checkOut).format("YYYY-MM-DD"),
							start_date: moment(searchState.StartDate).format("YYYY-MM-DD"),
							end_date: moment(searchState.EndDate).format("YYYY-MM-DD"),
							expiration_date: moment(searchState.expireDate).format(
								"YYYY-MM-DD"
							),
							//nationality: searchState?.nationality?.code,
							//residency: searchState?.residence?.code,
							nationalities: searchState?.nationality?.map((item) => {
								if (item.value == 0 || item.value == "0") {
									return 0;
								} else {
									return {
										country_code: item?.code,
										name: item?.label,
									};
								}
							}),
							residences: searchState?.residence?.map((item) => {
								if (item.value == 0 || item.value == "0") {
									return 0;
								} else {
									return {
										country_code: item?.code,
										name: item?.label,
									};
								}
							}),
							lang: locale,
							rooms_data: children,
							currency_code: searchState?.currency?.label,
							is_offer: searchState?.searchType?.value,
							//country_id: searchState?.country_id?.value,
							country: searchState?.country_id?.map((item) => {
								if (item.value == 0 || item.value == "0") {
									return 0;
								} else {
									return {
										country_id: item?.value,
										name: item?.label,
									};
								}
							}),
							discount: searchState?.discount,
							city_name: searchState?.destination?.label,
							rooms_count_payload: all_rooms_count,
							page: 0,
						};
						// let response;
						// if (searchState?.searchType?.value== 1) {
						// 	response = await fetchHotelsOffers(data);
						// 	localStorage.setItem("hotelOffer", 1);
						// } else {
						// 	response = await fetchHotels(data);
						// 	localStorage.setItem("hotelOffer", 0);
						// }

						console.log("post_data", data);
						let response = await searchHotelsApi(data);
						if (response.status === 200) {
							store.addNotification({
								title: "Offer",
								message: response?.data?.message
									? response?.data?.message
									: "Search Successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animated", "fadeIn"],
								animationOut: ["animated", "fadeOut"],
								dismiss: {
									duration: 2000,
									onScreen: true,
									pauseOnHover: true,
								},
								onRemoval: () => {
									history.push("/marketing-offers-list");

									// window.location.reload();
								},
							});
						}
					};
					handleSearch();
				}
			}
		};

		SearchHotels();
	}, [isErrorLoaded]);

	const handleAll = (e, name = null) => {
		let selectedItem = e?.map((item) => {
			if (item.label == "All") {
				return true;
			} else {
				return false;
			}
		});
		selectedItem = selectedItem?.some((e) => e == true);
		if (name == "nationality") {
			setCountriesAll(selectedItem ? [] : countriesAllClone);
		} else {
			setCountriesAll2(selectedItem ? [] : countriesAllClone);
		}
	};
	console.log("searchState88888", searchState);
	return (
		<>
			<div className="border">
				<div className="d-flex justify-content-between p-4 border-bottom">
					<h6 className="font-weight-bold">{marketPlace.marketingOffers}</h6>
				</div>
				<div className="search-hotels HotelSearch my-3">
					<div className="row align-items-start w-100 no-gutter m-0">
						<div className="col-6 ">
							<TextField
								type="text"
								label={marketPlace.Title}
								placeholder={marketPlace.Title}
								name="title"
								value={searchState.title}
								onChange={(e) => {
									setSearchState({
										...searchState,
										title: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "title", value: e.target.value },
											{
												required: true,
											}
										),
									});
								}}
								color={
									errors?.title?.required || errors?.title?.maxNumber
										? "danger"
										: ""
								}
								errors={errors?.title}
							/>
						</div>
						<div className="col-6">
							<SelectField
								label={marketPlace.searchType}
								placeholder={marketPlace.searchType}
								options={searchTypeOptions}
								name="searchType"
								onChange={(e) => {
									setSearchState({
										...searchState,
										searchType: e,
										destination: "",
										Provider:
											e.value == 1
												? { label: "SBS supplier", value: 3 }
												: searchState.Provider,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "searchType", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={searchState.searchType}
								color={errors?.searchType?.required ? "danger" : ""}
								errors={errors?.searchType}
								disabled={true}
							/>
						</div>

						<div className="col-12">
							<SelectField
								disabled={searchState.searchType?.value == 1}
								label={marketPlace.Provider}
								placeholder={marketPlace.Provider}
								options={ProviderOption}
								name="Provider"
								onChange={(e) => {
									setSearchState({
										...searchState,
										Provider: e,
										Hotels: [],
										HotelsSelected: [],
										HotelsName: "",
									});
									setErrors({
										...errors,
										...validate(
											{ name: "Provider", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={searchState.Provider}
								color={errors?.Provider?.required ? "danger" : ""}
								errors={errors?.Provider}
							/>
						</div>
						<div className="col-6 booking-autoComplete">
							{searchState.searchType == "" ||
							searchState.searchType?.value == 0 ? (
								<AutoCompleteField
									disabled={searchState.searchType == ""}
									hasLabel={true}
									flag={searchState.destination?.country?.flag}
									listAuto={listAuto}
									setListAuto={setListAuto}
									getListAuto={getListAuto}
									isSearchable={true}
									placeholder={booking.Destination}
									label={booking.Destination}
									value={searchState.destination?.label}
									name="destination"
									onChange={(e) => {
										handleChange(
											{ name: "destination", value: { name: e } },
											true
										);
										setErrors({
											...errors,
											...validate(
												{ name: "destination", value: e },
												{
													required: true,
												}
											),
										});
									}}
									onSelectValue={(e) => {
										handleChange({ name: "destination", value: e }, true, true);
									}}
									color={errors?.destination?.required ? "danger" : ""}
									errors={errors?.destination}
								/>
							) : (
								<SelectField
									label={booking.Destination}
									placeholder={booking.Destination}
									options={offersOption}
									name="destination"
									onChange={(e) => {
										setSearchState({
											...searchState,
											destination: e,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "destination", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={searchState.destination}
									color={errors?.destination?.required ? "danger" : ""}
									errors={errors?.destination}
								/>
							)}
							<div
								className="selected-hotels text-white"
								style={{ height: "80px !important" }}
							>
								{searchState.HotelsSelected.map((h) => {
									return (
										<p className="hotel hotel-display" >
											<i
												class="fas fa-times delete-hotel"
												onClick={() => {
													let x = searchState.Hotels;
													let z = searchState.HotelsSelected;
													let SelectedHotelsId = x.filter(
														(res) => res != h.value
													);
													let SelectedHotels = z.filter(
														(res) => res.value != h.value
													);
													setSearchState({
														...searchState,
														Hotels: SelectedHotelsId,
														HotelsSelected: SelectedHotels,
													});
												}}
											></i>
										</p>
									);
								})}
							</div>
						</div>
						<div className="col-6 search-hotels-autoComp-feild">
							<AutoCompleteMultiField
								disabled={
									searchState.Provider == "" || searchState.destination == ""
								}
								hasLabel={true}
								listAuto={listHotels}
								setListAuto={setListHotels}
								getListAuto={getHotelsListAuto}
								isSearchable={true}
								placeholder={marketPlace.Hotels}
								label={marketPlace.Hotels}
								value={searchState.HotelsName}
								ListValue={searchState.Hotels}
								name="Hotels"
								onChange={(e) => {
									setSearchState({
										...searchState,
										HotelsName: e,
									});
									// setErrors({
									// 	...errors,
									// 	...validate(
									// 		{ name: "Hotels", value: e },
									// 		{
									// 			required: true,
									// 		}
									// 	),
									// });
								}}
								onSelectValue={(checked, e) => {
									let x = searchState.Hotels;
									let z = searchState.HotelsSelected;
									if (checked) {
										setSearchState({
											...searchState,
											Hotels: [...x, e.value],
											HotelsSelected: [...z, e],
										});
										setErrors({
											...errors,
											...validate(
												{ name: "Hotels", value: e },
												{
													required: true,
												}
											),
										});
									} else {
										let SelectedHotelsId = x.filter((res) => res != e.value);
										let SelectedHotels = z.filter(
											(res) => res.value != e.value
										);
										setSearchState({
											...searchState,
											Hotels: SelectedHotelsId,
											HotelsSelected: SelectedHotels,
										});
									}

									// handleChange({ name: "Hotels", value: e }, true);
								}}
								// color={
								// 	errors?.Hotels?.required && searchState?.Hotels?.length == 0
								// 		? "danger"
								// 		: ""
								// }
								// errors={errors?.Hotels && searchState?.Hotels?.length == 0}
							/>
							<div
								className="selected-hotels"
								style={{ height: "80px !important" }}
							>
								{searchState.HotelsSelected.map((h) => {
									return (
										<p className="hotel" title={h.name}>
											<span className="hotel-name">{h.name}</span>
											<i
												class="fas fa-times delete-hotel"
												onClick={() => {
													let x = searchState.Hotels;
													let z = searchState.HotelsSelected;
													let SelectedHotelsId = x.filter(
														(res) => res != h.value
													);
													let SelectedHotels = z.filter(
														(res) => res.value != h.value
													);
													setSearchState({
														...searchState,
														Hotels: SelectedHotelsId,
														HotelsSelected: SelectedHotels,
													});
												}}
											></i>
										</p>
									);
								})}
							</div>
							{/* <SelectField
								label={marketPlace.Hotels}
								placeholder={marketPlace.Hotels}
								multi={true}
								options={HotelsOption}
								name="Hotels"
								onChange={(e) => {
									setSearchState({
										...searchState,
										Hotels: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "Hotels", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={searchState.Hotels}
								color={errors?.Hotels?.required ? "danger" : ""}
								errors={errors?.Hotels}
							/> */}
						</div>

						<div className="col-6">
							<DatePickerField
								type="text"
								placeholder={marketPlace.checkin}
								onOutsideClick={false}
								hasLabel={true}
								label={marketPlace.checkin}
								date={searchState?.checkIn}
								name="CheckIn"
								onChangeDate={(e) => {
									setSearchState({
										...searchState,
										checkIn: e,
										checkOut: moment(searchState.checkOut).isAfter(
											moment(e).add(1, "d")
										)
											? searchState.checkOut
											: moment(e).add(1, "d"),
									});

									setErrors({
										...errors,
										...validate(
											{ name: "CheckIn", value: e },
											{
												required: true,
											}
										),
									});
								}}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
								color={errors?.CheckIn?.required ? "danger" : ""}
								errors={errors?.CheckIn}
							/>
						</div>
						<div className="col-6">
							<DatePickerField
								type="text"
								placeholder={marketPlace.Checkout}
								onOutsideClick={false}
								hasLabel={true}
								label={marketPlace.Checkout}
								date={
									searchState?.checkOut == null
										? moment(searchState.checkIn).add(1, "d")
										: searchState?.checkOut
								}
								onChangeDate={(e) => {
									setSearchState({
										...searchState,
										checkOut: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "CheckOut", value: e },
											{
												required: true,
											}
										),
									});
								}}
								color={errors?.CheckOut?.required ? "danger" : ""}
								errors={errors?.CheckOut}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							/>
						</div>

						<div className="col-6">
							<DatePickerField
								type="text"
								placeholder={marketPlace.StartDate}
								onOutsideClick={false}
								hasLabel={true}
								label={marketPlace.StartDate}
								date={searchState?.StartDate}
								name="StartDate"
								onChangeDate={(e) => {
									setSearchState({
										...searchState,
										StartDate: e,
										EndDate: moment(searchState.EndDate).isAfter(
											moment(e).add(1, "d")
										)
											? searchState.EndDate
											: moment(e).add(1, "d"),
									});

									setErrors({
										...errors,
										...validate(
											{ name: "StartDate", value: e },
											{
												required: true,
											}
										),
									});
								}}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
								color={errors?.StartDate?.required ? "danger" : ""}
								errors={errors?.StartDate}
							/>
						</div>

						<div className="col-6">
							<DatePickerField
								type="text"
								placeholder={marketPlace.EndDate}
								onOutsideClick={false}
								hasLabel={true}
								label={marketPlace.EndDate}
								date={
									searchState?.EndDate == null
										? moment(searchState.StartDate).add(1, "d")
										: searchState?.EndDate
								}
								name="EndDate"
								onChangeDate={(e) => {
									setSearchState({
										...searchState,
										EndDate: e,
									});

									setErrors({
										...errors,
										...validate(
											{ name: "EndDate", value: e },
											{
												required: true,
											}
										),
									});
								}}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
								color={errors?.EndDate?.required ? "danger" : ""}
								errors={errors?.EndDate}
							/>
						</div>
						{/**Start Expiration Date && Search Type */}

						<div className="col-6">
							<DatePickerField
								type="text"
								placeholder={marketPlace.expirationDate}
								onOutsideClick={false}
								hasLabel={true}
								label={marketPlace.expirationDate}
								date={searchState?.expireDate}
								onChangeDate={(e) => {
									setSearchState({
										...searchState,
										expireDate: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "expireDate", value: e },
											{
												required: true,
											}
										),
									});
								}}
								color={errors?.expireDate?.required ? "danger" : ""}
								errors={errors?.expireDate}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							/>
						</div>
						<div className="col-6 ">
							<TextField
								type="number"
								label={"Discount"}
								placeholder={"Discount"}
								name="discount"
								value={searchState.discount}
								onChange={(e) => {
									setSearchState({
										...searchState,
										discount: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "discount", value: e.target.value },
											{
												maxNumber: 100,
											}
										),
									});
								}}
								color={errors?.discount?.maxNumber ? "danger" : ""}
								errors={errors?.discount}
							/>
						</div>
						{/**End Expiration Date && Search Type */}

						{/**Start Rooms */}
						<div className="col-md-12">
							<label>{marketPlace?.rooms}</label>
						</div>
						<div className="col-md-12  bg-Rooms py-3">
							<div className="col-md-11 m-auto">
								<div className=" product-build__product-collpase branch-collapse my-0 mb-2">
									{/* rooms */}
									<div className="row">
										{searchState?.rooms && searchState?.rooms.length > 0
											? searchState?.rooms?.map((res, index) => {
													return (
														<>
															<div
																className="col-md-11"
																style={{ padding: "0 15px" }}
															>
																<div className="button-collapse my-2">
																	<div className="title-style-container">
																		<div
																			className="w-100"
																			onClick={() => {
																				setRoomsCount(
																					"BoxChild",
																					index,
																					!res.BoxChild
																				);
																			}}
																		>
																			<div className="w-100">
																				<p
																					className="global-input font-weight-bold border-0 "
																					value=""
																					type="text"
																				>
																					<span className="roomsNumber">
																						{res.roomLength} {"  "}
																						{marketPlace.messages.room}
																					</span>
																					<span className="adultNumber">
																						({res.number_of_adult}{" "}
																						{marketPlace.messages.adult} {"  "},
																						{res?.children?.length}{" "}
																						{marketPlace.messages.child}){" "}
																						{marketPlace.perroom}
																					</span>
																				</p>
																			</div>
																		</div>

																		<div className="icon-items-section d-flex align-items-center">
																			<i
																				onClick={() =>
																					setRoomsCount(
																						"BoxChild",
																						index,
																						!res.BoxChild
																					)
																				}
																				className={`fas fa-fw text-primary fa-lg ${
																					res.BoxChild !== true
																						? "fa-chevron-right "
																						: "fa-chevron-down"
																				}`}
																			></i>
																		</div>
																	</div>
																	{res.BoxChild ? (
																		<div
																			className="row box-child"
																			//	ref={DetectClickOutside}
																		>
																			<div
																				className="col-md-12"
																				style={{ padding: "0 15px" }}
																			>
																				<div className="row">
																					<div className="col-md-4 my-3">
																						<div className="container-centerLabel px-2">
																							<label htmlFor="">
																								{marketPlace.messages.rooms}
																							</label>
																						</div>
																					</div>
																					<div className="col-md-8">
																						<NumberField
																							placeholder={
																								marketPlace.messages.NoOfRooms
																							}
																							increase={() => {
																								setRoomsCount(
																									"roomLength",
																									index,
																									res.roomLength
																										? +res.roomLength + 1
																										: 0 + 1
																								);
																								setChildrenCount(
																									"children",
																									index,
																									0
																								);
																							}}
																							decrease={() => {
																								setRoomsCount(
																									"roomLength",
																									index,
																									res.roomLength - 1
																								);
																								setChildrenCount(
																									"children",
																									index,
																									0
																								);
																							}}
																							max={9}
																							min={1}
																							value={res.roomLength}
																							hasLabel={false}
																							label={marketPlace.messages.rooms}
																							name="roomLength"
																							type="number"
																							onChange={(e) => {
																								let removeZero = e.target.value;
																								if (e.target.value[0] == 0) {
																									removeZero =
																										e.target.value.substring(1);
																								}
																								setRoomsCount(
																									"roomLength",
																									index,
																									+removeZero > 9
																										? 9
																										: removeZero < 1
																										? 1
																										: removeZero
																								);
																								setChildrenCount(
																									"children",
																									index,
																									0
																								);
																							}}
																							color={
																								errors?.rooms[index]?.roomLength
																									?.required
																									? "danger"
																									: ""
																							}
																							errors={
																								errors?.rooms[index]?.roomLength
																							}
																						/>
																					</div>
																				</div>
																			</div>

																			<div
																				className="col-md-12"
																				style={{ padding: "0 15px" }}
																			>
																				<div className="row">
																					<div className="col-md-4 my-3">
																						<div className="container-centerLabel px-2">
																							<label
																								className="m-0 w-100"
																								htmlFor=""
																							>
																								{marketPlace.messages.adult}
																							</label>
																							<p className="small-title">
																								{marketPlace.ForEveryRoom}
																							</p>
																						</div>
																					</div>
																					<div className="col-md-8">
																						<NumberField
																							placeholder={
																								marketPlace.messages.NoOfAdults
																							}
																							hasLabel={false}
																							label={marketPlace.messages.adult}
																							value={res.number_of_adult}
																							name="number_of_adult"
																							max={6}
																							min={1}
																							increase={() => {
																								setRoomsCount(
																									"number_of_adult",
																									index,
																									res.number_of_adult
																										? +res.number_of_adult + 1
																										: 0 + 1
																								);
																							}}
																							decrease={() => {
																								setRoomsCount(
																									"number_of_adult",
																									index,
																									res.number_of_adult - 1
																								);
																							}}
																							onChange={(e) => {
																								let removeZero = e.target.value;
																								if (e.target.value[0] == 0) {
																									removeZero =
																										e.target.value.substring(1);
																								}

																								setRoomsCount(
																									"number_of_adult",
																									index,
																									+removeZero > 6
																										? 6
																										: removeZero < 1
																										? 1
																										: removeZero
																								);
																							}}
																							type={"number"}
																							color={
																								errors?.rooms[index]
																									?.number_of_adult?.required
																									? "danger"
																									: ""
																							}
																							errors={
																								errors?.rooms[index]
																									?.number_of_adult
																							}
																						/>
																					</div>
																				</div>{" "}
																			</div>

																			<div
																				className="col-md-12"
																				style={{ padding: "0 15px" }}
																			>
																				<div className="row">
																					<div className="col-md-4 my-3">
																						<div className="container-centerLabel px-2">
																							<label
																								className="m-0 w-100"
																								htmlFor=""
																							>
																								{marketPlace.messages.child}
																							</label>
																							<p className="small-title">
																								{marketPlace.ForEveryRoom}
																							</p>
																						</div>
																					</div>
																					<div className="col-md-8">
																						<NumberField
																							placeholder={
																								marketPlace.messages
																									.NoOfChildren
																							}
																							hasLabel={false}
																							label={marketPlace.messages.child}
																							name="rooms_count"
																							max={4}
																							min={0}
																							value={res?.children?.length.toString()}
																							increase={() => {
																								setChildrenCount(
																									"children",
																									index,
																									res.children.length
																										? +res.children.length + 1
																										: 0 + 1
																								);
																							}}
																							decrease={() => {
																								setChildrenCount(
																									"children",
																									index,
																									res.children.length - 1
																								);
																							}}
																							onChange={(e) => {
																								let removeZero = e.target.value;

																								if (e.target.value[0] == 0) {
																									removeZero =
																										e.target.value.substring(1);
																								}

																								setChildrenCount(
																									"children",
																									index,
																									+removeZero > 4
																										? 4
																										: removeZero < 0
																										? 0
																										: removeZero
																								);
																							}}
																							type={"number"}
																							color={
																								errors?.rooms[index]?.children
																									?.required
																									? "danger"
																									: ""
																							}
																							errors={
																								errors?.rooms[index]?.children
																							}
																						/>
																					</div>
																				</div>{" "}
																			</div>
																			<div
																				className="col-md-12"
																				style={{ padding: "0 15px" }}
																			>
																				{res?.roomsList &&
																				res?.roomsList?.length > 0 &&
																				res?.children &&
																				res?.children?.length > 0 ? (
																					<p className="text-danger font-weight-bold my-3">
																						{marketPlace.oldChildren}
																					</p>
																				) : (
																					""
																				)}
																				{res?.roomsList &&
																				res?.children?.length > 0 &&
																				res?.roomsList?.length > 0
																					? res.roomsList?.map(
																							(rese, indexRoom) => {
																								return (
																									<>
																										<div className="row">
																											<div className="col-md-3 my-4">
																												<div className="container-centerLabel px-2">
																													<label
																														className="m-0 w-100"
																														htmlFor=""
																													>
																														{
																															marketPlace
																																.messages.room
																														}{" "}
																														{+indexRoom + 1}
																													</label>
																												</div>
																											</div>
																											<div className=" row col-9">
																												{rese?.children &&
																												rese?.children.length >
																													0
																													? rese?.children.map(
																															(
																																child,
																																indexChild
																															) => {
																																return (
																																	<>
																																		<div className="col-md-4 ">
																																			<NumberField
																																				placeholder={`${
																																					marketPlace
																																						.messages
																																						.child
																																				} ${
																																					indexChild +
																																					1
																																				}`}
																																				hasLabel={
																																					false
																																				}
																																				value={
																																					child.age
																																				}
																																				max={12}
																																				min={1}
																																				name="setAge"
																																				onChange={(
																																					e
																																				) => {
																																					let removeZero =
																																						e
																																							.target
																																							.value;

																																					setAge(
																																						index,
																																						indexRoom,
																																						indexChild,
																																						+removeZero >
																																							12
																																							? 12
																																							: removeZero <
																																							  1
																																							? 1
																																							: removeZero
																																					);
																																				}}
																																				type={
																																					"number"
																																				}
																																				color={
																																					""
																																				}
																																				errors={
																																					""
																																				}
																																				// type="number"
																																				onEnter={(
																																					e
																																				) =>
																																					false
																																				}
																																				increase={() => {
																																					setAge(
																																						index,
																																						indexRoom,
																																						indexChild,
																																						+child.age +
																																							1
																																					);
																																				}}
																																				decrease={() => {
																																					setAge(
																																						index,
																																						indexRoom,
																																						indexChild,
																																						+child.age -
																																							1
																																					);
																																				}}
																																			/>
																																		</div>
																																	</>
																																);
																															}
																													  )
																													: ""}
																											</div>
																										</div>
																									</>
																								);
																							}
																					  )
																					: ""}
																			</div>
																		</div>
																	) : null}
																</div>
															</div>

															<div className="col-md-1">
																{index > 0 ? (
																	<span
																		className="span-remove"
																		onClick={() => {
																			let rooms = [...searchState?.rooms];
																			rooms.splice(index, 1);
																			setSearchState({
																				...searchState,
																				rooms: rooms,
																			});
																		}}
																	>
																		<i class="fas fa-times"></i>
																	</span>
																) : null}
															</div>
														</>
													);
											  })
											: null}
									</div>

									<div className="col-md-11 mt-1" style={{ height: "10px" }}>
										<p
											className="text-success  text-right"
											// style={{ background: "red" }}
										>
											<span
												onClick={() => {
													let x = searchState?.rooms;
													x.push({});
													setSearchState({ ...searchState, rooms: x });
													setRoomsCount(
														"roomLength",
														+searchState?.rooms?.length - 1,
														1
													);
													setRoomsCount(
														"number_of_adult",
														+searchState?.rooms?.length - 1,
														2
													);
												}}
												style={{ cursor: "pointer" }}
											>
												{marketPlace.AddNew}
												<i class="fas fa-plus mx-1"></i>{" "}
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>

						{/**End Rooms */}
						<div className="col-3 mt-3">
							<div
								className={`${
									errors?.country_id?.required ? "danger py-2" : ""
								}`}
							>
								<span>{marketPlace.country}</span>
								<Select
									isMulti
									label={marketPlace.country}
									placeholder={marketPlace.country}
									options={countries}
									multi={true}
									name="country_id"
									onChange={(e) => {
										setSearchState({
											...searchState,
											country_id: e,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "country_id", value: e },
												{
													required: true,
												}
											),
										});
									}}
									//className={`${errors?.country_id?.required ? "danger" : ""}`}
									value={searchState.country_id}
									color={errors?.country_id?.required ? "danger" : ""}
									errors={errors?.country_id}
								/>
							</div>
							<small
								className={`control-field__feedback control-field__feedback--danger d-block error-message`}
							>
								{errors?.country_id?.required ? "country_id is Required" : ""}
							</small>
							{/* <SelectField
								label={marketPlace.country}
								placeholder={marketPlace.country}
								options={countries}
								multi={true}
								name="country_id"
								onChange={(e) => {
									setSearchState({
										...searchState,
										country_id: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "country_id", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={searchState.country_id}
								color={errors?.country_id?.required ? "danger" : ""}
								errors={errors?.country_id}
							/> */}
						</div>

						
						<div className="col-3 mt-3">
							<div
								className={`${
									errors?.nationality?.required ? "danger py-2" : ""
								}`}
							>
								<span>{marketPlace.nationality}</span>
								<Select
									isMulti
									llabel={marketPlace.nationality}
									placeholder={marketPlace.nationality}
									options={countriesAll}
									name="nationality"
									multi={true}
									onChange={(e) => {
										handleAll(e, "nationality");

										setSearchState({
											...searchState,
											nationality: e,
											//setCountriesAll(true?[]:countriesAll);
										});
										setErrors({
											...errors,
											...validate(
												{ name: "nationality", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={searchState.nationality}
									//className={`${errors?.nationality?.required ? "danger" : ""}`}
								/>
							</div>
							<small
								className={`control-field__feedback control-field__feedback--danger d-block error-message`}
							>
								{errors?.nationality?.required ? "nationality is Required" : ""}
							</small>
							{/* <SelectField
								label={marketPlace.nationality}
								placeholder={marketPlace.nationality}
								options={countriesAll}
								name="nationality"
								multi={true}
								onChange={(e) => {
									setSearchState({
										...searchState,
										nationality: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "nationality", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={searchState.nationality}
								color={errors?.nationality?.required ? "danger" : ""}
								errors={errors?.nationality}
							/> */}
						</div>
						<div className="col-3 mt-3">
							<div
								className={`${
									errors?.residence?.required ? "danger py-2" : ""
								}`}
							>
								<span>{marketPlace.residence}</span>
								<Select
									isMulti
									label={marketPlace.residence}
									placeholder={marketPlace.residence}
									options={countriesAll2}
									id="basic-countries"
									name="residence"
									multi={true}
									onChange={(e) => {
										handleAll(e);
										setSearchState({
											...searchState,
											residence: e,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "residence", value: e },
												{
													required: true,
												}
											),
										});
									}}
									value={searchState.residence}
									//className={`${errors?.residence?.required ? "danger" : ""}`}
								/>
							</div>
							<small
								className={`control-field__feedback control-field__feedback--danger d-block error-message`}
							>
								{errors?.residence?.required ? "residence is Required" : ""}
							</small>
							{/* <SelectField
								label={marketPlace.residence}
								placeholder={marketPlace.residence}
								options={countriesAll}
								id="basic-countries"
								name="residence"
								multi={true}
								onChange={(e) => {
									setSearchState({
										...searchState,
										residence: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "residence", value: e },
											{
												required: true,
											}
										),
									});
								}}
								value={searchState.residence}
								color={errors?.residence?.required ? "danger" : ""}
								errors={errors?.residence}
							/> */}
						</div>
						<div className="col-3">
							<SelectField
								placeholder={marketPlace.currency}
								label={marketPlace.currency}
								name="currency"
								options={currencies}
								value={searchState?.currency}
								onChange={(e) => {
									setSearchState({
										...searchState,
										currency: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "currency", value: e },
											{
												required: true,
											}
										),
									});
								}}
								color={errors?.currency?.required ? "danger" : ""}
								errors={errors?.currency}
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end">
						<button
							className="btn btn-primary px-3 "
							onClick={handleSearchCheck}
						>
							{marketPlace.generateURL}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default MarketingOffers;
