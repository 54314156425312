import { useGlobalState } from "context/global";
import { Link } from "react-router-dom";

export default function ManulaBookItem({ data, checkedColums }) {
	const { locale } = useGlobalState();

	return (
		<tr>
			{/* {checkedColums.includes("referral_code") ? <td>{data?.referral_code}</td> : null} */}
			{/* {checkedColums.includes("brn") ? <td>{data?.id ? data?.id : "-"}</td> : null} */}

			{checkedColums.includes("sbs_ref") ? <td>{data.sbs_ref}</td> : null}

			{checkedColums.includes("admin_name") ? (
				<td>{data?.admin_name ? data?.admin_name : "-"}</td>
			) : null}

			{checkedColums.includes("supplier") ? (
				<td>{data.supplier ? data?.supplier : "-"}</td>
			) : null}

			{checkedColums.includes("company") ? (
				<td>{data?.company?.name?.[locale]}</td>
			) : null}

			{checkedColums.includes("hotel_name") ? <td>{data.hotel_name}</td> : null}

			{checkedColums.includes("destination") ? (
				<td>{data.destination ? data?.destination?.name : "-"}</td>
			) : null}

			{checkedColums.includes("rooms_count") ? (
				<td>{data.rooms_count}</td>
			) : null}

			{checkedColums.includes("created_at") ? <td>{data.created_at}</td> : null}

			{checkedColums.includes("status") ? (
				<td
					className={`text-capitalize text-${
						data?.status === "pending" ||
						data?.status === "new request" ||
						data?.status === "tentative"
							? "pending"
							: data?.status
					}`}
				>
					{data?.status}
				</td>
			) : null}

			{checkedColums.includes("date_from") ? <td>{data.date_from}</td> : null}

			{checkedColums.includes("nights") ? <td>{data.nights}</td> : null}

			{checkedColums.includes("pax") ? <td>{data.pax}</td> : null}

			{checkedColums.includes("selling_rate") ? (
				<td>{data?.total_sell_price}</td>
			) : null}

			{checkedColums.includes("total_refund") ? (
				<td>{data.total_refund}</td>
			) : null}

			{checkedColums.includes("customer_charges") ? (
				<td>{data.customer_charges?.toFixed(2)}</td>
			) : null}

			{checkedColums.includes("net_rate") ? (
				<td>{data.total_net_price}</td>
			) : null}

			{checkedColums.includes("earnings") ? (
				<td>{data.earnings?.toFixed(2)}</td>
			) : null}
			{checkedColums.includes("currency") ? <td>{data.currency}</td> : null}

			{/* {checkedColums.includes("payment_method") ? (
				<td>{data.payment_method ? data.payment_method : "-"}</td>
			) : null} */}

			<td>
				<Link to={`sbs-reservations/${data?.id}/details`}>
					<i class="fas fa-chevron-right"></i>
				</Link>
			</td>
		</tr>
	);
}
