import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import { useContractState } from "context/contractRequest";
import validate from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import CancelModal from "./CancelModal";
import Photos from "./Photos";

const ContractDetails = ({ state }) => {
	const { backOffice } = Locale;
	const [canceled, setCanceled] = useState(false);
	const dispatch = useContractState();
	let history = useHistory();
	const [propertyDetails, setPropertyDetails] = useState({
		contractDurationFrom: undefined,
		contractDurationTo: undefined,
		commissionType: "",
		commission: "",
		type: "",
		spin: false,
	});
	const [disabled, setDisabled] = useState(true);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const [commissionTypeSelected, setCommissionTypeSelected] = useState(null);
	const [typeSelected, setTypeSelected] = useState(null);
	const [currencies, setCurrencies] = useState([]);
	useEffect(() => {
		if (state?.contract?.is_fixed === 1) {
			setCommissionTypeSelected({ label: "Fixed", value: "1" });
		} else {
			setCommissionTypeSelected({ label: "unFixed", value: "2" });
		}

		if (state?.contract?.is_percentage === 1) {
			setTypeSelected({ label: "percentage", value: "percentage" });
		} else {
			setTypeSelected({ label: "fixed", value: "fixed" });
		}
	}, []);

	useEffect(() => {
		setCurrencies(state?.contract?.commissions);
	}, [state]);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "commissionType", value: propertyDetails.commissionType },
				{ required: true }
			),
			...validate(
				{ name: "commission", value: propertyDetails.commission },
				{ required: true }
			),
		});
	};

	const submit = () => {
		setPropertyDetails({
			...propertyDetails,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
		// history.push("../contract-management")
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setPropertyDetails({ ...propertyDetails, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setPropertyDetails({ ...propertyDetails, [name]: value });
		}
	};

	console.log("stateData555", state?.contract?.commissions);
	return (
		<>
			<div className="property-details">
				<CancelModal isOpen={canceled} toggle={setCanceled} />
				<div className="row m-0">
					<div className="col-12 pr-4 p-0">
						<div className="row m-0">
							<div className="col-md-6 pl-0">
								<DatePickerField
									label={backOffice.contractDurationFrom}
									disabled={true}
									date={moment(state?.contract?.duration_from)}
									onChangeDate={(date) =>
										setPropertyDetails({
											...propertyDetails,
											contractDurationFrom: date._d,
										})
									}
									isOutsideRange={(day) => {
										return false;
									}}
								/>
							</div>
							<div className="col-md-6 pr-0">
								<DatePickerField
									label={backOffice.contractDurationTo}
									disabled={true}
									date={moment(state?.contract?.duration_to)}
									onChangeDate={(date) =>
										setPropertyDetails({
											...propertyDetails,
											contractDurationTo: date._d,
										})
									}
									isOutsideRange={(day) => {
										return false;
									}}
								/>
							</div>

							<div className="col-md-6 pl-0">
								<SelectField
									id="commissionType"
									label={backOffice.commissionType}
									// placeholder={"Fixed"}
									disabled
									name="commissionType"
									onChange={(e) => {
										handleChange({ name: "commissionType", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "commissionType", value: e },
												{ required: true }
											),
										});
									}}
									options={[
										{ label: "add", value: "add" },
										{ label: "deduct", value: "deduct" },
									]}
									value={{
										label: state?.contract?.commissions[0]?.apply_type,
										value: state?.contract?.commissions[0]?.apply_type,
									}}
									color={errors.commissionType?.required ? "danger" : ""}
									errors={errors.commissionType}
								/>
							</div>

							<div className="col-md-6 pr-0">
								<div className="text-with-select">
									<SelectField
										label={backOffice.commission}
										placeholder={backOffice.commission}
										value={{
											label: state?.contract?.commission_type,
											value: state?.contract?.commission_type,
										}}
										id="commission-3"
										disabled
										onChange={(e) => {
											handleChange({ name: "type", value: e });
										}}
										options={[
											{ label: "percentage", value: "percent" },
											{ label: "fixed", value: "fixed" },
										]}
									/>
									{state?.contract?.commission_type != "fixed" ? (
										<TextField
											label={backOffice.commission}
											placeholder={backOffice.commission}
											value={state?.contract?.commissions[0]?.value}
											name="commission"
											id="commission-2"
											disabled
											onChange={(e) => {
												handleChange(e);
											}}
										/>
									) : null}
								</div>
							</div>
						</div>

						{state?.contract?.commission_type == "fixed" &&
							currencies?.length > 0 &&
							currencies.map((item, index) => {
								return (
									<div className="row">
										<div className="col-md-6 ">
											<SelectField
												disabled
												label={backOffice.commission}
												placeholder={backOffice.commission}
												value={{
													label: item.currency,
													value: index + 1,
												}}
												name="commissionCurrency"
												id="commissionCurrency"
											/>
										</div>
										<div className="col-md-6">
											<TextField
												disabled
												label={backOffice.commission}
												placeholder={backOffice.commission}
												value={item?.value}
											/>
										</div>
									</div>
								);
							})}
						{state?.file?.length > 0 ? (
							<Photos
								disabled={disabled}
								setDisabled={setDisabled}
								Files={state?.file}
							/>
						) : null}
						<div className="row flex-row-reverse px-2">
							<div className="col-md-2">
								<button
									className="btn btn-secondary w-100"
									onClick={() => history.push("/contract-mangment?page=1")}
								>
									{backOffice.back}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContractDetails;
