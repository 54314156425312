import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import TextField from "components/shared/TextField";
import { getNotification } from "helpers/makeNotifications";
import validate, { isFormValid } from "helpers/validate";
import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { uploadFile } from "services/companies";
import { AddTransaction } from "services/financial";
import Locale from "translations";

export default function AddBalancePopup({
	isOpen,
	popupInfo,
	setpopupInfo,
	toggle,
	setReload,
}) {
	const { wallet } = Locale;
	const [errors, setErrors] = useState({});
	let checkFormKeys = {
		amount: popupInfo.amount,
		description: popupInfo.description,
		logo: popupInfo.logo,
	};
	console.log("popupInfopopupInfo1", popupInfo);
	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(checkFormKeys).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value: checkFormKeys[key],
					},
					{ required: true }
				),
			};
		});
		setErrors(() => SubmitError);
		return isFormValid(SubmitError);
	};

	const addBalance = async () => {
		let formData = new FormData();
		formData.append("file", popupInfo.logo);
		let ImgSelected = "";
		if (popupInfo.logo) {
			ImgSelected = await uploadFile(formData);
		}
		if (checkFormErrors() && ImgSelected?.status == 200) {
			let data = {
				transaction_type: "add",
				amount: popupInfo.amount,
				payment_card_id: popupInfo?.id,
				company_id: popupInfo?.company_id,
				description: popupInfo.description,
				attachment: ImgSelected.data.uuid,
			};
			//Call Api
			let res = await AddTransaction(data);
			if (res?.status === 200) {
				setReload((prev) => !prev);
				getNotification(res?.data?.message);
				toggle();
			}
		}
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-2 ">
				<p className="text-secondary font-size-16 fw-bold mb-0">
					Add Transaction
				</p>
				<i
					className="fas fa-times bg-light-gray text-white p-1 pointer"
					onClick={toggle}
				></i>
			</div>
			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<div className=" m-0 p-0 w-75">
					<div className="d-flex">
						<div className=" mx-2 w-50">
							<div className="d-flex flex-column bg-light-gray px-3 py-3">
								<span>Company</span>
								<span className="fw-bold">{popupInfo.company}</span>
							</div>
						</div>
						<div className="mx-2 w-50">
							<div className="d-flex flex-column bg-light-gray px-3 py-3">
								<span>Currency</span>
								<span className="fw-bold">
									{popupInfo.currency ? popupInfo.currency : "-"}
								</span>
							</div>
						</div>
					</div>

					<div>
						<TextField
							type="number"
							placeholder={wallet.Amount}
							hasLabel={true}
							label={wallet.amount}
							name="amount"
							value={popupInfo.amount}
							onChange={(e) => {
								setpopupInfo({ ...popupInfo, amount: e.target.value });
								setErrors({
									...errors,
									...validate(
										{ name: "amount", value: e.target.value },
										{
											required: true,
										}
									),
								});
							}}
							errors={errors?.amount}
							color={errors?.amount?.required ? "danger" : ""}
						/>
					</div>
					<div>
						<TextAreaField
							label={"Description"}
							customLabel={"Description"}
							placeholder={"Description..."}
							value={popupInfo.description}
							rows="3"
							className="control-field__textarea_input"
							name="description"
							onChange={(e) => {
								setpopupInfo({ ...popupInfo, description: e.target.value });
								setErrors({
									...errors,
									...validate(
										{ name: "description", value: e.target.value },
										{
											required: true,
										}
									),
								});
							}}
							errors={errors?.description}
							color={errors?.description?.required ? "danger" : ""}
						/>
					</div>
					<div
						className={`mx-2 our-border-dashed ${
							errors?.logo?.required ? "border border-danger" : ""
						}`}
					>
						<h6 className="p-2 span">Attachment</h6>
						<div className="py-3  text-center d-flex flex-column justify-content-center">
							{popupInfo.displayImg ? (
								<figure className="d-flex  justify-content-center ">
									{popupInfo?.logo?.type !== "application/pdf" ? (
										<img
											src={`${popupInfo.displayImg}`}
											className="col-6"
											alt="attachment"
										/>
									) : (
										popupInfo?.logo?.name
									)}
								</figure>
							) : null}
							<button className="file-btn shape-border w-50 m-auto pointer">
								{"Add Attachment"}
								<input
									type="file"
									className="custom-file"
									accept="image/png, image/gif, image/jpeg,application/pdf"
									onChange={(e) => {
										setpopupInfo({
											...popupInfo,
											displayImg: URL.createObjectURL(e.target.files[0]),
											logo: e.target.files[0],
										});
										setErrors({
											...errors,
											...validate(
												{ name: "logo", value: e.target.value },
												{
													required: true,
												}
											),
										});
									}}
								/>
							</button>
						</div>
					</div>
				</div>
			</ModalBody>
			<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
				<button
					className="btn rounded companies-btn  w-75"
					onClick={addBalance}
					disabled={popupInfo.amount?.length > 9}
				>
					{wallet.addBalance}
				</button>
			</div>
		</Modal>
	);
}
