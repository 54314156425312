import axios from "axios";
import { cleanEmpty } from "services/general";
const URL = process.env.REACT_APP_API_URL + "/api";

export const getOnlineTransaction = async (filter) => {
	console.log("reset");
	return await axios
		.get(`${URL}/online-payment/list`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPaymentCards = async (filter) => {
	return await axios
		.get(`${URL}/payment-cards`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const AddTransaction = async (data) => {
	return await axios
		.post(`${URL}/payment-transactions/debit`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const getListTransactions = async (filter) => {
	return await axios
		.get(`${URL}/payment-transactions`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportOnlineTransactionToExcel = async (params) => {
	return await axios
		.get(`${URL}/online-payment/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportCompanyWalletToExcel = async (params) => {
	return await axios
		.get(`${URL}/payment-cards/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportTransactionWalletoExcel = async (params) => {
	return await axios
		.get(`${URL}/payment-transactions/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
