import Locale from 'translations';
import CustomModal from '../Modal';

export default function RejectVisaModal({ isOpen, toggle, reason, handleReasonChange, submitReason }) {
  const { backOffice, translate_companies } = Locale;
  return (
    <CustomModal
      modalIsOpen={isOpen}
      header={'Reject Visa'}
      centered={true}
      toggle={toggle}
    >
      <div>
        <p className="m-0 font-weight-bold">{backOffice.provideRejectReason}</p>

        <textarea
          placeholder={`${backOffice.reasonForRejection}...`}
          name="reject-reason"
          id="reject-reason"
          rows="5"
          className="w-100 control-field__input h-auto"
          value={reason}
          onChange={(e) => handleReasonChange(e.target.value)}
        />

        <button
          className="reject-btn w-100"
          onClick={() => submitReason()}
          disabled={reason.length < 3}
        >
          {translate_companies.reject}
        </button>
      </div>
    </CustomModal>
  )
}
