import CheckBox from "components/chekbox";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ModuleRow from "./ModuleRow";

export default function ModuleList({
	subscriptionData,
	selectedPlan,
	setSelectedPlan,
	handleCheck,
	handleCheckAll,
	handlePaidType,
	handleCheckAllModules,
	setSubscription,
	isPackage,
}) {
	const { plan_id } = useParams();
	const modules = subscriptionData?.modules;
	const isActiveStatus = subscriptionData?.status === "active" && plan_id;

	const allModulesRow =
		modules?.length > 0 &&
		modules.map((module, index) => (
			<ModuleRow
				moduleData={module}
				key={module.id}
				selectedPlan={selectedPlan}
				setSelectedPlan={setSelectedPlan}
				handleCheck={handleCheck}
				handleCheckAll={handleCheckAll}
				handlePaidType={handlePaidType}
				currency={subscriptionData.currency}
				isActiveStatus={isActiveStatus}
				isPackage={isPackage}
			/>
		));
	return (
		<div className="module-list">
			<div className="module-list-header">
				<div className="row mx-0">
					<div className="col-md-10 module-list">
						<div className="d-flex mt-1" style={{ gap: "8px" }}>
							<CheckBox
								type="Checkbox"
								name={"All Modules"}
								id={"allModules"}
								onChange={(e) => {
									handleCheckAllModules(e.target.checked);
								}}
								disabled={isActiveStatus || isPackage}
							/>
							<p className="ml-2">Module List</p>
						</div>
					</div>
					<div className="col-md-1 modul-column">
						<p>Free</p>
					</div>
					<div className="col-md-1 modul-column">
						<p>Paid</p>
					</div>
					{/* <div className="col-md-3 modul-column">
						<p>Service Price</p>
					</div> */}
				</div>
			</div>
			<div className="module-list-body">
				{/** loop for all module */}
				{allModulesRow}
			</div>
		</div>
	);
}
