import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function AllVisasFilter({
	visaTypesLookup,
	sourcesLookup,
	agencyLookup,
	filters,
	handleFiltersChanges,
	resetAllFilters,
	countriesLookup,
	statusLookup,
	exportToFileFn,
}) {
	const { backOffice, booking, requests } = Locale;

	return (
		<div className="main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				{/* passport number input */}
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.passport}
						label={backOffice.passport}
						id="passport_number"
						name="passport_number"
						value={filters.passport_number}
						onChange={(e) =>
							handleFiltersChanges(e.target.value, "passport_number")
						}
					/>
				</div>
				{/* country input */}
				<div className="col">
					<SelectField
						placeholder={backOffice.country}
						label={backOffice.country}
						id="country"
						name="country"
						options={countriesLookup}
						value={filters.country}
						onChange={(e) => handleFiltersChanges(e, "country")}
					/>
				</div>
				{/* visa type input */}
				<div className="col">
					<SelectField
						placeholder={booking.VisaType}
						label={booking.VisaType}
						id="visa_type"
						name="visa_type"
						options={visaTypesLookup}
						value={filters.visa_type}
						onChange={(e) => handleFiltersChanges(e, "visa_type")}
					/>
				</div>
				{/* date input */}
				<div className="col">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={requests.date}
						id="date"
						name="date"
						onOutsideClick={false}
						date={filters?.date}
						onChangeDate={(e) => handleFiltersChanges(e, "date")}
					/>
				</div>
				{/* source input */}
				<div className="col">
					<SelectField
						placeholder={backOffice.select}
						label={backOffice.source}
						id="source"
						name="source"
						options={sourcesLookup}
						value={filters.source}
						onChange={(e) => handleFiltersChanges(e, "source")}
					/>
				</div>

				{/* agency_name input */}
				<div className="col">
					<SelectField
						placeholder={"Agency Name"}
						label={"Agency Name"}
						name="agency_name"
						options={agencyLookup}
						value={filters.agency_name}
						onChange={(e) => handleFiltersChanges(e, "agency_name")}
					/>
				</div>
				{/** Status input */}
				<div className="col">
					<SelectField
						placeholder={"Status"}
						label={"Status"}
						name="status"
						options={statusLookup}
						value={filters.status}
						onChange={(e) => handleFiltersChanges(e, "status")}
					/>
				</div>
				{/* reset filters button */}
				<div className="col">
					<button
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={resetAllFilters}
					>
						<img src={resetIcon} alt="reset icon" />
						<span className="text-caption">{backOffice.resetFilter}</span>
					</button>
				</div>
				<div className="col">
					<button className="btn btn-primary mt-4" onClick={exportToFileFn}>
						Export To Excel
					</button>
				</div>
			</div>
		</div>
	);
}
