import ShowForPermission from 'helpers/showForPermission';
import { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { deletePromo, fetchAllPromos, togglePromoPublish } from 'services/promo';
import PromoFilter from './PromoFilter';
import PromoTable from './PromoTable';


export default function Promo() {
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [promosList, setPromosList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
  });
  const [meta, setMeta] = useState();
  const [deleteConfiramtionModal, setDeleteConfiramtionModal] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null)
  function toggleConfiramationModal() {
    setDeleteConfiramtionModal(!deleteConfiramtionModal)
  }

  async function fetchPromosList() {
    const promosRes = await fetchAllPromos(filters);
    if (promosRes.status === 200) {
      setPromosList(promosRes?.data?.data);
      setMeta(promosRes?.data?.meta);
    }
  }

  function goTo(page) {
    setFilters((prev) => ({ ...prev, page: page }));
  };


  useEffect(() => {
    fetchPromosList();
  }, [filters, reload])

  async function changePublishStatus(id) {
    const res = await togglePromoPublish(id);
    if (res.status === 200) {
      store.addNotification({
        title: "Change Status",
        message: res?.data?.message ? res?.data?.message : "Changed Status Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 1000,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => setReload((prev) => !prev)
      });
    }
  }

  async function deletePromoCity(id) {
    const deleteRes = await deletePromo(id);
    if (deleteRes.status === 200) {
      store.addNotification({
        title: "Change Status",
        message: deleteRes?.data?.message ? deleteRes?.data?.message : "Deleted Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 1000,
          onScreen: true,
          pauseOnHover: true,
        }
      });
      setReload((prev) => !prev);
      toggleConfiramationModal();
      setSelectedPromo(null);
    }
  }


  return (
    <div>
      <div className="d-flex justify-content-between">
        <h4>Promo</h4>
        <ShowForPermission permission="Promo-Manage">
          <button
            className="btn btn-info px-4"
            onClick={() => history.push('/promo/add')}
          >
            Add City
          </button>
        </ShowForPermission>
      </div>

      <PromoFilter
        filters={filters}
        setFilters={setFilters}
      />

      <PromoTable
        promosList={promosList}
        changePublishStatus={changePublishStatus}
        toggleConfiramationModal={toggleConfiramationModal}
        setSelectedPromo={setSelectedPromo}
        meta={meta}
        goTo={goTo}
      />

      {/* delete modal confiramtion */}
      {deleteConfiramtionModal && selectedPromo ?
        <Modal isOpen={deleteConfiramtionModal && selectedPromo} centered>
          <ModalHeader toggle={toggleConfiramationModal} title='Delete Promo'>
            Delete Promo
          </ModalHeader>
          <ModalBody>
            <p className=''>
              {`Are you sure you want delete promo city `}
              <span className='font-weight-bold '>{`"${selectedPromo?.city_name}" ?`}</span>
            </p>
          </ModalBody>
          <ModalFooter>
            <div>
              <button className='btn btn-danger mx-2 px-4' onClick={() => deletePromoCity(selectedPromo?.id)}>
                Yes
              </button>
              <button className='btn btn-secondary px-4' onClick={toggleConfiramationModal}>
                No
              </button>
            </div>
          </ModalFooter>
        </Modal>
        : null
      }
    </div>
  )
}
