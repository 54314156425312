import { Box, Card, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import moment from "moment";
import imagePlaceHolder from "../../../../../assets/images/image_placeholder.png";

import { CheckInIcon, CheckOutIcon, HotelsIcon, LocationIcon } from "../Icons";
import RoomDetails from "./share/RoomDetails";

function HotelDetails({ details, madinah }) {
	return (
		<Card style={{ ...(madinah && { marginTop: 24 }) }}>
			<div className="w-100 bg-light-gray d-flex py-2 px-3 align-items-center svg_blue">
				<HotelsIcon />
				<Box
					component="h6"
					style={{
						color: "#0f3e5e",
						fontWeight: "bold",
						marginBottom: 0,
						marginInlineStart: 16,
					}}
				>
					{madinah ? "Madinah Hotel" : "Makkah Hotel"}
				</Box>
			</div>
			<div className={`d-flex w-100 py-2 px-3`}>
				<div className={`d-flex flex-column`}>
					<img
						className="rounded-start"
						src={details?.data?.bannerImagePath || imagePlaceHolder}
						alt={
							`${details?.data?.address}, ${details?.data?.city}, ${details?.data?.state}, ${details?.data?.country}` ||
							""
						}
						width={135}
						height={99}
					/>
				</div>
				<Box style={{ marginLeft: "1rem" }}>
					<Typography variant="h6" style={{ fontWeight: "bold" }}>
						{details?.name}
					</Typography>
					<Typography variant="body2">
						<LocationIcon className="me-1" />
						{`${details?.data?.address}, ${details?.data?.city}, ${details?.data?.state}, ${details?.data?.country}`}
					</Typography>
					<Rating
						style={{ fontSize: "1rem" }}
						name="read-only"
						value={details?.data?.rating}
						readOnly
					/>
					<Typography variant="subtitle2">
						<CheckInIcon className="me-1" />
						Check In:
						{moment(details?.data?.checkInDate).format("YYYY-MM-DD")}
					</Typography>
					<Typography variant="subtitle2">
						<CheckOutIcon className="me-1" />
						Check Out:
						{moment(details?.data?.checkOutDate).format("YYYY-MM-DD")}
					</Typography>
				</Box>
			</div>
			<div className="w-100 border-top  px-3 ">
				<Box
					component="h6"
					className="b2c_secondary_color"
					style={{
						fontWeight: "bold",
						marginTop: 8,
					}}
				>
					Selected Rooms
				</Box>
				{details?.data?.roomGroups && details?.data?.roomGroups?.length > 0
					? details?.data?.roomGroups?.map((group, index) => {
							return (
								<RoomDetails
									key={index}
									room={group?.rooms[0]}
									viewSelcetedRoom={true}
								/>
							);
					  })
					: []}
			</div>
		</Card>
	);
}

export default HotelDetails;
