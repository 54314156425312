import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getReservationsFailed } from "services/multiWallets";
import Locale from "translations";
function ListPayFiled() {
	const { backOffice } = Locale;
	const [reservations, setReservations] = useState([]);

	useEffect(() => {
		const getWallet = async () => {
			const res = await getReservationsFailed();
			if (res?.status === 200) {
				setReservations(res.data.data);
			}
		};
		getWallet();
	}, []);
	return (
		<div className="col-12">
			<h3>Pay later reservations failed</h3>
			<div className=" table-responsive our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th width="7%">{backOffice.ref}</th>
							<th>{backOffice.agency}</th>
							<th style={{ width: "200px" }}>{backOffice.hotelName}</th>
							<th>{backOffice.destination}</th>

							<th>{backOffice.requestDate}</th>
						</tr>
					</thead>
					<tbody>
						{reservations && reservations.length > 0 ? (
							reservations.map((item) => {
								return (
									<tr>
										<td>{item.reservation_num}</td>
										<td>{item.agency}</td>
										<td className="dashboard-pay-hotel-name">
											{item.hotel_name}
										</td>
										<td>{item.destination}</td>
										<td>{item.created_at}</td>
										<td>
											<ShowForPermission permission="Bookings-View">
												<Link
													to={{
														pathname: `/booking/view/${item.id}/bookings`,
														state: {
															BookingInfo: item,
														},
													}}
												>
													<i class="fas fa-angle-right"></i>
												</Link>
											</ShowForPermission>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-build__product-no-data fullHeight">
										<i class="far fa-envelope fs-1"></i>
										<h4>{backOffice.Noreservationsfailedfound}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default ListPayFiled;
