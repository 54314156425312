export function MoreIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M12.833 13C13.3853 13 13.833 12.5523 13.833 12C13.833 11.4477 13.3853 11 12.833 11C12.2807 11 11.833 11.4477 11.833 12C11.833 12.5523 12.2807 13 12.833 13Z"
				fill="#9C9FAB"
				stroke="#9C9FAB"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.833 6C13.3853 6 13.833 5.55228 13.833 5C13.833 4.44772 13.3853 4 12.833 4C12.2807 4 11.833 4.44772 11.833 5C11.833 5.55228 12.2807 6 12.833 6Z"
				fill="#9C9FAB"
				stroke="#9C9FAB"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.833 20C13.3853 20 13.833 19.5523 13.833 19C13.833 18.4477 13.3853 18 12.833 18C12.2807 18 11.833 18.4477 11.833 19C11.833 19.5523 12.2807 20 12.833 20Z"
				fill="#9C9FAB"
				stroke="#9C9FAB"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
