import { useContractDispatch, useContractState } from "context/contractRequest";
import { useGlobalState } from "context/global";
import ContractDetails from "modules/backOffice/Components/ContractRequest/Details/ContractDetails/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { viewRequestContract } from "services/property";
import Locale from "translations";
import ManagersDetails from "./ManagerDetails/index";
import OwnersDetails from "./OwnersDetails/index";
import PropertyDetails from "./PropertyDetails/index";
export default function Details() {
	const { backOffice } = Locale;
	const { allCountries, locale } = useGlobalState();
	const { step, current } = useContractState();
	const dispatchContract = useContractDispatch();
	const [activeTab, setActiveTab] = useState(step);
	const [state, setstate] = useState({});
	const [stateCurrency, setStateCurrency] = useState({});
	const [countries, setCountries] = useState([]);

	const { id } = useParams();

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
			dispatchContract({ type: "progress", current: tab, step: step });
		}
	};

	const fetchData = async (id) => {
		const res = await viewRequestContract(id);

		if (res.status === 200) {
			// dispatch({ type: "ContractDetails", payload: res.data });
			setstate(res.data);
			setStateCurrency(res?.data);
		}
	};

	useEffect(() => {
		if (id !== undefined) {
			fetchData(id);
			dispatchContract({
				type: "progress",
				progress: 0,
				step: "2",
				current: "2",
			});
		} else {
			dispatchContract({
				type: "progress",
				progress: 0,
				step: "2",
				current: "2",
			});
		}
	}, [id]);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (current) {
			setActiveTab(current);
		} else {
			setActiveTab(step);
		}
	}, [current, step]);

	//Fetch Countries
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	return (
		<div className="property contract-details">
			<div className=" text-black mb-3">
				<div className="">
					<div className="row m-0">
						<h1>{state.property_details?.classification_name}</h1>
					</div>
				</div>
			</div>
			<div className="">
				<div className="text-black">
					<div className="d-flex route-block">
						<Nav tabs>
							{/* <NavItem>
                <NavLink
                  className={`${activeTab === "1" ? "is-active" : ""} ${
                    +step > 1 ? "done" : ""
                  }`}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {backOffice.propertyDetails}
                </NavLink>
              </NavItem> */}

							<NavItem>
								<NavLink
									className={`${activeTab === "2" ? "is-active" : ""}  ${
										+step > 2 ? "done " : ""
									} `}
									onClick={() => {
										toggle("2");
									}}
								>
									{backOffice.ownerDetails}
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={`${activeTab === "3" ? "is-active" : ""}  ${
										+step > 3 ? "done " : ""
									} `}
									onClick={() => {
										toggle("3");
									}}
								>
									{backOffice.managerDetails}
								</NavLink>
							</NavItem>

							{state?.property_details?.status !== "Rejected" ? (
								<NavItem>
									<NavLink
										className={`${activeTab === "4" ? "is-active" : ""} ${
											+step > 4 ? "done " : ""
										}`}
										onClick={() => {
											toggle("4");
										}}
									>
										{backOffice.contractDetails}
									</NavLink>
								</NavItem>
							) : null}
						</Nav>
					</div>
				</div>
			</div>
			<div className="mt-3">
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<PropertyDetails
							SetActiveTab={setActiveTab}
							state={state}
							countries={countries}
						/>
					</TabPane>
					<TabPane tabId="2">
						<OwnersDetails
							SetActiveTab={setActiveTab}
							state={state}
							countries={countries}
						/>
					</TabPane>
					<TabPane tabId="3">
						<ManagersDetails
							SetActiveTab={setActiveTab}
							state={state}
							countries={countries}
						/>
					</TabPane>
					{state?.property_details?.status !== "Rejected" ? (
						<TabPane tabId="4">
							<ContractDetails state={state} stateCurrency={stateCurrency} />
						</TabPane>
					) : null}
				</TabContent>
			</div>
		</div>
	);
}
