import Pagination from "components/shared/Pagination";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function ReservationsTable({
	reservations,
	setReservations,
	goTo,
	metaData,
}) {
	const { backOffice, booking, wallet } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	const noResult = (
		<tr>
			<td colSpan="11">
				<div className="product-no-data">
					<i className="fas fa-info-circle fa-lg"></i>{" "}
					<h4>{backOffice.noResult}</h4>
				</div>
			</td>
		</tr>
	);
	return (
		<div className="our-border  px-4">
			<table className="custom-table reservation-table back-office-table table table-striped ">
				<thead>
					<tr>
						<th>{booking.Ref}</th>
						<th>{backOffice.agency}</th>
						<th>{backOffice.hotelName}</th>
						<th>{booking.Destination}</th>
						<th>{backOffice.NoOfRooms}</th>
						<th>
							{backOffice.checkIn} {}
						</th>
						<th>{backOffice.nights}</th>
						<th>{wallet.requestDate}</th>
						<th>Price</th>
						<th>Currency</th>
						<th>{backOffice.status}</th>
						{/* <th>{backOffice.tools}</th> */}
					</tr>
				</thead>
				<tbody className="text-capitalize">
					{reservations?.length > 0
						? reservations?.map((reservation) => {
								return (
									<tr key={reservation?.id}>
										<td>{reservation?.id}</td>
										<td>{reservation?.company?.name[locale]}</td>
										<td>{reservation?.hotel_name}</td>
										<td>{reservation?.destination?.name}</td>
										<td>{reservation?.rooms_count}</td>
										<td>
											{moment(reservation?.date_from).format("DD-MM-YYYY")}
										</td>
										<td>{reservation?.nights}</td>
										<td>
											{moment(reservation?.created_at).format("DD-MM-YYYY")}
										</td>
										<td>{reservation?.total_price}</td>
										<td>{reservation?.currency}</td>

										<td
											className={`text-${
												reservation?.status === "pending" ||
												reservation?.status === "new request" ||
												reservation?.status === "tentative"
													? "pending"
													: reservation?.status
											}`}
										>
											{reservation?.status}
										</td>
										<td>
											<Link
												to={`sudan-reservations/${reservation?.id}/details`}
											>
												<i class="fas fa-chevron-right"></i>
											</Link>
										</td>
									</tr>
								);
						  })
						: noResult}
				</tbody>
			</table>
			{reservations?.length > 0 ? (
				<Pagination info={metaData} goTo={goTo} />
			) : null}
		</div>
	);
}
