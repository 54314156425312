export function IncludedServiceIcon() {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_4636_25994)">
				<rect x="4" y="2" width="18" height="18" rx="9" fill="white" />
				<rect
					x="6.5"
					y="4.5"
					width="13"
					height="13"
					rx="6.5"
					stroke="#1E85FF"
					stroke-width="5"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_4636_25994"
					x="0"
					y="0"
					width="26"
					height="26"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.647059 0 0 0 0 0.639216 0 0 0 0 0.682353 0 0 0 0.3 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_4636_25994"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_4636_25994"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
}
