import Pagination from "components/shared/Pagination";
import Locale from "translations";

export default function SBSReservationsTable({
	reservations,
	tableHeaders,
	goTo,
	metaData,
}) {
	const { backOffice } = Locale;

	const noResult = (
		<tr>
			<td colSpan="11">
				<div className="product-no-data">
					<i className="fas fa-info-circle fa-lg"></i>{" "}
					<h4>{backOffice.noResult}</h4>
				</div>
			</td>
		</tr>
	);
	return (
		<div className="table-responsive  our-border  px-4">
			<table className="custom-table table-responsive back-office-table table table-striped">
				<thead>
					<tr className="text-nowrap">{tableHeaders}</tr>
				</thead>

				<tbody>
					{reservations.length > 0 ?
						reservations
						:
						noResult
					}
				</tbody>

			</table>
			{reservations?.length > 0 ? (
				<Pagination info={metaData} goTo={goTo} />
			) : null}
		</div>
	);
}
