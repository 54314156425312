import imagePlaceHolder from "../../../../../assets/images/image_placeholder.png";
export default function Reservation({ reservation }) {
	const statusStyle = () => {
		switch (reservation?.reservation_status) {
			case "pending":
				return "tentative";
			case "paid":
				return "text-definite";
			default:
				return "text-danger";
		}
	};
	return (
		<tr>
			<td>{reservation?.reference_id}</td>
			<td>{reservation?.trip_type?.name}</td>
			<td>
				<img
					className="rounded-circle"
					alt=""
					src={
						reservation?.airline?.logo
							? reservation?.airline?.logo
							: imagePlaceHolder
					}
					height={"25"}
					width={"25"}
				/>

				{reservation?.airline?.name}
			</td>
			<td>{reservation?.pax}</td>
			<td>
				{+reservation?.paid_amount} {reservation?.currency}
			</td>
			<td>{reservation?.departure_date}</td>
			<td>{reservation?.night_count}</td>
			<td className={statusStyle()}>{reservation.reservation_status}</td>
			{/* <td>
				<Link
					to={{
						pathname: `/b2c-user-booking-details/${reservation.id}`,
						state: {
							traveller_name: reservation.traveller_name,
						},
					}}
				>
					<i class="fas fa-chevron-right" />
				</Link>
			</td> */}
		</tr>
	);
}
