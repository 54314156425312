import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	fetchAvailableSubscriptionPackages,
	saveSubscriptionPlan,
	UpdateSubscripionPlan,
	UpdateSubscripionPlanStatus,
	ViewSubscripionConfigurationsPlan,
} from "services/subscriptionPlan";
import ChangeStatus from "../Models/changeStatus";
import ApplyFree from "./ApplyFree";
import ModuleList from "./ModuleList";

const formatData = (plans) => {
	return plans.map((plan) => ({
		...plan,
		isSelected: false,
	}));
};

export default function AddEditSubscriptionPlan() {
	const { company_id, plan_id } = useParams();
	const history = useHistory();

	const [subscriptionState, setSubscriptionState] = useState([]);
	const [freeTrialDates, setFreeTrialDates] = useState({
		start_date_free: null,
		end_date_free: null,
	});
	const [paymentStatus, setPaymentStatus] = useState("free");
	const [isOpenStatus, setIsOpenStatus] = useState(false);
	const [Status, setStatus] = useState({
		status: "canceled",
		refundAmount: null,
	});

	const addSupscriptionPlan = async () => {
		const data = {
			company_id,
			packages: subscriptionState
				.filter(({ isSelected }) => isSelected)
				.map((moduleData) => moduleData.id),
			start_date: freeTrialDates?.start_date_free.format("YYYY-MM-DD"),
			end_date: freeTrialDates?.end_date_free.format("YYYY-MM-DD"),
		};

		const res = await saveSubscriptionPlan(data);

		if (res?.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Plan has been added successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			history?.goBack();
		}
	};

	const updateSupscriptionPlan = async () => {
		const data = {
			company_id,
			packages: subscriptionState
				.filter(({ isSelected }) => isSelected)
				.map((moduleData) => moduleData.id),
			start_date: freeTrialDates?.start_date_free.format("YYYY-MM-DD"),
			end_date: freeTrialDates?.end_date_free.format("YYYY-MM-DD"),
		};

		const res = await UpdateSubscripionPlan(data, plan_id);

		if (res?.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Plan has been updated successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			history?.goBack();
		}
	};

	const cancelPlan = async () => {
		const data = {
			status: "canceled",
			refundAmount: paymentStatus === "free" ? 0 : Status.refundAmount,
		};

		const res = await UpdateSubscripionPlanStatus(data, plan_id);

		if (res?.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Plan has been canceled successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			history?.goBack();
		}
	};

	const handleModuleSelection = (moduleId, isSelected) => {
		const updatedModules = subscriptionState?.map((moduleData) => {
			if (moduleData.id === moduleId) {
				return {
					...moduleData,
					isSelected,
				};
			}
			return moduleData;
		});
		setSubscriptionState(updatedModules);
	};

	const isApplyFreeDisabled = () => {
		if (plan_id) {
			return (
				freeTrialDates?.start_date_free === null ||
				freeTrialDates?.end_date_free === null
			);
		} else {
			return (
				!subscriptionState.some((module) => module.isSelected) ||
				freeTrialDates?.start_date_free === null ||
				freeTrialDates?.end_date_free === null
			);
		}
	};

	const calculateTotal = () => {
		return subscriptionState
			.filter(({ isSelected }) => isSelected)
			.reduce((acc, { price, discount_type, discount_value }) => {
				if (discount_type === "percentage") {
					return acc + price - (price * discount_value) / 100;
				} else {
					return acc + price - discount_value;
				}
			}, 0);
	};

	const toggleStatus = () => {
		setIsOpenStatus(!isOpenStatus);
	};

	useEffect(() => {
		async function viewConfigurations() {
			const res = await fetchAvailableSubscriptionPackages();
			const formated = formatData(res?.data?.data);

			if (plan_id) {
				const res = await ViewSubscripionConfigurationsPlan(plan_id);
				const selectedIDs = res?.data?.subscribed;

				const updated = formated.map((moduleData) => {
					if (selectedIDs.includes(moduleData.id)) {
						return {
							...moduleData,
							isSelected: true,
						};
					}
					return moduleData;
				});

				setSubscriptionState(updated);
				setFreeTrialDates({
					start_date_free: moment(res?.data?.start_date),
					end_date_free: moment(res?.data?.end_date),
				});
				setPaymentStatus(res?.data?.payment_status);
			} else {
				setSubscriptionState(formated);
			}
		}

		viewConfigurations();
	}, [plan_id]);

	return (
		<>
			<div className="subscription-plan add-edit-plan">
				<div className="subscription-header">
					<h4>Available Bundles</h4>
				</div>
				<ModuleList
					subscriptionData={subscriptionState}
					handleModuleSelection={handleModuleSelection}
				/>

				<div className="row py-3" style={{ backgroundColor: "#F8F9FA" }}>
					<div className="col-6 col-md-9" />
					<div className="ms-auto col-6 col-md-3 ">
						<div
							className="p-3 text-center fs-5"
							style={{
								backgroundColor: "#EDEFF1",
								borderRadius: "4px",
								fontWeight: "bold",
							}}
						>
							Total: {calculateTotal()} {}
						</div>
					</div>
				</div>
				{paymentStatus !== "free" ? null : (
					<ApplyFree
						freeTrialDates={freeTrialDates}
						setFreeTrialDates={setFreeTrialDates}
						addPlanFn={plan_id ? updateSupscriptionPlan : addSupscriptionPlan}
						disabled={isApplyFreeDisabled()}
					/>
				)}
			</div>

			<div className="btn-action-subsc-plan">
				{plan_id && (
					<button
						className="btn btn-cancel"
						onClick={() => setIsOpenStatus(true)}
					>
						<span className="me-2">X</span>
						<span>
							{paymentStatus !== "free" ? "Cancel Plan" : "Cancel Free Trial"}
						</span>
					</button>
				)}
			</div>

			<ChangeStatus
				isOpen={isOpenStatus}
				toggle={toggleStatus}
				Status={Status}
				setStatus={setStatus}
				ChangeStatus={cancelPlan}
				isStatusFree={paymentStatus === "free" ? true : false}
			/>
		</>
	);
}
