import moment from "moment";
import { store } from "react-notifications-component";
import CommercialDistAirLineReservation from '../AirLineReservation';
import CommercialDistHotelReservation from '../HotelReservation';
import CommercialDistTourReservation from '../TourReservation';
import CommercialDistTransferReservation from '../TransferReservation';
// import CommercialDistHotelReservation from "../HotelReservation";
// import CommercialDistTourReservation from "../TourReservation";
// import CommercialDistTransferReservation from "../TransferReservation";

export function formatCommercialDist(res) {
	const resData = res?.data?.data;
	const formatData = {
		details: {
			name: resData?.name,
			country: {
				...resData?.country_id,
				label: resData?.country_id?.name,
				value: resData?.country_id?.id,
			},
			city: {
				...resData?.city_id,
				label: resData?.city_id?.name,
				value: resData?.city_id?.id,
			},
			currency: {
				...resData?.currency,
				label: resData?.currency?.code,
				value: resData?.country_id?.id,
			},
			date_from: resData?.contract_date_from
				? moment(resData?.contract_date_from)
				: null,
			date_to: resData?.contract_date_to
				? moment(resData?.contract_date_to)
				: null,
			link: resData?.link,
		},
		servicesList: resData?.services,
		editMode: false,
	};
	return formatData;
}

export function generateServicesTabs(servicesList) {
	let servicesTabs = [];
	servicesList?.forEach((service) => {
		switch (service?.name) {
			case "hotels":
				servicesTabs.push({
					id: 4,
					route: "hotel-reservation",
					label: "Hotel Reservation",
					component: <CommercialDistHotelReservation />,
				});
				break;
			case "transfers":
				servicesTabs.push({
					id: 5,
					route: "transfer-reservation",
					label: "Transfer Reservation",
					component: <CommercialDistTransferReservation />,
				});
				break;
			case "flight":
				servicesTabs.push({
					id: 6,
					route: "airlines-reservation",
					label: "Airlines Reservation",
					component: <CommercialDistAirLineReservation />,
				});
				break;
			case "tours":
				servicesTabs.push({
					id: 7,
					route: "tours-reservation",
					label: "Tours Reservation",
					component: <CommercialDistTourReservation />,
				});
				break;
			default:
				break;
		}
	});
	return servicesTabs;
}
export function handleResponseNotification(response, type, title, message) {
	store.addNotification({
		title: title,
		message: response?.data?.message ? response?.data?.message : message,
		type: type,
		insert: "top",
		container: "top-right",
		animationIn: ["animated", "fadeIn"],
		animationOut: ["animated", "fadeOut"],
		dismiss: {
			duration: 3000,
			onScreen: true,
			pauseOnHover: true,
		},
	});
}
