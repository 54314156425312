// import React from "react";
// import Modal from "react-modal";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ConfirmModal(props) {
	const { backOffice, userDetails } = Locale;

	return (
		<>
			<Modal
				isOpen={props.IsOpen}
				toggle={props.toggle}
				centered={props?.centered || false}
			>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 pt-3 w-90 m-auto">
					<h4 className="py-4 text-center">{props.message}</h4>
					<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
						<button
							className={`btn rounded   text-white mx-1 w-25 ${
								props.type == "comfirm" ? "btn-success" : "btn-danger"
							}`}
							onClick={props.onConfirm}
						>
							{props.type == "comfirm" ? (
								backOffice.confirm
							) : (
								<>
									{backOffice.delete}{" "}
									<i class="fas fa-trash text-white mx-2"></i>
								</>
							)}
							{/* {backOffice.delete} <i class="fas fa-trash text-white mx-2"></i> */}
						</button>
						<button
							className="btn btn-dark  text-white rounded  mx-1  w-25"
							onClick={props.toggle}
						>
							{userDetails.cancel}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
