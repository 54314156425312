import TextAreaField from "components/shared/TextAreaField";
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
// import TextAreaField from "components/Form/TextAreaField/TextAreaField";

import { useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { addRemarks, deleteRemark } from "services/sbsReservations";

function Remarks(props) {
	const { details, handleReservationState } = props;
	const { remarks } = details;
	const { id } = useParams();
	const manualReservationsManagePermission = useShowPermission({
		permission: "SBS-Manual-Reservations-Manage",
	});
	const [remarkState, setRemarkState] = useState("");

	const handleRemarksList = (response) => {
		let updatedRemarksState = response.data.data.remarks;

		handleReservationState({ name: "remarks", value: updatedRemarksState });
		setRemarkState("");
	};

	const addNewRemark = async () => {
		const response = await addRemarks(id, {
			content: remarkState,
		});

		if (response.status == 200) {
			handleRemarksList(response);
		}
	};

	const deleteRe = async (remark_id) => {
		const response = await deleteRemark(id, remark_id);
		if (response.status == 200) {
			store.addNotification({
				title: "Change Status",
				message: "Deleted Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			handleRemarksList(response);
		}
	};
	return (
		<div id="reserv-remarks">
			{remarks.map((i) => (
				<div
					key={i.id}
					className="w-100 border p-2 mt-2 remarks_content  d-flex justify-content-between align-items-center"
				>
					<div>
						<p>{`${i.user_name} | ${i.created_at}`}</p>
						<p>{i.content}</p>
					</div>
					<ShowForPermission permission="SBS-Manual-Reservations-Manage">
						<i
							className="fas fa-trash-alt text-danger "
							style={{ cursor: "pointer" }}
							onClick={() => deleteRe(i.id)}
						/>
					</ShowForPermission>
				</div>
			))}

			<TextAreaField
				hasLabel={false}
				placeholder={"Add Remarks"}
				disabled={!manualReservationsManagePermission}
				value={remarkState}
				rows={5}
				name="reasons"
				onChange={(e) => {
					setRemarkState(e.target.value);
				}}
			/>
			<div className="w-100 text-end">
				<ShowForPermission permission="SBS-Manual-Reservations-Manage">
					<button
						className="btn btn-info p-2 my-2 rounded-0"
						onClick={addNewRemark}
						disabled={details?.status?.id == 5}
					>
						Add Remarks
					</button>
				</ShowForPermission>
			</div>
		</div>
	);
}

export default Remarks;
