import { Modal, ModalBody } from "reactstrap";

export default function RemarkModel({ modal, toggleModal, details }) {
	return (
		<>
			<Modal isOpen={modal} toggle={toggleModal} size={"md"} centered={true}>
				<div className=" p-3  border-bottom d-flex justify-content-between">
					<h4 className="mb-0  font-weight-bold">Remark</h4>
					<span
						className="close-modal fas fa-times text-gray"
						onClick={toggleModal}
					></span>
				</div>

				<ModalBody>
					<div className="text-center py-4">
						{details?.notes ? details?.notes : "Not Found Remark"}
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
