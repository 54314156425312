import { useGlobalState } from "context/global";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

function LatestOnlineTransactions({ latestitems }) {
	const { financial, backOffice } = Locale;
	const { permissions, role } = useGlobalState();
	const [isOpen, setIsOpen] = useState(false);
	const [failedMsg, setFailedMsg] = useState("");
	const toggle = (msg) => {
		debugger;
		setFailedMsg(msg);
		setIsOpen((prev) => !prev);
	};
	console.log(isOpen);
	const history = useHistory();

	const statusRoutes = (data) => {
		switch (data?.subject_type) {
			case "hotel":
				return {
					url: `/booking/view/${data?.id}/bookings`,
					hasPermission:
						permissions?.includes("Bookings-View") || role === "Super Admin",
				};
			case "online_visa":
				return {
					url: `/online-visa/view/request/issued/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Visa-Requests-View") ||
						role === "Super Admin",
				};
			case "ground_work":
				return {
					url: `/requests/ground/assigned/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Ground-Service-Requests-View") ||
						role === "Super Admin",
				};
			case "transportation":
				return {
					url: `/requests/transportation/assigned/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Transportation-Requests-View") ||
						role === "Super Admin",
				};
			case "wallet":
				return {
					url: `/wallet-Tarnsactions/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Financial-Companies-Wallets-View") ||
						role === "Super Admin",
				};
			case "hotel_group":
				return {
					url: `/manual-reservations/sbs-reservations/${data?.subject_id}/details`,
					hasPermission:
						permissions?.includes("SBS-Manual-Reservations-View") ||
						role === "Super Admin",
				};
			default:
				return `/online-transactions`;
		}
	};
	return (
		<div className="col-6">
			<h4 className="p-1 pt-5">B2B Online Transactions</h4>
			<div className="our-border  px-4">
				<table className="custom-table reservation-table back-office-table table table-striped ">
					<thead>
						<tr>
							<th width="7%">{financial.company}</th>
							<th>{financial.currency}</th>
							<th>{financial.paymentStatus}</th>
							<th>{financial.provider}</th>
							<th>{financial.total}</th>
							<th>{financial.action}</th>
						</tr>
					</thead>
					<tbody>
						{latestitems?.online_transactions?.length > 0 ? (
							latestitems?.online_transactions?.map((item) => {
								return (
									<tr>
										<td>{item.company_name}</td>
										<td>{item.currency}</td>
										<td
											className={`${
												item?.payment_status === "failed"
													? "text-danger"
													: "text-success"
											}`}
										>
											{item.payment_status}
										</td>
										<td>{item.provider}</td>
										<td>{item?.amount}</td>
										<td className="pointer">
											{statusRoutes(item)?.hasPermission &&
											item?.payment_status !== "failed" ? (
												<div
													onClick={() => history.push(statusRoutes(item)?.url)}
												>
													<i className="fa fa-eye" /> {financial.view}
												</div>
											) : (
												item?.payment_status === "failed" && (
													<button
														className="btn btn-link "
														onClick={() => toggle(item?.failed_message)}
													>
														<i class="fas fa-exclamation-triangle" />
													</button>
												)
											)}
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			<Modal isOpen={isOpen} toggle={() => toggle()} centered={true}>
				<div className="lan d-flex justify-content-between align-items-baseline p-2">
					<p className="text-secondary font-size-16 fw-bold mb-0">
						The reason for the transaction failure
					</p>
					<i
						className="fas fa-times bg-light-gray text-white p-1 pointer"
						onClick={() => toggle()}
					></i>
				</div>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<div className=" m-0 p-0 w-90 py-4">
						<p className="fw-bold text-center">
							{failedMsg ? (
								failedMsg
							) : (
								<span className="text-muted ">
									<i class="fas fa-exclamation-triangle" /> Not Found Reason For
									Now
								</span>
							)}
						</p>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default LatestOnlineTransactions;
