import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import Locale from "translations";

export default function SeasonsTableContent({
	setSeasonList,
	fetchData,
	seasonItem,
	index,
	OpenSeasonsModalHandler,
	confirmIsOpen,
	setConfirmIsOpen,
	toggleConfirm,
	setConfirmData,
}) {
	const { backOffice, confirmation } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	return (
		<>
			<tr key={seasonItem.id}>
				<td scope="row">{seasonItem.name}</td>
				<td>{moment(seasonItem.start_date).format("DD-MM-YYYY")} </td>
				<td>{moment(seasonItem.end_date).format("DD-MM-YYYY")} </td>
				<td>
					<p
						className={
							seasonItem.is_active == "0"
								? "text-warning"
								: seasonItem.is_active == "1"
								? "text-success"
								: "text-danger"
						}
					>
						{seasonItem.is_active == "1"
							? backOffice.active
							: backOffice.inActive}
					</p>
				</td>
				<ShowForPermission permission="Season-Manage">
					<td>
						<a
							href={() => false}
							className="d-inline-block action--btn Button-Pointer"
							onClick={(Model, Seasons, Index) =>
								OpenSeasonsModalHandler("edit", seasonItem, index)
							}
						>
							<i className="fas fa-edit fa-lg"></i> {backOffice.edit}
						</a>

						<a
							href={() => false}
							className="d-inline-block text-danger Button-Pointer"
							onClick={() => {
								setConfirmData(seasonItem);
								toggleConfirm();
							}}
						>
							<i className="fas fa-trash fa-lg"></i> {backOffice.delete}
						</a>
					</td>
				</ShowForPermission>
			</tr>

			{/* {id==seasonItem.id?
      <ConfirmModal IsOpen={confirmIsOpen} toggle={toggleConfirm}
      message={confirmation.confirmDeleteSeason} onConfirm={()=>  removeItem(seasonItem.id)} />
:null} */}
		</>
	);
}
