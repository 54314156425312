import Pagination from "components/shared/Pagination";
import ChangeRequest from "../shared/ChangeRequest";
import RefundRequest from "../shared/RefundRequest";
import VoidRequest from "../shared/VoidRequest ";

export default function ClosedRequests({
	closedRequests,
	meta,
	goTo,
	FetchFilghtRequests,
}) {
	function renderRequestsByType(request) {
		switch (request?.type) {
			case "refund":
				return (
					<RefundRequest
						request={request}
						key={`${request?.id}-${request?.request_num}`}
						isView={true}
					/>
				);
			case "change":
				return (
					<ChangeRequest
						request={request}
						key={`${request?.id}-${request?.request_num}`}
						isView={true}
						fetchData={FetchFilghtRequests}
					/>
				);
			case "void":
				return (
					<VoidRequest
						request={request}
						key={`${request?.id}-${request?.request_num}`}
						isView={true}
					/>
				);
			default:
				return null;
		}
	}
	return (
		<div className="p-md-3 py-2 bg-white">
			{closedRequests.length > 0 ? (
				closedRequests?.map((request) => renderRequestsByType(request))
			) : (
				<div className="w-100 text-center">
					<div className="product-build__product-no-data fullHeight">
						<h4>
							<i className="fas fa-exclamation-triangle" /> Not Request Found
						</h4>
					</div>
				</div>
			)}
			<Pagination info={meta} goTo={goTo} />
		</div>
	);
}
