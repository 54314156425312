import Locale from "translations";

export default function Main() {
	const { backOffice } = Locale;

	return (
		<>
			<h3>{backOffice.Dashboard}</h3>
		</>
	);
}
