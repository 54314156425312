import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory, useParams } from "react-router-dom";
import {
	addNoteTransportation,
	exportToExcel,
	fetchNotesTransportation,
	viewTransportationRequestWaiting,
} from "services/Request";
import Locale from "translations";
import ViewRequestHeader from "./ViewRequestHeader";

export default function ViewRequestTransportation() {
	const { requests, backOffice } = Locale;
	const [searchVal, setSearchVal] = useState(null);
	const [ListOfChecked, setListOfChecked] = useState([]);
	const { status, id } = useParams();
	let history = useHistory();
	const [currentTab, setCurrentTab] = useState(1);
	const { locale } = useGlobalState();
	const [passangers, setPassangers] = useState([]);
	const [notes, setNotes] = useState([1, 2, 3]);
	const [changeListState, setChangeListState] = useState(false);
	const [header, setHeader] = useState({});
	const handelCheck = (passenger, flag) => {
		if (flag) {
			setListOfChecked([...ListOfChecked, passenger.id]);
		} else {
			let newListChecked = ListOfChecked.filter((id) => id != passenger.id);
			setListOfChecked(newListChecked);
		}
	};

	const allPassengers =
		passangers?.length > 0
			? passangers?.map((passenger) => {
					return (
						<tr className="text-nowrap">
							<td className="d-flex align-items-center">
								{/* <input
                type="checkbox"
                checked={ListOfChecked.includes(passenger.id)}
                className="mx-1 insert-group-checkbox "
                onChange={(e) => {
                  handelCheck(passenger, e.target.checked);
                }}
              /> */}
								{passenger.passport_number}
							</td>

							<td>
								{locale === "en"
									? passenger.full_name_en
									: passenger.full_name_ar}
							</td>
							<td>{passenger.gender}</td>
							<td>{passenger.date_of_birth}</td>
							<td>{passenger.age}</td>
							<td>
								{passenger.relationship ? passenger.relationship[locale] : ""}
							</td>
							<td>{passenger.national_id}</td>
							<td>{passenger.nationality}</td>
							<td>
								<Link
									to={`/requests/transportation/passenger/${status}/${id}/${passenger.id}`}
								>
									{locale === "en" ? (
										<i class="fas fa-angle-right"></i>
									) : (
										<i class="fas fa-angle-left"></i>
									)}
								</Link>
							</td>
						</tr>
					);
			  })
			: [];

	// const allNotes = notes.length > 0 ? notes.map((note) => {
	//   return <div className='bg-gray p-2 mt-3  border rounded'>
	//     <p className="m-0 txt-blue font-weight-bold">
	//       Ahmed Mohamed | 29 Aug 2022 06:00:00
	//     </p>
	//     <p className="m-0">
	//       Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry’s Standard Dummy Text Ever Since The 1500S,
	//       When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book.
	//     </p>
	//   </div>
	// }) : []

	useEffect(() => {
		// const fetchRequestIssued = async () => {
		//   const response = await viewOnlineVisaRequest(id)
		//   if (response.status === 200 || response.status === 201) {
		//     setPassangers(response.data.data.passengers)
		//     setHeader(response.data.data)
		//   }
		// }

		const fetchRequestWaiting = async () => {
			const response = await viewTransportationRequestWaiting(id);
			if (response.status === 200 || response.status === 201) {
				setPassangers(response.data.data.passengers);
				setHeader(response.data.data);
			}
		};

		// if (status === "waiting") {
		fetchRequestWaiting();
		// } else {
		//   fetchRequestIssued()
		// }
	}, []);

	useEffect(() => {
		const getAllNotes = async () => {
			const res = await fetchNotesTransportation(id);
			if (res.status === 200 || res.status === 201) {
				setNotes(res.data?.data);
			}
		};
		getAllNotes();
	}, [changeListState]);

	const allNotes =
		notes?.length > 0 ? (
			notes.map((note) => {
				return (
					<div className="bg-gray-100 p-2 mt-3  border rounded">
						<p className="m-0 txt-blue font-weight-bold">
							{note?.user_name} | {note?.created_at?.slice(0, 10)}
						</p>
						<p className="m-0">{note?.body}</p>
					</div>
				);
			})
		) : (
			<p className="text-center">No Notes</p>
		);
	const addNote = async () => {
		let data = {
			//id,
			body: searchVal,
		};

		const res = await addNoteTransportation(id, data);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "Info!",
				message: "Add Note successfully!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setChangeListState((prev) => !prev);
				},
			});
			setSearchVal("");
		}
	};
	const exportToFile = async () => {
		const res = await exportToExcel(id);
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					//type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div>
				<ViewRequestHeader header={header} />
			</div>

			<div className="mt-3 bg-white  rounded border">
				<div className=" visa-view-list row m-0 p-0">
					<div className="col-3 p-0 d-flex  booking-view-header">
						<button
							className={` btn border text-blue-black  rounded-0 w-50 py-3 ${
								currentTab === 1 ? "viewRequest-active" : ""
							}`}
							onClick={() => {
								setCurrentTab(1);
							}}
						>
							<i class="fas fa-users mx-1"></i>
							{requests.Travelers}
						</button>
						<button
							className={`btn border  text-blue-black rounded-0 w-50 py-3 ${
								currentTab === 2 ? "viewRequest-active" : ""
							}`}
							onClick={() => {
								setCurrentTab(2);
							}}
						>
							<i class="fas fa-file-invoice mx-1"></i>
							{requests.notes}
						</button>
					</div>
					<div className="col-9 border-bottom p-0 m-0"></div>
				</div>

				{currentTab === 1 ? (
					<div className=" m-0   justify-content-end d-flex align-items-center  ">
						<button className="btn btn-primary  m-1" onClick={exportToFile}>
							<i class="fas fa-file-excel mx-2"></i>
							{requests.ExportSelectedToExcel}
						</button>

						<div className=" mx-2 w-25">
							<TextField
								hasLabel={false}
								placeholder={requests.search}
								value={searchVal}
								onChange={(e) => {
									setSearchVal(e.target.value);
								}}
							/>
						</div>
					</div>
				) : (
					<div className="p-3">
						<div className="mt-2">
							<textarea
								className="w-100 p-4"
								placeholder="Add Notes"
								value={searchVal}
								onChange={(e) => setSearchVal(e.target.value)}
							/>
						</div>
						<div className="d-flex justify-content-end">
							<ShowForPermission permission="Transportation-Requests-Manage">
								<button
									className="btn companies-btn"
									onClick={() => {
										addNote();
									}}
									disabled={searchVal == ""}
								>
									Add Notes
								</button>
							</ShowForPermission>
						</div>
					</div>
				)}
			</div>

			{currentTab === 1 ? (
				<div className="border table-responsive">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">
								<th>{requests.PassportNumber}</th>
								<th>{requests.Name}</th>
								<th>{requests.Gender}</th>
								<th>{requests.Birthday}</th>
								<th>{requests.Age}</th>
								<th>{requests.Relationship}</th>
								<th>{requests.NationalId}</th>
								<th>{requests.Nationality}</th>
							</tr>
						</thead>
						<tbody>{allPassengers}</tbody>
					</table>
				</div>
			) : (
				<div className="">{allNotes}</div>
			)}

			<div className="d-flex justify-content-end my-4">
				<button
					className="btn bg-gray mx-2 px-5"
					onClick={() => {
						history.push(`/requests/transportation/${status}`);
					}}
				>
					{requests.back}
				</button>
			</div>
		</>
	);
}
