import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { listPlatformHotels } from "services/platform";
import Locale from "translations";
import PlatformHotelDetailsFilter from "./PlatformHotelDetailsFilter";

const HotelDetails = ({ platformID }) => {
	let locale = localStorage.getItem("currentLocale") || "en";
	const history = useHistory();
	const { backOffice } = Locale;
	const { id } = useParams();
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [hotelList, setHotelList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchData = async (id, filters = {}, page = pages) => {
		const res = await listPlatformHotels(id, filters, page);
		if (res?.status === 200) {
			setHotelList(res?.data?.hotels);
			setMeta(res?.data?.meta);
		}
	};

	useEffect(() => {
		if (platformID !== undefined) {
			fetchData(platformID);
		}
	}, [platformID, pages]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	const editHotel = (itemId) => {
		console.log(`edit Hotel ${itemId}`);
	};

	const allHotels = hotelList?.map((item, index) => (
		<>
			<tr key={item.id}>
				<td>{item.name}</td>
				<td>{item.country}</td>
				<td className="text-orange">
					{item?.platform_value} {backOffice.SAR}
				</td>
				<td className="text-orange">
					{item?.total_commission} {backOffice.SAR}
				</td>
				<td className="text-orange">
					{item?.safa_value} {backOffice.SAR} ({item?.safa_commission}%)
				</td>
				<td className="text-orange">
					{Math.floor(Math.random() * 100000)}{" "}
					{/* this sub bbc code will retrieved from API later */}
				</td>
			</tr>
		</>
	));

	return (
		<>
			<div className="property-details">
				<div className="row m-0">
					<div className="col-12 p-0">
						<PlatformHotelDetailsFilter
							hotelList={hotelList}
							setHotelList={setHotelList}
							pages={pages}
							platformID={platformID}
						/>
						<div className="our-border px-4">
							<table className="custom-table back-office-table table table-striped">
								<thead>
									<tr>
										<th>{backOffice.hotelName}</th>
										<th>{backOffice.country}</th>
										<th className="text-orange">{backOffice.commission}</th>
										<th className="text-orange">
											{backOffice.totalCommission}
										</th>
										<th className="text-orange">{backOffice.safaCommission}</th>
										<th className="text-orange">{backOffice.subBCC}</th>
									</tr>
								</thead>
								<tbody>
									{hotelList ? (
										allHotels
									) : (
										<tr>
											<td colSpan="6">
												<div className="product-no-data">
													<i className="fas fa-info-circle fa-lg"></i>{" "}
													<h4>{backOffice.noResult}</h4>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							{hotelList?.length > 0 ? (
								<Pagination info={meta} goTo={goTo} />
							) : null}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HotelDetails;
