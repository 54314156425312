import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createPackage, listPackages, updatePackage } from "services/package";
import { mapCurrencyToSelect } from ".";

export const PACKAGE_TYPES = [
	{ value: "add", label: "Add", id: 0 },
	{ value: "deduct", label: "Deduct", id: 0 },
	{ value: "transfer", label: "Transfer", id: 0 },
	{ value: "gift", label: "Gift", id: 0 },
];

export const TRANSFER_TYPES = [
	{ value: "General Transfer", label: "General Transfer", id: 0 },
	{ value: "Season Transfer", label: "Season Transfer", id: 0 },
	{ value: "Company to Company", label: "Company to Company", id: 0 },
];

const REQUIRED_FIELDS = [
	"safa_key",
	"package",
	"contract",
	"package_type",
	"package_price",
	"currency",
	"start_date",
	"end_date",
	"visa_count",
	"transfer_type",
	"season",
	"company_from",
	"company_to",
];

function isLastDayInMonth(date) {
	const currentDay = moment(date).format("DD");
	const lastDay = moment(date).endOf("month").format("DD");

	if (currentDay === "Invalid date") {
		throw new Error("Invalid Date");
	}
	return currentDay === lastDay;
}

function excludeField(fields, field) {
	if (Array.isArray(field)) {
		return fields.filter((f) => !field.includes(f));
	}

	return fields.filter((f) => f !== field);
}

export default function AddEditPackageModal({
	setAddEditModal,
	addEditModal,
	fetchPackages,
	safaKeys,
	setSafaKeys,
	getSafaKeys,
	companyFromSafaKeys,
	companyToSafaKeys,
	setCompanyFromSafaKeys,
	setCompanyToSafaKeys,
}) {
	const [visibleMonth, setVisibleMonth] = useState(false);
	const { allCurrencies } = useGlobalState();
	const [packagesSearchResult, setPackagesSearchResult] = useState([]);
	const [errors, setErrors] = useState({});

	const isGiftPackage = addEditModal?.data?.package_type?.value === "gift";
	const isTransferPackage =
		addEditModal?.data?.package_type?.value === "transfer";

	const getPackages = async (inputValue) => {
		if (!inputValue) return;

		const {
			data: { data },
		} = await listPackages({}, { id: inputValue });

		const formatted = data.map((item) => ({
			...item,
			label: item?.id,
			name: item?.id,
			value: item?.id,
		}));

		setPackagesSearchResult(formatted);
	};

	const handleOnChange = (key, value) => {
		setErrors({
			...errors,
			...validate(
				{
					name: key,
					value,
				},
				{
					required: true,
					minNumber:
						key === "package_price" ? addEditModal.data?.amount_pay : null,
				}
			),
		});

		setAddEditModal({
			...addEditModal,
			data: {
				...addEditModal.data,
				[key]: value,
			},
		});
	};

	const addPackage = async () => {
		const formData = new FormData();
		formData.append("package", addEditModal.data.package);
		formData.append("company_safa_key_id", addEditModal.data?.safa_key?.id);
		formData.append("contract", addEditModal.data.contract);
		formData.append(
			"package_price",
			!(isGiftPackage || isTransferPackage)
				? addEditModal.data.package_price
				: 0
		);
		!(isGiftPackage || isTransferPackage) &&
			formData.append("currency", addEditModal.data.currency.id);
		formData.append(
			"start_date",
			moment(addEditModal.data.start_date).format("YYYY-MM-DD")
		);
		formData.append(
			"end_date",
			moment(addEditModal.data.end_date).format("YYYY-MM-DD")
		);
		formData.append("package_type", addEditModal.data.package_type.value);
		formData.append("visa_count", addEditModal.data.visa_count);

		if (isTransferPackage) {
			formData.append("transfer_type", addEditModal.data.transfer_type.value);
			if (addEditModal.data.transfer_type.value === "Season Transfer") {
				formData.append("session_number", addEditModal.data.season);
			} else if (
				addEditModal.data.transfer_type.value === "Company to Company"
			) {
				formData.append(
					"from_package_id",
					+addEditModal.data.company_from.id || +addEditModal.data.company_from
				);
				formData.append("to_company_id", +addEditModal.data.company_to.id);
			}
		}

		const res = await createPackage(formData);
		return res;
	};

	const editPackage = async () => {
		const formData = new FormData();
		formData.append("_method", "put");
		formData.append("package", addEditModal.data.package);
		formData.append(
			"company_safa_key_id",
			addEditModal.data?.safa_key?.id || addEditModal?.data?.company_safa_key_id
		);
		formData.append("contract", addEditModal.data.contract);
		(!isGiftPackage || !isTransferPackage) &&
			formData.append("package_price", addEditModal.data.package_price);
		(!isGiftPackage || !isTransferPackage) &&
			formData.append("currency", addEditModal.data.currency.id);
		formData.append(
			"start_date",
			moment(addEditModal.data.start_date).format("YYYY-MM-DD")
		);
		formData.append(
			"end_date",
			moment(addEditModal.data.end_date).format("YYYY-MM-DD")
		);
		formData.append("package_type", addEditModal.data.package_type.value);
		formData.append("visa_count", addEditModal.data.visa_count);

		if (isTransferPackage) {
			formData.append("transfer_type", addEditModal.data.transfer_type.value);
			if (addEditModal.data.transfer_type.value === "General Transfer") {
				formData.append("season", addEditModal.data.season);
			} else if (
				addEditModal.data.transfer_type.value === "Company to Company"
			) {
				formData.append(
					"from_package_id",
					addEditModal.data.company_from.id || addEditModal.data.company_from
				);
				formData.append("to_company_id", +addEditModal.data.company_to.id);
			}
		}

		const res = await updatePackage(addEditModal.data?.id, formData);
		return res;
	};

	const checkFormErrors = () => {
		let submitErrors = {};
		Object.keys(addEditModal.data).forEach((key) => {
			let requiredFields = REQUIRED_FIELDS;

			if (isGiftPackage || isTransferPackage) {
				requiredFields = excludeField(requiredFields, [
					"package_price",
					"currency",
				]);
			}

			if (addEditModal.data.package_type?.value !== "transfer") {
				requiredFields = excludeField(requiredFields, [
					"transfer_type",
					"season",
					"company_from",
					"company_to",
				]);
			}

			if (addEditModal.data.transfer_type?.value !== "Season Transfer") {
				requiredFields = excludeField(requiredFields, "season");
			}

			if (addEditModal.data.transfer_type?.value !== "Company to Company") {
				requiredFields = excludeField(requiredFields, [
					"company_from",
					"company_to",
				]);
			}

			if (requiredFields.includes(key)) {
				submitErrors = {
					...submitErrors,
					...validate(
						{
							name: key,
							value: addEditModal.data[key],
						},
						{
							required: true,
							minNumber:
								key === "package_price" ? addEditModal.data?.amount_pay : null,
						}
					),
				};
			}
		});
		setErrors(submitErrors);
		return submitErrors;
	};

	const toggleAddEditModal = (type) => {
		setAddEditModal({
			...addEditModal,
			open: !addEditModal.open,
			type,
			data: {
				safa_key: null,
				package: "",
				contract: "",
				package_price: "",
				currency: null,
				start_date: null,
				end_date: null,
			},
		});
		setErrors({});
	};

	const submit = async () => {
		const formErrors = checkFormErrors();
		let statusCode;
		if (isFormValid(formErrors)) {
			try {
				if (addEditModal.type === "add") {
					const res = await addPackage();
					statusCode = res.status;
				} else {
					const res = await editPackage();
					statusCode = res.status;
				}

				if (statusCode === 201 || statusCode === 200) {
					fetchPackages();
					toggleAddEditModal(addEditModal.type);
				}
			} catch (error) {}
		}
	};

	useEffect(() => {
		if (addEditModal.open) {
			setErrors({});
		}
	}, [addEditModal.open]);

	return (
		<Modal
			isOpen={addEditModal.open}
			toggle={() => toggleAddEditModal(addEditModal.type)}
		>
			<ModalHeader toggle={() => toggleAddEditModal(addEditModal.type)}>
				{addEditModal.type === "add" ? "Add Package" : "Edit Package"}
			</ModalHeader>

			<ModalBody>
				<div className="row align-items-end">
					<div className="col-6">
						<AutoCompleteField
							label="Safa Key"
							placeholder="Safa Key"
							name="safa_key"
							id="safa_key"
							listAuto={safaKeys}
							setListAuto={setSafaKeys}
							getListAuto={(data) => getSafaKeys(data, setSafaKeys)}
							isSearchable={true}
							value={addEditModal.data?.safa_key?.safa_key}
							onChange={(e) => {
								setAddEditModal({
									...addEditModal,
									data: {
										...addEditModal.data,
										safa_key: e,
										company_to: e || addEditModal.data?.safa_key,
									},
								});
							}}
							onSelectValue={(e) => {
								setAddEditModal({
									...addEditModal,
									data: {
										...addEditModal.data,
										safa_key: e,
										company_to: e || addEditModal.data?.safa_key,
									},
								});
							}}
							color={errors?.safa_key?.required ? "danger" : ""}
							errors={errors?.safa_key}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Country"
							type="text"
							placeholder="Country"
							name="country"
							id="country"
							value={addEditModal.data?.safa_key?.country}
							disabled
						/>
					</div>
					<div className="col-12">
						<TextField
							label="Company Name"
							type="text"
							placeholder="Company Name"
							name="company"
							id="company"
							value={addEditModal.data?.safa_key?.company_name}
							disabled
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Package Name"
							type="text"
							placeholder="Package Name"
							name="package"
							id="package"
							value={addEditModal.data.package}
							onChange={(e) => {
								handleOnChange("package", e.target.value);
							}}
							color={errors?.package?.required ? "danger" : ""}
							errors={errors?.package}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Contract"
							type="number"
							placeholder="Contract"
							name="contract"
							id="contract"
							value={addEditModal.data.contract}
							onChange={(e) => {
								handleOnChange("contract", e.target.value);
							}}
							color={errors?.contract?.required ? "danger" : ""}
							errors={errors?.contract}
						/>
					</div>
					<div className="col-12">
						{/* visa its a number */}
						<TextField
							label="Visa Count"
							type="number"
							placeholder="Visa Count"
							name="visa_count"
							id="visa_count"
							value={addEditModal.data.visa_count}
							onChange={(e) => {
								handleOnChange("visa_count", e.target.value);
							}}
							color={errors?.visa_count?.required ? "danger" : ""}
							errors={errors?.visa_count}
						/>
					</div>
					<div className="col-12">
						<SelectField
							label="Package Type"
							placeholder="Package Type"
							name="package_type"
							id="package_type"
							options={PACKAGE_TYPES}
							value={addEditModal.data.package_type}
							onChange={(e) => {
								const { value } = e;
								let _errors = { ...errors };
								let _addEditModal = { ...addEditModal };

								const updateModalData = (dataUpdates) => {
									_addEditModal = {
										..._addEditModal,
										data: {
											...addEditModal.data,
											package_type: e,
											...dataUpdates,
										},
									};
								};

								const updateErrors = (errorUpdates) => {
									_errors = {
										..._errors,
										...errorUpdates,
									};
								};

								updateErrors(
									validate(
										{
											name: "package_type",
											value: e,
										},
										{ required: true }
									)
								);

								if (value === "gift") {
									updateModalData({ package_price: 0, currency: null });
								} else if (value === "transfer") {
									updateModalData({ package_price: 0, currency: null });
								} else {
									updateModalData({
										transfer_type: null,
										season: null,
									});
									updateErrors({
										transfer_type: { required: false },
										season: { required: false },
										company_from: { required: false },
										company_to: { required: false },
									});
								}

								setAddEditModal(_addEditModal);
								setErrors(_errors);
							}}
							color={errors?.package_type?.required ? "danger" : ""}
							errors={errors?.package_type}
						/>
					</div>

					{isTransferPackage && (
						<div className="col-12">
							<SelectField
								label="Transfer Type"
								placeholder="Transfer Type"
								name="transfer_type"
								id="transfer_type"
								options={TRANSFER_TYPES}
								value={addEditModal.data.transfer_type}
								onChange={(e) => {
									handleOnChange("transfer_type", e);
									let _errors = { ...errors };
									let _addEditModal = { ...addEditModal };

									_errors = {
										..._errors,
										...validate(
											{
												name: "transfer_type",
												value: e,
											},
											{ required: true }
										),
									};

									if (e.value === "General Transfer") {
										_addEditModal = {
											..._addEditModal,
											data: {
												..._addEditModal.data,
												transfer_type: e,
												season: null,
												company_from: null,
											},
										};
									} else if (e.value === "Season Transfer") {
										_addEditModal = {
											..._addEditModal,
											data: {
												..._addEditModal.data,
												transfer_type: e,
												season: null,
											},
										};
									} else if (e.value === "Company to Company") {
										_addEditModal = {
											..._addEditModal,
											data: {
												..._addEditModal.data,
												transfer_type: e,
												season: null,
												company_from: null,
											},
										};
									}

									setAddEditModal(_addEditModal);
									setErrors(_errors);
								}}
								color={errors?.transfer_type?.required ? "danger" : ""}
								errors={errors?.transfer_type}
							/>
						</div>
					)}

					{addEditModal.data.transfer_type?.value === "Season Transfer" && (
						<div className="col-12">
							<TextField
								label="Season Transfer"
								type="number"
								placeholder="Season"
								name="season"
								id="season"
								value={addEditModal.data.season}
								onChange={(e) => {
									handleOnChange("season", e.target.value);
								}}
								color={errors?.season?.required ? "danger" : ""}
								errors={errors?.season}
							/>
						</div>
					)}

					{addEditModal.data.transfer_type?.value === "Company to Company" && (
						<>
							<div className="col-6">
								<AutoCompleteField
									label="Company From (Package ID)"
									placeholder="Company From"
									name="company_from"
									id="company_from"
									listAuto={packagesSearchResult}
									setListAuto={setPackagesSearchResult}
									getListAuto={getPackages}
									isSearchable={true}
									value={addEditModal.data?.company_from?.id}
									onChange={(e) => {
										handleOnChange("company_from", e);
									}}
									onSelectValue={(e) => {
										handleOnChange("company_from", e);
									}}
									color={errors?.company_from?.required ? "danger" : ""}
									errors={errors?.company_from}
								/>
							</div>

							<div className="col-6">
								<AutoCompleteField
									label="Company To"
									placeholder="Company To"
									name="company_to"
									id="company_to"
									listAuto={companyToSafaKeys}
									setListAuto={setCompanyToSafaKeys}
									getListAuto={(data) =>
										getSafaKeys(data, setCompanyToSafaKeys)
									}
									isSearchable={true}
									// value={addEditModal.data?.company_to?.safa_key}
									value={
										addEditModal.data?.safa_key?.safa_key ||
										addEditModal.data?.safa_key
									}
									onChange={(e) => {
										handleOnChange("company_to", e);
									}}
									onSelectValue={(e) => {
										handleOnChange("company_to", e);
									}}
									// color={errors?.company_to?.required ? "danger" : ""}
									// errors={errors?.company_to}
									disabled
								/>
							</div>
						</>
					)}

					{!(isGiftPackage || isTransferPackage) && (
						<div className="col-6">
							<TextField
								label="Package Price"
								type="number"
								placeholder="Package Price"
								name="package_price"
								id="package_price"
								value={addEditModal.data.package_price}
								min={addEditModal.data?.amount_pay}
								onChange={(e) => {
									handleOnChange("package_price", e.target.value);
								}}
								color={errors?.package_price?.required ? "danger" : ""}
								errors={errors?.package_price}
							/>
						</div>
					)}
					{!(isGiftPackage || isTransferPackage) && (
						<div className="col-6">
							<SelectField
								label="Currency"
								placeholder="Currency"
								name="currency"
								id="currency"
								options={mapCurrencyToSelect(allCurrencies)}
								value={addEditModal.data.currency}
								onChange={(e) => {
									handleOnChange("currency", e);
								}}
								color={errors?.currency?.required ? "danger" : ""}
								errors={errors?.currency}
							/>
						</div>
					)}
					<div className="col-6">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Start Date"}
							date={addEditModal.data.start_date}
							onChangeDate={(e) => {
								setAddEditModal({
									...addEditModal,
									data: {
										...addEditModal.data,
										start_date: e,
										end_date: null,
									},
								});
								if (isLastDayInMonth(e)) {
									setVisibleMonth(moment(e).add(1, "day"));
								} else {
									setVisibleMonth(moment(e));
								}

								setErrors({
									...errors,
									...validate(
										{
											name: "start_date",
											value: e,
										},
										{ required: true }
									),
								});
							}}
							readOnly
							color={errors?.start_date?.required ? "danger" : ""}
							errors={errors?.start_date}
						/>
					</div>
					<div className="col-6">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"End Date"}
							date={addEditModal.data.end_date}
							minDate={addEditModal.data.start_date}
							onChangeDate={(e) => {
								handleOnChange("end_date", e);
								setErrors({
									...errors,
									...validate(
										{
											name: "end_date",
											value: e,
										},
										{ required: true }
									),
								});
							}}
							month={moment(new Date())}
							initialVisibleMonth={() =>
								visibleMonth ||
								moment(addEditModal.data.start_date || new Date())
							}
							readOnly
							color={errors?.end_date?.required ? "danger" : ""}
							errors={errors?.end_date}
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<button
					className="btn btn-secondary"
					onClick={() => toggleAddEditModal(addEditModal.type)}
				>
					Cancel
				</button>
				<button className="btn btn-primary" onClick={submit}>
					Save
				</button>
			</ModalFooter>
		</Modal>
	);
}
