import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	fetchAvailableSubscriptionPackages,
	UpdateSubscripionPlanStatus,
	ViewSubscripionConfigurationsPlan,
} from "services/subscriptionPlan";
import ModuleList from "../AddEditPlan/ModuleList";
import ChangeStatus from "../Models/changeStatus";
import { UpArraw } from "../shared/Icons";

export default function ViewPlan() {
	const { company_id, plan_id } = useParams();
	const history = useHistory();
	const [subscriptionState, setSubscriptionState] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("free");
	const [planStatus, setPlanStatus] = useState("");
	const [isOpenStatus, setIsOpenStatus] = useState(false);
	const [Status, setStatus] = useState({
		status: "canceled",
		refundAmount: null,
	});

	const toggleStatus = () => {
		setIsOpenStatus(!isOpenStatus);
	};

	const calculateTotal = () => {
		return subscriptionState.reduce(
			(acc, { price, discount_type, discount_value }) => {
				if (discount_type === "percentage") {
					return acc + price - (price * discount_value) / 100;
				} else {
					return acc + price - discount_value;
				}
			},
			0
		);
	};

	const cancelPlan = async () => {
		const data = {
			status: "canceled",
			refundAmount: Status.refundAmount,
		};

		const res = await UpdateSubscripionPlanStatus(data, plan_id);

		if (res?.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Plan has been canceled successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			history?.goBack();
		}
	};

	useEffect(() => {
		async function fetchData() {
			const availablePackagesRes = await fetchAvailableSubscriptionPackages();
			const availablePackages = availablePackagesRes?.data?.data;

			const planConfigRes = await ViewSubscripionConfigurationsPlan(plan_id);
			const selectedIDs = planConfigRes?.data?.subscribed;

			const updated = availablePackages.filter((module) => {
				return selectedIDs.includes(module.id);
			});

			setSubscriptionState(updated);
			setPaymentStatus(planConfigRes?.data?.payment_status);
			setPlanStatus(planConfigRes?.data.status);
		}

		plan_id && fetchData();
	}, [plan_id]);

	return (
		<div className="subscription-plan add-edit-plan">
			<div className="row w-100 mb-3">
				<button
					className="btn btn-primary ms-auto d-flex align-items-center"
					style={{
						gap: 2,
					}}
					onClick={() =>
						history.replace(`/tourism-company/${company_id}/subscription-plan/`)
					}
				>
					<span
						style={{
							transform: "rotate(-90deg)",
						}}
					>
						<UpArraw width={22} stroke="white" />
					</span>
					Back to Plans
				</button>
			</div>
			<ModuleList
				subscriptionData={subscriptionState}
				noSelection={true}
				expandAll={true}
			/>

			<div className="row py-3" style={{ backgroundColor: "#F8F9FA" }}>
				<div className="col-6 col-md-9" />
				<div className="ms-auto col-6 col-md-3 ">
					<div
						className="p-3 text-center fs-5"
						style={{
							backgroundColor: "#EDEFF1",
							borderRadius: "4px",
							fontWeight: "bold",
						}}
					>
						Total: {calculateTotal()} {}
					</div>
				</div>
			</div>

			{planStatus !== "canceled" && (
				<>
					<div className="btn-action-subsc-plan">
						{plan_id && (
							<button
								className="btn btn-cancel"
								onClick={() => setIsOpenStatus(true)}
							>
								<span className="me-2">X</span>
								<span>
									{paymentStatus !== "free"
										? "Cancel Plan"
										: "Cancel Free Trial"}
								</span>
							</button>
						)}
					</div>

					<ChangeStatus
						isOpen={isOpenStatus}
						toggle={toggleStatus}
						Status={Status}
						setStatus={setStatus}
						ChangeStatus={cancelPlan}
						isStatusFree={paymentStatus === "free"}
					/>
				</>
			)}
		</div>
	);
}
