import { useEffect, useState } from "react";

import { store } from "react-notifications-component";
import { cancelRoomReservations } from "services/companiesReservations";

import Locale from "translations";

export default function InventoryTab({ details, fetchData }) {
	console.log("detailsdetailsdetails", details);
	const { backOffice, booking } = Locale;
	// const { state } = useLocation();
	const state = { tab: "hotel" };
	const [RoomsList, setRoomsList] = useState([]);
	const [modalEdit, setModalEdit] = useState(false);

	const toggleModalEdit = () => setModalEdit(!modalEdit);

	useEffect(() => {
		setRoomsList(details.payload.rooms);
	}, []);

	const cancelRoom = async (brn, code) => {
		const data = await cancelRoomReservations({
			brn: details.brn,
			bookingCode: code,
		});
		if (data.status == 200) {
			fetchData();
			store.addNotification({
				title: "Info!",
				message: "done",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	// const DOMPurify = createDOMPurify(window);
	// const [editState, setEditState] = useState({
	// 	checkIn: details?.start_date
	// 		? moment(details.start_date)
	// 		: moment(new Date()).add(1, "d"),
	// 	checkOut: details?.end_date
	// 		? moment(details.end_date)
	// 		: moment(new Date()).add(2, "d"),
	// 	adult: +details.adults,
	// 	child: 0,
	// 	roomType: null,
	// });

	const print = (e) => {
		const popupWin = window.open(
			"",
			"_blank",
			"top=0,left=0,height=auto,width=auto"
		);
		popupWin.document.open();
		// console.log();
		popupWin.document.write(`
				<html>
					<head>
				<title>Print tab</title>

					</head>
				<body style="width:100%;">
				${e}
				<script>

				setTimeout(() => {
					console.log("Delayed for 1 second.");
					window.print();

					document.addEventListener('keydown', logKey);

				function logKey(e) {
					console.log("Delayed for 1 second." ,e);
				}
				}, "1000")

				</script>
				</body>
				</html>`);

		// popupWin.close();
		// popupWin.document.close();
	};

	const roomType = (status) => {
		switch (status) {
			case 1:
				return "single";
			case 2:
				return "dauble";
			case 3:
				return "triple";
			case 4:
				return "quadruple";
			default:
				return "Rooms";
		}
	};

	// <tr>
	// 	<td>#{index + 1}</td>
	// 	<td>{productItem.name}</td>
	// 	<td>{roomType(productItem.room_type)}</td>
	// 	<td>{productItem.from_date}</td>
	// 	<td>{productItem.to_date}</td>
	// 	<td>
	// 		<p className="bg-light p-1 rounded text-center">
	// 			<i class="fas fa-child mx-1 "></i>
	// 			{productItem.adults}{" "}
	// 		</p>
	// 	</td>
	// 	<td>
	// 		<p className="bg-light p-1 rounded text-center">
	// 			<i class="fas fa-child mx-1 "></i>
	// 			{productItem.children}
	// 		</p>
	// 	</td>
	// 	<td>{productItem.maxOccupancy}</td>
	// 	<td>{productItem.price}</td>
	// 	<td>
	// 		<a
	// 			href={() => false}
	// 			onClick={() => print(productItem.voucher)}
	// 			className="link_action"
	// 		>
	// 			<i class="fas fa-cloud-download-alt"></i>
	// 			Voucher
	// 		</a>

	// 		{!productItem.is_cancelled ? (
	// 			<button
	// 				className="btn btn-danger mx-2"
	// 				onClick={() =>
	// 					cancelRoom(productItem.brn, productItem.bookingCode)
	// 				}
	// 			>
	// 				Cancel
	// 			</button>
	// 		) : (
	// 			<span className="text-danger p-2 h6 font-weight-bold">
	// 				Canceled
	// 			</span>
	// 		)}
	// 	</td>
	// </tr>
	const AllRooms =
		RoomsList?.length > 0 ? (
			RoomsList.map((productItem, index) => {
				const mealType = productItem?.room_rates?.filter(
					(res) => +res?.mealOptionId == +productItem?.mealOptionId
				)[0]?.mealTitle;
				return (
					<tr>
						<td>#{index}</td>
						<td>{productItem.from_date}</td>
						<td>{productItem.to_date}</td>
						<td className="set-w-11">
							{productItem?.passengers?.length > 0 ? (
								<span>
									{productItem?.passengers[0]?.first_name}{" "}
									{productItem?.passengers[0]?.last_name}
								</span>
							) : (
								""
							)}
							{/* {productItem?.passengers[0].first_name}{" "}
					{productItem?.passengers[0].last_name} */}
						</td>
						<td>
							{/* {productItem.room_rates && productItem.room_rates.length > 0 ? (
						productItem.room_rates.filter(
							(res) => +res.mealOptionId == +productItem.mealOptionId
						)[0].mealTitle
					) : (
						<span className="text-gray">-</span>
					)} */}
							{productItem?.name}
						</td>

						<td>{productItem.adults}</td>
						<td>{productItem.children}</td>
						<td>
							{productItem.room_rates && productItem.room_rates.length > 0 ? (
								mealType
							) : (
								<span className="text-gray">-</span>
							)}
						</td>
						{/* <td>{productItem.maxOccupancy}</td> */}
						<td>
							{productItem.price} {details.currency}
						</td>
						{/* <td width="19%" className="tools">
							{productItem.is_cancelled ? (
								<span className="text-danger p-2 mx-2 font-weight-bold">
									Canceled
								</span>
							) : (
								<div className="row d-flex " style={{ gap: "2px" }}>
									<a
										href={() => false}
										title="Voucher"
										onClick={() => print(productItem.voucher)}
										className="d-flex mx-1 align-items-center"
										style={{ background: "#28A745", height: "26px" }}
										id={`tooltip-${index}-voucher`}
									>
										<i className="fas fa-cloud-download-alt custom-icon-size mx-1 custom-icon-color  text-white"></i>
									</a>

									<a
										href={() => false}
										title="print"
										onClick={() =>
											cancelRoom(productItem.brn, productItem.bookingCode)
										}
										className="mx-1 px-1 d-flex align-items-center"
										style={{ background: "#FF6868", height: "26px" }}
										id={`tooltip-${index}-cancel`}
									>
										<i className="fas fa-ban mx-1 custom-icon-size text-white"></i>
									</a>
								</div>
							)}

							<div className="row"></div>
						</td> */}
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result "}</h4>
					</div>
				</td>
			</tr>
		);
	return (
		<>
			<div className="booking-details border bg-white m-3">
				<h5 className="absolute-header">{booking.messages.passengerDetails}</h5>
				<table className="custom-table table table-striped custom-padding-15 passenger-details">
					<thead>
						<tr>
							<th>{booking.RoomNo}</th>
							<th>{booking.Arrival}</th>
							<th>{booking.Departure}</th>
							<th>{booking.PassengerName}</th>
							<th>{booking.RoomType}</th>
							<th>{booking.Adult}</th>
							<th>{booking.Child}</th>
							<th>{booking.BoardBasis}</th>
							<th>{backOffice.Price}</th>
							{/* <th>{booking.Actions}</th> */}
						</tr>
					</thead>
					<tbody>{AllRooms}</tbody>
				</table>
			</div>

			{/* <Models /> */}
		</>
	);
}
