function StatusBar({ type, title, items }) {
	// convert deal_status object to array
	items = items instanceof Array ? items :
		Object.keys(items).map((key) => {
			let keyName = [key][0];
			switch (keyName) {
				case "confirmed_by_ta":
					keyName = "Confirmed by TA"
					break;
				case "e_number":
					keyName = "E-Number"
					break;
				case "package_change":
					keyName = "PKG-CH"
					break;
				case "passport_submitted":
					keyName = "Passport Submitted"
					break;
				case "visa_number":
					keyName = "Visa"
					break;
				case "payment_done":
					keyName = "Payment Done"
					break;
				default:
					break;
			}
			return {
				flag: items[key],
				item: [key][0],
				name: keyName
			}
		});
	return (
		<>
			<div className={`status-bar ${type} `}>
				<div className="status-name">{title}</div>
				<div className="d-flex align-items-end py-2 gap-2">
					{items?.map((item, index) => {
						return (
							<div className={`d-flex justify-content-center flex-column align-items-center text-capitalize ${item?.flag ? "" : "text-muted"}`}
								key={`${item?.item}-${index}`}>
								{item?.flag ? <i class="far fa-check-circle"></i> : null}
								<p className='text-center'>{item?.name || item?.item}</p>
							</div>
						)
					})}
				</div>
			</div>
		</>
	);
}

export default StatusBar;
