import ShowForPermission from 'helpers/showForPermission';
import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ToggleModuleStatus } from "services/vendor";

const VendorModule = ({ Name, status, setReload }) => {
	const { id } = useParams();
	const [IsToggle, setIsToggle] = useState(false);
	const toggle = () => {
		setIsToggle(!IsToggle);
	};
	const toggleStatus = async () => {
		const req = await ToggleModuleStatus(id, { module_name: Name });
		if (req.status === 200) {
			setReload((prev) => !prev);
			toggle();
		}
	};

	return (
		<div className="module-box" key={Name}>
			<div className="handel-head">
				<div className="d-flex align-items-center">
					<i class="fas fa-cog px-1"></i>
					<div className="ps-2 pe-4 pt-2">
						<p className="">Module Name</p>
						<h5 className="fw-bold position-relative">
							{Name}
							{IsToggle && (
								<>
									{status ? (
										<>
											<span
												onClick={toggleStatus}
												role="button"
												className="toggle-status text-danger"
											>
												<i class="fas fa-ban"></i> Deactivate Module
											</span>
										</>
									) : (
										<>
											<span
												onClick={toggleStatus}
												role="button"
												className="toggle-status text-success"
											>
												<i class="fas fa-check"></i> Activate Module
											</span>
										</>
									)}
								</>
							)}
						</h5>
					</div>
				</div>
				<ShowForPermission permission="Vendors-Management-Manage">
					<i class="fas fa-ellipsis-v" role="button" onClick={toggle}></i>
				</ShowForPermission>
			</div>
			<div>
				<p>status</p>
				<p className={`fw-bold ${status ? "text-success" : "text-danger"} `}>
					{status ? "Active" : "Inactive"}
				</p>
			</div>
		</div>
	);
};

export default VendorModule;
