import Model from "components/model";
import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import Locale from "translations";
import CommissionModelAdd from "./CommissionModel";

function CommissionList() {
	const { backOffice } = Locale;
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});
	const addCommission = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "modal",
			title: "Add Commission",
		});
	};
	const closeaddCommission = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: null,
			title: null,
		});
	};
	return (
		<>
			<div className="personal-data">
				<div className="col-md-12 head-manager p-0">
					<p>{backOffice.commissions}</p>
				</div>
				<div className="col our-border px-4">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr>
								<th>{backOffice.name}</th>
								<th>{backOffice.type}</th>
								<th>{backOffice.selectors}</th>
								<th>{backOffice.commission}</th>
								<th>{backOffice.safaCommission}</th>
								<th>{backOffice.platformCommission}</th>
								<ShowForPermission permission="B2C-Commission-Manage">
									<th>&nbsp;</th>
								</ShowForPermission>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>test</td>
								<td>base</td>
								<td>
									<button class="btn btn-link" id="selector0">
										<i class="fas fa-eye"></i> 2 Selectors
									</button>
								</td>
								<td>%10</td>
								<td>50%</td>
								<td>50%</td>
								<ShowForPermission permission="B2C-Commission-Manage">
									<td>
										<button
											className="btn btn-link mr-2"
											/* 									onClick={(Model, Commissions, Index) =>openCommissionModalHandler("edit", item, index)}
											 */ title={backOffice.edit}
										>
											<i className="fas fa-edit"></i>{" "}
										</button>
										<a
											href={() => false}
											className="d-inline-block text-danger"
											/* 									onClick={() => removeItem(item, index)}
											 */
										>
											<i className="fas fa-trash"></i>
										</a>
									</td>
								</ShowForPermission>
							</tr>
						</tbody>
					</table>
				</div>
				<ShowForPermission permission="B2C-Commission-Manage">
					<a
						className="btn btn-lg text-success d-inline-block my-2 "
						href={() => false}
						onClick={() => addCommission("add", null, null)}
					>
						<i className="fas fa-plus-circle"></i> {backOffice.addCommission}
					</a>
				</ShowForPermission>
			</div>
			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={backOffice.addCommission}
				toggle={closeaddCommission}
				size="lg"
				hasFooter={false}
			>
				<CommissionModelAdd />
			</Model>
		</>
	);
}

export default CommissionList;
