import Pagination from "components/shared/Pagination";
import { getNotification } from "helpers/makeNotifications";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { deleteB2cUserView, listB2cUsers } from "services/b2c";
import Locale from "translations";
import ChangePassword from "../View/Profile/ChangePassword";
import Filter from "./Filter";
import User from "./User";

export default function B2cUsers() {
	const { backOffice, Companies, userDetails, commons, confirmation } = Locale;
	const [users, setUsers] = useState([]);
	const [meta, setMeta] = useState(null);
	const [deleteUserModal, setDeleteUserModal] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [userId, setUserId] = useState(null);

	const [filters, setFilter] = useState({
		search: "",
		date: null,
		status: null,
		country_id: null,
	});

	const openPasswordModal = (id) => {
		setChangePasswordModal(true);
		setUserId(id);
	};
	const closePasswordModal = () => {
		setChangePasswordModal(false);
		setUserId(null);
	};

	const openDeleteModal = (id) => {
		setDeleteUserModal(true);
		setUserId(id);
	};
	const closeDeleteModal = () => {
		setDeleteUserModal(false);
		setUserId(null);
	};

	useEffect(() => {
		const fetchList = async () => {
			const res = await listB2cUsers({
				...filters,
				status: filters.status?.value,
				country: filters.country_id?.value,
				date: filters.date ? moment(filters.date).format("YYYY-MM-DD") : null,
			});
			if (res?.status === 200) {
				setUsers(res?.data?.data);
				setMeta(res?.data?.meta);
			}
		};
		fetchList();
	}, [filters]);

	const goTo = (page) => {
		setFilter({ ...filters, page: page });
	};

	const removeUser = async () => {
		const res = await deleteB2cUserView(userId);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilter({ ...filters });
			closeDeleteModal();
		}
	};

	const AllUsers =
		users.length > 0 &&
		users.map((user, index) => (
			<User
				user={user}
				key={index}
				removeUser={(id) => openDeleteModal(id)}
				changePassword={(id) => openPasswordModal(id)}
			/>
		));

	return (
		<>
			<div>
				<div className="d-flex justify-content-between">
					<h4 className="text-black ">B2C Users</h4>
				</div>
				<div className="d-flex">
					<Filter filter={filters} setFilter={setFilter} />
				</div>
				<div className="px-3 our-border border-top-0">
					<table className="custom-table back-office-table table table-striped mb-0">
						<thead>
							<tr>
								<th>{userDetails.fullName}</th>
								<th>{backOffice.country}</th>
								<th>{backOffice.email}</th>
								<th>{userDetails.phoneNumber}</th>
								<th>{commons.registerDate}</th>
								{/* <th>{"Key"}</th> */}
								<th>{backOffice.status}</th>

								<th>{Companies.Control}</th>
							</tr>
						</thead>

						<tbody>
							{users?.length > 0 ? (
								AllUsers
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<Pagination info={meta} goTo={goTo} items={10} />
				</div>
			</div>

			<Modal isOpen={changePasswordModal} toggle={closePasswordModal}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0">
					<ChangePassword closeModal={closePasswordModal} userId={userId} />
				</ModalBody>
			</Modal>

			<Modal isOpen={deleteUserModal} toggle={closeDeleteModal}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<h4 className="py-4 font-weight-bolder">
						{confirmation.confirmDelete}
					</h4>
				</ModalBody>
				<div className="w-100 pb-5 border-top-0  text-center  p-0 ">
					<button
						className="btn btn-dark text-white rounded font-weight-bolder   mx-1  w-25"
						onClick={closeDeleteModal}
					>
						{userDetails.cancel}
					</button>
					<button
						className="btn btn-danger text-white rounded font-weight-bolder mx-1   w-25"
						onClick={removeUser}
					>
						{backOffice.delete} <i class="fas fa-trash text-white mx-2"></i>
					</button>
				</div>
			</Modal>
		</>
	);
}
