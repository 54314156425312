import moment from 'moment'

export function formatPromoFromAPI(promoObj) {
  return {
    cityName: { label: promoObj?.city_name, value: promoObj?.city_id },
    cityBanner: promoObj?.city_banner,
    cityImage: promoObj?.city_image,
    currency: { label: promoObj?.currency, value: promoObj?.currency, currency_code: promoObj?.currency },
    discounts: promoObj?.discounts?.map(discount => {
      return {
        id: discount?.id,
        discount: discount?.discount,
        countries: discount?.all_countries ? [{ label: "All", value: 0 }] : discount?.countries?.map(country => {
          return {
            value: country.country_id,
            label: country.country_name,
            code: country.country_code,
          }
        }),
      }
    }),
    publish: promoObj?.publish,
    hotels: promoObj?.hotels?.map(hotel => {
      return {
        id: hotel?.id,
        hotelName: { label: hotel?.name, value: hotel?.id },
        priceFrom: hotel?.price_starting_from,
        hotelDiscount: hotel?.discount,
        bookingDate: hotel?.booking_date_from,
        travelDate: { from: hotel?.travel_date_from, to: hotel?.travel_date_to },
      }
    })
  }
}

export function formatPromoDataForAPI(promoObj) {
  return {
    publish: +promoObj?.publish,
    city_id: promoObj?.cityName?.value,
    city_image: promoObj?.cityImage,
    city_banner: promoObj?.cityBanner,
    currency: promoObj?.currency?.currency_code,
    discounts: promoObj?.discounts?.map(discountObj => {
      const isAllSelected = discountObj?.countries?.filter(value => value?.label === "All")?.length > 0;
      return {
        discount: +discountObj?.discount,
        countries: isAllSelected ? [] : discountObj?.countries?.map(country => {
          return {
            country_id: country?.value,
            country_code: country?.code,
            country_name: country?.label
          }
        })
      }
    }),
    hotels: promoObj?.hotels?.map(hotel => {
      return {
        hotel_id: hotel?.hotelName?.value,
        price_starting_from: hotel?.priceFrom,
        discount: hotel?.hotelDiscount,
        booking_date_from: hotel?.bookingDate,
        travel_date_from: moment(hotel?.travelDate?.from).format("YYYY-MM-DD"),
        travel_date_to: moment(hotel?.travelDate?.to).format("YYYY-MM-DD")
      }
    })
  }
}