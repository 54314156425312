import hotelImg from "assets/images/Hotelcircel.svg";
import flightImg from "assets/images/flight.svg";
import toursImg from "assets/images/tours.svg";
import transfersImg from "assets/images/transfers.svg";

export default function ServicesImages({ name, className }) {
  const imgSrc = (() => {
    switch (name) {
      case 'hotels':
        return hotelImg
      case 'transfers':
        return transfersImg
      case 'flight':
        return flightImg
      case 'tours':
        return toursImg
      case 'transportation':
        return transfersImg
      default:
        return ""
    }
  })();
  return (
    <img src={imgSrc} alt={name} className={className} />
  )
}