import { Rating } from '@material-ui/lab'
import hotelIcon from 'assets/images/icons/hotel-gold.svg'
import Nightsimg from 'assets/images/icons/nights.svg'
import moment from 'moment'

function HotelItem({ element, index, id }) {

  return (
    <div className='package-item-container'>
      <div className='package-item'>
        <div className='icon-item'>
          <img src={hotelIcon} alt="hotel icon" />
        </div>
        <div className='d-flex flex-column m-2' >
          <p className='airprot-name d-flex align-items-center'>
            <span>{element?.itemable?.hotel?.name}</span>
            <Rating
              style={{ fontSize: "1rem" }}
              name="read-only"
              value={+element.itemable?.hotel?.rating}
              readOnly
            />
          </p>
          <span className='country-name'>
            {element?.itemable?.hotel?.country} / {element.itemable?.hotel?.city}
          </span>
          <span className='date-name'>
            {moment(element?.itemable?.check_in_at).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className='box-nights'>
          <img src={Nightsimg} alt="" />
          <span>{element.itemable?.night_count} Nights</span>
        </div>

      </div>
    </div>
  )
}

export default HotelItem