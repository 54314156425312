import TextField from "components/Form/TextField/TextField";
import moment from 'moment';
import { useState } from 'react';

export default function Listpax({ packagePaxList }) {
	const [searchTerm, setSearchTerm] = useState("");
	const [filterdPaxList, setFilterdPaxList] = useState(packagePaxList)
	function filterTravelersByName(e) {
		const value = e.target.value.toLowerCase();
		if (value !== "") {
			const filterdTravelers = packagePaxList?.filter(traveler => {
				return (
					(`${traveler?.first_name} ${traveler?.last_name} `?.toLowerCase()?.includes(value))
					&& traveler
				)
			})
			setFilterdPaxList(filterdTravelers);
		} else {
			setFilterdPaxList(packagePaxList);
		}
		setSearchTerm(value);
	}
	return (
		<>
			<div className="d-flex  align-items-center py-2 px-2 border bg-white"></div>
			<div className="filter-update bg-filter-gray border">
				<div className="product-buttons col-md-12">
					<div className="main-filter-payment pax-filter row align-items-center d-flex justify-content-between">
						<h4 className='col-md-8'>
							Pax Details
						</h4>
						<div className='col-md-4'>
							<TextField
								type="text"
								id="pax-search"
								name="pax-search"
								placeholder={"Search"}
								value={searchTerm}
								onChange={filterTravelersByName}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="border table-responsive">
				<table className="custom-table back-office-table table table-striped m-0">
					<thead>
						<tr>
							<th>Name</th>
							<th>Type</th>
							<th>Birthday</th>
							<th>Nationality</th>
							<th>Country</th>
							<th>Passport No.</th>
							<th>Visa</th>
							<th>E-Number</th>
						</tr>
					</thead>
					<tbody>
						{filterdPaxList?.length > 0 ?
							filterdPaxList?.map((traveler, index) => {
								const travelerAge = moment().diff(moment(traveler?.birth_date), 'years');
								return (
									<tr key={`traveler-${index}`}>
										<td>{`${traveler?.first_name} ${traveler?.last_name}`}</td>
										<td>{travelerAge > 12 ? "Adult" : "Child"}</td>
										<td>{traveler?.birth_date ? moment(traveler?.birth_date).format("DD-MM-YYYY") : "-"}</td>
										<td>{traveler?.country?.name}</td>
										<td>{traveler?.nationality?.name}</td>
										<td>{traveler?.passport_number}</td>
										<td>{traveler?.visa_number ? traveler?.visa_number : "-"}</td>
										<td>{traveler?.e_number ? traveler?.e_number : "-"}</td>
									</tr>
								)
							})
							:
							<tr className='text-center'>
								<td colspan="100%">No Travelers</td>
							</tr>
						}
					</tbody>
				</table>
			</div>
		</>
	);
}
