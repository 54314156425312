import backArrow from "assets/images/back.svg";
import Model from "components/model";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	commercialDistCommissionsList,
	commercialDistDeleteCommission,
} from "services/commercialDistribution";
import { handleResponseNotification } from "../../helpers/commercialDistHelper";
import ServicesImages from "../ServiceImg";
import AddEditProviderModal from "./AddEditProviderModal";

export default function CommercialDistributionServiceProvider() {
	const history = useHistory();
	const { id, brn } = useParams();
	const [providerModal, setProviderModal] = useState({
		isOpen: false,
		type: "add",
		data: null,
	});
	const [providersList, setProvidersList] = useState([]);
	const [providersLookup, setProviderLookup] = useState([]);
	const [providerReload, setProviderReload] = useState(false);

	function toggleProviderModal() {
		setProviderModal({ ...providerModal, isOpen: !providerModal.isOpen });
	}

	function goBack() {
		history.goBack();
	}
	// get providers list
	useEffect(() => {
		async function getProvidersList() {
			const res = await commercialDistCommissionsList(id, brn);
			if (res?.status === 200) {
				const formatProvidersLookup = res?.data?.data?.available_providers?.map(
					(provider) => {
						return {
							id: provider?.id,
							value: provider?.id,
							label: provider?.name,
						};
					}
				);
				setProvidersList(res?.data?.data?.commissions);
				setProviderLookup(formatProvidersLookup);
			}
		}
		getProvidersList();
	}, [providerReload]);

	// delete provider commsission
	async function deleteProvider(commissionId) {
		const res = await commercialDistDeleteCommission(id, commissionId);
		if (res?.status === 200) {
			setProviderReload((prev) => !prev);
			handleResponseNotification(res, "success", "Delete", null);
		}
	}

	return (
		<>
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center mb-3">
					<img src={backArrow} alt="" role="button" onClick={goBack} />
					<ServicesImages name={brn} className={"px-2"} />
					<h5 className="fw-bold mb-0 text-capitalize">{brn} Service</h5>
				</div>
				<ShowForPermission permission="Commercial-Distribution-Manage">
					<button
						className="btn btn-success"
						onClick={() => {
							setProviderModal({
								...providerModal,
								type: "Add",
								isOpen: !providerModal.isOpen,
								data: null,
							});
						}}
					>
						Add Provider
					</button>
				</ShowForPermission>
			</div>

			<div className=" table-responsive px-4 our-border w-100">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Provider Name</th>
							<th>Commission</th>
							<th>Commission Type</th>
							<th>Safa Commission</th>
							<th>Vendor Commission</th>
							<th>Control</th>
						</tr>
					</thead>
					<tbody>
						{providersList?.length > 0 ? (
							<>
								{providersList?.map((provider) => (
									<tr key={provider.id}>
										<td>{provider.provider?.name}</td>
										<td>{provider.amount}%</td>
										<td>{provider.type}</td>
										<td>{provider.safa_amount}%</td>
										<td>{provider.consumer_amount}%</td>
										<td>
											<ShowForPermission permission="Commercial-Distribution-Manage">
												<button
													className="btn text-info py-0"
													onClick={() =>
														setProviderModal({
															...providerModal,
															type: "Edit",
															isOpen: !providerModal.isOpen,
															data: provider,
														})
													}
												>
													<i className="fas fa-edit"></i>
												</button>
												<button
													className="btn text-danger py-0"
													onClick={() => deleteProvider(provider.id)}
												>
													<i className="fas fa-trash pe-1"></i>
												</button>
											</ShowForPermission>
										</td>
									</tr>
								))}
							</>
						) : (
							<tr>
								<td colSpan="6">
									<div className="product-build__product-no-data">
										<i className="icx icx-eye-slash" aria-hidden="true"></i>
										<h4>{"No Providers Found For this Service"}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			<Model
				isOpen={providerModal.isOpen}
				type={`${providerModal?.type}`}
				title={`${providerModal?.type} Provider`}
				toggle={toggleProviderModal}
				size="lg"
				hasFooter={false}
				hasHeader={true}
			>
				<AddEditProviderModal
					toggleModal={toggleProviderModal}
					providerModal={providerModal}
					providersLookup={providersLookup}
					setProviderReload={setProviderReload}
				/>
			</Model>
		</>
	);
}
