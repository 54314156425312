import TourBookingDetailsCard from "./BookingDetailsCard";

export default function ToursBookingDetails({
	bookingDetails,
	setBookingDetails,
}) {
	return (
		<div className="tour-booking-details" key={bookingDetails?.brn}>
			<div className="tour-booking-details-header">
				<div className='d-flex justify-content-between mt-3 align-items-center'>
					<p className="trans-dark-top">Reservation</p>
					<p className='ref-btn'>Ref No. {bookingDetails?.reference_number}</p>
				</div>
			</div>
			{/* map on cart items */}
			{bookingDetails?.reservations.map((reservationItem) => (
				<TourBookingDetailsCard
					reservationItem={reservationItem}
					setBookingDetails={setBookingDetails}
					paymentStatus={bookingDetails?.payment_status}
					bookingStatus={bookingDetails?.status}
					key={reservationItem?.details?.id}
				/>
			))}
		</div>
	);
}
