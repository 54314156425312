import moment from "moment";
import { useParams } from "react-router";

export default function ToursBookingSummary({
	bookingDetails,
}) {
	const { id } = useParams();
	
	// const confirmedReservations = bookingDetails?.reservations?.filter(
	// 	(reservation) => reservation?.status === "confirmed"
	// );



	return (
		<div className=" mt-2 mt-md-0">
			<h6 className="text-dark-blue mt-4 trans-dark-top">booking Summary</h6>
			{/* cart items */}
			{bookingDetails?.reservations?.length > 0 ? (
				<div className="p-2 mt-3 light-border bg-white">
					{bookingDetails?.reservations?.map((reservation, index) => (
						<div key={`summary-${reservation?.details?.id}`}>
							<div className="d-flex tour-booking-summary-item">
								<div className="col-md-9">
									<p className="font-weight-bold text-14">
										{reservation?.details?.name}
									</p>
									<div className="tour-booking-item-info flex-wrap">
										<i className="fas fa-map-marker-alt"></i>
										<span className="mx-1">
											{reservation?.details?.destinations?.map((city) => {
												return (
													<span key={city?.code}>
														{city?.name} ({city?.code}),{" "}
													</span>
												);
											})}
										</span>
									</div>

									<div className="tour-booking-item-info flex-wrap">
										<i className="far fa-calendar-alt"></i>
										<span className="mx-1">
											{moment(reservation?.start_date).format("DD/MM/YYYY")} (
											{moment(reservation?.start_date).format("dddd")})
										</span>
									</div>

									<div className="tour-booking-item-info flex-wrap">
										<i className="far fa-user"></i>
										<span className="mx-1">{reservation?.adults} adults</span>
										{reservation?.children?.length > 0 ? (
											<span>
												{reservation?.children?.length} children (
												{reservation?.children?.map((age, index) => {
													return (
														<span key={`age-${index}-${age}`}>
															{age}
															{index !== reservation?.children?.length - 1
																? ", "
																: " "}
														</span>
													);
												})}
												Years Old )
											</span>
										) : null}
									</div>
								</div>
								<span className="col font-weight-bold text-14">
									{(reservation?.details?.price).toFixed(2)}{" "}
									{reservation?.details?.currency}
								</span>
							</div>

							{index !== bookingDetails?.reservations?.length - 1 && <hr />}
						</div>
					))}
				</div>
			) : null}
			<div className="">
					<p className="trans-dark-light mb-0">Reservation Total Cost</p>
					<p className="text-success fw-bold fs-5">
					{bookingDetails?.total_price?.toFixed(2)} {bookingDetails?.currency}
					</p>
				</div>

		</div>
	);
}
