export default function TotalPrice({ state, setState, isPackage }) {
	console.log("TotalPrice", state);
	return (
		<>
			<div className="module-list-header total-price">
				<div className="row mx-0 mt-2">
					<div className="col-md-9">
						<h4 className="mt-3">Total Price</h4>
					</div>

					<div className="col-md-3 modul-column total-price-item d-flex align-items-center">
						<input
							type="text"
							className="form-control w-75 text-center border-gray-1"
							value={state?.price}
							onChange={(e) => {
								setState({ ...state, price: e.target.value });
							}}
							disabled={isPackage}
						/>
						<span className="mx-2">{state?.currency}</span>
					</div>
				</div>
			</div>
		</>
	);
}
