import bankTranfertImg from "assets/images/wallet_gray.svg";
import { printVoucher } from "assets/prints/printVoucher";
import Pagination from "components/shared/Pagination";
import { useGlobalDispatch } from "context/global";
import createDOMPurify from "dompurify";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import {
	cancelHotelBooking,
	cancelRoom,
	confirmBookingHotels,
	fetchVoucher,
	getAllSpecialRequests,
	getListWallets,
	updateBooking,
} from "services/Request";
import Locale from "translations";
import EditReservation from "./EditReservation";
import CustomModal from "./Modal";
import PassengerFilter from "./PassengerFilter";
import Passenger from "./passenger";

export default function Passangers({ passangerInfo, setReload }) {
	const dispatch = useGlobalDispatch();
	let history = useHistory();

	const data = passangerInfo?.payload?.rooms[0];

	const [filter, setFilter] = useState({});
	const [meta, setMeta] = useState();
	const [passengers, setPassengers] = useState([]);
	const { backOffice, booking, inventory } = Locale;
	// Handel Models
	const [cancelIsOpen, setcancelIsOpen] = useState(false);
	const [guestIsOpen, setGuestIsOpen] = useState(false);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [docIsOpen, setDocIsOpen] = useState(false);
	const [payIsOpen, setPayIsOpen] = useState(false);
	const DOMPurify = createDOMPurify(window);

	const [selectedGuests, setSelectedGuests] = useState(data?.passengers);
	const [listWallets, setListWallets] = useState([]);
	const [editState, setEditState] = useState({
		checkIn: passangerInfo?.start_date
			? moment(passangerInfo?.start_date)
			: moment(new Date()).add(1, "d"),
		checkOut: passangerInfo?.end_date
			? moment(passangerInfo.end_date)
			: moment(new Date()).add(2, "d"),
		adult: +passangerInfo?.adults,
		child: +passangerInfo?.children,
		roomType: null,
	});
	const [selected, setSelected] = useState({});
	const [childrenArray, setchildrenArray] = useState([]);
	const [swichToggle, setswichToggle] = useState(true);
	const [configData, setConfigData] = useState({});
	const [radio, setRadio] = useState();

	const handleSwitch = () => {
		setswichToggle(!swichToggle);
	};
	const listB = [
		{
			value: 0,
			label: "No Preference",
		},
		{
			value: 1,
			label: "King Size",
		},
		{
			value: 2,
			label: "Queen Size",
		},
		{
			value: 3,
			label: "Twin",
		},
	];
	useEffect(() => {
		setPassengers(passangerInfo?.payload.rooms);
	}, [data]);

	const toggleCancel = (cancel_type) => {
		setcancelIsOpen(!cancelIsOpen);
		setCancelType(typeof cancel_type === "string" ? cancel_type : null)
	};
	const toggleGuset = () => {
		setGuestIsOpen(!guestIsOpen);
	};
	const toggleEdit = () => {
		setEditIsOpen(!editIsOpen);
	};
	const toggleDoc = () => {
		setDocIsOpen(!docIsOpen);
	};
	const togglePay = () => {
		setPayIsOpen(!payIsOpen);
	};

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	useEffect(() => {
		setchildrenArray(selected.childrenAges);
		setEditState({
			...editState,
			adult: selected?.adults,
			checkIn: selected?.from_date
				? moment(selected.from_date)
				: moment(new Date()).add(1, "d"),
			checkOut: selected?.to_date
				? moment(selected.to_date)
				: moment(new Date()).add(2, "d"),
			child: selected.children,
			childrenArray: selected.childrenAges,
			maxOccupancy: selected.maxOccupancy,
			adultOption: selected?.maxAdult
				? [...Array(+selected?.maxAdult).keys()].map((i, index) => ({
						value: index + 1,
						label: index + 1,
				  }))
				: [],
			childOption: selected?.maxChildren
				? [...Array(+selected?.maxChildren).keys()].map((i, index) => ({
						value: index + 1,
						label: index + 1,
				  }))
				: [],
			rateOption: selected?.room_rates
				? selected?.room_rates.map((res) => ({
						...res,
						label: res.mealTitle + "   (" + res.room_total_price + ")    ",
						value: res.roomConfigurationId,
				  }))
				: [],
			BeddingOption: listB,
			beddingPreferenceName: selected?.beddingPreference
				? listB.filter((res) => +res.id == +selected?.beddingPreference)[0]
						?.name
				: "",
			mealOptionId: selected?.roomConfigurationId,
			mealOptionName: selected?.room_rates
				? selected?.room_rates.filter(
						(res) => +res?.mealOptionId == +selected?.mealOptionId
				  )[0]?.mealTitle
				: "",
			allowsSpecialRequests: selected.allowsSpecialRequests
				? selected?.allowsSpecialRequests
				: true,
			specialRequests: selected?.specialRequests,
			beddingPreference: selected?.beddingPreference,
		});
		debugger;
	}, [selected]);

	const [SpecialRequests, setSpecialRequests] = useState([]);

	useEffect(async () => {
		const SpecialRequests = await getAllSpecialRequests();

		if (SpecialRequests?.status == 200) {
			setSpecialRequests(SpecialRequests.data);
		}
	}, []);

	const requsetEdit = async () => {
		const Data = {
			brn: passangerInfo?.brn,
			date_from: moment(editState.checkIn).format("YYYY-MM-DD"),
			date_to: moment(editState.checkOut).format("YYYY-MM-DD"),
			bookingCode: selected.bookingCode,
			mealOptionId: editState.mealOptionId?.toString(),
			provider_id: passangerInfo?.gds,
			company_id: passangerInfo?.company_id,
			number_of_adult: editState.adult,
			beddingPreference: editState.beddingPreference?.toString()
				? editState.beddingPreference?.toString()
				: "0",
			children:
				editState.childrenArray && editState.childrenArray.length > 0
					? editState.childrenArray
					: [],
			special_requests:
				editState.specialRequests && editState.specialRequests.length > 0
					? editState.specialRequests
					: [],
		};
		let book;
		book = await updateBooking(Data);
		if (book.status === 200) {
			//setRejectModal(false);
			//setModalEdit(false);
			toggleEdit();
			setConfigData(book.data);
			//setModalPay(true);
			togglePay();
			async function fetchSafaWallet() {
				setListWallets([]);
				const res = await getListWallets({
					company_id: passangerInfo?.company_id,
					currency: book.data.rate_types[0].currency,
				});

				if (res.status == 200) {
					setListWallets(res?.data?.data);
				}
			}
			fetchSafaWallet();
		} else {
			if (book.status == 400) {
				store.addNotification({
					title: "error!",
					message: "Error!",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
			setEditIsOpen(false);
			//toggleRejectModal();
		}
	};
	const payAfterEdit = async () => {
		const Data = {
			brn: passangerInfo.brn,
			company_id: passangerInfo?.company_id,
			beddingPreference: editState.beddingPreference
				? editState.beddingPreference.toString()
				: "0",
			date_from: moment(editState.checkIn).format("YYYY-MM-DD"),
			date_to: moment(editState.checkOut).format("YYYY-MM-DD"),
			bookingCode: selected.bookingCode,
			mealOptionId: editState.mealOptionId.toString(),
			provider_id: 4,
			number_of_adult: editState.adult,
			children:
				editState.childrenArray && editState.childrenArray.length > 0
					? editState.childrenArray
					: [],
			special_requests:
				editState.specialRequests && editState.specialRequests.length > 0
					? editState.specialRequests
					: [],

			confirmData: {
				new_price: configData.rate_types[0].new_price,
				allocation_details: configData.rate_types[0].allocation_details,
				amend_charge: configData.rate_types[0].paid_amend_charge,
			},
			payment_method: radio,
		};
		let book;
		book = await confirmBookingHotels(Data);

		if (book.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "updated successfully!",
				type: "info",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			togglePay();
			// fetchSafaWallet();
			// fetchData();
			// togglePayModal();
		}
	};
	const [cancellationReason, setCancellationReason] = useState("");
	const [RoomsList, setRoomsList] = useState(passangerInfo?.payload?.rooms);
	const [cancelType, setCancelType] = useState(null)

	const cancelBooking = async () => {
		let cancel;
		const Data = {
			brn: passangerInfo.brn,
			bookingCode: selected.bookingCode,
			cancellationReason: cancellationReason,
		};
		if ((passangerInfo?.gds === 3
				|| passangerInfo?.gds === 4 
				|| passangerInfo?.gds === 5
				|| passangerInfo?.gds === 6
				|| passangerInfo?.gds === 9
				|| passangerInfo?.gds === 11
				|| passangerInfo?.gds === 12
			) && cancelType === "booking") {
			cancel = await cancelHotelBooking(Data);
		} else {
			cancel = await cancelRoom(Data);
		}

		if (cancel.status === 200) {
			toggleCancel();
			store.addNotification({
				title: "Info!",
				message: "Cancal Reservation successfully!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
				},
			});
		}
	};

	const voucherData = async (romeKey) => {
		const res = await fetchVoucher(passangerInfo?.brn, romeKey);
		if (res.status == 200) {
			// dispatch({ type: "getVoucher", payload: res?.data?.data });
			// history.push("/inventory-voucher");

			const voucher = res?.data?.data;

			printVoucher(voucher, inventory);
		}
	};
	const allPassengersList = useMemo(() => {
		return passengers?.length > 0
			? passengers?.map((data, index) => {
					return (
						<Passenger
							index={index}
							passenger={data}
							data={passangerInfo}
							passangerInfo={data}
							toggleCancel={toggleCancel}
							toggleGuset={toggleGuset}
							toggleEdit={toggleEdit}
							toggleDoc={toggleDoc}
							setSelected={setSelected}
							voucherData={voucherData}
							status={passangerInfo?.reservation_status}
						/>
					);
			  })
			: [];
	}, [passengers]);
	const setValueSpecialRequests = (checked, value) => {
		let x =
			editState.specialRequests && editState.specialRequests.length > 0
				? [...editState.specialRequests]
				: [];
		if (checked) {
			x.push({ req: value });
		} else {
			x = x.filter((res) => res.req != value);
		}
		setEditState({
			...editState,
			specialRequests: x,
		});
	};
	const setNewChildrenQuantity = (e) => {
		let x = [...Array(+e).keys()].map((i) => ({
			age: 0,
		}));
		setEditState({ ...editState, childrenArray: x, child: e });
	};
	const changeAge = (index, e) => {
		let x = editState.childrenArray;
		x[index] = { age: e.target.value };
		setEditState({ ...editState, childrenArray: x });
	};
	const ToDay = moment().format("yyyy-MM-DD");

	return (
		<>
			<div className="border">
				<div>
					<PassengerFilter filter={filter} setFilter={setFilter} />
				</div>

				{(passangerInfo?.gds === 3
				|| passangerInfo?.gds === 4 
				|| passangerInfo?.gds === 5
				|| passangerInfo?.gds === 6
				|| passangerInfo?.gds === 9
				|| passangerInfo?.gds === 11
				|| passangerInfo?.gds === 12) &&
				passangerInfo?.reservation_status !== "expired" &&
				passangerInfo?.reservation_status !== "canceled" && +passangerInfo?.updated_price === 0  && passangerInfo?.payment_status !== "pending"  ? (
					<div className="d-flex justify-content-end">
						<ShowForPermission permission="Hotels-Bookings-Manage">
							<button
								className="btn btn-secondary mx-3"
								style={{ backgroundColor: "#FF6868" }}
								onClick={() => {
									toggleCancel("booking");
									setSelected({
										...passangerInfo,
										room_price_currency: passangerInfo?.currency,
									});
								}}
							>
								<i class="fas fa-ban text-white right"></i> Cancel
							</button>
						</ShowForPermission>
					</div>
				) : (
					""
				)}

				<div className=" table-responsive">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">
								<th>{booking.RoomNo}</th>
								<th>{booking.Arrival}</th>
								<th>{booking.Departure}</th>
								<th>{booking.PassengerName}</th>
								<th>{booking.RoomType}</th>
								<th>{booking.Adult}</th>
								<th>{booking.Child}</th>
								<th>{booking.BoardBasis}</th>
								<th>{booking.Price}</th>
								<th>{booking.Actions}</th>
							</tr>
						</thead>

						<tbody>
							{allPassengersList.length > 0 ? (
								allPassengersList
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<div className="px-2">
						<Pagination info={meta} goTo={goTo} />
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={cancelIsOpen}
				header={booking.CancelReservation}
				toggle={toggleCancel}
				size={"md"}
				centered={true}
			>
				<div className=" col-md-12">
					<div className="p-2 row">
						{selected?.cancellation_rules?.map((res) => {
							return (
								<>
									{!res.hasOwnProperty("fromDate") ? (
										<>
											<p className="w-100 mt-2">
												{booking.messages.before} {res.toDate}
											</p>
											<div className="col-md-6 ">
												{res.cancelCharge == 0 ? (
													<div className="boxCancellation bg-Ground">
														<p>{booking.messages.noCancellationCharge}</p>
													</div>
												) : res.cancelRestricted ? (
													<div className="boxCancellation bg-dangerr">
														<p>{booking.messages.cancellationRestricted}</p>
													</div>
												) : (
													<div className="boxCancellation">
														{booking.messages.cancellationCharge}
														<p>
															{" "}
															{res.cancelCharge}
															{""}
															{selected?.room_price_currency}
														</p>
													</div>
												)}
											</div>

											<div className="col-md-6 ">
												{res.amendCharge == 0 ? (
													<div className="boxCancellation bg-Ground">
														<p> {booking.messages.noAmendCharge}</p>
													</div>
												) : res.amendRestricted ? (
													<div className="boxCancellation bg-dangerr">
														{booking.messages.amendRestricted}
													</div>
												) : (
													<div className="boxCancellation">
														<p>{booking.messages.amendmentCharge}</p>
														{res.amendCharge}
														{selected?.room_price_currency}
													</div>
												)}
											</div>
										</>
									) : !res.hasOwnProperty("toDate") &&
									  !res.hasOwnProperty("noShowPolicy") ? (
										<>
											<p className="w-100 mt-3">
												{booking.messages.after} {res.fromDate}
											</p>
											<div className="col-md-6">
												{res.cancelCharge == 0 ? (
													<div className="boxCancellation bg-Ground">
														<p>{booking.messages.noCancellationCharge}</p>
													</div>
												) : res.cancelRestricted ? (
													<div className="boxCancellation bg-dangerr">
														<p>{booking.messages.cancellationRestricted}</p>
													</div>
												) : (
													<div className="boxCancellation">
														{booking.messages.cancellationCharge}
														<p>
															{" "}
															{res.cancelCharge} {selected?.room_price_currency}
														</p>
													</div>
												)}
											</div>
											<div className="col-md-6 ">
												{res.amendCharge == 0 ? (
													<div className="boxCancellation bg-Ground">
														<p>{booking.messages.noAmendCharge}</p>
													</div>
												) : res.amendRestricted ? (
													<div className="boxCancellation bg-dangerr">
														<p> {booking.messages.amendRestricted}</p>
													</div>
												) : (
													<div className="boxCancellation">
														<p> {booking.messages.amendmentCharge}</p>
														{res.amendCharge} {selected.room_price_currency}
													</div>
												)}
											</div>
										</>
									) : res.hasOwnProperty("noShowPolicy") ? (
										<>
											<p className="w-100 mt-3">
												{" "}
												{booking.messages.after} {res.fromDate}
											</p>

											<div className="col-md-12">
												<div className="boxCancellation bg-dangerNew ">
													<p>{booking.messages.noShowPolicyCharge}</p>
													{res.charge} {selected.room_price_currency}
												</div>
											</div>
										</>
									) : (
										<>
											<p className="w-100 mt-3">
												{" "}
												between {res.fromDate} and {res.toDate}
											</p>
											<div className="col-md-6">
												{res.cancelCharge == 0 ? (
													<div className="boxCancellation bg-Ground">
														<p>{booking.messages.noAmendCharge}</p>
													</div>
												) : res.cancelRestricted ? (
													<div className="boxCancellation bg-dangerr">
														<p>{booking.messages.cancellationRestricted}</p>{" "}
													</div>
												) : (
													<div className="boxCancellation">
														<p>{booking.messages.cancellationCharge}</p>
														<p>
															{" "}
															{res.cancelCharge} {selected?.room_price_currency}
														</p>
													</div>
												)}
											</div>
											<div className="col-md-6 ">
												{res.amendCharge == 0 ? (
													<div className="boxCancellation bg-Ground">
														<p>{booking.messages.noCancellationCharge}</p>
													</div>
												) : res.amendRestricted ? (
													<div className="boxCancellation bg-dangerr">
														<p>{booking.messages.amendRestricted}</p>
													</div>
												) : (
													<div className="boxCancellation">
														<p>{booking.messages.amendmentCharge}</p>
														<p>
															{" "}
															{res.amendCharge} {selected?.room_price_currency}
														</p>
													</div>
												)}
											</div>
										</>
									)}
								</>
							);
						})}
						<div className="col-md-12 mt-2">
							<textarea
								className="w-100 p-3 my-2"
								label={booking.messages.cancellationReason}
								placeholder={booking.messages.cancellationReason}
								value={cancellationReason}
								onChange={(e) => setCancellationReason(e.target.value)}
							/>
						</div>
						<button
							style={{ backgroundColor: "#FF6868" }}
							className="btn companies-btn w-100"
							onClick={() => cancelBooking()}
						>
							CANCEL RESERVATION
						</button>
					</div>
				</div>
			</CustomModal>

			<CustomModal
				modalIsOpen={guestIsOpen}
				header={booking.GuestDetails}
				toggle={toggleGuset}
				size={"md"}
				centered={true}
			>
				<div className=" table-responsive">
					<table className=" back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">
								<th>{booking.Title}</th>
								<th>{booking.FirstName}</th>
								<th>{booking.LastName}</th>
							</tr>
						</thead>
						<tbody>
							{selected?.passengers?.length > 0 ? (
								selected?.passengers.map((item, index) => (
									<tr>
										<td>{item.salutation}</td>
										<td>{item.first_name}</td>
										<td>{item.last_name}</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</CustomModal>
			<CustomModal
				modalIsOpen={docIsOpen}
				header={booking.cancelDoc}
				toggle={toggleDoc}
				size={"md"}
				centered={true}
			>
				<div className=" col-md-12">
					<div className="p-2 row">
						<h6 className="h5 font-weight-bold">
							{inventory.messages.cancellationPolicy}
						</h6>
						<div className="col-12">
							{typeof selected?.cancellation_rules === "string" ? (
								<div className="col-md-12 text-right">
									<div
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(selected?.cancellation_rules),
										}}
									/>
								</div>
							) : (
								<div className=" row col-md-12">
									{selected?.cancellation_rules?.map((res) => {
										return (
											<>
												{!res.hasOwnProperty("fromDate") ? (
													<>
														<p className="w-100 mt-2">
															{inventory.messages.before} {res.toDate}
														</p>
														<div className="col-md-6">
															{res.cancelCharge == 0 ? (
																<div className="boxCancellation bg-Ground">
																	<p>
																		{inventory.messages.noCancellationCharge}
																	</p>
																</div>
															) : res.cancelRestricted ? (
																<div className="boxCancellation bg-dangerr">
																	<p>
																		{inventory.messages.cancellationRestricted}
																	</p>
																</div>
															) : (
																<div className="boxCancellation">
																	{inventory.messages.cancellationCharge}
																	<p>
																		{" "}
																		{res.cancelCharge}{" "}
																		{selected?.room_price_currency}
																	</p>
																</div>
															)}
														</div>
														<div className="col-md-6 ">
															{res.amendCharge == 0 ? (
																<div className="boxCancellation bg-Ground">
																	<p> {inventory.messages.noAmendCharge}</p>
																</div>
															) : res.amendRestricted ? (
																<div className="boxCancellation bg-dangerr">
																	{inventory.messages.amendRestricted}
																</div>
															) : (
																<div className="boxCancellation">
																	<p>{inventory.messages.amendmentCharge}</p>
																	{res.amendCharge}
																	{selected?.room_price_currency}
																</div>
															)}
														</div>
													</>
												) : !res.hasOwnProperty("toDate") &&
												  !res.hasOwnProperty("noShowPolicy") ? (
													<>
														<p className="w-100 mt-3">
															{inventory.messages.after} {res.fromDate}
														</p>
														<div className="col-md-6">
															{res.cancelCharge == 0 ? (
																<div className="boxCancellation bg-Ground">
																	<p>
																		{inventory.messages.noCancellationCharge}
																	</p>
																</div>
															) : res.cancelRestricted ? (
																<div className="boxCancellation bg-dangerr">
																	<p>
																		{inventory.messages.cancellationRestricted}
																	</p>
																</div>
															) : (
																<div className="boxCancellation">
																	{inventory.messages.cancellationCharge}
																	<p>
																		{" "}
																		{res.cancelCharge}{" "}
																		{selected?.room_price_currency}
																	</p>
																</div>
															)}
														</div>
														<div className="col-md-6 ">
															{res.amendCharge == 0 ? (
																<div className="boxCancellation bg-Ground">
																	<p>{inventory.messages.noAmendCharge}</p>
																</div>
															) : res.amendRestricted ? (
																<div className="boxCancellation bg-dangerr">
																	<p> {inventory.messages.amendRestricted}</p>
																</div>
															) : (
																<div className="boxCancellation">
																	<p className="m-0">
																		{" "}
																		{inventory.messages.amendmentCharge}
																	</p>
																	{res.amendCharge}{" "}
																	{selected.room_price_currency}
																</div>
															)}
														</div>
													</>
												) : res.hasOwnProperty("noShowPolicy") ? (
													<>
														<p className="w-100 mt-3">
															{" "}
															{inventory.messages.after} {res.fromDate}
														</p>

														<div className="col-md-12">
															<div className="boxCancellation bg-dangerNew ">
																<p>{inventory.messages.noShowPolicyCharge}</p>
																{res.charge} {selected.room_price_currency}
															</div>
														</div>
													</>
												) : (
													<>
														<p className="w-100 mt-3">
															{" "}
															between {res.fromDate} and {res.toDate}
														</p>
														<div className="col-md-6">
															{res.cancelCharge == 0 ? (
																<div className="boxCancellation bg-Ground">
																	<p>{inventory.messages.noAmendCharge}</p>
																</div>
															) : res.cancelRestricted ? (
																<div className="boxCancellation bg-dangerr">
																	<p>
																		{inventory.messages.cancellationRestricted}
																	</p>{" "}
																</div>
															) : (
																<div className="boxCancellation">
																	<p>{inventory.messages.cancellationCharge}</p>
																	<p>
																		{" "}
																		{res.cancelCharge}{" "}
																		{selected?.room_price_currency}
																	</p>
																</div>
															)}
														</div>
														<div className="col-md-6 ">
															{res.amendCharge == 0 ? (
																<div className="boxCancellation bg-Ground">
																	<p>
																		{inventory.messages.noCancellationCharge}
																	</p>
																</div>
															) : res.amendRestricted ? (
																<div className="boxCancellation bg-dangerr">
																	<p>{inventory.messages.amendRestricted}</p>
																</div>
															) : (
																<div className="boxCancellation">
																	<p>{inventory.messages.amendmentCharge}</p>
																	<p>
																		{" "}
																		{res.amendCharge}{" "}
																		{selected?.room_price_currency}
																	</p>
																</div>
															)}
														</div>
													</>
												)}
											</>
										);
									})}
								</div>
							)}
						</div>
					</div>
					{selected?.termsAndConditions != "" &&
					selected?.termsAndConditions != null ? (
						<div className="p-2 row">
							<p className="h5 font-weight-bold">
								{inventory.messages.termsAndConditions}
							</p>
							<div className="col-md-12 text-right">
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(selected?.termsAndConditions),
									}}
									style={{ textAlign: "start" }}
								/>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</CustomModal>

			<CustomModal
				modalIsOpen={editIsOpen}
				header={booking.EditReservation}
				toggle={toggleEdit}
				size={"md"}
				centered={true}
			>
				<EditReservation
					requsetEdit={requsetEdit}
					editState={editState}
					setEditState={setEditState}
					setValueSpecialRequests={setValueSpecialRequests}
					setNewChildrenQuantity={setNewChildrenQuantity}
					changeAge={changeAge}
					swichToggle={swichToggle}
					handleSwitch={handleSwitch}
					SpecialRequests={SpecialRequests}
				/>
			</CustomModal>
			{/**Pay Modal */}
			<CustomModal
				modalIsOpen={payIsOpen}
				header={booking.EditReservation}
				toggle={togglePay}
				size={"md"}
				centered={true}
			>
				<div className="container payModal">
					<div className=" d-flex justify-content-center ">
						<div className=" text-center payment-box p-3">
							<p>{booking.messages.totalNewPrice}</p>
							<p className="font-weight-bold">
								{configData &&
								configData.rate_types &&
								configData.rate_types.length > 0
									? configData?.rate_types[0]?.new_price.toFixed(2)
									: ""}{" "}
								{configData.rate_types && configData.rate_types.length > 0
									? configData?.rate_types[0]?.currency
									: null}
							</p>
						</div>
						<div className=" text-center  payment-box p-3 mx-1">
							<p>{booking.messages.amendmentCharge}</p>
							<p className="font-weight-bold">
								{configData?.rate_types && configData?.rate_types.length > 0
									? configData?.rate_types[0]?.paid_amend_charge.toFixed(2)
									: ""}{" "}
								{configData.rate_types && configData.rate_types.length > 0
									? configData?.rate_types[0]?.currency
									: null}
							</p>
						</div>
						<div className=" text-center must-box p-3">
							<p>{booking.messages.mustPay}</p>
							<p className="font-weight-bold">
								{configData?.rate_types && configData?.rate_types.length > 0
									? configData?.rate_types[0]?.paid_deduct_amount.toFixed(2)
									: ""}{" "}
								{configData.rate_types && configData.rate_types.length > 0
									? configData?.rate_types[0]?.currency
									: null}
							</p>
						</div>
						{configData?.rate_types &&
						configData?.rate_types[0]?.paid_refund_amount &&
						configData?.rate_types[0]?.paid_refund_amount > 0 ? (
							<div className=" text-center  payment-box p-3 mx-3">
								<p>{booking.messages.refundAmount}</p>
								<p className="font-weight-bold">
									{configData?.rate_types && configData?.rate_types.length > 0
										? configData?.rate_types[0]?.paid_refund_amount.toFixed(2)
										: ""}{" "}
									{configData.rate_types && configData.rate_types.length > 0
										? configData?.rate_types[0]?.currency
										: null}
								</p>
							</div>
						) : (
							""
						)}
					</div>
					<div className="d-flex mt-3">
						{/* {configData?.rate_types &&
						+configData?.rate_types[0].refund_amount === 0 ? (
							<div
								className={`radio-control box-radio ${
									radio == "online" ? "border-active" : ""
								}`}
							>
								<input
									className="mx-1"
									type="radio"
									name="RechargeRadios"
									id="radios1"
									value="online"
									onChange={(e) => {
										setRadio(e.target.value);
									}}
								/>
						
								<img src={masterCardImg} alt="master card" />
								<p>Pay From Credit Card</p>
							</div>
						) : null} */}
						{listWallets.map((res) => {
							return (
								<>
									{res.type == "debit" ? (
										<div
											className={`radio-control box-radio mx-2 ${
												radio == "debit" ? "border-active" : ""
											}`}
										>
											<input
												className="mx-1"
												type="radio"
												name="RechargeRadios"
												id="radios2"
												value="debit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											{/* <label htmlFor="payRadios2">
									{payment.messages.bankTranfer}
								</label> */}
											<img src={bankTranfertImg} alt="master card" />
											<p>Pay From My Wallet Balance</p>
										</div>
									) : (
										<div
											className={`radio-control box-radio ${
												radio == "credit" ? "border-active" : ""
											}`}
										>
											<input
												className="mx-1"
												type="radio"
												name="RechargeRadios"
												id="radios3"
												value="credit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											{/* <label htmlFor="payRadios2">
											{payment.messages.bankTranfer}
										</label> */}
											{/* <img src={Group1068} alt="master card" /> */}
											<p>Pay From Safa Wallet Balance</p>
										</div>
									)}
								</>
							);
						})}
					</div>
					<button
						className="btn bg-nxt w-100 btn-pay-disabled"
						onClick={() => {
							payAfterEdit();
						}}
						disabled={
							!(radio == "online" || radio == "debit" || radio == "credit")
						}
					>
						PAY
					</button>
				</div>
			</CustomModal>
		</>
	);
}
