import moment from "moment";
import { useState } from "react";
import { Collapse } from "reactstrap";

export default function FlightSummarySegmensts({ journyData, segments }) {
	const [isopen, setisopen] = useState(false);

	function toHoursAndMinutes(totalMinutes) {
		const hours = Math.floor(+totalMinutes / 60);
		const minutes = +totalMinutes % 60;
		return `${hours}h ${minutes}m`;
	}

	//const segments = journyData?.tickets;
	const departureSegment = segments?.[0];
	const arrivaleSegment = segments?.[segments?.length - 1];
	const departureTime = moment(journyData?.departure_date).format("HH:mm");
	const arrivalTime = moment(journyData?.arrival_date).format("HH:mm");

	const flightsNumbers = segments.reduce(
		(prev, current, index) =>
			prev +
			(index === 0 ? "" : " | ") +
			current?.airline?.code +
			current?.flight_num,
		""
	);

	return (
		<>
			<div className="flight_item my-3  border-light w-95 mx-auto">
				<div className="head-toggel">
					<button
						className="btn-toggel btn"
						onClick={() => {
							setisopen(!isopen);
						}}
					>
						{isopen ? (
							<>
								Hide <i class="fas fa-angle-up"></i>
							</>
						) : (
							<>
								Details
								<i class="fas fa-angle-down"></i>
							</>
						)}
					</button>
				</div>
				{/* general journy data */}
				<div className="time-line">
					<div className="infoAir">
						<h5 className="takeoff mb-0">{departureTime}</h5>
						<p className="airport-name">
							<span className="blue">{departureSegment?.departure?.code} </span>{" "}
							<span>{departureSegment?.departure?.name}</span>
						</p>
						<p className="airport-name">
							Departure Terminal{" "}
							<span className="blue">
								{departureSegment?.departure_terminal}
							</span>{" "}
						</p>
					</div>
					<div className="text-center">
						<i class="fas fa-plane-departure" style={{ color: "#1A61AF" }}></i>
						<p className="spent">
							{toHoursAndMinutes(journyData?.journey_time)}{" "}
							<span className="blue">
								{journyData?.stops_count > 0
									? journyData?.stops_count + " Stop "
									: "Non-stop"}
							</span>
						</p>
					</div>
					<div className="Landing">
						<h5 className="takeoff mb-0">{arrivalTime}</h5>
						<p className="airport-name">
							<span className="blue">{arrivaleSegment?.arrival?.code} </span>{" "}
							<span>{arrivaleSegment?.arrival?.name}</span>
						</p>
						<p className="airport-name">
							Arrival Terminal{" "}
							<span className="blue">{arrivaleSegment?.arrival_terminal}</span>{" "}
						</p>
					</div>
				</div>

				<Collapse isOpen={isopen}>
					{segments.map((segment, index) => {
						const stopTime = moment(
							`${segments[index + 1]?.departure_date}`
						).diff(`${segment?.arrival_date}`, "m");
						return (
							<div className="mx-4 collapse-box p-2">
								<div className="d-flex align-items-center   justify-content-between">
									<div className="d-flex align-items-center px-1">
										<img
											className="img-company mr-2"
											src={segment?.airline?.logo}
											alt=""
										/>
										<div className="mx-1">
											<p className="takeoff">{segment?.airline?.name}</p>
											<p style={{ fontSize: "14px" }}>
												{segment?.airline?.code + segment?.flight_num}
											</p>
										</div>
									</div>
									<div style={{ fontSize: "14px" }}>
										{segment.ticket_number && (
											<>
												<span className="font-weight-bold">Ticket No:</span>{" "}
												<span>{segment.ticket_number}</span>
											</>
										)}
										{segment.pnr && (
											<>
												<span className="m-2">/</span>{" "}
												<span className="font-weight-bold">PNR:</span>{" "}
												<span>{segment.pnr}</span>
											</>
										)}
									</div>
								</div>

								<div className="time-line">
									<div className="infoAir">
										<p className="airport-name">
											<span className="blue">{segment?.departure?.code}</span>{" "}
											<span>{segment?.departure?.name}</span>
										</p>
										<span className="all-date">
											{moment(segment?.departure_date).format(
												"DD MMM YYYY HH:MM ddd"
											)}
										</span>
										<p className="airport-name">
											Departure Terminal{" "}
											<span className="blue">
												{segment?.departure_terminal}
											</span>{" "}
										</p>
									</div>

									<div className="text-center">
										<p className="spent">
											{toHoursAndMinutes(segment.flight_time)}
										</p>
									</div>

									{/* arrival airport */}
									<div className="Landing">
										<p className="airport-name">
											<span className="blue">{segment?.arrival?.code}</span>{" "}
											<span>{segment?.arrival?.name}</span>
										</p>
										<span className="all-date">
											{moment(segment?.arrival_date).format(
												"DD MMM YYYY HH:MM ddd"
											)}
										</span>
										<p className="airport-name">
											Arrival Terminal{" "}
											<span className="blue">{segment?.arrival_terminal}</span>{" "}
										</p>
									</div>
								</div>

								{segments?.length > 1 && index < segments?.length - 1 && (
									<div className="mx-2 stop-box">
										<p
											className="pl-2"
											style={{ display: "inline-block", width: "43%" }}
										>
											<span>Stop </span>
											<span className="blue">{segment?.arrival?.name}</span>
										</p>
										<span className="stop">{toHoursAndMinutes(stopTime)} </span>
									</div>
								)}
							</div>
						);
					})}
				</Collapse>

				<div className="px-2">
					<div className="footer-toogle">
						<div className="d-flex justify-content-start align-items-center">
							<img
								className="img-company"
								src={departureSegment?.airline?.logo}
								alt=""
							/>
							<div className="mx-1">
								<p className="takeoff">{departureSegment?.airline.name}</p>
								<p style={{ fontSize: "14px" }}>{flightsNumbers}</p>
							</div>
						</div>

						<div className="d-flex justify-content-start align-items-center ">
							{segments?.map((segment, index) => {
								return (
									<p
										className="px-1"
										style={{
											backgroundColor: "#EBEBEB",
											borderRadius: "2px",
											marginInlineEnd: "4px",
										}}
										key={segment.segmentId}
									>
										{segment?.equipment}
									</p>
								);
							})}
						</div>

						<div className="d-flex justify-content-end align-items-center ">
							<p>{departureSegment?.cabinClass}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
