import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { listPlatforms } from "services/platform";
import PlatformCommissionsFilter from "../Components/PlatformCommessions/PlatformCommissionsList/PlatformCommissionsFilter";
import PlatformCommissionsTable from "../Components/PlatformCommessions/PlatformCommissionsList/PlatformCommissionsTable";

import ShowForPermission from 'helpers/showForPermission';
import moment from 'moment';
import Locale from "translations";

export default function PlatformCommissionList() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [PlatformList, setPlatformList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filterState, setFilterState] = useState({
		platformName: "",
		date: null,
		country: "",
		status: null,
	});


	async function fetchData() {
		const data = {
			name: filterState.platformName,
			contract_start_date: filterState.date
				? moment(filterState.date).format("YYYY-MM-DD")
				: null,
			country_id: filterState.country?.value,
			is_active: filterState.status?.value,
		};
		const res = await listPlatforms(data, pages);
		setPlatformList(res?.data.platforms);
	}
	useEffect(() => {
		fetchData();
	}, [
		filterState.platformName,
		filterState.date,
		filterState.country,
		filterState.status,
	]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className="main-title ">{backOffice.platformCommissions}</h6>

				<ShowForPermission permission="Platform-Commission-Manage">
					<Link className="btn btn-success" to={`/add-platform`}>
						<i class="fas fa-plus-circle"></i> {backOffice.addNewPlatform}
					</Link>
				</ShowForPermission>
			</div>
			<PlatformCommissionsFilter
				filterState={filterState}
				setFilterState={setFilterState}
			/>
			<PlatformCommissionsTable
				PlatformList={PlatformList}
				fetchData={fetchData}
				goTo={goTo}
				meta={meta}
			/>
		</>
	);
}
