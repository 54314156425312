import Info from "./Info";
import Rooms from "./Rooms";

function Details(props) {
	const {
		details,
		handleReservationState,
		errors,
		setErrors,
		edit,
		Reload,
		setReload,
	} = props;

	return (
		<div id="reserv-details">
			<Info
				errors={errors}
				setErrors={setErrors}
				info={details}
				infoHandler={handleReservationState}
				edit={edit}
				Reload={Reload}
				setReload={setReload}
			/>
			<Rooms
				errors={errors}
				setErrors={setErrors}
				details={details}
				roomsHandler={handleReservationState}
				edit={edit}
			/>
		</div>
	);
}

export default Details;
