import Locale from "translations";

export default function PassengerDetails({
	name,
	paxDetails,
	index,
	bookingDetails,
}) {
	const { backOffice } = Locale;

	let isDmmyData =
		paxDetails?.filter((item) => item?.name?.includes("XXX"))?.length > 0;
	console.log("isDmmyData", isDmmyData);
	return (
		<div>
			<h6 className="text-dark-blue">Passenger details</h6>
			<div className=" table-responsive light-border">
				<div className="bg-light-gray d-flex justify-content-between p-2 text-dark-blue fw-bold">
					<p>{name}</p>
					{!isDmmyData && (
						<p>
							{paxDetails?.filter((item) => item.age > 18)?.length} Adults ,{" "}
							{paxDetails?.filter((item) => item.age < 18)?.length} Children
						</p>
					)}
				</div>

				<table className="custom-table back-office-table table bg-white mb-0">
					<thead>
						<tr className="text-nowrap">
							<th>Type</th>
							<th>Name</th>
							<th>Surname</th>
							<th>Mobile/Cell</th>
							<th>Email</th>
						</tr>
					</thead>
					<tbody>
						{paxDetails?.length > 0 ? (
							!isDmmyData ? (
								paxDetails?.map((item) => (
									<tr>
										{/* <td>{+item?.age > 18 ? "Adult" : "Child"}</td> */}
										<td>
											{item?.paxType == "AD" || item?.type == "ADULT"
												? "Adult"
												: "Child"}
										</td>
										<td>{item?.name}</td>
										<td>{item?.surname}</td>
										<td>-</td>
										<td>-</td>
									</tr>
								))
							) : (
								<tr>
									<td>Adult</td>
									<td>{bookingDetails?.leader_name?.split(" ")?.[0]}</td>
									<td>{bookingDetails?.leader_name?.split(" ")?.[1]}</td>
									<td>-</td>
									<td>-</td>
								</tr>
							)
						) : (
							<tr>
								<td colSpan="5">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}
