import DatePickerField from "components/shared/DatePickerField";
import TextField from "components/shared/TextField";
import TimePickerField from "components/shared/TimePickerField";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";
import { Modal, ModalBody } from "reactstrap";
import { DeleteIcon } from "../Icons/deleteIcon";

export default function ConfigureCalender(props) {
	const {
		isOpen,
		toggle,
		state,
		setState,
		handleTime,
		handleAddNewTime,
		calenderBulk,
	} = props;
	const disabledBtn = () => {
		if (
			state.date_from !== "" &&
			state.date_to !== "" &&
			state.price_per_pax !== "" &&
			state.max_pax !== "" &&
			!moment(state.date_from).isSame(moment(state.date_to))
		)
			return false;
		return true;
	};
	const removeTime = (index) => {
		debugger;
		const clone = state?.times;
		clone.splice(index, 1);
		setState((current) => ({ ...current, times: clone }));
	};
	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className="configure-calender-attraction-modal"
		>
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<div className="w-90 title">
					<p className=" text-secondary text-center">Configure</p>
				</div>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3 ">
				<div className=" m-0 px-4 w-100">
					<div className="mx-1">
						<DatePickerField
							date={state.date_from}
							placeholder={"DD/MM/YYYY"}
							label={"From"}
							onChangeDate={(date) => {
								setState({
									...state,
									date_from: date,
									date_to: moment(state.date_to).isAfter(
										moment(date).add(1, "d")
									)
										? state.date_to
										: moment(date).add(1, "d"),
								});
							}}
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
						/>
					</div>
					<div className="mx-1">
						<DatePickerField
							date={state.date_to}
							placeholder={"DD/MM/YYYY"}
							label={"To"}
							onChangeDate={(date) => {
								setState({
									...state,
									date_to: date,
								});
							}}
							isOutsideRange={(day) => {
								return day.isBefore(
									moment(state.date_from).add(1, "day"),
									"day"
								);
							}}
						/>
						<p className="text-danger">
							{moment(state.date_from).isSame(moment(state.date_to))
								? "* The date to field must be a date after date from."
								: null}
						</p>
					</div>
					<div>
						<TextField
							type="text"
							placeholder={"Price"}
							label={"Price"}
							value={state.price_per_pax}
							onChange={(e) => {
								setState({
									...state,
									price_per_pax: e.target.value,
								});
							}}
						/>
					</div>
					<div>
						<TextField
							type="text"
							placeholder={"Pax"}
							label={"Pax"}
							value={state.max_pax}
							onChange={(e) => {
								setState({
									...state,
									max_pax: e.target.value,
								});
							}}
						/>
					</div>

					<div>
						{state.times.map((time, index) => (
							<div className="time-confige  d-flex ">
								<TimePickerField
									label={`Time ${index > 0 ? index + 1 : ""}`}
									fromValue={time}
									onFromChange={(e) => {
										handleTime(e, index);
									}}
									name="time"
									hourPlaceholder="00"
									minutePlaceholder="00"
									format="hh:mm a"
								/>
								{index != 0 && (
									<button
										className="btn btn-link mt-4"
										onClick={() => removeTime(index)}
									>
										<DeleteIcon />
									</button>
								)}
							</div>
						))}
						<button className="btn btn-add" onClick={handleAddNewTime}>
							{/* <PlusIcon color={"#6A4D1B"} />  */}
							<i className="fas fa-plus" /> Add New
						</button>
					</div>
				</div>
			</ModalBody>
			<div className="w-100 pb-5 border-top-0  text-center px-4 mt-2">
				<button
					className="btn rounded companies-btn text-white   w-100"
					onClick={calenderBulk}
					disabled={disabledBtn() ? true : false}
				>
					Apply
				</button>
			</div>
		</Modal>
	);
}
