import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { getNotification } from "helpers/makeNotifications";
// import ShowForPermission from "helpers/showForPermission";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ModalFooter } from "react-bootstrap";
import { store } from "react-notifications-component";
import { Link, NavLink, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
	assginSuppliersOnlineVisa,
	attachOnlineVisaToOfflineSupplier,
	exportToWaitingVisas,
	listOfflineSuppliers,
	listOnlineVisaRequests,
	listSuppliersOnlineVisa,
} from "services/Request";
import Locale from "translations";
import Filter from "./Filter";
import CustomModal from "./Modal";

export default function OnlineVisaRequests() {
	const { requests, backOffice } = Locale;
	const { locale } = useGlobalState();
	const { status } = useParams();
	const isWaiting = status === "waiting";
	const [ModalEdit, setModalEdit] = useState(false);
	const [lookupsList, setLookupsList] = useState({
		visaTypes: [],
		sources: [],
	});

	const [addEditSupplierModal, setAddEditSupplierModal] = useState({
		isOpen: false,
		isEdit: false,
	});

	const [offlineSuppliers, setOfflineSuppliers] = useState([]);
	const [selectedOfflineSupplier, setSelectedOfflineSupplier] = useState(null);
	const [allVisa, setAllVisa] = useState([]);
	const [ListSuppliers, setListSuppliers] = useState([]);
	const [assginData, setAssginData] = useState();
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		page: null,
		company_name: "",
		destination_id: null,
		visa_type_id: null,
		request_date: null,
		source: null,
		offline_supplier_id: null,
	});
	const toggleEdit = (id = null) => {
		setModalEdit(!ModalEdit);
		setAssginData({
			...assginData,
			id,
		});
	};

	const [nationalityModal, setNationalityModal] = useState(false);
	const [selectPassengerItem, setSelectPassengerItem] = useState(null);
	function toggleNationalityModal(data) {
		setNationalityModal((cur) => !cur);
		setSelectPassengerItem(data);
	}

	const allVisaList = useMemo(() => {
		return allVisa?.length > 0
			? allVisa.map((visa) => {
					return (
						<tr className="text-nowrap" key={visa.id}>
							<td>{visa.id}</td>
							<td>{visa.company_name}</td>
							<td className="text-uppercase">{visa.source}</td>
							<td>{visa.destination}</td>
							{/* <td>{visa.transportation_type}</td> */}
							<td>{visa.visa_type}</td>
							<td>{visa.pax}</td>
							<td>
								<div>
									<p className="m-0 text-success text-nowrap">
										{visa.departure_date}
									</p>
									<p className="m-0 text-danger text-nowrap">
										{visa.return_date}
									</p>
								</div>
							</td>
							<td>{visa.request_date}</td>
							<td>
								{" "}
								{visa.currency} {visa.total}
							</td>
							{isWaiting && (
								<td>
									{visa?.passengers?.length > 0 &&
									visa?.passengers.length === 1 ? (
										visa?.passengers[0]
									) : (
										<button
											className="btn btn-link border"
											onClick={() => toggleNationalityModal(visa?.passengers)}
										>
											View
										</button>
									)}
								</td>
							)}
							<td>
								{visa.is_umrah
									? visa.rawda_reservation_document
										? "Uploaded"
										: "Not Uploaded"
									: "--"}
								{}
							</td>
							<td>
								{visa.rawda_date
									? moment(visa?.rawda_date).format("YYYY-MM-DD")
									: "--"}
							</td>
							{/* {status === "waiting" ? (
					return (
						<tr className="text-nowrap" key={visa.id}>
							<td>{visa.id}</td>
							<td>{visa.company_name}</td>
							<td>{visa.destination}</td>
							{/* <td>{visa.transportation_type}</td> */}
							{/* {status === "waiting" ? (
								<td>
									<div className="d-flex justify-content-between">
										<div className="w-50">
											{" "}
											{visa.supplier_name ? visa.supplier_name : "-"}
										</div> */}
							{/* <ShowForPermission permission="Visa-Requests-Manage">
											<i
												onClick={() => toggleEdit(visa.id)}
												class="fas fa-edit text-primary pointer mx-2"
											></i>
										</ShowForPermission> */}
							{/* </div>
								</td>
							) : (
								<td>
									<div className="d-flex justify-content-between">
										<div className="w-50">
											{" "}
											{visa.supplier_name ? visa.supplier_name : "-"}
										</div>
									</div>
								</td>
							)} */}

							<td>
								{visa?.offline_supplier_id && visa?.offline_supplier_name ? (
									<>
										{visa.offline_supplier_name}
										<i
											onClick={() => {
												setAddEditSupplierModal({
													isOpen: true,
													isEdit: true,
													id: visa.id,
												});

												setSelectedOfflineSupplier({
													value: visa.offline_supplier_id,
													label: visa.offline_supplier_name,
												});
											}}
											class="fas fa-edit text-primary pointer mx-2"
										></i>
									</>
								) : (
									<>
										<button
											className="btn companies-btn w-100"
											onClick={() =>
												setAddEditSupplierModal({
													isOpen: true,
													isEdit: false,
													id: visa?.id,
												})
											}
										>
											Assign
										</button>
									</>
								)}
							</td>

							<td
								className={`${
									visa.status === "waiting" ? "text-warning" : "text-success"
								}`}
							>
								{visa.status}
							</td>
							<td>
								<ShowForPermission
									permission={["Visa-Requests-View", "Visa-Requests-Manage"]}
								>
									<Link to={`/online-visa/view/request/${status}/${visa.id}`}>
										{locale === "en" ? (
											<i class="fas fa-angle-right"></i>
										) : (
											<i class="fas fa-angle-left"></i>
										)}
									</Link>
								</ShowForPermission>
							</td>
						</tr>
					);
			  })
			: [];
	}, [allVisa]);

	const fetchOnlineVisaList = useCallback(async () => {
		let status_list = [];
		if (status == "waiting") {
			status_list = ["waiting", "inprogress"];
		} else {
			status_list = [status];
		}
		const response = await listOnlineVisaRequests({
			...filter,
			// status,
			status_list: status_list,
			destination_id: filter?.destination_id?.value,
			visa_type_id: filter?.visa_type_id?.value,
			request_date: filter?.request_date
				? moment(filter?.request_date).format("YYYY-MM-DD")
				: "",
			offline_supplier_id: filter?.offline_supplier_id?.value,
			source: filter?.source?.value,
		});

		if (response.status === 200 || response.status === 201) {
			setAllVisa(response.data.data);
			setMeta(response.data.meta);
			const formatVisaTypes = await response?.data?.visa_types.map(
				(visaType) => ({
					value: visaType.id,
					label: visaType.name,
				})
			);
			const formatSources = response?.data?.sources.map((source) => ({
				value: source,
				label: source,
			}));
			setLookupsList({ visaTypes: formatVisaTypes, sources: formatSources });
		}
	}, [filter]);

	useEffect(() => {
		if (status) {
			fetchOnlineVisaList();
		}
	}, [status, filter]);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	useEffect(() => {
		const fetchSuppliers = async () => {
			const res = await listSuppliersOnlineVisa();
			if (res.status === 200 || res.status === 201) {
				const formatted = res.data.data.map((item) => {
					return { value: item.id, label: item.name };
				});
				setListSuppliers(formatted);
			}
		};
		fetchSuppliers();
	}, []);

	useEffect(() => {
		const getOfflineSuppliers = async () => {
			const res = await listOfflineSuppliers({ type: "visa" });
			console.log("supplier", res);
			if (res.status === 200 || res.status === 201) {
				const formatted = res.data.data.map((item) => {
					return { value: item.id, label: item.name };
				});
				setOfflineSuppliers(formatted);
			}
		};

		addEditSupplierModal && getOfflineSuppliers();
	}, []);

	const assginSupplier = async () => {
		const res = await assginSuppliersOnlineVisa(assginData);
		if (res.status === 200 || res.status === 201) {
			getNotification(res.data.message);
			toggleEdit();
			setFilter({ ...filter });
		}
	};
	const exportToFile = async () => {
		let res;
		let status_list = [];
		let name_file;
		if (isWaiting) {
			status_list = ["waiting", "inprogress"];
			name_file = "Waiting For Issuing Visa";
			res = await exportToWaitingVisas({
				...filter,
				status_list: status_list,
				destination_id: filter?.destination_id?.value,
				visa_type_id: filter?.visa_type_id?.value,
				request_date: filter?.request_date
					? moment(filter?.request_date).format("YYYY-MM-DD")
					: "",
				source: filter?.source?.value,
				page: filter.page,
			});
		} else {
			status_list = [status];
			name_file = "Issued Visa";
			res = await exportToWaitingVisas({
				...filter,
				status_list: status_list,
				destination_id: filter?.destination_id?.value,
				visa_type_id: filter?.visa_type_id?.value,
				request_date: filter?.request_date
					? moment(filter?.request_date).format("YYYY-MM-DD")
					: "",
				source: filter?.source?.value,
				page: filter.page,
			});
		}

		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`${name_file}-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<div className="">
			<div
				className=" tabs d-flex align-items-center all-tabs"
				style={{ borderBottom: "1px solid #d9dde0" }}
			>
				<NavLink
					exact={true}
					to="/online-visa/requests/waiting"
					activeClassName="is-active"
					className="d-block p-3 our-taps"
					onClick={() => {
						setFilter({
							page: null,
							company_name: "",
							destination_id: null,
							visa_type_id: null,
							request_date: null,
							source: null,
						});
					}}
				>
					<i class="fas fa-hourglass-half mx-1"></i>{" "}
					{requests.WaitingForIssuingVisa}
				</NavLink>

				<NavLink
					exact={true}
					to="/online-visa/requests/issued"
					activeClassName="is-active"
					className="d-block mx-3 p-3 our-taps"
					onClick={() => {
						setFilter({
							page: null,
							company_name: "",
							destination_id: null,
							visa_type_id: null,
							request_date: null,
							source: null,
						});
					}}
				>
					<i class="fas fa-check-circle mx-1"></i>
					{requests.IssuedVisa}
				</NavLink>

				<NavLink
					exact={true}
					to="/online-visa/requests/direct"
					activeClassName="is-active"
					className="d-block mx-3 p-3 our-taps"
					onClick={() => {
						setFilter({
							page: null,
							company_name: "",
							destination_id: null,
							visa_type_id: null,
							request_date: null,
						});
					}}
				>
					{/* <i class="fas fa-check-circle mx-1"></i> */}
					{"Direct visa"}
				</NavLink>
				<NavLink
					exact={true}
					to="/online-visa/requests/eup"
					activeClassName="is-active"
					className="d-block mx-3 p-3 our-taps"
					onClick={() => {
						setFilter({
							page: null,
							company_name: "",
							destination_id: null,
							visa_type_id: null,
							request_date: null,
						});
					}}
				>
					{"EUP Barcode"}
				</NavLink>
			</div>

			<div>
				<Filter
					visaTypes={lookupsList.visaTypes}
					sources={lookupsList.sources}
					filter={filter}
					setFilter={setFilter}
					offlineSuppliers={offlineSuppliers}
					exportToFileFn={exportToFile}
				/>
			</div>

			<div className=" table-responsive  px-4 our-border ">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>{requests.ID}</th>
							<th>{requests.TravelAgent}</th>
							<th>{backOffice.source}</th>
							<th>{requests.Destination}</th>
							{/* <th>{requests.TransportType}</th> */}
							<th>{requests.VisaType}</th>
							<th>{requests.Pax}</th>
							<th>{requests.TripDate}</th>
							<th>{requests.RequestDate}</th>
							<th>{requests.Price}</th>
							{isWaiting && <th>Nationality</th>}
							<th>Madinah Rawda doc</th>
							<th>Rawda Date</th>
							{/* <th>{requests.supplier}</th> */}
							<th>Supplier</th>
							<th>{requests.status}</th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						{allVisa?.length > 0 ? (
							allVisaList
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>

			<CustomModal
				modalIsOpen={ModalEdit}
				header={requests.SelectSupplier}
				toggle={toggleEdit}
				size={"md"}
				centered={true}
			>
				<div className=" ">
					<SelectField
						hasLabel={false}
						placeholder={requests.SelectSupplier}
						name="status"
						options={ListSuppliers}
						onChange={(e) => {
							setAssginData({
								...assginData,
								supplier_id: e.value,
								supplier_name: e.label,
							});
						}}
					/>

					<div className="d-flex  justify-content-end mt-5">
						<button className=" cancel-bg p-2 px-4 mx-3" onClick={toggleEdit}>
							Back
						</button>
						<button
							className="btn companies-btn"
							onClick={assginSupplier}
							disabled={!assginData?.supplier_id}
						>
							Save
						</button>
					</div>
				</div>
			</CustomModal>

			{nationalityModal ? (
				<Modal isOpen={nationalityModal}>
					<ModalHeader toggle={toggleNationalityModal}>
						Passengers Nationality
					</ModalHeader>
					<ModalBody>
						<div className="font-weight-bold mb-0" style={{ fontSize: "15px" }}>
							{selectPassengerItem.map((item, index) => (
								<p key={index}>{item}</p>
							))}
						</div>
					</ModalBody>
					{/* <ModalFooter>
						<div>
							<button
								className="btn btn-danger mx-2 px-4"
								onClick={() => deleteOfferItem()}
							>
								Yes
							</button>
							<button
								className="btn btn-secondary px-4"
								onClick={toggleConfiramationModal}
							>
								No
							</button>
						</div>
					</ModalFooter> */}
				</Modal>
			) : null}

			{addEditSupplierModal?.isOpen ? (
				<Modal isOpen={addEditSupplierModal?.isOpen}>
					<ModalHeader
						toggle={() => {
							setAddEditSupplierModal({
								...addEditSupplierModal,
								isOpen: false,
							});
							setSelectedOfflineSupplier(null);
						}}
					>
						{addEditSupplierModal?.isEdit ? "Edit Supplier" : "Add Supplier"}
					</ModalHeader>
					<ModalBody>
						<div className="d-flex flex-column">
							<div className="form-group">
								<SelectField
									label={requests.SelectSupplier}
									placeholder={requests.SelectSupplier}
									name="supplier_id"
									options={offlineSuppliers}
									value={selectedOfflineSupplier}
									onChange={(selectedSupplier) => {
										setSelectedOfflineSupplier(selectedSupplier);
									}}
								/>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<button
							className="btn btn-secondary"
							onClick={() => {
								if (
									!addEditSupplierModal?.id &&
									!selectedOfflineSupplier?.value
								)
									return;

								attachOnlineVisaToOfflineSupplier({
									visa_id: addEditSupplierModal?.id,
									offline_supplier_id: selectedOfflineSupplier?.value,
									offline_supplier_name: selectedOfflineSupplier?.label,
								}).then((res) => {
									if (res.status === 200 || res.status === 201) {
										getNotification(res.data.message);
										setAddEditSupplierModal({
											isOpen: false,
											isEdit: false,
										});
										setSelectedOfflineSupplier(null);
										fetchOnlineVisaList();
									}
								});
							}}
						>
							Save
						</button>
					</ModalFooter>
				</Modal>
			) : null}
		</div>
	);
}
