import { useHistory } from "react-router-dom";

export default function Notification({ notification, readed }) {
	const history = useHistory();

	return (
		<div
			className={`notification-items pointer mt-3 ${
				notification?.read_at ? " " : "notification-readed"
			}`}
			onClick={() => {
				if (!notification?.read_at) {
					readed(notification.id);
				}
				if (notification.item_type == "company") {
					window.open(
						`/tourism-company/profile/${notification.item_id}`,
						"_blank"
					);
				} else if (notification.item_type == "brn") {
					window.open(
						`/booking/view/${notification.item_id}/bookings`,
						"_blank"
					);
				} else if (notification.item_type == "groupReservation") {
					window.open(
						`/manual-reservations/sbs-reservations/${notification.item_id}/details`,
						"_blank"
					);
				} else if (notification.item_type == "flight_request") {
					window.open(`/airline-requests`, "_blank");
				} else if (notification.item_type == "tour_booking") {
					window.open(`/bookings-tours/${notification.item_id}`, "_blank");
				} else if (notification.item_type == "send_visa_request") {
					window.open(
						`/online-visa/view/request/waiting/${notification.item_id}`,
						"_blank"
					);
				} else if (notification?.item_type == "online_visa_request") {
					window.open(
						`/online-visa/view/request/waiting/${notification?.item_id}`,
						"_blank"
					);
				}
			}}
		>
			<div>
				<p className="my-1 h5">{notification.title}</p>
				<p className="notification-content">{notification.body}</p>
			</div>
			{notification?.read_at ? null : <i class="fas fa-circle dot"></i>}
		</div>
	);
}
