import Pagination from "components/shared/Pagination";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { commercialDistAirLineList } from 'services/commercialDistribution';
import Locale from 'translations';
import CommercialDistAirLineFilters from './Filters';


export default function CommercialDistAirLineReservation() {
  const { backOffice, vendor } = Locale;
  const { id } = useParams();
  const [airLineList, setAirLineList] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState({
    search: "",
    booking_date: null,
    travel_date: null,
    status: null,
    page: 1,
  });


  function goTo(page) {
    setFilters({ ...filters, page: +page });
  };



  async function fetchAirLineReservations() {
    const filtersData = {
      search: filters?.search,
      status: filters?.status?.label,
      booking_date: filters?.booking_date ? moment(filters?.booking_date).format("YYYY-MM-DD") : null,
      travel_date: filters?.travel_date ? moment(filters?.travel_date).format("YYYY-MM-DD") : null,
      page: filters?.page
    }
    const airlineRes = await commercialDistAirLineList(id, filtersData);
    if (airlineRes?.status === 200) {
      setAirLineList(airlineRes?.data?.data);
      setMeta(airlineRes?.data?.meta);
    }
  }

  useEffect(() => {
    fetchAirLineReservations();
  }, [filters]);

  const statusClasses = (status) => {
    switch (status) {
      case "pending":
        return "text-light-yellow";
      case "confirmed":
      case "issued":
      case "approved":
        return "text-success";
      default:
        return "text-danger";
    }
  };


  return (
    <div>
      <CommercialDistAirLineFilters filters={filters} setFilters={setFilters} />
      <div className=" table-responsive px-4 our-border">
        <table className="custom-table back-office-table table table-striped">
          <thead>
            <tr>
              <th>Ref.</th>
              <th>Flight Order</th>
              <th>{vendor.bookingDate}</th>
              <th>Travel Date</th>
              <th>Pax</th>
              <th>{vendor.Total_Price}</th>
              {/* <th>Total Paid</th> */}
              <th>{vendor.status}</th>
            </tr>
          </thead>
          <tbody>
            {airLineList?.length > 0 ?
              airLineList?.map(airline => {
                return (
                  <tr>
                    <td>{airline?.reference_number}</td>
                    <td>{airline?.order_number}</td>
                    <td>
                      {airline?.booking_date ? moment(airline?.booking_date).format("DD-MM-YYYY") : "-"}
                    </td>
                    <td>
                      {airline?.travel_date ? moment(airline?.travel_date).format("DD-MM-YYYY") : "-"}
                    </td>
                    <td>{airline?.pax}</td>
                    <td>{airline?.total_price} {airline?.currency}</td>
                    <td className={`${statusClasses(airline.status)} text-capitalize`}>{airline?.status}</td>
                    <td>
                      <Link className="d-inline-block action--btn" to={`/manage-commercial-distribution/${id}/view/airline/${airline.id}`}>
                        <i class="fas fa-chevron-right mx-1"></i>
                      </Link>
                    </td>
                  </tr>
                )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>

            }
          </tbody>

        </table>
        <div className="px-2">
          <Pagination info={meta} goTo={goTo} />
        </div>
      </div>
    </div >
  )
}
