import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";
export const fetchFilesURL = process.env.REACT_APP_API_URL + "/api/files/fetch";

export const listOnlineVisaRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/online-visa-requests`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewOnlineVisaRequest = async (id) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/issued-request/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewOnlineVisaRequestWaiting = async (id) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/online-visa-requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const visaRequestReapplyPassenger = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/passenger-reapply`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const visaRequestRejectPassenger = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/passenger-reject`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewOnlineVisaPassenger = async (requestId, passengerId) => {
	return await axios
		.get(
			`${backOfficeApi}/online-visa/show-passenger/${requestId}/${passengerId}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listSuppliersOnlineVisa = async () => {
	return await axios
		.get(`${backOfficeApi}/back-office/suppliers/dropdown?service_type=2`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const assginSuppliersOnlineVisa = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/assign-supplier`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchManualVisaList = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/list-manual-visa`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const addManualVisa = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/add-manual-visa`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editManualVisa = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/edit-manual-visa/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteManualVisa = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/online-visa/delete-manual-visa/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchAllVisasList = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/list-visa-passengers`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const allVisasPassengerProfile = async (requstId, passengerId) => {
	return await axios
		.get(
			`${backOfficeApi}/online-visa/show-passenger-info/${requstId}/${passengerId}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listCycleTypes = async () => {
	return await axios
		.get(`${backOfficeApi}/transportation/list/cycle_types`)
		.then((res) => res)
		.catch((err) => err.response);
};

// api booking
export const listBooking = async (params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const bookingExportToExcel = async (params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewBookingPassinger = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getInvouce = async (brn) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/get-invoice/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateBooking = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/update-booking`, data)
		.catch((err) => err.response);
};

export const getAllSpecialRequests = async () => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/get_all_special_requests`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const cancelRoom = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/cancel-room`, data)
		.catch((err) => err.response);
};

export const cancelHotelBooking = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/cancel-booking`, data)
		.catch((err) => err.response);
};
export const fetchVoucher = async (brn, number) => {
	return await axios.get(
		`${backOfficeApi}/marketplace/bookings/get-voucher/${brn}/${number}`
	);
};
//list Wallets
export const getListWallets = async (data) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/list-wallets`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const confirmBookingHotels = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/update-booking-confirm`, data)
		.catch((err) => err.response);
};

export const getListNotes = async (brn) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/notes/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addNotes = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/notes/add`, data)
		.catch((err) => err.response);
};
export const addRefund = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/refund`, data)
		.catch((err) => err.response);
};

export const editConfirmation = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/bookings/edit-confirmation-num`, data)
		.catch((err) => err.response);
};
export const editHotelBooking = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/marketplace/bookings/edit-booking-price/${id}`,
			data
		)
		.catch((err) => err.response);
};

//Ground Service
export const listGroundRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/ground-work-requests`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const assginSuppliersGround = async (data) => {
	return await axios
		.post(`${backOfficeApi}/ground-work/assign-supplier`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroundRequestWaiting = async (id) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/ground-work-requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewGroundPassenger = async (id) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/show-passenger/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchNotes = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/notes/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
//trabsportation
export const listTransportationRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/transportation/requests`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewTransportationRequestWaiting = async (id) => {
	return await axios
		.get(`${backOfficeApi}/transportation/requests/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const assginSuppliersTransportation = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/transportation/requests/${id}/assign-supplier`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewTransportationPassenger = async (id_req, id_pass) => {
	return await axios
		.get(
			`${backOfficeApi}/transportation/requests/${id_req}/passenger/${id_pass}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportToExcel = async (id) => {
	return await axios
		.get(`${backOfficeApi}/transportation/export-request/${id}`, {
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelGround = async (id) => {
	return await axios
		.get(`${backOfficeApi}/ground-work/export-request/${id}`, {
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const addNotesGround = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/ground-work/add-note/${id}`, data)
		.catch((err) => err.response);
};

export const UpdatePaymentExpirationDate = async (data) => {
	return await axios
		.post(
			`${backOfficeApi}/marketplace/bookings/edit-payment-expiration-date`,
			data
		)
		.catch((err) => err.response);
};
export const addNoteTransportation = async (id, data) => {
	return await axios
		.post(`${backOfficeApi}/transportation/add-note/${id}`, data)
		.catch((err) => err.response);
};
export const fetchNotesTransportation = async (id) => {
	return await axios
		.get(`${backOfficeApi}/transportation/list-notes/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchHistoryBooking = async (id, params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/history/${id}`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchBookingHistoryUsers = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/bookings/history/${id}/users`)
		.then((res) => res)
		.catch((err) => err.response);
};

/**Start Tour */
export const listBookingTours = async (params) => {
	return await axios
		.get(`${backOfficeApi}/tours/booking`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const ViewBookingsTour = async (id) => {
	return await axios
		.get(`${backOfficeApi}/tours/booking/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getPreCancelTour = async (id) => {
	return await axios
		.get(`${backOfficeApi}/tours/booking/${id}/cancel`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const cancelTour = async (id) => {
	return await axios
		.post(`${backOfficeApi}/tours/booking/${id}/cancel`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const cancelTourItem = async (id) => {
	return await axios
		.post(`${backOfficeApi}/tours/reservation/${id}/cancel`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const preCancelTourItem = async (id) => {
	return await axios
		.get(`${backOfficeApi}/tours/reservation/${id}/cancel`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getToursInvoice = async (id) => {
	return await axios
		.get(`${backOfficeApi}/tours/booking/${id}/invoice`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getAllToursVoucher = async (book_id) => {
	return await axios
		.get(`${backOfficeApi}/tours/booking/${book_id}/reservation-voucher`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getVoucher = async (book_id, voucher_id) => {
	return await axios
		.get(
			`${backOfficeApi}/tours/booking/${book_id}/reservation-voucher/${voucher_id}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
/**End Tour */
export const addVisaDocument = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/add-visa-document`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addRawdaDocument = async (data) => {
	return await axios.post(
		`${backOfficeApi}/online-visa/add-rawda-reservation-document`,
		data
	);
};
export const SoldVisaList = async (data, page) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/list-sold-visas?page=${page}`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const downloadFiles = async (uuid) => {
	return await axios
		.get(`${fetchFilesURL}/${uuid}`, { responseType: "blob" })
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateSoldVisaExpireDate = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/edit-expiration-date`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const togglePaymentDirectVisa = async (id, mark) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/toggle-payment/${id}?mark=${mark}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelDirectVisa = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/export-manual-visa`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const ChangeStatusTour = async (id, data) => {
	return await axios
		.put(`${backOfficeApi}/tour/booking/${id}/status`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelTours = async (params) => {
	return await axios
		.get(`${backOfficeApi}/tours/booking/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportToExcelSoldVisas = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/export-sold-visas`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportToWaitingVisas = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/online-visa-requests-export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const exportToAllVisas = async (params) => {
	return await axios
		.get(`${backOfficeApi}/online-visa/list-visa-passengers-export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchEUPBarcodeList = async (params) => {
	return await axios
		.get(`${backOfficeApi}/v1/eup-barcode`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const addEUPBarcode = async (data) => {
	return await axios
		.post(`${backOfficeApi}/v1/eup-barcode`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editEUPBarcode = async (id, data) => {
	return await axios
		.put(`${backOfficeApi}/v1/eup-barcode/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteEUPBarcode = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/v1/eup-barcode/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const togglePaymentEUPBarcode = async (id, data) => {
	return await axios
		.patch(`${backOfficeApi}/v1/eup-barcode/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcelEupBarcode = async (params) => {
	return await axios
		.get(`${backOfficeApi}/v1/eup-barcode/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const attachOnlineVisaToOfflineSupplier = async (data) => {
	return await axios
		.post(`${backOfficeApi}/online-visa/attach-visa-to-offline-supplier`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const attachEUPToOfflineSupplier = async (data) => {
	return await axios
		.post(`${backOfficeApi}/v1/attach-eup-barcode-to-offline-supplier`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const attachManualVisaToOfflineSupplier = async (data) => {
	return await axios
		.post(
			`${backOfficeApi}/online-visa/attach-manual-visa-to-offline-supplier`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listOfflineSuppliers = async (params) => {
	return await axios
		.get(`${backOfficeApi}/group-reservation/list-offline-suppliers`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
