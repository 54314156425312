import phone from "assets/images/phone.png";
import SafaLogo from "assets/images/safa_logo.png";
import { toursVoucher } from "assets/prints/toursVoucher";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useState } from "react";
import { getVoucher, preCancelTourItem } from "services/Request";
import CancelModel from "./CancelModel";
export default function TourBookingDetailsCard({
	reservationItem,
	paymentStatus,
	bookingStatus,
	setBookingDetails,
}) {
	const expiredBooking = bookingStatus === "expired";
	const expiredItem =
		reservationItem?.status === "expired" ||
		moment().isAfter(moment(reservationItem.start_date));

	const canCancelTour =
		bookingStatus === "confirmed" &&
		reservationItem?.status === "confirmed" &&
		moment().isBefore(moment(reservationItem.start_date));

	const amount = {
		//totalPrice: reservationItem?.details?.amountDetail?.totalAmount?.amount,
		totalPrice: +reservationItem?.price,
		currency: reservationItem?.currency,
	};
	console.log("reservationItemreservationItem", reservationItem);
	const [modalCancel, setModalCancel] = useState(false);
	const [preCancel, setPreCancel] = useState();

	const toggleModalCancel = () => setModalCancel(!modalCancel);
	async function getPreCancelTourItem() {
		const preCancelRes = await preCancelTourItem(reservationItem?.id);
		if (preCancelRes.status >= 200 && preCancelRes.status < 300) {
			setPreCancel(preCancelRes?.data?.data);
			setModalCancel(true);
		}
	}
	const printVoucher = async () => {
		const res = await getVoucher(
			reservationItem?.booking_id,
			reservationItem?.id
		);
		if (res.status !== 200) return;
		const data = res?.data?.vouchers?.[0];

		let childrenString = "";
		data?.children?.forEach((age, index) => {
			childrenString = childrenString.concat(
				`<span >
					${age}
					${index !== data?.children?.length - 1 ? ", " : " "}
				</span>`
			);
		});
		let remarkString = data?.remarks?.concat(`<p>${data?.remarks}</p>`);

		const keyValuePair = {
			"[reference_number_key]": "Reference Number",
			"[reference_number_value]": data?.reference,
			"[safa_reference_number_key]": "Safa Reference Number",
			"[safa_reference_number_value]": data?.safa_reference,
			"[name]": data?.name,
			"[passenger_name_key]": "Passenger Name",
			"[passenger_name_value]": data?.passenger_name,
			"[booking_date_key]": "Booking Date",
			"[booking_date_value]": moment(data?.booking_date).format("YYYY-MM-DD"),
			"[agency_Reference_key]": "Agency Reference",
			"[agency_Reference_value]": data?.agency_reference,
			"[from_key]": "From",
			"[from_value]": moment(data?.from_date).format("YYYY-MM-DD"),
			"[to_key]": "To",
			"[to_value]": moment(data?.from_date).format("YYYY-MM-DD"),
			"[ticket_type_key]": "Ticket Type",
			"[ticket_type_vaue]": data?.ticket_type,
			// "[service_time_key]": "Service Time",
			// "[service_time_value]": "--",
			"[adult_key]": "Adult",
			"[adult_value]": data?.pax?.adults,
			"[children_key]": "Children",
			"[children_value]": data?.pax?.children,
			"[children_ages_key]": "Children Ages",
			"[children_ages_value]":
				data?.pax?.children_ages?.length > 0 ? data?.pax?.children_ages : "--",
			"[remarks_key]": "Remarks",
			"[remarks_value]": remarkString,
			"[provider_name_value]": data?.provider?.name,
			"[provider_ref_value]": data?.provider?.reference
				? data?.provider?.reference
				: "--",
			"[supplier_name_value]": data?.supplier?.name,
			"[supplier_vat_value]": data?.supplier?.vatNumber,
		};

		let printVar = toursVoucher;
		for (const key in keyValuePair) {
			printVar = printVar.replace(key, keyValuePair[key]);
		}

		printVar = printVar.replace(
			"[logo]",
			`<img width={200} src="${SafaLogo}" alt="safa logo" />`
		);
		printVar = printVar.replace(
			"[supplier_img]",
			`<img src="" alt="supplier img" class="img-fluid" width={200} />`
		);
		printVar = printVar.replace(
			"[phone_img]",
			`<img src="${phone}" alt="phone" />`
		);

		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
        <head>
        <title> print Voucher</title>
        <style>

        </style>
        </head>
        <body onLoad="{()=>window.print()}">${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};

	return (
		<>
			<div className="tour-booking-item bg-white">
				<div className="d-flex flex-wrap">
					<div className="tour-card-img">
						<img
							src={reservationItem?.details?.images?.[0]}
							alt="tours"
							className="img-fluid tour-booking-item-img"
						/>
					</div>
					<div className="d-flex flex-column mx-md-2">
						<h2 className="mb-2">
							<span className="font-weight-bold text-dark-blue h5">
								{reservationItem?.details?.name}
							</span>
						</h2>
						<span className="font-weight-bold">
							Source :{" "}
							{reservationItem?.details?.gds == 10 ? "SBS" : "Hotelbeds"}
						</span>
						<i className="fal fa-sort-alt"></i>

						<div className="tour-booking-item-info">
							<i className="fas fa-map-marker-alt"></i>
							<p className="mx-1">
								{reservationItem?.details?.destinations?.map((city) => {
									return (
										<span key={city?.code}>
											{city?.name} ({city?.code}),{" "}
										</span>
									);
								})}
							</p>
						</div>

						<div className="tour-booking-item-info">
							<i className="far fa-calendar-alt"></i>
							<span className="mx-1 font-weight-bold">
								{moment(reservationItem?.start_date).format("DD/MM/YYYY")} (
								{moment(reservationItem?.start_date).format("dddd")})
							</span>
						</div>

						<div className="tour-booking-item-info">
							<i className="far fa-user"></i>
							<span className="mx-1 font-weight-bold">
								{reservationItem?.adults} Adults
							</span>
							{reservationItem?.children?.length > 0 ? (
								<span>
									{reservationItem?.children?.length}
									{"Children "}(
									{reservationItem?.children?.map((age, index) => {
										return (
											<span key={`age-${index}-${age}`}>
												{age}
												{index !== reservationItem?.children?.length - 1
													? ", "
													: " "}
											</span>
										);
									})}
									Years Old )
								</span>
							) : null}
						</div>
					</div>
				</div>

				<p className="font-weight-bold text-dark-blue mt-1">{"Remarks"}</p>
				{reservationItem?.details?.comments?.map((remark, remarkIdx) => {
					return <p key={`${remark?.type}-${remarkIdx}`}>{remark?.text}</p>;
				})}

				<p className="font-weight-bold text-dark-blue pt-2 border-top-gray">
					<span>
						{reservationItem?.adults} {"Adults"}
					</span>
					{reservationItem?.children?.length > 0 ? (
						<span>
							{reservationItem?.children?.length} {"Children"} (
							{reservationItem?.children?.map((age, index) => {
								return (
									<span key={`age-${index}-${age}`}>
										{age}
										{index !== reservationItem?.children?.length - 1
											? ", "
											: " "}
									</span>
								);
							})}
							Years Old)
						</span>
					) : null}{" "}
					({reservationItem?.details?.modality?.name})
				</p>

				{/* cancelations info */}
				{reservationItem?.details?.cancellationPolicies?.length > 0 ? (
					<div className="mt-3 tour-booking-item-cancelation">
						<div className="d-flex align-items-center flex-wrap py-3">
							<div className="col-md-6">
								<div className="">
									<div className="d-flex justify-content-between ">
										<p className="font-weight-bold h6">{"Cancellation Fees"}</p>
									</div>
									{reservationItem?.details?.cancellationPolicies?.map(
										(policy, index) => {
											return (
												<div
													className="d-flex justify-content-between"
													key={index}
												>
													<p className="tour-booking-item-blue-dark">
														{"From"}{" "}
														{moment(policy?.dateFrom).format("hh:mm A")}{" "}
														{moment(policy?.dateFrom).format("DD/MM/YYYY")}
													</p>
													<span className="text-light-danger font-weight-bold h6 text-14">
														{policy?.amount} {reservationItem?.currency}
													</span>
												</div>
											);
										}
									)}
								</div>
							</div>
							<div className="col-md-6 text-muted">
								Date and time are calculated based on local time in the
								destination. In case of no-show, different fees will apply.
								Please refer to our T&C.
							</div>
						</div>
					</div>
				) : null}

				<div className="d-flex justify-content-between py-2 tour-booking-item-action">
					<div className="w-100 d-flex align-items-center justify-content-between">
						<ShowForPermission permission="Tours-Bookings-Manage">
							{bookingStatus !== "canceled" &&
								reservationItem?.status !== "canceled" && (
									<div className="text-light-warning" onClick={printVoucher}>
										<i className="fas fa-download"></i>
										<span className="mx-1 text-light-warning">
											Download Voucher
										</span>
									</div>
								)}
						</ShowForPermission>

						{/* <div>
							<ShowForPermission permission="Tours-Bookings-Manage">
								{canCancelTour && paymentStatus !== "pending" ? (
									<button
										className="btn text-light-danger p-0"
										onClick={getPreCancelTourItem}
									>
										<i className="fas fa-ban text-light-danger"></i>
										<span className="mx-1">Cancel Tour</span>
									</button>
								) : null}
							</ShowForPermission>
						</div> */}
					</div>

					{reservationItem?.status === "canceled" ? (
						<p className="text-light-danger font-weight-bold">{"Canceled"}</p>
					) : null}
					{expiredBooking ? (
						<p className="text-light-danger font-weight-bold">{"Expired"}</p>
					) : null}
				</div>
			</div>
			{canCancelTour && modalCancel ? (
				<CancelModel
					model={modalCancel}
					toggleModel={toggleModalCancel}
					details={reservationItem}
					cancelType={"single-item"}
					preCancel={preCancel}
					amount={amount}
					setBookingDetails={setBookingDetails}
				/>
			) : null}
		</>
	);
}
