import ArrowRightIcon from "assets/images/arrow-right.png";
import CheckBox from "components/chekbox";

export default function ModuleRow({
	moduleData,
	selectedPlan,
	setSelectedPlan,
	handleCheck,
	handleCheckAll,
	handlePaidType,
	currency,
	isActiveStatus,
	isPackage,
}) {
	const subModules = moduleData?.subModules || [];
	let collectionPlan = [];
	const allSubModules =
		subModules?.length > 0 &&
		subModules.map((subModule, index) => {
			collectionPlan = [...collectionPlan, subModule.id];
			return (
				<div className="module-list-item" key={subModule.id}>
					<div className="row mx-0">
						<div className="col-md-10 module-name">
							<div
								className="d-flex mt-1 sub-module-name"
								style={{ gap: "8px" }}
							>
								<CheckBox
									type="Checkbox"
									name={subModule?.name}
									id={subModule?.id}
									checked={
										subModule?.status === "active" ||
										selectedPlan?.includes(subModule.id)
									}
									onChange={(e) => {
										handleCheck(subModule?.id, e.target.checked);
										handlePaidType(
											"status",
											moduleData?.id,
											e.target.checked ? "active" : "inactive",
											true,
											subModule?.id,
											subModules,
											moduleData?.id
										);
									}}
									disabled={isActiveStatus || isPackage}
								/>
								<p className="mx-4">
									{" "}
									<img src={ArrowRightIcon} className="mb-1 mx-2" alt="" />
									{subModule?.name}
								</p>
							</div>
						</div>
						<div className="col-md-1 modul-column text-center">
							<input
								// class="form-check-input"
								type="radio"
								name={`paid_type-${subModule?.id}`}
								id={subModule?.id}
								value={subModule?.id}
								checked={subModule?.type === "free" ? true : false}
								onChange={(e) => {
									handlePaidType(
										"type",
										moduleData?.id,
										e.target.checked ? "free" : "paid",
										true,
										subModule?.id,
										subModules,
										moduleData?.id
									);
								}}
								disabled={isActiveStatus || isPackage}
							/>
						</div>
						<div className="col-md-1 modul-column text-center">
							<input
								// class="form-check-input"
								type="radio"
								name={`paid_type-${subModule?.id}`}
								id={subModule?.id}
								value={subModule?.id}
								checked={subModule?.type === "paid" ? true : false}
								onChange={(e) => {
									handlePaidType(
										"type",
										moduleData?.id,
										e.target.checked ? "paid" : "free",
										true,
										subModule?.id,
										subModules,
										moduleData?.id
									);
								}}
								disabled={isActiveStatus || isPackage}
							/>
						</div>
						{/* <div className="col-md-3 module-price text-center">
							<input
								type="number"
								min={1}
								value={subModule?.price}
								onChange={(e) => {
									handlePaidType(
										"price",
										moduleData?.id,
										e.target.value,
										true,
										subModule?.id,
										subModules,
										moduleData?.id
									);
								}}
								disabled={subModule?.type === "free" || isActiveStatus}
								className={`${
									isActiveStatus && subModule?.type !== "free"
										? "active-plan-disabled"
										: ""
								}`}
							/>
							<span>{currency}</span>
						</div> */}
					</div>
				</div>
			);
		});
	return (
		<>
			{/** module name Row */}
			<div className="module-list-item">
				<div className="row mx-0">
					<div className="col-md-10 module-name">
						<div className="d-flex mt-1" style={{ gap: "8px" }}>
							<CheckBox
								type="Checkbox"
								name={moduleData?.name}
								id={moduleData?.id}
								checked={
									moduleData?.status === "active"
									// ||
									// selectedPlan?.includes(moduleData?.id)
								}
								onChange={(e) => {
									if (collectionPlan?.length > 0) {
										handleCheckAll(
											[...collectionPlan, moduleData?.id],
											e.target.checked,
											moduleData?.id
										);
									} else {
										handleCheck(moduleData?.id, e.target.checked);
									}
									handlePaidType(
										"status",
										moduleData?.id,
										e.target.checked ? "active" : "inactive",
										false,
										null,
										subModules,
										"parent"
									);
								}}
								disabled={isActiveStatus || isPackage ? true : false}
							/>

							<p className="ml-2">{moduleData?.name}</p>
						</div>
					</div>
					<div className="col-md-1 modul-column text-center">
						<input
							type="radio"
							name={`paid_type-${moduleData?.id}`}
							id={moduleData.id}
							value={moduleData.id}
							checked={moduleData?.type === "free" ? true : false}
							onChange={(e) => {
								handlePaidType(
									"type",
									moduleData?.id,
									e.target.checked ? "free" : "paid",
									false,
									null,
									subModules,
									moduleData?.id
								);
							}}
							disabled={isActiveStatus || isPackage}
						/>
					</div>
					<div className="col-md-1 modul-column text-center">
						<input
							// className="form-check-input"
							type="radio"
							name={`paid_type-${moduleData?.id}`}
							id={moduleData.id}
							value={moduleData.id}
							checked={moduleData?.type === "paid" ? true : false}
							onChange={(e) => {
								handlePaidType(
									"type",
									moduleData?.id,
									e.target.checked ? "paid" : "free",
									false,
									null,
									subModules,
									moduleData?.id
								);
							}}
							disabled={isActiveStatus || isPackage}
						/>
					</div>
					{/* <div className="col-md-3 module-price text-center">
						<input
							type="number"
							min={1}
							value={moduleData?.price}
							onChange={(e) => {
								handlePaidType("price", moduleData?.id, e.target.value);
							}}
							disabled={
								moduleData?.type === "free" ||
								moduleData?.subModules?.length !== 0 ||
								isActiveStatus
							}
							className={`${
								moduleData?.subModules?.length !== 0 &&
								moduleData?.type !== "free"
									? "isParentModule"
									: ""
							} ${
								isActiveStatus && moduleData?.type !== "free"
									? "active-plan-disabled"
									: ""
							}`}
						/>
						<span>{currency}</span>
					</div> */}
				</div>
			</div>
			{/** sub module name row  */}
			{/*** Here Loop for sub module */}
			{allSubModules}
		</>
	);
}
