import DateRangeField from "components/shared/DateRangeField";
import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { UpdateCalenderBulk } from "services/attraction";
import { fetchAttractoinCalender } from "services/calendar";
import ConfigureCalender from "../models/configureCalender";
import CalendarRoom from "./CalendarRoom";

export default function AttractoinCalender() {
	const { id } = useParams();
	const [roomCalender, setRoomCalender] = useState([]);
	const [days, setDays] = useState([]);
	const [filters, setFilters] = useState({
		rooms: [],
		dateStart: moment(new Date()),
		dateEnd: moment(new Date()),
	});

	const getViewCalendar = async (data) => {
		// const { id, date_from, date_to } = fetchCalendarFormat({
		// 	...filters,
		// });

		if (moment(data.endDate).isAfter(data.startDate)) {
			const req = await fetchAttractoinCalender(
				id,
				data.startDate,
				data.endDate
			);
			setRoomCalender([]);

			setTimeout(() => {
				if (req.hasOwnProperty("data")) {
					setRoomCalender(req.data);
					getDays(
						moment(data.startDate).subtract(1, "days").format("YYYY-MM-DD"),
						moment(data.endDate).add(1, "days").format("YYYY-MM-DD")
					);
					return req;
				}
			}, 30);
		}
	};

	async function getDays(startDate, endDate) {
		let dates = [];
		let startloob;
		startloob = startDate;
		setDays([]);
		if (startDate !== "Invalid date" && endDate !== "Invalid date") {
			while (moment(startloob._d).format("YYYY-MM-DD") !== endDate) {
				if (
					moment(startloob._d).format("YYYY-MM-DD") !==
					moment().format("YYYY-MM-DD")
				) {
					dates.push(moment(startloob._d).format("YYYY-MM-DD"));
				}
				startloob = moment(startloob).add(1, "days");
			}
		}
		setTimeout(() => {
			setDays(dates);
		}, 20);
	}

	const [dateState, setDateState] = useState({
		dateStart: moment(new Date()),
		dateEnd: moment(new Date()),
	});
	const [configureState, setConfigureState] = useState({
		date_from: "",
		date_to: "",
		price_per_pax: "",
		max_pax: "",
		times: [""],
	});
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen((prev) => !prev);
		setConfigureState({
			date_from: "",
			date_to: "",
			price_per_pax: "",
			max_pax: "",
			times: [""],
		});
	};

	const handleTime = (value, index) => {
		const clone = configureState.times;
		clone[index] = value;
		setConfigureState((current) => ({ ...current, times: clone }));
	};
	const handleAddNewTime = () => {
		const clone = configureState.times;
		clone.push("");
		setConfigureState((current) => ({ ...current, times: [...clone] }));
	};
	console.log(configureState);

	const calenderBulk = async () => {
		const data = {
			date_from: moment(configureState.date_from).format("YYYY-MM-DD"),
			date_to: moment(configureState.date_to).format("YYYY-MM-DD"),
			price_per_pax: +configureState.price_per_pax,
			max_pax: +configureState.max_pax,
			times: configureState.times,
		};
		const response = await UpdateCalenderBulk(id, data);
		if (response.status === 200 || response.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "Saved Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					toggle();
					setConfigureState({
						date_from: "",
						date_to: "",
						price_per_pax: "",
						max_pax: "",
						times: [""],
					});
					getViewCalendar({
						startDate: moment(dateState?.dateStart).format("YYYY-MM-DD"),
						endDate: moment(dateState?.dateEnd).format("YYYY-MM-DD"),
					});
				},
			});
		}
	};
	console.log("dateStatedateState", {
		startDate: moment(dateState?.dateStart).format("YYYY-MM-DD"),
		endDate: moment(dateState?.dateEnd).format("YYYY-MM-DD"),
	});

	return (
		<>
			{/* <div className="col-md-12"> */}
			{/* <Filter
					getViewCalendar={getViewCalendar}
					id={id}
					setFilters={setFilters}
					filters={filters}
					rooms={roomList}
				/> */}
			{/* </div> */}

			<div className="attraction_calender">
				<div className="calender_head">
					<div className="date">
						<DateRangeField
							hasLabel={true}
							label={"Date"}
							startDate={dateState.dateStart}
							className="data_input_ranger"
							endDate={dateState.dateEnd}
							onDatesChange={({ startDate, endDate }) => {
								setDateState({
									//...dateState,
									dateStart: startDate,
									dateEnd: endDate,
								});
								getViewCalendar({
									startDate: moment(startDate).format("YYYY-MM-DD"),
									endDate: moment(endDate).format("YYYY-MM-DD"),
								});
							}}
							isOutsideRange={() => false}
						/>
					</div>
					<button className="btn btn-success px-5" onClick={toggle}>
						Configure
					</button>
				</div>
				<div className="calender_body">
					{roomCalender && roomCalender.length > 0 ? (
						<CalendarRoom
							days={days}
							id={id}
							filters={filters}
							getViewCalendar={getViewCalendar}
							DaysCalender={roomCalender}
							// key={room}
							view={() =>
								getViewCalendar({
									startDate: moment(dateState?.dateStart).format("YYYY-MM-DD"),
									endDate: moment(dateState?.dateEnd).format("YYYY-MM-DD"),
								})
							}
						/>
					) : null}
				</div>

				<ConfigureCalender
					isOpen={isOpen}
					toggle={toggle}
					state={configureState}
					setState={setConfigureState}
					handleTime={handleTime}
					handleAddNewTime={handleAddNewTime}
					calenderBulk={calenderBulk}
				/>
			</div>
		</>
	);
}
