import axios from "axios";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { store } from "react-notifications-component";

let axiosInterceptor = null;
let axiosRequestInterceptor = null;
const AxiosConfiguration = () => {
  let currentLocale = localStorage.getItem("currentLocale") ?? "en";
  let { token } = useGlobalState();
  // let token = localStorage.getItem("token");
  const dispatch = useGlobalDispatch();

  axios.defaults.headers.common["Accept-Language"] = currentLocale;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["X-REQUEST-FROM"] = `back_office`;
  let counter = 0;
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }

  if (!!axiosRequestInterceptor || axiosRequestInterceptor === 0) {
    axios.interceptors.request.eject(axiosRequestInterceptor);
  }
  axiosRequestInterceptor = axios.interceptors.request.use(
    (request) => {
      counter++;
      if (request.data && request.data.hasOwnProperty("search")) {
        return request;
      } else {
        dispatch({ type: "setLoading", payload: true });
        return request;
      }
    },
    (error) => {
      counter--;
      console.log("Request Error ", error);
    }
  );

  axiosInterceptor = axios.interceptors.response.use(
    (response) => {
      counter--;
      // if (!response.config.url.includes("view_user_profile")) {
      if (counter === 0) {
        dispatch({ type: "setLoading", payload: false });
        return response;
      } else {
        return response;
      }
    },

    (error) => {
      counter--;
      // Show Alert FOr Error
      if (counter === 0) {
        dispatch({ type: "setLoading", payload: false });
      }
      if (error.response && error.response.status === 400) {
        store.addNotification({
          title: "Something Went Wrong!",
          message: error.response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 401) {
        store.addNotification({
          title: "Unauthenticated!",
          message: "Go Back to Login",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        localStorage.removeItem("isAuth");
        localStorage.removeItem("token");
        localStorage.removeItem("currentLocale");
        window.location.href = "/auth/login";
      } else if (error.response && error.response.status === 422) {
        let errorsMsgs = ``;

        if (error.response.data.errors) {
          Object.values(error.response.data.errors).map(
            (msg) => (errorsMsgs += msg + "\n")
          );
        } else {
          errorsMsgs = " ";
        }

        store.addNotification({
          title: error.response.data.message,
          message: errorsMsgs,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 429) {
        store.addNotification({
          title: "Something Went Wrong!",
          message: "Too Many Attempts, Please try again",
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 500) {
        let errorsMsgs = ``;

        if (error.response.data.errors) {
					Object.values(error.response.data.errors).map(
						(msg) => (errorsMsgs += msg + "\n")
					);
				} else if (error.response.data.message) {
					errorsMsgs = error.response.data.message;
				} else {
					errorsMsgs = " ";
				}

        store.addNotification({
          title: error.response.data.errors,
          message: errorsMsgs,
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 404) {
        //  to fix in another  time
        if (!error.response.request.responseURL.includes("api/fetch/")) {
          store.addNotification({
            title: "Something Went Wrong!",
            message: "Page not found 404",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              pauseOnHover: true,
            },
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

export default AxiosConfiguration;
