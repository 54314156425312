import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
import Switch from "react-switch";

export default function VisaRowPackagePlans({
	countryBase,
	configDataItem,
	packageItem,
	packageIndex,
	visaTypeIndex,
	residenceIndex,
	visaPermissionManage,
	handleChenageinPackages,
	handleAddPackageRow,
	handleRemovenIcludeVisa,
	handleRemovePackageRow,
	visaIncludeState,
	setVisaIncludeState,
}) {
	const VisaPermissionManage = useShowPermission({
		permission: "Visa-Configuration-Manage",
	});
	return (
		<div>
			<div className="row d-flex flex-column">
				<div className="d-flex flex-nowrap w-100 align-items-center">
					{/* Package Name */}
					<div className="col-md-3">
						<TextField
							disabled={!visaPermissionManage}
							type="text"
							label={"Package Name"}
							placeholder={"Package Name"}
							name="plan_name"
							onChange={(e) => {
								handleChenageinPackages(
									e.target.value,
									visaTypeIndex,
									residenceIndex,
									packageIndex,
									"plan_name"
								);
							}}
							value={packageItem?.plan_name}
						/>
					</div>
					{/* range from */}
					<div className="col-md-3 position-relative">
						<TextField
							disabled={!visaPermissionManage}
							type="text"
							label={"From"}
							placeholder={"from"}
							name="range_from"
							onChange={(e) => {
								handleChenageinPackages(
									e.target.value,
									visaTypeIndex,
									residenceIndex,
									packageIndex,
									"range_from"
								);
							}}
							value={packageItem?.range_from}
						/>
						<span style={{ position: "absolute", top: "50%", right: "8%" }}>
							Visa
						</span>
					</div>
					{/* range to */}
					<div className="col-md-3 position-relative">
						<TextField
							disabled={!visaPermissionManage}
							type="text"
							label={"To"}
							placeholder={"To"}
							name="range_to"
							onChange={(e) => {
								handleChenageinPackages(
									e.target.value,
									visaTypeIndex,
									residenceIndex,
									packageIndex,
									"range_to"
								);
							}}
							value={packageItem?.range_to}
						/>

						<span style={{ position: "absolute", top: "50%", right: "8%" }}>
							Visa
						</span>
					</div>
					{/* package price */}
					<div className="col-md-2">
						<TextField
							disabled={!visaPermissionManage}
							type="text"
							label={"Price"}
							placeholder={"Price"}
							name="package_price"
							onChange={(e) => {
								handleChenageinPackages(
									e.target.value,
									visaTypeIndex,
									residenceIndex,
									packageIndex,
									"package_price"
								);
							}}
							value={packageItem?.package_price}
						/>
					</div>
					{/* packages buttons */}
					<div className="col-md-1 mt-4">
						<ShowForPermission permission="Visa-Configuration-Manage">
							{packageIndex === configDataItem?.package_plans?.length - 1 && (
								<button
									className="btn copyIcon-configRow  mt-3"
									onClick={() =>
										handleAddPackageRow(
											residenceIndex,
											visaTypeIndex,
											packageIndex
										)
									}
								>
									<i class="fas fa-plus"></i>
								</button>
							)}
							<button
								className="btn copyIcon-configRow text-danger  "
								onClick={() =>
									handleRemovePackageRow(
										residenceIndex,
										visaTypeIndex,
										packageIndex
									)
								}
							>
								<i class="fas fa-trash"></i>
							</button>
						</ShowForPermission>
					</div>
				</div>

				{/* notes */}
				<div className="my-2 col-md-12">
					<h6>Notes</h6>
					<textarea
						placeholder="Enter Notes"
						name={`package-note-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
						id={`package-note-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
						className="col-md-6"
						value={packageItem.notes}
						onChange={(e) => {
							handleChenageinPackages(
								e.target.value,
								visaTypeIndex,
								residenceIndex,
								packageIndex,
								"notes"
							);
						}}
					></textarea>
				</div>
			</div>

			<div className="w-100 pb-2 row">
				<h6 className="pt-2 col-md-12 px-2 mb-0">
					Add Visa Include for each package
				</h6>
				{/* visa include input */}
				<div className="col-md-6 d-flex">
					<div className="col-md-6 p-0">
						<TextField
							disabled={!visaPermissionManage}
							type="text"
							label={"Visa Include"}
							placeholder={"Visa Include"}
							name="visa_include"
							id={`visa_include-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
							onChange={(e) => {
								setVisaIncludeState(e.target.value);
							}}
						/>
					</div>
					{/* add new visa include */}
					<div className="mx-2 mt-1">
						<ShowForPermission permission="Visa-Configuration-Manage">
							<button
								className="btn btn-primary px-5"
								onClick={(e) => {
									handleChenageinPackages(
										visaIncludeState,
										visaTypeIndex,
										residenceIndex,
										packageIndex,
										"visa_include"
									);
									setVisaIncludeState("");
									document.getElementById(
										`visa_include-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`
									).value = "";
								}}
								style={{ marginTop: "38px" }}
								disabled={
									document.getElementById(
										`visa_include-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`
									) &&
									document.getElementById(
										`visa_include-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`
									)?.value === ""
								}
							>
								Add
							</button>
						</ShowForPermission>
					</div>
				</div>
				{/* Residency, entry stamp */}
				<div className="d-flex justify-content-end align-items-end col-md-6 mb-2">
					<div className="d-flex mx-3">
						<Switch
							id={`package-residency-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
							width={45}
							height={22}
							handleDiameter={10}
							uncheckedIcon={false}
							checkedIcon={false}
							className="mx-2 align-middle"
							onColor="#027B30"
							onChange={() => {
								handleChenageinPackages(
									!packageItem?.residence_data,
									visaTypeIndex,
									residenceIndex,
									packageIndex,
									"residence_data"
								);
							}}
							disabled={!VisaPermissionManage}
							checked={packageItem?.residence_data}
						/>
						<label
							htmlFor={`package-residency-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
						>
							Residency required
						</label>
					</div>
					<div className="d-flex mx-2">
						<Switch
							id={`package-stamp-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
							width={45}
							height={22}
							handleDiameter={10}
							uncheckedIcon={false}
							checkedIcon={false}
							className="mx-2 align-middle"
							onColor="#027B30"
							onChange={() => {
								handleChenageinPackages(
									!packageItem?.entry_stamp,
									visaTypeIndex,
									residenceIndex,
									packageIndex,
									"entry_stamp"
								);
							}}
							disabled={!VisaPermissionManage}
							checked={packageItem?.entry_stamp}
						/>
						<label
							htmlFor={`package-stamp-${countryBase}${residenceIndex}${visaTypeIndex}${packageIndex}`}
						>
							Attach the entry stamp
						</label>
					</div>
				</div>
				{/* visa include list */}
				<div className="col-md-12">
					<div className="visa-include-data">
						{configDataItem?.package_plans[packageIndex]?.visa_include?.map(
							(item, inc_index) => (
								<span key={inc_index}>
									{item.name ?? item}{" "}
									<i
										role="button"
										className="fas fa-times mx-2"
										onClick={() =>
											handleRemovenIcludeVisa(
												residenceIndex,
												visaTypeIndex,
												packageIndex,
												inc_index
											)
										}
									/>
								</span>
							)
						)}
					</div>
				</div>
			</div>
			{packageIndex !== configDataItem?.package_plans?.length - 1 ? (
				<hr className="opacity-100" />
			) : null}
		</div>
	);
}
