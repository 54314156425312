import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import Locale from "translations";
export default function OfflineReservationCompany({
	companyDetails,
	setCompanyDetails,
	countries,
	setErrors,
	errors,
}) {
	const { booking, backOffice, userDetails, login } = Locale;

	return (
		<>
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Company Details</p>
			</div>
			<div className="row pb-3 our-border p-0 m-0">
				{/* company_name */}
				<div className="col-4">
					<TextField
						label={backOffice.companyName}
						placeholder={backOffice.companyName}
						value={companyDetails.company_name}
						name="company_name"
						onChange={(e) => {
							setCompanyDetails({
								...companyDetails,
								company_name: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "company_name", value: e.target.value },
									{ required: true }
								),
							});
						}}
						color={errors?.company_name?.required ? "danger" : ""}
						errors={errors?.company_name}
					/>
				</div>

				{/* contact person */}
				<div className="col-4">
					<TextField
						label={booking.ContactPerson}
						placeholder={booking.ContactPerson}
						value={companyDetails.contact_person}
						name="company_name"
						onChange={(e) => {
							setCompanyDetails({
								...companyDetails,
								contact_person: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "contact_person", value: e.target.value },
									{ required: true }
								),
							});
						}}
						color={errors?.contact_person?.required ? "danger" : ""}
						errors={errors?.contact_person}
					/>
				</div>

				{/* email address */}
				<div className="col-4">
					<TextField
						type="email"
						label={login.emailAddress}
						placeholder={login.emailAddress}
						value={companyDetails.email}
						name="email"
						onChange={(e) => {
							setCompanyDetails({
								...companyDetails,
								email: e.target.value,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "email", value: e.target.value },
									{ required: true, email: true }
								),
							});
						}}
						color={errors?.email?.required ? "danger" : ""}
						errors={errors?.email}
					/>
				</div>

				{/*  country */}
				<div className="col-4">
					<SelectField
						label={backOffice.country}
						placeholder={backOffice.country}
						value={companyDetails.country}
						name="country"
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e.value },
									{ required: true }
								),
							});
							setCompanyDetails({
								...companyDetails,
								country: e,
							});
						}}
						color={errors?.country?.required ? "danger" : ""}
						errors={errors?.country}
					/>
				</div>

				{/* phone */}
				<div className="col-4">
					<TextField
						type="number"
						label={userDetails.phoneNumber}
						placeholder={userDetails.phoneNumber}
						name="phone"
						value={companyDetails.phone}
						extraText={
							companyDetails.country?.value
								? "+" + companyDetails.country?.value
								: "---"
						}
						extraTextPosition="prepend"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "phone", value: e.target.value },
									{ required: true, phone: true }
								),
							});
							setCompanyDetails({
								...companyDetails,
								phone: e.target.value,
							});
						}}
						color={
							errors?.phone?.required || errors?.phone?.phone ? "danger" : ""
						}
						errors={errors?.phone}
					/>
				</div>
			</div>
		</>
	);
}
