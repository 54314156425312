import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import { useContractState } from "context/contractRequest";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { addContractDetails } from "services/property";
import Locale from "translations";
import RejectModal from "../RejectModal";
import CancelModal from "./CancelModal";
import Photos from "./Photos";

const ContractDetails = ({ state, stateCurrency }) => {
	//console.log(stateCurrency?.property_details?.currencies);
	const { backOffice } = Locale;
	const [canceled, setCanceled] = useState(false);

	const [filePhoto, setFilePhoto] = useState(null);

	const dispatch = useContractState();
	let history = useHistory();
	const [propertyDetails, setPropertyDetails] = useState({
		contractDurationFrom: undefined,
		contractDurationTo: undefined,
		commissionType: "",
		commission: "",
		type: "",
		commissions: [],
		//commissionCurrencyValue: "",
		spin: false,
	});
	const [disabled, setDisabled] = useState(true);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const { id } = useParams();
	const [rejected, setRejected] = useState(false);

	const checkFormErrors = () => {
		let Error = [];
		// propertyDetails.commissions.map((res, index) => {
		// 	let newError = {
		// 		...validate(
		// 			{
		// 				name: `commissionCurrencyValue${index}`,
		// 				value: res?.value,
		// 			},
		// 			{
		// 				required: true,
		// 			}
		// 		),
		// 	};
		// 	Error.push(newError);
		// });
		// setErrors({ ...errors, ...Object.values(Error) });

		// setErrors({
		// 	...errors,
		// 	...validate(
		// 		{
		// 			name: "commissionCurrencyValue",
		// 			value: propertyDetails?.commissionCurrencyValue,
		// 		},
		// 		{ required: true }
		// 	),
		// });
	};
	let commissions = [
		{
			value: 10,
			apply_type: "add",
			currency: "SAR",
		},
		{
			value: 10,
			apply_type: "add",
			currency: "EGP",
		},
	];
	const [commissionsCurrency, setCommissionsCurrency] = useState(
		stateCurrency?.property_details?.currencies
	);
	useEffect(() => {
		setPropertyDetails({
			contractDurationFrom: stateCurrency?.contract?.duration_from,
			contractDurationTo: stateCurrency?.contract?.duration_to,
			commissionType: stateCurrency?.contract?.commissions[0]?.apply_type,
			commission: stateCurrency?.contract?.commissions[0]?.value,
			type: stateCurrency?.contract?.commission_type,
			commissions: stateCurrency?.contract?.commissions,
		});
	}, [stateCurrency]);

	useEffect(() => {
		setCommissionsCurrency(stateCurrency?.property_details?.currencies);

		if (propertyDetails?.type?.value == "fixed") {
			if (commissionsCurrency?.length > 0) {
				setPropertyDetails({
					...propertyDetails,
					commissions: [
						...commissionsCurrency.map((item, index) => {
							return {
								currency: item.currency_code,
								value: 0,
								apply_type: propertyDetails?.commissionType?.value,
							};
						}),
					],
				});
			}
		}
	}, [propertyDetails?.type, propertyDetails?.commissionType]);

	const submit = () => {
		setPropertyDetails({
			...propertyDetails,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		const submitAction = async () => {
			let payload = {};
			const formData = new FormData();
			if (
				isFormValid(errors) ||
				(Object.keys(errors).length == 0 && filePhoto != null)
			) {
				if (propertyDetails.commissionType.value == 1) {
					formData.append(
						`duration_from`,
						moment(propertyDetails.contractDurationFrom).format("YYYY-MM-DD")
					);
					formData.append(
						`duration_to`,
						moment(propertyDetails.contractDurationTo).format("YYYY-MM-DD")
					);
					formData.append(
						`commission_type`,
						propertyDetails.commissionType.value
					);
					formData.append(`commission_apply_type`, propertyDetails.type.value);
					formData.append(`commission`, propertyDetails.commission);
					formData.append(`file`, filePhoto);

					if (propertyDetails.type.value == "fixed") {
						formData.append(
							`commissions`,
							JSON.stringify(propertyDetails.commissions)
						);
					} else {
						formData.append(
							`commissions`,
							JSON.stringify([
								{
									apply_type: propertyDetails?.commissionType?.value,
									value: propertyDetails.commission,
									currency: "SAR",
								},
							])
						);
					}

					// payload = {
					//   duration_from: moment(propertyDetails.contractDurationFrom).format("YYYY-MM-DD"),
					//   duration_to: moment(propertyDetails.contractDurationTo).format("YYYY-MM-DD"),
					//   is_fixed: propertyDetails.commissionType.value,
					//   is_percentage: propertyDetails.type.value,
					//   commission: propertyDetails.commission,
					//   file: filePhoto
					// }
				} else {
					formData.append(
						`duration_from`,
						moment(propertyDetails.contractDurationFrom).format("YYYY-MM-DD")
					);
					formData.append(
						`duration_to`,
						moment(propertyDetails.contractDurationTo).format("YYYY-MM-DD")
					);
					// formData.append(`is_fixed`, propertyDetails.commissionType.value);
					formData.append(`file`, filePhoto);
					formData.append(
						`commission_apply_type`,
						propertyDetails.commissionType.value
					);
					formData.append(`commission_type`, propertyDetails.type.value);
					formData.append(`commission`, propertyDetails.commission);

					if (propertyDetails.type.value == "fixed") {
						formData.append(
							`commissions`,
							JSON.stringify(propertyDetails.commissions)
						);
					} else {
						formData.append(
							`commissions`,
							JSON.stringify([
								{
									apply_type: propertyDetails?.commissionType?.value,
									value: propertyDetails.commission,
									currency: "SAR",
								},
							])
						);
					}
					// payload = {
					//   duration_from: moment(propertyDetails.contractDurationFrom).format("YYYY-MM-DD"),
					//   duration_to: moment(propertyDetails.contractDurationTo).format("YYYY-MM-DD"),
					//   is_fixed: propertyDetails.commissionType.value,
					//   file: filePhoto
					// }
				}
				console.log("payload", payload);

				const res = await addContractDetails(id, formData);
				console.log("res", res.data);
				if (res.status === 200) {
					store.addNotification({
						title: "Done!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					history.push(`/contract-request?page=1`);
				}
			}
		};

		submitAction();
	}, [isErrorLoaded]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setPropertyDetails({ ...propertyDetails, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			setPropertyDetails({ ...propertyDetails, [name]: value });
		}
	};

	const Rejected = () => {
		setRejected(true);
	};

	console.log("propertyDetails101", propertyDetails);

	const handleCommission = (currency, value) => {
		if (
			propertyDetails.commissions.filter((res) => res.currency == currency)
				.length > 0
		) {
			let x = [...propertyDetails.commissions];
			x = x.map((res) => {
				if (res.currency == currency) {
					return {
						...res,
						value: value,
						apply_type: propertyDetails.commissionType.value,
					};
				} else {
					return res;
				}
			});
			setPropertyDetails({ ...propertyDetails, commissions: x });
		} else {
			setPropertyDetails({
				...propertyDetails,
				commissions: [...propertyDetails.commissions, { value, currency }],
			});
		}
	};
	return (
		<>
			<div className="property-details">
				<CancelModal isOpen={canceled} toggle={setCanceled} />
				<div className="row m-0">
					<div className="col-12 pr-4 p-0">
						<div className="row m-0">
							<div className="col-md-6 pl-0">
								<DatePickerField
									label={backOffice.contractDurationFrom}
									date={moment(propertyDetails?.contractDurationFrom)}
									onChangeDate={(date) =>
										setPropertyDetails({
											...propertyDetails,
											contractDurationFrom: date._d,
										})
									}
									isOutsideRange={(day) => {
										return false;
									}}
								/>
							</div>
							<div className="col-md-6 pr-0">
								<DatePickerField
									label={backOffice.contractDurationTo}
									date={moment(propertyDetails?.contractDurationTo)}
									onChangeDate={(date) =>
										setPropertyDetails({
											...propertyDetails,
											contractDurationTo: date._d,
										})
									}
									isOutsideRange={(day) => {
										return false;
									}}
								/>
							</div>

							<div className="col-md-6 pl-0">
								<SelectField
									id="commissionType"
									label={backOffice.commissionType}
									// placeholder={"Fixed"}
									name="commissionType"
									onChange={(e) => {
										handleChange({ name: "commissionType", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "commissionType", value: e },
												{ required: true }
											),
										});
									}}
									options={[
										{ label: "add", value: "add" },
										{ label: "deduct", value: "deduct" },
									]}
									//value={propertyDetails.commissionType}
									value={
										typeof propertyDetails.commissionType === "object"
											? propertyDetails.commissionType
											: {
													value: propertyDetails.commissionType,
													label: propertyDetails.commissionType,
											  }
									}
									color={errors.commissionType?.required ? "danger" : ""}
									errors={errors.commissionType}
								/>
							</div>

							<div className="col-md-6 pr-0">
								<div className="text-with-select">
									<SelectField
										label={backOffice.commission}
										placeholder={backOffice.commission}
										//value={propertyDetails.type}
										value={
											typeof propertyDetails.type === "object"
												? propertyDetails.type
												: {
														value: propertyDetails.type,
														label: propertyDetails.type,
												  }
										}
										id="commission-3"
										onChange={(e) => {
											handleChange({ name: "type", value: e });
										}}
										options={[
											{ label: "percentage", value: "percent" },
											{ label: "fixed", value: "fixed" },
										]}
									/>
									{propertyDetails.type?.value == "percent" ||
									propertyDetails.type == "percent" ? (
										<TextField
											label={backOffice.commission}
											placeholder={backOffice.commission}
											value={propertyDetails.commission}
											name="commission"
											id="commission-2"
											onChange={(e) => {
												handleChange(e);
											}}
										/>
									) : null}
								</div>
							</div>
							{propertyDetails?.type?.value == "fixed" ||
							propertyDetails?.type == "fixed"
								? commissionsCurrency?.length > 0 &&
								  commissionsCurrency.map((commissionItem, index) => {
										return (
											<>
												<div className="col-md-6 ">
													<SelectField
														disabled
														label={backOffice.commission}
														placeholder={backOffice.commission}
														value={{
															label: commissionItem.currency_code,
															value: index + 1,
														}}
														name="commissionCurrency"
														id="commissionCurrency"
														onChange={(e) => {
															handleChange({
																name: "commissionCurrency",
																value: e,
															});
														}}
													/>
												</div>
												<div className="col-md-6">
													<TextField
														label={backOffice.commission}
														placeholder={backOffice.commission}
														value={propertyDetails?.commissionCurrencyValue}
														name={`commissionCurrencyValue${index}`}
														onChange={(e) => {
															handleChange({
																name: `commissionCurrencyValue${index}`,
																value: e.target.value,
															});
															handleCommission(
																commissionItem.currency_code,
																e.target.value
															);
														}}
													/>
												</div>
											</>
										);
								  })
								: null}
						</div>

						<Photos
							setFilePhoto={setFilePhoto}
							disabled={disabled}
							setDisabled={setDisabled}
							photosState={stateCurrency?.file}
						/>

						<RejectModal isOpen={rejected} toggle={setRejected} ID={id} />
						<div className="row flex-row-reverse px-2">
							{state?.property_details?.status !== "Rejected" ? (
								<React.Fragment>
									<div className="col-md-2">
										<button
											className="btn btn-primary w-100 Reject-Button"
											id="basic-submit"
											onClick={() => Rejected()}
										>
											Reject
										</button>
									</div>
									<div className="col-md-2">
										<button
											className="btn btn-primary w-100"
											onClick={() => submit()}
										>
											confirm
										</button>
									</div>
								</React.Fragment>
							) : null}
							<div className="col-md-2">
								<button
									className="btn btn-secondary w-100"
									onClick={() => history.push("/contract-mangment?page=1")}
								>
									{backOffice.back}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContractDetails;
