import Pagination from "components/shared/Pagination";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function ListTransfers({ TransfersList, meta, goTo }) {
	return (
		<>
			<div className=" table-responsive px-4  our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>Ref.</th>
							<th>Agency</th>
							<th>Lead Name</th>
							<th>Confirmation Date</th>
							<th>Free cancelation charge Date</th>
							<th>Auto cancelation</th>
							<th>Source</th>
							<th>Price</th>
							<th>Currency</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{TransfersList.map((res) => {
							return (
								<tr key={res.id}>
									<td>{res?.reference}</td>
									<td>{res?.company_name}</td>
									<td>{res?.name}</td>
									<td>
										{res.confirmation_date
											? moment(res?.confirmation_date).format("YYYY-MM-DD")
											: "-"}
									</td>
									<td>
										{moment(res?.free_cancellation_date).format("YYYY-MM-DD")}
									</td>
									<td>
										{res.auto_cancellation_datemoment
											? (res?.auto_cancellation_datemoment).format("YYYY-MM-DD")
											: "-"}
									</td>
									<td>{res?.source}</td>
									<td>{`${res?.total_amount}`}</td>
									<td>{`${res?.currency}`}</td>
									<td>
										{" "}
										<span className={`statue-${res.status}`}>
											{res?.status}
										</span>
									</td>
									<td>
										{/* <ShowForPermission permission="Transfers-Bookings-View"> */}
										<Link to={`/view-bookings-transfer/${res.id}`}>
											<i class="fas fa-angle-right"></i>
										</Link>
										{/* </ShowForPermission> */}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</>
	);
}

export default ListTransfers;
