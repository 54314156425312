import moment from "moment";
export default function TourBookingDetailsCard({ reservationItem }) {
	return (
		<div className="tour-booking-item bg-white">
			<div className="d-flex flex-wrap">
				<div className="tour-card-img">
					<img
						src={reservationItem?.details?.images?.[0]}
						alt={`${reservationItem?.details?.name}`}
						className="img-fluid tour-booking-item-img"
					/>
				</div>
				<div className="d-flex flex-column mx-md-2">
					<h2 className="mb-2">
						<span className="font-weight-bold text-dark-blue h5">
							{reservationItem?.details?.name}
						</span>
					</h2>
					<i className="fal fa-sort-alt"></i>

					<div className="tour-booking-item-info">
						<i className="fas fa-map-marker-alt"></i>
						<p className="mx-1">
							{reservationItem?.details?.destinations?.map((city) => {
								return (
									<span key={city?.code}>
										{city?.name} ({city?.code}),{" "}
									</span>
								);
							})}
						</p>
					</div>

					<div className="tour-booking-item-info">
						<i className="far fa-calendar-alt"></i>
						<span className="mx-1 font-weight-bold">
							{moment(reservationItem?.start_date).format("DD/MM/YYYY")} (
							{moment(reservationItem?.start_date).format("dddd")})
						</span>
					</div>

					<div className="tour-booking-item-info">
						<i className="far fa-user"></i>
						<span className="mx-1 font-weight-bold">
							{reservationItem?.adults} Adults
						</span>
						{reservationItem?.children?.length > 0 ? (
							<span>
								{reservationItem?.children?.length}
								{"Children "}(
								{reservationItem?.children?.map((age, index) => {
									return (
										<span key={`age-${index}-${age}`}>
											{age}
											{index !== reservationItem?.children?.length - 1
												? ", "
												: " "}
										</span>
									);
								})}
								Years Old )
							</span>
						) : null}
					</div>
				</div>
			</div>

			<p className="font-weight-bold text-dark-blue mt-1">{"Remarks"}</p>
			{reservationItem?.details?.comments?.map((remark, remarkIdx) => {
				return <p key={`${remark?.type}-${remarkIdx}`}>{remark?.text}</p>;
			})}

			<p className="font-weight-bold text-dark-blue pt-2 border-top-gray">
				<span>
					{reservationItem?.adults} {"Adults"}
				</span>
				{reservationItem?.children?.length > 0 ? (
					<span>
						{reservationItem?.children?.length} {"Children"} (
						{reservationItem?.children?.map((age, index) => {
							return (
								<span key={`age-${index}-${age}`}>
									{age}
									{index !== reservationItem?.children?.length - 1
										? ", "
										: " "}
								</span>
							);
						})}
						Years Old)
					</span>
				) : null}{" "}
				({reservationItem?.details?.modality?.name})
			</p>

			{reservationItem?.details?.cancellationPolicies?.length > 0 ? (
				<div className="mt-3 tour-booking-item-cancelation">
					<div className="d-flex align-items-center flex-wrap py-3">
						<div className="col-md-6">
							<div className="">
								<div className="d-flex justify-content-between ">
									<p className="font-weight-bold h6">{"Cancellation Fees"}</p>
								</div>
								{reservationItem?.details?.cancellationPolicies?.map(
									(policy, index) => {
										return (
											<div
												className="d-flex justify-content-between"
												key={index}
											>
												<p className="tour-booking-item-blue-dark">
													{"From"}{" "}
													{moment(policy?.dateFrom).format("hh:mm A")}{" "}
													{moment(policy?.dateFrom).format("DD/MM/YYYY")}
												</p>
												<span className="text-light-danger font-weight-bold h6 text-14">
													{policy?.amount} {reservationItem?.currency}
												</span>
											</div>
										);
									}
								)}
							</div>
						</div>
						<div className="col-md-6 text-muted">
							Date and time are calculated based on local time in the
							destination. In case of no-show, different fees will apply.
							Please refer to our T&C.
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}
