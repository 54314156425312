import Pagination from "components/shared/Pagination";
import Locale from "translations";
function ReservationTable({ Reservation, setReservation, meta, goto }) {
	const { supplerReservation, backOffice } = Locale;
	const AllReservation = Reservation?.map((res) => {
		return (
			<tr key={res?.id}>
				<td>{res?.id}</td>
				<td>{res?.company?.name?.en}</td>
				<td>{res?.hotel_name}</td>
				<td>{res?.destination?.name} </td>
				<td>{res?.rooms_count}</td>
				<td>{res?.date_from}</td>
				<td>{res?.nights}</td>
				<td>{res?.created_at}</td>
				<td>{res?.total_price}</td>
				<td>{res?.currency}</td>
				<td className={`${res?.status} text-capitalize`}>{res?.status}</td>
			</tr>
		);
	});
	return (
		<>
			<div className="our-border  px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{supplerReservation.ref}</th>
							<th>{"Agency Name"}</th>
							<th>{supplerReservation.HotelName}</th>
							<th>{supplerReservation.Destination}</th>
							<th>{supplerReservation.NoofRooms}</th>
							<th>{supplerReservation.ChecKin}</th>
							<th>{supplerReservation.Nights}</th>
							<th>{supplerReservation.RequestDate}</th>
							<th>Price</th>
							<th>Currency</th>
							<th>{supplerReservation.Status}</th>
						</tr>
					</thead>
					<tbody>
						{Reservation?.length > 0 ? (
							AllReservation
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{Reservation?.length > 0 ? (
					<Pagination info={meta} goTo={goto} />
				) : null}
			</div>
		</>
	);
}

export default ReservationTable;
