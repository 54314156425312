import backArrow from "assets/images/backArrow.svg";
import noHotel from "assets/images/placeHoleder.png";
import { InvoicePrint } from "components/Printing/InvoicePrint";
import { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router-dom";
import Carousel from "react-spring-3d-carousel";
import { getInvouce } from "services/Request";
import Locale from "translations";
import safaImg from "../../../../../../assets/images/backOffice/safa_logo.png";
import RemarkModel from "./RemarkModel";

export default function BookingDetails({ details }) {
	const { company_id, reservation_id } = useParams();
	const [modalRemark, setModalRemark] = useState(false);
	const toggleModalRemark = () => {
		setModalRemark(!modalRemark);
	};
	const history = useHistory();
	const { marketPlace, backOffice } = Locale;
	const state = { tab: "hotel" };
	const roomType = (status) => {
		switch (status) {
			case 1:
				return backOffice.single;
			case 2:
				return backOffice.dauble;
			case 3:
				return backOffice.triple;
			case 4:
				return backOffice.quadruple;
			default:
				return "Rooms";
		}
	};
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);

	const totalPax = () => {
		let sumPax = 0;
		for (let i = 0; i < details.payload.rooms?.length; i++) {
			sumPax +=
				details.payload.rooms[i].adults + details.payload.rooms[i].children;
		}
		return sumPax;
	};

	useEffect(() => {
		let imagesList =
			details.hotel_data.images && details.hotel_data.images.length > 0
				? details.hotel_data.images.map((res, index) => {
						return {
							key: index + "uuid.v4(2)",
							content: (
								<img
									className="bg-white"
									src={res}
									alt={index + 1}
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = noHotel;
									}}
								/>
							),
							onClick: () => setActive(index),
						};
				  })
				: null;
		setSilders(imagesList);
	}, []);
	const dawnloadInvoice = async (brn) => {
		const invoice = await getInvouce(brn);
		const allRooms =
			invoice?.data?.data?.rooms?.length > 0
				? invoice?.data?.data?.rooms.map((room, index) => {
						return `		<tr>
							<td
								class=${
									+index + 1 === +invoice?.data?.data?.rooms.length
										? "last-td-border"
										: "remove-last-td-border"
								}
							>
								${+index === 0 ? invoice?.data?.data?.hotel_name : ""}
							</td>
							<td className="f-12">${room.name}</td>
							<td>
						
								${
									room?.passengers?.length > 0
										? room?.passengers.map((passenger) => {
												return `<p className="m-0 f-12">
												${passenger?.first_name} ${passenger?.last_name}
											</p>`;
										  })
										: "-"
								}
							</td>
							<td className="f-12">${invoice?.data?.data?.rooms?.length}</td>
							<td className="f-12">${room.adults}</td>
							<td className="f-12">${room.children}</td>
							<td className="f-12">${room.nights}</td>
							<td className="f-12">${room.from_date}</td>
							<td className="f-12">${room.to_date}</td>
							<td className="f-12">
								${room.price} ${invoice?.data?.data?.currency}
							</td>
						</tr>`;
				  })
				: [];

		if (invoice) {
			let respos = InvoicePrint;
			console.log(respos);
			respos = respos.replace("[allRooms]", allRooms);

			respos = respos.replace(
				"[invoiceNumber]",
				invoice?.data?.data?.invoiceNumber
			);
			respos = respos.replace(
				"[confirmationNumber]",
				invoice?.confirmationNumber
			);
			respos = respos.replace(
				"[company_name]",
				invoice?.data?.data?.company?.name
			);
			respos = respos.replace(
				"[company_name_sec]",
				invoice?.data?.data?.company?.name
			);
			respos = respos.replace(
				"[company_address]",
				invoice?.data?.data?.company?.address
					? invoice?.data?.data?.company?.address
					: "-"
			);

			respos = respos.replace(
				"[company_phone]",
				invoice?.data?.data?.company?.phone
			);
			respos = respos.replace(
				"[remark]",
				invoice?.data?.data?.remark
					? `<div class="border-section">
				<div class="row justify-content-start align-items-center  p-4 ">
					<div class=" col-12 d-flex flex-column">
						<p class="font-weight-bold mb-1">Agent Remarks</p>
						<p class="m-0">${invoice?.data?.data?.remark}</p>
					</div>
				</div>
			</div>`
					: ""
			);

			respos = respos.replace(
				"[total_price]",
				invoice?.data?.data?.total_price
			);

			respos = respos.replace(
				"[invoiceDate]",
				invoice?.data?.data?.invoiceDate
			);
			respos = respos.replace("[currency]", invoice?.data?.data?.currency);

			respos = respos.replace("[SafaLogo]", safaImg);
			respos = respos.replace(
				"[company_image]",
				invoice?.data?.data?.companyImage
					? `<img src=${invoice?.data?.data?.companyImage} alt="hotal-logo" class="img-fluid w-50" />`
					: `<div class="company-logo-font letter-logo p-5 ">
										${invoice?.data?.data?.company?.name[0]}
									</div>`
			);

			var popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>

						</style>
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	return (
		<>
			<section className="p-3 bg-white">
				<div className="">
					{/* <!-- start transport slider --> */}
					<div className="transport-slider-container px-0">
						<div className="border-bottom slider-headers mb-2 d-flex justify-content-between align-items-start">
							<div>
								<h5 className="font-weight-bold text-headline mb-0">
									{state.tab === "hotel"
										? details.hotel_data.name
										: details.transporter_data.name}
								</h5>

								<div className="d-flex">
									<p className="mb-0">
										{state.tab === "hotel"
											? details.hotel_data.address
											: details.transporter_data.address}
									</p>
									<span className="px-2">|</span>
									<p className="mb-0">
										{state.tab === "hotel"
											? details.hotel_data.distance + " m from Haram"
											: null}
									</p>
								</div>
								<ul className="list-unstyled d-flex m-0">
									{state.tab === "hotel"
										? [...Array(details.hotel_data.rating).keys()].map((i) => (
												<li>
													<i
														key={i}
														className="fa fa-star"
														aria-hidden="true"
													></i>
												</li>
										  ))
										: null}
								</ul>
							</div>

							<button
								onClick={() => history.goBack()}
								className="btn mx-2 d-flex border align-items-center"
							>
								<img src={backArrow} className="mx-2" alt="" srcset="" />
								{marketPlace.BackSearch}
							</button>
						</div>
						<div className="btns-booking mt-2 mb-4 d-flex justify-content-end">
							<button
								className=" btn btn-link text-uppercase"
								onClick={() => {
									toggleModalRemark();
								}}
							>
								<i class="fas fa-eye mx-1"></i>
								Remark
							</button>

							<button
								className=" btn btn-secondary text-uppercase"
								onClick={() => dawnloadInvoice(details.brn)}
							>
								<i class="fas fa-cloud-download-alt"></i> Download Invoice
							</button>
						</div>
						{state.tab === "hotel" && details.hotel_data.images.length > 0 ? (
							<div
								style={{
									height: "300px",
									width: "100%",
									overflow: "hidden",
									background: "#fff",
								}}
							>
								<Carousel
									slides={Silders}
									offsetRadius={6}
									goToSlide={active}
								/>
							</div>
						) : null}
						{/* <!-- Start OverView --> */}
						<div className="overview gray-box my-3">
							<div className="booking-header">
								<h5 className="px-2  py-2  font-weight-bold  mt-2 mb-2">
									{backOffice.overview}
								</h5>
							</div>
							<p className="mb-1 p-2 ">
								{state.tab === "hotel"
									? details.hotel_data.description
									: details.transporter_data.description}
							</p>
						</div>
						{/* <!-- End OverView --> */}
						{state.tab === "hotel" ? (
							<div className=" gray-box mt-3">
								<div className="booking-header">
									<h5 className="px-2 py-2 font-weight-bold  mt-2 mb-2">
										{backOffice.hotelFacility}
									</h5>
								</div>

								<ul className="px-1">
									{details?.hotel_data?.facilities &&
									details?.hotel_data?.facilities?.length > 0 ? (
										details?.hotel_data?.facilities.map((item, index) => {
											return (
												<li key={index}>
													<i
														style={{ color: "gray" }}
														className="fas fa-check px-1"
													></i>
													{item}
												</li>
											);
										})
									) : (
										// <td colSpan="9">
										<div className="w-100 no-data semiHeight d-flex justify-content-center align-items-center">
											<h4>
												{"No Facilities"}
												<i
													className="icx icx-eye-slash "
													aria-hidden="true"
												></i>
											</h4>
										</div>
										// </td>
									)}
								</ul>
							</div>
						) : null}
						{/* <div className="facilities booking-box my-1">
							<div className="booking-header">
								<h6 className="px-2 py-2  rounded font-weight-bold  mt-2 mb-2">
									{backOffice.Facilities}
								</h6>
							</div>
							<div className="facilities-content  box-container">
								<ul>
									{details?.hotel_data?.facilities &&
									details?.hotel_data?.facilities?.length > 0 ? (
										details?.hotel_data?.facilities.map((item, index) => {
											return (
												<li key={index}>
													<i className="fas fa-check px-1 text-success"></i>
													{item}
												</li>
											);
										})
									) : (
										// <td colSpan="9">
										<div className="w-100 no-data semiHeight d-flex justify-content-center align-items-center">
											<h4>
												{"No Facilities"}
												<i
													className="icx icx-eye-slash "
													aria-hidden="true"
												></i>
											</h4>
										</div>
										// </td>
									)}
								</ul>
							</div>
						</div> */}
						<div className="booking-details border mt-4">
							<h5 className="absolute-header">{backOffice.bookingDetails}</h5>

							<div class="row  p-2">
								<div class="col-md-6 col-12 my-2 px-0">
									<div class="row row-inner">
										<div class="col-4 key-side ">
											<h5>BOOKING DATE</h5>
										</div>
										<div class="col-8 value-side border-top">
											<p>{details.start_date}</p>
										</div>
									</div>
									<div class="row row-inner">
										<div class="col-4 key-side ">
											<h5>BOOKING SERVICE</h5>
										</div>
										<div class="col-8 value-side ">
											<p>{details?.hotel_name.substring(0, 43)}</p>
										</div>
									</div>
									<div class="row">
										<div class="col-4 key-side">
											<h5>NUMBER OF ROOMS</h5>
										</div>
										<div class="col-8 value-side">
											<p>{details?.rooms_number}</p>
										</div>
									</div>
									<div class="row">
										<div class="col-4 key-side">
											<h5 class="text-uppercase">Status</h5>
										</div>
										<div class="col-8 value-side">
											<p
												className={`${
													details?.reservation_status === "confirmed" ||
													details?.reservation_status === "approved" ||
													details?.reservation_status === "paid"
														? "text-success"
														: "text-danger"
												} font-weight-bold text-uppercase`}
											>
												{details?.reservation_status}
											</p>
										</div>
									</div>
								</div>
								<div class="col-md-6 col-12 my-2 px-0">
									<div class="row">
										<div class="col-4 key-side">
											<h5>TOTAL PAX</h5>
										</div>
										<div class="col-8 value-side border-top	">
											<p>{totalPax()}</p>
										</div>
									</div>
									<div class="row row-inner">
										<div class="col-4 key-side">
											<h5>Total Price</h5>
										</div>
										<div class="col-8 value-side">
											<p>
												{details?.total_price} {details?.currency}
											</p>
										</div>
									</div>
									<div class="row row-inner">
										<div class="col-4 key-side">
											<h5>Lead Passenger</h5>
										</div>
										<div class="col-8 value-side">
											<p>_</p>
										</div>
									</div>
									<div class="row row-inner">
										<div class="col-4 key-side">
											<h5>Payment</h5>
										</div>
										<div class="col-8 value-side">
											<p
												className={`${
													details?.payment_status === "paid" ||
													details?.payment_status === "confirmed"
														? "text-success"
														: details?.payment_status === "pending"
														? "text-warning"
														: "text-danger"
												} font-weight-bold text-uppercase`}
											>
												{details?.payment_status}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* <!-- Start Passenger Details --> */}
				</div>
			</section>
			<RemarkModel
				modal={modalRemark}
				toggleModal={toggleModalRemark}
				details={details}
			/>
		</>
	);
}
