import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { createCountry, listCountries } from "services/ads";
import Locale from "translations";
import CustomModal from "../Modal";
import Filter from "./Filter";
export default function PopupAdsCountries() {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const [countriesState, setCountriesState] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		page: 1,
	});
	const [countries, setCountries] = useState([]);
	const [Reload, setReload] = useState(false);
	const [IsOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!IsOpen);
	};
	const [countryValue, setCountryValue] = useState(null);
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};
	//Fetch Countries
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);
	useEffect(() => {
		const countriesListFn = async () => {
			const res = await listCountries({
				...filter,
			});
			if (res.status == 200) {
				setCountriesState(res.data.data);
				setMeta(res.data.meta);
			}
		};
		countriesListFn();
	}, [filter, Reload]);

	const AllCountries = countriesState?.map((item) => (
		<tr key={item?.id}>
			<td>{item?.country_name}</td>
			<td>{item?.no_of_items}</td>
			<td>
				{item?.active_ads ? (
					<i class="fas fa-check text-success"></i>
				) : (
					<i class="fas fa-times text-danger"></i>
				)}
			</td>
			<td>
				<Link to={`/view/popup-ads/${item?.id}`}>
					<i class="fas fa-angle-right"></i>
				</Link>
			</td>
		</tr>
	));
	const addCountry = async () => {
		if (countryValue != null) {
			const res = await createCountry({
				country_id: countryValue?.value,
			});
			if (res.status == 200) {
				toggle();
				store.addNotification({
					title: "Info!",
					message: res?.data?.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						setReload((prev) => !prev);
						setCountryValue(null);
					},
				});
			}
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between">
				<h4>Popup Ads</h4>
				<ShowForPermission permission="Popup-Ads-Manage">
					<button className="btn btn-info px-4" onClick={toggle}>
						Add Country
					</button>
				</ShowForPermission>
			</div>
			<Filter setFilter={setFilter} filter={filter} />
			<div className="our-border  px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th width={"35%"}>Country</th>
							<th>No. of items</th>
							<th>Active Ads</th>

							<th width={"5%"}></th>
						</tr>
					</thead>
					<tbody>
						{countriesState?.length > 0 ? (
							AllCountries
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{countriesState?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>
			<CustomModal
				modalIsOpen={IsOpen}
				header={"Add Country"}
				toggle={toggle}
				size={"md"}
				centered={true}
			>
				<div className=" col-md-12">
					<div className="p-2 row">
						<div className="col-md-12">
							<SelectField
								label={"Add Country"}
								value={countryValue}
								options={countries}
								onChange={(e) => {
									setCountryValue(e);
								}}
							/>
						</div>

						<button
							style={{ backgroundColor: "#FF6868" }}
							className="btn companies-btn w-100 mx-3"
							onClick={() => addCountry()}
						>
							Add
						</button>
					</div>
				</div>
			</CustomModal>
		</>
	);
}
