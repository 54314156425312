import axios from "axios";
import { cleanEmpty } from "./general";

const URL = process.env.REACT_APP_API_URL + "/api";

export const listCommericialDist = async (params) => {
	return await axios
		.get(`${URL}/consumers`, { params: cleanEmpty(params) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchCommericialDistById = async (id) => {
	return await axios
		.get(`${URL}/consumers/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addCommercialDist = async (data) => {
	return await axios
		.post(`${URL}/consumers`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editCommercialDist = async (id, data) => {
	return await axios
		.put(`${URL}/consumers/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistAssociateServices = async (id, data) => {
	return await axios
		.post(`${URL}/consumers/${id}/services`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistCommissionsList = async (id, serviceName) => {
	return await axios
		.get(`${URL}/consumers/${id}/commissions?service_name=${serviceName}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistAddCommission = async (id, data) => {
	return await axios
		.post(`${URL}/consumers/${id}/commissions`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistEditCommission = async (id, commissionId, data) => {
	return await axios
		.put(`${URL}/consumers/${id}/commissions/${commissionId}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistDeleteCommission = async (id, commissionId) => {
	return await axios
		.delete(`${URL}/consumers/${id}/commissions/${commissionId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistHotelList = async (id, params) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/hotel`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistWalletList = async (id, params) => {
	return await axios
		.get(`${URL}/consumers/wallet/${id}/transactions`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistChargeWallet = async (id, data) => {
	return await axios
		.put(`${URL}/consumers/wallet/${id}/charge`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistHotelView = async (id, brn) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/hotel/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistTransferList = async (id, params) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/transfer`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistTransferView = async (id, transferId) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/transfer/${transferId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistToursList = async (id, params) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/tour`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistToursView = async (id, tourId) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/tour/${tourId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistAirLineList = async (id, params) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/flight`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const commercialDistAirLineView = async (id, reservationId) => {
	return await axios
		.get(`${URL}/consumers/${id}/reservation/flight/${reservationId}`)
		.then((res) => res)
		.catch((err) => err.response);
};
