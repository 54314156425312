import React from "react";
import { useGlobalState } from "../context/global";

export default function ShowForPermission(props) {
	const { permissions, role } = useGlobalState();
	if (permissions && permissions?.length > 0 && permissions != "[]") {
		const type = props.type ? props.type : "or";
		if (Array.isArray(props.permission) && type === "or") {
			return props.permission?.some((per) => permissions?.includes(per)) ? (
				<React.Fragment>{props.children}</React.Fragment>
			) : null;
		} else if (Array.isArray(props.permission) && type === "and") {
			return props.permission?.every((per) => permissions?.includes(per)) ? (
				<React.Fragment>{props?.children}</React.Fragment>
			) : null;
		} else {
			return permissions?.includes(props.permission) ? (
				<React.Fragment>{props.children}</React.Fragment>
			) : null;
		}
	} else if (role == "Super Admin") {
		return <React.Fragment>{props.children}</React.Fragment>;
	} else {
		return null;
	}
}
