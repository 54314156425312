import { useEffect, useState } from "react";
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from "react-router-dom";
import { viewVendorReservation } from "services/vendor";
import Locale from "translations";
import Booking from "./Booking";
import Financial from "./Financial";
import Passangers from "./Passangers";
import Payments from "./Payments";
export default function ReservationView() {
	const { id, brn } = useParams();
	const { vendor, booking } = Locale;
	const { path, url } = useRouteMatch();
	const { push, location } = useHistory();
	const { pathname } = location;
	const [bookingPassengerData, setBookingPassengerData] = useState();

	useEffect(() => {
		const viewReservation = async () => {
			const res = await viewVendorReservation(id, brn);
			setBookingPassengerData(res?.data?.data);
		};
		viewReservation();
	}, []);

	const tabsContent = [
		{
			id: 1,
			route: "booking",
			component: <Booking bookingInfo={bookingPassengerData} />,
		},
		{
			id: 2,
			route: "passangers",
			component: <Passangers passangerInfo={bookingPassengerData} />,
		},
		{
			id: 3,
			route: "financial",
			component: <Financial finacial={bookingPassengerData} />,
		},
		{
			id: 4,
			route: "payments",
			component: <Payments payment={bookingPassengerData} />,
		},
	];

	return (
		<div className="vendor">
			<h3 className="txt-blue font-weight-bold col">{vendor.vendor}</h3>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between  ">
				<div className="d-flex ">
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${pathname.endsWith("booking") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/booking`);
						}}
					>
						{booking.booking}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${pathname.endsWith("passangers") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/passangers`);
						}}
					>
						{booking.Passengers}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${pathname.endsWith("financial") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/financial`);
						}}
					>
						{booking.Financial}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${pathname.endsWith("payments") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/payments`);
						}}
					>
						{booking.Payments}
					</button>
				</div>
			</div>

			<Switch>
				{tabsContent.map((pane) => (
					<Route path={`${path}/${pane.route}`}>{pane.component}</Route>
				))}
			</Switch>
		</div>
	);
}
