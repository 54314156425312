import CheckBox from "components/chekbox";
import SelectField from "components/shared/SelectField";
import { useState } from "react";
import {
	DownArraw,
	FlightIcon,
	HotelIcon,
	MazaratIcon,
	TransportationIcon,
	UpArraw,
	VisaIcon,
} from "../shared/Icons";

export default function ServiceItems({
	name,
	allData,
	setData,
	gdsLookups,
	addNew,
	servicesLookups,
}) {
	const [isOpen, setisOpen] = useState(name == "hotel" ? true : false);
	const data = allData[name];
	const handleCommission = (
		service_name,
		commission_input,
		value,
		index = null
	) => {
		const serviceData = allData[service_name];
		if (Array.isArray(serviceData)) {
			if (commission_input === "gds") {
				serviceData[index][commission_input] = value;
			} else {
				serviceData[index][commission_input] = value;
			}
		} else {
			if (commission_input === "gds") {
				serviceData[commission_input] = value;
			} else {
				serviceData[commission_input] = value;
			}
		}

		setData({ ...allData, [name]: serviceData });
	};
	const handleCheckbox = (typeCheck, value, index = null) => {
		let serviceData = allData[name];
		if (typeCheck === "parent") {
			if (Array.isArray(serviceData)) {
				serviceData = serviceData.map((service) => ({
					...service,
					is_active: value,
				}));
			} else {
				serviceData["is_active"] = value;
			}
		} else {
			if (Array.isArray(serviceData)) {
				serviceData[index]["is_active"] = value;
			} else {
				serviceData["is_active"] = value;
			}
		}

		setData({ ...allData, [name]: serviceData });
	};
	const serviceIcon = () => {
		switch (name) {
			case "hotel":
				return <HotelIcon />;
			case "tour":
				return <MazaratIcon />;
			case "transfer":
				return <TransportationIcon />;
			case "flight":
				return <FlightIcon />;
			case "online_visa":
				return <VisaIcon />;
			default:
				return null;
		}
	};

	console.log("allDataallDataallData", allData);
	return (
		<div className="service-item">
			<div className="name-item">
				<div className="info">
					<CheckBox
						type="Checkbox"
						name={name}
						id={name}
						checked={
							Array.isArray(data)
								? data.every((item) => item?.is_active)
								: data.is_active
						}
						onChange={(e) => {
							handleCheckbox("parent", e.target.checked);
						}}
					/>
					<div>
						{serviceIcon()}
						{/* <HotelIcon /> */}
						<p>{name?.replace(/_/g, " ")}</p>
					</div>
				</div>
				<button
					className="btn btn-link"
					onClick={() => setisOpen((prev) => !prev)}
				>
					{isOpen ? <UpArraw /> : <DownArraw />}
				</button>
			</div>
			{isOpen ? (
				<div className="providers">
					<div className="labels">
						<div className="row">
							<div className="col-md-4 provider-col">
								<p>Providers</p>
							</div>
							<div className="col-md-2">
								<p>Base Commission</p>
							</div>

							<div className="col-md-2">
								<p>Safa Commission</p>
							</div>

							<div className="col-md-2">
								<p>Company Commission</p>
							</div>
						</div>
					</div>
					{Array.isArray(data) ? (
						data?.length > 0 &&
						data.map((item, index) => (
							<InfoProvider
								name={name}
								data={item}
								handleCommission={handleCommission}
								gdsLookups={servicesLookups[name]}
								index={index}
								handleCheckbox={handleCheckbox}
							/>
						))
					) : (
						<InfoProvider
							name={name}
							data={data}
							handleCommission={handleCommission}
							gdsLookups={servicesLookups[name]}
							handleCheckbox={handleCheckbox}
							index={null}
						/>
					)}
					{/* && data[0]?.type === "static"  */}
					{Array.isArray(data) ? (
						<div className="add-new-commission">
							<button
								className="btn btn-link text-success"
								onClick={() => addNew(name)}
							>
								<i className="fas fa-plus-circle" /> Add New
							</button>
						</div>
					) : null}
				</div>
			) : null}
		</div>
	);
}

export function InfoProvider({
	name,
	data,
	handleCommission,
	gdsLookups,
	handleCheckbox,
	index,
}) {
	const isError = +data?.safa_commission + +data?.platform_commission !== 100;
	return (
		<div className="info-item">
			<div className="row">
				<div className="col-md-4 provider-col">
					<CheckBox
						type="Checkbox"
						name={name}
						id={name}
						checked={data.is_active}
						onChange={(e) => {
							// handleCommission(name, "is_active", e.target.checked);
							handleCheckbox("child", e.target.checked, index);
						}}
					/>
					<div className="gds-select">
						{/* <p>{gds[data?.gds]}</p> */}
						{data?.type === "static" ? (
							<SelectField
								id="gds"
								name="gds"
								hasLabel={false}
								placeholder={"Select gds"}
								value={data?.gds}
								options={gdsLookups}
								onChange={(e) => {
									handleCommission(name, "gds", e, index);
								}}
							/>
						) : (
							<p className="text-center">{data.gds_name?.replace(/_/g, " ")}</p>
						)}
					</div>
				</div>
				<div className="col-md-2 input-commission">
					<input
						type="number"
						name="base_commission"
						id="base_commission"
						value={data?.base_commission}
						onChange={(e) => {
							handleCommission(name, "base_commission", e.target.value, index);
						}}
					/>
				</div>

				<div className="col-md-2 input-commission">
					<input
						className={`${isError && data.is_active ? "border-danger" : ""}`}
						type="number"
						name="safa_commission"
						id="safa_commission"
						value={data?.safa_commission}
						onChange={(e) => {
							handleCommission(name, "safa_commission", e.target.value, index);
						}}
					/>
				</div>

				<div className="col-md-2 input-commission">
					<input
						type="number"
						name="platform_commission"
						id="platform_commission"
						className={`${isError && data.is_active ? "border-danger" : ""}`}
						value={data?.platform_commission}
						onChange={(e) => {
							handleCommission(
								name,
								"platform_commission",
								e.target.value,
								index
							);
						}}
					/>
				</div>
				<div className="col-md-1 percentage">%</div>
				<div className="col-md-4"></div>
				<div className="col-md-2"></div>

				<div className="col-md-5">
					{isError && data.is_active ? (
						<p className="text-danger">
							* Safa Commission + Company Commission Must equal 100
						</p>
					) : null}
				</div>
			</div>
		</div>
	);
}
