import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	applySubscriptionCommission,
	viewSubscriptionCommission,
} from "services/companies";
import { fetchProviders } from "services/lookups";
import ServiceItems from "./serviceItems";

export default function CommissionSubscriptionPlan() {
	const history = useHistory();
	const { company_id, plan_id } = useParams();
	const [data, setData] = useState({});
	const static_data = {
		hotel: [
			{
				is_active: 0,
				reservation_type: "hotel",
				gds: undefined,
				gds_name: undefined,
				type: "static",
			},
		],
		tour: [
			{
				is_active: 0,
				reservation_type: "tour",
				gds: undefined,
				gds_name: undefined,
				type: "static",
			},
		],
		transfer: [
			{
				is_active: 0,
				reservation_type: "transfer",
				gds: undefined,
				gds_name: undefined,
				type: "static",
			},
		],
		flight: [
			{
				is_active: 0,
				reservation_type: "flight",
				gds: undefined,
				gds_name: undefined,
				type: "static",
			},
		],
		online_visa: [
			{
				is_active: 0,
				reservation_type: "online_visa",
				gds: undefined,
				gds_name: undefined,
				type: "static",
			},
		],
	};
	const [gdsLookups, setGdsLookups] = useState([]);

	const addNew = (name) => {
		let cloneData = data;
		let selectedService = cloneData[name];
		selectedService.push({
			is_active: 0,
			reservation_type: name,
			gds: undefined,
			gds_name: undefined,
			type: "static",
		});
		setData({ ...cloneData });
	};
	useEffect(() => {
		//viewSubscriptionCommission
		async function FetchCommission() {
			const res = await viewSubscriptionCommission(company_id, plan_id);
			//setData(res?.data?.data || static_data);
			setData(
				Array.isArray(res?.data?.data)
					? static_data
					: { ...static_data, ...res?.data?.data }
			);
			console.log("allData", { ...static_data, ...res?.data?.data });
		}
		FetchCommission();
	}, [company_id, plan_id]);

	const [servicesLookups, setServicesLookups] = useState({
		hotel: [],
		transfer: [],
		tour: [],
		flight: [],
		online_visa: [],
	});
	// gds lookups
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchProviders();
			if (res.status == 200 || res?.data?.data?.length > 0) {
				const format = res?.data?.data?.map((t) => ({
					value: t.id,
					label: t.name,
					services: t.services,
				}));
				setGdsLookups(format);
				setServicesLookups({
					...servicesLookups,
					hotel: format?.filter((item) => item?.services?.includes("hotels")),
					transfer: format?.filter((item) =>
						item?.services?.includes("transfers")
					),
					tour: format?.filter((item) => item?.services?.includes("tours")),
					flight: format?.filter((item) => item?.services?.includes("flight")),
				});
			}
		}
		fetchLookups();
	}, []);

	console.log("servicesLookups", servicesLookups);

	const apply = async () => {
		let all_result = [];
		const data_res = {
			// commissions: Object.entries(data)
			// 	.filter(([key, value]) => value?.is_active)
			// 	.map(([key, value]) => {
			// 		return {
			// 			reservation_type: value?.reservation_type,
			// 			base_commission: +value?.base_commission,
			// 			safa_commission: +value?.safa_commission,
			// 			platform_commission: +value?.platform_commission,
			// 			gds: value?.gds?.value ?? value?.gds,
			// 			is_active: value?.is_active ? 1 : 0,
			// 		};
			// 	}),

			commissions: Object.entries(data).map(([key, value], index) => {
				if (Array.isArray(value)) {
					const result = value
						.filter((item) => item.is_active)
						.map((item) => ({
							reservation_type: item?.reservation_type,
							base_commission: +item?.base_commission,
							safa_commission: +item?.safa_commission,
							platform_commission: +item?.platform_commission,
							gds: item?.gds?.value ?? item?.gds,
							is_active: item?.is_active ? 1 : 0,
						}));
					all_result.push(result);
				} else {
					return {
						reservation_type: value?.reservation_type,
						base_commission: +value?.base_commission,
						safa_commission: +value?.safa_commission,
						platform_commission: +value?.platform_commission,
						gds: value?.gds?.value ?? value?.gds,
						is_active: value?.is_active ? 1 : 0,
					};
				}
				if (Array.isArray(value) && Object.keys(data).length - 1 === index) {
					return all_result.flat();
				}
			}),
		};
		const commissions = data_res?.commissions?.filter((item) => item)?.flat();

		const res = await applySubscriptionCommission(
			company_id,
			plan_id,
			//data_res
			{ commissions }
		);
		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "Update Commission successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push(`/tourism-company/${company_id}/subscription-plan`);
				},
			});
		}
	};
	console.log("dataaaaa", data);
	return (
		<div className="subscription-commission">
			<div className="head">
				<h6>
					<i
						className="fas fa-chevron-left"
						role="button"
						onClick={() =>
							history.push(`/tourism-company/${company_id}/subscription-plan`)
						}
					></i>
					<span className="mx-2">Commission</span>
				</h6>
				<button className="btn btn-primary" onClick={() => apply()}>
					Save & Apply
				</button>
			</div>
			<div className="services-list">
				<div className="service-head">
					<p>Services Listing</p>
				</div>
				{Object.entries(data).map(([key, value]) => {
					return (
						<ServiceItems
							name={key}
							allData={data}
							setData={setData}
							gdsLookups={gdsLookups}
							addNew={addNew}
							servicesLookups={servicesLookups}
						/>
					);
				})}
			</div>
		</div>
	);
}
