export function UpArraw({ width = 30, height = 30, stroke = "#707170" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M7.5 18.75L15 11.25L22.5 18.75"
				stroke={stroke}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.5 18.75L15 11.25L22.5 18.75"
				stroke="black"
				stroke-opacity="0.1"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export function DownArraw() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M22.5 11.25L15 18.75L7.5 11.25"
				stroke="#707170"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.5 11.25L15 18.75L7.5 11.25"
				stroke="black"
				stroke-opacity="0.1"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function HotelIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<rect width="48" height="48" rx="24" fill="#F8F9FA" />
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="23.5"
				stroke="#9C9FAB"
				stroke-opacity="0.24"
			/>
			<g clip-path="url(#clip0_16817_66978)">
				<g mask="url(#mask0_16817_66978)">
					<path
						d="M16.4688 35.2969H31.5313V16.4687H16.4688V35.2969Z"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M31.5313 14.2094C31.5313 13.8099 31.3727 13.4269 31.09 13.1445C30.8075 12.8617 30.4246 12.7031 30.025 12.7031H17.975C17.5755 12.7031 17.1925 12.8617 16.9101 13.1445C16.6273 13.4269 16.4688 13.8099 16.4688 14.2094V16.4688H31.5313V14.2094Z"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M22.4937 35.2969H25.5062V30.7781H22.4937V35.2969Z"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.4814 19.4813V20.2344"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M22.4937 19.4813V20.2344"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M25.5063 19.4813V20.2344"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M28.5186 19.4813V20.2344"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.4814 23.2469V24"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M22.4937 23.2469V24"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M25.5063 23.2469V24"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M28.5186 23.2469V24"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.4814 27.0125V27.7656"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M22.4937 27.0125V27.7656"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M25.5063 27.0125V27.7656"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M28.5186 27.0125V27.7656"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.4814 30.7782V31.5312"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M28.5186 30.7782V31.5312"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12.7031 35.2969H35.2969"
						stroke="#9C9FAB"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_16817_66978">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(12 12)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
export function FlightIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<rect width="48" height="48" rx="24" fill="#F8F9FA" />
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="23.5"
				stroke="#9C9FAB"
				stroke-opacity="0.24"
			/>
			<g clip-path="url(#clip0_16817_67032)">
				<path
					d="M21.0302 33.6898L23.3602 31.7298C23.7102 31.4298 24.2902 31.4298 24.6402 31.7298L26.9702 33.6898C27.5102 33.9598 28.1702 33.6898 28.3702 33.1098L28.8102 31.7798C28.9202 31.4598 28.8102 30.9898 28.5702 30.7498L26.3002 28.4698C26.1302 28.3098 26.0002 27.9898 26.0002 27.7598V24.9098C26.0002 24.4898 26.3102 24.2898 26.7002 24.4498L31.6102 26.5698C32.3802 26.8998 33.0102 26.4898 33.0102 25.6498V24.3598C33.0102 23.6898 32.5102 22.9198 31.8902 22.6598L26.3002 20.2498C26.1402 20.1798 26.0002 19.9698 26.0002 19.7898V16.7898C26.0002 15.8498 25.3102 14.7398 24.4702 14.3098C24.1702 14.1598 23.8202 14.1598 23.5202 14.3098C22.6802 14.7398 21.9902 15.8598 21.9902 16.7998V19.7998C21.9902 19.9798 21.8502 20.1898 21.6902 20.2598L16.1102 22.6698C15.4902 22.9198 14.9902 23.6898 14.9902 24.3598V25.6498C14.9902 26.4898 15.6202 26.8998 16.3902 26.5698L21.3002 24.4498C21.6802 24.2798 22.0002 24.4898 22.0002 24.9098V27.7598C22.0002 27.9898 21.8702 28.3098 21.7102 28.4698L19.4402 30.7498C19.2002 30.9898 19.0902 31.4498 19.2002 31.7798L19.6402 33.1098C19.8202 33.6898 20.4802 33.9698 21.0302 33.6898Z"
					stroke="#9C9FAB"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_16817_67032">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(12 12)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
export function TransportationIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<rect width="48" height="48" rx="24" fill="#F8F9FA" />
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="23.5"
				stroke="#9C9FAB"
				stroke-opacity="0.24"
			/>
			<path
				d="M30.75 17.25H17.25C16.4216 17.25 15.75 17.9216 15.75 18.75V24.75C15.75 25.5784 16.4216 26.25 17.25 26.25H30.75C31.5784 26.25 32.25 25.5784 32.25 24.75V18.75C32.25 17.9216 31.5784 17.25 30.75 17.25Z"
				stroke="#9C9FAB"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M30.75 26.25H17.25C16.4216 26.25 15.75 26.9216 15.75 27.75V30.75C15.75 31.5784 16.4216 32.25 17.25 32.25H30.75C31.5784 32.25 32.25 31.5784 32.25 30.75V27.75C32.25 26.9216 31.5784 26.25 30.75 26.25Z"
				stroke="#9C9FAB"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M30.75 17.25H17.25C16.8525 17.2489 16.4716 17.0905 16.1906 16.8094C15.9095 16.5284 15.7511 16.1475 15.75 15.75C15.7511 15.3525 15.9095 14.9716 16.1906 14.6906C16.4716 14.4095 16.8525 14.2511 17.25 14.25H30.75C31.1475 14.2511 31.5284 14.4095 31.8094 14.6906C32.0905 14.9716 32.2489 15.3525 32.25 15.75C32.2489 16.1475 32.0905 16.5284 31.8094 16.8094C31.5284 17.0905 31.1475 17.2489 30.75 17.25ZM18.75 32.25V33.2812C18.75 33.4056 18.7006 33.5248 18.6127 33.6127C18.5248 33.7006 18.4056 33.75 18.2812 33.75H16.9688C16.8444 33.75 16.7252 33.7006 16.6373 33.6127C16.5494 33.5248 16.5 33.4056 16.5 33.2812V32.25H18.75ZM31.5 32.25V33.2812C31.5 33.4056 31.4506 33.5248 31.3627 33.6127C31.2748 33.7006 31.1556 33.75 31.0312 33.75H29.7188C29.5944 33.75 29.4752 33.7006 29.3873 33.6127C29.2994 33.5248 29.25 33.4056 29.25 33.2812V32.25H31.5Z"
				stroke="#9C9FAB"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M29.25 30C29.6642 30 30 29.6642 30 29.25C30 28.8358 29.6642 28.5 29.25 28.5C28.8358 28.5 28.5 28.8358 28.5 29.25C28.5 29.6642 28.8358 30 29.25 30Z"
				stroke="#9C9FAB"
				stroke-linejoin="round"
			/>
			<path
				d="M18.75 30C19.1642 30 19.5 29.6642 19.5 29.25C19.5 28.8358 19.1642 28.5 18.75 28.5C18.3358 28.5 18 28.8358 18 29.25C18 29.6642 18.3358 30 18.75 30Z"
				stroke="#9C9FAB"
				stroke-linejoin="round"
			/>
			<path
				d="M24 17.25V26.25M15.75 15.75V29.25M32.25 15.75V29.25"
				stroke="#9C9FAB"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function VisaIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<rect width="48" height="48" rx="24" fill="#F8F9FA" />
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="23.5"
				stroke="#9C9FAB"
				stroke-opacity="0.24"
			/>
			<path
				d="M29.8247 35.5H15.4814V15.431H29.8247C31.3124 15.431 32.5185 16.6047 32.5185 18.0525V32.8785C32.5185 34.3263 31.3124 35.5 29.8247 35.5Z"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.4814 15.4316L26.7172 12.5995C28.5001 12.088 30.2907 13.641 30.0627 15.4316"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M29.4774 24.3912C29.4774 27.3357 27.0248 29.7227 23.9992 29.7227C20.9736 29.7227 18.521 27.3357 18.521 24.3912C18.521 21.4468 20.9736 19.0599 23.9992 19.0599C27.0248 19.0599 29.4774 21.4468 29.4774 24.3912Z"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.5332 19.1019C21.4998 23.0397 21.4839 25.7555 23.5004 29.6777"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M24.4896 29.6777C26.508 25.749 26.5087 23.0332 24.4771 19.1019"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.6841 24.3906H29.3059"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.7231 32.6309H21.2664"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M24.2129 32.6309H29.4779"
				stroke="#9C9FAB"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function MazaratIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<rect width="48" height="48" rx="24" fill="#F8F9FA" />
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="23.5"
				stroke="#9C9FAB"
				stroke-opacity="0.24"
			/>
			<g clip-path="url(#clip0_16817_67069)">
				<path
					d="M12.7031 28.4178H35.2964M12.7031 35.2961H35.2964M33.1402 28.4178V24.6146H14.8593V28.4178M30.0934 28.4178V24.6146M27.0466 28.4178V24.6146M23.9998 28.4178V24.6146M20.9529 28.4178V24.6146M17.9061 28.4178V24.6146M31.5 24.6146C31.5 20.4922 28.1582 17.1504 24.0359 17.1504H23.9636C19.8413 17.1504 16.4995 20.4922 16.4995 24.6146M14.2261 28.4178H33.8846V35.2961H14.2261V28.4178Z"
					stroke="#9C9FAB"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M24.0555 12.7031V14.907M26.3518 17.5185V17.2629C26.3518 15.964 25.2988 14.911 23.9999 14.911C22.7009 14.911 21.6479 15.964 21.6479 17.2629V17.5185M18.3943 30.928C17.5482 30.928 16.8623 31.6139 16.8623 32.46V35.2956H19.9262V32.46C19.9262 31.6139 19.2403 30.928 18.3943 30.928ZM23.9999 30.928C23.1538 30.928 22.4679 31.6139 22.4679 32.46V35.2956H25.5318V32.46C25.5318 31.6139 24.8459 30.928 23.9999 30.928ZM29.6055 30.928C28.7594 30.928 28.0735 31.6139 28.0735 32.46V35.2956H31.1375V32.46C31.1375 31.6139 30.4515 30.928 29.6055 30.928Z"
					stroke="#9C9FAB"
					stroke-miterlimit="10"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_16817_67069">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(12 12)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
