import checkmarkIcon from 'assets/images/icons/checkmark.svg';
import errorIcon from 'assets/images/icons/error-icon.svg';
import moment from 'moment';


export default function SummaryTab({ packageDetails }) {
	const dealStatus = packageDetails?.deal_status;
	const travelersCount = packageDetails?.travelers?.length;
	const travelersHasEnumbers = packageDetails?.travelers?.filter(traveler => traveler?.e_number !== null)?.length;
	const travelersHasVisa = packageDetails?.travelers?.filter(traveler => traveler?.visa_number !== null)?.length;
	const lastPassportSubmited = packageDetails?.travelers?.sort((a, b) => moment(a?.created_at) - moment(b?.created_at))[0];


	return (
		<div className="summarybox">
			<h4 className="summaryhead"> Summary Details</h4>
			<div className="info-box row">
				<div className="col-6">
					<div className="info-row">
						<p className="label">RefNo</p>
						<p className="info">{packageDetails?.reference_num}</p>
					</div>
					<div className="info-row">
						<p className="label">owner</p>
						<p className="info">{packageDetails?.owner?.full_name}</p>
					</div>
					<div className="info-row">
						<p className="label">phone</p>
						<p className="info">{packageDetails?.owner?.phone_code}{packageDetails?.owner?.phone}</p>
					</div>
					<div className="info-row">
						<p className="label">Email</p>
						<p className="info">{packageDetails?.owner?.email}</p>
					</div>
					<div className="info-row">
						<p className="label">departure</p>
						<p className="info"> {packageDetails?.departure_date ? moment(packageDetails?.departure_date).format("DD-MMM-YYYY") : ""}</p>
					</div>
					<div className="info-row">
						<p className="label">nights</p>
						<p className="info">{packageDetails?.nights}</p>
					</div>
					<div className="info-row">
						<p className="label">Package</p>
						<p className="info">{packageDetails?.trip_name}</p>
					</div>
					<div className="info-row">
						<p className="label">pax</p>
						<p className="info">{packageDetails?.pax}</p>
					</div>
					<div className="info-row">
						<p className="label">Travel Agent</p>
						<p className="info">{packageDetails?.travel_agent}</p>
					</div>
				</div>
				<div className="col-6">
					<div className="info-row">
						<p className="label text-capitalize">total</p>
						<p className="info">{(+packageDetails?.total_price)?.toFixed(2)} {packageDetails?.currency}</p>
					</div>
					<div className="info-row">
						<p className="label text-capitalize">paid</p>
						<p className="info text-success">{(+packageDetails?.payments_total_paid)?.toFixed(2)} {packageDetails?.currency}</p>
					</div>
					<div className="info-row">
						<p className="label text-capitalize">Outstanding</p>
						<p className="info text-warning">{(+packageDetails?.payments_outstanding)?.toFixed(2)} {packageDetails?.currency}</p>
					</div>
					<div className="info-row">
						<p className="label text-capitalize">status</p>
						<div>
							<p className="info m-0 w-100 text-capitalize">
								{dealStatus?.payment_done ?
									<img className='mx-1' src={checkmarkIcon} alt="" />
									:
									<img className='mx-1' src={errorIcon} alt="" />
								}
								<span>Payment</span>
								<span className='mx-1'>{packageDetails?.payments?.length > 0 ? moment(packageDetails?.payments?.[0]?.pay_date).format("DD-MMM-YYYY | hh:mm") : moment(packageDetails?.created_at).format("DD-MMM-YYYY | hh:mm")}</span>
							</p>
							<p className="info m-0 w-100 text-capitalize">
								{dealStatus?.passport_submitted ?
									<img className='mx-1' src={checkmarkIcon} alt="" />
									:
									<img className='mx-1' src={errorIcon} alt="" />
								}
								<span>Passport Submitted</span>
								<span className='mx-1'>{moment(lastPassportSubmited?.created_at).format("DD-MMM-YYYY | hh:mm")}</span>

							</p>
							<p className="info m-0 w-100 text-capitalize">
								{dealStatus?.e_number ?
									<img className='mx-1' src={checkmarkIcon} alt="" />
									:
									<img className='mx-1' src={errorIcon} alt="" />
								}
								E-Number ({travelersHasEnumbers}/{travelersCount})
							</p>
							<p className="info m-0 w-100 text-capitalize">
								{dealStatus?.visa_number ?
									<img className='mx-1' src={checkmarkIcon} alt="" />
									:
									<img className='mx-1' src={errorIcon} alt="" />
								}
								Visa Number ({travelersHasVisa}/{travelersCount})
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
