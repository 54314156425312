import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { exportToExcel, getpackageBookingList } from "services/b2c";
import { fetchCompaniesLookups } from "services/sbsReservations";
import PriorityModel from "./Models/PriorityModel";
import PackagesBookingFilter from "./PackagesBookingFilter";
import PackagesBookinglist from "./PackagesBookinglist";

function PackagesBooking() {
	const [isOpen, setisOpen] = useState(false);
	const toggle = () => {
		setisOpen(!isOpen);
	};
	const [select, setselect] = useState([]);
	const [isFilter, setIsFilter] = useState(false);
	const [filter, setFilter] = useState({
		owner_name: "",
		request_date: undefined,
		departure_date: undefined,
		pax: { from: "", to: "" },
		paid: { from: "", to: "" },
		deal_status: "",
		priority: "",
		after_departure: { date: undefined, status: "" },
		status: "",
		travel_agent: "",
		night: { from: "", to: "" },
		total: { from: "", to: "" },
		outstanding: { from: "", to: "" },
		deal_closing: "",
		before_departure: { date: undefined, status: "" },
		//page: 1,
	});
	const [packagesList, setPackagesList] = useState([]);
	const [meta, setMeta] = useState({});
	const [Reload, setReload] = useState(false);
	const [travelAgentOptions, setTravelAgentOptions] = useState();

	useEffect(() => {
		async function fetchLookups() {
			const companiesLookupsResponse = await fetchCompaniesLookups();
			if (companiesLookupsResponse.status === 200) {
				const formatCompaniesLookups =
					companiesLookupsResponse?.data?.data?.map((company) => {
						return { ...company, label: company?.name, value: company?.id };
					});
				setTravelAgentOptions(formatCompaniesLookups);
			}
		}
		fetchLookups();
	}, []);

	const packageBookingList = async () => {
		const res = await getpackageBookingList({
			owner_name: filter.owner_name,
			company_id: filter.travel_agent?.value,
			request_date: filter.request_date
				? moment(filter.request_date).format("YYYY-MM-DD")
				: "",
			departure_date: filter.departure_date
				? moment(filter.departure_date).format("YYYY-MM-DD")
				: "",
			nights_from: filter.night.from,
			nights_to: filter.night.to,
			total_from: filter.total.from,
			total_to: filter.total.to,
			paid_from: filter.paid.from,
			paid_to: filter.paid.to,
			pax_from: filter.pax.from,
			pax_to: filter.pax.to,
			outstanding_from: filter.outstanding.from,
			outstanding_to: filter.outstanding.to,
			deal_status: filter.deal_status?.value,
			priority: filter.priority?.value,
			before_departure_date: filter.before_departure.date
				? moment(filter.before_departure.date).format("YYYY-MM-DD")
				: "",
			before_departure_status: filter.before_departure.status?.value,
			after_departure_date: filter.after_departure.date
				? moment(filter.after_departure.date).format("YYYY-MM-DD")
				: "",
			after_departure_status: filter.after_departure.status?.value,
			page: filter.page,
		});
		setPackagesList(res?.data?.data);
		setMeta(res?.data?.meta);
	};
	useEffect(() => {
		packageBookingList();
	}, [isFilter, Reload, filter?.owner_name]);
	console.log("filterfilter", filter);
	const goTo = (page) => {
		setFilter((prev) => ({ ...prev, page: page }));
		setIsFilter((prev) => !prev);
	};
	const resetFilter = () => {
		setFilter({
			owner_name: "",
			request_date: undefined,
			departure_date: undefined,
			pax: { from: "", to: "" },
			paid: { from: "", to: "" },
			deal_status: "",
			priority: "",
			after_departure: { date: undefined, status: "" },
			status: "",
			travel_agent: "",
			night: { from: "", to: "" },
			total: { from: "", to: "" },
			outstanding: { from: "", to: "" },
			deal_closing: "",
			before_departure: { date: undefined, status: "" },
			page: 1,
		});
	};

	const exportToFile = async () => {
		const res = await exportToExcel({
			company_id: filter.travel_agent?.value,
			request_date: filter.request_date
				? moment(filter.request_date).format("YYYY-MM-DD")
				: "",
			departure_date: filter.departure_date
				? moment(filter.departure_date).format("YYYY-MM-DD")
				: "",
			nights_from: filter.night.from,
			nights_to: filter.night.to,
			total_from: filter.total.from,
			total_to: filter.total.to,
			paid_from: filter.paid.from,
			paid_to: filter.paid.to,
			pax_from: filter.pax.from,
			pax_to: filter.pax.to,
			outstanding_from: filter.outstanding.from,
			outstanding_to: filter.outstanding.to,
			deal_status: filter.deal_status?.value,
			priority: filter.priority?.value,
			before_departure_date: filter.before_departure.date
				? moment(filter.before_departure.date).format("YYYY-MM-DD")
				: "",
			before_departure_status: filter.before_departure.status?.value,
			after_departure_date: filter.after_departure.date
				? moment(filter.after_departure.date).format("YYYY-MM-DD")
				: "",
			after_departure_status: filter.after_departure.status?.value,
			owner_name: filter.owner_name,
			//page: filter.page,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute("download", `b2c-packages-${moment().format("DD-MM-YYYY")}`);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<h1>CRM</h1>
			<PackagesBookingFilter
				filter={filter}
				setFilter={setFilter}
				setIsFilter={setIsFilter}
				resetFilter={resetFilter}
				exportToFile={exportToFile}
				toggle={toggle}
				isOpen={isOpen}
				setisOpen={setisOpen}
				select={select}
				travelAgentOptions={travelAgentOptions}
			/>
			<PackagesBookinglist
				packagesList={packagesList}
				goTo={goTo}
				meta={meta}
				select={select}
				setselect={setselect}
			/>
			<PriorityModel
				toggle={toggle}
				isOpen={isOpen}
				select={select}
				setselect={setselect}
				setReload={setReload}
				initialPriority={""}
			/>
		</>
	);
}

export default PackagesBooking;
