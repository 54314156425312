import ImagePlaceholder from "assets/images/image_placeholder.png";
import DatePickerField from "components/shared/DatePickerField";
import TextField from "components/shared/TextField";
import { downloadFileWithUUID } from 'helpers/downloadFileWithUUID';
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { allVisasPassengerProfile } from 'services/Request';
import Locale from 'translations';


export default function ViewPassengerProfile() {
  const { requests } = Locale;
  let history = useHistory();
  const { request_id, passenger_id } = useParams();

  async function getPassengerProfile() {
    const passengerRes = await allVisasPassengerProfile(request_id, passenger_id);
    if (passengerRes?.status === 200) {
      setMember(passengerRes?.data?.data);
    }
  }

  const [member, setMember] = useState({
    first_name: { ar: "", en: "" },
    father_name: { ar: "", en: "" },
    grandfather_name: { ar: "", en: "" },
    last_name: { ar: "", en: "" },
    national_id: "",
    gender: "",
    title: "",
    passport_type: "",
    passport_number: "",
    marital_status: "",
    educational_level: "",
    country: "",
    nationality: "",
    birth_country: "",
    birth_place: "",
    issue_country: "",
    issue_place: "",
    date_of_birth: "",
    issue_date: "",
    expire_date: "",
    job: "",
    address: "",
    photo: "",
    relationship: "",
    mrz: "",
  });


  useEffect(() => {
    getPassengerProfile();
  }, []);

  return (
    <div className="list-container passenger-profile ">
      <div className="row">
        <div className="member-photo m-0 col-2 online-vise-img mt-4">
          <span>Profile Picture</span>
          <img
            className="img-fluid rounded w-100"
            src={member.photo ? `${member.photo}` : ImagePlaceholder}
            width={200}
            height={200}
            alt="Profile"
          />
          {member.photo && (
            <a href={member.photo} download className='btn btn-link text-primary w-100' target='_blank' rel='noreferrer'>
              Profile Picture <i className="fa fa-download" />
            </a>
          )}
          <span className="d-block mt-2 ">Passport Picture</span>
          <img
            className="img-fluid rounded w-100 "
            src={
              member?.passport_photo
                ? `${member.passport_photo}`
                : ImagePlaceholder
            }
            width={200}
            height={200}
            alt="passport"
          />
          {member?.passport_photo && (
            <a href={member.passport_photo} download className='btn btn-link text-primary w-100' target='_blank' rel='noreferrer'>
              passport Picture <i className="fa fa-download" />
            </a>
          )}
        </div>

        <div className=" col-10 p-0 ">
          <div className=" d-flex align-items-start justify-content-between">
            <div className=" w-100">
              <div className="box1">
                <div className="row align-items-center w-100 no-gutter  m-0 ">
                  <div className="col-md-3 ">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={requests.NATIONALID}
                      placeholder={requests.NATIONALIDPlaceholder}
                      value={member.national_id}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          national_id: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <TextField
                      name="contract"
                      disabled={true}
                      placeholder={requests.Gender}
                      label={requests.Gender}
                      value={member.gender}
                    />
                  </div>

                  <div className="col-md-3 ">
                    <TextField
                      name="group-status"
                      placeholder={"Select Title"}
                      label={requests.title}
                      value={member.title}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>

              <div className="box2 border p-1 mx-1 px-2 rounded">
                <h3 className="p-1 font-dark-blue"> {requests.ArabicName}</h3>
                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3 ">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={requests.firstNamePlaceholder}
                      value={member.first_name_ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          first_name: {
                            en: member.first_name.en,
                            ar: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={requests.fatherNamePlaceholder}
                      value={member.father_name_ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          father_name: {
                            en: member.father_name.en,
                            ar: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={requests.grandFatherNamePlaceholder}
                      value={member.grandfather_name_ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          grandfather_name: {
                            en: member.grandfather_name.en,
                            ar: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={requests.lastNamePlaceholder}
                      value={member.last_name_ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          last_name: {
                            en: member.last_name.en,
                            ar: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <h3 className="p-1 font-dark-blue"> English Name</h3>
                <div className="row align-items-center w-100 no-gutter m-0  ">
                  <div className="col-md-3 ">
                    <TextField
                      hasLabel={false}
                      type="text"
                      disabled={true}
                      placeholder={requests.firstNamePlaceholder}
                      value={member.first_name_en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          first_name: {
                            en: e.target.value,
                            ar: member.first_name.ar,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      disabled={true}
                      placeholder={requests.fatherNamePlaceholder}
                      value={member.father_name_en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          father_name: {
                            en: e.target.value,
                            ar: member.father_name.ar,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      disabled={true}
                      placeholder={requests.grandFatherNamePlaceholder}
                      value={member.grandfather_name_en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          grandfather_name: {
                            en: e.target.value,
                            ar: member.grandfather_name.ar,
                          },
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      //label={requests.lastName}
                      disabled={true}
                      placeholder={requests.lastNamePlaceholder}
                      value={member.last_name_en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          last_name: {
                            en: e.target.value,
                            ar: member.last_name.ar,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>

              <div className=" my-2  ">
                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <TextField
                      name="contract"
                      disabled={true}
                      label={requests.passportType}
                      placeholder={requests.passportTypePlaceholder}
                      value={member?.passport_type}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          passport_type: e,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      name="contract"
                      disabled={true}
                      label={requests.maritalStatus}
                      placeholder={requests.maritalStatusPlaceholder}
                      value={member?.marital_status}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          marital_status: e,
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      name="group-status"
                      disabled={true}
                      label={requests.educationLevel}
                      placeholder={requests.educationLevelPlaceholder}
                      value={member.educational_level}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          educational_level: e,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      name="contract"
                      label={requests.country}
                      disabled={true}
                      placeholder={requests.countryPlaceholder}
                      value={member?.country}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          country: e,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <TextField
                      name="contract"
                      label={requests.nationality}
                      disabled={true}
                      placeholder={requests.nationalityPlaceholder}
                      value={member?.nationality}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          nationality: e,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      name="contract"
                      label={requests.birthCountry}
                      disabled={true}
                      placeholder={requests.birthCountryPlaceholder}
                      value={member?.birth_country}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          birth_country: e,
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      name="group-status"
                      label={requests.issueCountry}
                      disabled={true}
                      placeholder={requests.issueCountryPlaceholder}
                      value={member.issue_country}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          issue_country: e,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={requests.issuePlace}
                      disabled={true}
                      placeholder={requests.issuePlacePlaceholder}
                      value={member.issue_place}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          issue_place: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={requests.birthPlace}
                      disabled={true}
                      placeholder={requests.birthPlacePLaceholder}
                      value={member.birth_place}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          birth_place: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <DatePickerField
                      label={requests.Birthday}
                      placeholder={"DD/MM/YYYY"}
                      disabled={true}
                      date={moment(member?.date_of_birth)}
                      value={moment(member?.date_of_birth)}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          date_of_birth: e,
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-3 ">
                    <DatePickerField
                      label={requests.issueDate}
                      placeholder={"DD/MM/YYYY"}
                      disabled={true}
                      date={
                        member?.issue_date ? moment(member?.issue_date) : null
                      }
                      onChange={(e) => {
                        setMember({
                          ...member,
                          issue_date: e,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <DatePickerField
                      label={requests.expiryDate}
                      disabled={true}
                      placeholder={"DD/MM/YYYY"}
                      date={
                        member?.expire_date ? moment(member?.expire_date) : null
                      }
                      onChange={(e) => {
                        setMember({
                          ...member,
                          expire_date: e,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-6">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={requests.JOB}
                      disabled={true}
                      placeholder={requests.jobPlaceholdre}
                      value={member?.job}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          job: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      disabled={true}
                      label={"Passport No"}
                      placeholder={"Passport No"}
                      value={member.passport_number}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          passport_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                {member?.residence_data?.residence_photo ?
                  <div className="my-2">
                    <h4 className="mb-2">Residence Data</h4>
                    <div className="row">
                      <div className="col-3">
                        <div className="p-2 upload-residence w-100">
                          <p className="text-caption mb-2 waiting">
                            Download Residency photo
                          </p>

                          <p className="text-gray d-flex align-items-center">
                            <span className="flex-fill overflow-hidden text-dark">
                              {member?.residence_data?.residence_photo}
                            </span>
                            <i className="fas fa-download pointer waiting" onClick={() => {
                              downloadFileWithUUID(member?.residence_data?.residence_photo, `residence-photo-${member?.passport_number}`)
                            }}></i>
                          </p>

                        </div>
                      </div>
                      <div className="col-3 no-padding">
                        <TextField
                          hasLabel={true}
                          type="text"
                          disabled={true}
                          label={"Residency Number"}
                          placeholder={"Residency Number"}
                          value={member?.residence_data?.residence_number}
                        />
                      </div>
                      <div className="col-md-3 ">
                        <DatePickerField
                          label={"Issue Date"}
                          minDate={false}
                          placeholder={"DD/MM/YYYY"}
                          date={moment(member?.residence_data?.issue_date)}
                          disabled={true}
                        />
                      </div>

                      <div className="col-md-3 ">
                        <DatePickerField
                          label={"Expiry Date"}
                          disabled={true}
                          placeholder={"DD/MM/YYYY"}
                          date={moment(member?.residence_data?.expiry_date)}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  null
                }





                <div className="row align-items-center w-100 no-gutter m-0  round mrz   ">
                  <div className="bg-mrz col-md-2  py-2 text-light text-center rounded ">
                    <p className="mb-0">MRZ</p>
                  </div>
                  <div className="col-md-10 py-2 text-16 bg-gray-100 rounded">
                    <p className="" style={{ wordBreak: 'break-word' }}>{member?.mrz} </p>
                  </div>
                </div>

                <div className="d-flex justify-content-end my-4">
                  <button className="btn btn-back mx-2 px-5"
                    onClick={() => history.push(`/online-visa/requests/all-visas`)}
                  >
                    {requests.back}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
