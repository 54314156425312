import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";

import TextField from "components/shared/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter, countries }) {
	const { backOffice, vendor } = Locale;

	const resetFilters = () => {
		setFilter({
			search: "",
			booking_date: "",
			travel_date: "",
			status: "",
			page: 1,
		});
	};
	const Status = [
		{ value: 1, label: "pending" },
		{ value: 2, label: "rejected" },
		// { value: 3, label: "approved" },
		{ value: 4, label: "canceled" },
		// { value: 5, label: "paid" },
		{ value: 6, label: "confirmed" },
		{ value: 7, label: "expired" },
		{ value: 8, label: "issued" },
	];
	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-3">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									label={backOffice.search}
									hasLabel={true}
									value={filter.search}
									onChange={(e) => {
										setFilter({
											...filter,
											search: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="main-label">
								<DatePickerField
									hasLabel={true}
									label={"Booking Date"}
									date={filter.booking_date}
									placeholder={backOffice.date}
									onChangeDate={(filterDate) => {
										setFilter({
											...filter,
											booking_date: filterDate,
										});
									}}
									isOutsideRange={() => false}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="main-label">
								<DatePickerField
									hasLabel={true}
									label={"Travel Date"}
									date={filter.travel_date}
									placeholder={backOffice.date}
									onChangeDate={(filterDate) => {
										setFilter({
											...filter,
											travel_date: filterDate,
										});
									}}
									isOutsideRange={() => false}
								/>
							</div>
						</div>
						<div className="col-2">
							<SelectField
								hasLabel={true}
								label={vendor.status}
								value={filter.status}
								placeholder={backOffice.status}
								name="status"
								options={Status}
								onChange={(e) => {
									setFilter({
										...filter,
										status: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
