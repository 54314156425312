import img_placeholder from "assets/images/image_placeholder.png";
import axios from "axios";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchImages } from "services/companies";

function ViewVendor({ details }) {
	const { id } = useParams();
	const [logo, setLogo] = useState(img_placeholder);

	useEffect(() => {
		const fetchLogo = async () => {
			const fetchLogo = await fetchImages(details?.logo);
			setLogo(fetchLogo);
		};
		fetchLogo();
		return () => {};
	}, []);

	function downloadCertFiles() {
		const fetchFilesURL = process.env.REACT_APP_API_URL + "/api/files/fetch";
		const config = {
			fetchFilesURL,
			method: "GET",
			responseType: "blob",
		};
		axios.get(`${fetchFilesURL}/${details?.contract}`, config).then((res) => {
			debugger;
			const blob = new Blob([res.data], {
				type: res.headers["content-type"],
			});
			console.log(res);
			const objectUrl = window.URL.createObjectURL(blob);
			const anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`${details?.contract}.${res.data.type.split("/")[1]}`
			);
			anchor.click();
		});
	}

	return (
		<div className="m-4">
			<div className="row">
				<div className="col-3">
					<div className="img-box-ven w-75">
						<img className="img-fluid" src={logo} alt="" />
					</div>
				</div>
				<div className="col-6">
					<div className="row">
						<h1 className="pro-label col-6">Vendor Name</h1>
						<p className="pro-data mb-4 col-6 pt-1">{details?.name}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">Country</h1>
						<p className="pro-data mb-4 col-6">{details?.country?.name}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">URL</h1>
						<p className="pro-data mb-4 col-6">{details?.sbs_url}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">Contract validity</h1>
						<p className="pro-data mb-4 col-6">{`${
							details?.contract_start ? details?.contract_start : ""
						} - ${details?.contract_end ? details?.contract_end : ""}`}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">Address</h1>
						<p className="pro-data mb-4 col-6">{details?.address}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">Manager Name</h1>
						<p className="pro-data mb-4 col-6">{details?.manager_name}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">Email Address</h1>
						<p className="pro-data mb-4 col-6">{details?.email}</p>
					</div>

					<div className="row">
						<h1 className="pro-label col-6">Mobile No.</h1>
						<p className="pro-data mb-4 col-6">{details?.phone}</p>
					</div>
					<div className="row">
						<h1 className="pro-label col-6">Vendor Type</h1>
						<p className="pro-data mb-4 col-6">{details?.type}</p>
					</div>
					<div className="row">
						<h1 className="pro-label col-6">Contract</h1>
						<div className="divro-data d-flex col-6">
							<button
								className="download-cert text-success"
								onClick={downloadCertFiles}
							>
								<i class="fas fa-download"></i>
								<span className="mx-1">Download contract</span>
							</button>
						</div>
					</div>
				</div>

				<div className="col-3 text-end">
					<ShowForPermission permission="Vendors-Management-Manage">
						<Link
							to={{
								pathname: `/edit-vendor/${id}`,
							}}
							className="btn btn-success px-5 text-white"
						>
							Edit
						</Link>
					</ShowForPermission>
				</div>
			</div>
		</div>
	);
}

export default ViewVendor;
