import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Model from "components/model";
import DatePickerField from "components/shared/DatePickerField";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";

///////////////////////////////// EditPaymentModal/////////////////////////

const paymentMethodList = [
	{ label: "payment", value: "payment" },
	{ label: "refund", value: "refund" },
	{ label: "refund Fees", value: "refund Fees" },
];

const paymentTypeList = [
	{ label: "add", value: "add" },
	{ label: "deduct", value: "deduct" },
];

export const AddPaymentModal = (props) => {
	const { modalState, toggleModel, details, handleReservationState } = props;
	const [selected, setSelected] = useState("edit");
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const [paymentState, setPaymentState] = useState({
		option_date: new Date(),
		provider_amount: 0,
		action: "",
		type: "",
		status: "pending",
	});

	const [errors, setErrors] = useState({});

	const editPayment = selected === "edit";
	const confirmPayment = selected === "confirm";

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "option_date", value: paymentState.option_date },
				{ required: true }
			),

			...validate(
				{ name: "provider_amount", value: paymentState.provider_amount },
				{
					required: true,
					minNumber: 1,
				}
			),
		});
	};
	const onEditPayment = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (Object.values(errors).length > 1) {
			if (isFormValid(errors)) {
				if (editPayment) {
					let updatedPayments = [...details?.["payments"]];
					const updatepayment = { ...paymentState };

					updatepayment.provider_amount = paymentState.provider_amount;
					updatepayment.action = paymentState.action.label;
					updatepayment.status = paymentState.action?.label?.includes("refund")
						? "confirmed"
						: "pending";
					updatepayment.type = paymentState?.action?.label.includes("refund")
						? "deduct"
						: "add";
					updatepayment.option_date = moment(paymentState.option_date).format(
						"YYYY-MM-DD"
					);
					updatedPayments.push(updatepayment);
					handleReservationState({ name: "payments", value: updatedPayments });
					toggleModel();
				} else {
				}
			}
		}

		return () => {};
	}, [isErrorLoaded]);

	const resetForm = () => {
		setPaymentState({
			option_date: new Date(),
			amount: 0,
			paymentMethod: "",
			bankReceipt: "",
		});
		setErrors({});
	};

	return (
		<Model
			isOpen={modalState}
			// type={tentativeModal.type}
			title={"add Payment"}
			toggle={toggleModel}
			size="md"
			hasFooter={false}
		>
			<div className="w-100 px-5 reserv_modal row">
				{/* <div className={"reserv_modal_total_price  my-3 w-100"}>
					<p>Total Price</p>
					<p>{`${paymentState?.amount} ${details?.currency}`}</p>
				</div> */}

				{/*///////////// Form Fields ///////////////*/}

				<div className="m-0 modal-body px-0 reserv_modal_payment_fields">
					<div>
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Option Date"}
							date={moment(paymentState.option_date)}
							onChangeDate={(date) =>
								setPaymentState((current) => ({
									...current,
									option_date: date._d,
								}))
							}
							isOutsideRange={(day) => {
								return false;
							}}
						/>
					</div>

					<div className="payment_field_model">
						<SelectField
							label={"Payment Action"}
							placeholder={paymentState?.action?.label}
							value={paymentState?.action?.label}
							name="paymentMethod"
							id="paymentMethod"
							options={paymentMethodList}
							onChange={(e) => {
								setPaymentState((current) => ({
									...current,
									action: e,
									amount: 0,
								}));

								setErrors({
									...errors,
									...validate({ name: "action", value: e }, { required: true }),
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "action",
											value: paymentState.action,
										},
										{ required: true }
									),
								})
							}
							color={errors?.action?.required ? "danger" : ""}
							errors={errors?.action}
						/>
					</div>

					{/* <div className="payment_field_model">
						<SelectField
							label={"Payment Type"}
							placeholder={paymentState?.type?.label}
							value={paymentState?.type?.label}
							name="paymentMethod"
							id="paymentMethod"
							options={paymentTypeList}
							onChange={(e) => {
								setPaymentState((current) => ({
									...current,
									type: e,
									amount: 0,
								}));
								console.log(e);

							
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "type",
											value: paymentState.type,
										},
										{ required: true }
									),
								})
							}
							color={errors?.type?.required ? "danger" : ""}
							errors={errors?.type}
						/>
					</div> */}

					<div className="payment_field_model">
						<TextField
							type="text"
							label={"amount"}
							placeholder={"amount"}
							id={"provider_amount"}
							name="provider_amount"
							value={paymentState?.provider_amount}
							extraTextPosition="append"
							extraText={details?.currency}
							onChange={(e) => {
								setPaymentState((current) => ({
									...current,
									provider_amount: +e.target.value.replace(/[^0-9.]/, ""),
								}));

								setErrors({
									...errors,
									...validate(
										{ name: "provider_amount", value: e.target },
										{ required: true, minNumber: 0 }
									),
								});
							}}
							color={
								errors?.provider_amount?.required ||
								errors?.provider_amount?.minNumber
									? "danger"
									: ""
							}
							min={0}
							errors={errors?.provider_amount}
						/>
					</div>
				</div>

				<button
					className="btn btn-res-primary w-100 mt-2"
					onClick={onEditPayment}
				>
					Edit
				</button>
			</div>
		</Model>
	);
};
