
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ImgServiec from '../../ServiceImg';

const ListBody = ({Service}) => {
  const {id,brn} = useParams()


  return (
 
          <tr key={Service?.name}>
                  <td> 
                  <ImgServiec name={Service?.name} />
                    <span className='ms-3' >{Service?.name}</span>
                   </td>
                   <td>
                    {Service?.providers?.map((Service,index)=>  <span> {index > 0 ? "-":null} {Service}</span>)}
                   </td>
                   <td >
                     <Link to={`/vendor/${id}/configurations/${Service?.name}`} className={"text-success fw-bold"} >Manage</Link>
                   </td>
                </tr>
  )
}

export default ListBody