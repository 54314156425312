export function ClearIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.00977 14.5101C8.18977 14.8101 8.40976 15.0901 8.65976 15.3401C10.4998 17.1801 13.4898 17.1801 15.3398 15.3401C16.0898 14.5901 16.5198 13.64 16.6598 12.67"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.33984 11.3301C7.47984 10.3501 7.90985 9.41003 8.65985 8.66003C10.4999 6.82003 13.4898 6.82003 15.3398 8.66003C15.5998 8.92003 15.8098 9.20005 15.9898 9.49005"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.81982 17.18V14.51H10.4898"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.1797 6.82007V9.49005H13.5098"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
