import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listOrders = async (params, id) => {
	return await axios
		.get(`${backOfficeApi}/company/${id}/reservations`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
