import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getPackageDetails, searchPackages } from "services/b2c";
import ChangeFilter from "./ChangeFilter";
import ChangeTable from "./ChangeTable";

const firstArrivalOptions = [
	{ value: 3, label: "Makkah", code: "3" },
	{ value: 4, label: "Madina", code: "4" },
];

let INITIAL_FILTERS = {};
export default function ChangePackageCrm() {
	const [filters, setFilters] = useState({});
	const { id } = useParams();
	const [packageDetails, setPackageDetails] = useState(null);
	const [filterPackageResult, setFilterPackageResult] = useState([]);
	const [tabelDate, setTabelDate] = useState();

	const formatFilter = (data) => {
		const travelAgent = {
			value: data.product.company_id,
			code: data.product.company_id.toString(),
			label: data.travel_agent,
		};
		const nights = {
			value: data.nights,
			code: data.nights.toString(),
			label: data.nights.toString(),
		};
		const departureDate = moment(data.departure_date);
		const classId = data.product.product_classification.id;
		const stars = {
			value: classId,
			code: classId.toString(),
			label: classId.toString(),
		};

		const firstArrival =
			firstArrivalOptions[data.trip_type.includes("umrah") ? 1 : 0];

		const departure = data.product.product_items.find(
			(i) => i.item.name === "external transportation"
		);
		// let departureFrom = departure.itemable.from_country;
		// departureFrom = {
		// 	...departureFrom,
		// 	value: departureFrom.id,
		// 	label: departureFrom.name,
		// };
		let airline = departure.itemable.transporter;
		airline = {
			...airline,
			value: airline.id,
			label: airline.name,
		};
		INITIAL_FILTERS = {
			travelAgent,
			nights,
			class: stars,
			firstArrival,
			// departureFrom,
			airline,
			departureDate,
		};
		setTabelDate(departureDate);

		setFilters(INITIAL_FILTERS);
	};

	const packageTypeUmrahOrHajj =
		packageDetails?.trip_type?.includes("umrah") ||
		packageDetails?.trip_type?.includes("hajj");

	//  get package data details
	async function getPackageData() {
		const viewPackageRes = await getPackageDetails(id);
		if (viewPackageRes?.status >= 200 && viewPackageRes?.status < 300) {
			const data = viewPackageRes?.data?.data;
			formatFilter(data);
			setPackageDetails(data);
		}
	}
	// excute get package
	useEffect(() => {
		if (!packageDetails) {
			getPackageData();
		}
	}, []);
	// handle filter items changes
	const filterHandler = ({ name, value }) => {
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	// reset filters with initial filters
	const resetFilters = () => setFilters(INITIAL_FILTERS);
	//
	const submitFilter = async (departureDate) => {
		const filtersData = {
			search_for: "change",
			company_id: filters?.travelAgent?.value,
			departure_date:
				departureDate || moment(filters?.departureDate).format("YYYY-MM-DD"),
			nights_count: filters?.nights?.value,
			class: filters?.class?.value,
			first_arrival: filters?.firstArrival?.value,
			airline: filters?.airline?.id,
			price_from: filters?.minPrice || null,
			price_to: filters?.maxPrice || null,
		};
		// get result and set it in state
		const filterSearchRes = await searchPackages(id, filtersData);
		if (filterSearchRes?.status >= 200 && filterSearchRes?.status < 300) {
			setFilterPackageResult(filterSearchRes?.data?.data);
			setTabelDate(moment(filtersData.departure_date));
			filterHandler({ name: "departureDate", value: moment(filtersData.departure_date) });
		}
	};

	// useEffect(() => {
	// 	if (packageDetails) {
	// 		debugger;
	// 		submitFilter();
	// 	}

	// 	return () => {};
	// }, []);

	return (
		<>
			{packageDetails ? (
				<>
					<h4>Change</h4>
					<ChangeFilter
						filters={filters}
						filterHandler={filterHandler}
						submitFilter={submitFilter}
						resetFilters={resetFilters}
						hasFirstArrival={packageTypeUmrahOrHajj}
						firstArrivalOptions={firstArrivalOptions}
						packageDetails={packageDetails}
					/>
					<ChangeTable
						filterPackageResult={filterPackageResult}
						packageTypeUmrahOrHajj={packageTypeUmrahOrHajj}
						tabelDate={tabelDate}
						submitFilter={submitFilter}
						filterHandler={filterHandler}
						packageDetails={packageDetails}
					/>
				</>
			) : null}
		</>
	);
}
