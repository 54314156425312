import { Box, Button, Card, Divider, Typography } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Locale from "translations";
import imagePlaceHolder from "../../../../../assets/images/image_placeholder.png";

import { GroundServiceIcon, LocationIcon, TransportationIcon } from "../Icons";
import GroundServiceDetails from "./dialogs/groundServiceDetails";
import PoliciesDialog from "./dialogs/transportationDetails/Policies";
import HotelDetails from "./HotelDetails";

export default function B2CDetails({ bookingDetails }) {
	const { userDetails, backOffice, commons } = Locale;
	const makkahHotel = bookingDetails?.cartItems?.find(
		(i) => i.type === "hotel" && i.hotelCity === "Makkah"
	);
	const madinahHotel = bookingDetails?.cartItems?.find(
		(i) => i.type === "hotel" && i.hotelCity === "Madinah"
	);
	const transportation = bookingDetails?.cartItems?.find(
		(i) => i.type === "transportation"
	);
	const groundService = bookingDetails.cartItems?.find(
		(i) => i.type === "ground_service"
	);
	const searchData = bookingDetails.searchRequestDetails;
	const summary = bookingDetails.summary;
	const userInfo = bookingDetails.user_info;

	const [policiesDialogStatus, setPoliciesDialogStatus] = useState(false);
	const [groundDialogStatus, setGroundDialogStatus] = useState(false);

	function transportationDialogClose() {
		setPoliciesDialogStatus(false);
	}

	//////// transportation Policies Dialog /////////
	const policies = transportation?.data?.policies;
	const cancellationPolicy = transportation?.data?.cancellationPolicy;
	const TransportationTerms = transportation?.data?.termsAndConditions;
	//////// Ground service Dialog /////////
	const groundTerms = groundService?.data?.termsAndConditions;
	const details = groundService?.data?.category?.description;

	const { pathname } = useLocation();

	const routeTypeUser = pathname.split("/")?.[1] === "b2c-booking-details";
	const userSection = routeTypeUser ? (
		<div className="border bg-white">
			<div className="row m-0 p-0 ">
				<p className="m-0 p-2 col-2 bg-bookingView">{userDetails.fullName}</p>
				<p className="m-0 p-2 col-4 border-booking">{userInfo?.name}</p>
				<p className="m-0 p-2 col-2 bg-bookingView">{backOffice.country}</p>
				<p className="m-0 p-2 col-4 border-booking">
					{userInfo?.country?.name}
				</p>
			</div>

			<div className="row m-0 p-0 ">
				<p className="m-0 p-2 col-2 bg-bookingView"> {backOffice.email}</p>
				<p className="m-0 p-2 col-4 border-booking">{userInfo?.email}</p>
				<p className="m-0 p-2 col-2 bg-bookingView">
					{userDetails.phoneNumber}
				</p>
				<p className="m-0 p-2 col-4 border-booking">{userInfo?.phone}</p>
			</div>

			<div className="row m-0 p-0 ">
				<p className="m-0 p-2 col-2 bg-bookingView">{commons.registerDate}</p>
				<p className="m-0 p-2 col-4 border-booking">
					{userInfo?.booking_date &&
						moment(userInfo?.booking_date).format("YYYY-MM-DD")}
				</p>
				<p className="m-0 p-2 col-2 bg-bookingView">{backOffice.status}</p>
				<p
					className={`m-0 p-2 col-4 border-booking ${
						"user?.status" === "active" || true ? "active" : "text-danger"
					}`}
				>
					{userInfo?.status}
				</p>
			</div>
		</div>
	) : null;

	return (
		<div className="b2c_booking_details hotel_details">
			<div className="col-md-12 mt-4">
				{userSection}
				<div className="row mt-3">
					<div className="col-md-8">
						<div className="d-flex justify-content-between p-3 header_color mb-2">
							<h5 className="b2c_secondary_color m-0">Trip Path</h5>
							<p className="b2c_secondary_color m-0">
								{bookingDetails.trip_path}
							</p>
						</div>
						<p className="b2c_secondary_color">Review Your Package</p>

						{/********* Makka Card *********/}
						<HotelDetails details={makkahHotel} />

						{/********* Madinah Card *********/}
						{(bookingDetails.hasMadinahHotel || madinahHotel) && (
							<HotelDetails details={madinahHotel} madinah />
						)}

						{/********* Transportation Card *********/}
						<Card style={{ marginTop: 24 }}>
							<div className="w-100 bg-light-gray d-flex py-2 px-3 align-items-center svg_blue">
								<TransportationIcon />
								<Box
									component="h6"
									color="secondary.main"
									style={{
										color: "#0f3e5e",
										fontWeight: "bold",
										marginBottom: 0,
										marginInlineStart: 16,
									}}
								>
									Transportations
								</Box>
							</div>
							<div className="d-flex w-100 p-3">
								<img
									className="rounded-start"
									src={
										transportation?.data?.images?.[0]?.url || imagePlaceHolder
									}
									alt={transportation?.data?.companyName || ""}
									// onError={(e) => (e.currentTarget.src = imagePlaceHolder)}
									// placeholder={imagePlaceHolder}
									width={135}
									height={99}
								/>
								<Box style={{ marginLeft: "1rem", flexGrow: 1 }}>
									<Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
										{transportation?.data?.companyName}
									</Typography>
									<Typography
										variant="h6"
										className="b2c_secondary_color"
										style={{ fontWeight: "bold" }}
									>
										{transportation?.data?.vehicleTypes?.[0]?.vehicleType}
									</Typography>

									<Typography variant="body2">
										<i class="fas fa-caret-right me-1" />
										No Of Vehicles: <b> {searchData?.noOfVehicles}</b>
									</Typography>
									<Typography variant="body2">
										<i class="fas fa-caret-right me-1" />
										category :
										{
											transportation?.data?.vehicleTypes?.[0]?.categories?.[0]
												?.categoryName
										}
									</Typography>
									<Typography variant="body2">
										<i class="fas fa-caret-right me-1" />
										{`Model: ${transportation?.data?.vehicleTypes?.[0]?.categories?.[0]?.model?.from} / ${transportation?.data?.vehicleTypes?.[0]?.categories[0]?.model?.to}`}
									</Typography>
									<Typography variant="body2">
										<i class="fas fa-caret-right me-1" />
										{`Max Capacity: ${transportation?.data?.vehicleTypes?.[0]?.categories?.[0]?.noOfPax}`}
									</Typography>
									<Typography variant="body2">
										<i class="fas fa-caret-right me-1" />
										{`Route: ${transportation?.data?.routeName}`}
									</Typography>
								</Box>
								<div className="d-flex flex-column justify-content-between align-items-end">
									<img
										className="rounded border"
										src={
											transportation?.data?.company?.images?.[0]?.url ||
											imagePlaceHolder
										}
										alt={transportation?.data?.companyName}
										width={72}
										height={72}
									/>
									<div className="d-flex w-100 ">
										<Button
											disableRipple={true}
											color="info"
											style={{ marginLeft: "0.5rem" }}
											onClick={() => setPoliciesDialogStatus(true)}
										>
											<i className="fas fa-info-circle fa-lg  text-info me-1" />
											<Box
												component="span"
												style={{ textDecoration: "underline" }}
											>
												Ppolicies
											</Box>
										</Button>
										<Button
											disableRipple={true}
											color="info"
											style={{ marginLeft: "0.5rem" }}
										>
											<i className="fas fa-phone fa-md text-info me-1" />

											<Box component="span">
												{transportation?.data?.company?.phone}
											</Box>
										</Button>
									</div>
								</div>
							</div>
							<PoliciesDialog
								dialogStatus={policiesDialogStatus}
								handleDialogClose={transportationDialogClose}
								policies={policies}
								cancellationPolicy={cancellationPolicy}
								terms={TransportationTerms}
							/>
						</Card>

						{/********* Ground Card *********/}
						<Card style={{ marginTop: 24, marginBottom: 24 }}>
							<div className="w-100 bg-light-gray d-flex py-2 px-3 align-items-center svg_blue">
								<GroundServiceIcon />
								<Box
									component="h6"
									style={{
										color: "#0f3e5e",
										fontWeight: "bold",
										marginBottom: 0,
										marginInlineStart: 16,
									}}
								>
									Ground Services
								</Box>
							</div>

							<div className="d-flex w-100 p-3">
								<img
									className="rounded-start"
									src={groundService?.data?.image || imagePlaceHolder}
									alt={groundService?.data?.uoName || ""}
									width={135}
									height={99}
								/>
								<Box style={{ marginLeft: "1rem", flexGrow: 1 }}>
									<Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
										{groundService?.data?.uoName}
									</Typography>

									<Typography variant="body2">
										<LocationIcon className="me-1" />

										{groundService?.data?.address}
									</Typography>

									<Typography
										variant="body1"
										style={{ fontWeight: "bold", marginTop: 8 }}
										className="b2c_secondary_color"
									>
										Additional Service:
									</Typography>
									{groundService?.data?.additionalServices?.map(
										(service, index) => {
											return (
												<div key={service?.code}>
													<i class="fas fa-caret-right me-1" />
													<span>
														{`${service?.serviceName}  |  Quantity: ${service?.quantity} Pax(s)  |  Duration: ${service?.duration} Day(s)`}
													</span>
												</div>
											);
										}
									)}
								</Box>
							</div>
							<div className="d-flex w-100 px-3 justify-content-end">
								<Button
									disableRipple={true}
									color="info"
									style={{ marginLeft: "0.5rem" }}
									onClick={() => setGroundDialogStatus(true)}
								>
									<i className="fas fa-info-circle fa-lg  text-info me-1" />
									<Box component="span" style={{ textDecoration: "underline" }}>
										Show Details
									</Box>
								</Button>
							</div>
							<GroundServiceDetails
								detailsDialogStatus={groundDialogStatus}
								setDetailsDialogStatus={setGroundDialogStatus}
								terms={groundTerms}
								details={details}
							/>
						</Card>
					</div>

					{/* summary  */}
					<div className="col-md-4">
						<div className="d-flex justify-content-between p-3 header_color mb-2">
							<h5 className="b2c_secondary_color m-0">Reference No.</h5>
							<p className="b2c_secondary_color m-0">
								{bookingDetails.reference_id}
							</p>
						</div>

						<p className="b2c_secondary_color">Price Summary</p>
						<div className="summary_box px-4 py-3">
							<div className="d-flex w-100 justify-content-between">
								<p>Makkah Hotel</p>
								<p>{summary?.makkahHotelsSum?.toFixed(2)} SAR</p>
							</div>
							<div className="d-flex w-100 justify-content-between">
								<p>Madinah Hotel</p>
								<p>{summary?.madinahHotelsSum?.toFixed(2) ?? "00.00"} SAR</p>
							</div>
							<div className="d-flex w-100 justify-content-between">
								<p>Transportations</p>
								<p>{summary?.transportationSum?.toFixed(2)} SAR</p>
							</div>
							<div className="d-flex w-100 justify-content-between">
								<p>Ground Services</p>
								<p>{summary?.groundServiceSum?.toFixed(2)} SAR</p>
							</div>
							<Divider className="mb-3" />

							<div className="d-flex w-100 justify-content-between">
								<p> Total Before Tax </p>
								<p>{summary?.total_before_taxes?.toFixed(2)} SAR</p>
							</div>

							<div className="d-flex w-100 justify-content-between">
								<p>Taxes</p>
								<p>{summary?.taxes?.toFixed(2)} SAR</p>
							</div>
							<div className="d-flex w-100 justify-content-between">
								<p>Visa Fees</p>
								<p>{summary?.visa_fees?.toFixed(2)} SAR</p>
							</div>

							<Divider className="mb-3" />

							<div className="d-flex w-100 Total-Package justify-content-between">
								<p>
									<strong>Total Package Price</strong>
								</p>
								<p>
									<strong>
										{summary?.total_package_price?.toFixed(2)} SAR
									</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
