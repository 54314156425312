import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createProvider, editProvider, fetchVendorList } from "services/vendor";
import Locale from "translations";

function AddEditProvider({ fetchService, toggle, model }) {
	const { vendor, backOffice } = Locale;
	const { id, brn } = useParams();
	const firstRender = useRef(false);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [PercentageError, setPercentageError] = useState(false);
	const [ProviderOption, setProviderOption] = useState([]);

	const [Provider, setProvider] = useState({
		provider: 0,
		serviceName: "",
		commissionType: "",
		commission: 0,
		safaCommission: 0,
		vendorCommission: 0,
	});
	const handelAdd = ({ value, name }) => {
		setProvider({ ...Provider, [name]: value });
	};

	useEffect(() => {
		console.log(model);
		debugger;
		const fetchProviders = async () => {
			const providors = await fetchVendorList();
			if (providors?.status == 200) {
				const providersList = providors?.data.data?.map((p) => {
					return { value: p?.id, label: p?.name };
				});
				setProviderOption(providersList);
			}
		};
		if (model.type === "Edit") {
			setProvider({
				provider: {
					value: model?.data?.provider_id,
					label: model?.data?.provider,
				},
				serviceName: model?.data?.service_name,
				commissionType: {
					value: model?.data?.commission_type,
					label: model?.data?.commission_type,
				},
				commission: model?.data?.commission,
				safaCommission: model?.data?.safa_commission,
				vendorCommission: model?.data?.vendor_commission,
			});
		}
		fetchProviders();
	}, []);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "provider", value: Provider?.provider },
				{ required: true }
			),

			...validate(
				{ name: "commission", value: Provider?.commission },
				{ required: true }
			),
			...validate(
				{ name: "safaCommission", value: Provider?.safaCommission },
				{ required: true }
			),
			...validate(
				{ name: "vendorCommission", value: Provider?.vendorCommission },
				{ required: true }
			),
		});
	};
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	useEffect(() => {
		const sendData = async () => {
			if (model.type === "Edit") {
				debugger;
				const data = {
					id: model?.data?.id,
					provider_id: Provider?.provider?.value,
					service_name: brn,
					// commission_type:Provider.commissionType.label,
					commission_type: "add",
					commission: +Provider?.commission,
					safa_commission: +Provider?.safaCommission,
					vendor_commission: +Provider?.vendorCommission,
				};
				const req = await editProvider({ id, data });
				if (req.status === 200) {
					fetchService();
					toggle();
					store.addNotification({
						title: model?.type,
						message: req?.data?.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = {
					provider_id: Provider?.provider?.value,
					service_name: brn,
					// commission_type:Provider.commissionType.value,
					commission_type: "add",
					commission: +Provider?.commission,
					safa_commission: +Provider?.safaCommission,
					vendor_commission: +Provider?.vendorCommission,
				};
				const req = await createProvider({ id, data });
				if (req?.status === 200) {
					fetchService();
					toggle();
					store.addNotification({
						title: model?.type,
						message: req?.data?.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};
		if (isErrorLoaded) {
			sendData();
		}
	}, [isErrorLoaded]);

	useEffect(() => {
		if (firstRender.current) {
			if (
				Provider.commission !== "" &&
				Number(Provider.safaCommission) + Number(Provider.vendorCommission) ===
					100
			) {
				setPercentageError(false);
			} else {
				setPercentageError(true);
			}
		}
		firstRender.current = true;
	}, [Provider.vendorCommission, Provider.safaCommission]);

	return (
		<div className="row mx-5">
			<div className="col-12">
				<SelectField
					label={vendor.Providers}
					placeholder={vendor.Providers}
					name="provider"
					options={ProviderOption}
					onChange={(e) => {
						setProvider({
							...Provider,
							provider: { value: e.value, label: e.label },
						});
						setErrors({
							...errors,
							...validate({ name: "provider", value: e }, { required: true }),
						});
					}}
					value={Provider.provider}
					errors={errors.provider}
					color={errors.provider}
				/>
			</div>

			<div className="col-6">
				<TextField
					type="number"
					label={vendor.commission}
					placeholder={vendor.commission}
					extraTextPosition="append"
					extraText="%"
					onChange={(e) => {
						handelAdd({ value: e.target.value, name: "commission" });
						setErrors({
							...errors,
							...validate({ name: "commission", value: e }, { required: true }),
						});
					}}
					value={Provider.commission}
					errors={errors.commission}
				/>
			</div>
			<div className="col-6">
				<SelectField
					id="commissionType"
					label={backOffice.commissionType}
					name="commissionType"
					// onChange={(e)=>{
					// 	setProvider({...Provider,commissionType:e})
					// 	setErrors({
					// 		...errors,
					// 		...validate(
					// 			{ name: "commissionType", value: e },
					// 			{ required: true }
					// 		),
					// 	});
					// }}
					// options={OptionscommissionType}
					// value={Provider.commissionType}
					// errors={errors.commissionType}
					// color={errors.commissionType}
					defaultValue={{ label: "Add", value: "add" }}
					disabled
				/>
			</div>
			<div className="col-6">
				<TextField
					type="number"
					label={vendor.safaAmount}
					placeholder={vendor.safaAmount}
					name="safaCommission"
					extraTextPosition="append"
					extraText="%"
					onChange={(e) => {
						handelAdd({ value: e.target.value, name: "safaCommission" });
						setErrors({
							...errors,
							...validate(
								{ name: "safaCommission", value: e },
								{ required: true }
							),
						});
					}}
					value={Provider.safaCommission}
					errors={errors.safaCommission}
					color={PercentageError && errors.safaCommission ? "danger" : ""}
				/>
				{Provider.safaCommission > 100 ? (
					<p className="text-danger">*Must be equal or less than 100</p>
				) : null}
				{PercentageError ? (
					<p className="text-danger">
						*The total of safa commission and vendor commission must be 100
					</p>
				) : null}
			</div>
			<div className="col-6">
				<TextField
					type="number"
					label={vendor.Provider}
					placeholder={vendor.Provider}
					name="vendorCommission"
					extraTextPosition="append"
					extraText="%"
					onChange={(e) => {
						handelAdd({ value: e.target.value, name: "vendorCommission" });
						setErrors({
							...errors,
							...validate(
								{ name: "vendorCommission", value: e },
								{ required: true }
							),
						});
					}}
					value={Provider.vendorCommission}
					errors={errors.vendorCommission}
					color={PercentageError && errors.vendorCommission ? "danger" : ""}
				/>
				{PercentageError ? (
					<p className="text-danger">
						*The total of safa commission and vendor commission must be 100
					</p>
				) : null}
				{Provider.vendorCommission > 100 ? (
					<p className="text-danger">*Must be equal or less than 100</p>
				) : null}
			</div>
			<button className="btn btn-success w-100 mb-4" onClick={submit}>
				{model?.type} commission
			</button>
		</div>
	);
}

export default AddEditProvider;
