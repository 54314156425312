import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { getPackageHistory } from "services/b2c";

export default function History({ packageDetails, Reload }) {
	const [historyList, setHistoryList] = useState([]);
	const { id } = packageDetails;
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	useEffect(() => {
		async function getProductHistory() {
			const res = await getPackageHistory(id);
			if (res?.status === 200 || res?.status === 201) {
				setHistoryList(res?.data?.data);
			}
		}
		getProductHistory();
	}, [Reload]);
	console.log("test");

	const actionImg = (attribute) => {
		switch (attribute) {
			case "priority":
				return <i className="fas fa-exclamation-triangle" />;
			case "redirect_flag":
				return <i className="fas fa-random" />;
			case "change_flag":
				return <i className="fas fa-exchange-alt" />;
			default:
				return <i className="fas fa-info-circle" />;
		}
	};
	const priorityStyle = (status) => {
		switch (status) {
			case "high":
				return "text-priority-danger";
			case "medium":
				return "text-priority-warning";
			default:
				return "";
		}
	};
	// (data?.attribute === "change_flag" ||data?.attribute === "redirect_flag")
	const toFromKey = (data) => {
		if (data?.attribute === "priority") return "to";
		else if (data?.attribute !== "priority" && data?.old_value !== null)
			return "from";
		else return "to";
	};
	return (
		<>
			<div className=" px-4">
				{historyList.length > 0
					? historyList.map((res) => {
							return (
								<div className="mb-3">
									<div className="head">
										<p className="text-capitalize">
											{actionImg(res.attribute)}
											<span className="mx-1">{res.attribute}</span>
										</p>
										<p>
											{moment(res.created_at)
												.tz(timeZone)
												.format("DD-MMM-YYYY | hh:mm A")}
										</p>
									</div>
									<span className="descrip">
										{res.user_name} {res.action} {/**Here from/to */}
										{res.attribute === "priority" ||
										res.attribute === "reservation_status" ? (
											<>
												{res.attribute} to{" "}
												<span className={`${priorityStyle(res.value)}`}>
													{res.value}
												</span>{" "}
											</>
										) : null}{" "}
										{/* {toFromKey(res)}{" "}
										<span className={`${priorityStyle(res.value)}`}>
											{res.value}
										</span> */}
									</span>
								</div>
							);
					  })
					: ""}
			</div>
		</>
	);
}
