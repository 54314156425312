import Locale from 'translations'

function ListReportInvoices() {
  const {backOffice}=Locale
  return (
    <>
     <div className="our-border ">
				<table className="financialTable  align-middle table table-striped">
					<thead>
						<tr>
						
							<th >{backOffice.InvoiceNo}</th>
							<th >{backOffice.TravelAgent}</th>
							<th>{backOffice.Package}</th>
							<th >{backOffice.Pax}</th>
							<th >{backOffice.Status}</th>
							<th >{backOffice.Commission}</th>
							<th>{backOffice.Total}</th>
							<th>{backOffice.PaidOutstanding}</th>
							<th>{backOffice.InvoiceDate}</th>
							<th>{backOffice.PaymentStatus}</th>
							<th >{backOffice.Actions}</th>
						</tr>
					</thead>
					<tbody>
						<tr >
              <td>20A-123456</td>
							<td>
								<i class="fas fa-user-circle"></i> Nile Misr
							</td>
							<td>Umrah Package</td>
							<td>
              6 Pax
							</td>
							<td>
              Payment 10-Dec-17 | 20:00				</td>
							<td>
              1000 EGP
                
							</td>
							<td>
									10,000 EGP
							</td>
						
					
							<td>
              <p className="mb-0" style={{ color: "rgb(112 215 11)" }}>
									10,000 EGP
								</p>
								<p className="mb-0" style={{ color: "#B17A00" }}>
									10,543 EGP
								</p>							</td>
                <td>
                10-Dec-17 20:00
                </td>
                <td>
                Visa
                </td>
							<td>
								<span className='me-2' >
                <i class="fas fa-download"></i>
                Download
                   </span>
                   <span>
                   <i class="fas fa-eye"></i>
                   View
                   </span>
							</td>
							
						</tr>
					</tbody>
				</table>
			</div>
    </>
  )
}

export default ListReportInvoices