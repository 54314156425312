export function UploadImage() {
	return (
		<svg
			width="88"
			height="89"
			viewBox="0 0 88 89"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" width="80" height="80" rx="8" fill="#F7F6F6" />
			<path
				d="M37.5 50H43.5C48.5 50 50.5 48 50.5 43V37C50.5 32 48.5 30 43.5 30H37.5C32.5 30 30.5 32 30.5 37V43C30.5 48 32.5 50 37.5 50Z"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M37.5 38C38.6046 38 39.5 37.1046 39.5 36C39.5 34.8954 38.6046 34 37.5 34C36.3954 34 35.5 34.8954 35.5 36C35.5 37.1046 36.3954 38 37.5 38Z"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M31.1699 46.9501L36.0999 43.6401C36.8899 43.1101 38.0299 43.1701 38.7399 43.7801L39.0699 44.0701C39.8499 44.7401 41.1099 44.7401 41.8899 44.0701L46.0499 40.5001C46.8299 39.8301 48.0899 39.8301 48.8699 40.5001L50.4999 41.9001"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<rect x="55.5" y="57" width="32" height="32" rx="8" fill="white" />
			<path
				d="M71.5 83C77.0228 83 81.5 78.5228 81.5 73C81.5 67.4772 77.0228 63 71.5 63C65.9772 63 61.5 67.4772 61.5 73C61.5 78.5228 65.9772 83 71.5 83Z"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M71.5 76.5V70.5"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M68.5 72.5L71.5 69.5L74.5 72.5"
				stroke="#707170"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
