import resetIcon from "assets/images/wallet/resetIcon.png";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import moment from "moment";
import Locale from "translations";

export default function MainFilter({
	filter,
	setFilter,
	countries,
	handleChange,
	listAuto,
	setListAuto,
	getListAuto,
}) {
	const { booking, backOffice } = Locale;
	const { locale } = useGlobalState();
	const sourceOption = [
		{ value: "website", label: "Website" },
		{ value: "marketplace", label: "Marketplace" },
	];
	return (
		<div className=" py-2 my-2 px-3 border-bottom main-filter">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						/* 						hasLabel={false}
						 */ value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col booking-autoComplete">
					<AutoCompleteField
						hasLabel={true}
						flag={filter.goingTo?.country?.flag}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						isSearchable={true}
						placeholder={booking.Destination}
						label={booking.Destination}
						value={filter.destination}
						onChange={(e) =>
							handleChange({ name: "destination", value: { name: e } }, true)
						}
						onSelectValue={(e) => {
							handleChange({ name: "destination", value: e }, true);
						}}
					/>
				</div>

				{/* <div className="col">
                    <SelectField
                        hasLabel={false}
                        placeholder={booking.VisaType}
                        name="status"
                        options={visaTypesLookups}
                        value={filter.visa_type_id}
                        onChange={(e) => {
                            setFilter({
                                ...filter,
                                visa_type_id: e,
                                page: null
                            })
                        }}

                    />
                </div> */}

				{/* <div className="col">
					<DatePickerField
						type="text"
						placeholder={booking.BookingDate}
						label={booking.BookingDate}
						onOutsideClick={false}
					 	date={filter?.booking_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								booking_date: e,
								page: null,
							});
						}}
					/>
				</div> */}
				<div className="col date-range-input date-range-input-right-50">
					<p className="mb-0 mt-2">{"Booking Date"}</p>
					<DateRangeField
						hasLabel={false}
						startDatePlaceholder={"Start Date"}
						endDatePlaceholder={"End Date"}
						startDate={filter.booking_date_start}
						readOnly={true}
						endDate={filter.booking_date_end}
						onDatesChange={({ startDate, endDate }) => {
							//.add(1, "d")
							endDate = endDate
								? moment(endDate).format("YYYY-MM-DD")
								: moment(startDate).format("YYYY-MM-DD");
							setFilter({
								...filter,
								booking_date_start: moment(startDate).format("YYYY-MM-DD"),
								booking_date_end: endDate,
								page: null,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={"Source"}
						label={"Source"}
						name="source"
						options={sourceOption}
						value={filter.source}
						onChange={(e) => {
							setFilter({
								...filter,
								source: e,
								page: null,
							});
						}}
					/>
				</div>
				<div className="col">
					<div
						className="d-flex  mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={() => {
							setFilter({
								page: filter.page,
								company_name: "",
								destination: "",
								visa_type_id: null,
								request_date: null,
								source: null,
							});
						}}
					>
						{/* 						<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
						 */}{" "}
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
