import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { showVendor } from "services/vendor";
import Locale from "translations";
import VendorConfigurations from "./Configurations";
import AirlinesReservation from "./airlinesReservation";
import ViewVendor from "./details/View";
import ToursReservation from "./toursReservation";
import TransferReservation from "./transferReservation";
import VendorReservation from "./vendorReservation";
import VendorWallet from "./vendorWallet";

export default function ManageVendor() {
	const { wallet } = Locale;
	const { path, url } = useRouteMatch();
	const { push, location } = useHistory();
	const { pathname } = location;
	const [service, setService] = useState([]);
	const { id } = useParams();

	const [isOpenAdd, setIsOpenAdd] = useState(false);
	const toggleAdd = () => {
		setIsOpenAdd((prev) => !prev);
	};
	useEffect(() => {
		const fetchData = async () => {
			const res = await showVendor(id);
			if (res?.status === 200) {
				let serv = res.data.data.services;
				let listServ = [
					{
						id: 1,
						route: "vendor-details",
						label: "Vendor Details",
						component: <ViewVendor details={res.data.data} />,
					},
					{
						id: 2,
						route: "configurations",
						label: "Configurations",
						component: <VendorConfigurations fetchData={fetchData} allService={serv} />,
					},
					// {
					// 	id: 3,
					// 	route: "commission",
					// 	label: "Commission",
					// 	component: <VendorCommission />,
					// },
				];

				serv.map((res) => {
					if (res.name == "hotels") {
						listServ.push({
							id: 4,
							route: "hotel-reservation",
							label: "Hotel Reservation",
							component: <VendorReservation />,
						});
					} else if (res.name == "transfers") {
						listServ.push({
							id: 5,
							route: "transfer-reservation",
							label: "Transfer Reservation",
							component: <TransferReservation />,
						});
					} else if (res.name == "flight") {
						listServ.push({
							id: 6,
							route: "airlines-reservation",
							label: "Airlines Reservation",
							component: <AirlinesReservation />,
						});
					} else if (res.name == "tours") {
						listServ.push({
							id: 7,
							route: "tours-reservation",
							label: "Tours Reservation",
							component: <ToursReservation />,
						});
					}
				});
				listServ.push({
					id: 8,
					route: "wallet",
					label: "wallet",
					// component: (
					// 	<VendorWallet
					// 		isOpenAdd={isOpenAdd}
					// 		toggleAdd={toggleAdd}
					// 	/>
					// ),
				});
				setService(listServ);
			}
		};
		fetchData();
	}, []);
	return (
		<div className="vendor">
			<div
				className=" tabs d-flex align-items-center all-tabs"
				style={{ borderBottom: "1px solid #d9dde0" }}
			>
				<div className="d-flex justify-content-between w-100">
					<div className="">
						{service.map((pane) => (
							<NavLink
								exact={true}
								activeClassName="is-active"
								className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
                         ${pathname.includes(pane.route) ? " is-active" : ""}`}
								onClick={() => {
									push(`${url}/${pane.route}`);
								}}
							>
								{pane.label}
							</NavLink>
						))}

						{/* <NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${pathname.includes("hotel-reservation") ? "is-active" : ""}`}
							onClick={() => {
								push(`${url}/hotel-reservation`);
							}}
						>
							Hotel Reservations
						</NavLink>

						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${pathname.includes("transfer-reservation") ? "is-active" : ""}`}
							onClick={() => {
								push(`${url}/transfer-reservation`);
							}}
						>
							Transfer Reservations
						</NavLink>

						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${pathname.includes("tours-reservation") ? "is-active" : ""}`}
							onClick={() => {
								push(`${url}/tours-reservation`);
							}}
						>
							Tours Reservations
						</NavLink>
						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${pathname.includes("airlines-reservation") ? "is-active" : ""}`}
							onClick={() => {
								push(`${url}/airlines-reservation`);
							}}
						>
							Airlines Reservations
						</NavLink>
						<NavLink
							exact={true}
							activeClassName="is-active"
							className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps
					${pathname.includes("wallet") ? "is-active" : ""}`}
							onClick={() => {
								push(`${url}/wallet`);
							}}
						>
							{vendor.wallet}
						</NavLink> */}
					</div>
					<ShowForPermission permission="Vendors-Management-Manage">
						{pathname.includes("wallet") ? (
							<button
								//type="button"
								class="btn btn-success text-white px-2 mx-3 my-1"
								onClick={() => setIsOpenAdd((prev) => !prev)}
							>
								{wallet.AddWallet}
							</button>
						) : null}
					</ShowForPermission>
				</div>
			</div>
			<Switch>
				{service.map((pane) => (
					<Route path={`${path}/${pane.route}`}>
						{pane.route != "wallet" ? (
							pane.component
						) : (
							<VendorWallet isOpenAdd={isOpenAdd} toggleAdd={toggleAdd} />
						)}
					</Route>
				))}
			</Switch>
		</div>
	);
}
