import validate, { isFormValid } from "helpers/validate";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { addCommercialDist, commercialDistAssociateServices, editCommercialDist } from 'services/commercialDistribution';
import ConsumnerConfigurationTable from '../Configuration';
import { formatCommercialDist, generateServicesTabs, handleResponseNotification } from '../helpers/commercialDistHelper';
import AddServicesModal from './AddServicesModal';
import CounsumerDetails from './Details';


export default function CommercialDistributionDetails({
  isManage,
  lookups,
  commercialDistData,
  setCommercialDistData,
  fetchCitiesBasedOnCountry,
  setServicesListTabs
}) {
  const history = useHistory();
  const { id } = useParams();

  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  // enable Edit 
  function enableEditData() {
    setCommercialDistData({ ...commercialDistData, editMode: true })
  }

  function toggleServicesModal() {
    setServiceModalOpen(!serviceModalOpen);
  }

  function checkFormErrors() {
    let submitError = {}
    Object.keys(commercialDistData?.details).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: commercialDistData?.details[key] },
          { required: true, }
        ),
      }
    });
    setErrors(() => submitError)
  }

  // changes in commercial distribution inputs
  function handleCommerialDistDetailsInputs(value, keyName) {
    if (keyName === "country") {
      fetchCitiesBasedOnCountry(value?.id);
      setCommercialDistData({
        ...commercialDistData,
        details: {
          ...commercialDistData?.details,
          city: null,
          country: value,
        }
      });
    } else if (keyName === "date_from" && commercialDistData?.details?.date_to) {
      setCommercialDistData({
        ...commercialDistData,
        details: {
          ...commercialDistData?.details,
          date_to: moment(commercialDistData?.details?.date_to).isAfter(value) ? commercialDistData?.details?.date_to : null,
          date_from: value
        }
      });
    } else {
      setCommercialDistData({
        ...commercialDistData,
        details: {
          ...commercialDistData?.details,
          [keyName]: value,
        }
      });
    }
    setErrors({
      ...errors,
      ...validate({ name: keyName, value },
        { required: true }),
    });
  }

  // add new consumer
  async function addEditCommercialDist() {
    const data = {
      name: commercialDistData?.details?.name,
      link: commercialDistData?.details?.link,
      country_id: commercialDistData?.details?.country?.id,
      city_id: commercialDistData?.details?.city?.id,
      currency: commercialDistData?.details?.currency?.label,
      contract_date_from: commercialDistData?.details?.date_from ? moment(commercialDistData?.details?.date_from).format("YYYY-MM-DD") : "",
      contract_date_to: commercialDistData?.details?.date_from ? moment(commercialDistData?.details?.date_to).format("YYYY-MM-DD") : "",
    }
    const res = isManage ? await editCommercialDist(id, data) : await addCommercialDist(data);
    if (res?.status === 200) {
      const formatData = formatCommercialDist(res);
      isManage ? setCommercialDistData(formatData) : history.push(`/manage-commercial-distribution/${res?.data?.data?.id}/details`);
      const message = isManage ? "Commercial Distribution edited successfully" : "Commercial Distribution added successfully"
      handleResponseNotification(res, "success", "", message);
    }
  }
  // add services
  async function addServices(selectedServices) {
    const data = { services: selectedServices?.map(service => service?.name) }
    // call api 
    const res = await commercialDistAssociateServices(id, data)
    if (res?.status === 200) {
      const formatData = formatCommercialDist(res);
      const servicesTabs = generateServicesTabs(res?.data?.data?.services);
      setCommercialDistData(formatData);
      setServicesListTabs(servicesTabs);
      handleResponseNotification(res, "success", "Commercial Distribution Services", "Services added successfully");
      toggleServicesModal();
    }
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      addEditCommercialDist();
    }
    return () => { }
  }, [isErrorLoaded])

  return (
    <div className='d-flex flex-column'>
      <div className=''>
        {!isManage && <h2 className='h5 my-2'>Add New</h2>}
        <section className='mt-3'>
          <CounsumerDetails
            commercialDistDetails={commercialDistData?.details}
            lookups={lookups}
            isManage={isManage}
            editMode={commercialDistData?.editMode}
            handleCommerialDistDetailsInputs={handleCommerialDistDetailsInputs}
            enableEditData={enableEditData}
            errors={errors}
          />
        </section>
      </div>

      {isManage ?
        <section className='mt-3'>
          <ConsumnerConfigurationTable
            servicesListList={commercialDistData.servicesList}
            toggleServicesModal={toggleServicesModal}
            allServices={lookups?.services}
          />
        </section>
        :
        null
      }



      <div className='d-flex justify-content-end mt-3'>
        <button className='cancel-bg py-2 px-4 mx-3' onClick={() => history.push('/commercial-distribution')}>Back</button>
        {!isManage ?
          <button className='accept-btn' onClick={submit}>
            Add
          </button>
          : null
        }
        {isManage && commercialDistData?.editMode ?
          <button className='accept-btn' disabled={!commercialDistData?.editMode} onClick={submit}>
            Save Changes
          </button>
          :
          null
        }
      </div>

      <AddServicesModal
        isOpen={serviceModalOpen}
        toggleModal={toggleServicesModal}
        allServices={lookups?.services}
        addServices={addServices}
        selectedServices={commercialDistData.servicesList}
      />
    </div >
  )
}
