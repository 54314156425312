import { Modal, ModalBody } from "reactstrap";

const InsuranceModal = ({
	isOpen,
	toggle,
	setInsuranceState,
	insuranceState,
}) => {
	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle}>
				<div className="lan d-flex justify-content-between align-items-baseline p-2 border-bottom vendor-wallet-view-popup ">
					<p className="  h4  font-weight-bold">Upload Insurance </p>
					<i
						className="far fa-times-circle fa-lg  pointer"
						onClick={toggle}
					></i>
				</div>
				<ModalBody className="lan d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<div className=" m-0 p-0 w-75">
						<div className=" our-border-dashed mx-3">
							<h6 className="p-2 span">Attachment</h6>
							<div
								className={`py-3  text-center d-flex flex-column justify-content-center `}
							>
								<figure className="d-flex  justify-content-center ">
									<i className="fas fa-paperclip file-attach" />
									{insuranceState?.name}
								</figure>
								<button className="file-btn shape-border w-50 m-auto pointer">
									{"Add Attachment"}
									<input
										type="file"
										className="custom-file"
										accept="application/pdf"
										onChange={(e) => {
											setInsuranceState(e.target.files[0]);
										}}
									/>
								</button>
							</div>
						</div>
						<button
							className="btn companies-btn w-100 my-3"
							// onClick={AddRawda}
							disabled={insuranceState == ""}
							onClick={() => console.log("insuranceState", insuranceState)}
						>
							Send
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default InsuranceModal;
