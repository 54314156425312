import moment from "moment-timezone";
import { useState } from "react";
import { store } from "react-notifications-component";
import { addCallLog } from "services/b2c";
import { InboundIcon, OutboundIcon } from "./logIcon";

export default function CallLogs({ packageDetails, setReload }) {
	const { id, call_logs } = packageDetails;
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const [log, setLog] = useState({
		call_type: "inbound",
		note: "",
		package_reservation_id: id,
	});
	async function addLog() {
		const res = await addCallLog(log);
		if (res.status === 200 || res.status === 201) {
			setReload((prev) => !prev);
			setLog({
				...log,
				note: "",
			});
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1100,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}
	return (
		<>
			<div className="d-flex d-flex align-items-center justify-content-center">
				<button
					className={`btn Inbound  ${
						log.call_type === "inbound" ? "" : "opacity-50"
					}`}
					onClick={() => setLog({ ...log, call_type: "inbound" })}
				>
					<i class="fas fa-phone-alt me-1"></i>
					Inbound
				</button>
				<button
					className={`btn Outbound ms-2  ${
						log.call_type === "outbound" ? "" : "opacity-50"
					}`}
					onClick={() => setLog({ ...log, call_type: "outbound" })}
				>
					<i class="fas fa-phone-alt me-1"></i>
					Outbound
				</button>
			</div>
			<div className="mt-2 px-4 pb-4">
				<textarea
					className=" w-100 p-3"
					placeholder="Note Call"
					value={log.note}
					onChange={(e) => setLog({ ...log, note: e.target.value })}
				/>
				<div className="d-flex justify-content-end">
					<button
						className="btn btn-color text-white  px-3 d-flex justify-content-end"
						onClick={() => addLog()}
						disabled={log.note?.length < 3}
					>
						Save
					</button>
				</div>
			</div>
			{call_logs && call_logs.length > 0 ? (
				<div className="history p-4">
					<h1 className="mb-4">Calls Log</h1>
					<div>
						{call_logs.map((res) => {
							return (
								<div className="mb-3">
									<div className="head">
										<p>
											<span className="mx-1">
												{res?.call_type === "inbound" ? (
													<InboundIcon />
												) : (
													<OutboundIcon />
												)}
											</span>
											Logged by {res?.by_user}
										</p>

										<p>
											{moment(res.created_at)
												.tz(timeZone)
												.format("YYYY-MMM-DD | hh:mm A")}
										</p>
									</div>
									<span className="descrip">{res.note}</span>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}
