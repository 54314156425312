import { useEffect, useState } from "react";
import { store } from 'react-notifications-component';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "reactstrap";
import { cancelAllTransfers, cancelTransfers } from "services/Transfers";

function CancelAllModel({ isOpen, toggle, items, currency }) {
	const { id } = useParams();
	const checkeditem = items?.filter((res) => res?.status !== "CANCELED" &&
	res?.status !== "expired " ?  res:null);
	const [CancelItems, setCancelItems] = useState(checkeditem);
	const [AllCalc, setAllCalc] = useState({
		totalAfterCancel: null,
		CancellationFees: null,
		totalPaid: null,
		allTotalRefund: null,
	});
	const Notif = ()=>{
		store.addNotification({
			title: "Canceld",
			message: "item Canceld Successfully",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		window.location.reload();
	}
	const [Accepting, setAccepting] = useState(false);

	const handelItem = (item) => {
		if (CancelItems.includes(item)) {
			const itemremove = CancelItems.filter((res) => res !== item);
			setCancelItems(itemremove);
		} else {
			setCancelItems([...CancelItems, item]);
		}
	};
	const CallCancelItem = async (item) => {
		
		const req = await cancelTransfers(id, item);
		if (req.status === 200 || req.status === 201) {
			toggle()
			Notif()	
		}
	};
	const CallCancelAllItem = async () => {
		
		const req = await cancelAllTransfers(id);
		if (req.status === 200 || req.status === 201) {
			Notif()
			toggle()
		}
	};
	useEffect(() => {
		let alltotalCancel = 0;
		let allCancellationFees = 0;
		let alltotalPaid = 0;
		let totalrefund = 0;

		for (let i = 0; CancelItems.length > i; i++) {
			alltotalCancel += CancelItems[i]?.total_price;
			allCancellationFees += CancelItems[i]?.charge;
			totalrefund += CancelItems[i]?.expected_refund;
		}

		for (let i = 0; items.length > i; i++) {
			alltotalPaid += items[i]?.total_price;
		}
		setAllCalc({
			totalAfterCancel: alltotalCancel,
			CancellationFees: allCancellationFees,
			totalPaid: alltotalPaid,
			allTotalRefund: totalrefund,
		});
	}, [CancelItems]);

	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
				<div className="d-flex align-items-center border-bottom justify-content-between px-4 pt-3 w-100 ">
					<h3>Cancel Service</h3>
					<a onClick={toggle} href="##" className="btn-x pointer">
						<i class="fas fa-times"></i>
					</a>
				</div>
				<div className="row px-4 transfer">
					{checkeditem?.map((item) => (
						<>
							<div
								key={item?.id}
								className="col-5 d-flex align-items-center justify-content-between ps-1 pt-2"
							>
								<input
									type="checkbox"
									className="check-box"
									onClick={() => {
										handelItem(item);
									}}
									checked={CancelItems.includes(item)}
									name=""
									id={item?.id}
								/>
								<div
									className="img-box"
									style={{ width: "80%", height: "80%" }}
								>
									<img
										style={{
											width: "90%",
											height: "90%",
											objectFit: "contain",
										}}
										src={item?.image}
										alt={`${item?.type} ${item?.category} ${item?.category}`}
										srcset=""
									/>
								</div>
							</div>
							<div className="col-7 pe-1 pt-2">
								<div className="w-100 d-flex align-items-center justify-content-between pe-2">
									<span>
										<span className="trans-dark" style={{ fontSize: "20px" }}>
											{item?.type}
										</span>
										<span className="trans-dark-light"> {item?.category} </span>
										{item?.vehicle}
									</span>
									<span className="text-success">
										({item?.total_price} {item?.currency})
									</span>
								</div>
								<div className="info-trans">
									<p>
										<i className="fas fa-map-marker-alt"></i>
										<span>{item?.from_name}</span>
										<i
											className="fas fa-exchange-alt mx-2"
											style={{ color: "#0850AD" }}
										></i>
										<span>{item?.to_name}</span>
									</p>
								</div>
								<div className="info-trans">
									<p>
										<i className="far fa-calendar-alt"></i>
										<span>{item?.flight_time}</span>
									</p>
								</div>
								<div className="info-trans">
									<p>
										<i className="far fa-user"></i>{" "}
										<span>{`${item?.trip_type} Adults ${item?.adults} Child ${item?.children} infants ${item?.infants}`}</span>
									</p>
								</div>
							</div>
						</>
					))}
					<div className="col-12  mt-2 model-row">
						<p>
							<span>Final Price :</span>{" "}
							<span className="fw-bold">
								{AllCalc?.totalAfterCancel} {currency}
							</span>
						</p>
						<p>
							<span>Cancellation Fees :</span>
							<span className="fw-bold">
								{AllCalc?.CancellationFees} {currency}
							</span>
						</p>
						{AllCalc?.CancellationFees === 0 && (
							<span className="text-success">Free Cancellation</span>
						)}
					</div>
					<div className="col-12  mt-2 model-row">
						<p>
							<span>Paid Amount : </span>{" "}
							<span className="fw-bold">
								{AllCalc?.totalPaid} {currency}
							</span>
						</p>
						<p>
							<span>Total To Be Refunded:{AllCalc.allTotalRefund} </span>
						</p>
					</div>
					<div className="col-12">
						<div className="d-flex align-items-center mt-4">
							{" "}
							<input
								type="checkbox"
								className="me-1"
								name=""
								id="Cancel"
								onClick={() => {
									setAccepting(!Accepting);
								}}
							/>{" "}
							<label htmlFor="Cancel" className="mb-0">
								{" "}
								By Clicking On “Cancel Service” You Are Accepting Cancellation
								Charges
							</label>
						</div>
					</div>
					<div className="col-12">
						<button
							className="btn w-100 my-2 cancel-btn"
							disabled={!Accepting}
							onClick={() => {
								if (CancelItems.length === checkeditem.length) {
									CallCancelAllItem();
								}else{
									CancelItems?.forEach((item) => {
										CallCancelItem(item.id);
									});
								}
							}}
						>
							CANCEL SERVICE
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default CancelAllModel;
