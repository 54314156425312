import resetIcon from "assets/images/wallet/resetIcon.png";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useState } from 'react';
import { fetchCitiesSearch } from 'services/lookups';
import Locale from 'translations';



export default function CommercialDistHotelFilters({ filters, setFilters }) {
  const { backOffice, vendor } = Locale;
  const [listAuto, setListAuto] = useState([]);
  const [destination, setDestination] = useState("")
  const STATUS = [
    { value: 6, label: "confirmed" },
    { value: 1, label: "pending" },
    { value: 4, label: "canceled" },
    // { value: 2, label: "rejected" },
    // { value: 3, label: "approved" },
    // { value: 5, label: "paid" },
    // { value: 7, label: "expired" },
  ];

  function resetFilters() {
    setFilters({
      search: "",
      start_date: null,
      status: null,
      destination: null,
      check_in: null,
      page: 1,
    });
    setDestination("")
  };

  async function getListAuto(inputValue) {
    if (inputValue.length > 2) {
      const countries = await fetchCitiesSearch(inputValue);
      let result = countries.map((item) => {
        return {
          ...item,
          id: item.id,
          //name: item.name + " - " + item.country.name[locale],
          name: item.name,
          value: item.id,
          label: item.name,
        };
      });

      setListAuto(result);
    }
  };

  return (
    <div className="main-filter bg-white-blue py-2 my-2">
      <div className="row align-items-center w-100 no-gutter m-0">
        {/* search */}
        <div className="col-3">
          <TextField
            type="text"
            placeholder={backOffice.search}
            id="hotelSearch"
            name="hotelSearch"
            label={backOffice.search}
            hasLabel={true}
            value={filters?.search}
            onChange={(e) => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
          />
        </div>
        {/* checkin date */}
        <div className="col-2">
          <DatePickerField
            hasLabel={true}
            label={vendor.checkin}
            placeholder={backOffice.date}
            id="hotelCheckinDate"
            name="hotelCheckinDate"
            date={filters.check_in}
            onChangeDate={(filterDate) => {
              setFilters({
                ...filters,
                check_in: filterDate,
              });
            }}
            isOutsideRange={() => false}
          />
        </div>
        {/* destination */}
        <div className="col-2 no-padding">
          <AutoCompleteField
            hasLabel={true}
            label={vendor.destination}
            listAuto={listAuto}
            setListAuto={setListAuto}
            getListAuto={getListAuto}
            isSearchable={true}
            placeholder={backOffice.destination}
            value={destination?.label || destination}
            onChange={(e) => setDestination(e)}
            onSelectValue={(e) => {
              setDestination(e);
              setFilters({
                ...filters,
                destination: e,
              });
            }}
          />
        </div>
        {/* destination */}
        <div className="col-2">
          <SelectField
            hasLabel={true}
            label={vendor.status}
            value={filters.status}
            placeholder={backOffice.status}
            id="hotelReservationStatus"
            name="hotelReservationStatus"
            options={STATUS}
            onChange={(e) => {
              setFilters({
                ...filters,
                status: e,
              });
            }}
          />
        </div>

        <div className="col-md">
          <div
            onClick={resetFilters}
            className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
          >
            <img src={resetIcon} alt="reset filter" />
            <p className="text-caption">{backOffice.resetFilter}</p>
          </div>
        </div>

      </div>
    </div>
  )
}
