import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserActivityLogs } from "services/b2c";
import Locale from "translations";
import Filter from "./Filter";
export default function UserActivityLogs() {
	const { activity } = Locale;
	const { id } = useParams();
	const [activityList, setActivityList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		date_from: undefined,
		date_to: undefined,
		page: 1,
	});
	console.log(filter);
	useEffect(() => {
		async function fetchActivity() {
			const res = await getUserActivityLogs(id, {
				...filter,
				date_from: filter.date_from
					? moment(filter.date_from).format("YYYY-MM-DD")
					: "",
				date_to: filter.date_to
					? moment(filter.date_to).format("YYYY-MM-DD")
					: "",
			});
			setActivityList(res.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchActivity();
	}, [filter]);

	const AllActivity =
		activityList?.length > 0 ? (
			activityList?.map((activity, index) => {
				return (
					<tr key={activity.id}>
						<td>{activity.full_description}</td>

						<td>{moment(activity.created_at).format("YYYY-MM-DD | h:mm")}</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="2">
					<div className="product-build__product-no-data fullHeight">
						<h4>{activity.noActivity}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div className="product-builder-list bg-transparent">
			<Filter filter={filter} setFilter={setFilter} />
			<div className=" table-responsive our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							{/* <th>{activity.teamMember}</th> */}
							{/* <th>{activity.email}</th> */}

							<th width="70%">{activity.Event}</th>
							<th>{activity.Date}</th>
						</tr>
					</thead>

					<tbody> {AllActivity}</tbody>
				</table>
				<div className="px-2">
					{activityList?.length > 0 ? (
						<Pagination info={meta} goTo={goTo} />
					) : null}{" "}
				</div>
			</div>
		</div>
	);
}
