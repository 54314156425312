import { useState } from "react";
import { store } from 'react-notifications-component';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "reactstrap";
import { cancelTransfers } from "services/Transfers";

function CancelModel({ isOpen, toggle, item }) {
	const { id } = useParams();
	const [Accepting, setAccepting] = useState(false);
	const Notif = ()=>{
		store.addNotification({
			title: "Canceld",
			message: "Item Canceld Successfully",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		window.location.reload();
	}
	const cancelitem = async (id, item) => {
		const req = await cancelTransfers(id, item);
		if (req.status === 200 || req.status === 201) {
			toggle()
			Notif()
		}
	};
	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
				<div className="d-flex align-items-center border-bottom justify-content-between px-4 pt-3 w-100 ">
					<h3>Cancel Service</h3>
					<a href="##" onClick={toggle} className="btn-x pointer">
						<i class="fas fa-times"></i>
					</a>
				</div>
				<div className="row px-4 transfer">
					<div className="col-5 ps-1 pt-2">
						<div className="img-box">
							<img
								src={item?.image}
								alt={`${item?.type} ${item?.category} ${item?.category}`}
								srcset=""
							/>
						</div>
					</div>
					<div className="col-7 pe-1 pt-2">
						<div className="w-100 d-flex align-items-center justify-content-between pe-2">
							<span>
								<span className="trans-dark" style={{ fontSize: "20px" }}>
									{item?.type}
								</span>
								<span className="trans-dark-light"> {item?.category} </span>
								{item?.vehicle}
							</span>
							<span className="text-success">
								({item?.total_price} {item?.currency})
							</span>
						</div>
						<div className="info-trans">
							<p>
								<i className="fas fa-map-marker-alt"></i>
								<span>{item?.from_name}</span>
								<i
									className="fas fa-exchange-alt mx-2"
									style={{ color: "#0850AD" }}
								></i>
								<span>{item?.to_name}</span>
							</p>
						</div>
						<div className="info-trans">
							<p>
								<i className="far fa-calendar-alt"></i>
								<span>{item?.flight_time}</span>
							</p>
						</div>
						<div className="info-trans">
							<p>
								<i className="far fa-user"></i>{" "}
								<span>{`${item?.trip_type} Adults ${item?.adults} Child ${item?.children} infants ${item.infants}`}</span>
							</p>
						</div>
					</div>
					<div className="col-12  mt-2 model-row">
						<p>
							<span>Final Price :</span>{" "}
							<span className="fw-bold">
								{item?.total_price} {item?.currency}
							</span>
						</p>
						<p>
							<span>Cancellation Fees :</span>
							{/* {item?.cancellation_policies?.map((res) => (
								<span key={res.amount} className="fw-bold">
									{res.amount} {res.currencyId}
								</span>
							))} */}
							<span>
								{item?.charge} {item?.currency}{" "}
							</span>
						</p>
						{item?.charge === 0 && (
							<span className="text-success">Free Cancellation</span>
						)}
					</div>
					<div className="col-12  mt-2 model-row">
						<p>
							<span>Paid Amount : </span>{" "}
							<span className="fw-bold">
								{item?.total_price} {item?.currency}
							</span>
						</p>
						<p>
							Total To Be Refunded:{" "}
							<span className="fw-bold">
								{" "}
								{item?.expected_refund} {item?.currency}{" "}
							</span>
						</p>
					</div>
					<div className="col-12">
						<div className="d-flex align-items-center mt-4">
							{" "}
							<input
								type="checkbox"
								className="me-1"
								name=""
								id="Cancel"
								onClick={() => {
									setAccepting(!Accepting);
								}}
							/>{" "}
							<label htmlFor="Cancel" className="mb-0">
								By Clicking On “Cancel Service” You Are Accepting Cancellation
								Charges
							</label>
						</div>
					</div>
					<div className="col-12">
						<button
							disabled={!Accepting}
							className="btn w-100 my-2 cancel-btn"
							onClick={() => {
								cancelitem(id, item?.id);
							}}
						>
							CANCEL SERVICE
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default CancelModel;
