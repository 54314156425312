import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function ContractFilter({ filterState, setFilterState }) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();

	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setFilterState({ ...filterState, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setFilterState({
					...filterState,
					city: "",
					[name]: value,
				});
			} else setFilterState({ ...filterState, [name]: value });
		}
	};

	//Fetch Countries
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const Status = [
		{
			value: 1,
			label: "Active",
		},
		/* 		{
			value: 2,
			label: "VERIFIED",
		}, */
		{
			value: 3,
			label: "Inactive",
		},
	];

	const resetFilters = () => {
		setFilterState({
			supplierName: "",
			hotelName: "",
			country: "",
			status: "",
		});
	};

	return (
		<>
			<>
				<div className="main-filter bg-white-blue">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.supplierName}
									label={backOffice.supplierName}
									/* 									hasLabel={false}
									 */ value={filterState.supplierName}
									onChange={(e) => {
										setFilterState({
											...filterState,
											supplierName: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.hotelName}
									label={backOffice.hotelName}
									value={filterState.hotelName}
									onChange={(e) => {
										setFilterState({
											...filterState,
											hotelName: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md-2">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filterState.country}
								placeholder={backOffice.country}
								label={backOffice.country}
								name="country"
								options={countries}
								onChange={(e) => {
									handleChange({ name: "country", value: e });
								}}
							/>
						</div>

						<div className="col-md-2">
							<SelectField
								/* 								hasLabel={false}
								 */ value={filterState.status}
								placeholder={backOffice.status}
								label={backOffice.status}
								name="status"
								options={Status}
								onChange={(e) => {
									setFilterState({
										...filterState,
										status: e,
									});
								}}
							/>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
							>
								<img src={resetIcon} alt="" srcset="" />
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
