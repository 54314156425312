import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { getNotification } from "helpers/makeNotifications";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { addPaymentCard, listCompanyWallets } from "services/multiWallets";
import Locale from "translations";
//import AddBalancePopup from "./ui/addBalancePopup";
import ShowForPermission from "helpers/showForPermission";
import PaymentPopup from "./ui/PaymentPopup";
import ViewTransactionPopup from "./ui/ViewTransactionPopup";
import Wallet from "./Wallet";

export default function MultiWallet() {
	const { allCurrencies } = useGlobalState();
	const { company_id } = useParams();
	const [listWallets, setListWallets] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [popupInfo, setpopupInfo] = useState({});
	const [reload, setReload] = useState(true);
	const { wallet } = Locale;

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenAdd, setIsOpenAdd] = useState(false);
	const [isOpenView, setIsOpenView] = useState(false);

	const toggle = (wallet_id = null, currency = null, account = null, type) => {
		setpopupInfo({
			wallet_id,
			currency,
			account,
			type,
		});
		setIsOpen((prev) => !prev);
	};

	const toggleAdd = () => {
		setIsOpenAdd(!isOpenAdd);
	};

	const toggleView = (wallet_id = null, deal_id = null, type = null) => {
		setpopupInfo({
			wallet_id,
			deal_id,
			type,
		});
		setIsOpenView(!isOpenView);
	};

	useEffect(() => {
		const getWallets = async () => {
			const res = await listCompanyWallets(company_id);
			if (res?.status === 200) {
				setListWallets(res.data.data);
			}
		};
		getWallets();
	}, []);

	useEffect(() => {
		const formatted = allCurrencies.map((item) => {
			return { value: item.id, label: item.currency_code };
		});
		setCurrencies(formatted);
		return () => {};
	}, [allCurrencies]);

	const allWallets = useMemo(() => {
		return listWallets?.length > 0
			? listWallets.map((wallet) => (
					<Wallet
						key={wallet.id}
						Wallet={wallet}
						toggle={toggle}
						isOpenView={isOpenView}
						toggleView={toggleView}
						viewMore={true}
						reload={reload}
						setReload={setReload}
					/>
			  ))
			: null;
	}, [listWallets, reload]);

	const addWallet = async () => {
		const res = await addPaymentCard({
			company_id,
			currency: popupInfo.Currency.label,
		});
		if (res?.status === 200 || res?.status === 201) {
			getNotification(res?.data?.message);
			toggleAdd();
			setListWallets((prev) => [...prev, res?.data?.data]);
		}
	};

	return (
		<div>
			<div className="d-flex justify-content-end">
				<div className="col-2 my-3">
					<ShowForPermission permission="Tourism-Company-Manage">
						<button
							type="button"
							class="btn btn-success w-75"
							onClick={toggleAdd}
						>
							{wallet.AddWallet}
						</button>
					</ShowForPermission>
				</div>
			</div>

			{allWallets}

			<PaymentPopup
				isOpen={isOpen}
				popupInfo={popupInfo}
				toggle={toggle}
				setpopupInfo={setpopupInfo}
				setReload={setReload}
			/>
			{/***For Test */}
			{/* <AddBalancePopup
				isOpen={isOpen}
				popupInfo={popupInfo}
				toggle={toggle}
				setpopupInfo={setpopupInfo}
				setReload={setReload}
			/> */}

			<ViewTransactionPopup
				isOpen={isOpenView}
				toggle={toggleView}
				popupInfo={popupInfo}
			/>

			<Modal isOpen={isOpenAdd} toggle={toggleAdd}>
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
					<p className=" text-secondary "> {wallet.AddWallet}</p>
					<i
						className="far fa-times-circle fa-lg text-secondary "
						onClick={toggleAdd}
					></i>
				</div>

				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<div className=" m-0 p-0 w-75">
						<div className="mx-1">
							<SelectField
								type="text"
								placeholder={wallet.Currency}
								label={wallet.Currency}
								options={currencies}
								onChange={(e) => {
									setpopupInfo({
										Currency: e,
									});
								}}
								hasLabel={true}
								name="Currency"
							/>
						</div>
					</div>
				</ModalBody>
				<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
					<button
						className="btn rounded companies-btn  w-75"
						onClick={addWallet}
					>
						{wallet.AddWallet}
					</button>
				</div>
			</Modal>
		</div>
	);
}
