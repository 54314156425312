import moment from "moment";

export const roomsTotals = (details) => {
	let totalRooms = 0;
	let totalSellingPrice = 0;
	const dateFrom = moment(details.date_from);
	const dateTo = moment(details.date_to);
	const nights = dateTo.diff(dateFrom, "days");

	details.rooms.forEach((i) => {
		totalRooms += +i.room_count;
		totalSellingPrice += +i.room_count * nights * i.selling_rate;
	});

	return { totalRooms, totalSellingPrice, nights };
};

export const paymentsTotals = (payments) => {
	let paidPayments = 0;
	let unpaidPayments = 0;
	let refundPayments = 0;
	let total = 0;
	payments.forEach((i) => {
		if (
			i.action != "refund Fees" &&
			i.action != "refund" &&
			i.action != "refund_fees"
		) {
			total += +i.provider_amount;
		}

		if (i.action == "refund") {
			total += -i.provider_amount;
		}

		if (i.status === "confimed" || i.status === "confirmed") {
			if (
				i.action != "refund" &&
				i.action != "refund Fees" &&
				i.action != "refund_fees"
			) {
				paidPayments += +i.provider_amount;
			}
		}

		if (i.status === "pending") {
			unpaidPayments += i.provider_amount;
		}
		if (i.action === "refund") {
			refundPayments += +i.provider_amount;
		}
	});
	return { paidPayments, unpaidPayments, refundPayments, total };
};

export const formatArray = (array) => {
	return array && array.length > 0
		? array.map((item) => (item = { label: item.name, value: item.id }))
		: [];
};
