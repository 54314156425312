import ShowForPermission from "helpers/showForPermission";
import { Link, useParams } from "react-router-dom";
import Switch from "react-switch";

import Locale from "translations";

export default function Employee({
	employee,
	index,
	toggle,
	changeStatus,
	removeEmployee,
	empolyeeStatus,
}) {
	const { Companies } = Locale;
	const { company_id } = useParams();

	console.log("employee101", employee);
	return (
		<tr>
			<td>{index + 1}</td>
			<td>{employee?.full_name}</td>
			<td>{employee?.user_id}</td>
			<td>{employee?.group?.name}</td>
			<td>{employee?.branch?.name}</td>
			<td
				className={`${
					employee?.status?.name === "active" ? "text-success" : "text-danger"
				}`}
			>
				{employee?.status?.name}
			</td>

			<td>
				<ShowForPermission permission="Tourism-Company-Manage">
					<Link
						to={{
							pathname: `/tourism-company/edit/employee/${company_id}`,
							state: { employee: employee },
						}}
					>
						<i class="fas fa-edit"></i>
					</Link>
					{employee?.status?.name === "active" ? (
						<Switch
							height={24}
							width={45}
							handleDiameter={20}
							className="mx-2 align-middle"
							onChange={() =>
								empolyeeStatus(employee.user_id, employee?.status?.name)
							}
							checked={true}
							onColor="#1777fb"
						/>
					) : (
						/* 	<i
						class="fas fa-ban text-danger mx-2"
						onClick={() => changeStatus(employee.user_id)}
					></i> */
						<Switch
							height={24}
							width={45}
							handleDiameter={20}
							className="mx-2 align-middle"
							onChange={() =>
								empolyeeStatus(employee.user_id, employee?.status?.name)
							}
							checked={false}
						/>
						/* 		<i
						class="fas fa-check text-success mx-2"
						onClick={() => changeStatus(employee.user_id)}
					></i> */
					)}{" "}
				</ShowForPermission>
				{/*   <i
          class="fas fa-key text-success mx-2 "
          onClick={() => {
            toggle();
          }}
        ></i> */}
				{/* <i
          class="fas fa-trash text-danger"
          onClick={() => removeEmployee(employee.user_id)}
        ></i> */}
			</td>
		</tr>
	);
}
