import BackOfficeRoute from "components/BackOfficeRoute";
import { CompaniesLayoutRoute } from "components/CompaniesLayoutRoute";
import ContractRouter from "components/ContractRouter";
import HotelProviderRouter from "components/HotelProviderRouter";
import AllNotifications from "components/Notifications";
import { OnlineVisaRoute } from "components/OnlineVisaRoute";
import PlatformRoute from "components/PlatformRoute";
import PropertyRoute from "components/PropertyRoute";
import Calendar from "modules/backOffice/Calendar";
import ActivityLogs from "modules/backOffice/Components/activityLogs";
import BankConfigrations from "modules/backOffice/Components/Bank-configration";
import MakeBankConfigrations from "modules/backOffice/Components/Bank-configration/MakeBankConfigrations";
import Bookings from "modules/backOffice/Components/Bookings";
import BookingView from "modules/backOffice/Components/Bookings/booking-view";
import Voucher from "modules/backOffice/Components/Bookings/booking-view/Voucher";
// import ManagingVendor from "modules/backOffice/Components/Vendor/manageVendor/managing";
//import ManageVendor from "modules/backOffice/Components/Vendor/manageVendor/vendorCommission";
import ChangePassword from "modules/backOffice/Components/change-password/ChangePassword";
import EditCompany from "modules/backOffice/Components/Companies/EditCompany";
import ContractListDetails from "modules/backOffice/Components/ContractList/Details/Index";
import EditDetails from "modules/backOffice/Components/ContractList/editDetails";
import ContractDetails from "modules/backOffice/Components/ContractRequest/Details/Index";
import ExchangeRate from "modules/backOffice/Components/ExchangeRate";
import GroundConfiguration from "modules/backOffice/Components/GeneralConfigration/Ground";
import HotelDetails from "modules/backOffice/Components/HotelCommissions/HotelDetails/Index";
import AddInvitation from "modules/backOffice/Components/Invitations/AddInvitation";
import AddGroup from "modules/backOffice/Components/ManagementTeam/Groups/AddGroup";
import Groups from "modules/backOffice/Components/ManagementTeam/Groups/Groups";
import AddUser from "modules/backOffice/Components/ManagementTeam/Users/AddUser";
import Users from "modules/backOffice/Components/ManagementTeam/Users/Users";
import ManualSuppliers from "modules/backOffice/Components/manual-suppliers";
import AddSupplier from "modules/backOffice/Components/manual-suppliers/addSupplier";
import ListReservation from "modules/backOffice/Components/manual-suppliers/ListReservation/index";
import Payment from "modules/backOffice/Components/manual-suppliers/payment";
import AddOfflineReservation from "modules/backOffice/Components/OfflineReservation/addOfflineReservation";
import ListOfflineReservations from "modules/backOffice/Components/OfflineReservation/listOfflineReservation";
import PlatformDetails from "modules/backOffice/Components/PlatformCommessions/PlatformDetails/Index";
import Property from "modules/backOffice/Components/Property";
import PropertyDetails from "modules/backOffice/Components/PropertyManagement/Details";
import RequestsGround from "modules/backOffice/Components/Requests/Ground";
import PassangerProfileGround from "modules/backOffice/Components/Requests/Ground/PassangerProfile";
import ViewRequestGround from "modules/backOffice/Components/Requests/Ground/ViewRequest";
import RequestsTransportation from "modules/backOffice/Components/Requests/Transportation";
import PassangerProfileTransportation from "modules/backOffice/Components/Requests/Transportation/PassangerProfile";
import ViewRequestTransportation from "modules/backOffice/Components/Requests/Transportation/ViewRequest";
import OnlineVisaRequests from "modules/backOffice/Components/Requests/Visa";
import PassangerProfile from "modules/backOffice/Components/Requests/Visa/PassangerProfile";
import ViewRequest from "modules/backOffice/Components/Requests/Visa/ViewRequest";
// import ManagingVendor from "modules/backOffice/Components/Vendor/manageVendor/managing";
//import ManageVendor from "modules/backOffice/Components/Vendor/manageVendor/vendorCommission";
import AirlineRequests from "modules/backOffice/Components/Airline/Requests";
import AirlineReservationsList from "modules/backOffice/Components/Airline/Reservations/list";
import ViewAirlineReservation from "modules/backOffice/Components/Airline/Reservations/view";
import Attractions from "modules/backOffice/Components/Attractions";

import AddEditAttraction from "modules/backOffice/Components/Attractions/AddEdit";
import AttractoinCalender from "modules/backOffice/Components/Attractions/Calender";
import ViewAttraction from "modules/backOffice/Components/Attractions/View";
import AllBookings from "modules/backOffice/Components/B2C/AllBookings";
import AllTransactions from "modules/backOffice/Components/B2C/AllTransactions";
import B2CBookingDetails from "modules/backOffice/Components/B2C/BookingDetails";
import CommissionB2c from "modules/backOffice/Components/B2C/Commission";
import ChangePackageCrm from "modules/backOffice/Components/B2C/Packages-Booking/ChangePackage";
import InvoicesReport from "modules/backOffice/Components/B2C/Packages-Booking/Invoices Report";
import PackagesBooking from "modules/backOffice/Components/B2C/Packages-Booking/List-Booking-Package";
import RedirectCrm from "modules/backOffice/Components/B2C/Packages-Booking/Redirect";
import ViewPackages from "modules/backOffice/Components/B2C/Packages-Booking/view-Booking-Package";
import UserActivityLogs from "modules/backOffice/Components/B2C/users/ActivityLogs";
import B2cUsers from "modules/backOffice/Components/B2C/users/List";
import ViewUser from "modules/backOffice/Components/B2C/users/View";
import SoldVisas from "modules/backOffice/Components/Bookings/Sold Visas/List";
import BookingTours from "modules/backOffice/Components/Bookings/Tours/list";
import ViewBookingTours from "modules/backOffice/Components/Bookings/Tours/view";
import Transfers from "modules/backOffice/Components/Bookings/transfer/list";
import TransferView from "modules/backOffice/Components/Bookings/transfer/view";
import CommercialDistribution from "modules/backOffice/Components/CommercialDistribution";
import ManageCommercialDistribution from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution";
import CommercialDistAirLineReservationView from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution/AirLineReservation/View";
import CommercialDistributionServiceProvider from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution/Configuration/ServiceProviders";
import CommercialDistHotelReservationView from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution/HotelReservation/View";
import CommercialDistToursReservationView from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution/TourReservation/View";
import CommercialDisTransferReservationView from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution/TransferReservation/View";
import CommercialDistributionWallet from "modules/backOffice/Components/CommercialDistribution/ManageCommercialDistribution/Wallet";
import AllWallet from "modules/backOffice/Components/Financial/companyWallet/AllWallet";
import TransactionWallet from "modules/backOffice/Components/Financial/companyWallet/ListTransaction";
import FinancialOffline from "modules/backOffice/Components/Financial/offlineSuppliers";
import OnlineTranactions from "modules/backOffice/Components/Financial/onlineTransactions";
import FinancialPlatforms from "modules/backOffice/Components/Financial/platforms";
import CountryConfiguration from "modules/backOffice/Components/GeneralConfigration/Country";
import TransportationConfiguration from "modules/backOffice/Components/GeneralConfigration/Transportation";
import VisaConfiguration from "modules/backOffice/Components/GeneralConfigration/Visa";
import Package from "modules/backOffice/Components/package";
import PackageView from "modules/backOffice/Components/package/PackgeView";
import PopupAds from "modules/backOffice/Components/PopupAds";
import AddEditPopup from "modules/backOffice/Components/PopupAds/addEditPopup";
import PopupAdsCountries from "modules/backOffice/Components/PopupAds/popupAdsCountries";
import AddEditPromoCity from "modules/backOffice/Components/Promo/AddEditPromo";
import Promo from "modules/backOffice/Components/Promo/PromoList";
import AllVisas from "modules/backOffice/Components/Requests/AllVisas";
import ViewPassengerProfile from "modules/backOffice/Components/Requests/AllVisas/ViewPassengerProfile";
import DirectVisa from "modules/backOffice/Components/Requests/Visa/DirectVisa";
import EUPVisa from "modules/backOffice/Components/Requests/Visa/EUP";
import SaudiReservationsList from "modules/backOffice/Components/SaudiReservations/ReservationsList";
import SaudiReservationView from "modules/backOffice/Components/SaudiReservations/ReservationView";
import SBSReservationView from "modules/backOffice/Components/SBSReservations";
import SBSReservationsList from "modules/backOffice/Components/SBSReservationsList";
import MarketingOffers from "modules/backOffice/Components/SearchHotels";
import MarketingOffersList from "modules/backOffice/Components/SearchHotels/searchItemsTable";
import ViewMarketingOffer from "modules/backOffice/Components/SearchHotels/searchItemsTable/viewitem/ViewSearchHotel";
import SharingListDetails from "modules/backOffice/Components/SharingList/Details/Index";
import SharingDetails from "modules/backOffice/Components/SharingRequest/Details/Index";
import SubscriptionPlan from "modules/backOffice/Components/SubscriptionPlan";
import AddEditSubscriptionPackagePlan from "modules/backOffice/Components/SubscriptionPlan/PackagePlan/AddEditPlan";
import ReservationsList from "modules/backOffice/Components/SudanReservations/ReservationsList";
import SudanReservationView from "modules/backOffice/Components/SudanReservations/ReservationView";
import OnlineSupReservation from "modules/backOffice/Components/Suppliers/ListReservation";
import OnlinePayment from "modules/backOffice/Components/Suppliers/onlinePayment";
import Suppliers from "modules/backOffice/Components/Suppliers/Suppliers";
import SuppliersEdit from "modules/backOffice/Components/Suppliers/SuppliersEdit";
import TourismCompanies from "modules/backOffice/Components/TourismCompanies";
import AddCompany from "modules/backOffice/Components/TourismCompanies/addCompany";
import Branches from "modules/backOffice/Components/TourismCompanies/Branches";
import Employees from "modules/backOffice/Components/TourismCompanies/Employees";
import AddEmployee from "modules/backOffice/Components/TourismCompanies/Employees/AddEmployee";
import GroupsTourism from "modules/backOffice/Components/TourismCompanies/Groups";
import AddGroupTourism from "modules/backOffice/Components/TourismCompanies/Groups/AddGroupTourism";
import MultiWallet from "modules/backOffice/Components/TourismCompanies/MultiWallet";
import ListWalletTransactions from "modules/backOffice/Components/TourismCompanies/MultiWallet/ListWalletTransactions";
import Profile from "modules/backOffice/Components/TourismCompanies/Profile";
import Reservations from "modules/backOffice/Components/TourismCompanies/Reservations/ListReservations";
import ViewReservations from "modules/backOffice/Components/TourismCompanies/Reservations/ViewReservations";
import SubscriptionPlanList from "modules/backOffice/Components/TourismCompanies/SubscriptionPlan";
import AddEditSubscriptionPlan from "modules/backOffice/Components/TourismCompanies/SubscriptionPlan/AddEditPlan";
import CommissionSubscriptionPlan from "modules/backOffice/Components/TourismCompanies/SubscriptionPlan/Commission";
import ViewPlan from "modules/backOffice/Components/TourismCompanies/SubscriptionPlan/ViewPlan";
import CreateCompanyRequest from "modules/backOffice/Components/TourismCompanies/Wallet/CreateCompanyRequest";
import DealsVeiw from "modules/backOffice/Components/TourismCompanies/Wallet/DealsVeiw";
import RequestVeiw from "modules/backOffice/Components/TourismCompanies/Wallet/RequestVeiw";
import SaddRequest from "modules/backOffice/Components/TourismCompanies/Wallet/SaddRequest";
import TravelDetails from "modules/backOffice/Components/TravelManagement/Details/index";
import Vendor from "modules/backOffice/Components/Vendor";
import ManageVendor from "modules/backOffice/Components/Vendor/manageVendor";
import ViewVendorAirlineReservation from "modules/backOffice/Components/Vendor/manageVendor/airlinesReservation/view";
import HotelsService from "modules/backOffice/Components/Vendor/manageVendor/Configurations/HotelsService";
import EditVendor from "modules/backOffice/Components/Vendor/manageVendor/details/Edit";
import VendorToursView from "modules/backOffice/Components/Vendor/manageVendor/toursReservation/view";
import VendorTransferView from "modules/backOffice/Components/Vendor/manageVendor/transferReservation/view";
import ReservationView from "modules/backOffice/Components/Vendor/manageVendor/vendorReservation/reservation-view";
import VendorTransactionsList from "modules/backOffice/Components/Vendor/manageVendor/vendorWallet/vendorTransactionsList";
import AddEditHotel from "modules/backOffice/Containers/AddEditHotel";
import AddEditPlatform from "modules/backOffice/Containers/AddEditPlatform";
import CompanyManagement from "modules/backOffice/Containers/Companies";
import ContractMangment from "modules/backOffice/Containers/ContractMangment";
import Home from "modules/backOffice/Containers/Home";
import HotelCommissionsList from "modules/backOffice/Containers/HotelCommissionsList";
import Invitations from "modules/backOffice/Containers/Invitations";
import Main from "modules/backOffice/Containers/Main";
import Orders from "modules/backOffice/Containers/Orders";
import PlatformCommissionList from "modules/backOffice/Containers/PlatformCommissionList";
import PropertyManagement from "modules/backOffice/Containers/PropertyManagement";
import Seasons from "modules/backOffice/Containers/Seasons";
import SharingList from "modules/backOffice/Containers/SharingList";
import SharingRequest from "modules/backOffice/Containers/SharingRequest";
import TravelManagement from "modules/backOffice/Containers/TravelManagement";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";

// Lazy Components
const Error404 = lazy(() => import("./components/Errors/Error404/Error404"));
const Login = lazy(() => import("./modules/auth/pages/Login.js"));

// Application Routes
const Routes = (
	<Switch>
		{/* Login Module */}
		<Route path="/auth/login" exact component={Login} />
		<BackOfficeRoute exact path="/" component={Main} />
		<BackOfficeRoute exact path="/dashboard" component={Home} />
		<BackOfficeRoute exact path="/invitation" component={Invitations} />
		<BackOfficeRoute exact path="/notifications" component={AllNotifications} />
		<BackOfficeRoute path="/invitation/add" component={AddInvitation} />
		<BackOfficeRoute
			path="/property-management"
			component={PropertyManagement}
			exact
		/>
		<PropertyRoute
			path="/property-management?page=:page"
			component={PropertyManagement}
		/>
		<PropertyRoute
			path="/property-management/:id"
			component={PropertyDetails}
		/>
		<BackOfficeRoute path="/Calendar/:id" component={Calendar} />
		<PropertyRoute exact path="/property" component={Property} />
		<PropertyRoute exact path="/property/:id" component={Property} />
		<PropertyRoute exact path="/property/:id/:tab" component={Property} />
		<BackOfficeRoute path="/seasons" component={Seasons} exact />
		<BackOfficeRoute path="/suppliers" component={Suppliers} exact />
		<BackOfficeRoute
			path="/suppliers/edit/:id"
			component={SuppliersEdit}
			exact
		/>
		<BackOfficeRoute
			path="/suppliers/:name/:id/reservation"
			component={OnlineSupReservation}
		/>
		<BackOfficeRoute
			path="/suppliers/payment/:id"
			component={OnlinePayment}
			exact
		/>
		<BackOfficeRoute
			path="/visa-configuration"
			component={VisaConfiguration}
			exact
		/>
		<BackOfficeRoute
			path="/bank-configuration"
			component={BankConfigrations}
			exact
		/>
		<BackOfficeRoute
			path="/bank-configuration/:status/:id"
			component={MakeBankConfigrations}
			exact
		/>
		<BackOfficeRoute
			path="/bank-configuration/:status"
			component={MakeBankConfigrations}
			exact
		/>
		{/* Online Visa Route */}
		<BackOfficeRoute
			exact
			path="/online-visa/requests/all-visas"
			component={AllVisas}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/requests/all-visas/passenger-profile/:request_id/:passenger_id"
			component={ViewPassengerProfile}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/requests/direct"
			component={DirectVisa}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/requests/eup"
			component={EUPVisa}
		/>
		<OnlineVisaRoute
			exact
			path="/online-visa/requests/:status"
			component={OnlineVisaRequests}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/view/request/:status/:id"
			component={ViewRequest}
		/>
		<BackOfficeRoute
			exact
			path="/online-visa/view/passenger/:status/:request_id/:passenger_id"
			component={PassangerProfile}
		/>
		<OnlineVisaRoute
			exact
			path="/requests/transportation/:status"
			component={RequestsTransportation}
		/>
		<BackOfficeRoute
			exact
			path="/requests/transportation/:status/:id"
			component={ViewRequestTransportation}
		/>
		<BackOfficeRoute
			exact
			path="/requests/transportation/passenger/:status/:request_id/:passenger_id"
			component={PassangerProfileTransportation}
		/>
		<OnlineVisaRoute
			exact
			path="/requests/ground/:status"
			component={RequestsGround}
		/>
		<BackOfficeRoute
			exact
			path="/requests/ground/:status/:id"
			component={ViewRequestGround}
		/>
		<BackOfficeRoute
			exact
			path="/requests/ground/passenger/:status/:request_id/:passenger_id"
			component={PassangerProfileGround}
		/>
		<BackOfficeRoute exact path="/bookings" component={Bookings} />
		<BackOfficeRoute path="/booking/view/:id" component={BookingView} />
		<BackOfficeRoute exact path="/bookings-tours" component={BookingTours} />
		<BackOfficeRoute exact path="/bookings-transfers" component={Transfers} />
		<BackOfficeRoute exact path="/bookings-sold-visas" component={SoldVisas} />
		<BackOfficeRoute
			path="/view-bookings-transfer/:id"
			component={TransferView}
		/>
		<BackOfficeRoute
			exact
			path="/bookings-tours/:id"
			component={ViewBookingTours}
		/>
		<BackOfficeRoute
			exact
			path="/manual-reservations/sbs-reservations"
			component={SBSReservationsList}
		/>
		<BackOfficeRoute
			exact
			path="/manual-reservations/offline-reservations"
			component={ListOfflineReservations}
		/>
		<BackOfficeRoute
			exact
			path="/manual-reservations/offline-reservations/add"
			component={AddOfflineReservation}
		/>
		<BackOfficeRoute exact path="/inventory-voucher" component={Voucher} />
		{/* SBS Group Reservations */}
		<BackOfficeRoute
			// exact
			path="/manual-reservations/sbs-reservations/:id"
			component={SBSReservationView}
		/>
		{/* Sudan Reservations */}
		<BackOfficeRoute
			exact
			path="/manual-reservations/sudan-reservations"
			component={ReservationsList}
		/>
		<BackOfficeRoute
			// exact
			path="/manual-reservations/sudan-reservations/:id"
			component={SudanReservationView}
		/>
		{/* Saudi Reservations */}
		<BackOfficeRoute
			exact
			path="/manual-reservations/Saudi-reservations"
			component={SaudiReservationsList}
		/>
		<BackOfficeRoute
			// exact
			path="/manual-reservations/Saudi-reservations/:id"
			component={SaudiReservationView}
		/>
		<BackOfficeRoute
			// exact
			path="/Offline-reservations/sbs-reservations/:id"
			component={SBSReservationView}
		/>
		{/* B2C */}
		<BackOfficeRoute
			path="/all-transactions"
			component={AllTransactions}
			exact
		/>
		<BackOfficeRoute
			path="/b2c-booking-details/:reservationId"
			component={B2CBookingDetails}
			exact
		/>
		<BackOfficeRoute
			path="/b2c-user-booking-details/:reservationId"
			component={B2CBookingDetails}
			exact
		/>
		<BackOfficeRoute path="/all-bookings" component={AllBookings} exact />
		<BackOfficeRoute path="/b2c-users" component={B2cUsers} exact />
		<BackOfficeRoute
			path="/b2c-users/activity-log/:id"
			component={UserActivityLogs}
			exact
		/>
		<BackOfficeRoute path="/b2c-users/:id" component={ViewUser} />
		<BackOfficeRoute path="/b2c-Commission" component={CommissionB2c} exact />
		<BackOfficeRoute path="/b2c-packages" component={PackagesBooking} exact />
		<BackOfficeRoute path="/b2c-redirect/:id" component={RedirectCrm} exact />
		<BackOfficeRoute
			path="/b2c-change/:id"
			component={ChangePackageCrm}
			exact
		/>
		<BackOfficeRoute path="/invoices-report" component={InvoicesReport} exact />
		<BackOfficeRoute
			path="/b2c-viewpackages/:id"
			component={ViewPackages}
			exact
		/>
		{/* Start Airline Route */}
		<BackOfficeRoute
			exact
			path="/airline-reservations"
			component={AirlineReservationsList}
		/>
		<BackOfficeRoute
			exact
			path="/airline-reservations/:id"
			component={ViewAirlineReservation}
		/>
		<BackOfficeRoute
			exact
			path="/airline-requests"
			component={AirlineRequests}
		/>
		{/* End Airline Route */}
		{/* Tourism  Companies */}
		ViewPackages
		<BackOfficeRoute
			path="/tourism-company"
			component={TourismCompanies}
			exact
		/>
		<BackOfficeRoute
			path="/tourism-company/add-company"
			component={AddCompany}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/profile/:company_id"
			component={Profile}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/employees/:company_id"
			component={Employees}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:status/employee/:company_id"
			component={AddEmployee}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:status/employee"
			component={AddEmployee}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:status/group/:company_id"
			component={AddGroupTourism}
			exact
		/>
		{/* <CompaniesLayoutRoute
      path="/tourism-company/:status/group"
      component={AddGroupTourism}
      exact
    />
 */}
		<CompaniesLayoutRoute
			path="/tourism-company/groups/:company_id"
			component={GroupsTourism}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/branches/:company_id"
			component={Branches}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/wallet"
			component={MultiWallet}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/reservations/:company_id"
			component={Reservations}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/reservations/:company_id/reservation/:reservation_id"
			component={ViewReservations}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/wallet/transactions/:wallet_id"
			component={ListWalletTransactions}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/subscription-plan"
			component={SubscriptionPlanList}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/subscription-plan/add"
			component={AddEditSubscriptionPlan}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/subscription-plan/edit/:plan_id"
			component={AddEditSubscriptionPlan}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/subscription-plan/view/:plan_id"
			component={ViewPlan}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/:company_id/subscription-plan/commission/:plan_id"
			component={CommissionSubscriptionPlan}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/:type/add/:company_id"
			component={CreateCompanyRequest}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/safa-pay/:company_id"
			component={SaddRequest}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/request-view/:id"
			component={RequestVeiw}
			exact
		/>
		<CompaniesLayoutRoute
			path="/wallet/deals-view/:id"
			component={DealsVeiw}
			exact
		/>
		<CompaniesLayoutRoute
			path="/tourism-company/orders/:company_id/:status"
			component={Orders}
		/>
		<BackOfficeRoute
			path="/company-management"
			component={CompanyManagement}
			exact
		/>
		<BackOfficeRoute
			path="/companies/edit/:company_id"
			component={EditCompany}
		/>
		<BackOfficeRoute path="/contract-mangment" component={ContractMangment} />
		<BackOfficeRoute
			path="/company-management?page=:page"
			component={CompanyManagement}
		/>
		{/* 	<BackOfficeRoute
			path="/contract-request"
			component={ContractRequest}
			exact
		/>
		<BackOfficeRoute
			path="/contract-request?page=:page"
			component={ContractRequest}
		/> */}
		<ContractRouter path="/contract-request/:id" component={ContractDetails} />
		{/* <ContractRouter path="/contract-list" component={ContractList} exact />
		<ContractRouter
			path="/contract-list?page=:page"
			component={ContractRequest}
		/> */}
		<ContractRouter path="/contract-list/:id" component={ContractListDetails} />
		<ContractRouter
			path="/contract-list0/edit/:id"
			exact
			component={EditDetails}
		/>
		{/* sharing  */}
		<BackOfficeRoute path="/sharing-request" component={SharingRequest} exact />
		<BackOfficeRoute
			path="/sharing-request?page=:page"
			component={SharingRequest}
		/>
		<BackOfficeRoute path="/sharing-request/:id" component={SharingDetails} />
		<BackOfficeRoute path="/sharing-list" component={SharingList} exact />
		<BackOfficeRoute
			path="/sharing-list?page=:page"
			component={SharingRequest}
		/>
		<BackOfficeRoute path="/sharing-list/:id" component={SharingListDetails} />
		<BackOfficeRoute
			path="/travel-management"
			component={TravelManagement}
			exact
		/>
		<BackOfficeRoute path="/travel-management/:id" component={TravelDetails} />
		{/* Commissions Module */}
		<PlatformRoute
			path="/platform-commissions-list"
			component={PlatformCommissionList}
			exact
		/>
		<PlatformRoute path="/add-platform" component={AddEditPlatform} exact />
		<PlatformRoute path="/edit-platform/:id" component={AddEditPlatform} />
		<PlatformRoute path="/platform-details/:id" component={PlatformDetails} />
		<PropertyRoute
			path="/hotel-commissions-list"
			component={HotelCommissionsList}
			exact
		/>
		<HotelProviderRouter path="/add-hotel" component={AddEditHotel} exact />
		<HotelProviderRouter path="/edit-hotel/:id" component={AddEditHotel} />
		<HotelProviderRouter path="/hotel-details/:id" component={HotelDetails} />
		{/* ---------------------------------------------------------------------------------------------------------- */}
		<BackOfficeRoute path="/calendar/:id" component={Calendar} />
		{/* team Mangment */}
		{/*   <BackOfficeRoute exact path="/management-team" component={ManagementTeam} /> */}
		<BackOfficeRoute exact path="/management-team/users" component={Users} />
		<BackOfficeRoute
			exact
			path="/management-team/:type/user"
			component={AddUser}
		/>
		<BackOfficeRoute
			exact
			path="/management-team/:type/user/:id"
			component={AddUser}
		/>
		<BackOfficeRoute exact path="/management-team/groups" component={Groups} />
		<BackOfficeRoute
			exact
			path="/management-team/:type/group"
			component={AddGroup}
		/>
		<BackOfficeRoute
			path="/Ground-configuration"
			component={GroundConfiguration}
			exact
		/>
		<BackOfficeRoute
			path="/country-configuration"
			component={CountryConfiguration}
			exact
		/>
		<BackOfficeRoute
			path="/Transportation-configuration"
			component={TransportationConfiguration}
			exact
		/>
		<BackOfficeRoute
			exact
			path="/management-team/:type/group/:id"
			component={AddGroup}
		/>
		{/* <BackOfficeRoute path="/notification" component={Notification} /> */}
		<BackOfficeRoute path="/exchange-rate" component={ExchangeRate} exact />
		<BackOfficeRoute path="/activityLogs" component={ActivityLogs} exact />
		<BackOfficeRoute path="/vendor" component={Vendor} exact />
		<BackOfficeRoute path="/edit-vendor/:id" component={EditVendor} exact />
		<BackOfficeRoute
			path="/vendor/:id/configurations/:brn"
			component={HotelsService}
			// exact
		/>
		<BackOfficeRoute
			path="/manage-vendor/:id/view/transfer/:brn"
			component={VendorTransferView}
			// exact
		/>
		<BackOfficeRoute
			path="/manage-vendor/:id/view/tours/:brn"
			component={VendorToursView}
			// exact
		/>
		<BackOfficeRoute
			path="/manage-vendor/:id/view/reservation/:brn"
			component={ReservationView}
			// exact
		/>
		<BackOfficeRoute path="/manage-vendor/:id" component={ManageVendor} />
		<BackOfficeRoute
			path="/manage-vendor/:id/wallet/transactions/:wallet_id"
			component={VendorTransactionsList}
			exact
		/>
		<BackOfficeRoute
			exact
			path="/vendor/:id/view/flight/reservation/:reservation_id"
			component={ViewVendorAirlineReservation}
		/>
		{/* commercial-distribution */}
		<BackOfficeRoute
			path="/commercial-distribution"
			component={CommercialDistribution}
			exact
		/>
		<BackOfficeRoute
			path="/add-commercial-distribution"
			component={ManageCommercialDistribution}
			exact
		/>
		<BackOfficeRoute
			path="/commercial-distribution/:id/configurations/:brn"
			component={CommercialDistributionServiceProvider}
			exact
		/>
		<BackOfficeRoute
			path="/manage-commercial-distribution/:id/view/hotel/:brn"
			component={CommercialDistHotelReservationView}
		/>
		<BackOfficeRoute
			path="/manage-commercial-distribution/:id/view/transfer/:reservation_id"
			component={CommercialDisTransferReservationView}
		/>
		<BackOfficeRoute
			path="/manage-commercial-distribution/:id/view/tour/:reservation_id"
			component={CommercialDistToursReservationView}
		/>
		<BackOfficeRoute
			path="/manage-commercial-distribution/:id/view/airline/:reservation_id"
			component={CommercialDistAirLineReservationView}
		/>
		<BackOfficeRoute
			path="/manage-commercial-distribution/:id"
			component={ManageCommercialDistribution}
		/>
		<BackOfficeRoute
			path="/manage-commercial-distribution/:id/wallet"
			component={CommercialDistributionWallet}
		/>
		<BackOfficeRoute path="/change-password" component={ChangePassword} />
		<BackOfficeRoute
			path="/marketing-offers"
			component={MarketingOffers}
			exact
		/>
		<BackOfficeRoute
			path="/marketing-offers-list"
			component={MarketingOffersList}
		/>
		<BackOfficeRoute
			path="/marketing-offers/:id"
			component={ViewMarketingOffer}
			exact
		/>
		{/*************Manual Suppliers*************/}
		<BackOfficeRoute
			path="/manual-suppliers"
			component={ManualSuppliers}
			exact
		/>
		<PropertyRoute
			path="/manual-suppliers?page=:page"
			component={ManualSuppliers}
			exact
		/>
		<BackOfficeRoute
			path="/manual-suppliers/:type"
			component={AddSupplier}
			exact
		/>
		<BackOfficeRoute
			path="/manual-suppliers/:name/:id/reservation"
			component={ListReservation}
			exact
		/>
		<BackOfficeRoute
			path="/manual-suppliers/view/payment/:id"
			component={Payment}
			exact
		/>
		<BackOfficeRoute path="/popup-ads" component={PopupAdsCountries} exact />
		<BackOfficeRoute path="/view/popup-ads/:id" component={PopupAds} exact />
		<BackOfficeRoute
			path="/popup-ads/:id/:status"
			component={AddEditPopup}
			exact
		/>
		<BackOfficeRoute
			path="/popup-ads/:id/:status/:id_ads"
			component={AddEditPopup}
			exact
		/>
		<BackOfficeRoute path="/promo" component={Promo} exact />
		<BackOfficeRoute path="/promo/:status" component={AddEditPromoCity} exact />
		<BackOfficeRoute
			path="/promo/:status/:id"
			component={AddEditPromoCity}
			exact
		/>
		<BackOfficeRoute
			path="/Financial-offlineSuppliers"
			component={FinancialOffline}
			exact
		/>
		<BackOfficeRoute
			path="/Financial-platforms"
			component={FinancialPlatforms}
			exact
		/>
		<BackOfficeRoute
			path="/online-transactions"
			component={OnlineTranactions}
			exact
		/>
		<BackOfficeRoute path="/company-wallet" component={AllWallet} exact />
		<BackOfficeRoute
			path="/wallet-Tarnsactions/:id"
			component={TransactionWallet}
			exact
		/>
		<BackOfficeRoute path="/attractions" component={Attractions} exact />
		<BackOfficeRoute
			path="/attractions/view/:id"
			component={ViewAttraction}
			exact
		/>
		<BackOfficeRoute
			path="/attractions/:status"
			component={AddEditAttraction}
			exact
		/>
		<BackOfficeRoute
			path="/attractions/calender/:id"
			component={AttractoinCalender}
			exact
		/>
		<BackOfficeRoute
			path="/attractions/:status/:id"
			component={AddEditAttraction}
			exact
		/>
		<BackOfficeRoute
			path="/subscription-plan"
			component={SubscriptionPlan}
			exact
		/>
		<BackOfficeRoute
			path="/subscription-package-plan"
			component={SubscriptionPlan}
			exact
		/>
		<BackOfficeRoute
			path="/subscription-package-plan/add"
			component={AddEditSubscriptionPackagePlan}
			exact
		/>
		<BackOfficeRoute
			path="/subscription-package-plan/edit/:plan_id"
			component={AddEditSubscriptionPackagePlan}
			exact
		/>
		<BackOfficeRoute
			path="/tourism-company/:company_id/subscription-package/edit/:plan_id"
			component={AddEditSubscriptionPackagePlan}
			exact
		/>
		<BackOfficeRoute path="/package" component={Package} exact />
		<BackOfficeRoute path="/package/:id" component={PackageView} exact />
		{/* 
    <BackOfficeRoute exact path="/team-management" component={TeamManagement} />
		<BackOfficeRoute path="/team-management/add-group" component={AddGroups} />
		<BackOfficeRoute path="/team-management/add-user" component={AddUser} />
		<BackOfficeRoute
			path="/team-management/change-password"
			component={ChangePassword}
		/> */}
		{/* <Route path="/modals" component={TeamManagementUI} /> */}
		{/* <PrivateRoute exact path="/property/basic" component={BasicInfo} /> */}
		{/* <PrivateRoute path="/progress-circular" component={ProgressCircular} /> */}
		{/* <PrivateRoute path="/statistics" component={index} /> */}
		{/* General Routes */}
		<Route path="*" component={Error404} />
	</Switch>
);

export default Routes;
