import { useState } from "react";
import { store } from "react-notifications-component";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
	airlineHandleVoidPending,
	airlineHandleWaiting,
} from "services/airlines";
import Locale from "translations";
import RejectRequestModal from "./Modals//RejectRequest";
import ConfirmPendingRequestModal from "./Modals/ConfirmPendingRequest";
import { RequestContactInfo } from "./RefundRequest";
import RequestItemHeader from "./RequestItemHeader";

export default function VoidRequest({ request, fetchData, isView = false }) {
	const { backOffice, translate_companies } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState({
		status: false,
		type: "accept",
	});
	const [rejectModal, setRejectModal] = useState(false);
	const [confirmPendingModal, setConfirmPendingModal] = useState(false);
	const [rejectReason, setRejectReason] = useState("");

	function toggleCollapse() {
		setIsOpen(!isOpen);
	}
	function toggleConfirmModal(xType = "accept") {
		setConfirmModalOpen({
			...confirmModalOpen,
			status: !confirmModalOpen.status,
			type: xType,
		});
	}
	function toggleRejectModal() {
		setRejectModal(!rejectModal);
	}
	function toggleConfirmPendingModal() {
		setConfirmPendingModal(!confirmPendingModal);
	}
	async function handleRejectRequest(e) {
		let data = {
			request_id: request.id,
			action: "reject",
			reject_reason: e.reject_reason,
		};
		const res = await airlineHandleVoidPending(data);
		if (res.status === 200 || res.status === 201) {
			toggleRejectModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	const handleAcceptRequest = async (e) => {
		let data = {
			request_id: request.id,
			action: "accept",
			amount: e.amount,
		};
		const res = await airlineHandleVoidPending(data);
		if (res.status === 200 || res.status === 201) {
			toggleConfirmPendingModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const handleAcceptWaiting = async (status) => {
		let data;
		if (status === "reject") {
			data = {
				request_id: request.id,
				action: status,
				reject_reason: rejectReason,
			};
		} else {
			data = {
				request_id: request.id,
				action: status,
			};
		}
		const res = await airlineHandleWaiting("void", data);
		if (res.status === 200 || res.status === 201) {
			toggleConfirmModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	// "pending" | "rejected" | "pending approval" | "under review" | "confirmed";

	const status = request?.status;

	return (
		<div className="my-3">
			<RequestItemHeader
				isOpen={isOpen}
				toggleCollapse={toggleCollapse}
				requestTypeText={"Void Request"}
				status={status}
				details={request}
				isView={isView}
			/>

			<Collapse isOpen={isOpen}>
				<div className="flight-requests-details">
					<div className="row m-0">
						<div className="col-md-6 col-12">
							{/* passengers data */}
							<div className="d-flex flex-column">
								<h2 className="text-capitalize blue h6">
									{backOffice.passengers}
								</h2>
								{request?.passengers?.map((passenger) => {
									return (
										<div
											className="flight-requests-refund-passenger"
											key={`${passenger?.id}-${passenger?.flight_request_id}`}
										>
											<div className="col-4">
												<i className="far fa-user-circle"></i>
												<span className="mx-1">
													{passenger?.passenger_info?.full_name}
												</span>
											</div>
											<span className="col-3">
												{passenger?.passenger_info?.type}
											</span>
											<span className="col-5">
												Ticket No. {passenger?.passenger_info?.ticketNumber}
											</span>
										</div>
									);
								})}
							</div>
						</div>

						<div className="col-md-6 col-12">
							{/* contact info */}
							<RequestContactInfo requestDetails={request} />

							{/* actions and status */}
							{status === "rejected" && request?.reject_reason && (
								<div className="flight-requests-danger-box py-1 px-2 mt-3">
									<h3 className="text-capitalize my-2">
										{backOffice.refundRejectReason}
									</h3>
									<p>{request?.reject_reason}</p>
								</div>
							)}
							{status === "pending" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<div className="flight-requests-refund-actions mt-2">
										<button
											className="reject-btn mx-3"
											onClick={toggleRejectModal}
										>
											{translate_companies.reject}
										</button>

										<button
											className="accept-btn"
											onClick={toggleConfirmPendingModal}
										>
											{backOffice.confirm}
										</button>
									</div>
								</div>
							)}

							{status === "waiting_approval" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<div className="flight-requests-refund-actions mt-2">
										<button
											className="reject-btn mx-3"
											//onClick={() => toggleConfirmModal("reject")}
											onClick={toggleRejectModal}
										>
											{translate_companies.reject}
										</button>

										<button
											className="accept-btn"
											onClick={() => toggleConfirmModal("accept")}
										>
											{backOffice.confirm}
										</button>
									</div>
								</div>
							)}

							{/* wait approval==under review */}

							{status === "under review" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2 blue h6">
										{backOffice.refundAmount}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{backOffice.amount}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>

									<div className="flight-requests-refund-actions mt-2">
										<button
											className="reject-btn mx-3"
											onClick={handleRejectRequest}
										>
											{translate_companies.reject}
										</button>
										<button className="accept-btn" onClick={toggleConfirmModal}>
											{backOffice.confirm}
										</button>
									</div>
								</div>
							)}

							{(status === "pending_approval" || status === "confirmed") && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2 blue h6">
										{backOffice.refundAmount}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{backOffice.amount}</span>
										<span className="text-danger">
											{request?.amount} {request?.currency}
										</span>
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</Collapse>

			{rejectModal && (
				<RejectRequestModal
					isOpen={rejectModal}
					toggle={toggleRejectModal}
					submitReject={handleRejectRequest}
				/>
			)}
			{confirmPendingModal && (
				<ConfirmPendingRequestModal
					isOpen={confirmPendingModal}
					toggle={toggleConfirmPendingModal}
					submitAmount={handleAcceptRequest}
				/>
			)}

			{/* confirm request modal */}
			{confirmModalOpen.status && (
				<Modal isOpen={confirmModalOpen.status} centered={true}>
					<ModalHeader toggle={toggleConfirmModal}>
						<span>
							{confirmModalOpen.type === "accept"
								? backOffice.confirmRequest
								: "Reject Request"}
						</span>
					</ModalHeader>
					<ModalBody>
						<div className="p-3 text-center">
							{confirmModalOpen.type === "accept" ? (
								<i
									className="fas fa-question-circle"
									style={{ fontSize: "3.6rem", color: "#F0E5D3" }}
								/>
							) : (
								<textarea
									placeholder={`${backOffice.reasonForRejection}...`}
									name="reject-reason"
									id="reject-reason"
									rows="5"
									className="w-100 control-field__input h-auto"
									value={rejectReason}
									onChange={(e) => setRejectReason(e.target.value)}
								/>
							)}

							{confirmModalOpen.type === "accept" && (
								<p className="py-4 h5 font-weight-bold">
									{backOffice.youSureConfirmRequest}
								</p>
							)}
							<button
								className={`flight-checkout-btn w-100 font-weight-bold border-0 rounded py-2 ${
									confirmModalOpen.type === "accept"
										? "accept-btn"
										: "reject-btn"
								}`}
								disabled={
									confirmModalOpen.type !== "accept" &&
									rejectReason.length === 0
								}
								onClick={() => handleAcceptWaiting(confirmModalOpen.type)}
							>
								{confirmModalOpen.type === "accept"
									? backOffice.confirm
									: backOffice.reject}
							</button>
						</div>
					</ModalBody>
				</Modal>
			)}
		</div>
	);
}
