import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	NavLink,
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { SaveSubscripionConfigurations } from "services/subscriptionPlan";
import ModuleList from "./ModuleList";
import SubscriptionPackagePlan from "./PackagePlan";

export default function SubscriptionPlan() {
	const { allCurrencies } = useGlobalState();
	const [subscriptionState, setSubscriptionState] = useState();
	const [currenciesOptions, setCurrenciesOptions] = useState([]);
	const [selectedCurrency, setSelectedCurrency] = useState();
	const [selectedPlan, setSelectedPlan] = useState([]);
	const [checkAllModules, setCheckAllModules] = useState();
	const location = useLocation();
	const tab = location.pathname.slice(1);
	const [isActiveTab, setIsActiveTab] = useState(tab);
	const history = useHistory();
	useEffect(() => {
		setIsActiveTab(location.pathname.slice(1));
	}, [isActiveTab, location]);

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrenciesOptions(format);
		}
		currenciesLookup();
	}, [allCurrencies]);

	// useEffect(() => {
	// 	async function viewConfigurations() {
	// 		const res = await FetchSubscripionConfigurations();
	// 		setSubscriptionState(res?.data?.data);
	// 		setSelectedCurrency({
	// 			value: res?.data?.data.currency,
	// 			label: res?.data?.data.currency,
	// 		});
	// 	}
	// 	viewConfigurations();
	// }, []);
	// {
	// 	/*** All Subscription Function ***/
	// }

	const handleCheck = (itemId, flag) => {
		if (flag) {
			setSelectedPlan([...selectedPlan, itemId]);
		} else {
			setSelectedPlan([...selectedPlan.filter((id) => id != itemId)]);
		}
	};

	const handleCheckAll = (collectionPlan, flag, id) => {
		let clone = subscriptionState;
		let selectedSubItem;
		let allModules = clone.modules;
		let selectedItem = allModules.find((item) => item.id === id);
		selectedSubItem =
			selectedItem.subModules?.length > 0 ? selectedItem.subModules : [];

		if (flag) {
			setSelectedPlan([...new Set([...selectedPlan, ...collectionPlan])]);

			selectedSubItem =
				selectedSubItem?.length > 0 &&
				selectedSubItem.map((subItem) => ({ ...subItem, status: "active" }));
			selectedItem = { ...selectedItem, subModules: selectedSubItem };
		} else {
			setSelectedPlan(
				selectedPlan.filter((per) => !collectionPlan.includes(per))
			);

			selectedSubItem =
				selectedSubItem?.length > 0 &&
				selectedSubItem.map((subItem) => ({
					...subItem,
					status: "inactive",
				}));
		}
		setSubscriptionState({
			...clone,
		});
	};
	const handleCheckAllModules = (flag) => {
		const clone = subscriptionState;
		let cloneModules;
		if (flag) {
			setCheckAllModules(true);
			cloneModules = clone?.modules?.map((module) => ({
				...module,
				status: "active",
				subModules:
					module.subModules.length > 0
						? module.subModules.map((subItem) => ({
								...subItem,
								status: "active",
						  }))
						: [],
			}));
		} else {
			setCheckAllModules(false);
			cloneModules = clone?.modules?.map((module) => ({
				...module,
				status: "inactive",
				subModules:
					module.subModules.length > 0
						? module.subModules.map((subItem) => ({
								...subItem,
								status: "inactive",
						  }))
						: [],
			}));
		}
		setSubscriptionState({ ...clone, modules: cloneModules });
	};

	const handlePaidType = (
		typeInput,
		id,
		value,
		issub = false,
		idSubModule = null,
		SubModuleArr = [],
		parentModuleId = null
	) => {
		let clone = subscriptionState;
		let selectedItem;
		if (!issub) {
			selectedItem = clone.modules.find((item) => item.id === id);
		} else {
			selectedItem = clone.modules.find((item) => item.id === id);
			selectedItem = selectedItem.subModules.find(
				(item) => item.id === idSubModule
			);
		}
		if (typeInput === "type") {
			selectedItem.type = value;
			if (SubModuleArr.length > 0) {
				const sumSubModule = SubModuleArr?.filter(
					(module) => module.status === "active" && module.type === "paid"
				)?.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue?.price;
				}, 0);
				if (parentModuleId !== null) {
					selectedItem = clone.modules.find(
						(item) => item.id === parentModuleId
					);
					selectedItem.price = +sumSubModule;
				} else {
					selectedItem = clone.modules.find((item) => item.id === id);
					selectedItem.price = +sumSubModule;
				}
			}
		} else if (typeInput === "price") {
			selectedItem.price = +value;

			if (SubModuleArr.length > 0) {
				const sumSubModule = SubModuleArr?.filter(
					(module) => module.status === "active" && module.type === "paid"
				)?.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue?.price;
				}, 0);
				selectedItem = clone.modules.find((item) => item.id === parentModuleId);

				selectedItem.price = +sumSubModule;
			}
		} else {
			selectedItem.status = value;
			if (SubModuleArr.length > 0) {
				const sumSubModule = SubModuleArr?.filter(
					(module) => module.status === "active" && module.type === "paid"
				)?.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue?.price;
				}, 0);
				if (typeof parentModuleId == "number") {
					//parentModuleId !== null
					selectedItem = clone.modules.find(
						(item) => item.id === parentModuleId
					);
					selectedItem.price = +sumSubModule;

					let SubModuleIsActive = SubModuleArr.some(
						(item) => item.status === "active"
					);
					if (SubModuleIsActive) {
						selectedItem.status = "active";
					} else {
						selectedItem.status = "inactive";
					}
				}
				if (parentModuleId === "parent") {
					selectedItem.subModules = SubModuleArr.map((item) => ({
						...item,
						status: value,
					}));
				}
			}
		}
		TotalPrice();
		setSubscriptionState({ ...clone });
	};
	const handleCurrency = ({ value, label }) => {
		let clone = subscriptionState;
		clone.currency = label;
		setSubscriptionState({ ...clone });
	};
	const saveConfiguration = async () => {
		const modules = subscriptionState?.modules.map((module) => ({
			module_id: module.id,
			status: module.status,
			type: module.type,
			price: module.price,
		}));
		const sub_modules = subscriptionState?.modules.map((module) => {
			if (module.subModules?.length > 0) {
				return module.subModules.map((sub_module) => ({
					module_id: sub_module.id,
					status: sub_module.status,
					type: sub_module.type,
					price: sub_module.price,
				}));
			}
		});
		const data = {
			currency: subscriptionState.currency,
			modules: [...modules, ...sub_modules?.flat()],
		};
		const res = await SaveSubscripionConfigurations(data);
		if (res?.status == 200) {
			store.addNotification({
				title: "Info!",
				message: "Set Subscription Plan Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	const [totalPrice, setTotalPrice] = useState(0);
	const TotalPrice = () => {
		let clone = subscriptionState;
		const total_modules = clone?.modules
			?.filter(
				(module) =>
					module.status === "active" &&
					module.type === "paid" &&
					module.subModules?.length === 0
			)
			?.reduce(function (accumulator, currentValue) {
				return accumulator + currentValue?.price;
			}, 0);
		let sub_modules = clone?.modules?.map((item) =>
			item.subModules?.length > 0 ? item.subModules : null
		);
		sub_modules = sub_modules?.filter((item) => item);
		console.log(sub_modules?.flat(), "sub_modules");
		const total_sub_modules = sub_modules
			?.flat()
			?.filter((module) => module.status === "active" && module.type === "paid")
			?.reduce(function (accumulator, currentValue) {
				return accumulator + currentValue?.price;
			}, 0);
		setTotalPrice(total_modules + total_sub_modules);
		// clone.price = total_modules;
		setSubscriptionState({
			...clone,
			price: total_modules + total_sub_modules,
		});
	};
	useEffect(() => {
		TotalPrice();
		//** check all module  */
		const clone = subscriptionState;
		let SubModuleIsActive = clone?.modules?.every(
			(item) => item.status === "active"
		);
		if (SubModuleIsActive) {
			setCheckAllModules(true);
		} else {
			setCheckAllModules(false);
		}
	}, [subscriptionState?.modules]);

	return (
		<div className="subscription-plan">
			<div className="tabs d-flex align-items-center  all-tabs ">
				<NavLink
					exact={true}
					to="/subscription-package-plan"
					activeClassName="is-active"
					className="d-block mx-3 p-3   our-taps "
					onClick={() => {
						setIsActiveTab("subscription-package-plan");
						history.push("/subscription-package-plan");
					}}
				>
					Packages Plan
				</NavLink>
			</div>
			{isActiveTab === "subscription-plan" && (
				<>
					<div className="subscription-header">
						<h4>Available Subscription Plan</h4>
						<div className="currency">
							<p>Select Currency</p>
							<SelectField
								hasLabel={false}
								value={selectedCurrency}
								options={currenciesOptions}
								onChange={(e) => {
									setSelectedCurrency(e);
									handleCurrency(e);
								}}
							/>
						</div>
					</div>

					{/* <ShowForPermission permission="Subscription-Plan-View"> */}
					<ModuleList
						subscriptionData={subscriptionState}
						selectedPlan={selectedPlan}
						setSelectedPlan={setSelectedPlan}
						handleCheck={handleCheck}
						handleCheckAll={handleCheckAll}
						handlePaidType={handlePaidType}
						totalPrice={totalPrice}
						handleCheckAllModules={handleCheckAllModules}
						checkAllModules={checkAllModules}
					/>
					{/* </ShowForPermission> */}
					<div className="action ">
						<ShowForPermission permission="Subscription-Plan-Manage">
							<button
								className="btn btn-primary no-shadow"
								onClick={() => saveConfiguration()}
							>
								Save & Apply
							</button>
						</ShowForPermission>
					</div>
				</>
			)}
			{isActiveTab === "subscription-package-plan" && (
				<SubscriptionPackagePlan />
			)}
		</div>
	);
}
