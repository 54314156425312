import axios from "axios";
import { cleanEmpty } from "./general";
const suppliersURL = process.env.REACT_APP_API_URL + "/api";

export const createNewSupplier = async (data) => {
	return await axios
		.post(`${suppliersURL}/group-reservation/offline-suppliers`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateSupplier = async (id, data) => {
	return await axios
		.put(`${suppliersURL}/group-reservation/offline-suppliers/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewSupplier = async (id) => {
	return await axios
		.get(`${suppliersURL}/group-reservation/offline-suppliers/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchOfflineSuppler = async (params, page = 1) => {
	return await axios
		.get(`${suppliersURL}/group-reservation/offline-suppliers?page=${page}`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

// list reservation supplier
export const fetchreservations = async (id, params = {}, page = 1) => {
	return await axios.get(
		`${suppliersURL}/group-reservation/offline-suppliers/${id}/reservations?page=${page}`,
		{
			params: cleanEmpty(params),
		}
	);
};
/***********Start Wallet api*********** */
export const fetchWalletList = async (id, params) => {
	return await axios
		.get(`${suppliersURL}/group-reservation/offline-suppliers/${id}/payments`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const createPaymentWallet = async (id, data) => {
	return await axios
		.post(
			`${suppliersURL}/group-reservation/offline-suppliers/${id}/payments`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchCurrencyList = async (id, params) => {
	return await axios
		.get(`${suppliersURL}/group-reservation/offline-suppliers/${id}/currencies`)
		.then((res) => res)
		.catch((err) => err.response);
};
/***********End Wallet api*********** */
export const exportToExcelManualSupplier = async (id, params) => {
	return await axios
		.get(
			`${suppliersURL}/group-reservation/offline-suppliers/${id}/reservations/export`,
			{
				params: cleanEmpty(params),
				responseType: "blob",
			}
		)
		.then((res) => res)
		.catch((err) => err.response);
};
