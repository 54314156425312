import TextField from "components/shared/TextField";
import Locale from "translations";

export default function FilterTransaction({ Filter, setFilter }) {
	const { backOffice } = Locale;
	const lokeuptest = [
		{ id: 0, label: "0", value: 0 },
		{ id: 1, label: "1", value: 1 },
		{ id: 2, label: "2", value: 2 },
	];
	return (
		<>
			<div className="main-filter bg-white-blue py-2 mt-2">
				<div className="row align-items-center  w-100 no-gutter m-0">
					<div className="col-md-3">
						<TextField
							label={backOffice.search}
							placeholder={backOffice.Typetosearch}
							value={Filter.search}
							onChange={(e) => {
								setFilter({
									...Filter,
									search: e.target.value,
								});
							}}
						/>
					</div>
					{/* <div className="col-md-2">
						<DatePickerField
							label={backOffice.date}
							placeholder={"DD/MM/YYYY"}
							onDateChange={(e) => {
								setFilter({
									...Filter,
									date: moment(e).format("YYYY-MM-DD"),
								});
							}}
						/>
					</div>
					<div className="col-md-2">
						<SelectField
							label={backOffice.type}
							placeholder={backOffice.select}
							value={Filter.type}
							options={lokeuptest}
							onChange={(e) => {
								setFilter({
									...Filter,
									type: e,
								});
							}}
						/>
					</div>
					<div className="col-md-2">
						<SelectField
							label={backOffice.paymentMethod}
							placeholder={backOffice.select}
							value={Filter.paymentmethod}
							options={lokeuptest}
							onChange={(e) => {
								setFilter({
									...Filter,
									paymentmethod: e,
								});
							}}
						/>
					</div> */}
					{/* <div className="col-md d-flex align-items-center gap-2 ">
						<div className="d-flex align-items-center  text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
}
