import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export async function listPackages(params, queryParams) {
	try {
		const query = new URLSearchParams(queryParams).toString();

		const res = await axios.get(
			`${backOfficeApi}/safa-visa/packages${query ? `?${query}` : ""}`,
			{
				params: cleanEmpty(params),
			}
		);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function createPackage(data) {
	try {
		const res = await axios.post(`${backOfficeApi}/safa-visa/packages`, data);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function fetchSafaKeys(search = "") {
	try {
		const res = await axios.get(
			`${backOfficeApi}/safa-visa/safa-keys?search=${search}`
		);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function exportPackages(params) {
	try {
		const res = await axios.get(`${backOfficeApi}/safa-visa/packages/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		});
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function updatePackage(id, data) {
	try {
		const res = await axios.post(
			`${backOfficeApi}/safa-visa/packages/${id}`,
			data
		);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function fetchPaymentMethods() {
	try {
		const res = await axios.get(`${backOfficeApi}/safa-visa/payment-method`);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function createPayment(data) {
	try {
		const res = await axios.post(
			`${backOfficeApi}/safa-visa/packages/payment`,
			data
		);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function fetchPackage(id) {
	try {
		const res = await axios.get(`${backOfficeApi}/safa-visa/packages/${id}`);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function fetchPaymentList(params) {
	try {
		const res = await axios.get(`${backOfficeApi}/safa-visa/packages/payment`, {
			params: cleanEmpty(params),
		});
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function deletePackage(id) {
	try {
		const res = await axios.delete(`${backOfficeApi}/safa-visa/packages/${id}`);
		return res;
	} catch (err) {
		return err.response;
	}
}

export async function updatePayment(id, data) {
	try {
		const res = await axios.post(
			`${backOfficeApi}/safa-visa/packages/payment/${id}`,
			data
		);

		return res;
	} catch (err) {
		return err.response;
	}
}
