import { useEffect, useState } from "react";
import { TabPane } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getPackageDetails } from "services/b2c";
import ActionsBar from "./ActionsBar";
import CallLogs from "./CallLogs";
import History from "./History";
import Listpax from "./ListPax";
import PackageItinerary from "./PackageItinerary";
import StatusBar from "./StatusBar";
import SummaryTab from "./SummaryTab";

function ViewPackages() {
	const [activeTap, setActiveTap] = useState(1);
	const [activeTapSide, setActiveTapSide] = useState(1);
	const [packageDetails, setPackageDetails] = useState(null);
	const { id } = useParams();
	const [Reload, setReload] = useState(false);
	// get single package data
	async function getPackageData() {
		const viewPackageRes = await getPackageDetails(id);
		if (viewPackageRes?.status >= 200 && viewPackageRes?.status < 300) {
			setPackageDetails(viewPackageRes?.data?.data);
		}
	}
	// get package details in inital call
	useEffect(() => {
		getPackageData();
	}, [Reload]);

	return (
		<>
			{packageDetails ? (
				<div className="">
					<ActionsBar
						reservationNum={packageDetails?.reservation_num}
						priority={packageDetails?.priority}
						getPackageData={getPackageData}
						setReload={setReload}
						reload={Reload}
					/>
					<div className="row">
						<div className="col-md-8 col-12">
							<StatusBar
								type={"status"}
								title={"Deal Status"}
								items={packageDetails?.deal_status}
							/>
							<StatusBar
								type={"deprture"}
								title={"After Departure"}
								items={packageDetails?.after_departure}
							/>
							{/* <StatusBar type={"closing"} /> */}
							<div>
								<div className="requestTaps mt-4">
									<TabPane
										className={`tap ${activeTap === 1 ? `active` : "pointer"}`}
										onClick={() => {
											setActiveTap(1);
										}}
										tabId={1}
									>
										Summar Details
									</TabPane>
									<TabPane
										className={`tap ${activeTap === 2 ? `active` : "pointer"}`}
										onClick={() => {
											setActiveTap(2);
										}}
										tabId={2}
									>
										Pax Details
									</TabPane>
								</div>
								{activeTap === 1 && (
									<SummaryTab packageDetails={packageDetails} />
								)}
								{activeTap === 2 && (
									<Listpax packagePaxList={packageDetails?.travelers} />
								)}
							</div>
							{/* PackageItinerary */}
							<PackageItinerary
								productItinerary={packageDetails?.product?.itinerary}
							/>
						</div>
						<div className="col-md-4">
							<div className="side-col">
								<div className="requestTaps">
									<TabPane
										className={`tap ${
											activeTapSide === 1 ? `active` : "pointer"
										}`}
										onClick={() => {
											setActiveTapSide(1);
										}}
										tabId={1}
									>
										CallsLog
									</TabPane>
									<TabPane
										className={`tap ${
											activeTapSide === 2 ? `active` : "pointer"
										}`}
										onClick={() => {
											setActiveTapSide(2);
										}}
										tabId={2}
									>
										History
									</TabPane>
								</div>
								{activeTapSide === 1 && (
									<CallLogs
										packageDetails={packageDetails}
										setReload={setReload}
									/>
								)}
								{activeTapSide === 2 && (
									<History packageDetails={packageDetails} Reload={Reload} />
								)}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}

export default ViewPackages;
