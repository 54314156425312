import { Modal } from "reactstrap";

export default function PriceBreakdownModal({ isOpen, toggle, flightData }) {
	const adultNumber = flightData?.passengers?.filter(
		(item, index) => item?.psgType == "ADT"
	)?.length;
	const childNumber = flightData?.passengers?.filter(
		(item, index) => item?.psgType == "CHD"
	)?.length;

	// adults
	const fareForAllAdults =
		+flightData?.price_details?.adtFare * +adultNumber || 0;
	const taxForAllAdults =
		+flightData?.price_details?.adtTax * +adultNumber || 0;
	const adultsTotalPrice = fareForAllAdults + taxForAllAdults || 0;
	// children
	const fareForAllChildren =
		+flightData?.price_details?.chdFare * +childNumber || 0;
	const taxForAllChildren =
		+flightData?.price_details?.chdTax * +childNumber || 0;
	const childrenTotalPrice = fareForAllChildren + taxForAllChildren || 0;
	//+ (+flightData?.price_details?.tktFee)

	return (
		<Modal isOpen={isOpen} toggle={toggle} size={"lg"} centered={true}>
			<div className="d-flex align-items-center justify-content-between pt-2 px-4  w-100">
				<h3>Price Breakdown</h3>
				<a href="##" onClick={toggle} className="btn-x">
					<i class="fas fa-times"></i>
				</a>
			</div>
			<table
				striped
				className="table-update p-2 bg-white m-0 table table-striped"
			>
				<thead>
					<tr>
						<th>Passenger Type</th>
						<th>Fare</th>
						<th>Taxes</th>
						<th>Ticketing Fee</th>
						<th>Passanger</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Adult</td>
						<td>{flightData?.price_details?.adtFare?.toFixed(2)}</td>
						<td>{flightData?.price_details?.adtTax?.toFixed(2)}</td>
						<td>{/**{flightData?.price_details?.tktFee?.toFixed(2)} */}</td>
						<td>x{adultNumber}</td>
						<td>
							{" "}
							{adultsTotalPrice.toFixed(2)} {flightData?.currency}
						</td>
					</tr>
					{childNumber > 0 ? (
						<tr>
							<td>Child</td>
							<td>{flightData?.price_details?.chdFare?.toFixed(2)}</td>
							<td>{flightData?.price_details?.chdTax?.toFixed(2)}</td>
							<td>{/**{flightData?.tktFee?.toFixed(2)} */}</td>
							<td>x{childNumber}</td>
							<td>
								{" "}
								{childrenTotalPrice.toFixed(2)} {flightData?.currency}
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
			<div className="d-flex justify-content-between align-items-center p-3">
				<p>
					{`Total Passanger`}
					<strong style={{ color: "#0050A6", fontWeight: "bold" }}>
						X{adultNumber + childNumber}
					</strong>
				</p>
				<div className="d-flex flex-column align-items-end">
					<p
						className="total"
						style={{ color: "#0050A6", lineHeight: "0.5", fontSize: "1.2rem" }}
					>
						{flightData?.total_price?.toFixed(2)} {flightData?.currency}
					</p>
					<span style={{ fontSize: "small" }}>Not Including Payment</span>
				</div>
			</div>
		</Modal>
	);
}
