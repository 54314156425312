import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useGlobalState } from "context/global";
import validate from "helpers/validate";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { fetchCitiesSearch } from "services/lookups";
import {
	convertReservationToOnline,
	fetchCompaniesLookups,
	fetchSuppliersHotels,
} from "services/sbsReservations";
import { useDropdownDate } from "../Main/dropdownData";
import { roomsTotals } from "../Main/helper";
import ConvertOnlineModal from "./ConvertOnlineModal";

function Info(props) {
	const { info, infoHandler, errors, setErrors, edit } = props;
	const { locale } = useGlobalState();

	const groupStatusId = info?.status?.id;
	const pendingStatus = groupStatusId === 2;
	const tentativeStatus = groupStatusId === 3 || groupStatusId === 4;
	const reservationStatusCondition = pendingStatus || tentativeStatus;
	const [hotelList, setHotelList] = useState([]);
	const [convertModalIsOpen, setConvertModalIsOpen] = useState(false);
	const [companiesLookups, setCompamiesLookups] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const { nights } = roomsTotals(info);

	const { id } = useParams();
	const history = useHistory();
	const { suppliers, mealPlans, countries } = useDropdownDate({
		model: { name: "info" },
	});
	const isOfflineUrl = window.location.href.includes("Offline");
	console.log("isOfflineUrl", isOfflineUrl);
	useEffect(() => {
		async function fetchLookups() {
			const companiesLookupsResponse = await fetchCompaniesLookups();
			if (companiesLookupsResponse.status === 200) {
				const formatCompaniesLookups =
					companiesLookupsResponse?.data?.data?.map((company) => {
						return { ...company, label: company?.name, value: company?.id };
					});
				setCompamiesLookups(formatCompaniesLookups);
			}
		}
		fetchLookups();
	}, []);

	const fetchSuppliers = async (e) => {
		const id = e.value;
		const hotels = await fetchSuppliersHotels(id);
		const formatedData = hotels.data.data.map(
			(item) => (item = { label: item.hotel_name, value: item.id })
		);
		setHotelList(formatedData);
	};

	const onChangeSupplier = (e) => {
		infoHandler({ name: "supplier_hotel", value: { label: "", value: null } });
		fetchSuppliers(e);
	};
	useEffect(() => {
		if (info.supplier && hotelList.length === 0) {
			fetchSuppliers(info.supplier);
		}
		return () => {};
	}, [info]);

	async function handleConvertToOnline() {
		const data = {
			company_id: selectedCompany?.id,
		};
		const response = await convertReservationToOnline(id, data);
		if (response.status === 200) {
			store.addNotification({
				title: "info!",
				message: response?.data?.message
					? response?.data?.message
					: "Group Request Updated successfully",
				type: "success",
				insert: "top",
				container: "top-left",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setTimeout(() => {
				history.push("/manual-reservations/sbs-reservations");
			}, 1000);
			setConvertModalIsOpen(false);
		}
	}

	const handleDateChange = ({ date, name }) => {
		const dateFrom = name === "date_from";
		const oppositeName = dateFrom ? "date_to" : "date_from";
		const oppositeValue = info?.[oppositeName];

		const currentDate = moment(date);
		const oppositeDate = moment(oppositeValue);
		const nights = dateFrom
			? 1
			: Math.abs(currentDate.diff(oppositeDate, "days"));

		let formatRooms = info.rooms.map((res) => {
			return {
				...res,
				total_selling_price: res.selling_rate * res.room_count * nights,
			};
		});

		const value = {
			rooms: formatRooms,
			...(dateFrom && { date_to: moment(date).add(1, "d") }),
			[name]: date._d,
			nights,
		};

		infoHandler({ value, multiValue: true });
	};

	const handleInfoState = ({ name, value }) => {
		infoHandler({ name, value });
	};
	const handleINightCount = ({ name, value }) => {
		handleInfoState({
			name,
			value,
		});
		let dateTo = "";
		if (value.length === 0) {
			dateTo = info?.["date_to"];
		} else if (nights < value) {
			let addNight = value - nights;
			const checkout = moment(info?.["date_to"])
				.add(addNight, "days")
				.format("YYYY-MM-DD");
			dateTo = checkout;
		} else {
			if (value > 0) {
				let removeNight = nights - value;
				const checkout = moment(info?.["date_to"])
					.subtract(removeNight, "days")
					.format("YYYY-MM-DD");
				dateTo = checkout;
			}
		}
		infoHandler({ name: "date_to", value: dateTo, multiValue: false });
	};

	const handleRoomsError = ({ validateValue }) => {
		setErrors((current) => ({
			...current,
			info: {
				...current.info,
				...validate(validateValue[0], {
					...validateValue[1],
				}),
			},
		}));
	};
	const dateFiled = ({ name, label, isOutsideRange, onChange }) => (
		<DatePickerField
			label={label}
			hasLabel={false}
			date={moment(info[name])}
			onChangeDate={(date) => {
				handleDateChange({ date, name });
				onChange && onChange(date);
			}}
			isOutsideRange={(day) =>
				isOutsideRange && !day.isAfter(isOutsideRange, "day")
			}
		/>
	);

	const infoTextFiled = ({ name, placeholder }) => (
		<TextField
			hasLabel={false}
			label={placeholder}
			type="text"
			placeholder={placeholder}
			id={`room-${name}`}
			name={name}
			value={info[name]}
			onChange={(e) => {
				handleInfoState({
					name,
					value: e.target.value,
				});
				handleRoomsError({
					validateValue: [
						e.target,
						{
							required: true,
						},
					],
				});
			}}
			color={errors?.["info"]?.[name]?.required ? "danger" : ""}
			errors={errors?.["info"]?.[name]}
		/>
	);

	const nightTextFiled = ({ name, placeholder }) => (
		<TextField
			hasLabel={false}
			label={placeholder}
			type="number"
			placeholder={placeholder}
			id={`room-${name}`}
			name={name}
			value={info[name]}
			onChange={(e) => {
				handleINightCount({
					name,
					value: e.target.value,
				});
				handleRoomsError({
					validateValue: [
						e.target,
						{
							required: true,
						},
					],
				});
			}}
			color={errors?.["info"]?.[name]?.required ? "danger" : ""}
			errors={errors?.["info"]?.[name]}
		/>
	);

	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					//name: item.name + " - " + item.country.name[locale],
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});

			setListAuto(result);
		}
	};

	const infoAutoSelect = ({ name, onChange }) => (
		<AutoCompleteField
			hasLabel={false}
			label={name}
			placeholder={info?.[name]?.label}
			value={info?.[name]?.label}
			name={name}
			id={`room-${name}`}
			flag={info?.[name]?.goingTo?.country?.flag}
			listAuto={listAuto}
			setListAuto={setListAuto}
			getListAuto={getListAuto}
			isSearchable={true}
			onChange={(e) => {
				onChange && onChange(e);
				handleInfoState({ name, value: e });
				handleRoomsError({
					validateValue: [
						{ name, value: e.value },
						{
							required: true,
						},
					],
				});
			}}
			onSelectValue={(e) => {
				onChange && onChange(e);
				handleInfoState({ name, value: e });
				handleRoomsError({
					validateValue: [
						{ name, value: e.value },
						{
							required: true,
						},
					],
				});
			}}
			color={errors?.["info"]?.[name]?.required ? "danger" : ""}
			errors={errors?.["info"]?.[name]}
		/>
	);

	const infoSelect = ({
		name,
		onChange,
		options = [
			{ label: "test", value: 1 },
			{ label: "empty", value: "" },
		],
	}) => (
		<SelectField
			hasLabel={false}
			label={name}
			placeholder={info?.[name]?.label}
			value={info?.[name]?.label}
			name={name}
			id={`room-${name}`}
			options={options}
			onChange={(e) => {
				onChange && onChange(e);
				handleInfoState({ name, value: e });
				handleRoomsError({
					validateValue: [
						{ name, value: e.value },
						{
							required: true,
						},
					],
				});
			}}
			onBlur={() =>
				handleRoomsError({
					validateValue: [
						{ name, value: info?.[name] },
						{
							required: true,
						},
					],
				})
			}
			color={
				errors?.["info"]?.[name]?.required && name !== "meal_plan"
					? "danger"
					: ""
			}
			errors={name !== "meal_plan" ? errors?.["info"]?.[name] : ""}
		/>
	);
	const hotelName = edit
		? infoTextFiled({ name: "hotel_name", placeholder: "Hotel Name" })
		: info?.["hotel_name"];

	const night = edit
		? nightTextFiled({ name: "nights", placeholder: "Hotel nights" })
		: nights;

	const destination = edit
		? infoAutoSelect({ name: "destination", placeholder: "Destination" })
		: info?.["destination"]?.label;

	const checkIn = edit
		? dateFiled({
				name: "date_from",
				label: "Check-in",
				isOutsideRange: moment(new Date()).add(0, "d"),
		  })
		: moment(info?.["date_from"]).format("DD/MM/YYYY");

	const checkOut = edit
		? dateFiled({
				name: "date_to",
				label: "Check-out",
				isOutsideRange: moment(info?.["date_from"]),
		  })
		: moment(info?.["date_to"]).format("DD/MM/YYYY");

	const mealPlan = edit
		? infoSelect({ name: "meal_plan", options: mealPlans })
		: info?.["meal_plan"]?.label;

	const residence = edit
		? infoSelect({ name: "residence", options: countries })
		: info?.["residence"]?.label;

	const nationality = edit
		? infoSelect({ name: "nationality", options: countries })
		: info?.["nationality"]?.label;

	const supplier =
		pendingStatus || edit
			? infoSelect({
					name: "supplier",
					options: suppliers,
					onChange: onChangeSupplier,
			  })
			: info?.["supplier"]?.label;

	const SelectHotelName =
		pendingStatus || edit
			? infoSelect({ name: "supplier_hotel", options: hotelList })
			: info?.["supplier_hotel"]?.label;
	return (
		<div className="reserv_details_info">
			<div className="row">
				<DetailsRow name="Reference" value={info?.["id"]} />
				<DetailsRow name="Request Date" value={info?.["created_at"]} />
			</div>

			<div className="row">
				<DetailsRow
					name="Destination"
					value={destination}
					className={edit ? "update_Field" : ""}
				/>
				<DetailsRow
					name="Hotel Name"
					value={hotelName}
					className={edit ? "update_Field" : ""}
				/>
			</div>

			<div className="row">
				<DetailsRow
					name="Check-In"
					value={checkIn}
					className={edit ? "update_Field" : ""}
				/>
				<DetailsRow
					name="Check-Out"
					value={checkOut}
					className={edit ? "update_Field" : ""}
				/>
			</div>
			<div className="row">
				<DetailsRow
					name="Nights"
					value={night}
					className={edit ? "update_Field" : ""}
				/>

				<DetailsRow
					name="Meal Plan"
					value={mealPlan}
					className={edit ? "update_Field" : ""}
				/>
			</div>

			<div className="row">
				<DetailsRow
					name="residence"
					value={residence}
					className={edit ? "update_Field" : ""}
				/>
				<DetailsRow
					name="Nationality"
					value={nationality}
					className={edit ? "update_Field" : ""}
				/>
			</div>

			<div className="row">
				<DetailsRow name="Currency" value={info?.["currency"]} />
			</div>

			<div className="reserv_details_info_middle mt-3 row">
				<DetailsRow
					name="Company Name"
					value={info?.company?.name ? info?.company?.name[locale] : ""}
					setConvertModalIsOpen={setConvertModalIsOpen}
				/>

				<DetailsRow
					name="Contact Person"
					value={info?.user?.full_name[locale]}
				/>
			</div>

			<div className="reserv_details_info_middle row">
				<DetailsRow name="Contact Email" value={info?.user?.business_email} />
				<DetailsRow name="Phone" value={info?.user?.phone} />
			</div>

			{reservationStatusCondition && (
				<Fragment>
					<div className="reserv_details_info_bottom mt-3 row">
						<DetailsRow
							name="Accepted Request"
							value={info?.accepted_request}
						/>
						<DetailsRow name="Accepted Date" value={info?.accepted_at} />
					</div>

					<div className="reserv_details_info_bottom row">
						<DetailsRow
							name="Supplier"
							value={supplier}
							className={reservationStatusCondition ? "update_Field" : ""}
						/>
						<DetailsRow
							name="Hotel Name"
							value={SelectHotelName}
							className={reservationStatusCondition ? "update_Field" : ""}
						/>
					</div>
				</Fragment>
			)}
			{/* convert to online modal */}
			<ConvertOnlineModal
				companiesLookups={companiesLookups}
				convertModalIsOpen={convertModalIsOpen}
				setConvertModalIsOpen={setConvertModalIsOpen}
				selectedCompany={selectedCompany}
				setSelectedCompany={setSelectedCompany}
				handleConvertToOnline={handleConvertToOnline}
			/>
		</div>
	);
}

export default Info;

const DetailsRow = (props) => {
	const { name, value, className, setConvertModalIsOpen } = props;
	const url = window.location.href.split(/[?#]/)[0];

	const OfflineReservations = url.includes("Offline-reservations");

	return (
		<Fragment>
			<div className="reserv_details_info_key col-2">{name}</div>
			<div className={`reserv_details_info_value col-4 ${className}`}>
				{value}

				{name == "Company Name" && OfflineReservations ? (
					<button
						className="btn btn-primary mx-4"
						onClick={(e) => setConvertModalIsOpen(true)}
					>
						Convert To Online
					</button>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};
