const locale = localStorage.getItem("currentLocale") || "en";

export function formatFetchReservationView(info) {
	const {
		rooms,
		destination,
		nationality,
		residence,
		meal_plan,
		supplier_hotel,
		supplier,
		net,
		selling,
		nights,
	} = info;
	let formatRooms = rooms.map((res) => {
		return {
			...res,
			room_view: { label: res.room_view?.name, value: res.room_view?.id },
			room_type: { label: res.room_type?.name, value: res.room_type?.id },
			net_rate: res.net,
			selling_rate: res.selling,
			total_selling_price: res.selling * res.room_count * nights,
		};
	});
	const nationalityFormat = {
		label: nationality.name,
		value: nationality.id,
	};
	const destinationFormat = {
		label: destination?.name,
		value: destination?.id,
	};
	const residenceFormat = {
		label: residence?.name,
		value: residence?.id,
	};
	const mealPlanFormat = { label: meal_plan?.name, value: meal_plan?.id };

	const supplierFormat = { label: supplier?.name, value: supplier?.id };
	const supplierHotelFormat = {
		label: supplier_hotel?.hotel_name,
		value: supplier_hotel?.id,
	};
	return {
		...info,
		destination: destinationFormat,
		net_rate: net,
		selling_rate: selling,
		nationality: nationalityFormat,
		...(residence && { residence: residenceFormat }),
		...(meal_plan && { meal_plan: mealPlanFormat }),
		...(supplier && { supplier: supplierFormat }),
		...(supplier_hotel && { supplier_hotel: supplierHotelFormat }),
		rooms: formatRooms,
	};
}
