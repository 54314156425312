import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Collapse } from "reactstrap";
import { UpdateCalenderBulk, updateCalender } from "services/attraction";
import Locale from "translations";
import ConfigureTimeCalender from "../models/configureTimeCalender";
import AddCustomRate from "./AddCustomRate";
import Cell from "./Cell";
export default function CalendarRoom(props) {
	const { hotelLayout } = Locale;

	const [calendar, setCalendar] = useState({ ratePlan: [{}] });
	const [modal, setModal] = useState({
		isOpen: false,
		type: "edit",
		title: "",
	});

	const { filters, getViewCalendar } = props;
	//Collapse
	const [isOpen, setIsOpen] = useState(true);
	const toggleButton = () => setIsOpen(!isOpen);
	const [RangeDays, setRangeDays] = useState({ days: [] });

	const toggle = (roomId) => {
		setModal({
			isOpen: !modal.isOpen,
			title: hotelLayout.calendar.bulkEdit,
			id: roomId,
		});
	};

	const [customRatemodal, setCustomRatemodal] = useState({
		isOpen: false,
		type: "edit",
		title: "",
	});

	const ratesplans = [{}];

	const ratetoggle = (ratePlanId) => {
		setCustomRatemodal({
			isOpen: !customRatemodal.isOpen,
			title: hotelLayout.calendar.bulkEdit,
			ratePlanId: ratePlanId,
		});
	};

	// cells
	const [selected, setSelected] = React.useState();
	const [started, setStarted] = React.useState("");
	const [handle, setHandle] = React.useState();
	const [handleFirstDay, setHandleFirstDay] = React.useState();
	const [IsOpenTime, setIsOpenTime] = useState(false);
	const toggleTime = () => setIsOpenTime((prev) => !prev);
	const { id } = useParams();
	const [selectedCalenderDayTime, setSelectedCalenderDayTime] = useState({});
	const handleMouseUp = (e) => {
		if (e.type === "mouseup" && selected && started) {
			setStarted(false);
		}
	};

	const handleMouseDown = (e) => {
		if (e.type === "mousedown" && selected && started) {
			setStarted(false);
		}
	};

	useEffect(() => {
		if (typeof document.onselectstart != "undefined") {
			document.onselectstart = new Function("return false");
		} else {
			document.onmousedown = new Function("return false");
			document.onmouseup = new Function("return true");
		}
	}, []);
	const [lookups, setLookups] = useState({
		countries: [],
	});
	const locale = localStorage.getItem("currentLocale") || "en";

	// useEffect(() => {
	//   async function fetchLookups() {
	//     const res = await fetchCountries();
	//     const format = res.map((t) => ({
	//       value: t.id,
	//       label: t.names[locale],
	//       code: t.country_code
	//     }));
	//     setLookups({
	//       ...lookups,
	//       countries: format
	//     });
	//   }

	//   fetchLookups();
	// }, []);

	const addCustomRate = (country, retePlanId) => {
		let ratePlan = [...calendar.ratePlan];
		ratePlan.forEach((element) => {
			if (element.value === retePlanId) {
				element.countries = [...element.countries, ...country];
			}
		});
		setCalendar({ ...calendar, ratePlan: ratePlan });
	};

	const ChangePriceRangeDays = async () => {
		setStarted(false);
		let date_from = RangeDays.days[0];
		let date_to = RangeDays.days[RangeDays.days.length - 1];
		let response;
		console.log("selectedCalenderDayTime**", selectedCalenderDayTime);
		debugger;
		if (
			moment(date_to).isAfter(date_from) ||
			moment(date_to).isSame(date_from)
		) {
			// response = await addPriceRangeDays(props.id, {
			// 	room_id: props.room.id,
			// 	count: RangeDays.count,
			// 	date_from: date_from,
			// 	date_to: date_to,
			// });

			response = await UpdateCalenderBulk(props.id, {
				//moment(date_from).subtract(1, "days")
				date_from: moment(date_from).subtract(1, "days").format("YYYY-MM-DD"),
				date_to: date_to,
				price_per_pax: selectedCalenderDayTime?.price_per_pax,
				max_pax: +selectedCalenderDayTime?.max_pax,
				times: [...selectedCalenderDayTime?.times],
			});
			if (response?.data || response.status == 200) {
				store.addNotification({
					title: "Info!",
					message: "done",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						props.view();
					},
				});
			}
		} else {
			// response = await addPriceRangeDays(props.id, {
			// 	room_id: props?.room?.id,
			// 	count: RangeDays.count,
			// 	date_from: date_to,
			// 	date_to: date_from,
			// });
			response = await UpdateCalenderBulk(props.id, {
				//moment(date_from).subtract(1, "days")
				date_from: date_to,
				date_to: date_from,
				price_per_pax: selectedCalenderDayTime.price_per_pax,
				max_pax: +selectedCalenderDayTime.max_pax,
				times: [...selectedCalenderDayTime?.times],
			});

			if (response?.data || response.status == 200) {
				store.addNotification({
					title: "Info!",
					message: "done",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						props.view();
					},
				});
			}
		}

		setRangeDays({ days: [] });

		if (response.message) {
			getViewCalendar({ ...filters, id: props.id });
			store.addNotification({
				title: "Info!",
				message: response.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	useEffect(() => {
		if (RangeDays.days.length > 0) {
			const timeoutId = setTimeout(() => ChangePriceRangeDays(), 800);
			return () => clearTimeout(timeoutId);
		}
	}, [RangeDays]);

	const [heightRate, setHeightRate] = useState(0);
	const selectRef = useRef(null);

	// useEffect(() => {
	// 	if (selectRef?.current?.offsetHeight !== null) {
	// 		setHeightRate(selectRef.current.offsetHeight);
	// 	}
	// }, [selectRef?.current?.offsetHeight, calendar?.ratePlan?.length]);

	const showCountries = (rate) => {
		let ratePlan = [...calendar.ratePlan];
		ratePlan.forEach((element) => {
			if (element.value === rate.value) {
				element.showCountries = !element.showCountries;
			}
		});
		setCalendar({ ...calendar, ratePlan: ratePlan });
	};

	const ConfigureTime = async (calenderDay) => {
		const response = await updateCalender(id, {
			days: [moment(calenderDay?.day).format("YYYY-MM-DD")],
			price_per_pax: +calenderDay?.price_per_pax,
			max_pax: +calenderDay?.max_pax,
			times: [...calenderDay?.times],
		});
		console.log("response", response);
		if (response?.data || response.status == 200) {
			store.addNotification({
				title: "Info!",
				message: "done",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					toggleTime();
					props.view();
				},
			});
		}
	};
	console.log("props.days", props.days);
	return (
		<>
			<AddCustomRate
				customRatemodal={customRatemodal}
				setCustomRatemodal={setCustomRatemodal}
				ratetoggle={ratetoggle}
				addCustomRate={addCustomRate}
				countries={lookups.countries}
			/>

			<div className="calendar-room">
				<div className="calendar-head bg-light">
					{/* <button
						className="btm btn-primary border-0 py-2 rounded font-12"
						onClick={toggle}
					>
						{hotelLayout.calendar.bulkEdit}
					</button> */}
				</div>
				{console.log("calendar.ratePlan", calendar.ratePlan)}
				<div className="calendar-parent">
					<ul className="calendar-title">
						<li></li>
						{/* <li>{hotelLayout.calendar.roomStatus}</li> */}
						<li></li>
						<li>Pax Per Reservation</li>
						<li>{hotelLayout.calendar.netBooked}</li>
						{/* <li className="calendar-select" ref={selectRef}>
							<SelectField
								label={hotelLayout.calendar.ratePlan}
								value={calendar.ratePlan}
								name="ratePlan"
								id="ratePlan"
								multi={true}
								options={ratesplans}
								onChange={(e) => {
									setCalendar({ ...calendar, ratePlan: e });
								}}
							/>

						</li> */}
						{calendar.ratePlan && calendar.ratePlan?.length > 0
							? calendar.ratePlan.map((ratePlan) => (
									<li className="shadow-sm1 product-build__product-collpase branch-collapse p-0">
										<div className="button-collapse bg-white border-0">
											<div className="p-2 d-flex justify-content-between align-items-center">
												<div>
													<p className="font-12" onClick={toggleButton}>
														{/* {ratePlan.label} */}
														Price
													</p>
													{/* <a
														href={() => false}
														className="text-primary font-12"
														onClick={() => ratetoggle(ratePlan.value)}
													>
														{hotelLayout.calendar.addCustomPrice}{" "}
													</a> */}
												</div>

												{ratePlan.countries && ratePlan.countries.length > 0 ? (
													<i
														onClick={() => showCountries(ratePlan)}
														className={`fas fa-fw pointer text-gray-300  ${
															ratePlan.showCountries !== true
																? "fa-chevron-right "
																: "fa-chevron-down"
														} `}
													></i>
												) : (
													""
												)}
											</div>
										</div>

										<Collapse isOpen={ratePlan.showCountries}>
											{ratePlan.countries && ratePlan.countries.length > 0
												? ratePlan.countries.map((res) => (
														<li className="child-country">- {res.label}</li>
												  ))
												: ""}
										</Collapse>
									</li>
							  ))
							: ""}
					</ul>
					{console.log("taha", calendar)}
					<div
						onMouseUp={handleMouseUp}
						onMouseDown={handleMouseDown}
						className="day-parent"
					>
						{props.days.map((cell, index) => {
							return (
								<Cell
									heightRate={heightRate}
									ratePlans={calendar.ratePlan}
									day={cell}
									handle={handle}
									setHandle={setHandle}
									selected={selected}
									setSelected={setSelected}
									started={started}
									setStarted={setStarted}
									setHandleFirstDay={setHandleFirstDay}
									handleFirstDay={handleFirstDay}
									setRangeDays={setRangeDays}
									RangeDays={RangeDays}
									id={index}
									hotelId={props.id}
									key={index + 100}
									room={props.DaysCalender}
									room_availability_days={props.DaysCalender}
									setSelectedCalenderDayTime={setSelectedCalenderDayTime}
									// ConfigureTime={ConfigureTime}
									toggleTime={toggleTime}
									view={() => props.view()}
								/>
							);
						})}
					</div>
				</div>
			</div>
			<ConfigureTimeCalender
				isOpen={IsOpenTime}
				toggle={toggleTime}
				state={selectedCalenderDayTime}
				setState={setSelectedCalenderDayTime}
				ConfigureTime={ConfigureTime}
			/>
		</>
	);
}
