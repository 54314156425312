export default function TotalPrice({ currency, totalPrice }) {
	return (
		<>
			<div className="module-list-header total-price">
				<div className="row mx-0">
					<div className="col-md-7"></div>
					<div className="col-md-1"></div>
					<div className="col-md-1"></div>
					<div className="col-md-3 modul-column total-price-item">
						<p>
							Total: {totalPrice || 0} {currency}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
