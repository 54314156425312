export function Plus() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 3.75V14.25"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.75 9H14.25"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export function Eye({ width = 24, height = 25, stroke = "#9C9FAB" }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.58 12.5C15.58 14.48 13.98 16.08 12 16.08C10.02 16.08 8.42004 14.48 8.42004 12.5C8.42004 10.52 10.02 8.92 12 8.92C13.98 8.92 15.58 10.52 15.58 12.5Z"
				stroke={stroke}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12 20.77C15.53 20.77 18.82 18.69 21.11 15.09C22.01 13.68 22.01 11.31 21.11 9.9C18.82 6.3 15.53 4.22 12 4.22C8.46997 4.22 5.17997 6.3 2.88997 9.9C1.98997 11.31 1.98997 13.68 2.88997 15.09C5.17997 18.69 8.46997 20.77 12 20.77Z"
				stroke={stroke}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export function Delete() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 6.48C17.67 6.15 14.32 5.98 10.98 5.98C9 5.98 7.02 6.08 5.04 6.28L3 6.48"
				stroke="#D71A21"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.5 5.47L8.72 4.16C8.88 3.21 9 2.5 10.69 2.5H13.31C15 2.5 15.13 3.25 15.28 4.17L15.5 5.47"
				stroke="#D71A21"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.85 9.64L18.2 19.71C18.09 21.28 18 22.5 15.21 22.5H8.79002C6.00002 22.5 5.91002 21.28 5.80002 19.71L5.15002 9.64"
				stroke="#D71A21"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.33 17H13.66"
				stroke="#D71A21"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.5 13H14.5"
				stroke="#D71A21"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export function PaymentClock() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1_5343)">
				<path
					d="M24 17.5C24 21.37 20.87 24.5 17 24.5C13.13 24.5 10 21.37 10 17.5C10 17.16 10.03 16.83 10.08 16.5H2V4.5H20V11.18C22.36 12.31 24 14.71 24 17.5ZM10.68 14.5C10.86 14.14 11.05 13.8 11.28 13.47C11.19 13.5 11.1 13.5 11 13.5C9.34 13.5 8 12.16 8 10.5C8 8.84 9.34 7.5 11 7.5C12.66 7.5 14 8.84 14 10.5C14 10.75 13.96 11 13.9 11.23C14.84 10.77 15.89 10.5 17 10.5C17.34 10.5 17.67 10.53 18 10.58V8.5C17.4696 8.5 16.9609 8.28929 16.5858 7.91421C16.2107 7.53914 16 7.03043 16 6.5H6C6 7.61 5.11 8.5 4 8.5V12.5C4.53043 12.5 5.03914 12.7107 5.41421 13.0858C5.78929 13.4609 6 13.9696 6 14.5H10.68ZM22 17.5C22 14.74 19.76 12.5 17 12.5C14.24 12.5 12 14.74 12 17.5C12 20.26 14.24 22.5 17 22.5C19.76 22.5 22 20.26 22 17.5Z"
					fill="#9C9FAB"
				/>
				<line x1="16.8997" y1="15" x2="16.8997" y2="20" stroke="#9C9FAB" />
				<line x1="14.3997" y1="17.5" x2="19.3997" y2="17.5" stroke="#9C9FAB" />
			</g>
			<defs>
				<clipPath id="clip0_1_5343">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export function Edit() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.26 4.1L5.04997 12.79C4.73997 13.12 4.43997 13.77 4.37997 14.22L4.00997 17.46C3.87997 18.63 4.71997 19.43 5.87997 19.23L9.09997 18.68C9.54997 18.6 10.18 18.27 10.49 17.93L18.7 9.24C20.12 7.74 20.76 6.03 18.55 3.94C16.35 1.87 14.68 2.6 13.26 4.1Z"
				stroke="#9C9FAB"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.89 5.55C12.32 8.31 14.56 10.42 17.34 10.7"
				stroke="#9C9FAB"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3 22.5H21"
				stroke="#9C9FAB"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export function Download() {
	return (
		<svg
			width="20"
			height="19"
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1_5311)">
				<path
					d="M15.3825 16.4204C15.3825 16.2122 15.3064 16.0319 15.1542 15.8798C15.002 15.7276 14.8218 15.6515 14.6136 15.6515C14.4053 15.6515 14.2251 15.7276 14.0729 15.8798C13.9207 16.0319 13.8446 16.2122 13.8446 16.4204C13.8446 16.6287 13.9207 16.8089 14.0729 16.9611C14.2251 17.1133 14.4053 17.1894 14.6136 17.1894C14.8218 17.1894 15.002 17.1133 15.1542 16.9611C15.3064 16.8089 15.3825 16.6287 15.3825 16.4204ZM18.4582 16.4204C18.4582 16.2122 18.3821 16.0319 18.2299 15.8798C18.0778 15.7276 17.8975 15.6515 17.6893 15.6515C17.481 15.6515 17.3008 15.7276 17.1486 15.8798C16.9964 16.0319 16.9204 16.2122 16.9204 16.4204C16.9204 16.6287 16.9964 16.8089 17.1486 16.9611C17.3008 17.1133 17.481 17.1894 17.6893 17.1894C17.8975 17.1894 18.0778 17.1133 18.2299 16.9611C18.3821 16.8089 18.4582 16.6287 18.4582 16.4204ZM19.9961 13.7291V17.5738C19.9961 17.8942 19.884 18.1665 19.6597 18.3908C19.4354 18.6151 19.1631 18.7272 18.8427 18.7272H1.15719C0.836797 18.7272 0.564466 18.6151 0.340193 18.3908C0.115921 18.1665 0.00378418 17.8942 0.00378418 17.5738V13.7291C0.00378418 13.4088 0.115921 13.1364 0.340193 12.9122C0.564466 12.6879 0.836797 12.5757 1.15719 12.5757H6.74398L8.36595 14.2097C8.83052 14.6583 9.37518 14.8825 9.99994 14.8825C10.6247 14.8825 11.1694 14.6583 11.6339 14.2097L13.2679 12.5757H18.8427C19.1631 12.5757 19.4354 12.6879 19.6597 12.9122C19.884 13.1364 19.9961 13.4088 19.9961 13.7291ZM16.0913 6.89283C16.2275 7.22123 16.1714 7.50157 15.9231 7.73385L10.5406 13.1164C10.3964 13.2686 10.2162 13.3447 9.99994 13.3447C9.78368 13.3447 9.60346 13.2686 9.45928 13.1164L4.07674 7.73385C3.82844 7.50157 3.77237 7.22123 3.90853 6.89283C4.0447 6.58045 4.28099 6.42426 4.61739 6.42426H7.69314V1.04172C7.69314 0.833463 7.76923 0.653244 7.92141 0.501059C8.0736 0.348874 8.25382 0.272781 8.46207 0.272781H11.5378C11.7461 0.272781 11.9263 0.348874 12.0785 0.501059C12.2307 0.653244 12.3067 0.833463 12.3067 1.04172V6.42426H15.3825C15.7189 6.42426 15.9552 6.58045 16.0913 6.89283Z"
					fill="#D4A655"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_5311">
					<rect
						width="20"
						height="18.4544"
						fill="white"
						transform="translate(0 0.272781)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
