import domesticIcon from 'assets/images/icons/domesticFlight.svg';
import { useGlobalState } from 'context/global';
import moment from 'moment';



export default function SightseeingItem({ element }) {
  const { locale } = useGlobalState();
  return (
    <div className='package-item-container'>
      <div className='package-item'>
        <div className='icon-item'>
          <img src={domesticIcon} alt="" />
        </div>
        <div className='d-flex flex-column m-2'>
          <span className='airprot-name'>  {element?.item?.name} [{element?.itemable?.name[locale]}]</span>
          <span>{element?.itemable?.country?.name}</span>
          <span>{moment(element?.itemable?.date).format("DD/MM/YYYY")}</span>
        </div>
      </div>
    </div>
  )
}
