import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listVendor } from "services/vendor";
import Locale from "translations";
import Filter from "./filter";
import ImgServiec from './manageVendor/Configurations/ServiceImg';

export default function Vendor() {
	const { backOffice, vendor: vendorLocale } = Locale;
	const [vendorList, setVendorList] = useState([]);
	const [filter, setFilter] = useState({
		search: "",
		country: null,
		contract_start: null,
		contract_end: null,
		service: null,
	});
	const fetchData = async () => {
		const formatFilter = {
			...(filter.search && { search: filter.search }),
			...(filter.country && { country_id: filter.country?.value }),
			...(filter.service && { service: filter.service?.label }),
			...(filter.contract_start && {
				contract_start: moment(filter.contract_start).format("YYYY-MM-DD"),
			}),
			...(filter.contract_end && {
				contract_end: moment(filter.contract_end).format("YYYY-MM-DD"),
			}),
		};
		const res = await listVendor(formatFilter);

		if (res?.status === 200) {
			setVendorList(res.data.data);
		}
	};
	useEffect(() => {
		fetchData();
	}, [filter]);

	const AllVendors =
		vendorList.length > 0 ? (
			vendorList.map((vendor, index) => (
				<tr>
					<td>{vendor.id}</td>
					<td>{vendor.name}</td>
					<td>{vendor.country?.name}</td>
					<td>{vendor.manager_name}</td>
					<td>{`${vendor?.contract_start ? vendor?.contract_start : ""} - ${
						vendor?.contract_end ? vendor?.contract_end : ""
					}`}</td>
					<td>
						<div className="d-flex">
							{vendor?.services?.map((res)=>(
							<ImgServiec name={res} className={"mx-2"} />
							))}
							{/* {vendor?.services?.includes("hotels") ? (
								<div className="img-service">
									<div className="mx-2">
										<img src={hotelImg} alt="" width={22} />
									</div>
								</div>
							) : null}

							{vendor?.services?.includes("transfers") ? (
								<div className="mx-2">
									<img src={transfersImg} alt="" width={40} />
								</div>
							) : null}
							{vendor?.services?.includes("flight") ? (
								<div className="mx-2">
									<img src={flightImg} alt="" width={40} />
								</div>
							) : null}
							{vendor?.services?.includes("tours") ? (
								<div className="mx-2">
									<img src={toursImg} alt="" width={40} />
								</div>
							) : null} */}
						</div>
					</td>
					<td>
						<Link
							to={{
								pathname: `/manage-vendor/${vendor.id}/vendor-details`,
							}}
							className="d-inline-block action--btn font-bold text-success"
						>
							{vendorLocale.manage}
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="4">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h6 className="main-title ">{"Vendor"}</h6>
				{/* <Link className="btn btn-success" to={`/add-vendor`}>
					<i class="fas fa-plus-circle"></i> {backOffice.add}
				</Link> */}
			</div>
			<Filter filter={filter} setFilter={setFilter} />
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Id</th>
							<th>{vendorLocale.name}</th>
							<th>Country</th>
							<th>Manager Name</th>
							<th>Contract Date</th>
							<th>{vendorLocale.services}</th>
							<th>Control</th>
						</tr>
					</thead>

					<tbody>{AllVendors}</tbody>
				</table>
			</div>
		</>
	);
}
