import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import { listVendorFlightReservation } from "services/vendor";
import Locale from "translations";
import Filter from "./filter";

const AirlinesReservation = () => {
	const { backOffice, vendor } = Locale;
	const { id } = useParams();
	const { locale } = useGlobalState();
	const [countries, setCountries] = useState([]);
	const [resvationList, setResvationList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filter, setFilter] = useState({
		search: undefined,
		//created_at
		booking_date: undefined,
		//departure_date
		travel_date: undefined,
		status: undefined,
		page: 1,
	});
	const statusClasses = (status) => {
		switch (status) {
			case "pending":
				return "text-light-yellow";
			case "confirmed":
			case "issued":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};
	const AllReservations =
		resvationList?.length > 0 ? (
			resvationList?.map((res, index) => (
				<tr>
					<td>{res.reference_number}</td>
					<td>{res.company.name[locale]}</td>
					<td>{res.user_name}</td>
					<td>{res.order_number}</td>
					<td>{moment(res.booking_date).format("YYYY-MM-DD")}</td>
					<td>{moment(res.travel_date).format("YYYY-MM-DD")}</td>
					<td>{res.pax}</td>
					<td>
						{res.total_price} {res.currency}{" "}
					</td>
					<td>
						{res?.total_paid} {res.currency}
					</td>
					<td className={`${statusClasses(res.status)} text-capitalize`}>
						{res.status}
					</td>
					<td>
						<Link
							to={{
								pathname: `/vendor/${id}/view/flight/reservation/${res.id}`,
							}}
							className="d-inline-block action--btn"
						>
							<i class="fas fa-chevron-right mx-1"></i>
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};

	useEffect(() => {
		async function fetchReservation() {
			const res = await listVendorFlightReservation(id, {
				//...filter,
				search: filter.search,
				status: filter.status?.label,
				created_at: filter.booking_date
					? moment(filter.booking_date).format("YYYY-MM-DD")
					: null,
				departure_date: filter.travel_date
					? moment(filter.travel_date).format("YYYY-MM-DD")
					: null,
				page: filter.page,
			});
			setResvationList(res?.data?.data);
			setMeta(res?.data?.meta);
		}

		async function fetchLookups() {
			const res = await fetchCountries();
			if (res.status === 200 || res?.data?.length > 0) {
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
				}));
				setCountries(format);
			}
		}
		fetchReservation();
		fetchLookups();
	}, [filter]);
	return (
		<>
			<Filter filter={filter} setFilter={setFilter} countries={countries} />
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Ref.</th>
							<th>{vendor.agency}</th>
							<th>Name</th>
							<th>Flight Order</th>
							<th>{vendor.bookingDate}</th>
							<th>Travel Date</th>
							<th>Pax</th>
							<th>{vendor.Total_Price}</th>
							<th>Total Paid</th>
							<th>{vendor.status}</th>
						</tr>
					</thead>

					<tbody>{AllReservations}</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
};

export default AirlinesReservation;
