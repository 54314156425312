import { voucherString } from "./voucherString";

export const printVoucher = (voucher, inventory) => {
	const keyValuePair = {
		"[voucher_name]": voucher?.company?.name,
		"[inventory_bookingReference]": inventory.messages.bookingReference,
		"[voucher_bookingCode]": voucher?.bookingCode,
		"[inventory_printedOn]": inventory.messages.printedOn,
		"[voucher_printedOn]": voucher?.printedOn,
		"[inventory_confirmationNO]": inventory.messages.confirmationNO,
		"[voucher_confirmationNumber]": voucher?.confirmationNumber,
		"[inventory_bookingStatus]": inventory.messages.bookingStatus,
		"[voucher_bookingStatus]": voucher?.bookingStatus,
		"[inventory_bookedBy]": inventory.messages.bookedBy,
		"[voucher_bookedBy]": voucher?.bookedBy,
		"[inventory_referenceNumber]": inventory.messages.referenceNumber,
		"[voucher_referenceNumber]": voucher?.referenceNumber,
		"[voucher_company_name]": voucher?.company?.name,
		"[inventory_address]": inventory.messages.address,
		"[voucher_address]": voucher?.company?.address,
		"[voucher_business_email]": voucher?.company?.business_email,
		"[inventory_telephone]": inventory.messages.telephone,
		"[voucher_phone]": voucher?.company?.phone,
		"[voucher_country]": voucher?.company?.country,
		"[inventory_serviceProviderDetails]":
			inventory.messages.serviceProviderDetails,
		"[inventory_hotelName]": inventory.messages.hotelName,
		"[voucher_hotel_name]": voucher?.hotel_name,
		"[voucher_hotel_address]": voucher?.hotel_address,
		"[voucher_hotel_phone]": voucher?.hotel_phone,
		"[inventory_passengerDetails]": inventory.messages.passengerDetails,
		"[inventory_passengerName]": inventory.messages.passengerName,
		"[voucher_passenger_name]": voucher?.passenger?.name,
		"[inventory_passengerNationality]": inventory.messages.passengerNationality,
		"[voucher_nationality]": voucher?.passenger?.nationality,
		"[inventory_CountryResidence]": inventory.messages.CountryResidence,
		"[voucher_passenger_country]": voucher?.passenger?.country,
		"[inventory_serviceType]": inventory.messages.serviceType,
		"[voucher_service_type]": voucher?.passenger?.service_type,
		"[inventory_city]": inventory.messages.city,
		"[voucher_city]": voucher?.passenger?.city,
		"[inventory_checkin]": inventory.messages.checkin,
		"[voucher_checkin]": voucher?.checkin,
		"[inventory_checkOut]": inventory.messages.checkOut,
		"[voucher_checkout]": voucher?.checkout,
		"[inventory_roomType]": inventory.messages.roomType,
		"[voucher_roomType]": voucher?.roomType,
		"[inventory_roomCategory]": inventory.messages.roomCategory,
		"[voucher_roomCategory]": voucher?.roomCategory,
		"[inventory_MealPlan]": inventory.messages.MealPlan,
		"[voucher_rateBasis]": voucher?.rateBasis,
		"[inventory_additionalRequests]": inventory.messages.additionalRequests,
		"[voucher_additionalRequests]": voucher?.additionalRequests,
	};

	let printVar = voucherString;
	const onlineVisaURL = process.env.REACT_APP_API_URL + "/v1/files/fetch/";

	printVar = printVar.replace(
		"[voucher_avatar]",
		voucher?.company?.avatar
			? `<img
				src={${onlineVisaURL}${voucher?.company?.avatar}}
				alt="hotal-logo"
				width={100}
				height={100}
			/>`
			: `
			<div className="letter-logo">${voucher?.company?.name[0]}</div>
		`
	);

	for (const key in keyValuePair) {
		printVar = printVar.replaceAll(key, keyValuePair[key]);
	}

	const popupWin = window.open();

	popupWin.document.write(`
          <html>
          <head>
          <title> print Invoice</title>
          <style>
			
          </style>
          </head>
          <body onLoad="window.print()">${printVar}</body>
          </html>`);
	popupWin.document.close();

	setTimeout(() => {
		popupWin.print();
	}, 500);

	return true;
};
