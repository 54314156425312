import { useEffect, useState } from "react";
import {
	getDirectVisaStatistics,
	getLatestItem,
	getStatistics,
	getVisaStatistics,
} from "services/multiWallets";
import blueimg from "../../../../assets/images/blue.png";
import greenimg from "../../../../assets/images/green.png";
import redimg from "../../../../assets/images/red.png";
import LatestGroupReservations from "./GroupReservations";
import ListPayFiled from "./ListPayFiled";
import LatestOnlineTransactions from "./OnlineTransactions";
import StatisticItem from "./StatisticItem";
import LatestHotelReservations from "./hotelReservations";

function Statistics() {
	const [reservations, setReservations] = useState({});
	const [latestitems, setlatestitems] = useState({});
	const [visaState, setVisaState] = useState({});
	const [directvisaState, setDirectVisaState] = useState({});

	useEffect(() => {
		const getWallet = async () => {
			const res = await getStatistics();
			if (res?.status === 200) {
				setReservations(res.data.data);
			}
		};
		const fetchLatest = async () => {
			const data = await getLatestItem();
			if (data?.status === 200) {
				setlatestitems(data.data);
			}
		};
		const fetchVisa = async () => {
			const data = await getVisaStatistics();
			if (data?.status === 200) {
				setVisaState(data.data?.data);
			}
		};
		const fetchDirectVisa = async () => {
			const data = await getDirectVisaStatistics();
			debugger;
			if (data?.status === 200) {
				setDirectVisaState(data.data?.data);
			}
		};
		getWallet();
		fetchLatest();
		fetchVisa();
		fetchDirectVisa();
	}, []);

	return (
		<>
			<div className="row mb-4">
				<StatisticItem
					title={"Total Companies"}
					number={reservations?.total_companies?.count}
					img={blueimg}
					className="col-4"
				/>
				<StatisticItem
					title={"Unverified Companies"}
					number={reservations?.unverified_companies?.count}
					img={redimg}
					className="col-4"
				/>
				<StatisticItem
					title={"Total Bookings"}
					number={reservations?.total_bookings?.count}
					img={greenimg}
					className="col-4"
				/>
			</div>
			<h3>Visas</h3>
			<div className="row  mb-4">
				<StatisticItem
					title={"Total Visas"}
					number={visaState?.total_visas}
					img={blueimg}
					className="col-3"
				/>
				<StatisticItem
					title={"Used Visas"}
					number={visaState?.used_visas}
					img={blueimg}
					className="col-3"
				/>
				<StatisticItem
					title={"Available"}
					number={visaState?.available_visas}
					img={blueimg}
					className="col-3"
				/>
				<StatisticItem
					title={"Expired Visas"}
					number={visaState?.expired_visas}
					img={blueimg}
					className="col-3"
				/>
			</div>
			<h3>Direct Visa</h3>
			<div className="row  mb-4">
				<StatisticItem
					title={"Total Direct Visas"}
					number={directvisaState?.direct_visas}
					img={blueimg}
					className="col-4"
				/>
				<StatisticItem
					title={"Total Paid Visas"}
					number={directvisaState?.direct_paid_visas}
					img={greenimg}
					className="col-4"
				/>
				<StatisticItem
					title={"Total Unpaid Visas"}
					number={directvisaState?.direct_unpaid_visas}
					img={redimg}
					className="col-4"
				/>
			</div>
			<div className="row m-0">
				<ListPayFiled />
				<LatestHotelReservations latestitems={latestitems} />

				<LatestGroupReservations latestitems={latestitems} />

				<LatestOnlineTransactions latestitems={latestitems} />
			</div>
		</>
	);
}

export default Statistics;
