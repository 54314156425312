import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function HotelCommissionsTableContent({
	fetchData,
	hotelItem,
	index,
}) {
	const { backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	return (
		<>
			<tr key={hotelItem.id}>
				<td scope="row">{hotelItem.name}</td>
				<td>{hotelItem.country.name}</td>
				<td>{moment(hotelItem.contract_start_date).format("DD-MM-YYYY")} </td>
				<td>{moment(hotelItem.contract_end_date).format("DD-MM-YYYY")} </td>
				<td>{hotelItem.mobile} </td>
				<td>
					<p
						className={
							hotelItem.is_active == "0"
								? "text-warning"
								: hotelItem.is_active == "1"
								? "text-success"
								: "text-danger"
						}
					>
						{hotelItem.is_active == "1"
							? backOffice.active
							: backOffice.inActive}
					</p>
				</td>
				<td>
					<Link
						to={{
							pathname: `/hotel-details/${hotelItem.id}`,
						}}
						className="d-inline-block action--btn"
					>
						<i className="fas fa-eye fa-lg"></i> {backOffice.view}
					</Link>
					<ShowForPermission permission="Hotel-Commission-Manage">
						<Link
							to={{
								pathname: `/edit-hotel/${hotelItem.id}`,
							}}
							className="d-inline-block action--btn"
						>
							<i className="fas fa-edit fa-lg"></i> {backOffice.edit}
						</Link>
					</ShowForPermission>
					{/* <Link
            to={{
              pathname: `/hotel-details/${hotelItem.id}`
            }}
            className="d-inline-block action--btn"
          >
            <i className="fas fa-money-check-alt fa-lg"></i> {backOffice.rounds}
          </Link> */}
					{/* <a
              href={() => false}
              className="d-inline-block text-danger"
              onClick={() => removeItem(hotelItem.id)}
            >
              <i className="fas fa-trash fa-lg"></i> {backOffice.delete}
            </a> */}
				</td>
			</tr>
		</>
	);
}
