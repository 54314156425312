export default function Passenger({
	setSelected,
	passangerInfo,
	index,
	toggleCancel,
	toggleGuset,
	toggleEdit,
	toggleDoc,
	voucherData,
	status,
	passengers,
}) {
	console.log("passangerInfo", passengers);
	return (
		<tr>
			<td>#{index + 1}</td>
			<td>{passangerInfo.from_date}</td>
			<td>{passangerInfo.to_date}</td>
			<td>
				{passangerInfo?.passengers?.length > 0 ? (
					// passangerInfo?.passengers.map((pass,index)=>{
					//  return <span>
					// 			{pass?.first_name}{" "}
					// 			{pass?.last_name}
					// 		</span>
					// })
					<span>
						{passangerInfo?.passengers[0]?.first_name}{" "}
						{passangerInfo?.passengers[0]?.last_name}
					</span>
				) : (
					""
				)}
			</td>
			<td>{passangerInfo.name}</td>
			<td>{passangerInfo.adults}</td>
			<td>{passangerInfo.children}</td>
			<td>
				{passangerInfo.room_rates && passangerInfo.room_rates.length > 0 ? (
					passangerInfo.room_rates.filter(
						(res) => +res.mealOptionId == +passangerInfo.mealOptionId
					)[0]?.mealTitle
				) : (
					<span className="text-gray">-</span>
				)}
			</td>
			<td>
				{passangerInfo.price} {passengers.currency}
			</td>

			{/* <td>
      {status!="canceled"?
            <>
        <i onClick={() => {
          toggleEdit();
          setSelected({
            ...passangerInfo,
            room_price_currency:
            passangerInfo.room_rates[0]?.room_price_currency,
          });
        }}
          style={{ backgroundColor: "#CB9A51" }} class="far fa-edit p-1 text-white mx-1"></i>
        

        <i onClick={() => {
          	voucherData(passangerInfo?.room_key);
        }} style={{ backgroundColor: "#28A745" }} class="fas fa-cloud-download-alt p-1 text-white mx-1"></i>


        <i onClick={() => {
          toggleDoc();
          setSelected({
            ...passangerInfo,
            room_price_currency:
            passangerInfo.room_rates[0]?.room_price_currency,
          });
        }} style={{ backgroundColor: "#0C3B5C" }} class="fas fa-file-alt text-white p-1 mx-1"></i>


        <i onClick={() => {
          toggleGuset();
          setSelected({
            ...passangerInfo,
            room_price_currency:
            passangerInfo.room_rates[0]?.room_price_currency,
          });
        }} style={{ backgroundColor: "#0C3B5C" }} class="fas fa-users text-white p-1 mx-1"></i>


        <i onClick={() => {
          toggleCancel();
          setSelected({
            ...passangerInfo,
            room_price_currency:
            passangerInfo.room_rates[0]?.room_price_currency,
          });
        }} style={{ backgroundColor: "#FF6868" }} class="fas fa-ban text-white  p-1 mx-1"></i>
        </> :<p className='text-danger'>Canceled</p>}
      </td> */}
		</tr>
	);
}
