import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { addFlightTicket, updateFlightTicket } from "services/airlines";
import { airlineLookup } from "services/b2c";
import Locale from "translations";

const classOption = [
	{ id: "ECONOMY", value: "ECONOMY", label: "ECONOMY" },
	{
		id: "PremiumEconomy",
		value: "PremiumEconomy",
		label: "PremiumEconomy",
	},
	{ id: "Business", value: "Business", label: "Business" },
	{ id: "First", value: "First", label: "First" },
];

export default function HandleRoutes({ isOpen, toggle, submitAmount, ticket }) {
	const update = ticket?.update;
	const { backOffice } = Locale;
	const [routeData, setRouteData] = useState({
		from: null,
		to: null,
		airline: null,
		flightNo: "",
		departureTerminal: "",
		arrivalTerminal: "",
		ticketNo: "",
		pnr: "",
		segmentID: "",
		class: null,
		equipment: "",
		flightTime: "",
		departureDate: moment(),
		departureTime: moment(),
		arrivalDate: moment().add(1, "day"),
		arrivalTime: moment(),
	});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const [errors, setErrors] = useState([]);

	const [listAutoFrom, setListAutoFrom] = useState([]);

	const getListAutoFrom = async (inputValue) => {
		if (inputValue.length > 2) {
			const airs = await airlineLookup(inputValue);
			let result = airs?.data?.data?.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});
			setListAutoFrom(result);
		}
	};
	const [listAutoTo, setListAutoTo] = useState([]);

	const getListAutoTo = async (inputValue) => {
		if (inputValue.length > 2) {
			const airs = await airlineLookup(inputValue);
			let result = airs?.data?.data?.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});
			setListAutoTo(result);
		}
	};

	const [listAutoAirline, setListAutoAirline] = useState([]);

	const getListAutoAirline = async (inputValue) => {
		if (inputValue.length > 2) {
			const airs = await airlineLookup(inputValue);
			let result = airs?.data?.data?.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});
			setListAutoAirline(result);
		}
	};

	const checkFormErrors = () => {
		let error = {};
		Object.keys(routeData).forEach((key) => {
			error = {
				...error,
				...validate(
					{ name: key, value: routeData[key] },
					{ required: key !== "segmentID" }
				),
			};
		});
		setErrors(error);
	};

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		const submitAction = async () => {
			if (isFormValid(errors)) {
				let TicketData = {
					passenger_id: ticket.passengerId,
					flight_id: ticket.flight_id,
					cabin_class: routeData.class.id,
					departure_date: `${moment(routeData.departureDate).format(
						"YYYY-MM-DD"
					)} ${moment(routeData.departureTime).format("HH:mm:ss")}`,
					arrival_date: `${moment(routeData.arrivalDate).format(
						"YYYY-MM-DD"
					)} ${moment(routeData.arrivalTime).format("HH:mm:ss")}`,
					ticket_number: routeData.ticketNo,
					flight_journey_id: ticket?.flight_journey_id,
					segment_id: routeData.segmentID,
					airline: routeData.airline.id,
					flight_num: +routeData.flightNo,
					equipment: routeData.equipment,
					// booking_code: routeData.,
					departure: "JED",
					departure_terminal: routeData.departureTerminal,
					arrival: "KWI",
					arrival_terminal: routeData.arrivalTerminal,
					flight_time: +routeData.flightTime,
					pnr: routeData.pnr,
				};
				debugger;
				const res = update
					? updateFlightTicket(ticket.requestId, ticket.id, TicketData)
					: addFlightTicket(ticket.requestId, TicketData);
				if (res.status === 200) {
					store.addNotification({
						title: "Ticket",
						message: `Ticket ${update ? "Updated" : "Added"} Successfully`,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					toggle();
				}
			}
		};
		submitAction();
		return () => {};
	}, [isErrorLoaded]);

	useEffect(() => {
		if (update) {
			setRouteData({
				from: { ...ticket.departure, label: ticket.departure.name },
				to: { ...ticket.arrival, label: ticket.arrival.name },
				airline: { ...ticket.airline, label: ticket.airline.name },
				flightNo: ticket.flight_num,
				departureTerminal: ticket.departure_terminal,
				arrivalTerminal: ticket.arrival_terminal,
				ticketNo: ticket.ticket_number,
				pnr: ticket.pnr,
				segmentID: ticket.segment_id,
				class: {
					id: ticket.cabin_class,
					label: ticket.cabin_class,
					name: ticket.cabin_class,
				},
				equipment: ticket.equipment,
				flightTime: ticket.flight_time,
				departureDate: moment(ticket.departure_date),
				departureTime: moment(ticket.departure_date),
				arrivalDate: moment(ticket.arrival_date),
				arrivalTime: moment(ticket.arrival_date),
			});
		}
		return () => {};
	}, []);

	const routeHandler = (data, multiapl, required = true) => {
		const { name, value } = data;
		const handledData = multiapl ? data : { [name]: value };
		setRouteData((prev) => ({ ...prev, ...handledData }));
		const multiName = Object.keys(data)[0];
		const validateObj = multiapl
			? { name: multiName, value: data[multiName] }
			: { name, value };

		setErrors((prev) => ({
			...errors,
			...validate(validateObj, {
				required: required,
			}),
		}));
	};

	return (
		<Modal isOpen={isOpen} centered={true}>
			<ModalHeader toggle={toggle}>
				<span>Add Route</span>
			</ModalHeader>

			<ModalBody className="pt-0 ">
				<div className="px-3 text-center row">
					<div className="col-6 route-filed">
						<AutoCompleteField
							name="from"
							id="from"
							label="Route From"
							placeholder="Route From"
							listAuto={listAutoFrom}
							setListAuto={setListAutoFrom}
							getListAuto={getListAutoFrom}
							isSearchable={true}
							value={routeData.from?.label}
							onChange={(e) => routeHandler({ name: "from", value: e })}
							onSelectValue={(e) => {
								routeHandler({ name: "from", value: e });
							}}
							color={errors?.from?.required ? "danger" : ""}
							errors={errors?.from}
						/>
					</div>
					<div className="col-6 route-filed">
						<AutoCompleteField
							name="to"
							id="to"
							label="Route To"
							placeholder="Route To"
							listAuto={listAutoTo}
							setListAuto={setListAutoTo}
							getListAuto={getListAutoTo}
							isSearchable={true}
							value={routeData.to?.label}
							onChange={(e) => routeHandler({ name: "to", value: e })}
							onSelectValue={(e) => {
								routeHandler({ name: "to", value: e });
							}}
							color={errors?.to?.required ? "danger" : ""}
							errors={errors?.to}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<AutoCompleteField
							name="airline"
							id="airline"
							label="Airline"
							placeholder="Airline"
							listAuto={listAutoAirline}
							setListAuto={setListAutoAirline}
							getListAuto={getListAutoAirline}
							isSearchable={true}
							value={routeData.airline?.label}
							onChange={(e) => routeHandler({ name: "airline", value: e })}
							onSelectValue={(e) => {
								routeHandler({ name: "airline", value: e });
							}}
							color={errors?.airline?.required ? "danger" : ""}
							errors={errors?.airline}
						/>
					</div>

					<div className="col-6 change-request-fields">
						<TextField
							type={"number"}
							name="flightNo"
							id="flightNo"
							label="Flight No"
							placeholder="Flight No"
							value={routeData.flightNo}
							onChange={(e) =>
								routeHandler({ name: "flightNo", value: e.target.value })
							}
							color={errors?.flightNo?.required ? "danger" : ""}
							errors={errors?.flightNo}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<TextField
							type={"text"}
							name="departureTerminal"
							id="departureTerminal"
							label="Departure terminal"
							placeholder="Departure terminal"
							value={routeData.departureTerminal}
							onChange={(e) =>
								routeHandler({
									name: "departureTerminal",
									value: e.target.value,
								})
							}
							color={errors?.departureTerminal?.required ? "danger" : ""}
							errors={errors?.departureTerminal}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<TextField
							type={"text"}
							name="arrivalTerminal"
							id="arrivalTerminal"
							label="Arrival terminal"
							placeholder="Arrival terminal"
							value={routeData.arrivalTerminal}
							onChange={(e) =>
								routeHandler({ name: "arrivalTerminal", value: e.target.value })
							}
							color={errors?.arrivalTerminal?.required ? "danger" : ""}
							errors={errors?.arrivalTerminal}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<TextField
							type={"text"}
							name="ticketNo"
							id="ticketNo"
							label="Ticket No"
							placeholder="Ticket No"
							value={routeData.ticketNo}
							onChange={(e) =>
								routeHandler({ name: "ticketNo", value: e.target.value })
							}
							color={errors?.ticketNo?.required ? "danger" : ""}
							errors={errors?.ticketNo}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<TextField
							type={"text"}
							name="pnr"
							id="pnr"
							label="PNR"
							placeholder="PNR"
							value={routeData.pnr}
							onChange={(e) => {
								routeHandler({ name: "pnr", value: e.target.value });
							}}
							color={errors?.pnr?.required ? "danger" : ""}
							errors={errors?.pnr}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<TextField
							type={"text"}
							name="segmentID"
							id="segmentID"
							label="Segment ID"
							placeholder="Segment ID"
							value={routeData.segmentID}
							onChange={(e) => {
								routeHandler(
									{ name: "segmentID", value: e.target.value },
									false,
									false
								);
							}}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<SelectField
							placeholder="Class"
							hasLabel={true}
							id="class"
							name="class"
							label="Class"
							options={classOption}
							value={routeData.class}
							onChange={(e) => {
								routeHandler({ name: "class", value: e });
							}}
							color={errors?.class?.required ? "danger" : ""}
							errors={errors?.class}
						/>
					</div>

					<div className="col-6 change-request-fields">
						<TextField
							type={"text"}
							name="equipment"
							id="equipment"
							label="Equipment"
							placeholder="Equipment"
							value={routeData.equipment}
							onChange={(e) => {
								routeHandler({ name: "equipment", value: e.target.value });
							}}
							color={errors?.equipment?.required ? "danger" : ""}
							errors={errors?.equipment}
						/>
					</div>
					<div className="col-6 change-request-fields">
						<TextField
							type={"number"}
							name="flightTime"
							id="flightTime"
							label="Flight Time"
							placeholder="Flight Time"
							value={routeData.flightTime}
							onChange={(e) => {
								routeHandler({ name: "flightTime", value: e.target.value });
							}}
							color={errors?.flightTime?.required ? "danger" : ""}
							errors={errors?.flightTime}
						/>
					</div>

					<div className="col-6 px-3">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Departure Date"}
							date={routeData.departureDate}
							onChangeDate={(date) => {
								routeHandler(
									{
										departureDate: date,
										arrivalDate: moment(date).add(1, "d"),
									},
									true
								);
							}}
						/>
					</div>

					<div className="col-6  change-request-time-picker-container">
						<label htmlFor="departureTime">Departure Time</label>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								className="change-request-time-picker"
								variant="outlined"
								inputVariant="outlined"
								id="departureTime"
								value={routeData.departureTime}
								onChange={(time) =>
									routeHandler({ name: "departureTime", value: time })
								}
							/>
						</MuiPickersUtilsProvider>
					</div>

					<div className="col-6 px-3">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Arrival Date"}
							date={routeData.arrivalDate}
							onChangeDate={(date) => {
								routeHandler({ name: "arrivalDate", value: date });
							}}
						/>
					</div>
					<div className="col-6  change-request-time-picker-container">
						<label htmlFor="arrivalTime">Arrival Time</label>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								className="change-request-time-picker"
								variant="outlined"
								inputVariant="outlined"
								id="arrivalTime"
								// label="Arrival Time"
								// format="dd/MM/yyyy"
								value={routeData.arrivalTime}
								onChange={(date) =>
									routeHandler({ name: "arrivalTime", value: date })
								}
							/>
						</MuiPickersUtilsProvider>
					</div>

					<button className="accept-btn w-100 mt-2" onClick={submit}>
						Save Changes
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}
