import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { ChangeStatusTour } from "services/Request";
import RequestModal from "../modals/requestModal";
import TourBookingDetailsCard from "./BookingDetailsCard";
import KeyValue from "./KeyValue";
import PassengerDetails from "./PassengerDetails";
import QuestionsAnswers from "./QuestionsAnswers";

export default function ToursBookingDetails({
	bookingDetails,
	setBookingDetails,
	setReload,
}) {
	const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
	const [typeRequest, setTypeRequest] = useState({});

	function toggleRequestModal(type = null, id = null) {
		setRequestModalIsOpen((prev) => !prev);
		setTypeRequest({ type, id });
	}
	async function handleRequest(e = null) {
		let data;
		if (typeRequest.type == "accept") {
			data = {
				status: "approved",
				expiration_date: moment(e?.expiration_date).format("YYYY-MM-DD"),
			};
		} else {
			data = {
				status: "rejected",
				reject_reason: e?.reject_reason,
			};
		}

		const res = await ChangeStatusTour(bookingDetails.brn, data);
		if (res.status === 200 || res.status === 201) {
			// toggleRequestModal();
			// setFilter((prev) => ({ ...prev }));
			store.addNotification({
				title: "Info",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
					toggleRequestModal();
					setTypeRequest({});
				},
			});
		}
	}
	return (
		<div className="tour-booking-details" key={bookingDetails?.brn}>
			<div className="tour-booking-details-header">
				<h6 className="text-dark-blue">{"Reservation"}</h6>
			</div>
			{/* map on cart items */}
			{bookingDetails?.reservations.map((reservationItem) => (
				<TourBookingDetailsCard
					reservationItem={reservationItem}
					setBookingDetails={setBookingDetails}
					paymentStatus={bookingDetails?.payment_status}
					bookingStatus={bookingDetails?.status}
					key={reservationItem?.details?.id}
				/>
			))}
			{bookingDetails?.reservations.map((res) => (
				<QuestionsAnswers questions={res?.details?.questions} />
			))}
			{bookingDetails?.reservations?.map((reservationItem, index) => (
				<PassengerDetails
					index={index}
					paxDetails={reservationItem?.details?.paxes}
					name={reservationItem?.details?.name}
					bookingDetails={bookingDetails}
				/>
			))}
			<h6 className="text-dark-blue mt-3 mb-1">Agency Reference</h6>
			<KeyValue
				data={[
					{
						value: "",
						key: bookingDetails?.agency_reference,
					},
				]}
				classStyle={"bg-light-danger"}
			/>

			{bookingDetails?.status === "pending" && (
				<div className="d-flex justify-content-between">
					<button
						className="btn  btn-accept  text-success "
						onClick={() => toggleRequestModal("accept", bookingDetails.brn)}
					>
						<i class="fas fa-check"></i> Accept
					</button>
					<button
						className="btn btn-reject text-danger"
						onClick={() => toggleRequestModal("reject", bookingDetails.brn)}
					>
						<i class="fas fa-times"></i> Reject
					</button>
				</div>
			)}

			{requestModalIsOpen && (
				<RequestModal
					isOpen={requestModalIsOpen}
					toggle={toggleRequestModal}
					submit={handleRequest}
					typeRequest={typeRequest.type}
				/>
			)}
		</div>
	);
}
