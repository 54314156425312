import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter, resetFilter, tabStatus }) {
	const { backOffice } = Locale;
	const statusOptions =
		tabStatus == 1
			? [
					{ value: "pending", label: "Pending" },
					{ value: "waiting_approval", label: "Waiting Approval" },
					{ value: "pending_approval", label: "Pending Approval" },
			  ]
			: [
					{ value: "confirmed", label: "Confirmed" },
					{ value: "expired", label: "Expired" },
					{ value: "rejected", label: "Rejected" },
			  ];

	const requestTypeOptions = [
		{ value: "change", label: "Change" },
		{ value: "refund", label: "Refund" },
		{ value: "void", label: "Void" },
	];
	return (
		<div className=" main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="col">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"Request Date"}
						onOutsideClick={false}
						date={filter?.request_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								request_date: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={"Request Type"}
						label={"Request Type"}
						options={requestTypeOptions}
						value={filter.request_type}
						onChange={(e) => {
							setFilter({
								...filter,
								request_type: e,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						placeholder={"Status"}
						label={"Status"}
						options={statusOptions}
						value={filter.status}
						onChange={(e) => {
							setFilter({
								...filter,
								status: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={resetFilter}
					>
						<img src={resetIcon} alt="" srcset="" />{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
