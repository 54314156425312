import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { exportToExcelAirlineRes, getFilghtList } from "services/airlines";
import Locale from "translations";
import Filter from "./Filter";

export default function AirlineReservationsList() {
	const { locale } = useGlobalState();
	const { backOffice } = Locale;
	const [airlineList, setAirlineList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		booking_date_start: undefined,
		booking_date_end: undefined,
		travel_date: null,
		status: null,
		source: null,
	});

	const FetchAirlineList = async () => {
		const res = await getFilghtList({
			search: filter.search,
			created_at_from: filter.booking_date_start
				? moment(filter.booking_date_start).format("YYYY-MM-DD")
				: null,
			created_at_to: filter.booking_date_end
				? moment(filter.booking_date_end).format("YYYY-MM-DD")
				: null,
			departure_date: filter.travel_date
				? moment(filter.travel_date).format("YYYY-MM-DD")
				: "",
			status: filter.status?.value,
			source: filter.source?.value,
			page: filter.page,
		});
		setAirlineList(res?.data?.data);
		setMeta(res?.data?.meta);
	};
	useEffect(() => {
		FetchAirlineList();
	}, [filter]);

	const statusClasses = (status) => {
		switch (status) {
			case "pending":
			case "issue processing":
				return "text-light-yellow";
			case "confirmed":
			case "issued":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};
	const allAirlineList =
		airlineList.length > 0 ? (
			airlineList.map((item) => {
				return (
					<tr className="text-nowrap" key={item?.reference_number}>
						<td>{item?.reference_number}</td>
						<td>{item?.company?.name?.en || item?.company?.name?.ar || "-"}</td>
						<td>{item?.user_name}</td>
						<td>{item?.order_number}</td>
						<td>{item?.source}</td>
						<td>{moment(item?.booking_date)?.format("YYYY-MM-DD")}</td>
						<td>{moment(item?.travel_date)?.format("YYYY-MM-DD")}</td>

						<td>{item?.pax}</td>
						{/* <td>{item?.total_paid}</td>
						<td>{item?.currency}</td> */}
						<td>{item?.total_price}</td>
						<td>{item?.currency}</td>
						<td className={`${statusClasses(item?.status)} text-capitalize`}>
							{item?.status}
						</td>
						<td>
							<ShowForPermission
								permission={[
									"View-Airlines-Reservations",
									"Manage-Airlines-Reservations",
								]}
							>
								<Link to={`/airline-reservations/${item?.id}`}>
									{locale === "en" ? (
										<i class="fas fa-angle-right"></i>
									) : (
										<i class="fas fa-angle-left"></i>
									)}
								</Link>
							</ShowForPermission>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter((prev) => ({ ...prev, page: page }));
	};
	const exportToFile = async () => {
		const res = await exportToExcelAirlineRes({
			search: filter.search,
			created_at_from: filter.booking_date_start
				? moment(filter.booking_date_start).format("YYYY-MM-DD")
				: null,
			created_at_to: filter.booking_date_end
				? moment(filter.booking_date_end).format("YYYY-MM-DD")
				: null,
			departure_date: filter.travel_date
				? moment(filter.travel_date).format("YYYY-MM-DD")
				: "",
			status: filter.status?.value,
			source: filter.source?.value,
			page: filter.page,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Airline-Reservation-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<div>
			<div className="d-flex justify-content-between">
				<h3 className="mb-2">Airline Reservations</h3>
				<button className="btn btn-primary" onClick={() => exportToFile()}>
					Export To Excel
				</button>
			</div>
			<Filter filter={filter} setFilter={setFilter} />

			<div className=" table-responsive px-4  our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>Ref.</th>
							<th>Agancy</th>
							<th>Name</th>
							<th>Flight Order</th>
							<th>Source</th>
							<th>Booking Date</th>
							<th>Travel Date</th>
							<th>Pax</th>
							<th>Total Price</th>
							<th>Currency</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>

					<tbody>{allAirlineList}</tbody>
				</table>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	);
}
