import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import AutoCompleteMultiField from "components/Form/AutoCompleteMultiField/AutoCompleteMultiField";
import NumberField from "components/Form/NumberField/NumberField";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import useShowPermission from "hooks/useShowPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { isInclusivelyAfterDay } from "react-dates";
import { store } from "react-notifications-component";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { fetchCitiesSearch } from "services/lookups";
import {
	editDatesOffers,
	fetchProviderslist,
	fetchProvidorHotels,
	fetchViewHotelsSearch,
} from "services/wallet";
import Locale from "translations";

function ViewMarketingOffer() {
	const { locale, allCountries, allCurrencies } = useGlobalState();
	const { marketPlace, backOffice, booking } = Locale;
	const { id } = useParams();
	const [searchState, setSearchState] = useState({
		rooms: [{ number_of_adult: null, BoxChild: false }],
	});
	const [errors, setErrors] = useState({
		rooms: [],
	});
	const [providersList, setProviderList] = useState(null);
	const [lookups, setLookups] = useState({
		countries: [],
		currencies: [],
	});
	const [listHotels, setListHotels] = useState([]);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [listAuto, setListAuto] = useState([]);

	const history = useHistory();

	const searchTypeOptions = [
		{ label: "Hotels", value: 0 },
		{ label: "Offers", value: 1 },
	];
	// gneric onchange input
	function handleInputChanges(value, keyName, resetHotels) {
		if (resetHotels && keyName === "destination") {
			setSearchState({
				...searchState,
				[keyName]: value,
				hotels: [],
				hotelsName: "",
			});
		} else {
			setSearchState({
				...searchState,
				[keyName]: value,
			});
		}

		setErrors({
			...errors,
			...validate({ name: keyName, value }, { required: true }),
		});
	}
	// handle edit market offer
	const handleEdit = async () => {
		let children = [];
		let all_rooms_count = [];
		let rooms_data = searchState?.rooms.map((resa) => {
			resa?.roomsList?.map((res) => {
				children.push({
					number_of_adult: +resa.number_of_adult,
					children: [...res.children],
				});
			});
			return children;
		});
		let rooms_count_payload = searchState?.rooms.map((resa) => {
			let x = [];
			resa?.roomsList?.map((res) => {
				x.push(res.children.map((res1) => res1)[0]);
			});
			all_rooms_count.push({
				number_of_adult: +resa.number_of_adult,
				rooms_count: resa.roomLength,
				children: x,
			});
			return all_rooms_count;
		});

		const data = {
			is_umrah: 0,
			is_vacation: 1,
			special_code: "",
			is_offer: searchState?.is_offer?.value,
			//country: searchState.destination.value,
			title: searchState?.title,
			hotels: searchState.hotels,
			provider_id: searchState?.provider?.id,
			city_code: +searchState?.search_payload?.city_code,
			date_from: moment(searchState.checkin).format("YYYY-MM-DD"),

			date_to: moment(searchState.checkout).format("YYYY-MM-DD"),
			start_date: moment(searchState.start_date).format("YYYY-MM-DD"),
			end_date: moment(searchState.end_date).format("YYYY-MM-DD"),
			expiration_date: moment(searchState.expiration_date).format("YYYY-MM-DD"),
			nationalities: searchState?.nationalities?.map((item) => {
				if (item.value === 0 || item.value === "0") {
					return 0;
				} else {
					return {
						country_code: item?.code || item?.country_code,
						name: item?.label || item?.name,
					};
				}
			}),
			residences: searchState?.residence?.map((item) => {
				if (item.value === 0 || item.value === "0") {
					return 0;
				} else {
					return {
						country_code: item?.code || item?.country_code,
						name: item?.label || item?.name,
					};
				}
			}),
			lang: searchState?.search_payload?.lang,
			currency_code:
				searchState?.currency?.label ||
				searchState?.search_payload?.currency_code,

			country: searchState?.country?.map((item) => {
				if (item.value === 0 || item.value === "0") {
					return 0;
				} else {
					return {
						country_id: item?.value,
						name: item?.label || item?.name,
					};
				}
			}),
			discount: searchState?.discount,
			city_name: searchState?.search_payload?.city_name,
			rooms_data: children,
			rooms_count_payload: all_rooms_count,
		};

		let response = await editDatesOffers(searchState?.id, data);
		if (response.status === 200) {
			store.addNotification({
				title: "Offer",
				message: "Edit Dates Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push("/marketing-offers-list");
				},
			});
		}
	};

	// handle hotels options list
	const getHotelsListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const hotels = await fetchProvidorHotels(
				searchState.provider.value || searchState.provider.id,
				inputValue,
				+searchState?.destination?.id
			);
			let result = hotels?.data.map((item) => {
				return {
					...item,
					id: item.hotel_id,
					name: item.name,
					value: item.hotel_id,
					label: item.name,
				};
			});

			setListHotels(result);
		}
	};

	const setRoomsCount = (name, index, value) => {
		let x = searchState?.rooms;
		x[index] = { ...x[index], [name]: value };
		if (name === "roomLength") {
			let rooms = [...Array(+value).keys()].map((res) => ({
				...res,
				children: [],
			}));
			x[index] = { ...x[index], roomsList: rooms };
		}
		setSearchState({ ...searchState, rooms: x });
	};

	const setChildrenCount = (name, index, value) => {
		let x = searchState?.rooms;
		let rooms = [...Array(+value)?.keys()].map((res) => ({
			...res,
			age: 0,
		}));
		let roomsList = x[index].roomsList;
		if (roomsList && roomsList?.length > 0) {
			roomsList.map((res) => {
				res.children = rooms;
			});
		}
		x[index] = { ...x[index], [name]: rooms };
		setSearchState({ ...searchState, rooms: x });
	};

	const setAge = (index, DD, XX, value) => {
		let x = searchState?.rooms;
		let c = x[index]?.roomsList[DD].children;
		const newArr = c.map((ress, indexs) => {
			if (indexs == XX) {
				return { ...ress, age: +value };
			}
			return { ...ress };
		});
		x[index].roomsList[DD].children = newArr;
		setSearchState({ ...searchState, rooms: x });
	};

	// fetch providorslist
	useEffect(() => {
		const fetchProviders = async () => {
			const providers = await fetchProviderslist();
			if (providers?.status === 200) {
				const providersList = providers.data?.map((p) => {
					return { id: p.id, value: p.id, label: p.name, name: p?.name };
				});
				setProviderList(providersList);
			}
		};
		if (!lookups.providers) {
			fetchProviders();
		}
	}, []);

	// format countries and currencies
	useEffect(() => {
		async function formatLookup() {
			const formatCurrencies = allCurrencies?.map((currency) => ({
				id: currency.id,
				value: currency.id,
				label: currency.currency_code,
			}));
			const formatCountries = allCountries?.map((country) => ({
				id: country?.id,
				value: country.id,
				label: country.names[locale],
				code: country.country_code,
			}));
			setLookups({
				currencies: formatCurrencies,
				countries: [{ label: "All", value: 0 }, ...formatCountries],
			});
		}
		if (allCountries && allCurrencies) {
			formatLookup();
		}
	}, [allCountries, allCurrencies, locale]);

	useEffect(() => {
		setRoomsCount("roomLength", 0, 1);
		setRoomsCount("number_of_adult", 0, 2);
	}, []);

	// get market offer data
	useEffect(() => {
		const getView = async () => {
			const hotelView = await fetchViewHotelsSearch(id);
			if (hotelView?.status === 200) {
				const data = hotelView?.data?.data;
				setSearchState({
					...data,
					hotels: data?.hotels || [],
					is_offer:
						data?.is_offer === 0 ? searchTypeOptions[0] : searchTypeOptions[1],
					provider: {
						...data?.provider,
						value: data?.provider?.id,
						label: data?.provider?.name,
					},
					destination: {
						name: data?.search_payload?.city_name,
						label: data?.search_payload?.city_name,
						value: data?.search_payload?.city_code,
						id: data?.search_payload?.city_code,
					},
					country:
						data?.country === null
							? [{ label: "All", value: 0 }]
							: data?.country?.map((t) => ({
									value: t.id,
									label: t.name,
									code: t.country_code,
							  })),
					residence:
						data?.residences === null
							? [{ label: "All", value: 0 }]
							: data?.residences?.map((t) => ({
									value: t.country_code,
									label: t.name,
									code: t.country_code,
							  })),
					nationalities:
						data?.nationalities === null
							? [{ label: "All", value: 0 }]
							: data?.nationalities?.map((t) => ({
									value: t.country_code,
									label: t.name,
									code: t.country_code,
							  })),
					currency: {
						label: data?.search_payload?.currency_code,
						value: data?.search_payload?.currency_code,
						currency_code: data?.search_payload?.currency_code,
					},
					rooms: data?.rooms_count_payload?.map((room) => {
						return {
							number_of_adult: room?.number_of_adult,
							roomLength: room?.rooms_count,
							roomsList: [...Array(+room?.rooms_count).keys()].map((r) => {
								return {
									children: room?.children || [],
								};
							}),
							children: room?.children || [],
							BoxChild: false,
						};
					}),

					checkin: moment(data?.search_payload?.date_from),
					checkout: moment(data?.search_payload?.date_to),
					start_date: moment(data?.start_date),
					end_date: moment(data?.end_date),
					expiration_date: moment(data?.expiration_date),
				});
			}
		};
		getView();
	}, []);

	const MarketingPermissionManage = useShowPermission({
		permission: "Marketing-Offers-Management-Manage",
	});
	// check form errors before submit
	const checkFormErrors = () => {
		const validationsKeys = [
			"title",
			"destination",
			"residence",
			"nationalities",
			"provider",
			"currency",
			"country",
			"discount",
		];
		let roomError = [];
		let submitError = {};
		searchState.rooms.forEach((res) => {
			let newError = {
				...validate(
					{
						name: "roomLength",
						value: res.roomLength,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "number_of_adult",
						value: res.number_of_adult,
					},
					{
						required: true,
					}
				),
			};
			roomError.push(newError);
		});

		validationsKeys.forEach((key) => {
			submitError = {
				...submitError,
				...errors,
				...validate({ name: key, value: searchState[key] }, { required: true }),
			};
		});

		setErrors({
			rooms: roomError,
			...submitError,
		});
	};
	// check errors when submit the form
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	// check if there is errors then submit edited data
	useEffect(() => {
		if (Object.values(errors).length > 1) {
			const flag = errors.rooms.every((error) => {
				if (!isFormValid(error)) {
					return false;
				}
				return true;
			});
			if (isFormValid({ ...errors, rooms: { required: !flag } }) && flag) {
				handleEdit();
			}
		}
	}, [isErrorLoaded]);
	// autocompelete cities
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					//name: item.name + " - " + item.country.name[locale],
					name: item.name,
					value: item.id,
					label: item.name,
					country: item.country,
				};
			});

			setListAuto(result);
		}
	};

	return (
		<div className="search-hotels HotelSearch my-3">
			<div className="row align-items-center w-100 no-gutter m-0 view-genertaLink">
				<h3 className="col-12">{searchState?.title}</h3>
				<div className="genlink-box  row align-items-start mb-3 w-100 no-gutter m-0 view-genertaLink ">
					<div className="genlink-box-head col-12">
						<h4>{marketPlace.details}</h4>
					</div>
					{/* title */}
					<div className="col-6 ">
						<TextField
							type="text"
							label={marketPlace.Title}
							placeholder={marketPlace.Title}
							name="title"
							disabled={!MarketingPermissionManage}
							value={searchState?.title}
							onChange={(e) => {
								handleInputChanges(e.target.value, "title");
							}}
						/>
					</div>
					{/* search type */}
					<div className="col-6">
						<SelectField
							disabled={true}
							label={marketPlace.searchType}
							name="searchType"
							placeholder={marketPlace.searchType}
							options={searchTypeOptions}
							value={searchState?.is_offer}
							// onChange={(e) => {
							// 	handleInputChanges(e, "is_offer");
							// }}
						/>
					</div>
					{/* destination */}
					<div className="col-6 booking-autoComplete">
						<AutoCompleteField
							hasLabel={true}
							flag={searchState.destination?.country?.flag}
							listAuto={listAuto}
							setListAuto={setListAuto}
							getListAuto={getListAuto}
							disabled={!MarketingPermissionManage}
							isSearchable={true}
							placeholder={booking.Destination}
							label={booking.Destination}
							value={searchState.destination?.label}
							name="destination"
							onChange={(e) => {
								handleInputChanges(e, "destination");
							}}
							onSelectValue={(e) => {
								handleInputChanges(e, "destination", true);
							}}
							color={errors?.destination?.required ? "danger" : ""}
							errors={errors?.destination}
						/>
					</div>

					{/* hotels */}
					<div className="col-6 search-hotels-autoComp-feild">
						<AutoCompleteMultiField
							hasLabel={true}
							listAuto={listHotels}
							setListAuto={setListHotels}
							getListAuto={getHotelsListAuto}
							isSearchable={true}
							placeholder={marketPlace.Hotels}
							disabled={!MarketingPermissionManage}
							label={marketPlace.Hotels}
							value={searchState.hotelsName}
							ListValue={searchState.hotels?.map((hotel) => hotel?.id) || []}
							name="hotels"
							onChange={(e) => {
								handleInputChanges(e, "hotelsName");
							}}
							onSelectValue={(checked, e) => {
								let currentHotels = searchState.hotels;
								if (checked) {
									handleInputChanges([...currentHotels, e], "hotels");
								} else {
									let selectedHotelsId = currentHotels.filter(
										(res) => res?.value !== e.value
									);
									handleInputChanges(selectedHotelsId, "hotels");
								}
							}}
							color={errors?.hotels?.required ? "danger" : ""}
							errors={errors?.hotels}
						/>
						<div
							className="selected-hotels"
							style={{ height: "80px !important" }}
						>
							{searchState?.hotels?.map((hotel) => {
								return (
									<div className="hotel" title={hotel?.name}>
										<p className="hotel-name">{hotel?.name}</p>
										<i
											class="fas fa-times delete-hotel pointer"
											onClick={() => {
												let currentHotels = searchState.hotels;
												let selectedHotelsId = currentHotels.filter(
													(res) => res?.value !== hotel.value
												);
												handleInputChanges(selectedHotelsId, "hotels");
												// setSearchState({
												// 	...searchState,
												// 	hotels: selectedHotelsId,
												// });
											}}
										></i>
									</div>
								);
							})}
						</div>
					</div>

					{/* provider  */}
					<div className="col-6">
						<SelectField
							// disabled={searchState?.is_offer?.value === 1}
							disabled={true}
							label={marketPlace.Provider}
							placeholder={marketPlace.Provider}
							name="Provider"
							options={providersList}
							value={searchState?.provider}
							onChange={(e) => {
								handleInputChanges(e, "provider");
							}}
						/>
					</div>

					{/* checkin Date */}
					<div className="col-6">
						<DatePickerField
							type="text"
							date={searchState?.checkin}
							hasLabel={true}
							label={marketPlace.checkin}
							disabled={!MarketingPermissionManage}
							name="CheckIn"
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							onDateChange={(e) => {
								setSearchState({
									...searchState,
									checkin: e,
									checkout: moment(searchState.checkout).isAfter(
										moment(e).add(1, "d")
									)
										? searchState.checkout
										: moment(e).add(1, "d"),
								});
							}}
						/>
					</div>

					{/* checkout Date */}
					<div className="col-6">
						<DatePickerField
							type="text"
							date={searchState.checkout}
							hasLabel={true}
							disabled={!MarketingPermissionManage}
							label={marketPlace.Checkout}
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							onChangeDate={(e) => {
								setSearchState({
									...searchState,
									checkout: e,
								});
							}}
						/>
					</div>

					{/* Start Date */}
					<div className="col-6">
						<DatePickerField
							type="text"
							hasLabel={true}
							disabled={!MarketingPermissionManage}
							label={marketPlace.StartDate}
							date={searchState.start_date}
							name="StartDate"
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							onDateChange={(e) => {
								setSearchState({
									...searchState,
									start_date: e,
									end_date: moment(searchState.end_date).isAfter(
										moment(e).add(1, "d")
									)
										? searchState.end_date
										: moment(e).add(1, "d"),
								});
							}}
						/>
					</div>

					{/* End Date */}
					<div className="col-6">
						<DatePickerField
							type="text"
							hasLabel={true}
							disabled={!MarketingPermissionManage}
							label={marketPlace.EndDate}
							date={searchState.end_date}
							name="EndDate"
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							onDateChange={(e) => {
								setSearchState({
									...searchState,
									end_date: e,
								});
							}}
						/>
					</div>

					{/* Discount */}
					<div className="col-6 ">
						<TextField
							type="number"
							label={"Discount"}
							disabled={!MarketingPermissionManage}
							name="discount"
							value={searchState?.discount}
							onChange={(e) => {
								handleInputChanges(e.target.value, "discount");
							}}
							color={errors?.discount?.required ? "danger" : ""}
							errors={errors?.discount}
						/>
					</div>

					{/* expiration Date */}
					<div className="col-6">
						<DatePickerField
							type="text"
							hasLabel={true}
							label={marketPlace.expirationDate}
							disabled={!MarketingPermissionManage}
							date={searchState.expiration_date}
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							onDateChange={(e) => {
								handleInputChanges(e, "expiration_date");
							}}
						/>
					</div>
					<div className="row w-100 mb-2">
						{/* countries */}
						<div className="col-3 mt-3 " style={{ position: "relative" }}>
							<div
								className={`${errors?.country?.required ? "danger py-2" : ""}`}
							>
								<span>{marketPlace.country}</span>
								{!MarketingPermissionManage ? (
									<div
										style={{
											width: " 100%",
											height: "71%",
											position: "absolute",
											"z-index": "9",
											opacity: ".5",
										}}
									></div>
								) : null}
								<Select
									isMulti
									label={marketPlace.country}
									placeholder={marketPlace.country}
									options={lookups.countries}
									multi={true}
									name="country"
									onChange={(e) => {
										handleInputChanges(e, "country");
									}}
									value={searchState.country}
									color={errors?.country?.required ? "danger" : ""}
									errors={errors?.country}
									disabled={!MarketingPermissionManage}
									readonly={!MarketingPermissionManage}
								/>
							</div>
							<small
								className={`control-field__feedback control-field__feedback--danger d-block error-message`}
							>
								{errors?.country?.required ? "country is Required" : ""}
							</small>
						</div>

						{/* residence */}
						<div className="col-3 mt-3" style={{ position: "relative" }}>
							<div
								className={`${
									errors?.residence?.required ? "danger py-2" : ""
								}`}
							>
								{!MarketingPermissionManage ? (
									<div
										style={{
											width: " 100%",
											height: "71%",
											position: "absolute",
											"z-index": "9",
											opacity: ".5",
										}}
									></div>
								) : null}
								<span>{marketPlace.residence}</span>

								<Select
									isMulti
									label={marketPlace.residence}
									placeholder={marketPlace.residence}
									options={lookups.countries}
									readonly={!MarketingPermissionManage}
									id="basic-countries"
									name="residence"
									multi={true}
									onChange={(e) => {
										handleInputChanges(e, "residence");
									}}
									value={searchState.residence}
								/>
							</div>
							<small
								className={`control-field__feedback control-field__feedback--danger d-block error-message`}
							>
								{errors?.residence?.required ? "residence is Required" : ""}
							</small>
						</div>

						{/* nationality */}
						<div className="col-3 mt-3" style={{ position: "relative" }}>
							<div
								className={`${
									errors?.nationalities?.required ? "danger py-2" : ""
								}`}
							>
								{!MarketingPermissionManage ? (
									<div
										style={{
											width: " 100%",
											height: "71%",
											position: "absolute",
											"z-index": "9",
											opacity: ".5",
										}}
									></div>
								) : null}
								<span>{marketPlace.nationality}</span>
								<Select
									isMulti
									label={marketPlace.nationality}
									placeholder={marketPlace.nationality}
									options={lookups.countries}
									readonly={!MarketingPermissionManage}
									name="nationalities"
									multi={true}
									onChange={(e) => {
										handleInputChanges(e, "nationalities");
									}}
									value={searchState.nationalities}
								/>
							</div>
							<small
								className={`control-field__feedback control-field__feedback--danger d-block error-message`}
							>
								{errors?.nationalities?.required
									? "nationalities is Required"
									: ""}
							</small>
						</div>
						{/* currency */}
						<div className="col-3 mt-1">
							<SelectField
								multi={false}
								label={marketPlace.currency}
								name="currency"
								options={lookups.currencies}
								disabled={!MarketingPermissionManage}
								value={searchState?.currency}
								onChange={(e) => {
									handleInputChanges(e, "currency");
								}}
							/>
						</div>
					</div>
					{/**Start Rooms */}
					<div className="col-md-12  bg-Rooms py-3">
						<div className="col-md-11 m-auto">
							<div className=" product-build__product-collpase branch-collapse my-0 mb-2">
								{/* rooms */}
								<div className="row">
									{searchState?.rooms && searchState?.rooms.length > 0
										? searchState?.rooms?.map((res, index) => {
												return (
													<>
														<div
															className="col-md-11"
															style={{ padding: "0 15px" }}
														>
															<div className="button-collapse my-2">
																<div className="title-style-container">
																	<div
																		className="w-100"
																		onClick={() =>
																			setRoomsCount(
																				"BoxChild",
																				index,
																				!res.BoxChild
																			)
																		}
																	>
																		<div className="w-100">
																			<p className="global-input font-weight-bold border-0 ">
																				<span className="roomsNumber">
																					{res.roomLength} {"  "}
																					{marketPlace.messages.room}
																				</span>
																				<span className="adultNumber">
																					({res.number_of_adult}{" "}
																					{marketPlace.messages.adult} {"  "},
																					{res?.children?.length}{" "}
																					{marketPlace.messages.child}){" "}
																					{marketPlace.perroom}
																				</span>
																			</p>
																		</div>
																	</div>

																	<div className="icon-items-section d-flex align-items-center">
																		<i
																			onClick={() =>
																				setRoomsCount(
																					"BoxChild",
																					index,
																					!res.BoxChild
																				)
																			}
																			className={`fas fa-fw text-primary fa-lg ${
																				res.BoxChild !== true
																					? "fa-chevron-right "
																					: "fa-chevron-down"
																			}`}
																		></i>
																	</div>
																</div>
																{res.BoxChild ? (
																	<div className="row box-child">
																		<div
																			className="col-md-12"
																			style={{ padding: "0 15px" }}
																		>
																			<div className="row">
																				<div className="col-md-4 my-3">
																					<div className="container-centerLabel px-2">
																						<label htmlFor="">
																							{marketPlace.messages.rooms}
																						</label>
																					</div>
																				</div>
																				<div className="col-md-8">
																					<NumberField
																						placeholder={
																							marketPlace.messages.NoOfRooms
																						}
																						increase={() => {
																							setRoomsCount(
																								"roomLength",
																								index,
																								res.roomLength
																									? +res.roomLength + 1
																									: 0 + 1
																							);
																							setChildrenCount(
																								"children",
																								index,
																								0
																							);
																						}}
																						decrease={() => {
																							setRoomsCount(
																								"roomLength",
																								index,
																								res.roomLength - 1
																							);
																							setChildrenCount(
																								"children",
																								index,
																								0
																							);
																						}}
																						max={9}
																						min={1}
																						value={res.roomLength}
																						hasLabel={false}
																						label={marketPlace.messages.rooms}
																						name="roomLength"
																						type="number"
																						onChange={(e) => {
																							let removeZero = e.target.value;
																							if (e.target.value[0] == 0) {
																								removeZero =
																									e.target.value.substring(1);
																							}
																							setRoomsCount(
																								"roomLength",
																								index,
																								+removeZero > 9
																									? 9
																									: removeZero < 1
																									? 1
																									: removeZero
																							);
																							setChildrenCount(
																								"children",
																								index,
																								0
																							);
																						}}
																						color={
																							errors?.rooms[index]?.roomLength
																								?.required
																								? "danger"
																								: ""
																						}
																						errors={
																							errors?.rooms[index]?.roomLength
																						}
																						disabled={
																							!MarketingPermissionManage
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div
																			className="col-md-12"
																			style={{ padding: "0 15px" }}
																		>
																			<div className="row">
																				<div className="col-md-4 my-3">
																					<div className="container-centerLabel px-2">
																						<label
																							className="m-0 w-100"
																							htmlFor=""
																						>
																							{marketPlace.messages.adult}
																						</label>
																						<p className="small-title">
																							{marketPlace.ForEveryRoom}
																						</p>
																					</div>
																				</div>
																				<div className="col-md-8">
																					<NumberField
																						placeholder={
																							marketPlace.messages.NoOfAdults
																						}
																						disabled={
																							!MarketingPermissionManage
																						}
																						hasLabel={false}
																						label={marketPlace.messages.adult}
																						value={res.number_of_adult}
																						name="number_of_adult"
																						max={6}
																						min={1}
																						increase={() => {
																							setRoomsCount(
																								"number_of_adult",
																								index,
																								res.number_of_adult
																									? +res.number_of_adult + 1
																									: 0 + 1
																							);
																						}}
																						decrease={() => {
																							setRoomsCount(
																								"number_of_adult",
																								index,
																								res.number_of_adult - 1
																							);
																						}}
																						onChange={(e) => {
																							let removeZero = e.target.value;
																							if (e.target.value[0] == 0) {
																								removeZero =
																									e.target.value.substring(1);
																							}
																							setRoomsCount(
																								"number_of_adult",
																								index,
																								+removeZero > 6
																									? 6
																									: removeZero < 1
																									? 1
																									: removeZero
																							);
																						}}
																						type={"number"}
																						color={
																							errors?.rooms[index]
																								?.number_of_adult?.required
																								? "danger"
																								: ""
																						}
																						errors={
																							errors?.rooms[index]
																								?.number_of_adult
																						}
																					/>
																				</div>
																			</div>{" "}
																		</div>

																		<div
																			className="col-md-12"
																			style={{ padding: "0 15px" }}
																		>
																			<div className="row">
																				<div className="col-md-4 my-3">
																					<div className="container-centerLabel px-2">
																						<label
																							className="m-0 w-100"
																							htmlFor=""
																						>
																							{marketPlace.messages.child}
																						</label>
																						<p className="small-title">
																							{marketPlace.ForEveryRoom}
																						</p>
																					</div>
																				</div>
																				<div className="col-md-8">
																					<NumberField
																						placeholder={
																							marketPlace.messages.NoOfChildren
																						}
																						hasLabel={false}
																						label={marketPlace.messages.child}
																						name="rooms_count"
																						max={4}
																						min={0}
																						value={res?.children?.length.toString()}
																						increase={() => {
																							setChildrenCount(
																								"children",
																								index,
																								res.children.length
																									? +res.children.length + 1
																									: 0 + 1
																							);
																						}}
																						decrease={() => {
																							setChildrenCount(
																								"children",
																								index,
																								res.children.length - 1
																							);
																						}}
																						onChange={(e) => {
																							let removeZero = e.target.value;

																							if (e.target.value[0] == 0) {
																								removeZero =
																									e.target.value.substring(1);
																							}
																							setChildrenCount(
																								"children",
																								index,
																								+removeZero > 4
																									? 4
																									: removeZero < 0
																									? 0
																									: removeZero
																							);
																						}}
																						type={"number"}
																						color={
																							errors?.rooms[index]?.children
																								?.required
																								? "danger"
																								: ""
																						}
																						errors={
																							errors?.rooms[index]?.children
																						}
																					/>
																				</div>
																			</div>{" "}
																		</div>
																		<div
																			className="col-md-12"
																			style={{ padding: "0 15px" }}
																		>
																			{res?.roomsList &&
																			res?.roomsList?.length > 0 &&
																			res?.children &&
																			res?.children?.length > 0 ? (
																				<p className="text-danger font-weight-bold my-3">
																					{marketPlace.oldChildren}
																				</p>
																			) : (
																				""
																			)}
																			{res?.roomsList &&
																			res?.children?.length > 0 &&
																			res?.roomsList?.length > 0
																				? res.roomsList?.map(
																						(rese, indexRoom) => {
																							return (
																								<>
																									<div className="row">
																										<div className="col-md-3 my-4">
																											<div className="container-centerLabel px-2">
																												<label
																													className="m-0 w-100"
																													htmlFor=""
																												>
																													{
																														marketPlace.messages
																															.room
																													}{" "}
																													{+indexRoom + 1}
																												</label>
																											</div>
																										</div>
																										<div className=" row col-9">
																											{rese?.children &&
																											rese?.children.length > 0
																												? rese?.children.map(
																														(
																															child,
																															indexChild
																														) => {
																															return (
																																<>
																																	<div className="col-md-4 ">
																																		<NumberField
																																			placeholder={`${
																																				marketPlace
																																					.messages
																																					.child
																																			} ${
																																				indexChild +
																																				1
																																			}`}
																																			hasLabel={
																																				false
																																			}
																																			value={
																																				child.age
																																			}
																																			max={12}
																																			min={1}
																																			name="setAge"
																																			onChange={(
																																				e
																																			) => {
																																				let removeZero =
																																					e
																																						.target
																																						.value;
																																				setAge(
																																					index,
																																					indexRoom,
																																					indexChild,
																																					+removeZero >
																																						12
																																						? 12
																																						: removeZero <
																																						  1
																																						? 1
																																						: removeZero
																																				);
																																			}}
																																			type={
																																				"number"
																																			}
																																			color={""}
																																			errors={
																																				""
																																			}
																																			disabled={
																																				!MarketingPermissionManage
																																			}
																																			onEnter={(
																																				e
																																			) =>
																																				false
																																			}
																																			increase={() => {
																																				setAge(
																																					index,
																																					indexRoom,
																																					indexChild,
																																					+child.age +
																																						1
																																				);
																																			}}
																																			decrease={() => {
																																				setAge(
																																					index,
																																					indexRoom,
																																					indexChild,
																																					+child.age -
																																						1
																																				);
																																			}}
																																		/>
																																	</div>
																																</>
																															);
																														}
																												  )
																												: ""}
																										</div>
																									</div>
																								</>
																							);
																						}
																				  )
																				: ""}
																		</div>
																	</div>
																) : null}
															</div>
														</div>

														<div className="col-md-1">
															{index > 0 ? (
																<span
																	className="span-remove"
																	onClick={() => {
																		let rooms = [...searchState?.rooms];
																		rooms.splice(index, 1);
																		setSearchState({
																			...searchState,
																			rooms: rooms,
																		});
																	}}
																>
																	<i class="fas fa-times"></i>
																</span>
															) : null}
														</div>
													</>
												);
										  })
										: null}
								</div>

								<div className="col-md-11 mt-1" style={{ height: "10px" }}>
									<ShowForPermission permission="Marketing-Offers-Management-Manage">
										<p className="text-success  text-right">
											<span
												onClick={() => {
													let x = searchState?.rooms;
													x.push({});
													setSearchState({ ...searchState, rooms: x });
													setRoomsCount(
														"roomLength",
														+searchState?.rooms?.length - 1,
														1
													);
													setRoomsCount(
														"number_of_adult",
														+searchState?.rooms?.length - 1,
														2
													);
												}}
												style={{ cursor: "pointer" }}
											>
												{marketPlace.AddNew}
												<i class="fas fa-plus mx-1"></i>{" "}
											</span>
										</p>
									</ShowForPermission>
								</div>
							</div>
						</div>
					</div>
					{/**End Rooms */}
				</div>
			</div>

			<div className="mt-3 d-flex justify-content-end pb-5 mb-5">
				<div className="mx-2">
					<Link
						className={`btn btn-sbs-secondary`}
						to={`/marketing-offers-list`}
					>
						{backOffice.back}
					</Link>
				</div>

				<div className="d-flex">
					<ShowForPermission permission="Marketing-Offers-Management-Manage">
						<button
							className="btn btn-primary mx-1 px-5 mb-2"
							onClick={() => submit()}
						>
							Edit
						</button>
					</ShowForPermission>
				</div>
			</div>
		</div>
	);
}
export default ViewMarketingOffer;
