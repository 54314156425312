import { useParams } from "react-router-dom";
import Locale from "translations";
export default function PlatformCommissionSelector({
	selectorsSelected,
	countries,
	season,
}) {
	const { backOffice } = Locale;
	const { id } = useParams();

	return (
		<div className="row custom--row">
			{selectorsSelected?.country !== "" && selectorsSelected?.country ? (
				<div className="col-12 mb-3">
					<div className="item--display light-bg p-3">
						<div className="item--key">{backOffice.country}</div>
						<div className="item--value">
							{selectorsSelected?.country === "all"
								? "All"
								: selectorsSelected?.country?.map((item, index) => {
										if (id) {
											let countryObject = countries.find(
												(country) => country.value === item.country_id
											);
											return selectorsSelected?.country?.length - 1 === index
												? countryObject.label
												: countryObject.label + " , ";
										} else {
											let countryObject = countries.find(
												(country) => country.value === item
											);
											return selectorsSelected?.country?.length - 1 === index
												? countryObject.label
												: countryObject.label + " , ";
										}
								  })}
						</div>
					</div>
				</div>
			) : null}

			{selectorsSelected?.season !== "" && selectorsSelected?.season ? (
				<div className="col-12 mb-3">
					<div className="item--display light-bg p-3">
						<div className="item--key">{backOffice.season}</div>
						<div className="item--value">
							{selectorsSelected?.season === "all"
								? "All"
								: selectorsSelected?.season?.map((item, index) => {
										if (id) {
											let seasonObject = season.find(
												(itemSeason) => itemSeason.value === item.id
											);
											return selectorsSelected?.season?.length - 1 === index
												? seasonObject.label
												: seasonObject.label + " , ";
										} else {
											let seasonObject = season.find(
												(itemSeason) => itemSeason.value === item
											);
											return selectorsSelected?.season?.length - 1 === index
												? seasonObject.label
												: seasonObject.label + " , ";
										}
								  })}
						</div>
					</div>
				</div>
			) : null}

			{selectorsSelected?.hotel !== "" && selectorsSelected?.hotel ? (
				<div className="col-12">
					<div className="item--display light-bg p-3">
						<div className="item--key">{backOffice.hotelLevel}</div>
						<div className="item--value">
							{selectorsSelected?.hotel === "all"
								? "All"
								: selectorsSelected?.hotel?.map((item, index) => {
										if (id) {
											return selectorsSelected?.hotel?.length - 1 === index
												? item?.name?.en
												: item?.name?.en + " , ";
										} else {
											return selectorsSelected?.hotel?.length - 1 === index
												? item
												: item + " , ";
										}
								  })}
						</div>
					</div>
				</div>
			) : null}
			{selectorsSelected?.company !== "" && selectorsSelected?.company ? (
				<div className="col-12 mt-3">
					<div className="item--display light-bg p-3">
						<div className="item--key">{backOffice.company}</div>
						<div className="item--value">
							{selectorsSelected?.company === "all"
								? "All"
								: selectorsSelected?.company?.map((item, index) => {
										if (id) {
											return selectorsSelected?.company?.length - 1 === index
												? item?.name?.en
												: item?.name?.en + " , ";
										} else {
											return selectorsSelected?.company?.length - 1 === index
												? item
												: item + " , ";
										}
								  })}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}
