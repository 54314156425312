import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { commercialDistToursView } from 'services/commercialDistribution';
import ToursBookingDetails from "./BookingDetails";
import ToursBookingSummary from "./BookingSummary";

export default function CommercialDistToursReservationView() {
	const [bookingDetails, setBookingDetails] = useState(null);
	const { id, reservation_id } = useParams();
	useEffect(() => {
		const fetchTour = async () => {
			const res = await commercialDistToursView(id, reservation_id);
			if (res?.status === 200) {
				setBookingDetails(res.data?.data);
			}
		};
		fetchTour();
		return () => { };
	}, [id, reservation_id]);

	return (
		<>
			<div className='me-5  transfer d-flex align-items-center justify-content-between'>
				<div className='Booking-Details-tab'>Booking Details</div>
			</div>
			<div className="row bg-white tour-details transfer ">
				<div className="col-8">
					<ToursBookingDetails
						bookingDetails={bookingDetails}
						key={bookingDetails?.brn}
						setBookingDetails={setBookingDetails}
					/>
				</div>
				<div className="col-4">
					<ToursBookingSummary
						bookingDetails={bookingDetails}
						setBookingDetails={setBookingDetails}
					/>
				</div>
			</div>
		</>
	);
}
