import hotelImg from "assets/images/Hotelcircel.svg";
import flightImg from "assets/images/flight.svg";
import toursImg from "assets/images/tours.svg";
import transfersImg from "assets/images/transfers.svg";

function ImgServiec({name,className}) {
  const imgSrc = (() => {
    switch (name) {
      case 'hotels':
     return 	hotelImg	
      break;
      case 'transfers':
      return		transfersImg
      break;
      case 'flight':
        return	flightImg
        break;
      case 'tours':
        return	toursImg
        break;
      case 'transportation':
        return	transfersImg
        break;
      default:
       return	""		
    }
  })();
  return (
    <img src={imgSrc} alt={name} className={className}  />
  )
}

export default ImgServiec