export const TentativePDF = `
<style>
			.w-25 {
				width: 25% !important;
			}

			.w-50 {
				width: 50% !important;
			}

			.w-75 {
				width: 75% !important;
			}

			.w-100 {
				width: 100% !important;
			}

			.mr-3,
			.mx-3 {
				margin-right: 1rem !important;
			}

			.ml-3,
			.mx-3 {
				margin-left: 1rem !important;
			}

			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}

			.mb-3,
			.my-3 {
				margin-bottom: 1rem !important;
			}
			.mb-1 {
				margin-bottom: .2rem !important;
			}
			* {
				font-family: Helvetica;
			}

			.bg-gray-100 {
				background: #f4f4f4 !important;
			}

			.container {
				max-width: 1140px;
			}

			.ml-5,
			.mx-5 {
				margin-left: 3rem !important;
				margin-right: 3rem;
			}

			.pt-2,
			.py-2 {
				padding-top: 0.5rem !important;
			}

			.d-flex {
				display: flex !important;
			}

			.text-center {
				text-align: center !important;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.align-items-center {
				align-items: center;
			}

			.nav {
				background-color: #ebebeb;
				border-bottom: 1px solid #a7a7a7;
				padding: 1rem 0 1rem 1rem;
			}
			.hero-section {
				padding: 0.5rem 1rem;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
			}

			.v-label {
				transform: rotate(342deg);
			}

			.border-bottom {
				border-bottom: 1px solid #0c3b5c;
			}
			.border-bottom-bold {
				border-bottom: 2px solid #0c3b5c;
			}
			.sm-font {
				font-size: 13px;
				color: #2d2d2d;
				font-weight: 600;
			}

			.sm-font-light {
				font-size: 13px;
				color: #2d2d2d;
				font-weight: lighter;
			}

			table {
				width: 100%;
			}

			.table,
			.th,
			.td {
				border: 1px solid #0c3b5c;
				border-collapse: collapse;
			}

			.th {
				color: #2d2d2d;
				font-weight: bold;
				padding: 10px;
			}

			.td {
				color: #2d2d2d;
				font-weight: lighter;
				font-size: 13px;
				padding: 11px;
				text-align: center;
			}

			ul {
				list-style: none;
			}

			.footer {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 1rem;
				text-align: center;
				font-size: 14px;
			}

			.main-color {
				color: #0c3b5c;
			}
			.nav1 {
				justify-content: center;
				padding: 12px 0;
			}
			.font-bold{
				font-weight: 800 !important;
			}
			.justify-content-start{
				justify-content: flex-start !important;
			}
			.py-5 {
				padding-bottom: 2.5rem !important;
				padding-top: 2.5rem !important;
			}
			.py-3 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}
			.my-3 {
				margin-top: 1rem !important;
				margin-bottom: 1rem !important;
			}
			.my-1 {
				margin-top: 2rem !important;
				margin-bottom: 2rem !important;
			}
			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}
			@page {
				size: A4;
				margin:15px
				
			}
			@media print {
				.container{
					width: 100% !important;
					border: 1px solid #0c3b5c;
				}
				.border-bottom{
					height:13vh !important;
				}
			}
			.font-bold-700{
				font-weight: 700 !important;
				text-decoration: underline
			}
			.bankAcc ul{
				display: flex;
    flex-direction: column;
		gap: 4px
			}

			.allRoom {
				color:#fff
				}
				.mb-2 {
					margin-bottom: 0.5rem;
				}
		</style>
		<div class="tentative_pdf">
		<div
			class="container"
			style="
				background: #fff;
				width: 900px !important;
				margin: 25px auto !important;
				border: 1px solid #0c3b5c;
			"
		>
			<nav class="nav1 d-flex">
				<img
					src=[SafaLogo]
					alt="safa_logo"
					style="width: 187.93px; height: 42.45px"
				/>
			</nav>

			<div class="hero-section border-bottom d-flex">
				<table class="mx-3" style="margin-bottom:25px">
				

				<td style="width: 10%; text-align: left">
						<ul>
							<li class="sm-font-light font-bold">Date</li>
							<li class="sm-font-light  font-bold">From</li>

							<li class="sm-font-light mb-1 font-bold">To</li>
							
						</ul>
					</td>
					<td style="width: 30%; text-align: left">
						<ul>
							<li class="sm-font-light font-bold ">: [Date]</li>
							
							<li class="sm-font-light font-bold ">: [From]</li>
							<li class="sm-font-light font-bold mb-1">: [To]</li>
							
						</ul>
					</td>

				
					<td style="width: 55%; text-align: center; margin-bottom:250px">
						<h3 class="v-label"><span style="text-transform: capitalize;">[status]</span> Confirmation</h3>
					</td>
				</table>
			</div>
			<div class="hero-section border-bottom d-flex justify-content-start py-3">
				<table class="mx-3">
					<td style="width: 10%; text-align: left">
						<ul>
							<li class="sm-font-light font-bold">Res.NO</li>
							<li class="sm-font-light mb-1 font-bold">Arrival date</li>
							<li class="sm-font-light font-bold">Guest Name</li>
							
							
						</ul>
					</td>
					<td style="width: 30%; text-align: left">
						<ul>
							<li class="sm-font font-bold">:[resNo]</li>
							<li class="sm-font mb-1">:[depart_date]</li>
							<li class="sm-font">:[guest_name]</li>
							
						</ul>
					</td>
					<td style="width: 13%; text-align: left">
						<ul>
							<li class="sm-font-light font-bold">Hotel Name</li>
							<li class="sm-font-light font-bold">Depart date</li>
							<li class="sm-font-light font-bold">Confirmation No.</li>
						</ul>
					</td>
					<td style="width: 30%; text-align: left">
						<ul>
							<li class="sm-font">:[hotel_name]</li>
							<li class="sm-font">:[arrival_date]</li>
							<li class="sm-font">:[confirmation_number]</li>
						</ul>
					</td>
				</table>
			</div>
			<div class="allRoom">
			<table class="table" style="width: 100%">
				<thead>
					<tr>
						<th class="th">QTY</th>
						<th class="th">Room Type</th>
						<th class="th">Room Rate</th>
						<th class="th">Room View</th>
						<th class="th">Meal</th>
					</tr>
				</thead>
				<tbody>
				[allRooms]
				</tbody>
			</table>
			</div>
			<div class="hero-section border-bottom-bold d-flex py-5">
				<p class="font-bold">Total Net Value: [net_currency]</p>
				<h6 style="font-size:24px">[net_amount] [net_currency1]</h6>
			</div>
			<div class="hero-section border-bottom-bold">
				<p>Option Date : Please Send Bank Transer by [option_date]</p>
			</div>

			[remarks]
			<div class="hero-section border-bottom-bold py-1">
				<p>
					We Hope That we have covered all your request we have covered all your
					request
				</p>
			</div>
			<div>

			<div class="bankAcc">
			<table class="mx-3" style="margin-top:15px">
			

			<td style="width: 5%; text-align: left">
					<ul>
						<li class="mb-2 font-bold-700">* Our Bank Acc</li>
						<li class="mb-1">Beneficiary</li>
						<li class="mb-1"> Bank Name</li>
						<li class="mb-1">Branch</li>
						<li class="mb-1">Account No</li>
						<li class="mb-1">Swift Code</li>
						<li class="mb-1">IBAN </li>
					</ul>
				</td>
				<td style="width: 30%; text-align: left">
					<ul>
					<li class="mb-2"></li>
						<li class="mb-1">:  شركة صفا لخدمات الاعمال </li>
						<li class="mb-1">:  Alinma Bank </li>
						<li class="mb-1">:  Jaddah </li>
						<li class="mb-1">:  68202564407000 </li>
						<li class="mb-1">:  INMASARI</li>
						<li class="mb-1">:  SA7305000068202564407000 </li>
						
					</ul>
				</td>

			
			
			</table>



			</div>
				<div
					class="border-bottom mx-2"
					style="height: 40vh; display: flex; align-items: center"
				>
					<table style="text-align: right" class="mx-5">
						<tr>
							<td>
								<p style="color: #2d2d2d">Thanks and Best Regards</p>
							</td>
						</tr>
						<tr>
							<td>
								<h4 style="font-weight: 600; color: #2d2d2d;text-transform: capitalize;">[user_name]</h4>
							</td>
						</tr>
						<tr>
							<td>
								
							</td>
						</tr>
					</table>
				</div>
			</div>
			<table class="footer">
				<tr>
					<td>
						<span class="sm-font-light">Website:</span
						><span class="sm-font">https://safalive.com</span>
					</td>
				</tr>
				<tr>
					<td>
						<span class="sm-font-light">E-mail:</span
						><span class="sm-font">res@safasoft.com</span>
					</td>
				</tr>
			</table>
		</div>
</div>
`;
