import Locale from "translations";

export default function Rooms({ bookingData }) {
	const { backOffice, booking } = Locale;

	return (
		<>
			<div className="border mt-2">

				<div className=" table-responsive">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">
								<th>{booking.RoomNo}</th>
								<th>{booking.Arrival}</th>
								<th>{booking.Departure}</th>
								<th>{booking.PassengerName}</th>
								<th>{booking.RoomType}</th>
								<th>{booking.Adult}</th>
								<th>{booking.Child}</th>
								<th>{booking.BoardBasis}</th>
								<th>{booking.Price}</th>
								{/* <th>{booking.Actions}</th> */}
							</tr>
						</thead>

						<tbody>
							{bookingData?.rooms?.length > 0 ?
								bookingData?.rooms?.map((room, index) => {
									return (
										<tr>
											<td>#{index + 1}</td>
											<td>{room.from_date}</td>
											<td>{room.to_date}</td>
											<td>
												{room?.passengers?.length > 0 ?
													<span>
														{room?.passengers[0]?.first_name}{" "}
														{room?.passengers[0]?.last_name}
													</span>
													:
													""
												}
											</td>
											<td>{room?.name}</td>
											<td>{room?.total_adults}</td>
											<td>{room?.total_children}</td>
											<td>
												{room?.meal_details?.meal_option_name}
											</td>
											<td>
												{room?.total_price} {bookingData?.currency}
											</td>
										</tr>
									)
								})
								:
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
