import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Locale from "translations";
import PriorityModel from "../List-Booking-Package/Models/PriorityModel";

function ActionsBar({
	reservationNum,
	priority,
	getPackageData,
	reload,
	setReload,
}) {
	const { backOffice } = Locale;
	const { id } = useParams();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedReservations, setSelectedReservations] = useState([
		reservationNum,
	]);
	useEffect(() => {
		if (selectedReservations?.length === 0) {
			setSelectedReservations([reservationNum]);
		}
	}, [isOpen]);

	useEffect(() => {
		if (reload) {
			getPackageData();
		}
	}, [reload]);

	const togglePriorityModal = () => {
		setIsOpen(!isOpen);
	};
	return (
		<>
			<div className="header-filter-crm rounded mb-4">
				<div className="left-btns">
					<Link className="btn btn-header" to={`/b2c-redirect/${id}`}>
						<i class="fas fa-random"></i> {backOffice.Redirect}
					</Link>
					<Link className="btn btn-header" to={`/b2c-change/${id}`}>
						<i class="fas fa-exchange-alt"></i> {backOffice.ChangePackage}
					</Link>
					<button className="btn btn-header" onClick={togglePriorityModal}>
						<i class="fas fa-exclamation-triangle"></i> {backOffice.Priority}
					</button>
				</div>
				{/* <div className="right-btns">
					<button className="btn btn-header">
						<i class="fas fa-print"></i> Print
					</button>
				</div> */}
			</div>
			{isOpen && (
				<PriorityModel
					toggle={togglePriorityModal}
					isOpen={isOpen}
					select={selectedReservations}
					setselect={setSelectedReservations}
					initialPriority={priority}
					setReload={setReload}
				/>
			)}
		</>
	);
}

export default ActionsBar;
