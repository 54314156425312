import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { Link } from "react-router-dom";

export default function User({ user, changePassword, removeUser }) {
	const { allCountries } = useGlobalState();
	// const { allCountries } = useGlobalState();
	// console.log(allCountries);
	return (
		<tr>
			<td>{user?.name}</td>
			<td>{allCountries?.find((i) => i.id == user?.country_id)?.names?.en}</td>
			<td>{user.email}</td>
			<td>{user.phone}</td>
			<td>{user.created_at}</td>
			<td
				className={`${user?.status === "active" ? "definite" : "text-danger"}`}
			>
				{user?.status}
			</td>

			<td>
				<Link to={`/b2c-users/${user.id}/profile`}>
					<i class="fas mx-1 fa-eye text-info pointer" />
				</Link>
				<ShowForPermission permission="B2C-Users-Manage">
					<i
						class="fas fa-lock text-pending px-2 pointer"
						onClick={() => changePassword(user.id)}
					/>
				</ShowForPermission>
				<Link to={`/b2c-users/activity-log/${user.id}`}>
					<i class="fas fa-database pointer text-primary" />
				</Link>
				<ShowForPermission permission="B2C-Users-Manage">
					<i
						class="fas fa-trash px-2 text-danger pointer"
						onClick={() => removeUser(user.id)}
					/>
				</ShowForPermission>
			</td>
		</tr>
	);
}
