import resetIcon from "assets/images/wallet/resetIcon.png";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import Locale from "translations";

function FilterTransfers({ setFilter, filter }) {
	const { backOffice, booking } = Locale;
	const statusOptions = [
		{ value: "CONFIRMED", label: "CONFIRMED" },
		{ value: "PENDING", label: "PENDING" },
		{ value: "EXPIRED", label: "EXPIRED" },
		{ value: "CANCELED", label: "CANCELED" },
	];
	const sourceOption = [
		{ value: "website", label: "Website" },
		{ value: "marketplace", label: "Marketplace" },
	];
	return (
		<div className=" main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col  date-range-input date-range-input-right-50">
					<p className="mb-0 mt-2">Confirmation Date</p>
					<DateRangeField
						hasLabel={false}
						startDatePlaceholder={"Start Date"}
						readOnly={true}
						endDatePlaceholder={"End Date"}
						startDate={filter.booking_date_from}
						endDate={filter.booking_date_to}
						onDatesChange={({ startDate, endDate }) => {
							endDate = endDate
								? moment(endDate).format("YYYY-MM-DD")
								: moment(startDate).add(0, "d").format("YYYY-MM-DD");
							setFilter({
								...filter,
								booking_date_from: moment(startDate).format("YYYY-MM-DD"),
								booking_date_to: endDate,
								// page: null
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={"Source"}
						label={"Source"}
						name="source"
						options={sourceOption}
						value={filter.source}
						onChange={(e) => {
							setFilter({
								...filter,
								source: e,
								page: null,
							});
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={"Status"}
						label={"Status"}
						name="status"
						options={statusOptions}
						value={filter.Status}
						onChange={(e) => {
							console.log(e);
							setFilter({
								...filter,
								Status: e,
							});
						}}
					/>
				</div>
				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={() => {
							setFilter({
								search: "",
								confirmationDate: null,
								FreeCancelationDate: null,
								Status: null,
								page: 1,
								booking_date_from: null,
								booking_date_to: null,
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FilterTransfers;
