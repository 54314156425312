import ShowForPermission from "helpers/showForPermission";
import Locale from "translations";

export default function Request({
	request,
	toggleView,
	acceptRequst,
	rejectRequst,
}) {
	const { translate_companies, backOffice, wallet } = Locale;

	return (
		<tr>
			<td>{request?.receipt_number}</td>
			<td>{request?.request_type} </td>
			<td>{request?.amount} </td>
			<td>{request?.action_type}</td>
			<td>{request?.created_at}</td>
			<td className="text-warning">{request?.status}</td>
			<td className="d-flex col justify-content-end align-items-center ">
				<ShowForPermission permission="Vendors-Management-Manage">
					<button
						type="button"
						class="btn btn-success mx-1 font-weight-bolder"
						onClick={() => {
							acceptRequst(request.id, request.receipt_number);
						}}
					>
						{translate_companies.confirm}
					</button>
					<button
						type="button"
						class="btn btn-danger mx-1 font-weight-bolder"
						onClick={() => {
							rejectRequst(request.id, request.receipt_number);
						}}
					>
						{backOffice.rejected}
					</button>
				</ShowForPermission>
				<i
					class="fas fa-eye mx-1 "
					onClick={() =>
						toggleView(request.payment_card_id, request.id, "request")
					}
				></i>
			</td>
		</tr>
	);
}
