import TextField from "components/Form/TextField/TextField";
import Model from "components/model";
import DatePickerField from "components/shared/DatePickerField";
import { formatFetchReservationView } from "data/sbsREservaiton";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { TentativeConfirmReservation } from "services/sbsReservations";
import { RemoveIcon } from "./DetailsIcons";
import { roomsTotals } from "./helper";

//////////////////////////////// UpdateTentativeModal ///////////////////////////////

export const UpdateTentativeModal = (props) => {
	const { modalState, toggleModel, details, setReservationDetails } = props;
	const { totalSellingPrice } = roomsTotals(details);
	const refundOrAmend = totalSellingPrice - details?.oldTotalPrice;

	let { url } = useRouteMatch();
	const { push } = useHistory();

	const initState = {
		option_date: new Date(),
		amount: refundOrAmend,
		key: window.self.crypto.randomUUID(),
	};

	const [selected, setSelected] = useState("full");

	const [tentativeState, setTentativeState] = useState([
		{
			option_date: new Date(),
			amount: 0,
		},
	]);

	const [refundState, setRefundState] = useState([{ amount: 0 }]);

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [totalPriceNotEqualPayments, setTotalPriceNotEqualPayments] =
		useState(false);

	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (modalState) {
			setTentativeState([initState]);
		}

		return () => {};
	}, [modalState]);

	const { id } = useParams();

	const handleTentativeState = ({ value, name, index }) => {
		let updatedTentativeState = [...tentativeState];
		let cuurentTentativeState = {
			...updatedTentativeState[index],
			[name]: name === "option_date" ? value._d : value,
		};
		updatedTentativeState[index] = cuurentTentativeState;

		setTentativeState(updatedTentativeState);
		setTotalPriceNotEqualPayments(false);
	};

	const removePaid = (index) => {
		let updatedTentativeState = [...tentativeState];
		updatedTentativeState.splice(index, 1);
		// updatedTentativeState.forEach(
		// 	(i) => (i.amount = amount / updatedTentativeState.length)
		// );
		setTentativeState(updatedTentativeState);
	};

	const addNewPaid = () => {
		let updatedTentativeState = [...tentativeState];
		const newRoom = {
			key: window.self.crypto.randomUUID(),
			option_date: new Date(),
			amount: 0,
		};
		updatedTentativeState.push(newRoom);
		// updatedTentativeState.forEach(
		// 	(i) => (i.amount = amount / updatedTentativeState.length)
		// );
		setTentativeState(updatedTentativeState);
	};

	const fullPayment = selected === "full";
	const partialPayment = selected === "partial";
	const tentativeFieldsCount = tentativeState.length;

	const checkFormErrors = () => {
		const errorCheker = tentativeState.map((i) => ({
			...validate(
				{ name: "option_date", value: i.option_date },
				{ required: true }
			),
			...validate(
				{ name: "amount", value: i.amount },
				{
					required: true,
				}
			),
		}));
		console.log(errorCheker);
		setErrors([...errorCheker]);
	};

	const onComplete = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const ConfirmReservation = async (details) => {
		const res = await TentativeConfirmReservation(id, {
			...details,
		});

		if (res.status == 200) {
			console.log(res);
			const data = formatFetchReservationView(res.data.data);
			setReservationDetails(data);
			toggleModel();
			setSelected("full");
			setTentativeState([initState]);
		}
	};

	useEffect(() => {
		let totalPayment = 0;
		tentativeState.forEach((i) => {
			totalPayment += i.amount;
		});
		console.log(totalPayment);
		if (refundOrAmend > 0) {
			// if (totalSellingPrice !== totalPayment) {
			// 	setTotalPriceNotEqualPayments(true);
			// 	return;
			// }
		}
		if (Object.values(errors).length > 0) {
			const rooms = details.rooms.map((i) => ({
				id: i.id,
				net: i.net_rate,
				selling: i.selling_rate,
			}));
			const data = {
				supplier_id: details?.supplier?.value,
				hotel_id: details?.supplier_hotel?.value,
				rooms,
				payments: tentativeState,
			};

			const flag = errors.every((error) => {
				if (!isFormValid(error)) {
					return false;
				}
				return true;
			});

			if (flag) {
				// ConfirmReservation(data);
				let updatedPayments = [...details?.["payments"]];
				if (refundOrAmend > 0) {
					const updatepayment = tentativeState.map((res) => {
						return {
							key: res?.key,
							amount: res.amount,
							action: "payment",
							type: "add",
							status: "pending",

							option_date: moment(res.option_date).format("YYYY-MM-DD"),
						};
					});

					updatedPayments = [...updatedPayments, ...updatepayment];
					setReservationDetails({ ...details, payments: updatedPayments });
					toggleModel();

					push(`${url}/payments`);
				} else {
					if (selected == "Free") {
						const updatepayment = {
							...tentativeState[0],
							amount: Math.abs(tentativeState[0].amount),
							action: "refund",
							type: "deduct",
							status: "confirmed",
							option_date: moment(tentativeState[0].option_date).format(
								"YYYY-MM-DD"
							),
						};
						updatedPayments.push(updatepayment);
						setReservationDetails({ ...details, payments: updatedPayments });

						toggleModel();
						push(`${url}/payments`);
					} else if (selected == "Non-Refundable") {
						const updatepayment = {
							...tentativeState[0],
							amount: +tentativeState[0].amount,
							action: "refund Fees",
							type: "deduct",
							status: "confirmed",
							option_date: moment(tentativeState[0].option_date).format(
								"YYYY-MM-DD"
							),
						};
						updatedPayments.push(updatepayment);
						setReservationDetails({ ...details, payments: updatedPayments });
						toggleModel();
						push(`${url}/payments`);
					} else if (selected == "Amount") {
						const updatepayment = [
							{
								...tentativeState[0],
								amount: Math.abs(
									+tentativeState[0].amount + +refundState?.amount
								),
								action: "refund Fees",
								type: "deduct",
								status: "confirmed",
								option_date: moment(tentativeState[0].option_date).format(
									"YYYY-MM-DD"
								),
							},
							{
								...tentativeState[0],
								amount: +refundState?.amount,
								action: "refund",
								type: "deduct",
								status: "confirmed",
								option_date: moment(tentativeState[0].option_date).format(
									"YYYY-MM-DD"
								),
							},
						];

						updatedPayments = [...updatedPayments, ...updatepayment];
						setReservationDetails({ ...details, payments: updatedPayments });
						toggleModel();
						push(`${url}/payments`);
					}
				}
			}
		}

		return () => {};
	}, [isErrorLoaded]);

	return (
		<Model
			isOpen={modalState}
			// type={tentativeModal.type}
			title={"Edit Reservations"}
			toggle={() => {
				toggleModel();
				setSelected("full");
				setTentativeState([initState]);
			}}
			size="md"
			hasFooter={false}
		>
			<div className="w-100 px-5 reserv_modal row">
				<div className="reserv_modal_new_price calc-width  my-3">
					<p>Total New Price</p>
					<p>{`${totalSellingPrice}  ${details?.currency}`}</p>
				</div>

				<div
					className={` ${
						refundOrAmend > 0
							? "reserv_modal_total_price"
							: "reserv_modal_danger_price"
					}   my-3 calc-width`}
				>
					<p> {refundOrAmend > 0 ? "Amend Charge" : "Refund"} </p>
					<p>{`${refundOrAmend} ${details?.currency}`}</p>
				</div>
				{totalPriceNotEqualPayments && (
					<small className="control-field__feedback error-message d-block mb-2">
						Total Price Must be Equal Total Payments Amount
					</small>
				)}

				{/*///////////// edit with add Inputs ///////////////*/}

				{refundOrAmend > 0 ? (
					<>
						{/*///////////// Radio Inputs ///////////////*/}
						<fieldset className="name-check d-flex justify-content-between form-group w-100">
							<div
								className="reserv_modal_radio form-check"
								onClick={() => {
									setSelected("full");
									setTentativeState([initState]);
								}}
							>
								<label
									htmlFor={"full-payment"}
									className={`pointer p-0 ${
										fullPayment ? "pointer-checked" : ""
									} form-check-label`}
								>
									<input
										className="form-check-input"
										type="radio"
										disabled={false}
										checked={fullPayment}
										name={"full-payment"}
										id={"full-payment"}
									/>
									Full Payment
								</label>
							</div>
							<div
								className="reserv_modal_radio form-check"
								onClick={() => {
									setSelected("partial");
									selected === "full" && addNewPaid();
								}}
							>
								<label
									htmlFor={"partial-payment"}
									className={`pointer p-0 ${
										partialPayment ? "pointer-checked" : ""
									} form-check-label`}
								>
									<input
										className="form-check-input"
										type="radio"
										disabled={false}
										checked={partialPayment}
										name={"partial-payment"}
										id={"partial-payment"}
									/>
									Partial Payment
								</label>
							</div>
						</fieldset>

						{/*///////////// Form Fields ///////////////*/}

						{tentativeState.map((row, index) => {
							const indexedTentativeState = tentativeState[index];
							const indexedError = errors?.[index];
							return (
								<div
									key={row.option_date}
									className="row m-0 modal-body px-0 reserv_modal_payment_fields"
								>
									<div
										className={`p-0 ${
											fullPayment ? "col-12 w-100" : "col-6 calc-width"
										}`}
									>
										<DatePickerField
											type="text"
											placeholder={"DD/MM/YYYY"}
											label={"Option Date"}
											date={moment(indexedTentativeState.option_date)}
											onChangeDate={(date) =>
												handleTentativeState({
													value: date,
													name: "option_date",
													index,
												})
											}
											isOutsideRange={(day) => {
												return false;
											}}
										/>
									</div>
									{partialPayment && (
										<div className="p-0 col-6 calc-width">
											<TextField
												type="text"
												label={"amount"}
												placeholder={"amount"}
												id={"room-amount"}
												name="amount"
												value={indexedTentativeState?.amount}
												extraTextPosition="append"
												extraText={details?.currency}
												onChange={(e) => {
													handleTentativeState({
														name: "amount",
														value: +e.target.value.replace(/[^0-9.]/, ""),
														index,
													});

													let updatedErrors = [...errors];
													let cuurentRoomError = {
														...updatedErrors[index],
														...validate(e.target, {
															required: true,
															minNumber: 1,
														}),
													};
													updatedErrors[index] = cuurentRoomError;
													console.log(cuurentRoomError, updatedErrors);

													setErrors(updatedErrors);
												}}
												color={
													indexedError?.amount?.required ||
													indexedError?.amount?.minNumber
														? "danger"
														: ""
												}
												minNumber={1}
												errors={indexedError?.amount}
											/>
											{index === tentativeFieldsCount - 1 &&
												tentativeFieldsCount > 1 && (
													<Fragment>
														<button
															type="button"
															onClick={() => removePaid(index)}
															className="remove_room_icon"
															disabled={tentativeFieldsCount < 3}
														>
															<RemoveIcon />
														</button>
														<button
															type="button"
															onClick={() => addNewPaid()}
															className="remove_add_icon"
														>
															<i class="fas fa-plus-circle" />
														</button>
													</Fragment>
												)}
										</div>
									)}
								</div>
							);
						})}
					</>
				) : (
					<>
						<fieldset className="name-check d-flex justify-content-between form-group w-100">
							<div
								className="reserv_modal_radio form-check"
								onClick={() => {
									setSelected("Free");
								}}
							>
								<label
									htmlFor={"Free"}
									className={`pointer p-0  form-check-label`}
								>
									<input
										className="form-check-input"
										type="radio"
										disabled={false}
										checked={selected === "Free"}
										name={"Free"}
										id={"Free"}
									/>
									Free
								</label>
							</div>
							<div
								className="reserv_modal_radio form-check"
								onClick={() => {
									setSelected("Non-Refundable");
								}}
							>
								<label
									htmlFor={"Non-Refundable"}
									className={`pointer p-0  form-check-label`}
								>
									<input
										className="form-check-input"
										type="radio"
										disabled={false}
										checked={selected == "Non-Refundable"}
										name={"Non-Refundable"}
										id={"Non-Refundable"}
									/>
									Non-Refundable
								</label>
							</div>

							<div
								className="reserv_modal_radio form-check"
								onClick={() => {
									setSelected("Amount");
								}}
							>
								<label
									htmlFor={"Amount"}
									className={`pointer p-0  form-check-label`}
								>
									<input
										className="form-check-input"
										type="radio"
										disabled={false}
										checked={selected == "Amount"}
										name={"Amount"}
										id={"Amount"}
									/>
									Amount
								</label>
							</div>
						</fieldset>
						{selected == "Amount" ? (
							<div className="row m-0 modal-body px-0 reserv_modal_payment_fields">
								<div className="p-0 col-12 FIX_modal_append">
									<TextField
										type="text"
										label={"amount"}
										placeholder={"amount"}
										id={"room-amount"}
										name="amount"
										value={refundState?.amount}
										extraTextPosition="append"
										extraText={details?.currency}
										onChange={(e) => {
											setRefundState({
												...refundState,
												amount: +e.target.value.replace(/[^0-9.]/, ""),
											});
										}}
										color={
											""
											// indexedError?.amount?.required ||
											// indexedError?.amount?.minNumber
											// 	? "danger"
											// 	: ""
										}
										minNumber={1}
										// errors={indexedError?.amount}
									/>
								</div>
							</div>
						) : (
							""
						)}
					</>
				)}

				<button
					className="btn btn-res-primary w-100  mt-2"
					onClick={onComplete}
				>
					Complete
				</button>
			</div>
		</Model>
	);
};
