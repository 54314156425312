import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { listGroups } from "services/managementTeam";
import Locale from "translations";
import Filter from "../Filter";
import ListGroups from "./ListGroups";

export default function Groups() {
	const { backOffice, teamManagement } = Locale;
	const { pathname } = useLocation();
	const [groups, setGroups] = useState();
	const [deleting, setdeleting] = useState(false);

	const [filter, setFilter] = useState({
		q: "",
	});

	useEffect(async () => {
		const res = await listGroups({
			...filter,
		});
		setGroups(res?.data?.roles);
	}, [filter, deleting]);

	return (
		<>
			<header className="d-flex flex-column   border-bottom">
				<p className="h4 main-title ">{backOffice.teamManagement}</p>
				<div className="row ">
					<div className="col-10 all-tabs d-flex">
						<ShowForPermission
							permission={[
								"Team-Management-Users-View",
								"Team-Management-Users-Manage",
							]}
						>
							<NavLink
								exact={true}
								to={`/management-team/users`}
								activeClassName="is-active "
								className="our-taps nav-icon__header"
							>
								<p className="m-0">{backOffice.users}</p>
								{/* 
							<div className="nav-icons__wrapper">
								{pathname.includes("/management-team/users") ? (
									<i class="fas fa-caret-up  nav-icon"></i>
								) : (
									<i class="fas fa-caret-up  nav-icon__notActive "></i>
								)}
							</div> */}
							</NavLink>
						</ShowForPermission>

						<NavLink
							exact={true}
							to={`/management-team/groups`}
							activeClassName="is-active"
							className="our-taps nav-icon__header"
						>
							<p className="m-0"> {backOffice.groups}</p>

							{/* <div className="nav-icons__wrapper">
								<p className=""> {backOffice.groups}</p>
									{pathname.includes("/management-team/groups") ? (
									<i class="fas fa-caret-up  nav-icon"></i>
								) : (
									<i class="fas fa-caret-up  nav-icon__notActive "></i>
								)}
							</div> */}
						</NavLink>
					</div>
					<div className="col-2 d-flex justify-content-end align-items-center">
						<ShowForPermission permission="Team-Management-Groups-Manage">
							<Link
								type="button"
								class="btn btn-success px-4"
								to={"/management-team/add/group"}
							>
								{backOffice.addGroup}
							</Link>
						</ShowForPermission>
					</div>
				</div>
			</header>

			<div className="mt-3 ">
				<div className="row  m-0 bg-teamMange_filter w-100 ">
					<div className="col-12 p-0">
						<Filter filter={filter} setFilter={setFilter} />
					</div>
				</div>
				<div className="our-border border-top-0 px-3 pb-4">
					<ListGroups groups={groups} setdeleting={setdeleting} />
				</div>
			</div>
		</>
	);
}
