import SafaLogo from "assets/images/hotelbeds.png";
import phone from "assets/images/phone.png";
import safaLogo from "assets/images/safa_logo.png";
import { TransferInvoicePrint } from "assets/prints/TransferInvoicePrint";
import { transferVoucher } from "assets/prints/TransferPrint";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
	FetchTransfersVendor,
	getAllTransferVoucher,
	getTransferInvoice,
} from "services/vendor";
import PricesBox from "./PricesBox";
import TransferItem from "./transferItem";
function VendorTransferView() {
	const { id, brn } = useParams();
	const [Transfer, setTransfer] = useState({});
	useEffect(() => {
		const fetchView = async () => {
			const res = await FetchTransfersVendor(id, brn);
			if (res.status == 200) {
				setTransfer(res?.data?.data);
			}
		};
		fetchView();
	}, []);
	const dawnloadInvoice = async () => {
		const invoice = await getTransferInvoice(id, brn);
		if (invoice) {
			let respos = TransferInvoicePrint;
			const keyValuePair = {
				"[ref]": invoice?.data?.data?.reference,
				"[name]": invoice?.data?.data?.name,
				"[agency_ref]": invoice?.data?.data?.agency_ref,
				"[booking_time]": invoice?.data?.data?.booking_date,
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			let serviceContent = "";
			invoice?.data?.data?.items.forEach((item) => {
				if (item.status === "CANCELED") return;
				serviceContent += `<div class="border" style="margin:8px;padding:12px">
					<h5 style="background: #F3F5F5;">TICKETS AND EXCURSIONS</h5>
					<h6 class="fw-700">${item.from_name}, ${item.to_name}</h6>
					<p>From: ${moment(item.pickup_time).format("DD/MM/YYYY (dddd)")} </p>
					<hr class="hr-lg" />
					<h6 class="fw-600 border-dots">Tickets</h6>
					<p class="border-dots">${item.adults} Adults , ${
					item.children ? item.children + " child" : ""
				} </p>
					<h5
						style="background: #F3F5F5; display: flex; flex-direction: row-reverse;padding: 5px;font-weight: 800;margin-top: 9px;">
						Total net amount : ${item.price} ${item.currency}</h5>

				</div>`;
			});

			respos = respos.replace("[service_content]", serviceContent);

			let cancelPolicy = "";
			invoice?.data?.data?.items?.forEach((item) => {
				item.cancellation_policies?.forEach((policy, index) => {
					if (item.status === "CANCELED") return;
					const freeCancel = `${moment(policy?.from)
						.subtract(1, "day")
						.format("DD/MM/YYYY")} at ${moment(policy?.from)
						.subtract(1, "minute")
						.format("hh:mm A")}`;

					cancelPolicy += `<div class="box-containt">
							<div class="row" style="width: 100%; margin: 5px;">
								<div class="col-5">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">Concept</h2>
										<p class="border-dots">
											${item?.from_name}, ${item?.to_name}
										</p>
									</div>
								</div>
								<div class="col-3">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">From</h2>
										<p class="border-dots">
											${moment(policy?.from).format("DD/MM/YYYY")}
										</p>
									</div>
								</div>
								<div class="col-2">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">Units</h2>
										<p class="border-dots">1</p>
									</div>
								</div>
								<div class="col-2">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">Value</h2>
										<p class="border-dots">
											${policy?.amount}
											${policy?.currencyId}
										</p>
									</div>
								</div>
							</div>
							<div style="width:100%;margin: 5px;">
								<p class="border-dots">
									Date and time is calculated based on local time of
									destination.
								</p>
							</div>

							<div class="border-dots" style="width:100%;margin: 15px;">
								<div
									class="fw-600"
									style="padding:12px;line-height: 1.2;border:2px solid #222"
								>
									PAYMENT DEADLINE BY CREDIT CARD: Please ensure you pay for
									this booking before ${freeCancel} (local time of
									destination) or your booking will be cancelled
									automatically. PAYMENT DEADLINE BY BANK TRANSFER: Please
									ensure you pay for this booking before ${freeCancel}
									sending us a payment proof or your booking will be cancelled
									automatically
								</div>
							</div>
						</div>`;
				});
			});

			respos = respos.replace("[cancle_policy]", cancelPolicy);
			respos = respos.replace("[SafaLogo]", SafaLogo);

			var popupWin = window.open(
				"",
				" ",
				"top=0,left=0,height=100%,width=auto"
			);
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>

						</style>
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	const printVoucher = async () => {
		const voucherRes = await getAllTransferVoucher(id, brn);
		if (voucherRes.status !== 200) return;
		const voucherData = voucherRes?.data?.data;
		let printVar = "";
		voucherData?.forEach((item) => {
			if (item.status === "CANCELED") return;
			const keyValuePair = {
				"[reference_number_key]": "Reference Number",
				"[reference_number_value]": item?.ref,
				"[supplier_reference_key]": "Supplier Reference",
				"[supplier_reference_value]": item?.supplier_reference,
				"[passenger_name_key]": "Passenger Name",
				"[passenger_name_value]": `${item?.passenger_name?.name} ${item?.passenger_name?.surname}`,
				"[product_type_key]": "Product Type",
				"[product_type_value]": `${item?.type} ${item?.category} ${item?.vehicle} `,
				"[product_image]": item?.image,
				"[from_key]": "From",
				"[from_value]": item?.from,
				"[to_key]": "To",
				"[to_value]": item?.to,
				"[passengers_key]": "Passengers",
				"[adults_value]": item?.adults,
				"[children_value]": item?.children,
				"[infants_value]": item?.infants,
				"[service_date_key]": "Service Date",
				"[service_date_value]": item?.pickup_date,
				"[pickUp_time_key]": "pick-up Time",
				"[pickUp_time_value]": item?.pickup_time
					? moment(item?.pickup_time).format("HH:mm")
					: "",
				"[pickUp_point_key]": "Pick-up Point",
				"[pickUp_point_value]": item?.pickup_information,
				"[travel_information]": "Travel Information",
				"[arrival_flight_key]": "Arrival Flight",
				"[arrival_flight_value]": item?.flight_number ?? "",
				"[arrival_time_key]": "Arrival Time",
				"[arrival_time_value]": item?.flight_time
					? moment(item?.flight_time).format("HH:mm")
					: "",
				"[included_services_key]": "Included Services",
				"[telephone]": "Telephone",
				"[voucher_date]": moment().format("DD/MM/YYYY"),
			};

			printVar += transferVoucher;
			for (const key in keyValuePair) {
				printVar = printVar.replace(key, keyValuePair[key]);
			}
			let included_services = "";
			item?.transfer_detail_info?.forEach(
				(info) => (included_services += `<p>${info}</p>`)
			);
			printVar = printVar.replace(
				"[included_services_value]",
				included_services
			);
			printVar = printVar.replace(
				"[logo]",
				`<img width={200} src="${safaLogo}" alt="safa logo" />`
			);
			printVar = printVar.replace(
				"[phone_img]",
				`<img src="${phone}" alt="phone" />`
			);
		});
		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
        <head>
        <title> print Voucher</title>
        <style>

        </style>
        </head>
        <body onLoad="{()=>window.print()}">${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};

	return (
		<>
			<div className="me-5 transfer d-flex align-items-center justify-content-between">
				<div className="Booking-Details-tab">Booking Details</div>
				<div className="box-invoice-btn mb-2 pt-2">
					<button
						className="btn btn-invoice-vendor"
						onClick={() => {
							dawnloadInvoice();
						}}
					>
						<i class="fas fa-download"></i> Invoice
					</button>
					<button
						className="btn btn-invoice-vendor"
						onClick={() => {
							printVoucher();
						}}
					>
						<i class="fas fa-download"></i> Voucher
					</button>
				</div>
			</div>
			<div className="row me-5 bg-white transfer">
				<div className="col-8">
					<div className="d-flex justify-content-between align-items-center">
						<p className="trans-dark-top">Reservation</p>
						<p className="ref-btn">Ref No. {Transfer?.reference}</p>
					</div>
					{Transfer.items?.map((res) => (
						<TransferItem item={res} key={res?.id} />
					))}
				</div>
				{/*                       right col                  */}
				<div className="col-4">
					<p className="trans-dark-top">Booking Summary</p>
					{Transfer?.items?.length > 0 && (
						<PricesBox
							Transfer={Transfer?.items}
							currency={Transfer?.currency}
						/>
					)}
				</div>
			</div>{" "}
		</>
	);
}

export default VendorTransferView;
