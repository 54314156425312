import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";

import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { listCycleTypes } from "services/Request";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { requests, backOffice } = Locale;
	const { allCountries } = useGlobalState()
	const [countries, setCountries] = useState([]);
	const { locale } = useGlobalState();
	const [cycleTypies, setCycleTypies] = useState([]);

	useEffect(() => {
		async function fetchLookups() {
			const format = allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			const resCycle = await listCycleTypes();
			const formatCycle = resCycle.data.map((t) => ({
				value: t.id,
				label: t.name[locale],
			}));
			setCycleTypies(formatCycle);
			setCountries(format);
		}

		fetchLookups();
	}, []);

	return (
		<div className=" main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						/* 						hasLabel={false}
						 */ value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						/* 						hasLabel={false}
						 */ placeholder={requests.Destination}
						label={requests.Destination}
						name="status"
						options={countries}
						value={filter.destination_id}
						onChange={(e) => {
							setFilter({
								...filter,
								destination_id: e,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						/* 						hasLabel={false}
						 */ placeholder={requests.CycleType}
						label={requests.CycleType}
						options={cycleTypies}
						name="status"
						value={filter.cycle_type_id}
						onChange={(e) => {
							setFilter({
								...filter,
								cycle_type_id: e,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={requests.date}
						onOutsideClick={false}
						/* 						hasLabel={false}
						 */ date={filter?.created_at}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								created_at: e,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={() => {
							setFilter({
								page: filter.page,
								search: "",
								destination_id: null,
								cycle_type_id: null,
								created_at: null,
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />
						{/* 						<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
						 */}{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
