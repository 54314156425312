import moment from "moment";
import { useEffect, useState } from "react";
import { listProperties } from "services/property";
import Locale from "translations";
import PropertyFilter from "../Components/PropertyManagement/PropertyFilter";
import PropertyTable from "../Components/PropertyManagement/PropertyTable";

export default function PropertyManagement() {
	const { backOffice } = Locale;
	const [properties, setProperties] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filterState, setFilterState] = useState({
		search: "",
		date: null,
		country: "",
		city: "",
		status: "",
		page: 1,
	});

	async function fetchData() {
		const data = {
			search: filterState.search,
			ask_verify_request_at: filterState.date
				? moment(filterState.date).format("YYYY-MM-DD")
				: null,
			country_id: filterState.country?.value,
			city_id: filterState.city?.value,
			status: filterState.status?.value,
		};

		const res = await listProperties(data, filterState?.page);
		setProperties(res?.data.data);
		setMeta(res.data.meta);
	}
	useEffect(() => {
		fetchData();
	}, [filterState]);

	const goTo = (pagenum) => {
		setFilterState({ ...filterState, page: pagenum });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className=" main-title ">{backOffice.propertyManagement}</h6>

				{/* <button className="btn text-white bg-blue-black rounded-0">
          Export
        </button> */}
			</div>
			<PropertyFilter
				filterState={filterState}
				setFilterState={setFilterState}
			/>
			<PropertyTable
				properties={properties}
				setProperties={setProperties}
				goTo={goTo}
				meta={meta}
			/>
		</>
	);
}
