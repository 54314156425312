import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePlatformDispatch } from "context/platformRequest";
import "date-fns";
import { useEffect, useState } from "react";
// import { formatPostPlatform, formatGetPlatform } from "data/platform";
import DateFnsUtils from "@date-io/date-fns";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { fetchSeason } from "services/lookups";
import { addPlatform, editPlatform, viewPlatform } from "services/platform";
import Locale from "translations";
import PlatfromSpecialOffer from "./SpecialOffer";
// import Commission from './Commission';
import Commission from "./Commission";

const PlatformAddContent = ({ state, isEditMode }) => {
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const dispatch = useGlobalDispatch();
	const PlatformDispatch = usePlatformDispatch();
	const history = useHistory();
	const [canceled, setCanceled] = useState(false);
	const [CommissionsList, setCommissionsList] = useState([]);
	const [platFormEdit, setPlatFormEdit] = useState(null);
	const [SpecialOfferList, setSpecialOfferList] = useState([]);

	const [platFormMainDetails, setPlatFormMainDetails] = useState({
		name: "",
		website: "",
		country: platFormEdit?.country_id,
		contract_start_date: undefined,
		contract_end_date: undefined,
		reservation_officer_name: "",
		mobile: "",
		email: "",
		// currency: "",
		spin: false,
		commissions: CommissionsList,
		specialOffers: SpecialOfferList,
	});

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [countryIndex, setCountryIndex] = useState(null);
	const [season, setSeason] = useState([]);

	const { id } = useParams();

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: platFormMainDetails.name },
				{ required: true, min: 3, notNumber: true }
			),
			...validate(
				{ name: "website", value: platFormMainDetails.website },
				{ required: true }
			),
			...validate(
				{ name: "country", value: platFormMainDetails.country },
				{ required: true }
			),
			...validate(
				{
					name: "contract_start_date",
					value: platFormMainDetails.contract_start_date,
				},
				{ required: true }
			),
			...validate(
				{
					name: "contract_end_date",
					value: platFormMainDetails.contract_end_date,
				},
				{ required: true }
			),
			...validate(
				{
					name: "reservation_officer_name",
					value: platFormMainDetails.reservation_officer_name,
				},
				{ required: true, min: 3, notNumber: true }
			),
			// ...validate({ name: "mobile", value: validatePhone(platFormMainDetails.mobile, countries.find((country) => country.value === platFormMainDetails.country.value)?.code) }, { required: true, mobile: true }),
			...validate(
				{ name: "mobile", value: platFormMainDetails.mobile },
				{ required: true, number: true }
			),
			...validate(
				{ name: "email", value: platFormMainDetails.email },
				{ required: true, email: true }
			),
			// ...validate(
			//   { name: "currency", value: platFormMainDetails.currency },
			//   { required: true }
			// ),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setPlatFormMainDetails({ ...platFormMainDetails, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setPlatFormMainDetails({
					...platFormMainDetails,
					[name]: value,
				});
			} else setPlatFormMainDetails({ ...platFormMainDetails, [name]: value });
		}
	};
	const [Reload, setReload] = useState(false);
	// Fetch Countries
	useEffect(() => {
		async function fetchLookups() {
			// res.push({country_code:'All', names:{en:'All', ar:'All'}});
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);

			const resSeason = await fetchSeason();
			const format_season = resSeason.seasons.map((t) => ({
				value: t.id,
				label: t.name,
			}));
			setSeason(format_season);

			if (id) {
				const resplatform = await viewPlatform(id);
				setPlatFormEdit(resplatform.data.platform);
				setCommissionsList(resplatform.data.platform.commissions);
				setSpecialOfferList(
					resplatform.data.platform.offers.map((res) => {
						return {
							form: res.from_date,
							to: res.to_date,
							offer: res.value,
							id: res.id,
						};
					})
				);
				setPlatFormMainDetails({
					...platFormMainDetails,
					name: resplatform.data.platform.name,
					website: resplatform.data.platform.website,
					country: resplatform.data.platform.country_id,
					contract_start_date: resplatform.data.platform.contract_start_date,
					contract_end_date: resplatform.data.platform.contract_end_date,
					reservation_officer_name:
						resplatform.data.platform.reservation_officer_name,
					mobile: resplatform.data.platform.mobile,
					email: resplatform.data.platform.email,
					// currency: resplatform.data.platform.currency,
					commissions: resplatform.data.platform.commissions,
				});
				const cIndx = allCountries?.findIndex(
					(x) => x.id == resplatform.data.platform.country_id
				);
				setCountryIndex(cIndx);
				setSelectedStartDate(resplatform.data.platform.contract_start_date);
				setSelectedEndDate(resplatform.data.platform.contract_end_date);
			}
		}
		fetchLookups();
	}, [allCountries, Reload]);

	const currenciesList = [
		{
			value: 1,
			label: "Egyptian Pound",
			code: "EGP",
			status: "Active",
			checkIcon: 0,
		},
		{
			value: 2,
			label: "Dollar",
			code: "$",
			status: "Deactivate",
			checkIcon: 1,
		},
		{
			value: 3,
			label: "Euro",
			code: "€",
			status: "Inactive",
			checkIcon: 0,
		},
		{
			value: 4,
			label: "British Pound",
			code: "£",
			status: "Deactivate",
			checkIcon: 1,
		},
		{
			value: 5,
			label: "Saudi Riyal",
			code: "SAR",
			status: "Active",
			checkIcon: 0,
		},
		{
			value: 6,
			label: "Rupiah",
			code: "Rup",
			status: "Deactivate",
			checkIcon: 1,
		},
	];

	useEffect(() => {
		const submitAction = async () => {
			if (isFormValid(errors)) {
				if (id) {
					const res = await editPlatform(id, {
						...platFormMainDetails,
						name: {
							ar: platFormMainDetails.name,
							en: platFormMainDetails.name,
						},
						country_id: platFormMainDetails.country,
					});
					if (res.status === 200) {
						//PlatformDispatch({ type: "platFormMainDetails", payload: res.data });
						store.addNotification({
							title: "Done!",
							message: res.data.message,
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 3000,
								onScreen: true,
								pauseOnHover: true,
							},
						});
						history.push(`/platform-commissions-list`);
					}
				} else {
					const res = await addPlatform({
						...platFormMainDetails,
						name: {
							ar: platFormMainDetails.name,
							en: platFormMainDetails.name,
						},
						country_id: platFormMainDetails.country.value,
					});

					if (res.status === 200) {
						//PlatformDispatch({ type: "platFormMainDetails", payload: res.data });
						store.addNotification({
							title: "Done!",
							message: res.data.message,
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 3000,
								onScreen: true,
								pauseOnHover: true,
							},
						});
						history.push(`/platform-commissions-list`);
					}
				}
			}
		};
		submitAction();
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
		setPlatFormMainDetails({
			...platFormMainDetails,
			contract_start_date: date,
		});
	};

	const [selectedEndDate, setSelectedEndDate] = useState(new Date());

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
		setPlatFormMainDetails({
			...platFormMainDetails,
			contract_end_date: date,
		});
	};

	return (
		<div className="Platform-details">
			{/* edit platform */}
			<div className="row custom--row">
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.PlatformName}
						placeholder={backOffice.PlatformName}
						name="name"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
									notNumber: true,
								}),
							});
							setPlatFormMainDetails({
								...platFormMainDetails,
								name: e.target.value,
							});
						}}
						min={3}
						color={
							errors?.name?.required ||
							errors?.name?.min ||
							errors?.name?.notNumber
								? "danger"
								: ""
						}
						value={platFormMainDetails?.name}
						errors={errors?.name}
					/>
				</div>
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.platformWebsiteLink}
						placeholder={"https://www.website.com"}
						name="website"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setPlatFormMainDetails({
								...platFormMainDetails,
								website: e.target.value,
							});
						}}
						color={
							errors?.website?.required || errors?.website?.min ? "danger" : ""
						}
						value={platFormMainDetails?.website}
						errors={errors?.website}
					/>
				</div>
				<div className="col-md-4">
					<SelectField
						label={backOffice.country + "/" + backOffice.region}
						placeholder={
							backOffice.select +
							" " +
							backOffice.country +
							"/" +
							backOffice.region
						}
						errors={errors.country}
						color={errors.country?.required ? "danger" : ""}
						options={countries}
						id="basic-countries"
						onChange={(e) => {
							handleChange({ name: "country", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={countries[countryIndex]}
					/>
				</div>
			</div>
			{/* edit platform */}
			<div className="row custom--row">
				<div className="col-md-4">
					<span className="d-block mt-3">{backOffice.contractStartDate}</span>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="my-2 DatePicker-Form"
							variant="outlined"
							inputVariant="outlined"
							id="date-picker-dialog"
							// label={backOffice.contractStartDate}
							format="dd/MM/yyyy"
							value={selectedStartDate}
							color={errors?.contract_start_date?.required ? "danger" : ""}
							errors={errors?.contract_start_date}
							onChange={handleStartDateChange}
						/>
					</MuiPickersUtilsProvider>
				</div>
				<div className="col-md-4">
					<span className="d-block mt-3">{backOffice.contractEndDate}</span>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="my-2 DatePicker-Form"
							variant="outlined"
							inputVariant="outlined"
							id="date-picker-dialog"
							// label={backOffice.contractEndDate}
							format="dd/MM/yyyy"
							value={selectedEndDate}
							color={errors?.contract_end_date?.required ? "danger" : ""}
							errors={errors?.contract_end_date}
							onChange={handleEndDateChange}
						/>
					</MuiPickersUtilsProvider>
				</div>
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.reservationOfficerName}
						placeholder={backOffice.reservationOfficerName}
						name="reservation_officer_name"
						value={platFormMainDetails?.reservation_officer_name}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									min: 3,
									notNumber: true,
								}),
							});
							setPlatFormMainDetails({
								...platFormMainDetails,
								reservation_officer_name: e.target.value,
							});
						}}
						min={3}
						color={
							errors?.reservation_officer_name?.required ||
							errors?.reservation_officer_name?.min ||
							errors?.reservation_officer_name?.notNumber
								? "danger"
								: ""
						}
						errors={errors?.reservation_officer_name}
					/>
				</div>
			</div>
			{/* edit platform */}
			<div className="row custom--row">
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.mobileNumber}
						placeholder={backOffice.mobileNumber}
						name="mobile"
						value={platFormMainDetails?.mobile}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setPlatFormMainDetails({
								...platFormMainDetails,
								mobile: e.target.value,
							});
						}}
						color={errors?.mobile?.required ? "danger" : ""}
						errors={errors?.mobile}
					/>
				</div>
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.email}
						placeholder={"email@example.com"}
						name="email"
						value={platFormMainDetails?.email}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setPlatFormMainDetails({
								...platFormMainDetails,
								email: e.target.value,
							});
						}}
						color={
							errors?.email?.required || errors?.email?.email ? "danger" : ""
						}
						errors={errors?.email}
					/>
				</div>
			</div>
			{/* commission */}
			<Commission
				setCommissionsList={setCommissionsList}
				commissionsList={CommissionsList}
				errors={errors}
				setErrors={setErrors}
				countries={countries}
				season={season}
			/>
			{/* SpecialOffers */}
			<PlatfromSpecialOffer
				specialOfferList={SpecialOfferList}
				setSpecialOfferList={setSpecialOfferList}
				setReload={setReload}
			/>

			{/* submit button */}
			<div className="row custom--row mt-2 flex-row-reverse">
				<div className="mx-1">
					<button
						className="btn btn-sbs-primary w-100"
						onClick={submit}
						disabled={platFormMainDetails.spin}
					>
						{platFormMainDetails.spin ? (
							<Spinner color="light" size="sm" />
						) : null}{" "}
						{id ? backOffice.edit : backOffice.addPlatform}
					</button>
				</div>
				<div className="mx-1">
					<Link
						className={`btn btn-sbs-secondary w-100`}
						to={`/platform-commissions-list`}
					>
						{backOffice.back}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default PlatformAddContent;
