import { CalendarIcon } from "modules/backOffice/Components/SideBar/Icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { airlineReservationsRequests, viewflight } from "services/airlines";
import FlightBookingRequests from "./Requests";
import ReservationDetails from "./details";

export default function ViewAirlineReservation() {
	const [tabStatus, setTabStatus] = useState(1);
	const [reservation, setReservation] = useState(null);
	const [requests, setRequests] = useState();
	const [historyDetails, setHistoryDetails] = useState([]);
	const { id } = useParams();
	const history = useHistory();
	const fetchInventory = async () => {
		const res = await viewflight(id);
		if (res.status === 200) {
			setReservation(res.data.data);
		}
	};
	// get flight booking details
	useEffect(() => {
		fetchInventory();
		return () => {};
	}, []);

	const fetchAirlineRequests = async () => {
		const res = await airlineReservationsRequests(id);
		if (res?.status === 200) {
			setRequests(res?.data?.data);
		}
	};
	useEffect(() => {
		fetchAirlineRequests();
		return () => {};
	}, []);

	// useEffect(() => {
	// 	const airlineHistory = async () => {
	// 		const res = await getFilghtHistory(id);
	// 		if (res?.status === 200) {
	// 			setHistoryDetails(res?.data?.data);
	// 		}
	// 	};
	// 	airlineHistory();
	// }, []);
	return (
		<div className="b2c_booking_details airline_reservation">
			<div>
				<h3> Airline Reservations</h3>
				<button className="btn px-0" onClick={() => history.goBack()}>
					<i className="fas fa-arrow-left text-info" />
					<span className="ms-2 text-info">Back To Reservations</span>
				</button>
			</div>
			<div className="booking_tab_container">
				<button
					className={`booking_tab p-3 ${tabStatus === 1 ? "active" : ""}`}
					onClick={() => setTabStatus(1)}
				>
					<CalendarIcon />
					<h6 className="px-2 m-0">Booking Details</h6>
				</button>
				<button
					className={`booking_tab p-3 ${tabStatus === 2 ? "active" : ""}`}
					onClick={() => setTabStatus(2)}
				>
					<h6 className="px-2 m-0">Requests</h6>
				</button>

				{/* <button
					className={`booking_tab p-3 ${tabStatus === 3 ? "active" : ""}`}
					onClick={() => setTabStatus(3)}
				>
					<h6 className="px-2 m-0">History</h6>
				</button> */}
			</div>
			{tabStatus === 1 ? (
				<ReservationDetails reservation={reservation} />
			) : tabStatus === 2 && requests ? (
				<FlightBookingRequests
					fetchInventory={fetchAirlineRequests}
					requests={requests}
				/>
			) : // : tabStatus === 3 ? (
			// 		<FlightBookingHistory historyDetails={historyDetails} />
			// 	)

			null}
		</div>
	);
}
