import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import CancelModel from "./cancelModel";

function TransferItem({ item, printVoucher }) {
	const [isOpen, setisOpen] = useState(false);
	const toggle = () => {
		setisOpen(!isOpen);
	};
	return (
		<>
			<div className="transfer-item mb-3">
				<div className="row">
					<div className="col-5 pt-2">
						<div className="img-box">
							<img
								src={item?.image}
								alt={`${item?.type} ${item?.category} ${item?.category}`}
								srcset=""
							/>
						</div>
					</div>
					<div className="col-7 py-1 pt-2">
						<span>
							<span className="trans-dark" style={{ fontSize: "20px" }}>
								{item?.type}
							</span>
							<span className="trans-dark-light"> {item?.category} </span>
							{item?.vehicle}
						</span>
						<div className="info-trans">
							<p>
								<i className="fas fa-map-marker-alt"></i>
								<span>{item?.from_name}</span>
								<i
									className="fas fa-exchange-alt mx-2"
									style={{ color: "#0850AD" }}
								></i>
								<span>{item?.to_name}</span>
							</p>
						</div>
						<div className="info-trans">
							<p>
								<i className="far fa-calendar-alt"></i>
								<span>{item?.flight_time}</span>
							</p>
						</div>
						<div className="info-trans">
							<p>
								<i className="far fa-user"></i>{" "}
								<span>{`${item?.trip_type} Adults ${item?.adults} Child ${item?.children} infants ${item.infants}`}</span>
							</p>
						</div>
					</div>
					<div className="col-12 px-4 pb-2">
						<p className="trans-dark" style={{ marginBottom: "5px" }}>
							{`Flight Number ${item?.flight_number}  Flight Time ${item?.flight_time}`}
						</p>
						<span className="text-warning">
							<i className="fas fa-exclamation-triangle me-2"></i>
							If The Information Is Not Accurate, The Supplier Is Not
							Responsible For The Correct Service Provision.
						</span>
					</div>
					<div className="two-box">
						<div className="half-box">
							<p style={{ fontWeight: "bold" }}>
								Cancellation Fees — {item?.trip_type}
							</p>
							{item?.cancellation_policies.map((res) => (
								<>
									<p className="text-success">
										Until {res?.from}
										<span
											style={{ fontWeight: "bold", marginInlineStart: "15px" }}
										>
											Free
										</span>
									</p>
									<p className="text-danger">
										After {res?.from}
										<span
											style={{ fontWeight: "bold", marginInlineStart: "15px" }}
										>
											<span>{res?.amount}</span>
											<span>{res?.currencyId}</span>
										</span>
									</p>
								</>
							))}
						</div>
						<div
							className="half-box"
							style={{ borderLeft: "1px solid #ebebeb" }}
						>
							<span>
								Date and time are calculated based on local time in the
								destination. In case of no-show, different fees will apply.
								Please refer to our T&C.
							</span>
						</div>
					</div>
					<div className=" w-100  px-4 py-1 d-flex align-items-center justify-content-between">
						<ShowForPermission permission="Transfers-Bookings-Manage">
							{item?.status !== "CANCELED" && item?.status !== "expired " ? (
								<>
									<p
										onClick={() => {
											printVoucher(item.id);
										}}
										className="text-warning pointer"
										style={{ margin: "0px" }}
									>
										<i className="fas fa-download"></i> Download Voucher
									</p>
									<p
										className="text-danger pointer"
										onClick={() => {
											toggle();
										}}
										style={{ margin: "0px" }}
									>
										<i class="fas fa-times-circle"></i>
										Cancel Transfer
									</p>
								</>
							) : null}
						</ShowForPermission>
					</div>
				</div>
			</div>
			<CancelModel isOpen={isOpen} toggle={toggle} item={item} />
		</>
	);
}

export default TransferItem;
