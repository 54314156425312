import TextField from "components/shared/TextField";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import FilterCrmModel from "./Models/FilterCrmModel";
export default function PackagesBookingFilter({
	filter,
	setFilter,
	setIsFilter,
	resetFilter,
	exportToFile,
	toggle,
	isOpen,
	setisOpen,
	select,
	travelAgentOptions,
}) {
	const { backOffice } = Locale;
	const history = useHistory();

	const [isFilterOpen, setisFilterOpen] = useState(false);
	const togglefilter = () => {
		setisFilterOpen(!isFilterOpen);
	};
	return (
		<>
			<div className="header-filter-crm">
				<div className="left-btns">
					{/* <button
						onClick={() => {
							history.push("/b2c-redirect");
						}}
						className="btn btn-header"
					>
						<i class="fas fa-random"></i> {backOffice.Redirect}
					</button>
					<button className="btn btn-header">
						<i class="fas fa-exchange-alt"></i> {backOffice.ChangePackage}
					</button>
					<button className="btn btn-header">
						<i class="far fa-times-circle"></i> {backOffice.Delete}
					</button> */}
					<button
						onClick={toggle}
						disabled={select.length <= 0}
						className="btn btn-header"
					>
						<i class="fas fa-exclamation-triangle"></i> {backOffice.Priority}
					</button>
				</div>
				<div className="right-btns">
					<button
						className="btn btn-header"
						onClick={() => {
							resetFilter();
							setIsFilter((prev) => !prev);
						}}
					>
						<i class="fas fa-redo"></i> {backOffice.Refresh}
					</button>
					<button
						onClick={() => {
							togglefilter();
							resetFilter();
						}}
						className="btn btn-header"
					>
						<i class="fas fa-filter"></i> {backOffice.Filter}
					</button>
					<button className="btn btn-header" onClick={exportToFile}>
						<i class="fas fa-file-export"></i> {backOffice.Export}
					</button>
				</div>
			</div>
			<div className="border border-bottom-0 crm-filter">
				<div className="col-md-6">
					<TextField
						type="text"
						placeholder={backOffice.search}
						value={filter.owner_name}
						onChange={(e) =>
							setFilter({
								...filter,
								owner_name: e.target.value,
							})
						}
					/>
				</div>
				<div className="col-md-6 d-flex justify-content-end gap-1">
					{/* 			<button className="btn btn-crm">{backOffice.HighPriority}</button>
					<button className="btn btn-crm">
						{backOffice.MissingPasssportNo}
					</button>
					<button className="btn btn-crm">{backOffice.Delay}</button> */}
				</div>
			</div>
			<FilterCrmModel
				toggle={togglefilter}
				isOpen={isFilterOpen}
				filter={filter}
				setFilter={setFilter}
				setIsFilter={setIsFilter}
				travelAgentOptions={travelAgentOptions}
			/>
		</>
	);
}
