import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import {
	commercialDistChargeWallet,
	commercialDistWalletList,
} from "services/commercialDistribution";
import Locale from "translations";
import ChargeWalletModal from "./ChargeWalletModal";

export default function CommercialDistributionWallet({ walletData }) {
	const { id } = useParams();
	const { backOffice } = Locale;
	const [walletList, setWalletList] = useState([]);
	const [filters, setFilters] = useState({
		page: 1,
	});
	const [meta, setMeta] = useState(null);
	const [chargeWalletState, setChargeWalletState] = useState({
		amount: "",
		description: "",
	});
	const [reload, setReload] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen((prev) => !prev);
	};

	async function fetchWallets() {
		const res = await commercialDistWalletList(walletData.id, filters);
		if (res?.status === 200) {
			setWalletList(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	}

	useEffect(() => {
		fetchWallets();
	}, [filters, reload]);

	function goTo(page) {
		setFilters({ ...filters, page: +page });
	}
	const chargeWalet = async () => {
		let data = {
			amount: +chargeWalletState.amount,
			description: chargeWalletState.description,
		};
		const res = await commercialDistChargeWallet(walletData.id, data);
		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: `Charge Wallet Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
					toggle();
					setChargeWalletState({ amount: "", description: "" });
				},
			});
		}
	};
	return (
		<div>
			<div className="wallet-box mt-4">
				<div className="row p-0 m-0 justify-content-between ">
					<div className="col-2 bg-wallet-name font-weight-bolder d-flex align-items-center justify-content-center ">
						{`${walletData?.currency} Wallet`}
					</div>

					<div className="col-2 p-2">
						<p className="m-0 font-weight-bolder">Amount</p>
						<p className="m-0">
							{" "}
							{walletData?.amount} {walletData?.currency}
						</p>
					</div>

					<div className={`d-flex align-items-center mx-2`}>
						<ShowForPermission permission="Commercial-Distribution-Manage">
							<button className="btn btn-primary px-4" onClick={toggle}>
								Charge Wallet
							</button>
						</ShowForPermission>
					</div>
				</div>
			</div>

			{/* <div className="charge-wallet d-flex justify-content-between mt-4 mb-3">
				<div className="total_amount d-flex">
					<p className="font-bold text-18">Total Amount :</p>
					<span className="mx-2 font-bold text-16  ">
						{walletData?.amount} {walletData?.currency}
					</span>
				</div>
				<button className="btn btn-primary px-4" onClick={toggle}>
					Charge Wallet
				</button>
			</div> */}

			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Transactions ID </th>
							<th>Type</th>
							<th>Amount</th>
							<th>Original Amount</th>
							<th>Created</th>
							<th>Description</th>
							<th>Action By </th>
							{/* <th>payment method</th> */}
						</tr>
					</thead>
					<tbody>
						{walletList?.length > 0 ? (
							walletList?.map((wallet) => {
								return (
									<tr key={wallet?.id}>
										<td>{wallet?.id}</td>
										<td>{wallet?.transaction_type}</td>
										<td>
											{wallet?.amount} {wallet?.currency}
										</td>
										<td>
											{wallet?.original_amount} {wallet?.original_currency}
										</td>
										<td>{wallet?.created_at}</td>

										<td>{wallet.description}</td>
										<td>{wallet?.action_by}</td>
										{/* <td></td> */}
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="8">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
					{/* <tbody>{AllReservations}</tbody> */}
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>

			<ChargeWalletModal
				isOpen={isOpen}
				toggle={toggle}
				chargeWalletState={chargeWalletState}
				setChargeWalletState={setChargeWalletState}
				chargeWalet={chargeWalet}
			/>
		</div>
	);
}
