import React, { useEffect, useState } from "react";
import { showTransaction } from "services/wallet";
import walletAdd from "assets/images/wallet/wallet-add.svg";
import Locale from "translations";
import { useHistory, useParams } from "react-router-dom";

export default function DealsVeiw() {
  const [transaction, setTransaction] = useState();
  const { wallet } = Locale;

  const { id } = useParams();
  let history = useHistory();

  useEffect(async () => {
    const res = await showTransaction(id);
    if (res?.status == 200) {
      setTransaction(res.data.data);
    }
  }, []);

  return (
    <div className="wallet-wrraper" style={{ height: "70vh" }}>
      <div className="container p-0 display-card">
        <div className="create-card">
          <img src={walletAdd} alt="" />
          <div
            className="row mt-3 justify-content-center  align-items-center"
            style={{ width: "60%" }}
          >
            <p className="col-6">{wallet.receiptNumber}</p>
            <p className="col-6">{transaction?.transaction_number}</p>
            <p className="col-6">{wallet.company_name}</p>

            <p className="col-6">{wallet.company_name}</p>
            <p className="col-6">{wallet.requestDate}</p>
            <p className="col-6">{transaction?.created_at}</p>
            <p className="col-6">{wallet.amount}</p>
            <p className="col-6">{transaction?.amount}</p>
            <button
              type="button"
              class="btn btn-secondary col-6 mt-4"
              onClick={() => {
                history.push(
                  `/tourism-company/${transaction.company_id}/wallet`
                );
              }}
            >
              {wallet.backToRequests}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
