import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listDeals = async (params) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const listRequests = async (params) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions/requests/list`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const showTransaction = async (id) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const showRequest = async (id) => {
	return await axios
		.get(`${backOfficeApi}/payment-transactions/requests/${id}/show`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getDepositReceipt = async (id) => {
	return await axios
		.get(`${backOfficeApi}/files/fetch/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const paymentCards = async (id) => {
	return await axios
		.get(`${backOfficeApi}/payment-cards/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addCompanyWallet = async (data) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/debit`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addYarvelWallet = async (data) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/credit`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const payCredit = async (data, id) => {
	return await axios
		.post(
			`${backOfficeApi}/payment-cards/${id}/pay-credit-by-debit`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const requestsActions = async (status, id) => {
	return await axios
		.post(`${backOfficeApi}/payment-transactions/requests/${id}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const searchHotelsApi = async (data) => {
	return await axios
		.post(`${backOfficeApi}/marketplace/url-tokens/set`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchSearchHotels = async (params) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/url-tokens/get`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusOffers = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/marketplace/url-tokens/change-offer-status/${id}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchProviderslist = async () => {
	return await axios
		.get(`${backOfficeApi}/marketplace/url-tokens/list-providers`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchProvidorHotels = async (provider_id, value, city_code) => {
	return await axios
		.get(
			`${backOfficeApi}/marketplace/url-tokens/list-provider-hotels/${provider_id}?search=${value}&city_code=${city_code}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchViewHotelsSearch = async (id) => {
	return await axios
		.get(`${backOfficeApi}/marketplace/url-tokens/by-token/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editDatesOffers = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/marketplace/url-tokens/edit/${id}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteOfer = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/marketplace/url-tokens/delete/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
