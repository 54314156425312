import TextField from 'components/shared/TextField';
import { useGlobalState } from 'context/global';
import generateUniqueID from 'helpers/generateUniqueID';
import validate from 'helpers/validate';
import { useEffect, useState } from 'react';
import Select from "react-select";

export default function PromoCityDiscounts({ promoDataState, setPromoDataState, errors, setErrors, isEditMode }) {
  const { locale, allCountries } = useGlobalState();
  const [isAllCountriesSelected, setIsAllCountriesSelected] = useState(false);
  const [countries, setCountries] = useState([]);

  function addNewDiscountRow() {
    setPromoDataState({
      ...promoDataState,
      discounts: [
        ...promoDataState?.discounts,
        { id: generateUniqueID(), countries: null, discount: null }
      ]
    })
  }

  function removeDiscountRow(index) {
    let discountClone = [...promoDataState?.discounts];
    discountClone.splice(index, 1);
    setPromoDataState({
      ...promoDataState,
      discounts: discountClone,
    })
  }


  function handleDiscountCountryChange(index, values) {
    const isAllSelected = values?.filter(value => value?.label === "All")?.length > 0;

    let clonedDiscounts = [...promoDataState?.discounts];
    let currentDiscount = clonedDiscounts[index]
    let discountsCountries = currentDiscount?.['countries'];
    discountsCountries = isAllSelected ? [{ label: "All", value: 0 }] : values;
    currentDiscount = { ...currentDiscount, countries: discountsCountries }

    clonedDiscounts[index] = currentDiscount;

    let filteredClonedDiscounts = clonedDiscounts?.filter(discount => {
      return discount?.countries?.filter(country => country?.label === "All")[0]
    });

    setPromoDataState({
      ...promoDataState,
      discounts: isAllSelected ? filteredClonedDiscounts : clonedDiscounts,
    });
    setErrors({
      ...errors,
      ...validate(
        { name: `countries-${index}`, value: values },
        { required: true }
      )
    });
    if (isAllSelected) {
      setIsAllCountriesSelected(true);
      setCountries([]);
    } else {
      countriesLookups();
      setIsAllCountriesSelected(false);
    }
  }


  function handleDiscountChange(index, value) {
    if (+value < 100) {
      let clonedDiscounts = [...promoDataState?.discounts];
      let currentDiscount = clonedDiscounts[index];
      currentDiscount = { ...currentDiscount, discount: value }
      clonedDiscounts[index] = currentDiscount;
      setPromoDataState({
        ...promoDataState,
        discounts: clonedDiscounts,
      });
      setErrors({
        ...errors,
        ...validate(
          { name: `discount-${index}`, value },
          { required: true }
        )
      });
    }
  }

  async function countriesLookups() {
    const format = await allCountries.map((t) => ({
      value: t.id,
      label: t.names[locale],
      code: t.country_code,
    }));
    setCountries([{ label: "All", value: 0 }, ...format]);
  }

  // get countries
  useEffect(() => {
    countriesLookups();
  }, [allCountries]);



  return (
    <div className='promo-city-container'>
      <h3 className='promo-city-header'>
        Dicounts
      </h3>
      <div className="promo-city-content col-12">
        {promoDataState?.discounts?.length > 0 ?
          promoDataState?.discounts?.map((discount, index) => {
            return (
              <div className='promo-city-discount' key={discount?.id}>
                <div className="promo-city-discount-item ">
                  {index === 0 ? <span className={errors?.[`countries-${index}`]?.required ? "text-danger" : ""}>Country</span> : null}
                  <Select
                    value={discount?.countries}
                    isMulti
                    id="country"
                    name="country"
                    options={countries}
                    onChange={(e) => handleDiscountCountryChange(index, e)}
                    styles={{ backgroundColor: 'red' }}
                  />
                  {errors?.[`countries-${index}`]?.required && <p className='m-0 text-danger'>Country is required</p>}
                </div>
                <div className="promo-city-discount-item">
                  <TextField
                    hasLabel={index === 0}
                    id="discount"
                    name="discount"
                    label={"General discount"}
                    value={discount?.discount ? discount?.discount : ""}
                    onChange={(e) => {
                      handleDiscountChange(index, e.target.value)
                    }}
                    errors={errors?.[`discount-${index}`]}
                    color={errors?.[`discount-${index}`]?.required ? "danger" : ""}
                  />
                </div>
                {isEditMode || index > 0 ?
                  <button
                    className='btn p-0 font-weight-bold shadow-none mt-2 remove-discount'
                    onClick={() => removeDiscountRow(index)}
                  >
                    <i className="fas fa-trash h5 text-danger"></i>
                  </button>
                  :
                  null
                }
              </div>
            )
          })
          :
          <div className='text-center text-danger text-capitalize my-3'>
            you must add one country at least
          </div>
        }

        {!isAllCountriesSelected ?
          <div className='d-flex align-items-center justify-content-end w-100'>
            <button
              className='btn text-success font-weight-bold shadow-none'
              onClick={addNewDiscountRow}
            >
              <i className="fas fa-plus-circle"></i>
              <span className='mx-1'>Add Country</span>
            </button>
          </div>
          :
          null
        }
      </div>
    </div>
  )
}
