import { formatGetRooms, formatPostRooms } from "data/property";
import { useEffect, useState } from "react";
import {
	fetchRoomNames,
	fetchRoomTypes,
	fetchRoomViews,
} from "services/lookups";
import { addRooms, EditRooms } from "services/property";
import Locale from "translations";

import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import CancelModal from "./CancelModal";
import Room from "./Room";

const locale = localStorage.getItem("currentLocale") || "en";

function Rooms(statusNum) {
	const { id, tab } = useParams();
	const history = useHistory();

	const { rooms, commons, supplierDetails } = Locale;
	const { propertyDetails } = usePropertyState();
	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const { progress, step } = useGlobalState();
	const [canceled, setCanceled] = useState(false);
	const [lookups, setLookups] = useState({
		types: [],
		names: [],
		views: [],
	});

	const [allRooms, setAllRooms] = useState([
		{
			type: "",
			name: "",
			view: "",
			count: 0,
			smokingPolicy: "",
			customName: "",
		},
	]);

	const setRoom = (i, name, value) => {
		let roomsClone = [...allRooms];
		roomsClone[i] = {
			...roomsClone[i],
			[name]: value,
		};
		setAllRooms(roomsClone);
	};

	const formatArray = (array) => {
		return array?.map((item) => (item = { label: item.name, value: item.id }));
	};
	const fetchLookups = async () => {
		const [types, names, views] = await Promise.all([
			fetchRoomTypes(locale),
			fetchRoomNames(locale),
			fetchRoomViews(locale),
		]);
		console.log("types, names, views", types);

		setLookups({
			types: formatArray(types.data.data.data),
			names: formatArray(names.data.data.data),
			views: formatArray(views.data.data.data),
		});
	};

	const addRoom = () => {
		setAllRooms([
			...allRooms,
			{
				type: "",
				name: "",
				view: "",
				count: "",
				smokingPolicy: "",
				customName: "",
			},
		]);
	};

	const deleteRoom = (index) => {
		let allRoomsClone = [...allRooms];
		allRoomsClone.splice(index, 1);
		setAllRooms(allRoomsClone);
	};

	const isRoomsValid = () => {
		let isValid = true;
		allRooms.forEach((room) => {
			const { type, customName, view, count, smokingPolicy } = room;
			if (
				Object.values({ type, customName, view, count, smokingPolicy }).some(
					(v) => v === ""
				)
			) {
				isValid = false;
			}
		});
		return isValid;
	};

	useEffect(() => {
		if (id && propertyDetails) {
			const formatted = formatGetRooms(propertyDetails?.property?.rooms);
			setAllRooms(formatted);
		}
	}, [propertyDetails]);

	// useEffect(() => {
	// 	async function fetchProperty() {
	// 		const res = await getProperty(id);
	// 		if (res.status === 200) {
	// 			propertyDispatch({ type: "propertyDetails", payload: res.data });
	// 		}
	// 	}
	// 	fetchProperty();
	// }, []);

	//

	const submit = async () => {
		if (isRoomsValid()) {
			if (id && propertyDetails?.property?.rooms?.length > 0) {
				const data = formatPostRooms(allRooms);
				const res = await EditRooms(id, { rooms: data });
				if (res.status == 200) {
					dispatch({ type: "progress", step: "4", current: "4" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					store.addNotification({
						title: "Rooms",
						message: "Edit Rooms Succussfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = formatPostRooms(allRooms);
				const res = await addRooms(id, { rooms: data });
				if (res.status === 201) {
					dispatch({ type: "progress", step: "4", current: "4" });
					propertyDispatch({ type: "propertyDetails", payload: res.data });
				}
			}
		} else {
			store.addNotification({
				title: "warning!",
				message: "Please fill all fields",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const reset = () => {
		setCanceled(true);
	};

	useEffect(() => {
		fetchLookups();
	}, []);

	//
	console.log("allRooms", allRooms);
	return (
		<div className="room">
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="border table-responsive">
				<table className="table custom-table  table-striped">
					<thead>
						<tr className="text-black">
							<th
								style={{ maxWidth: "140px", width: "140px", minWidth: "140px" }}
							>
								{rooms.type}
							</th>
							<th
								style={{ maxWidth: "120px", width: "120px", minWidth: "120px" }}
							>
								{rooms.name}
							</th>
							<th>{rooms.view}</th>
							<th>{rooms.smokingPolicy}</th>
							<th style={{ maxWidth: "60px", width: "60px", minWidth: "60px" }}>
								{rooms.count}
							</th>
							<th style={{ maxWidth: "70px", width: "80px", minWidth: "80px" }}>
								{rooms.MaxAdults}
							</th>
							<th
								style={{ maxWidth: "90px", width: "100px", minWidth: "100px" }}
							>
								{rooms.MaxChildrens}
							</th>
							<th
								style={{ maxWidth: "150px", width: "150px", minWidth: "150px" }}
							>
								{rooms.MaxAdultWithChild}
							</th>

							<th
								style={{ maxWidth: "50px", width: "50px", minWidth: "50px" }}
							></th>
						</tr>
					</thead>
					<tbody>
						{allRooms.map((room, i) => {
							return (
								<tr key={i}>
									<Room
										index={i}
										room={room}
										setRoom={setRoom}
										lookups={lookups}
										deleteRoom={deleteRoom}
									/>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<div className="text-success pointer">
							{tab !== "view" && (
								<button
									className="px-1 font14 btn-link text-success"
									onClick={addRoom}
									id="add-room"
								>
									<i className="fas fa-plus-circle"></i>
									Add Room
								</button>
							)}
						</div>
					</tfoot>
				</table>
			</div>
			{/* <ActionButton ID={id} StatusNum={statusNum} /> */}

			{/* <div className="row my-5 mx-0 justify-content-end">
        <button className="btn mx-2 cancel" onClick={reset} id="room-cancel">
          Cancel
        </button>
        <button
          className="btn btn-primary px-5"
          id="room-submit"
          onClick={submit}
        >
          Continue
        </button>
      </div> */}
			<div className="row my-5 mx-0 justify-content-between">
				<button
					onClick={() => history.push("/property-management")}
					className="btn cancel mx-2 "
					id="basic-cancel"
				>
					Back
				</button>

				{tab !== "view" && (
					<button
						className="btn btn-primary px-5"
						id="room-submit"
						onClick={submit}
					>
						continue
					</button>
				)}
			</div>
		</div>
	);
}

export default Rooms;
