import DatePickerField from 'components/shared/DatePickerField'
import SelectField from 'components/shared/SelectField'
import TextField from 'components/shared/TextField'
import Locale from 'translations'

function FilterInvoicesReport() {
  const {backOffice}=Locale
  

  return (
    <>
     <div className='row w-100 border border-bottom-0 d-flex align-items-center '>
      <div className='col-md-2'>
      <SelectField
      placeholder={"Select"}
      label={"Travel Agent"}
      />
      </div>
      <div className='col-md-2'>
      <DatePickerField
      placeholder={"Select"}
      label={"Travel Agent"}
      />
      </div>
      <div className='col-md-2'>
      <SelectField
      placeholder={"Select"}
      label={"Nights"}
      />
      </div>
      <div className='col-md-2 mt-2'>
            <label className='wrap-label'>Price</label>
          <div className='filter-warp-field'>
          <TextField
						type={"number"}
            					/>
            <p>To</p>
          <TextField
						type={"number"}
            					/>
          </div>
          </div>
      <div className='col-md-2 mt-2'>
            <label className='wrap-label'>Price</label>
          <div className='filter-warp-field'>
          <TextField
						type={"number"}
            extraTextPosition="append"
            extraText={"EGP"}						/>
            <p>To</p>
          <TextField
						type={"number"}
            extraTextPosition="append"
            extraText={"EGP"}						/>
          </div>
          </div>
      <div className='col-md-2 mt-2'>
            <label className='wrap-label'>Price</label>
          <div className='filter-warp-field'>
          <TextField
						type={"number"}
            extraTextPosition="append"
            extraText={"EGP"}						/>
            <p>To</p>
          <TextField
						type={"number"}
            extraTextPosition="append"
            extraText={"EGP"}						/>
          </div>
          </div>
    </div>
    		<div className="border border-bottom-0 crm-filter">
				<div className="col-md-6">
					<TextField type="text" placeholder={` ${backOffice.search}`} />
				</div>
			</div>
    </>
  )
}

export default FilterInvoicesReport