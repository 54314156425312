import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { listContracts } from "services/property";
import ContractFilter from "../Components/ContractList/ContractFilter";
import ContractTable from "../Components/ContractList/ContractTable";

import Locale from "translations";

export default function ContractList() {
	const history = useHistory();
	const { backOffice } = Locale;
	const { search } = useLocation();
	const [_, pages] = search.split("=");
	const [ProductList, setProductList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filterState, setFilterState] = useState({
		supplierName: "",
		hotelName: "",
		country: "",
		status: "",
	});
	async function fetchData() {
		const data = {
			supplier_name: filterState.supplierName,
			property_name: filterState.hotelName,
			country_id: filterState.country?.value,
			status: filterState.status?.value,
		};
		const res = await listContracts(data, pages);
		setProductList(res?.data.data);
		setMeta(res?.data.meta);
	}
	useEffect(() => {
		fetchData();
	}, [
		filterState.supplierName,
		filterState.hotelName,
		filterState.country_id,
		filterState.status,
	]);

	const goTo = (page) => {
		fetchData(null, page);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				{/* 				<h6 className="main-title ">{backOffice.contractList}</h6>
				 */}
				{/* <button className="btn text-white bg-blue-black rounded-0">
          Export
        </button> */}
			</div>
			<ContractFilter
				filterState={filterState}
				setFilterState={setFilterState}
			/>
			<ContractTable
				ProductList={ProductList}
				fetchData={fetchData}
				setProductList={setProductList}
				setMeta={setMeta}
				goTo={goTo}
				meta={meta}
			/>
		</>
	);
}
