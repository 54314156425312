export function PlusIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M11.9999 20.4853L11.9999 3.51472M20.4852 12L3.51465 12"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
			/>
		</svg>
	);
}
