import ModuleRow from "./ModuleRow";

export default function ModuleList({
	subscriptionData,
	handleModuleSelection,
	noSelection,
	expandAll,
}) {
	const allModulesRow =
		subscriptionData?.length > 0 &&
		subscriptionData.map((moduleData, index) => (
			<ModuleRow
				moduleData={moduleData}
				key={moduleData.id}
				handleModuleSelection={handleModuleSelection}
				noSelection={noSelection}
				expandAll={expandAll}
			/>
		));
	return (
		<div className="module-list">
			<div className="module-list-header">
				<div className="row mx-0">
					<div className="col-md-9 module-list">
						<div className="d-flex mt-1" style={{ gap: "8px" }}>
							<p className="ml-2">Bundles</p>
						</div>
					</div>

					<div className="col-md-3 modul-column">
						<p>Service Price</p>
					</div>
				</div>
			</div>
			<div className="module-list-body">
				{/** loop for all module */}
				{allModulesRow}
			</div>
			{/* <TotalPrice
				currency={subscriptionData?.currency}
				totalPrice={totalPrice}
			/> */}
		</div>
	);
}
