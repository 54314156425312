import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { getNotification } from "helpers/makeNotifications";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import {
	assginSuppliersTransportation,
	listSuppliersOnlineVisa,
	listTransportationRequests,
} from "services/Request";
import Locale from "translations";
import Filter from "./Filter";
import CustomModal from "./Modal";

export default function RequestsTransportation() {
	const { requests, backOffice } = Locale;
	const { locale } = useGlobalState();
	const { status } = useParams();
	const [ModalEdit, setModalEdit] = useState(false);
	const [allVisa, setAllVisa] = useState([]);
	const [ListSuppliers, setListSuppliers] = useState([]);
	const [assginData, setAssginData] = useState();
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		page: null,
		search: "",
		destination_id: null,
		cycle_type_id: null,
		created_at: null,
	});
	const toggleEdit = (id = null) => {
		setModalEdit(!ModalEdit);
		setAssginData({
			...assginData,
			id,
		});
	};

	const allVisaList = useMemo(() => {
		return allVisa.length > 0
			? allVisa.map((visa) => {
					return (
						<tr className="text-nowrap" key={visa.id}>
							<td>{visa.id}</td>
							<td>{visa.company}</td>
							<td>{visa.destination}</td>
							{/* <td>{visa.transportation_type}</td> */}
							<td>{visa.cycle_type} Cycle</td>
							<td>{visa.pax}</td>
							<td>
								<div>
									<p className="m-0 text-success text-nowrap">
										{visa.departure_date}
									</p>
									<p className="m-0 text-danger text-nowrap">
										{visa.return_date}
									</p>
								</div>
							</td>
							<td>{visa.request_date}</td>
							<td>
								{" "}
								{visa.currency} {visa.total_price}
							</td>

							{status === "waiting" ? (
								<td>
									<div className="d-flex justify-content-between">
										<div className="w-50">
											{" "}
											{visa.supplier_name ? visa.supplier_name : "-"}
										</div>
										<ShowForPermission permission="Transportation-Requests-Manage">
											<i
												onClick={() => toggleEdit(visa.id)}
												class="fas fa-edit text-primary pointer mx-2"
											></i>
										</ShowForPermission>
									</div>
								</td>
							) : (
								<td>
									<div className="d-flex justify-content-between">
										<div className="w-50">
											{" "}
											{visa.supplier_name ? visa.supplier_name : "-"}
										</div>
									</div>
								</td>
							)}

							<td
								className={`${
									visa.status === "waiting" ? "text-warning" : "text-success"
								}`}
							>
								{visa.status}
							</td>
							<td>
								<Link to={`/requests/transportation/${status}/${visa.id}`}>
									{locale === "en" ? (
										<i class="fas fa-angle-right"></i>
									) : (
										<i class="fas fa-angle-left"></i>
									)}
								</Link>
							</td>
						</tr>
					);
			  })
			: [];
	}, [allVisa]);

	useEffect(() => {
		const fetchOnlineVisaList = async () => {
			const response = await listTransportationRequests({
				...filter,
				assign_status: status,
				status: "approved",
				destination_id: filter?.destination_id?.value,
				cycle_type_id: filter?.cycle_type_id?.value,
				created_at: filter?.created_at
					? moment(filter?.created_at).format("YYYY-MM-DD")
					: "",
			});

			if (response.status === 200 || response.status === 201) {
				setAllVisa(response.data.data);
				setMeta(response.data.meta);
			}
		};
		if (status) {
			fetchOnlineVisaList();
		}
	}, [status, filter]);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	console.log("ffffff", filter);
	useEffect(() => {
		const fetchSuppliers = async () => {
			const res = await listSuppliersOnlineVisa();
			if (res.status === 200 || res.status === 201) {
				const formatted = res.data.data.map((item) => {
					return { value: item.id, label: item.name };
				});
				setListSuppliers(formatted);
			}
		};
		fetchSuppliers();
	}, []);

	const assginSupplier = async () => {
		const res = await assginSuppliersTransportation(assginData.id, assginData);
		if (res.status === 200 || res.status === 201) {
			getNotification(res.data.message);
			toggleEdit();
			setFilter({ ...filter });
		}
	};

	return (
		<div>
			<div
				className="tabs d-flex align-items-center  all-tabs "
				style={{ borderBottom: "1px solid #d9dde0" }}
			>
				<NavLink
					exact={true}
					to="/requests/transportation/waiting"
					activeClassName="is-active"
					className="d-block p-3  our-taps "
					onClick={() => {
						setFilter({
							page: null,
							company_name: "",
							destination_id: null,
							visa_type_id: null,
							request_date: null,
						});
					}}
				>
					<i class="fas fa-hourglass-half mx-1"></i>{" "}
					{requests.WaitingForAssignee}
				</NavLink>

				<NavLink
					exact={true}
					to="/requests/transportation/assigned"
					activeClassName="is-active"
					className="d-block mx-3 p-3   our-taps "
					onClick={() => {
						setFilter({
							page: null,
							company_name: "",
							destination_id: null,
							visa_type_id: null,
							request_date: null,
						});
					}}
				>
					<i class="fas fa-check-circle mx-1"></i>
					{requests.AssignedSupplier}
				</NavLink>
			</div>

			<div>
				<Filter filter={filter} setFilter={setFilter} />
			</div>

			<div className=" table-responsive px-4  our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>{requests.ID}</th>
							<th>{requests.TravelAgent}</th>
							<th>{requests.Destination}</th>
							{/* <th >{requests.TransportType}</th> */}
							<th>{requests.CycleType}</th>
							<th>{requests.Pax}</th>
							<th>{requests.TripDate}</th>
							<th>{requests.RequestDate}</th>
							<th>{requests.Price}</th>
							<th>{requests.supplier}</th>
							<th>{requests.status}</th>
						</tr>
					</thead>

					<tbody>
						{allVisa.length > 0 ? (
							allVisaList
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>

			<CustomModal
				modalIsOpen={ModalEdit}
				header={requests.SelectSupplier}
				toggle={toggleEdit}
				size={"md"}
				centered={true}
			>
				<div className=" ">
					<SelectField
						hasLabel={false}
						placeholder={requests.SelectSupplier}
						name="status"
						options={ListSuppliers}
						onChange={(e) => {
							setAssginData({
								...assginData,
								supplier_id: e.value,
								supplier_name: e.label,
							});
						}}
					/>

					<div className="d-flex  justify-content-end mt-5">
						<button className=" cancel-bg p-2 px-4 mx-3" onClick={toggleEdit}>
							Back
						</button>
						<button
							className="btn companies-btn"
							onClick={assginSupplier}
							disabled={!assginData?.supplier_id}
						>
							Save
						</button>
					</div>
				</div>
			</CustomModal>
		</div>
	);
}
