function KeyValue({ data, classStyle }) {
	return (
		<div
			className={`${classStyle} p-2 px-3 d-flex justify-content-between w-100 mb-3 `}
		>
			{data.map((item) => (
				<div>
					<p className="mb-0">{item.key}</p>
					<p className="fw-bolder">{item.value}</p>
				</div>
			))}
		</div>
	);
}

export default KeyValue;
