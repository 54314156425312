import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import AddBalancePopup from "./addBalancePopup";

export default function ListAllWallet({
	allWallet,
	goTo,
	setReload,
	handleSort,
	filter,
}) {
	const { payment, backOffice } = Locale;
	const [popupInfo, setpopupInfo] = useState({});
	const [isOpenModel, setIsOpenModel] = useState(false);
	const toggle = (data) => {
		setpopupInfo({
			...data,
		});
		setIsOpenModel((prev) => !prev);
	};
	return (
		<>
			<div className="our-border table-responsive px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>
								{backOffice.company}
								<span role="button" onClick={() => handleSort("company_sort")}>
									<i
										className={`${
											filter.company_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th>
								{backOffice.Country}
								<span role="button" onClick={() => handleSort("country_sort")}>
									<i
										className={`${
											filter?.country_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th>{backOffice.currency}</th>
							<th>
								{backOffice.totalamount}
								<span
									role="button"
									onClick={() => handleSort("total_amount_sort")}
								>
									<i
										className={`${
											filter?.total_amount_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th>
								{backOffice.totalused}
								<span
									role="button"
									onClick={() => handleSort("used_amount_sort")}
								>
									<i
										className={`${
											filter?.used_amount_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th>
								{backOffice.totalavailable}
								<span
									role="button"
									onClick={() => handleSort("total_available_sort")}
								>
									<i
										className={`${
											filter?.total_available_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th>
								Total Refund
								<span
									role="button"
									onClick={() => handleSort("total_refund_sort")}
								>
									<i
										className={`${
											filter?.total_refund_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th className="text-center" colSpan={"2"}>
								{payment.messages.Action}
							</th>
						</tr>
					</thead>
					<tbody>
						{allWallet.data && allWallet.data.length > 0
							? allWallet.data.map((item) => {
									return (
										<tr>
											<td>{item.company}</td>
											<td>{item.country}</td>
											<td>{item.currency}</td>
											<td>{item.total_amount}</td>
											<td>{item.total_used}</td>
											<td>{item.total_available}</td>
											<td>{item.total_refund}</td>
											<td className="d-flex gap-2 align-items-center">
												<Link to={"/wallet-Tarnsactions/" + item.id}>
													{" "}
													<p className="mb-0">
														<i class="fas fa-eye me-1"></i> View
													</p>
												</Link>
											</td>
											<td>
												<ShowForPermission permission="Financial-Companies-Wallets-Manage">
													<a
														style={{ color: "#00B545" }}
														onClick={() => toggle(item)}
														href={() => false}
													>
														<i class="fas fa-plus-circle me-1"></i>
														{backOffice.AddTransaction}
													</a>
												</ShowForPermission>
											</td>
										</tr>
									);
							  })
							: null}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={allWallet.meta} goTo={goTo} />
				</div>
			</div>
			<AddBalancePopup
				isOpen={isOpenModel}
				toggle={toggle}
				popupInfo={popupInfo}
				setpopupInfo={setpopupInfo}
				setReload={setReload}
			/>
		</>
	);
}
