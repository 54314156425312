import axios from "axios";
import { cleanEmpty } from "./general";
const vendorURL = process.env.REACT_APP_API_URL + "/api";

export const listVendor = async (params) => {
	return await axios
		.get(`${vendorURL}/vendors`, { params })
		.then((res) => res)
		.catch((err) => err.response);
};
export const addCommission = async (data) => {
	return await axios
		.post(`${vendorURL}/vendors/commissions`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateCommission = async (id, data) => {
	return await axios
		.put(`${vendorURL}/vendors/commissions/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const ViewVendor = async (id) => {
	return await axios
		.get(`${vendorURL}/vendors/${id}/commission`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const showVendor = async (id) => {
	return await axios
		.get(`${vendorURL}/vendors/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editVendors = async (id, data) => {
	return await axios
		.put(`${vendorURL}/vendors/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editVendor = async (id, data) => {
	return await axios
		.post(`${vendorURL}/vendors/${id}/commission`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteCommission = async (id) => {
	return await axios
		.delete(`${vendorURL}/vendors/commissions/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchVendorList = async () => {
	return await axios
		.get(`${vendorURL}/marketplace/providers`)
		.then((res) => res)
		.catch((err) => err.response);
};
/********************Vendor Wallet***************** */
export const listVendorWallets = async (id) => {
	return await axios
		.get(`${vendorURL}/vendor/${id}/wallet`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addVendorWallet = async (id, data) => {
	return await axios
		.post(`${vendorURL}/vendor/${id}/wallet`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addBalancesVendor = async (id, id_wallet, data) => {
	return await axios
		.put(`${vendorURL}/vendor/${id}/wallet/${id_wallet}/balance`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listVendorWalletsTransactions = async (id, id_wallet, params) => {
	return await axios
		.get(`${vendorURL}/vendor/${id}/wallet/${id_wallet}/transaction`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listVendorReservation = async (id, params) => {
	return await axios
		.get(`${vendorURL}/marketplace/providers/bookings/${id}`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listVendorFlightReservation = async (id, params) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/flights/reservations/list`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listVendorTransfersReservation = async (id, params) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/transfers/reservations/list`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const listVendorToursReservation = async (id, params) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/tours/reservations/list`, {
			params: params,
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewVendorReservation = async (id) => {
	return await axios
		.get(`${vendorURL}/marketplace/providers/bookings/${id}/view/1`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewVendorFlightReservation = async (id, reservation_id) => {
	return await axios
		.get(
			`${vendorURL}/providers/${id}/flights/reservations/view/${reservation_id}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editConfirmation = async (id, data) => {
	return await axios
		.post(
			`${vendorURL}/marketplace/providers/bookings/${id}/edit-confirmation-num`,
			data
		)
		.catch((err) => err.response);
};
export const FetchTransfersVendor = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/transfers/reservations/view/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getTransferInvoice = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/transfers/reservations/${brn}/invoice`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getAllTransferVoucher = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/transfers/reservations/${brn}/voucher`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getTransferVoucher = async (id, brn, voucher_id) => {
	return await axios
		.get(
			`${vendorURL}/providers/${id}/transfers/reservations/${brn}/voucher?item_id=${voucher_id}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const FetchToursVendor = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/tours/reservations/view/${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getToursInvoice = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/tours/reservations/${brn}/invoice`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getAllToursVoucher = async (id, brn) => {
	return await axios
		.get(`${vendorURL}/providers/${id}/tours/reservations/${brn}/voucher`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getToursVoucher = async (id, book_id, voucher_id) => {
	return await axios
		.get(
			`${vendorURL}/providers/${id}/tours/reservations/${book_id}/voucher/?item_id=${voucher_id}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const ConfigurationsModules = async (id) => {
	return await axios
		.get(`${vendorURL}/vendors/${id}/modules`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const ToggleModuleStatus = async (id, module) => {
	return await axios
		.put(`${vendorURL}/vendors/${id}/modules`, module)
		.then((res) => res)
		.catch((err) => err.response);
};
export const providerservices = async () => {
	return await axios
		.get(`${vendorURL}/lookups/provider-services`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const ToggleServices = async (id, items) => {
	return await axios
		.post(`${vendorURL}/vendors/${id}/services`,{services:items})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getProviderList = async ({id,brn}) => {
	return await axios
		.get(`${vendorURL}/marketplace/providers/services/${id}?service_name=${brn}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const createProvider = async ({id,data}) => {
	return await axios
		.post(`${vendorURL}/marketplace/providers/services/${id}/create`,data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editProvider = async ({id,data}) => {
	return await axios
		.post(`${vendorURL}/marketplace/providers/services/${id}/edit`,data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteProvider = async ({id,pro_id}) => {
	return await axios
		.get(`${vendorURL}/marketplace/providers/services/${id}/delete/${pro_id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
