import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { cancelTour, cancelTourItem } from "services/Request";
export default function CancelModel({
	model,
	toggleModel,
	details,
	cancelType,
	amount,
	preCancel,
	setBookingDetails,
}) {
	const [acceptCancel, setAcceptCancel] = useState(false);
	const { id } = useParams();
	async function cancelTours() {
		// here call api to cancel single tour or all tours
		const res = await cancelTour(id);
		if (res.status === 200) {
			toggleModel();
			setBookingDetails(res?.data?.tour);
			store.addNotification({
				title: "cancel Done",
				message: "cancel Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}
	async function cancelSingleTour() {
		const reservationId = details?.id;
		const cancelTourRes = await cancelTourItem(reservationId);
		if (cancelTourRes?.status === 200) {
			setBookingDetails(cancelTourRes?.data?.tour);
			store.addNotification({
				title: "cancel Done",
				message: "cancel Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}
	console.log("detailsdetails10001", details);
	return (
		<Modal
			size="md"
			className="mt-5 model-cancle "
			isOpen={model}
			toggle={toggleModel}
		>
			<ModalHeader
				className="align-items-center font-weight-bold hd-title-model"
				toggle={toggleModel}
			>
				Cancle Service
			</ModalHeader>

			<ModalBody>
				<div className="my-3">
					{/* tours details */}
					{cancelType === "single-item" ? (
						<CancelCardDetails details={details} />
					) : (
						details?.map((item) => {
							return (
								<div key={item?.id}>
									<CancelCardDetails details={item} />
									<hr />
								</div>
							);
						})
					)}
					{/* cancel summary */}
					<div className="d-flex flex-column align-items-end mt-2 py-2">
						<div>
							<span className="text-muted text-15 mb-0">Final Price :</span>
							<span className="font-weight-bold h6">{`${amount?.totalPrice?.toFixed(
								2
							)} ${amount?.currency}`}</span>
						</div>
						<div>
							<span className="text-muted text-15 mb-0">
								cancellation Fees :
							</span>
							<span className="font-weight-bold h6">{`${preCancel?.charge} ${preCancel?.currency}`}</span>
						</div>
						{preCancel?.charge === 0 && (
							<p className="text-success mb-0">Free Cancellation</p>
						)}
					</div>
					<hr />
					{/* summary */}
					<div className="d-flex flex-column align-items-end border-top-gray mt-2 py-2">
						<div>
							<span className="text-muted text-15">Paid Amount: </span>
							<span className="font-weight-bold h6">{`${amount?.totalPrice?.toFixed(
								2
							)} ${amount?.currency}`}</span>
						</div>
						<div>
							<span className="text-muted text-15">Total To Be Refunded: </span>
							<span className="font-weight-bold h6">
								{" "}
								{`${+(amount?.totalPrice - preCancel?.charge).toFixed(2)} ${
									amount?.currency
								}`}
							</span>
						</div>
					</div>

					{/* accept and proceed to cancel */}
					<div className="d-flex justify-content-start">
						<input
							type="Checkbox"
							name="acceptCancel"
							id="acceptCancel"
							checked={acceptCancel}
							onChange={() => setAcceptCancel(!acceptCancel)}
						/>
						<label
							htmlFor="acceptCancel"
							className="text-muted text-14 my-0 mx-1 pointer user-select-none"
						>
							By Clicking On “Cancel Service” You Are Accepting Cancellation
							Charges
						</label>
					</div>
					<div className="d-flex justify-content-center">
						<button
							className="btn btn-danger py-2 px-5 w-100 font-weight-bold mt-3 text-15 text-uppercase"
							disabled={!acceptCancel}
							onClick={() => {
								if (cancelType === "single-item") {
									cancelSingleTour();
								} else {
									cancelTours();
								}
							}}
						>
							Cancel Service
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}

export function CancelCardDetails({ details }) {
	return (
		<div className="d-flex justify-content-between">
			<div className="d-flex flex-md-nowrap flex-wrap w-100">
				<div className="tour-card-img">
					<img
						src={details?.details?.images?.[0]}
						alt="tours"
						className="img-fluid transfer-booking-item-img"
					/>
				</div>
				<div className="d-flex flex-column px-2 w-100">
					<div className="d-flex justify-content-between">
						<h6 className="mb-2">
							<span className="font-weight-bold text-dark-blue h5">
								{details?.name}
							</span>
						</h6>
						<p className="text-success text-center">
							<span className="d-block">
								{" "}
								{details?.details?.price?.toFixed(2)}{" "}
								{details?.details?.currency}
							</span>
						</p>

						{/* <p className="text-success text-center">
							<span className="d-block"> {details?.price}</span>
							{details?.currency}
						</p> */}
					</div>

					<div className="tour-booking-item-info">
						<i className="fas fa-map-marker-alt"></i>
						<p className="mx-1 mb-0">
							{details?.details?.destinations?.map((city) => {
								return (
									<span key={city?.code}>
										{city?.name} ({city?.code}),{" "}
									</span>
								);
							})}
						</p>
					</div>
					<div className="tour-booking-item-info">
						<i className="far fa-calendar-alt"></i>
						<span className="mx-1 font-weight-bold">
							{moment(details?.start_date).format("DD/MM/YYYY")} (
							{moment(details?.start_date).format("dddd")})
						</span>
					</div>
					<div className="tour-booking-item-info">
						<i className="far fa-user"></i>
						<span className="mx-2">
							{details?.adults} {"Adults"}
						</span>
						{details?.children?.length > 0 ? (
							<span>
								{details?.children?.length} {"Children"} (
								{details?.children?.map((age, index) => {
									return (
										<span key={`age-${index}-${age}`}>
											{age}
											{index !== details?.children?.length - 1 ? ", " : " "}
										</span>
									);
								})}
								Years Old )
							</span>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
