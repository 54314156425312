import { useEffect, useRef, useState } from "react";
import ClockIcon from "../Icons/clockIcon";

export const TimeField = ({ changeValue, label, value, errorMsg }) => {
	const [firstOpen, setFirstOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [time, setTime] = useState({
		hours: "00",
		minutes: "00",
	});

	useEffect(() => {
		// Set the internal state based on the value prop
		if (value && value.hours && value.minutes) {
			setTime({
				hours: value.hours,
				minutes: value.minutes,
			});
		}
	}, [value]);

	const dropdownRef = useRef(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		setFirstOpen(true);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};
	useEffect(() => {
		if (!isOpen && firstOpen) {
			changeValue(time.hours, time.minutes);
		}

		return () => {};
	}, [isOpen]);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const handleTime = ({ key, value }) => {
		setTime((prev) => ({
			...prev,
			[key]: value,
		}));
	};
	console.log(time);

	return (
		<div className={`${errorMsg ? "field-has-error" : ""}`}>
			{/* ${errorMsg ? "field-has-error" : ""} */}
			<label className={`d-block time-label `}>{label}</label>
			<div className="custom-time-field-container" ref={dropdownRef}>
				<div className={`custom-time-field `} onClick={toggleDropdown}>
					<span>{`${time.hours}h ${time.minutes}min`}</span>
					<ClockIcon />
				</div>
				{isOpen && (
					<div className="time-field-row">
						<div className="time-details">
							<input
								placeholder="00"
								name="hours"
								value={time.hours}
								type="number"
								onChange={(e) => {
									let value = e.target.value.padStart(2, "0");

									if (value.length > 2 && value[0] === "0") {
										value = value.slice(1);
									}
									handleTime({ key: "hours", value });
								}}
							/>
							<span>H</span>
							<span>:</span>
							<input
								placeholder="00"
								name="minutes"
								value={time.minutes}
								type="number"
								min={0}
								max={59}
								onChange={(e) => {
									let value = e.target.value.padStart(2, "0");

									if (value.length > 2 && value[0] === "0") {
										value = value.slice(1);
									}
									if (+value > 59) {
										value = "59";
									}

									handleTime({ key: "minutes", value });
								}}
							/>
							<span>Min</span>
							{/* <span className="primary-color">PM</span> */}
						</div>
					</div>
				)}
			</div>
			{errorMsg && (
				<small className={`${errorMsg ? "has-error" : ""}`}>{errorMsg}</small>
			)}
		</div>
	);
};
