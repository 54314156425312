import { useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import breadCrumbKeys from "./breadCrumb.json";

export default function BreadCrumb() {
	const location = useLocation();
	var pathsArray = location.pathname.split("/").slice(1);
	return (
		<div className="breadcrumb-app mb-3 p-3">
			<Breadcrumb listTag="div">
				{pathsArray?.map((item, index) => (
					<BreadcrumbItem
						href={`/${item}`}
						//tag={`${pathsArray?.length - 1 == index ? "none" : "a"}`}
						tag={"none"}
						active={pathsArray?.length - 1 == index ? true : false}
					>
						{breadCrumbKeys[item] != undefined ? breadCrumbKeys[item] : item}
					</BreadcrumbItem>
				))}
			</Breadcrumb>

			{/* {pathsArray?.map((item, index) =>
				breadCrumbKeys[item] != undefined ? (
					<Breadcrumb listTag="div">
						<BreadcrumbItem
							href={`/${item}`}
							tag={`${pathsArray?.length - 1 == index ? "none" : "a"}`}
							active={pathsArray?.length - 1 == index ? true : false}
						>
							{breadCrumbKeys[item] != undefined ? breadCrumbKeys[item] : item}
						</BreadcrumbItem>
					</Breadcrumb>
				) : null
			)} */}
		</div>
	);
}
