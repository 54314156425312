import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";

import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { requests, backOffice } = Locale;
	const [countries, setCountries] = useState([]);
	const { allCountries, locale } = useGlobalState();

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const visaTypes = [
		{
			id: 1,
			name: { ar: "عمرة", en: "Umra" },
		},
		{
			id: 2,
			name: { ar: "عمل", en: "Work" },
		},
		{
			id: 3,
			name: { ar: "عودة", en: "Return" },
		},
		{
			id: 4,
			name: { ar: "مرور", en: "Umra" },
		},
		{
			id: 5,
			name: { ar: "عمل موسمى", en: "Seasonal work" },
		},
		{
			id: 6,
			name: { ar: "سعودى", en: "Saudi" },
		},
		{
			id: 7,
			name: { ar: "وثيقة", en: "Policy" },
		},
		{
			id: 8,
			name: { ar: "مبعدين", en: "Banished" },
		},
		{
			id: 9,
			name: { ar: "مرحلين", en: "Deportation" },
		},
		{
			id: 10,
			name: { ar: "مجلس تعاون", en: "Cooperation Council" },
		},
		{
			id: 11,
			name: { ar: "أصدار الخارج", en: "Overseas version" },
		},
		{
			id: 12,
			name: { ar: "اقامة", en: "Accommodation" },
		},
		{
			id: 13,
			name: { ar: "حج", en: "Hajj" },
		},
		{
			id: 14,
			name: { ar: "عودة أجانب", en: "Return of foreigners" },
		},
		{
			id: 15,
			name: { ar: "مرور أجانب", en: "Passage of foreigners" },
		},
		{
			id: 16,
			name: { ar: "زيارة أجانب", en: "Visit foreigners" },
		},
		{
			id: 17,
			name: { ar: "خاص", en: "Private" },
		},
		{
			id: 18,
			name: { ar: "دبلوماسي", en: "Diplomat" },
		},
		{
			id: 19,
			name: { ar: "خروج نهائي", en: "Final Exit" },
		},
	];

	const visaTypesLookups = visaTypes.map((t) => ({
		value: t.id,
		label: t.name[locale],
	}));

	return (
		<div className=" py-2 mt-4 px-3 our-border border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						/* 						hasLabel={false}
						 */ value={filter.company_name}
						onChange={(e) => {
							setFilter({
								...filter,
								company_name: e.target.value,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						/* 						hasLabel={false}
						 */ placeholder={requests.Destination}
						label={requests.Destination}
						name="status"
						options={countries}
						value={filter.destination_id}
						onChange={(e) => {
							setFilter({
								...filter,
								destination_id: e,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={requests.date}
						onOutsideClick={false}
						/* 						hasLabel={false}
						 */ date={filter?.request_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								request_date: e,
								page: null,
							});
						}}
					/>
				</div>

				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={() => {
							setFilter({
								page: filter.page,
								company_name: "",
								destination_id: null,
								visa_type_id: null,
								request_date: null,
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />
						{/* 						<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
						 */}{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
