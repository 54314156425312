import TextField from "components/shared/TextField";
import { Modal, ModalBody } from "reactstrap";

const IncludeVisaModal = ({
	isOpen,
	toggle,
	incudeVisaIndexs,
	viewConfiguration,
	handleChenageinPackages,
	countryBase,
	handleAddIncludeVisa,
	handleRemovenIcludeVisa,
}) => {
	const { index_visa_type, index_residance, index_package } = incudeVisaIndexs;
	let counrtyX = viewConfiguration.filter(
		(res) => res.country == countryBase
	)[0];
	const arr =
		counrtyX.visa_types[index_visa_type]?.residences[index_residance]
			?.package_plans[index_package];
	console.log(arr);
	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle}>
				<div className="lan d-flex justify-content-between align-items-baseline p-2 border-bottom vendor-wallet-view-popup ">
					<p className="  h4  font-weight-bold">Include Visa </p>
					<i
						className="far fa-times-circle fa-lg  pointer"
						onClick={() => toggle()}
					></i>
				</div>
				<ModalBody className="lan d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<div className="container">
						{arr?.visa_include?.length > 1 ? (
							arr?.visa_include.slice(1)?.map((item, index) => (
								<div className="row">
									<div className="col-md-9">
										<TextField
											type="text"
											label={"include package"}
											placeholder={"include package"}
											name="visa_include"
											onChange={(e) => {
												handleChenageinPackages(
													e.target.value,
													index_residance,
													index_visa_type,
													index_package,
													"visa_include",
													index + 1
												);
											}}
											value={item.name ?? item}
										/>
									</div>
									{index == arr?.visa_include.length - 2 && (
										<div className="col-md-1 mt-4">
											<button
												className="btn copyIcon-configRow  mt-3"
												onClick={() =>
													handleAddIncludeVisa(
														index_residance,
														index_visa_type,
														index_package
													)
												}
											>
												<i class="fas fa-plus"></i>
											</button>
										</div>
									)}
									<button
										className="btn copyIcon-configRow text-danger  mt-4"
										onClick={() =>
											handleRemovenIcludeVisa(
												index_residance,
												index_visa_type,
												index_package,
												index + 1
											)
										}
									>
										<i class="fas fa-trash"></i>
									</button>
								</div>
							))
						) : (
							<div className="row">
								<div className="col-md-2 mt-4">
									<button
										className="btn copyIcon-configRow  mt-3"
										onClick={() =>
											handleAddIncludeVisa(
												index_residance,
												index_visa_type,
												index_package
											)
										}
									>
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>
						)}

						<div className="d-flex flex-sm-row-reverse">
							<button className="btn btn-primary  mx-3" onClick={toggle}>
								Save
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default IncludeVisaModal;
