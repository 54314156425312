import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listCompanies = async (params) => {
	return await axios
		.get(`${backOfficeApi}/company-management`, { params: cleanEmpty(params) })
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewCompanie = async (id) => {
	return await axios
		.get(`${backOfficeApi}/company-management/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusCompanie = async (id) => {
	return await axios
		.patch(`${backOfficeApi}/company-management/${id}/change-status`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusEmployee = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/change_user_status`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteCompanie = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/company-management/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteEmployee = async (id, data) => {
	return await axios
		.delete(
			`${backOfficeApi}/company-management/${id}/users/admin/delete_user?user_id=${data}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateCompanie = async (data, id) => {
	return await axios
		.put(`${backOfficeApi}/company-management/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const uploadFile = async (data) => {
	return await axios
		.post(`${backOfficeApi}/files/upload`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchImages = async (id) => {
	return await axios
		.get(`${backOfficeApi}/files/fetch/${id}`)
		.then((res) => res.config.url)
		.catch((err) => err.response);
};

export const listEmployees = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/list_company_users`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addEmployee = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/create_new_user_to_company`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editEmployee = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/edit_user`,
			params
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const groupsLookups = async (id, data) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/groups_lookup`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listGroups = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/list_groups_of_company`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteGroup = async (company_id, group_id) => {
	return await axios
		.delete(
			`${backOfficeApi}/company-management/${company_id}/users/admin/groups_delete/${group_id}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteBranch = async (company_id, branch_id) => {
	return await axios
		.delete(
			`${backOfficeApi}/company-management/${company_id}/users/admin/branches_delete/${branch_id}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listBranches = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/list_branch_of_company`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPermission = async (id) => {
	return await axios
		.get(
			`${backOfficeApi}/company-management/${id}/users/admin/list_permissions`
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addGroup = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/create_new_group_to_company`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editGroup = async (company_id, group_id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${company_id}/users/admin/edit_group/${group_id}`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addBranch = async (id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${id}/users/admin/create_new_branch_to_company`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editBranch = async (company_id, branch_id, params) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${company_id}/users/admin/edit_branch/${branch_id}`,
			cleanEmpty(params)
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addNewCompany = async (data) => {
	return await axios
		.post(`${backOfficeApi}/company-management/add-company`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportToExcel = async (params) => {
	return await axios
		.get(`${backOfficeApi}/company-management/export/companies`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listSubscriptionPlan = async (id) => {
	return await axios
		.get(`${backOfficeApi}/company/${id}/subscription-plan`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewSubscriptionCommission = async (company_id, plan_id) => {
	return await axios
		.get(
			`${backOfficeApi}/company-management/${company_id}/plan/${plan_id}/commissions`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const applySubscriptionCommission = async (
	company_id,
	plan_id,
	data
) => {
	return await axios
		.post(
			`${backOfficeApi}/company-management/${company_id}/plan/${plan_id}/commissions`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listSubscriptionPackagePlan = async (params) => {
	return await axios
		.get(`${backOfficeApi}/subscription-package`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusPackagePlan = async (id, data) => {
	return await axios
		.put(`${backOfficeApi}/subscription-package/${id}/status`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
