import { Rating } from "@material-ui/lab";
import { useGlobalState } from "context/global";
import moment from "moment";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { redirectReservation } from "services/b2c";
import Locale from "translations";

export default function RedirectTableRow({
	packageItem,
	toggleItineraryModal,
	setProductItinerary,
	packageTypeUmrahOrHajj,
	allRoomAvailabilities,
}) {
	const { locale } = useGlobalState();
	const { backOffice } = Locale;
	const { id } = useParams();
	const hotels = packageItem?.itinerary?.filter(
		(itinerary) => itinerary?.item?.name === "hotel"
	);
	const makkahHotels =
		packageTypeUmrahOrHajj &&
		hotels?.filter((hotel) => hotel?.itemable?.city_name === "Makkah");
	const madinahHotels =
		packageTypeUmrahOrHajj &&
		hotels?.filter((hotel) => hotel?.itemable?.city_name === "Madinah");
	const airLines = [
		...new Set(
			packageItem?.itinerary
				?.filter(
					(itinerary) => itinerary?.item?.name === "external transportation"
				)
				.map((item) => item?.itemable?.transporter?.name)
		),
	];
	const packageHasMazar = packageItem?.product_items?.filter(
		(product) => product?.item?.name === "sightseeing"
	);
	const nightsCount = hotels.reduce(
		(accumulator, currentValue) =>
			accumulator + currentValue?.itemable?.night_count,
		0
	);

	async function handleRedirectReservation() {
		const data = {
			reservation_num: id,
			product_uuid: packageItem?.product_uuid,
		};
		const redirectRes = await redirectReservation(data);
		if (redirectRes?.status >= 200 && redirectRes?.status < 300) {
			store.addNotification({
				title: "Redirect",
				message: "Reservation Redirected Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			//
		}
	}

	return (
		<tr key={packageItem?.product_uuid}>
			<td>
				<i class="fas fa-user-circle"></i>
				<span className="mx-1">{packageItem?.company_name?.[locale]}</span>
			</td>
			<td>{packageItem?.name?.[locale]}</td>
			<td>
				<span className="d-block">
					{moment(packageItem?.departure_date_at).format("DD-MMM-YYYY")}
				</span>
				<span className="d-block">{nightsCount} Nights</span>
			</td>
			<td>{airLines?.join(",")}</td>

			{/* if package hajj or umarh render to types of hotles else render hotels */}
			{packageTypeUmrahOrHajj ? (
				<>
					<td>
						{makkahHotels?.length > 0 ? (
							makkahHotels?.map((hotel) => <RenderHotelItem hotel={hotel} />)
						) : (
							<p className="text-center m-0">-</p>
						)}
					</td>
					<td>
						{madinahHotels?.length > 0 ? (
							madinahHotels?.map((hotel) => <RenderHotelItem hotel={hotel} />)
						) : (
							<p className="text-center m-0">-</p>
						)}
					</td>
				</>
			) : (
				<td>
					{hotels?.map((hotel) => (
						<RenderHotelItem hotel={hotel} />
					))}
				</td>
			)}

			<td>
				{packageHasMazar?.length > 0 ? (
					<i className="far fa-check-circle text-success"></i>
				) : (
					<i className="fas fa-times text-danger"></i>
				)}
			</td>

			{allRoomAvailabilities?.map((room) => {
				return (
					<td>
						{room?.adult_price}
						{packageItem?.product_price?.currency_code}
					</td>
				);
			})}

			<td>
				<button
					className="redirect-badge p-1"
					onClick={handleRedirectReservation}
				>
					<i class="fas fa-random"></i>
					<span className="mx-1">Redirect Package</span>
				</button>
				<i
					class="fas fa-eye pointer mx-1"
					onClick={() => {
						toggleItineraryModal();
						setProductItinerary(packageItem?.itinerary);
					}}
				></i>
			</td>
		</tr>
	);
}

export function RenderHotelItem({ hotel }) {
	const hotelData = hotel?.itemable?.hotel;
	return (
		<div key={hotel?.id} className="mb-2">
			<span className="d-block text-capitalize">{hotelData?.name}</span>
			<p className="m-0 d-flex flex-wrap align-items-center justify-content-center">
				<Rating value={+hotelData?.rating} readOnly size="small" />
				<span className="mx-1">{hotel?.itemable?.night_count} Nights</span>
			</p>
		</div>
	);
}
