import Model from "components/model";
import { Arrow } from "modules/backOffice/Components/SideBar/Icons";
import { useState } from "react";
import { Card, CardBody, Collapse, ModalBody } from "reactstrap";

export default function InfoPlan({ isOpen, toggle, plan }) {
	const [openIndex, setOpenIndex] = useState(null);

	const toggleCollapse = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<Model
			isOpen={isOpen}
			title="Module Details"
			toggle={toggle}
			hasHeader={true}
			hasFooter={false}
			className="info-plan"
			size="md"
		>
			{isOpen && plan && (
				<ModalBody>
					<div className="d-flex" style={{ flexDirection: "column", gap: 12 }}>
						{plan.plans.map((moduleData, index) => (
							<PlanData
								key={index}
								moduleData={moduleData}
								isCollapseOpen={openIndex === index}
								toggleCollapse={() => toggleCollapse(index)}
							/>
						))}
					</div>
				</ModalBody>
			)}
		</Model>
	);
}

const PlanData = ({ moduleData, isCollapseOpen, toggleCollapse }) => {
	return (
		<>
			<div
				className="d-flex justify-content-between align-items-center pb-3"
				style={{ borderBottom: "1px solid #EEEEEE" }}
			>
				<div className="d-flex align-items-center">
					<p className="fs-5" style={{ color: "#2C302E", fontWeight: 800 }}>
						{moduleData?.name}
					</p>
					<button className="p-0 bg-transparent ms-2" onClick={toggleCollapse}>
						<Arrow
							style={{
								width: 8,
								transform: `rotate(${isCollapseOpen ? "-90deg" : "90deg"})`,
								transition: "transform .3s ease-in-out",
							}}
						/>
					</button>
				</div>

				<div>
					<span className="fs-5" style={{ color: "#1E85FF", fontWeight: 800 }}>
						{new Intl.NumberFormat("en-US", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(moduleData.price)}
					</span>
					<span className="fs-5 ms-2">{moduleData.currency}</span>
				</div>
			</div>

			<div>
				<Collapse isOpen={isCollapseOpen}>
					<Card className="border-0">
						<CardBody className="p-0 ps-4">
							<p className="h2 mb-4">Includes: </p>
							<ul className="row w-100" style={{ rowGap: 14, paddingLeft: 12 }}>
								{moduleData.modules
									.filter((item) => item.status === "active")
									.map((item, index) => (
										<li
											key={index}
											className="col-6 fs-5 text-gray list-item-bullet"
											style={{ listStyle: "none", "--bg-color": "#007BFF" }}
										>
											{item.name}
										</li>
									))}
							</ul>
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</>
	);
};
