function AmountItem({ title, number, className }) {
	return (
		<div className={`px-2 ${className}`}>
			<div className="stat-item amount-item-financial ">
				<h6 className="stat-title">{title}</h6>
				<div className="d-flex justify-content-between align-items-center">
					<h4 className="stat-num">{number}</h4>
				</div>
			</div>
		</div>
	);
}

export default AmountItem;
