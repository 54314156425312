import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Switch from "react-switch";

export default function Company({ company, changeStatus, removeCompany }) {
	const status = true;
	const { locale } = useGlobalState();

	return (
		<tr>
			<td>
				{locale == "en"
					? company?.name?.en ?? company?.name?.ar
					: company?.name?.ar}
			</td>
			<td>{company?.web_slug ?? "-"}</td>
			<td>{company?.country?.name}</td>
			<td>{company.business_email}</td>
			<td>{company.auto_reservation == "0" ? "disabled" : "Enabled"}</td>
			{/* <td>{company.safa_key ? company.safa_key : "No key"}</td> */}
			<td
				className={`${
					company?.status?.name === "active" ? "text-success" : "text-danger"
				}`}
			>
				{company?.status?.name}
			</td>
			{/* <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td>
      <td>
        {status ? (
          <i class="fas fa-check text-success mx-2"></i>
        ) : (
          <i class="fas fa-ban text-danger mx-2"></i>
        )}
      </td> */}
			<td>
				<ShowForPermission permission="Tourism-Company-Manage">
					{company?.status?.name === "unverified" ||
					company?.status?.name === "inactive" ? (
						<>
							<Switch
								height={24}
								width={45}
								handleDiameter={20}
								className="mx-2 align-middle"
								onChange={() => changeStatus(company.id, company?.status?.name)}
								checked={false}
							/>
						</>
					) : (
						<Switch
							height={24}
							width={45}
							handleDiameter={20}
							className="mx-2 align-middle"
							onChange={() => changeStatus(company.id, company?.status?.name)}
							checked={true}
							onColor="#1777fb"
						/>
					)}{" "}
					<i
						class="fas fa-trash text-danger pointer"
						onClick={() => removeCompany(company.id)}
					></i>
				</ShowForPermission>

				<Link to={`/tourism-company/profile/${company.id}`}>
					<i class="fas mx-1 fa-edit"></i>
				</Link>
			</td>
		</tr>
	);
}
