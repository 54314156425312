import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ListReservations } from "services/companiesReservations";
import Locale from "translations";
import LooksupBranches from "../../lookups/fetchBranches";
import LooksupGroups from "../../lookups/fetchGroups";
import Reservation from "./Reservation";

export default function Reservations() {
	const { company_id } = useParams();
	const [reservations, setReservations] = useState([]);
	const { backOffice, rooms, teamManagement } = Locale;
	const [meta, setMeta] = useState(null);
	const Groups = LooksupGroups(company_id);
	const Branches = LooksupBranches(company_id);

	const [filters, setFilter] = useState({
		name: "",
		status: null,
	});

	useEffect(() => {
		const fetchListReservations = async () => {
			const res = await ListReservations(company_id, { ...filters });
			if (res?.status === 200 || res?.status === 201) {
				setReservations(res.data.data);
				setMeta(res?.data?.meta);
			}
		};

		fetchListReservations();
	}, [filters]);

	const allReservations = reservations?.map((item, index) => (
		<Reservation key={index} reservation={item} />
	));

	const goTo = (page) => {
		setFilter({ ...filters, page: page });
	};
	return (
		<>
			{/*     <Filter
        filter={filters}
        setFilter={setFilter}
        Groups={Groups}
        Branches={Branches}
      /> */}
			<div className="border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						{allReservations?.length > 0 ? (
							<tr>
								<th className="CompanyHead-title  ">BRN</th>
								<th className="CompanyHead-title  ">{backOffice.hotelName}</th>
								{/* <th className="CompanyHead-title text-gray ">{rooms.type}</th> */}
								{/* <th className="CompanyHead-title text-gray ">
                {backOffice.Availability}
              </th> */}
								<th className="CompanyHead-title  ">
									{backOffice.BookingDate}
								</th>
								<th className="CompanyHead-title ">{backOffice.checkIn}</th>
								<th className="CompanyHead-title ">{backOffice.NoofNights}</th>
								<th className="CompanyHead-title ">{backOffice.TotalPrice}</th>
								{/* <th className="CompanyHead-title text-gray ">
                {backOffice.groups}
              </th> */}
								<th className="CompanyHead-title ">{backOffice.Passport}</th>
								<th className="CompanyHead-title ">Source</th>
								<th className="CompanyHead-title ">
									{teamManagement.tableStatus}
								</th>
							</tr>
						) : (
							<th className="CompanyHead-title text-center">
								{backOffice.noResult}{" "}
								<i class="fas fa-exclamation-circle text-info"></i>
							</th>
						)}
					</thead>
					<tbody>{allReservations?.length > 0 ? allReservations : null}</tbody>
				</table>

				<Pagination info={meta} goTo={goTo} items={10} />
			</div>
		</>
	);
}
