import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { listUsers } from "services/managementTeam";
import Locale from "translations";
import Filter from "../Filter";

import ListUsers from "./ListUsers";

export default function Users() {
	const { backOffice, teamManagement } = Locale;
	const { pathname } = useLocation();
	const [users, setUsers] = useState();
	const [meta, setMeta] = useState(null);

	const [filter, setFilter] = useState({
		status: null,
		q: "",
		page: 1,
	});
	const [deleting, setdeleting] = useState(false);
	useEffect(async () => {
		const res = await listUsers({ ...filter, status: filter?.status?.value });
		if (res.status === 200) {
			setUsers(res.data.data);
			setMeta(res?.data?.meta);
		}
	}, [filter, deleting]);
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
		console.log("setFilter", filter);
	};

	return (
		<>
			<header className="d-flex flex-column  our-borderborder-bottom">
				<p className="h4 main-title ">{backOffice.teamManagement}</p>
				<div className="row">
					<div className="col-10 d-flex p-0 all-tabs">
						<NavLink
							exact={true}
							to={`/management-team/users`}
							activeClassName="is-active "
							className="our-taps nav-icon__header"
						>
							<p className="m-0">{backOffice.users}</p>

							{/* <div className="nav-icons__wrapper">
								<p className="">{backOffice.users}</p>
									{pathname.includes("/management-team/users") ? (
									<i class="fas fa-caret-up  nav-icon"></i>
								) : (
									<i class="fas fa-caret-up  nav-icon__notActive "></i>
								)}
							</div> */}
						</NavLink>

						<ShowForPermission
							permission={[
								"Team-Management-Groups-View",
								"Team-Management-Groups-Manage",
							]}
						>
							<NavLink
								exact={true}
								to={`/management-team/groups`}
								activeClassName="is-active"
								className="our-taps nav-icon__header"
							>
								<p className="m-0"> {backOffice.groups}</p>

								{/* <div className="nav-icons__wrapper">
								<p className=""> {backOffice.groups}</p>
									{pathname.includes("/management-team/groups") ? (
									<i class="fas fa-caret-up nav-icon"></i>
								) : (
									<i class="fas fa-caret-up  nav-icon__notActive "></i>
								)}
							</div> */}
							</NavLink>
						</ShowForPermission>
					</div>
					<div className="col-2  d-flex justify-content-end align-items-center">
						<ShowForPermission permission="Team-Management-Users-Manage">
							<Link
								type="button"
								class="btn  btn-success px-4"
								to={"/management-team/add/user"}
							>
								{backOffice.addNewUse}
							</Link>
						</ShowForPermission>
					</div>
				</div>
			</header>
			<div className="row  m-0 bg-teamMange_filter w-100  mt-3">
				<div className="col-12 p-0">
					<Filter filter={filter} setFilter={setFilter} />
				</div>
				<ListUsers
					users={users}
					setdeleting={setdeleting}
					meta={meta}
					goTo={goTo}
				/>
			</div>
		</>
	);
}
