import CustomModal from '../Modal';


export default function ReapplyVisaModal({ isOpen, toggle, reason, handleReasonChange, submitReason }) {
  return (
    <CustomModal
      modalIsOpen={isOpen}
      header={'Reapply Visa'}
      centered={true}
      toggle={toggle}
    >
      <div>
        <p className="m-0">Please Provide Reason For Reapply</p>

        <textarea
          placeholder={`Reason For Re Apply….`}
          name="reject-reason"
          id="reject-reason"
          rows="5"
          className="w-100 control-field__input h-auto"
          value={reason}
          onChange={(e) => handleReasonChange(e.target.value)}
        />

        <button
          className="btn btn-reapply w-100"
          onClick={() => submitReason()}
          disabled={reason.length < 3}
        >
          Confirm
        </button>
      </div>
    </CustomModal>
  )
}
