import ShowForPermission from "helpers/showForPermission";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ServicesImages from "./ServiceImg";

export default function ConsumnerConfigurationTable({
	servicesListList,
	toggleServicesModal,
}) {
	let { id } = useParams();
	return (
		<>
			<div className="border">
				<div className="d-flex justify-content-between align-items-center border p-3">
					<h3 className="h6">Configuration</h3>
					<ShowForPermission permission="Commercial-Distribution-Manage">
						{" "}
						{servicesListList?.length > 0 && (
							<button className="accept-btn" onClick={toggleServicesModal}>
								Edit service
							</button>
						)}
					</ShowForPermission>
				</div>

				<div className=" table-responsive px-4">
					{servicesListList?.length > 0 ? (
						<table className="custom-table back-office-table table table-striped">
							<thead>
								<tr>
									<th>Services</th>
									<th>Providers</th>
									<th>Control</th>
								</tr>
							</thead>
							<tbody>
								{servicesListList?.map((service) => {
									return (
										<tr key={service?.name}>
											<td>
												<ServicesImages name={service?.name} />
												<span className="mx-2 text-capitalize">
													{service?.name}
												</span>
											</td>
											<td>
												{service?.providers?.length > 0
													? service?.providers?.map((provider, index) => {
															return (
																<span>
																	{provider}
																	{index < service?.providers?.length - 1
																		? " - "
																		: ""}
																</span>
															);
													  })
													: "-"}
											</td>
											<td>
												<Link
													className="d-inline-block action--btn font-bold text-success"
													to={`/commercial-distribution/${id}/configurations/${service?.name}`}
												>
													Manage
												</Link>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						<div className="d-flex justify-content-center">
							<ShowForPermission permission="Commercial-Distribution-Manage">
								<button
									className="file-btn py-2 px-3 my-5 w-auto"
									onClick={toggleServicesModal}
								>
									<i className="fas fa-plus-circle"></i>
									<span className="mx-2">Add Service</span>
								</button>
							</ShowForPermission>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
