import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api";

export const listUsers = async (params) => {
	return await axios
		.get(`${backOfficeApi}/team-management/user`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listGroups = async (params = {}) => {
	return await axios
		.get(`${backOfficeApi}/team-management/groups`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const storeUser = async (data) => {
	return await axios
		.post(`${backOfficeApi}/team-management/user`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editUser = async (data, id) => {
	return await axios
		.put(`${backOfficeApi}/team-management/user/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const getUser = async (id) => {
	return await axios
		.get(`${backOfficeApi}/team-management/user/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteUser = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/team-management/user/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPermissions = async () => {
	return await axios
		.get(`${backOfficeApi}/team-management/permissions`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const storeGroup = async (data) => {
	return await axios
		.post(`${backOfficeApi}/team-management/groups`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const getGroupPermission = async (id) => {
	return await axios
		.get(`${backOfficeApi}/team-management/groups/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateGroup = async (data, id) => {
	return await axios
		.put(`${backOfficeApi}/team-management/groups/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteGroup = async (id) => {
	return await axios
		.delete(`${backOfficeApi}/team-management/groups/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateStatus = async (id, data) => {
	return await axios
		.put(
			`${backOfficeApi}/team-management/users/change-status/${id}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};
