import { Modal } from "reactstrap";
import Locale from "translations";

export default function PassangerModel({ isOpen, toggle, flightData }) {
	const { marketPlace, inventory } = Locale;

	return (
		<Modal isOpen={isOpen} toggle={toggle} size={"lg"} centered={true}>
			<div className="d-flex align-items-center justify-content-between pt-3 px-4 w-100">
				<h5>Passenger</h5>
				<a href="##" onClick={toggle} className="btn-x">
					<i class="fas fa-times"></i>
				</a>
			</div>
			<table
				striped
				className="table-update p-2 bg-white m-0 table table-striped"
			>
				<thead>
					<tr>
						<th>{"Name"}</th>
						<th>{"Gender"}</th>
						<th>{"Date Of Birth"}</th>
						<th>{"ID Number"}</th>
						<th>{"ID Type"}</th>
						<th>{"ID Expiry Date"}</th>
						<th>{"Frequent Flyer Program"}</th>
					</tr>
				</thead>
				<tbody>
					{flightData?.passengers?.length > 0 ? (
						flightData?.passengers?.map((item, index) => (
							<tr>
								<td>
									{item?.lastName}/{item?.firstName}
								</td>
								<td>{item?.sex === "F" ? "Female" : "Male"}</td>
								<td>{item?.birthday}</td>
								<td>{item?.cardNum}</td>
								<td>{item?.cardType === "P" ? "Passport" : item?.cardType}</td>
								<td>{item?.cardExpiredDate}</td>
								<td></td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="7">
								<div className="product-build__product-no-data ">
									<i className="icx icx-eye-slash" aria-hidden="true"></i>
									<h4>{"No Data Found"}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</Modal>
	);
}
