import Model from "components/model";
import Pagination from "components/shared/Pagination";
import { useRef, useState } from "react";
import Locale from "translations";
import AmountItem from "./AmountItem";

export default function ListTransaction({
	transaction,
	goTo,
	handleSort,
	filter,
}) {
	const { payment, backOffice } = Locale;
	const iframe = useRef(null);

	const [model, setmodel] = useState({
		title: "View Attachment",
		isOpen: false,
		size: "sm",
	});
	const toggelModel = (e) => {
		setmodel({ ...model, isOpen: !model.isOpen, ...e });
	};

	const totalAdded = transaction?.data
		?.filter((t) => t?.transaction_type === "add")
		.reduce((acc, curr) => acc + +curr?.amount, 0);
	const totalDeducted = transaction?.data
		?.filter((t) => t?.transaction_type === "deduct")
		.reduce((acc, curr) => acc + +curr?.amount, 0);
	const totalTransactionPerPage = (totalAdded - totalDeducted).toFixed(2);

	// useEffect(() => {
	// 	async function fetchImagesFN() {
	// 		debugger;
	// 		if (model.attachment) {
	// 			const res = await fetchImages(model.attachment);
	// 			debugger;
	// 			setmodel({ ...model, displayImg: res?.data?.data?.avatar });
	// 		}
	// 	}
	// 	fetchImagesFN();
	// }, [model.attachment]);
	const backOfficeApi = process.env.REACT_APP_API_URL + "/api/files/fetch/";

	return (
		<>
			<div className="our-border table-responsive">
				<table className="custom-table back-office-table table table-striped mb-0">
					<thead>
						<tr>
							<th>{payment.messages.transactionId}</th>
							<th>{backOffice.type}</th>
							<th>
								{payment.messages.Balance}
								<span
									role="button"
									onClick={() => handleSort("current_balance_sort")}
								>
									<i
										className={`${
											filter?.current_balance_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th style={{ width: "190px" }}>{payment.messages.description}</th>
							<th>{payment.messages.Created}</th>
							<th>{payment.messages.Actionby}</th>
							<th>{payment.messages.paymentMethod}</th>
							<th>
								{backOffice.totalavailable}
								<span role="button" onClick={() => handleSort("amount_sort")}>
									<i
										className={`${
											filter?.amount_sort === "asc"
												? "fa fa-sort-amount-up"
												: "fa fa-sort-amount-down"
										}  mx-1`}
									></i>
								</span>
							</th>
							<th>Subject Id</th>
							<th>Subject Type</th>
							<th>{payment.messages.Action}</th>
						</tr>
					</thead>
					<tbody>
						{transaction.data && transaction.data.length > 0
							? transaction.data.map((item) => {
									return (
										<tr key={item?.id}>
											<td>{item.transaction_number}</td>
											<td>{item.transaction_type}</td>
											<td>{item.amount}</td>
											<td>{item.description}</td>
											<td>{item.created_at}</td>
											<td>{item.action_by}</td>
											<td>{item.payment_method}</td>
											<td>{item.current_balance}</td>
											<td>{item.subject_id}</td>
											<td>{item.subject_type}</td>
											<td>
												{" "}
												{item.attachment != null ? (
													<a
														target="_blank"
														href={backOfficeApi + item.attachment}
														width="650px"
														height="300px"
														className="fix_img_iframe"
														title="test"
														alt="Empty img"
														rel="noreferrer"
													>
														<p>
															<i class="fas fa-eye"></i> View
														</p>
													</a>
												) : null}
											</td>
										</tr>
									);
							  })
							: null}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={transaction.meta} goTo={goTo} />
					<div className="row">
						{totalTransactionPerPage && (
							<AmountItem
								title={payment.messages.totalAddedPerPage}
								number={totalAdded?.toFixed(2)}
								className="col-4"
							/>
						)}

						{totalTransactionPerPage && (
							<AmountItem
								title={payment.messages.totalDeductedPerPage}
								number={totalDeducted?.toFixed(2)}
								className="col-4"
							/>
						)}

						{totalTransactionPerPage && totalTransactionPerPage !== "NaN" && (
							<AmountItem
								title={payment.messages.totalTransactionsPerPage}
								number={totalTransactionPerPage}
								className="col-4"
							/>
						)}

						{transaction?.total_amount && (
							<AmountItem
								title={payment.messages.totalAmount}
								number={Number(transaction?.total_amount).toFixed(2)}
								className="col-4 my-2"
							/>
						)}

						{transaction?.total_added && (
							<AmountItem
								title={payment.messages.totalAdded}
								number={Number(transaction?.total_added).toFixed(2)}
								className="col-4 my-2"
							/>
						)}

						{transaction?.total_deduct && (
							<AmountItem
								title={payment.messages.totalDeducted}
								number={Number(transaction?.total_deduct).toFixed(2)}
								className="col-4 my-2"
							/>
						)}
					</div>
					{/* {totalTransactionPerPage && (
						<p className="px-3 mb-2 font-weight-bold">
							{payment.messages.totalAddedPerPage}: {totalAdded?.toFixed(2)}
						</p>
					)} */}
					{/* {totalTransactionPerPage && (
						<p className="px-3 mb-2 font-weight-bold">
							{payment.messages.totalDeductedPerPage}:{" "}
							{totalDeducted?.toFixed(2)}
						</p>
					)} */}
					{/* {totalTransactionPerPage && totalTransactionPerPage !== "NaN" && (
						<p className="px-3 mb-2 font-weight-bold">
							{payment.messages.totalTransactionsPerPage}:{" "}
							{totalTransactionPerPage}
						</p>
					)} */}

					{/* {transaction?.total_amount && (
						<p className="px-3 mb-2 font-weight-bold">
							{payment.messages.totalAmount}: {+transaction?.total_amount}
						</p>
					)} */}

					{/* {transaction?.total_added && (
						<p className="px-3 mb-2 font-weight-bold">
							{payment.messages.totalAdded}: {+transaction?.total_added}
						</p>
					)} */}

					{/* {transaction?.total_deduct && (
						<p className="px-3 mb-2 font-weight-bold">
							{payment.messages.totalDeducted}: {+transaction?.total_deduct}
						</p>
					)} */}
				</div>
			</div>
			<Model
				toggel={toggelModel}
				isOpen={model.isOpen}
				hasFooter={false}
				hasHeader={false}
			>
				<div className="d-flex align-items-center justify-content-between p-2 pt-3 w-100">
					<h3>{model.title}</h3>
					<button onClick={toggelModel} className="btn-x">
						X
					</button>
				</div>
				<div className="w-100 d-flex align-items-center justify-content-center"></div>
			</Model>
		</>
	);
}
