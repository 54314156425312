import CheckBox from "components/chekbox";

export default function Discount({ state, setState, isPackage }) {
	return (
		<div className="discount-plan mt-2">
			<div className="check-box">
				<CheckBox
					type="Checkbox"
					name="discount"
					id="discount"
					checked={state?.apply_discount}
					onChange={(e) => {
						setState({ ...state, apply_discount: e.target.checked });
					}}
					disabled={isPackage}
				/>
				<label htmlFor="discount">Apply Discount </label>
			</div>
			<div className="radios">
				<div className="radio">
					<input
						type="radio"
						name="paid_type"
						id="paid_type"
						value={state?.discount_type}
						checked={state?.discount_type === "percentage" ? true : false}
						onChange={(e) => {
							setState({
								...state,
								discount_type: e.target.checked ? "percentage" : "fixed",
							});
						}}
						disabled={isPackage}
					/>
					<label>Discount %</label>
				</div>
				<div className="input">
					<input
						type="text"
						className="form-control"
						value={state?.discount_value}
						onChange={(e) => {
							setState({ ...state, discount_value: e.target.value });
						}}
						disabled={isPackage}
					/>
					<p>%</p>
				</div>
			</div>
			<div className="radios">
				<div className="radio">
					<input
						type="radio"
						name="paid_type"
						id="paid_type"
						value={state?.discount_type}
						checked={state?.discount_type === "fixed" ? true : false}
						onChange={(e) => {
							setState({
								...state,
								discount_type: e.target.checked ? "fixed" : "percentage",
							});
						}}
						disabled={isPackage}
					/>
					<label>Fixed Rate</label>
				</div>
				<div className="input">
					<input
						type="text"
						className="form-control"
						value={state?.fixed_value}
						onChange={(e) => {
							setState({ ...state, fixed_value: e.target.value });
						}}
						disabled={isPackage}
					/>
					<p>{state?.currency}</p>
				</div>
			</div>
		</div>
	);
}
