import moment from 'moment';
import { store } from 'react-notifications-component';
import { downloadFiles } from 'services/Request';

export async function downloadFileWithUUID(UUID, fileName = "") {
  if (UUID) {
    const res = await downloadFiles(UUID);
    if(res?.status === 200) {
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const objectUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.target = "_blank";
      anchor.href = objectUrl;
      anchor.setAttribute(
        "download",
        `${fileName || ""}-${moment().format("DD-MM-YYYY")}.${res.data.type.split("/")[1]}`
      );
      anchor.click();
    }
    store.addNotification({
      title: "Done!",
      message: "File Downloaded Successfuly",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  }
}