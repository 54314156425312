import resetIcon from 'assets/images/wallet/resetIcon.png'
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField'
import DateRangeField from 'components/shared/DateRangeField'
import SelectField from 'components/shared/SelectField'
import TextField from 'components/shared/TextField'
import { useGlobalState } from 'context/global'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { fetchCitiesSearch } from 'services/lookups'
import Locale from 'translations'

export default function FilterReservation({ onReset, filters, setFilters }) {
	const { backOffice, booking, marketPlace } = Locale
	const locale = localStorage.getItem('currentLocale') || 'en'
	const { allCountries } = useGlobalState()

	const [countries, setCountries] = useState([])
	const statusLookups = [
		{ value: 2, label: 'PENDING' },
		{ value: 3, label: 'TENTATIVE' },
		{ value: 4, label: 'DEFINITE' },
		{ value: 5, label: 'CANCELED' }
	]
	//Fetch Countries
	useEffect(() => {
		async function countriesList() {
			const format = await allCountries?.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code
			}))
			setCountries(format)
		}
		countriesList()
	}, [allCountries])

	function resetFilters() {
		setFilters({
			hotel_name: '',
			check_in: '',
			destination_id: '',
			status: ''
		})
	}

	const [listAuto, setListAuto] = useState([])
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue)
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name
				}
			})

			setListAuto(result)
		}
	}
	const handleChange = (e, flag = false) => {
		const name = e.target?.name
		//const value = e.target?.value;
		const value = e.value?.name

		if (flag) {
			setFilters({
				...filters,
				country: e['value'],
				goingTo: e.value
			})
		} else
			setFilters({
				...filters,
				destination_id: {
					label: e?.value?.name,
					value: e?.value?.id,
					country: e?.value?.country
				}
			})
		setFilters({
			...filters,
			destination_id: {
				label: e?.value?.name,
				value: e?.value?.id,
				country: e?.value?.country
			}
		})
	}
	return (
		<div className='main-filter bg-white-blue py-2 mt-2'>
			<div className='row align-items-center  w-100 no-gutter m-0'>
				<div className='col-md-3'>
					<div className='main-label'>
						<TextField
							type='text'
							placeholder={'search'}
							label={'search'}
							value={filters.hotel_name}
							onChange={(e) => {
								setFilters({
									...filters,
									hotel_name: e.target.value
								})
							}}
						/>
						<i className='fas fa-search fa-fw'></i>
					</div>
				</div>

				{/* <div className="col-md-2">
					<DatePickerField
						label={booking.CheckinDate}
						placeholder={booking.CheckinDate}
						date={filters.checkin}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								checkin: filterDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div> */}
				<div className='col-3 date-range-input'>
					<p className='mb-0 mt-2'>{booking.CheckinDate}</p>
					<DateRangeField
						startDate={filters.date_from_start}
						endDate={filters.date_from_end}
						startDatePlaceholder={'Start Date'}
						endDatePlaceholder={'End Date'}
						readOnly={true}
						onDatesChange={({ startDate, endDate }) => {
							endDate = endDate ? moment(endDate)._d : moment(startDate)._d
							setFilters({
								...filters,
								date_from_start: moment(startDate)._d,
								date_from_end: endDate
							})
						}}
						isOutsideRange={(day) => {
							return false
						}}
					/>
				</div>
				<div className='col-md-2 booking-autoComplete'>
					<AutoCompleteField
						label={booking.Destination}
						placeholder={booking.Destination}
						hasLabel={true}
						flag={filters.goingTo?.country?.flag}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						isSearchable={true}
						value={
							filters.destination_id?.label ? filters.destination_id?.label : ''
						}
						onChange={(e) =>
							handleChange({ name: 'destination_id', value: { name: e } }, true)
						}
						onSelectValue={(e) => {
							handleChange({ name: 'destination_id', value: e }, true)
						}}
					/>
				</div>

				<div className='col-md-2'>
					<SelectField
						label={backOffice.status}
						value={filters.status}
						placeholder={backOffice.status}
						name='country'
						options={statusLookups}
						onChange={(e) => {
							setFilters({
								...filters,
								status: e
							})
						}}
					/>
				</div>

				<div className='col-md' onClick={resetFilters}>
					<div
						onClick={onReset}
						className='d-flex align-items-center text-secondary pointer mt-4 reset-btn'
					>
						<img src={resetIcon} alt='' srcset='' />
						<p className='text-caption'>{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	)
}
