import moment from "moment";

export default function IssueProcessing({ issueData }) {

	return (
		<>
			<div className="flight_item my-3 d-flex justify-content-between align-items-center py-2 px-3  border-light">
				{issueData.status ? (
					<>
						<div className="d-flex justify-content-between align-items-center w-100 ">
							<div className="text-success">
								<i class="far fa-check-square text-success me-1"></i>
								<span className="fw-bold">Issued</span>
							</div>
						</div>
					</>
				) : (
					<>
						<p className="bold terms-color">
							<i class="fas fa-hourglass-half me-2 terms-color"></i>
							Issue Processing
						</p>
						<div className="d-flex">
							<p className="bold">
								{moment(issueData?.orderDate).format("HH:mm")}
							</p>
							<div className="text-center p-1 px-3">
								<p className="spent mt-2" style={{ borderColor: "#EDEDED" }}>
									<span>Ticket Will Be Issued Witin 2 Hours After Payment</span>
								</p>
							</div>
							<p className="bold">
								{moment(issueData?.orderDate).add(2, "hours").format("HH:mm")}
							</p>
						</div>
					</>
				)}
			</div>
		</>
	);
}
