import TextField from "components/Form/TextField/TextField";
import PasswordField from "components/shared/PasswordField";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
	editUser,
	getUser,
	listGroups,
	storeUser,
} from "services/managementTeam";
import Locale from "translations";

export default function AddUser() {
	let history = useHistory();
	const { backOffice, teamManagement, userDetails } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const [errors, setErrors] = useState({});
	const [groups, setGroups] = useState();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const { type, id } = useParams();
	const { allCountries } = useGlobalState();
	const [countries, setCountries] = useState([]);
	const [countriesOption, setCountriesOption] = useState([]);

	const [user, setUser] = useState({
		user_name: "",
		email: "",
		password: "",
		password_confirmation: "",
		is_admin: null,
		status: "active",
		roles: null,
		country: [],
	});

	const userTypes = [
		{ value: true, label: teamManagement.superAdmin },
		{ value: false, label: teamManagement.user },
	];
	/* get country lookups */
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries([{ label: "All", value: 0 }, ...format]);
			setCountriesOption([{ label: "All", value: 0 }, ...format]);
		}
		countriesLookups();
	}, [allCountries]);
	/* get data in case edit */
	useEffect(async () => {
		if (type === "edit") {
			const res = await getUser(id);
			if (res.status === 200) {
				setUser({
					...res.data.user,
					// password: res.data.user.password,
					// password_confirmation: res.data.user.password_confirmation,
					is_admin: {
						value: !res.data.user.is_admin ? false : true,
						label: !res.data.user.is_admin
							? teamManagement.user
							: teamManagement.superAdmin,
					},
					roles: res.data.user.roles?.id
						? {
								value: res.data.user.roles?.id,
								label: res.data.user.roles?.display_name,
						  }
						: null,
					country:
						res.data.user?.countryPermissions.length == 0
							? [{ label: "All", value: 0 }]
							: res.data.user?.countryPermissions?.map((item) => ({
									label: item.name,
									value: item.id,
							  })),
				});
			}
		}
	}, []);

	/* handel sending data add and edit */
	useEffect(async () => {
		let res;
		if (isFormValid(errors)) {
			if (type === "add") {
				debugger;
				res = await storeUser({
					...user,
					// is_admin: user.is_admin.value,
					is_admin: false,
					roles: user.roles?.value,
					password: user.password,
					password_confirmation: user.password_confirmation,
					country_permissions:
						user.country?.findIndex((obj) => obj.value === 0) !== -1
							? []
							: user.country?.map((item) => item?.value),
				});
			} else {
				res = await editUser(
					{
						...user,
						is_admin: user.is_admin.value,
						roles: !user.is_admin.value ? user.roles?.value : null,
						password: user.password,
						password_confirmation: user.password_confirmation,
						country_permissions:
							user.country?.findIndex((obj) => obj.value === 0) !== -1
								? []
								: user.country?.map((item) => item?.value),
					},
					id
				);
			}

			if (res.status === 200) {
				store.addNotification({
					title: "Info!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				history.push(`/management-team/users`);
			}
		}
	}, [isErrorLoaded]);

	/* check form validation */
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "user_name", value: user.user_name },
				{ required: true }
			),
			...validate(
				{ name: "email", value: user.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "password", value: user.password },
				{ required: type === "add" ? true : false }
			),
			...validate(
				{ name: "password_confirmation", value: user.password_confirmation },
				{
					required: type === "add" ? true : false,
					confirm: type === "add" ? true : false,
				},
				user.password ? user.password : ""
			),
			// ...validate(
			//   { name: "is_admin", value: user.is_admin },
			//   { required: true }
			// ),
			...validate(
				{ name: "roles", value: user.roles?.value },
				{ required: user?.is_admin?.value === false ? true : false }
			),
		});
	};

	const addUser = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	/* make groups depend on user type */
	useEffect(async () => {
		// if (user.is_admin) {
		const res = await listGroups();
		if (res.status === 200) {
			const formatted = res.data.roles.map((group) => {
				return {
					value: group.id,
					label: group.display_name,
				};
			});
			setGroups(formatted);
		}
		// }
	}, []);

	console.log(user);
	const handleAll = (e) => {
		debugger;
		let selectedItem = e?.map((item) => {
			if (item.label == "All") {
				return true;
			} else {
				return false;
			}
		});
		selectedItem =
			selectedItem?.length > 0 && selectedItem?.some((e) => e == true);
		setCountriesOption(selectedItem ? [] : countries);
	};
	console.log("user10001", user);
	return (
		<div>
			<p className="h5 border-bottom pb-3 font-weight-bold">
				{backOffice.addUser}
			</p>

			<p className="h4 m-0 py-3 our-border border-bottom-0 px-3 font-weight-bold">
				{backOffice.basicInfo}
			</p>
			<div className="row m-0 py-4 our-border">
				<div className="col-5 p-0">
					{type === "edit" ? (
						<i className="fas fa-asterisk required"></i>
					) : null}

					<TextField
						type="text"
						placeholder={teamManagement.userNamePlaceholder}
						label={teamManagement.userName}
						name="user_name"
						value={user.user_name}
						errors={errors?.user_name}
						color={errors?.user_name?.required ? "danger" : ""}
						onChange={(e) => {
							setUser({ ...user, user_name: e.target.value });
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
					/>
				</div>
				<div className="col-5">
					{type === "edit" ? (
						<i className="fas fa-asterisk required"></i>
					) : null}

					<TextField
						type="text"
						placeholder={teamManagement.emailAddressPlaceholder}
						label={teamManagement.emailAddress}
						name="email"
						value={user.email}
						errors={errors?.email}
						color={
							errors?.email?.required || errors?.email?.email ? "danger" : ""
						}
						onChange={(e) => {
							setUser({ ...user, email: e.target.value });
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
						}}
					/>
				</div>
				{/***Start Change password */}
				<div
					className={`col-5 p-0 ${
						type === "edit" ? "order-2 mt-5 border-top pt-3" : ""
					}`}
				>
					<PasswordField
						placeholder={userDetails.passwordPlaceholder}
						label={userDetails.password}
						name="password"
						value={user.password}
						errors={errors?.password}
						color={errors?.password?.required ? "danger" : ""}
						onChange={(e) => {
							setUser({ ...user, password: e.target.value });
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
						}}
					/>
				</div>
				<div
					className={`col-5  ${
						type === "edit" ? "order-3 mt-5 border-top pt-3" : ""
					}`}
				>
					<PasswordField
						placeholder={userDetails.repeatPasswordPlaceholder}
						label={userDetails.repeatPassword}
						name="password_confirmation"
						value={user.password_confirmation}
						errors={errors?.password_confirmation}
						color={
							errors?.password_confirmation?.required ||
							errors?.password_confirmation?.confirm
								? "danger"
								: ""
						}
						onChange={(e) => {
							setUser({ ...user, password_confirmation: e.target.value });
							setErrors({
								...errors,
								...validate(
									e.target,
									{
										required: true,
										confirm: true,
									},
									user.password ? user.password : ""
								),
							});
						}}
					/>
				</div>
				{/***End Change password */}

				{/**Hashed */}
				{/* <div className="col-5 p-0 ">
          {type === "edit" ? (
            <i className="fas fa-asterisk required"></i>
          ) : null}

          <SelectField
            label={backOffice.typeUser}
            placeholder={backOffice.typeUserPlaceholder}
            options={userTypes}
            name="is_admin"
            value={user.is_admin}
            errors={errors?.is_admin}
            color={errors?.is_admin?.required ? "danger" : ""}
            onChange={(e) => {
              const { label: value } = e;
              setUser({ ...user, is_admin: e });
              setErrors({
                ...errors,
                ...validate(
                  { name: "is_admin", value },
                  {
                    required: true,
                  }
                ),
              });
              if (user?.is_admin?.value === false) {
                setErrors({
                  ...errors,
                  roles: { required: false },
                });
              }
            }}
          />
        </div> */}
				<div className="col-10 ">
					{type === "edit" ? (
						<i className="fas fa-asterisk required"></i>
					) : null}

					<SelectField
						disabled={user?.is_admin?.value === true}
						label={teamManagement.teamName}
						placeholder={teamManagement.teamNamePlaceholder}
						// options={user?.is_admin?.value === false ? groups : []}
						options={groups}
						name="roles"
						// value={
						//   user?.is_admin?.value === false
						//     ? user.roles
						//     : user?.is_admin?.value === true
						//     ? { value: 1, label: teamManagement.typeSuperAdmin }
						//     : null
						// }
						value={user.roles}
						errors={errors?.roles}
						color={errors?.roles?.required ? "danger" : ""}
						onChange={(e) => {
							const { label: value } = e;
							setUser({ ...user, roles: e });
							setErrors({
								...errors,
								...validate(
									{ name: "roles", value },
									{
										required: true,
									}
								),
							});
						}}
					/>
				</div>
				<div className="col-10 px-3">
					<span>{backOffice.country}</span>
					{countries && (
						<Select
							label={backOffice.country}
							placeholder={backOffice.country}
							options={countriesOption}
							id="basic-countries"
							name="residences"
							isMulti
							onChange={(e) => {
								handleAll(e);
								setUser({ ...user, country: e });
							}}
							value={user.country}
						/>
					)}
				</div>
			</div>

			<div className="d-flex justify-content-end mt-5">
				<button
					className="btn-sbs-secondary  p-2 px-4 text-white"
					onClick={() => {
						history.push("/management-team/users");
					}}
				>
					{teamManagement.cancel}
				</button>
				<button
					className="btn-sbs-primary p-2 px-4 text-white mx-1"
					onClick={addUser}
				>
					{type === "add" ? teamManagement.addNewMember : backOffice.editUser}
				</button>
			</div>
		</div>
	);
}
