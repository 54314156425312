import axios from 'axios'
import { cleanEmpty } from 'services/general'
const TransfersURL =
	process.env.REACT_APP_API_URL + '/api/transfers/reservations'

export const listTransfers = async (params, page = 1) => {
	return await axios
		.get(`${TransfersURL}?page=${page}`, cleanEmpty({ params }))
		.then((res) => res)
		.catch((err) => err.response)
}
export const TransfersView = async (id) => {
	return await axios
		.get(`${TransfersURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const cancelTransfers = async (id, item) => {
	return await axios
		.post(`${TransfersURL}/${id}/cancel-item?item_id=${item}`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const cancelAllTransfers = async (id) => {
	debugger
	return await axios
		.post(`${TransfersURL}/${id}/cancel`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const getTransferInvoice = async (id) => {
	return await axios
		.get(`${TransfersURL}/${id}/invoice`)
		.then((res) => res)
		.catch((err) => err.response)
}
export const getTransferVoucher = async (id, itemid) => {
	return await axios
		.get(`${TransfersURL}/${id}/voucher/?item_id=${itemid}`)
		.then((res) => res)
		.catch((err) => err.response)
}

export const transfersExportToExcel = async (params) => {
	return await axios
		.get(`${TransfersURL}/export`, {
			params: cleanEmpty(params),
			responseType: 'blob'
		})
		.then((res) => res)
		.catch((err) => err.response)
}
