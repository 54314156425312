import ItemDestination from "./itemDestibation";
import ItemHotel from "./itemHotel";

export default function ItemsDetails(props) {
	const {
		setPopupAds,
		popupAds,
		setErrors,
		errors,
		handleItems,
		addOne,
		deleteOne,
		handleImageItem,
		deleteImageItem,
		AdsLink,
		AdSelected,
		setAdSelected,
	} = props;

	return (
		<div className="items-popupAds-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">Items</p>
			</div>
			<div className=" our-border">
				{popupAds?.type?.label == "destinations"
					? popupAds?.items_destination &&
					  popupAds?.items_destination?.length >= 0 &&
					  popupAds?.items_destination.map((item, index) => (
							<ItemDestination
								item={item}
								index={index}
								setPopupAds={setPopupAds}
								popupAds={popupAds}
								setErrors={setErrors}
								errors={errors}
								handleItems={handleItems}
								addOne={addOne}
								deleteOne={deleteOne}
								handleImageItem={handleImageItem}
								deleteImageItem={deleteImageItem}
								AdsLink={AdsLink}
								setAdSelected={setAdSelected}
							/>
					  ))
					: popupAds?.items_hotels &&
					  popupAds?.items_hotels?.length >= 0 &&
					  popupAds?.items_hotels.map((item, index) => (
							<ItemHotel
								item={item}
								index={index}
								setPopupAds={setPopupAds}
								popupAds={popupAds}
								setErrors={setErrors}
								errors={errors}
								handleItems={handleItems}
								addOne={addOne}
								deleteOne={deleteOne}
								handleImageItem={handleImageItem}
								deleteImageItem={deleteImageItem}
								AdsLink={AdsLink}
								AdSelected={AdSelected}
								setAdSelected={setAdSelected}
							/>
					  ))}
				<div className="d-flex justify-content-end mx-3">
					<button
						className="btn-link text-success d-flex justify-content-end"
						onClick={addOne}
					>
						<i class="fas fa-plus-circle text-success mt-1"></i> Add New
					</button>
				</div>
			</div>
		</div>
	);
}
