import backArrow from "assets/images/back.svg";
import Model from "components/model";
import ShowForPermission from 'helpers/showForPermission';
import { useState } from "react";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getProviderList } from "services/vendor";
import ImgServiec from "../ServiceImg";
import AddEditProvider from "./AddEditProvider";
import ProviderList from "./ServiceList";

function HotelsService() {
	const [ProModle, setProModle] = useState({
		isOpen: false,
		type: "",
		data: null,
	});
	const [isOpen, setisOpen] = useState(false);
	const [ListProviders, setListProviders] = useState([]);
	const history = useHistory();
	const { id, brn } = useParams();
	const toggle = () => {
		setProModle({ ...ProModle, isOpen: !ProModle.isOpen });
	};
	const fetchService = async () => {
		const res = await getProviderList({ id, brn });
		if (res.status === 200) {
			setListProviders(res?.data?.data);
		}
	};

	function goBack() {
		history.goBack();
	}

	return (
		<>
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					<img src={backArrow} alt="" role="button" onClick={goBack} />
					<ImgServiec name={brn} className={"px-2"} />
					<h5 className="fw-bold mb-0">{brn} Service</h5>
				</div>
				<ShowForPermission permission="Vendors-Management-Manage">
					<button
						className="btn btn-success"
						onClick={() => {
							setProModle({ ...ProModle, type: "Add", isOpen: !ProModle.isOpen });
						}}
					>
						Add Provider
					</button>
				</ShowForPermission>
			</div>
			<ProviderList
				ListProviders={ListProviders}
				fetchService={fetchService}
				setProModle={setProModle}
				ProModle={ProModle}
			/>
			<Model
				isOpen={ProModle.isOpen}
				type={`${ProModle?.type}`}
				title={`${ProModle?.type} Provider`}
				toggle={toggle}
				size="lg"
				hasFooter={false}
				hasHeader={true}
			>
				<AddEditProvider
					fetchService={fetchService}
					toggle={toggle}
					model={ProModle}
				/>
			</Model>
		</>
	);
}

export default HotelsService;
