import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import Locale from "translations";

export default function Filter({ filter, setFilter, countries }) {
	const { backOffice } = Locale;
	const statusOptions = [
		// { value: "paid", label: "Paid" },
		// { value: "cancelled", label: "Cancelled" },

		{ value: "approved", label: "Approved" },
		{ value: "confirmed", label: "Confirmed" },
		{ value: "rejected", label: "Rejected" },
		{ value: "expired", label: "Expired" },
		{ value: "pending", label: "Pending" },
	];
	const sourceOption = [
		{ value: "website", label: "Website" },
		{ value: "marketplace", label: "Marketplace" },
	];
	return (
		<div className=" main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						placeholder={"Country"}
						label={"Country"}
						options={countries}
						value={filter.country}
						onChange={(e) => {
							setFilter({
								...filter,
								country: e,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="col-3 date-range-input">
					<p className="mb-0 mt-2">{"Booking Date"}</p>
					<DateRangeField
						startDate={filter.start_booking_date}
						endDate={filter.end_booking_date}
						startDatePlaceholder={"Start Date"}
						endDatePlaceholder={"End Date"}
						readOnly={true}
						onDatesChange={({ startDate, endDate }) => {
							//.add(1, "d")
							endDate = endDate ? moment(endDate)._d : moment(startDate)._d;
							setFilter({
								...filter,
								// booking_date: [moment(startDate), endDate],
								start_booking_date: moment(startDate)._d,
								end_booking_date: endDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>

				<div className="col">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={"Tour Date"}
						onOutsideClick={false}
						date={filter?.tour_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								tour_date: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={"Source"}
						label={"Source"}
						name="source"
						options={sourceOption}
						value={filter.source}
						onChange={(e) => {
							setFilter({
								...filter,
								source: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={"Status"}
						label={"Status"}
						name="status"
						options={statusOptions}
						value={filter.status}
						onChange={(e) => {
							setFilter({
								...filter,
								status: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={() => {
							setFilter({
								search: "",
								country: null,
								booking_date: null,
								tour_date: null,
								status: null,
								page: 1,
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
