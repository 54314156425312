import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	exportCompanyWalletToExcel,
	listPaymentCards,
} from "services/financial";
import Locale from "translations";
import FilterWallet from "./FilterWallet";
import ListAllWallet from "./ListAllWallet";

export default function AllWallet() {
	const { backOffice } = Locale;
	const [allWallet, setAllWallet] = useState({});
	const [Reload, setReload] = useState(false);
	const [filter, setFilter] = useState({
		search: "",
		currency: null,
		country: null,

		company_sort: "desc",
		country_sort: "desc",
		total_amount_sort: "desc",
		used_amount_sort: "desc",
		total_refund_sort: "desc",
		total_available_sort: "desc",
		page: 1,
	});

	useEffect(() => {
		async function getPaymentCards() {
			const response = await listPaymentCards({ ...filter });
			setAllWallet(response.data);
		}
		getPaymentCards();
	}, [filter, Reload]);
	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};
	function handleSort(sort_by) {
		let sort_type;
		if (filter[sort_by] === "asc") {
			sort_type = "desc";
		} else if (filter[sort_by] === "desc") {
			sort_type = "asc";
		} else {
			sort_type = "asc";
		}

		setFilter({
			company_id: filter.company_id,
			currency: filter.currency,
			country_id: filter.country_id,
			[sort_by]: sort_type,
		});
	}
	const exportToFile = async () => {
		const res = await exportCompanyWalletToExcel({
			...filter,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Company-Wallet-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between mx-1">
				<h4>{backOffice.CompanyWallet}</h4>
				<button className="btn btn-primary px-2" onClick={() => exportToFile()}>
					<i class="fas fa-file-excel"></i> Export To Excel
				</button>
			</div>
			<FilterWallet setFilter={setFilter} Filter={filter} />
			<ListAllWallet
				allWallet={allWallet}
				goTo={goTo}
				setReload={setReload}
				handleSort={handleSort}
				filter={filter}
			/>
		</>
	);
}
