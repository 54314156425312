import axios from "axios";
import { cleanEmpty } from "services/general";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api/back-office";
const propertyUrl = process.env.REACT_APP_API_URL + "/api/properities";
const contractUrl =
	process.env.REACT_APP_API_URL_new + "/api/contract-requests";
const sharingUrl = process.env.REACT_APP_API_URL + "/api/back-office/share";

const backOfficeApiNew = process.env.REACT_APP_API_URL + "/api";

const contractUrlNew = process.env.REACT_APP_API_URL + "/api/contract-requests";
const contractRequestsUrlNew =
	process.env.REACT_APP_API_URL + "/api/contract-requests";
const addContractDetailsUrlNew =
	process.env.REACT_APP_API_URL + "/api/contracts";

const contractListUrlNew = process.env.REACT_APP_API_URL + "/api/contracts";

const propertyManagementUrl =
	process.env.REACT_APP_API_URL + "/api/property-management";

export const listProperties = async (params, page = 1) => {
	return await axios
		.get(`${backOfficeApiNew}/property-management?page=${page}`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyProperty = async (id, data) => {
	return await axios
		.put(`${backOfficeApiNew}/property-management/${id}/verification`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const stopShareProperty = async (id, data) => {
	return await axios
		.post(`${backOfficeApiNew}/property-management/${id}/stop-share`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getProperty = async (id) => {
	return await axios
		.get(`${propertyManagementUrl}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listContracts = async (params, page = 1) => {
	return await axios
		.get(`${contractListUrlNew}?page=${page}`, { params: cleanEmpty(params) })
		.then((res) => res)
		.catch((err) => err.response);
};

export const requestContracts = async (params, page = 1) => {
	return await axios
		.get(`${contractRequestsUrlNew}?page=${page}`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const listSharing = async (params, page = 1) => {
	return await axios
		.get(`${sharingUrl}?page=${page}`, { params })
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewContract = async (id) => {
	return await axios
		.get(`${contractUrlNew}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewContractRequest = async (id) => {
	return await axios
		.get(`${contractListUrlNew}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewContractList = async (id) => {
	return await axios
		.get(`${contractListUrlNew}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewRequestContract = async (id) => {
	return await axios
		.get(`${contractRequestsUrlNew}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const viewSharing = async (id) => {
	return await axios
		.get(`${sharingUrl}/view/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyContract = async (id, data) => {
	return await axios
		.patch(`${contractUrl}/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyContractList = async (id, data) => {
	return await axios
		.put(`${contractListUrlNew}/${id}/activate`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyRequestContract = async (id, data) => {
	return await axios
		.patch(`${contractRequestsUrlNew}/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const rejectRequestContract = async (id, data) => {
	return await axios
		.put(`${contractRequestsUrlNew}/${id}/verification`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifySharing = async (id, data) => {
	return await axios
		.patch(`${sharingUrl}/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

// add and edit
export const addBasicInfo = async (data) => {
	return await axios
		.post(`${propertyUrl}/basic-info`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editBasicInfo = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/basic-info`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addServices = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/facilities-services`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editServices = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/facilities-services`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addRooms = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/rooms`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editRooms = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/rooms`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addAmenities = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/room-amenities`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editAmenities = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/room-amenities`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addPhotos = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/photos`, data, {
			headers: {
				"content-type": "multipart/form-data",
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const editPhoto = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/photos`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deletePhoto = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/photos`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addPolicies = async (id, data) => {
	return await axios
		.post(`${propertyUrl}/${id}/policies`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const editPolicies = async (id, data) => {
	return await axios
		.put(`${propertyUrl}/${id}/policies`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const verificationAction = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/verification`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addContractDetails = async (id, data) => {
	return await axios
		.post(`${addContractDetailsUrlNew}/${id}`, data, {
			headers: {
				"content-type": "multipart/form-data",
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateContractDetails = async (id, data) => {
	return await axios
		.post(`${addContractDetailsUrlNew}/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
///Edit Propirty
export const EditBasicInfo = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/basic-info`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
//edit Services
export const EditServices = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/facilities-services`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
//edit Rooms
export const EditRooms = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/rooms`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const EditAmenities = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/room-amenities`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
//Add Photo
export const AddPhotos = async (id, data) => {
	return await axios
		.post(`${propertyManagementUrl}/${id}/photos`, data, {
			headers: {
				"content-type": "multipart/form-data",
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};
//Delete Photo
export const DeletePhoto = async (id, data) => {
	return await axios
		.post(`${propertyManagementUrl}/${id}/photos`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
//Edit photo
export const EditPhoto = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/photos`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

//Edit Policies
export const EditPolicies = async (id, data) => {
	return await axios
		.put(`${propertyManagementUrl}/${id}/policies`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
