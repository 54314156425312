import { useState } from "react";

export default function CollapseVisa({ children, collapseName, index }) {
	const [isDrop, setisDrop] = useState(false);
	return (
		<>
			<div className={`btn-visa-accroding w-100 mt-3`}>
				<button
					onClick={() => {
						setisDrop(!isDrop);
					}}
					className="btn px-3 py-2 w-100"
				>
					{collapseName}
					<i class="fas fa-chevron-right ms-2 "></i>
				</button>
			</div>
			{isDrop ? (
				<div className="config-row-new d-flex flex-wrap p-md-3 mb-2 mx-0 w-100">
					{children}
				</div>
			) : null}
		</>
	);
}
