import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
	exportOnlineTransactionToExcel,
	getOnlineTransaction,
} from "services/financial";
import { fetchPayableTypes } from "services/lookups";
import Locale from "translations";
import Filter from "./Filter";

export default function OnlineTranactions() {
	const { financial } = Locale;
	const { permissions, role } = useGlobalState();
	const history = useHistory();

	const [transactionList, setTransactionList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		// created_at: null,
		created_at_from: undefined,
		created_at_to: undefined,
		payable_type: "",
		payment_status: "",
		vendor: "",
	});
	const [payableTypeLookups, setPayableTypeLookups] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [failedMsg, setFailedMsg] = useState("");
	const toggle = (msg) => {
		setFailedMsg(msg);
		setIsOpen((prev) => !prev);
	};
	useEffect(() => {
		async function fetchTransactions() {
			//call api
			const res = await getOnlineTransaction({
				...filter,
				payable_type: filter?.payable_type?.value,
				payment_status: filter?.payment_status?.value,

				created_at_from: filter.created_at_from
					? moment(filter.created_at_from).format("YYYY-MM-DD")
					: "",
				created_at_to: filter.created_at_to
					? moment(filter.created_at_to).format("YYYY-MM-DD")
					: "",
				vendor: filter?.vendor?.value,
				company_name: filter?.company_name,
			});
			setTransactionList(res?.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchTransactions();
	}, [filter]);
	const statusLocale = (status) => {
		switch (status) {
			case "success":
				return financial.success;
			case "failed":
				return financial.failed;
			case "pending":
				return financial.pending;
			case "paid":
				return financial.paid;
			default:
				return status;
		}
	};
	const statusRoutes = (data) => {
		switch (data?.subject_type) {
			case "hotel":
				return {
					url: `/booking/view/${data?.id}/bookings`,
					hasPermission:
						permissions?.includes("Bookings-View") || role === "Super Admin",
				};
			case "online_visa":
				return {
					url: `/online-visa/view/request/issued/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Visa-Requests-View") ||
						role === "Super Admin",
				};
			case "ground_work":
				return {
					url: `/requests/ground/assigned/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Ground-Service-Requests-View") ||
						role === "Super Admin",
				};
			case "transportation":
				return {
					url: `/requests/transportation/assigned/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Transportation-Requests-View") ||
						role === "Super Admin",
				};
			case "wallet":
				return {
					url: `/wallet-Tarnsactions/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Financial-Companies-Wallets-View") ||
						role === "Super Admin",
				};
			case "hotel_group":
				return {
					url: `/manual-reservations/sbs-reservations/${data?.subject_id}/details`,
					hasPermission:
						permissions?.includes("SBS-Manual-Reservations-View") ||
						role === "Super Admin",
				};
			case "transfer":
				return {
					url: `/view-bookings-transfer/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Transfers-Bookings-View") ||
						role === "Super Admin",
				};
			case "tour":
				return {
					url: `/bookings-tours/${data?.subject_id}`,
					hasPermission:
						permissions?.includes("Tours-Bookings-View") ||
						role === "Super Admin",
				};
			default:
				return `/online-transactions`;
		}
	};
	const AllOnlieTransactions =
		transactionList?.length > 0 ? (
			transactionList?.map((item, index) => {
				return (
					<tr key={item.id}>
						<td>{item?.id}</td>
						<td>{filter?.vendor?.value ? filter?.vendor?.value : "Sbs"}</td>
						<td>{item.company_name}</td>
						<td>{item?.country_name}</td>
						<td>{item?.unique_id}</td>
						<td>{item.provider}</td>
						<td>{item?.amount}</td>
						<td>{item.paid_amount}</td>
						<td>{item.currency}</td>
						<td
							className={`${
								item?.payment_status === "failed"
									? "text-danger"
									: "text-success"
							}`}
						>
							{statusLocale(item.payment_status)}
						</td>
						<td>{item.payable_type?.slice(0, 8)}..</td>
						<td>{item.subject_type}</td>
						<td>{item.subject_id}</td>
						<td>{item.created_at}</td>
						<td className="pointer">
							{statusRoutes(item)?.hasPermission &&
							item?.payment_status !== "failed" ? (
								<div onClick={() => history.push(statusRoutes(item)?.url)}>
									<i className="fa fa-eye" /> {financial.view}
								</div>
							) : (
								item?.payment_status === "failed" && (
									<button
										className="btn btn-link "
										onClick={() => toggle(item?.failed_message)}
									>
										<i class="fas fa-exclamation-triangle" />
									</button>
								)
							)}
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="11">
					<div className="product-build__product-no-data fullHeight">
						<h4>{financial.noTransaction}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	/****Payable type lookups  */
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchPayableTypes();
			if (res.status == 200 || res?.data?.data?.length > 0) {
				const format = res?.data?.data?.map((t) => ({
					value: t.value,
					label: t.name,
				}));
				setPayableTypeLookups(format);
			}
		}
		fetchLookups();
	}, []);
	const exportToFile = async () => {
		const res = await exportOnlineTransactionToExcel({
			...filter,
			payable_type: filter?.payable_type?.value,
			payment_status: filter?.payment_status?.value,
			// created_at: filter.created_at
			// 	? moment(filter.created_at).format("YYYY-MM-DD")
			// 	: "",
			created_at_from: filter.created_at_from
				? moment(filter.created_at_from).format("YYYY-MM-DD")
				: "",
			created_at_to: filter.created_at_to
				? moment(filter.created_at_to).format("YYYY-MM-DD")
				: "",
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`online-transactions-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<div>
			<div className="d-flex justify-content-between mx-1">
				<h4>{financial.onlineTranactions}</h4>
				<button className="btn btn-primary p-2" onClick={() => exportToFile()}>
					<i class="fas fa-file-excel"></i> Export To Excel
				</button>
			</div>

			<div className="product-builder-list bg-transparent">
				<Filter
					filter={filter}
					setFilter={setFilter}
					payableTypeLookups={payableTypeLookups}
				/>
				<div className=" table-responsive our-border px-4">
					<table className="custom-table back-office-table table table-striped">
						<thead>
							<tr>
								<th>ID</th>
								<th>Vendor</th>
								<th width="7%">{financial.company}</th>
								<th>{financial.country}</th>
								<th>{financial.uniqueId}</th>
								<th>{financial.provider}</th>
								<th>{financial.total}</th>
								<th>{financial.paid}</th>
								<th>{financial.currency}</th>
								<th>{financial.paymentStatus}</th>
								<th>{financial.payableType}</th>
								<th>{financial.subjectType}</th>
								<th>{financial.subjectID}</th>
								<th>{financial.createAt}</th>
								<th>{financial.action}</th>
							</tr>
						</thead>

						<tbody> {AllOnlieTransactions}</tbody>
					</table>
					<div className="px-2">
						{transactionList?.length > 0 ? (
							<Pagination info={meta} goTo={goTo} />
						) : null}
					</div>
				</div>
			</div>
			<Modal isOpen={isOpen} toggle={() => toggle()} centered={true}>
				<div className="lan d-flex justify-content-between align-items-baseline p-2">
					<p className="text-secondary font-size-16 fw-bold mb-0">
						The reason for the transaction failure
					</p>
					<i
						className="fas fa-times bg-light-gray text-white p-1 pointer"
						onClick={() => toggle()}
					></i>
				</div>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<div className=" m-0 p-0 w-90 py-4">
						<p className="fw-bold text-center">
							{failedMsg ? (
								failedMsg
							) : (
								<span className="text-muted ">
									<i class="fas fa-exclamation-triangle" /> Not Found Reason For
									Now
								</span>
							)}
						</p>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
