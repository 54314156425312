import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	commercialDistAddCommission,
	commercialDistEditCommission,
} from "services/commercialDistribution";
import Locale from "translations";
import { handleResponseNotification } from "../../helpers/commercialDistHelper";

export default function AddEditProviderModal({
	toggleModal,
	providerModal,
	providersLookup,
	setProviderReload,
}) {
	const { id, brn } = useParams();
	const { vendor, backOffice } = Locale;
	const [providerData, setProviderData] = useState({
		provider: providerModal?.data?.provider
			? {
					...providerModal?.data?.provider,
					label: providerModal?.data?.provider?.name,
			  }
			: null,
		commission: providerModal?.data?.amount
			? providerModal?.data?.amount
			: null,
		safaCommission: providerModal?.data?.safa_amount
			? (
					(providerModal?.data?.safa_amount / 100) *
					providerModal?.data?.amount
			  ).toFixed(2)
			: null,
		vendorCommission: providerModal?.data?.consumer_amount
			? (
					(providerModal?.data?.consumer_amount / 100) *
					providerModal?.data?.amount
			  ).toFixed(2)
			: null,
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [percentageError, setPercentageError] = useState(false);
	const commissionId = providerModal?.data?.id;

	function handleProviderInputs(value, keyName) {
		setProviderData({
			...providerData,
			[keyName]: keyName === "commission" ? +value : value,
		});
		setErrors({
			...errors,
			...validate({ name: keyName, value }, { required: true }),
		});
		// handle precentage errors
		if (
			keyName === "safaCommission" ||
			keyName === "vendorCommission" ||
			keyName === "commission"
		) {
			const currentSafaCommission =
				keyName === "safaCommission" ? +value : providerData.safaCommission;
			const currentVendorCommission =
				keyName === "vendorCommission" ? +value : providerData.vendorCommission;

			const commission =
				keyName === "commission" ? +value : providerData.commission;
			if (
				+commission !== 0 &&
				Number(currentSafaCommission) + Number(currentVendorCommission) ===
					+commission
			) {
				setPercentageError(false);
			} else {
				setPercentageError(true);
			}
		}
	}

	function checkFormErrors() {
		let submitError = {};
		Object.keys(providerData).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: providerData[key] },
					{ required: true }
				),
			};
		});
		setErrors(() => submitError);
	}

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	async function addEditProviderCommission() {
		const data = {
			provider_id: providerData.provider?.id,
			service_name: brn,
			type: "add",
			amount: providerData.commission,
			// safa_amount: providerData.safaCommission,
			// consumer_amount: providerData.vendorCommission,
			safa_amount: (
				(providerData.safaCommission / providerData.commission) *
				100
			).toFixed(2),
			consumer_amount: (
				(providerData.vendorCommission / providerData.commission) *
				100
			).toFixed(2),
		};
		const res =
			providerModal?.type?.toLowerCase() === "add"
				? await commercialDistAddCommission(id, data)
				: providerModal?.type?.toLowerCase() === "edit"
				? await commercialDistEditCommission(id, commissionId, data)
				: null;

		if (res?.status === 200 || res?.status === 201) {
			setProviderReload((prev) => !prev);
			toggleModal();
			handleResponseNotification(
				res,
				"success",
				providerModal?.type,
				`${providerModal?.type}ed successfully`
			);
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			addEditProviderCommission();
		}
		return () => {};
	}, [isErrorLoaded]);

	console.log("providerDataproviderData", providerData);
	return (
		<div className="row mx-5 w-100">
			{/* select provider */}
			<div className="col-12 no-padding">
				<SelectField
					label={vendor.Providers}
					placeholder={vendor.Providers}
					name="provider"
					id="provider"
					options={providersLookup}
					value={providerData.provider}
					onChange={(e) => handleProviderInputs(e, "provider")}
					errors={errors.provider}
					color={errors.provider}
				/>
			</div>
			{/* commission */}
			<div className="col-12 no-padding">
				<TextField
					type="number"
					label={vendor.commission}
					placeholder={vendor.commission}
					id="commission"
					name="commission"
					extraTextPosition="append"
					extraText="%"
					onChange={(e) => handleProviderInputs(e.target.value, "commission")}
					value={providerData.commission}
					errors={errors.commission}
					color={errors.commission}
				/>
			</div>
			{/* commission Type */}
			<div className="col-6 no-padding display-none ">
				<SelectField
					id="commissionType"
					name="commissionType"
					label={backOffice.commissionType}
					defaultValue={{ label: "Add", value: "add" }}
					disabled={true}
				/>
			</div>
			{/* safa commission amount */}
			<div className="col-6 no-padding">
				<TextField
					type="number"
					label={"Safa Commission"}
					placeholder={"Safa Commission."}
					name="safaCommission"
					extraTextPosition="append"
					extraText="%"
					onChange={(e) =>
						handleProviderInputs(e.target.value, "safaCommission")
					}
					value={providerData.safaCommission}
					errors={errors.safaCommission}
					color={percentageError ? "danger" : ""}
				/>

				{(+providerData.safaCommission / 100) * +providerData.commission >
				+providerData.commission ? (
					<p className="text-danger">*Must be equal or less than commission</p>
				) : null}
				{percentageError ? (
					<p className="text-danger">
						*The total of safa commission and vendor commission must be equal
						commission
					</p>
				) : null}
			</div>
			{/*  */}
			<div className="col-6 no-padding">
				<TextField
					type="number"
					label={"Vendor Commission"}
					placeholder={"Vendor Commission"}
					name="vendorCommission"
					extraTextPosition="append"
					extraText="%"
					onChange={(e) =>
						handleProviderInputs(e.target.value, "vendorCommission")
					}
					value={providerData.vendorCommission}
					errors={errors.vendorCommission}
					color={percentageError ? "danger" : ""}
					//&& errors.vendorCommission
				/>
				{percentageError ? (
					<p className="text-danger">
						*The total of safa commission and vendor commission must be equal
						commission
					</p>
				) : null}
				{(+providerData.vendorCommission / 100) * +providerData.commission >
				+providerData.commission ? (
					<p className="text-danger">
						*Must be equal or less than equal commission
					</p>
				) : null}
			</div>

			<button
				className="btn btn-success w-100 my-4"
				disabled={percentageError}
				onClick={submit}
			>
				{providerModal?.type} commission
			</button>
		</div>
	);
}
