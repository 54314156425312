import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ViewHotelReservations } from "services/companiesReservations";
import Locale from "translations";
import BookingDetails from "./BookingDetails";
import InventoryTab from "./InventoryTab";

export default function ViewReservations() {
	const { company_id, reservation_id } = useParams();
	const [details, setDetails] = useState(null);
	const [activeTab, setActiveTab] = useState("1");
	const { backOffice, rooms, teamManagement } = Locale;
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	async function fetchData() {
		const { data } =
			"hotel" === "hotel"
				? await ViewHotelReservations(company_id, reservation_id)
				: null;
		setDetails(data.data);
	}
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="market-add-hotel marketplace-details bg-white">
			<div className="main-tabs container m-auto">
				{details !== null && (
					<>
						<BookingDetails details={details} />
						<InventoryTab
							details={details}
							setDetails={setDetails}
							fetchData={fetchData}
						/>
					</>
				)}
			</div>
		</div>
	);
}
