import { Collapse } from '@material-ui/core';
import dirctimg from "assets/images/icons/Dircte.svg";
import externalimg from 'assets/images/icons/External-gold.svg';
import moment from 'moment';
import { useState } from 'react';

function ExternalItem({ element }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const hasTransit = element?.itemable?.transits?.length > 0;
  function toggleCollapse() {
    setCollapseOpen(!collapseOpen);
  }
  return (
    <div className='package-item-container'>
      <div className='package-item'>
        <div className='icon-item'>
          <img src={externalimg} alt="" />
        </div>
        {/* external from */}
        <div className='d-flex flex-column m-2' >
          <p className='airprot-name'>
            {element?.itemable?.from_port?.name}
          </p>
          <span className='country-name'>
            {element?.itemable?.from_country?.name}
          </span>
          <span className='date-name'>
            {moment(element?.itemable?.arrival_at).format("DD/MM/YYYY")}
          </span>
        </div>

        {/* path  */}
        <div className='path-details'>
          <div className='path-start-point'></div>
          <span className='path-stop'>
            {element?.itemable?.transits?.length > 0 ? `${element?.itemable?.transits?.length} Stop` : "Direct"}
          </span>
          <img src={dirctimg} alt="" />
        </div>

        {/* extrenal to */}
        <div className='d-flex flex-column m-2' >
          <p className='airprot-name'>
            {element?.itemable?.to_port?.name}
          </p>
          <span className='country-name'>
            {element?.itemable?.to_country?.name}
          </span>
          <span className='date-name'>
            {moment(element?.itemable?.departure_at).format("DD/MM/YYYY")}
          </span>
        </div>

        <span onClick={toggleCollapse} className='pointer mx-1'>
          <i class={`fas fa-chevron-${collapseOpen ? "up" : "down"}`}></i>
        </span>
      </div>

      <Collapse in={collapseOpen} className='mt-2 px-4 overflow-hidden'>
        <>
          <div className={`air-step ${hasTransit ? "with-transit" : ""}`}>
            <div className='d-flex align-items-center my-1'>
              <img src={element?.itemable?.transporter?.logo} alt={element?.itemable?.transporter?.name} width="45" height="45" />
              <p className='mx-2 my-0'>{element?.itemable?.transporter?.name}</p>
            </div>
            {/* from airport to airport */}
            <div className='d-flex flex-wrap align-items-center justify-content-around mt-2'>
              {/* from */}
              <p className='d-flex flex-column'>
                <span className='airprot-name'>{element?.itemable?.from_port?.name}</span>
                <span>{moment(element?.itemable?.departure_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
              </p>

              <span>-</span>

              {/* to port */}
              <p className='d-flex flex-column'>
                <span className='airprot-name'>{element?.itemable?.to_port?.name}</span>
                <span>{moment(element?.itemable?.arrival_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
              </p>
            </div>
          </div>

          {/* transit */}
          {element?.itemable?.transits?.map((transit, index) => {
            return (
              <div key={index} className='transit'>
                <p className='transit-stop my-1'>
                  Stop
                  <span className='d-inline-block mx-1' style={{ color: "#0372BC" }}>{transit?.city?.name}</span>
                </p>

                <div className='air-step with-transit'>

                  <div className='d-flex align-items-center my-1'>
                    <img src={transit?.transporter?.logo} alt={transit?.transporter?.name} width="45" height="45" />
                    <p className='mx-2 my-0'>{transit?.transporter?.name}</p>
                  </div>

                  {/* transit from / to */}
                  <div className='d-flex flex-wrap align-items-center justify-content-around mt-2'>

                    {/* transit from */}
                    <p className='d-flex flex-column'>
                      <span className='airprot-name'>{transit?.departure_port?.name}</span>
                      <span > {moment(transit?.departure_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
                    </p>
                    <span>- </span>
                    {/* transit to */}
                    <p className='d-flex flex-column'>
                      <span className='airprot-name'>{transit?.arrival_port?.name}</span>
                      <span>{moment(transit?.arrival_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
                    </p>

                  </div>
                </div>

              </div>
            )
          })}
        </>
      </Collapse>
    </div>
  )
}

export default ExternalItem