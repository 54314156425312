import phone from "assets/images/phone.png";
import safaLogo from "assets/images/safa_logo.png";
import { transferVoucher } from "assets/prints/TransferPrint";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getTransferVoucher } from "services/vendor";

function TransferItem({ item }) {
	const { id, brn } = useParams();
	const printVoucher = async (item_id) => {
		const voucherRes = await getTransferVoucher(id, brn, item_id);
		if (voucherRes.status !== 200) return;
		debugger;
		const voucherData = voucherRes?.data?.data;
		let printVar = "";
		voucherData?.forEach((item) => {
			if (item.status === "CANCELED") return;
			const keyValuePair = {
				"[reference_number_key]": "Reference Number",
				"[reference_number_value]": item?.ref,
				"[supplier_reference_key]": "Supplier Reference",
				"[supplier_reference_value]": item?.supplier_reference,
				"[passenger_name_key]": "Passenger Name",
				"[passenger_name_value]": `${item?.passenger_name?.name} ${item?.passenger_name?.surname}`,
				"[product_type_key]": "Product Type",
				"[product_type_value]": `${item?.type} ${item?.category} ${item?.vehicle} `,
				"[product_image]": item?.image,
				"[from_key]": "From",
				"[from_value]": item?.from,
				"[to_key]": "To",
				"[to_value]": item?.to,
				"[passengers_key]": "Passengers",
				"[adults_value]": item?.adults,
				"[children_value]": item?.children,
				"[infants_value]": item?.infants,
				"[service_date_key]": "Service Date",
				"[service_date_value]": item?.pickup_date,
				"[pickUp_time_key]": "pick-up Time",
				"[pickUp_time_value]": item?.pickup_time
					? moment(item?.pickup_time).format("HH:mm")
					: "",
				"[pickUp_point_key]": "Pick-up Point",
				"[pickUp_point_value]": item?.pickup_information,
				"[travel_information]": "Travel Information",
				"[arrival_flight_key]": "Arrival Flight",
				"[arrival_flight_value]": item?.flight_number ?? "",
				"[arrival_time_key]": "Arrival Time",
				"[arrival_time_value]": item?.flight_time
					? moment(item?.flight_time).format("HH:mm")
					: "",
				"[included_services_key]": "Included Services",
				"[telephone]": "Telephone",
				"[voucher_date]": moment().format("DD/MM/YYYY"),
			};

			printVar += transferVoucher;
			for (const key in keyValuePair) {
				printVar = printVar.replace(key, keyValuePair[key]);
			}
			let included_services = "";
			item?.transfer_detail_info?.forEach(
				(info) => (included_services += `<p>${info}</p>`)
			);
			printVar = printVar.replace(
				"[included_services_value]",
				included_services
			);
			printVar = printVar.replace(
				"[logo]",
				`<img width={200} src="${safaLogo}" alt="safa logo" />`
			);
			printVar = printVar.replace(
				"[phone_img]",
				`<img src="${phone}" alt="phone" />`
			);
		});
		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
        <head>
        <title> print Voucher</title>
        <style>

        </style>
        </head>
        <body onLoad="{()=>window.print()}">${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};

	const [isOpen, setisOpen] = useState(false);
	const toggle = () => {
		setisOpen(!isOpen);
	};
	return (
		<>
			<div className="transfer-item mb-3">
				<div className="row">
					<div className="col-5 pt-2">
						<div className="img-box">
							<img
								src={item?.image}
								alt={`${item?.type} ${item?.category} ${item?.category}`}
								srcset=""
							/>
						</div>
					</div>
					<div className="col-7 py-1 pt-2">
						<span>
							<span className="trans-dark" style={{ fontSize: "20px" }}>
								{item?.type}
							</span>
							<span className="trans-dark-light"> {item?.category} </span>
							{item?.vehicle}
						</span>
						<div className="info-trans">
							<p>
								<i className="fas fa-map-marker-alt"></i>
								<span>{item?.from_name}</span>
								<i
									className="fas fa-exchange-alt mx-2"
									style={{ color: "#0850AD" }}
								></i>
								<span>{item?.to_name}</span>
							</p>
						</div>
						<div className="info-trans">
							<p>
								<i className="far fa-calendar-alt"></i>
								<span>{item?.flight_time}</span>
							</p>
						</div>
						<div className="info-trans">
							<p>
								<i className="far fa-user"></i>{" "}
								<span>{`${item?.trip_type} Adults ${item?.adults} Child ${item?.children} infants ${item.infants}`}</span>
							</p>
						</div>
					</div>
					<div className="col-12 px-4 pb-2">
						<p className="trans-dark" style={{ marginBottom: "5px" }}>
							{`Flight Number ${item?.flight_number}  Flight Time ${item?.flight_time}`}
						</p>
						<span className="text-warning">
							<i className="fas fa-exclamation-triangle me-2"></i>
							If The Information Is Not Accurate, The Supplier Is Not
							Responsible For The Correct Service Provision.
						</span>
					</div>
					<div className="two-box">
						<div className="half-box">
							<p style={{ fontWeight: "bold" }}>
								Cancellation Fees — {item?.trip_type}
							</p>
							{item?.cancellation_policies.map((res) => (
								<>
									<p className="text-success">
										Until {res?.from}
										<span
											style={{ fontWeight: "bold", marginInlineStart: "15px" }}
										>
											Free
										</span>
									</p>
									<p className="text-danger">
										After {res?.from}
										<span
											style={{ fontWeight: "bold", marginInlineStart: "15px" }}
										>
											<span>{res?.amount}</span>
											<span>{res?.currencyId}</span>
										</span>
									</p>
								</>
							))}
						</div>
						<div
							className="half-box"
							style={{ borderLeft: "1px solid #ebebeb" }}
						>
							<span>
								Date and time are calculated based on local time in the
								destination. In case of no-show, different fees will apply.
								Please refer to our T&C.
							</span>
						</div>
					</div>
					<div className=" w-100  px-4 py-1 d-flex align-items-center justify-content-between">
						{item.status !== "CANCELED" && (
							<p
								onClick={() => {
									printVoucher(item.id);
								}}
								className="text-warning pointer"
								style={{ margin: "0px" }}
							>
								<i className="fas fa-download"></i> Download Voucher
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default TransferItem;
