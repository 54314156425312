import moment from "moment";
import { CATEGORIES_OPTIONS } from "..";

export function formateSubscriptionConfig(data, actionType, countriesOptions) {
	const addCountries = countriesOptions?.length > 0 && {
		countries: [...countriesOptions],
	};
	const editCountries = countriesOptions?.length > 0 &&
		data?.countries && {
			countries:
				data?.countries?.length === 0
					? [...countriesOptions]
					: data?.countries.map((country) => ({
							value: country.id,
							label: country.name,
					  })),
		};
	return actionType !== "add"
		? {
				...editCountries,
				modules: data?.modules?.map((module) => ({
					...module,
					status: module?.status ?? "active",
					type: module?.type ?? "free",
					subModules: module?.subModules?.map((sub) => ({
						...sub,
						status: sub?.status ?? "active",
						type: sub?.type ?? "free",
					})),
				})),
				currency: data?.currency,
				price: data?.price,
				start_date: moment(data?.start_date).format("YYYY-MM-DD"),
				end_date: moment(data?.end_date).format("YYYY-MM-DD"),
				// countries:
				// 	data?.countries?.length === 0
				// 		? [...countriesOptions]
				// 		: data?.countries.map((country) => ({
				// 				value: country.id,
				// 				label: country.name,
				// 		  })),
				apply_discount: data?.apply_discount === 1 ? true : false,
				discount_type: data?.discount_type,
				discount_value:
					data?.discount_type === "percentage" ? data?.discount_value : 0,
				fixed_value:
					data?.discount_type !== "percentage" ? data?.discount_value : 0,
				title: data?.title,
				duration: data?.duration,
				category: CATEGORIES_OPTIONS.find(
					(category) => category.id === data?.category
				),
				name: data?.name,
				description: data?.description,
		  }
		: {
				...addCountries,
				modules: [...data?.modules],
				currency: data?.currency,
				price: "",
				start_date: undefined,
				end_date: undefined,
				// countries:  [...countriesOptions] ,
				apply_discount: false,
				discount_type: "percentage",
				discount_value: 0,
				fixed_value: 0,
				title: "",
				duration: "",
		  };
}
