import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { b2cUserView } from "services/b2c";
import Locale from "translations";
import PackageReservations from "./PackageReservations";
import ChangePassword from "./Profile/ChangePassword";
import EditUser from "./Profile/Edit";
import Profile from "./Profile/Profile";
import UserReservations from "./UserReservations";
import UserTransactions from "./UserTransactions";
// import UserTransactions from "./TransactionsList";

function ViewUser() {
	const { Companies, backOffice, wallet } = Locale;

	const [edit, setEdit] = useState(false);
	const [profileDetails, setProfileDetails] = useState(null);

	const { id } = useParams();
	const { path, url } = useRouteMatch();
	const { push, location } = useHistory();
	const { pathname } = location;
	useEffect(() => {
		const fetchList = async () => {
			const res = await b2cUserView(id);
			if (res?.status === 200) {
				setProfileDetails(res?.data?.data);
			}
		};
		fetchList();
	}, []);

	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const toggleChangePassword = () => {
		setChangePasswordModal((prev) => !prev);
	};

	const tabsContent = [
		{
			id: 1,
			route: "profile",
			component: edit ? (
				<EditUser setEdit={setEdit} details={profileDetails} />
			) : (
				<Profile profileDetails={profileDetails} />
			),
		},
		{
			id: 2,
			route: "umrah-reservations",
			component: <UserReservations />,
		},
		{
			id: 3,
			route: "package-reservations",
			component: <PackageReservations />,
		},
		{
			id: 4,
			route: "user-transactions",
			component: <UserTransactions />,
		},
	];

	return (
		<div className="b2c-users">
			<h4>{profileDetails?.name}</h4>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between">
				<div className="d-flex ">
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${pathname.endsWith("profile") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/profile`);
						}}
					>
						{Companies.profile}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${
												pathname.endsWith("umrah-reservations")
													? "is-active"
													: ""
											}`}
						onClick={() => {
							push(`${url}/umrah-reservations`);
						}}
					>
						Umrah Reservations
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${
												pathname.endsWith("package-reservations")
													? "is-active"
													: ""
											}`}
						onClick={() => {
							push(`${url}/package-reservations`);
						}}
					>
						Package Reservations
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                     ${
												pathname.endsWith("user-transactions")
													? "is-active"
													: ""
											}`}
						onClick={() => {
							push(`${url}/user-transactions`);
						}}
					>
						{wallet.Transactions}
					</button>
				</div>

				<ShowForPermission permission="B2C-Users-Manage">
					{pathname.endsWith("profile") && !edit ? (
						<div className="mb-1">
							<button
								type="button"
								class="btn btn-success font-weight-bold me-2"
								onClick={() => toggleChangePassword()}
							>
								{backOffice.changePassword}
							</button>

							<button
								type="button"
								class="btn btn-success font-weight-bold"
								onClick={() => setEdit(true)}
							>
								{backOffice.edit}
							</button>
						</div>
					) : null}
				</ShowForPermission>
			</div>

			<Switch>
				{tabsContent.map((pane) => (
					<Route path={`${path}/${pane.route}`}>{pane.component}</Route>
				))}
			</Switch>

			<Modal isOpen={changePasswordModal} toggle={toggleChangePassword}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0">
					<ChangePassword closeModal={toggleChangePassword} />
				</ModalBody>
			</Modal>
		</div>
	);
}

export default ViewUser;
